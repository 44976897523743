import { Injectable } from '@angular/core'

export interface PasswordMatcher {
  label: string
  pair?: boolean
  matcher: (s: string, r?: string) => boolean
}

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  isTrueLike(o: string | boolean) {
    return o == 'true' || o == true
  }

  isDate(d: any) {
    return !!(d && d instanceof Date && !isNaN(d.valueOf()))
  }

  getPasswordMatchRules(): PasswordMatcher[] {
    return [
      {
        label: 'Greater than 6 characters.',
        matcher: (s) => s?.length > 6,
      },
      {
        label: 'Has one capital letter.',
        matcher: (s) => !!s?.match(/[A-Z]/),
      },
      {
        label: 'Has letters.',
        matcher: (s) => !!s?.match(/[A-Za-z]/),
      },
      {
        label: 'Has numbers.',
        matcher: (s) => !!s?.match(/[0-9]/),
      },
      {
        label: 'Includes one or more special character',
        matcher: (s) =>
          !!s?.match(/^(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/),
      },
      {
        label: 'Passwords matches',
        matcher: (l, r) => !!(l && r && l == r),
        pair: true,
      },
    ]
  }

  notAllowedSpecialChar(value: string): boolean {
    const regex = /[\`~$&=;:'",<>\(\)\[\]\{\}\\|/?]/;
    return regex.test(value)
  }
}
