import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { from, Observable, of, throwError } from 'rxjs'
import { catchError, map, switchMap, timeout } from 'rxjs/operators'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ToastType } from 'src/app/common/components/toast/toast.types'
import {
  RocketSession,
  RocketVerificationOptions,
  UiDownloadFile,
  UiTransferFile,
  UiUploadFile,
} from 'src/app/components/rocket/common/classes/rocket-types'
import { Project, User } from 'src/app/models/bebop.model'
import { DownloadConstants, UploadConstants } from 'src/app/services/rocket/classes/rocket-constants'
import { DownloaderQuery } from 'src/app/store/rocket/downloader/downloader.query'
import { DownloaderService } from 'src/app/store/rocket/downloader/downloader.service'
import { LinkQuery } from 'src/app/store/rocket/link/link.query'
import { LinkService } from 'src/app/store/rocket/link/link.service'
import { UploaderQuery } from 'src/app/store/rocket/uploader/uploader.query'
import { UploaderService } from 'src/app/store/rocket/uploader/uploader.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { NavPermissions } from 'src/app/store/ui/ui.store'

import { BebopClientUtilsService } from '../bebop-client-utils.service'
import { BebopConfigService } from '../bebop-config.service'
import { ElectronService } from '../electron.service'
import { UserService } from '../user.service'
import { UserSettingsService } from '../user-settings.service'

import { DownloadPlus } from './classes/api/download/DownloadPlus'
import { RocketFileToken, RocketToken, RocketTokenProps } from './classes/rocket-tokens'
import { ElasticSearchService } from './elastic-search.service'
import { FileBadgeService } from './file-badge.service'

@Injectable({
  providedIn: 'root',
})
export class RocketService {
  // upload-plus.js, download-plus.js, TxCache.js, TransferIpService.js, bebopDnsService.js
  // upload-token.js, download-token.js

  ipCachePerStorage: any = {}

  // upload-token.js
  _tokenProvider = {
    downloader: () => new RocketToken(this),
    receive: new RocketToken(this),
    share: new RocketToken(this),
  }

  get tokenProvider() {
    return this._tokenProvider
  }

  // download-token.js
  _tokenFileProvider = {
    downloader: new RocketFileToken(this),
    share: (id: string) => new RocketFileToken(this, id),
  }

  get tokenFileProvider() {
    return this._tokenFileProvider
  }

  permissions: NavPermissions
  get user() {
    return this.userService.user
  }
  loggedIn = false

  _uploadCacheSettings: any
  _userSettings: any

  constructor(
    private utilService: BebopClientUtilsService,
    private electronService: ElectronService,
    private es: ElasticSearchService,
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private sessionQuery: SessionQuery,
    private uiQuery: UIQuery,
    private toastService: ToastService,
    private uploaderQuery: UploaderQuery,
    private uploaderService: UploaderService,
    private downloaderQuery: DownloaderQuery,
    private downloaderService: DownloaderService,
    private linkService: LinkService,
    public fileBadgeService: FileBadgeService,
    private userService: UserService,
    public util: BebopClientUtilsService,
    private userSettingService: UserSettingsService,
    private linkQuery: LinkQuery
  ) {
    DownloadPlus.prototype.readablizeBytes = this.utilService.readablizeBytes

    this.resolveDNS = this.resolveDNS.bind(this)
    this.getRocketIpCache = this.getRocketIpCache.bind(this)
    this.onNotification = this.onNotification.bind(this)

    this.uiQuery.getNavPermissions().subscribe((permissions) => {
      this.permissions = permissions
    })

    this.sessionQuery.getLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn

      if (!this.loggedIn) {
        this.onLogout()
      }
    })

    this.userSettingService.getUserSettings().subscribe((s) => (this._userSettings = s))
    this.userSettingService.getUploadCache().subscribe((s) => (this._uploadCacheSettings = s))
  }

  get elasticService() {
    return this.es
  }

  get elecService() {
    return this.electronService
  }

  onLogout() {}

  get MachineId() {
    return this.electronService.MachineId
  }

  get downloadPath() {
    return this._userSettings?.downloadPath || this.electronService.downloadPath
  }

  generateSessionID() {
    return Math.random().toString(36).substring(2, 17)
  }

  resolveDNS(host: string, cb: (err?: Error, data?: any) => void) {
    this.http
      .post<any>(`${this.bebopConfig.apiUrl}/api/v1/transfer/resolveDNS`, {
        host,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On /api/v1/transfer/resolveDNS', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
      .subscribe((e) => {
        if (e?.error) return cb(e)
        cb(null, e)
      })
  }

  newRocketToken(data: RocketTokenProps, cb: (err?: Error, data?: any) => void) {
    // newToken from transferTokenService
    this.http
      .post<any>(`${this.bebopConfig.apiUrl}/api/v2/transfer/token/new`, {
        data,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On /api/v2/transfer/token/new', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
      .subscribe((e) => {
        if (e?.error) return cb(e)
        cb(null, e)
      })
  }

  newRocketFileToken(data: RocketTokenProps, cb: (err?: Error, data?: any) => void) {
    // newDownloadToken from transferTokenService
    this.http
      .post<any>(`${this.bebopConfig.apiUrl}/api/v1/download/token/new`, {
        data,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On /api/v1/download/token/new', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
      .subscribe((e) => {
        if (e?.error) return cb(e)
        cb(null, e)
      })
  }

  downloadPlus() {
    return new DownloadPlus({
      simultaneousDownload: 4,
      smallFileCriteria: 50 * 1024 * 1024,
      verificationOption: RocketVerificationOptions.FullVerification,
    })
  }

  isRocketDownload(rocket: any) {
    return rocket instanceof DownloadPlus
  }

  get uploader() {
    return {
      query: this.uploaderQuery,
      service: this.uploaderService,
    }
  }

  get downloader() {
    return {
      query: this.downloaderQuery,
      service: this.downloaderService,
    }
  }

  get droplink() {
    return {
      query: this.linkQuery,
      service: this.linkService,
    }
  }

  createRocketDownloaderEvents(session: RocketSession<any, UiDownloadFile>, lcService: any) {
    session.rocket.rocketSession = session
  }

  updateTransferSpeedSettings(project: Project, rocketInstance: any) {
    let settings = {
      numberOfTransferServers: project.storage.numberOfTransferServers,
      targetUploadSpeed: project.targetUploadSpeed || (project.pod && project.pod.targetUploadSpeed) || 256,
    }

    let targetUploadSpeed = +settings.targetUploadSpeed || 256

    let storage = project.storage
    let rocket = storage.rocket || {}
    rocket.parallelUploads =
      rocket.parallelUploads ||
      (project.storage.type == 'LUCIDLINK'
        ? UploadConstants.MAX_FILES_PER_TX_BEBOP_FLEX
        : UploadConstants.MAX_FILES_PER_TX)
    rocket.parallelDownloads = rocket.parallelDownloads || DownloadConstants.ROCKET_PARALLEL_DOWNLOADS
    rocket.parallelVerifications = rocket.parallelVerifications || DownloadConstants.ROCKET_PARALLEL_VERIFICATIONS
    project.storage.rocket = rocket

    let uvOpt = project?.rocket?.uploadVerificationOption || storage?.rocket?.uploadVerificationOption
    rocketInstance.opts.verificationOption = uvOpt ?? rocketInstance.opts.uploadVerificationOption
    rocketInstance.opts.uploadsPerServer = rocket.parallelUploads
    rocketInstance.opts.verificationsPerServer = rocket.parallelVerifications
    rocketInstance.opts.chunkSize = UploadConstants.CHUNK_SIZE
    rocketInstance.opts.targetUploadSpeed = targetUploadSpeed
    rocketInstance.opts.badge = this.fileBadgeService
    console.log('# of parallel upload files per tx', rocketInstance.opts.uploadsPerServer)
    console.log('Target upload speed configured is', targetUploadSpeed)
    console.log('Size of upload chunk', rocketInstance.opts.chunkSize)
  }

  getClientTxIPs(id: string, cb: (err?: Error, data?: any) => void) {
    let url = `api/v1/storage-servers/${id}/clientTxIPs`
    this.http
      .get<any>(`${this.bebopConfig.apiUrl}/${url}`)
      .pipe(
        catchError((error: any) => {
          console.error(`On ${url}`, error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
      .subscribe((e) => {
        if (e?.error) return cb(new Error(e.error))
        cb(null, e)
      })
  }

  getRocketIpCache(id: string) {
    if (!id) return Promise.reject('Invalid request')

    let cache = this.ipCachePerStorage[id]
    let now = Date.now()
    if (cache && cache.timeout > now) {
      return cache.promise
    }

    // let oldCache = cache;
    cache = this.ipCachePerStorage[id] = { timeout: now + 60 * 1000 }
    cache.promise = new Promise((resolve, reject) => {
      // console.time('Get Client Tx Ips')
      this.getClientTxIPs(id, (err, ips) => {
        // console.timeEnd('Get Client Tx Ips')
        if (err) {
          // use old ips - on mcp error
          // if (oldCache && oldCache.promise ) {
          //   oldPromise.then(function(ips) {
          //     this.ipCachePerStorage[id] = oldCache;
          //     resolve(ips);
          //   }).catch(function(e) {
          //     delete this.ipCachePerStorage[id];
          //     return reject(err);
          //   })
          //   return;
          // }
          delete this.ipCachePerStorage[id]
          return reject(err)
        }
        let res = ips && ips.data // && ips.data.data
        resolve(res || { browse: [], rocket: [] })
      })
    })

    return cache.promise
  }

  onNotification(event: { message?: string; statusMessage?: string; statusCode?: number; type: ToastType }) {
    setTimeout(() => {
      let msg = event.message || event.statusMessage || event.statusCode?.toString?.() || 'Server Error'

      if (event.type == 'error') console.error('Error Notification:', msg, event)

      this.toastService.show({
        text: msg,
        type: event.type,
      })
    }, 16)
  }

  getStreamUrl(payload: any, project: any, channelInfo?: any): Observable<string> {
    const endPoint = '/api/v1/streams/download'

    if (!project) {
      return throwError(() => new Error('Project is missing for ' + endPoint))
    }

    const storage = project.storage
    if (!storage) {
      return throwError(() => new Error('Storage is missing for ' + endPoint))
    }

    let channels = ['SYNC', 'MEDIA', 'DL', 'CLIENT']
    if (channelInfo && Array.isArray(channelInfo.channelsOrder) && channelInfo.channelsOrder.length) {
      channels = channelInfo.channelsOrder.reverse()
    }

    if (!storage.txChannelDNS) {
      storage.txChannelDNS = { CLIENT: storage.bebopUploaderEndpoint }
      channels = ['CLIENT']
    }

    console.log('payload in here', payload)

    return this.txBootstrap(channels.pop(), payload, storage, endPoint).pipe(
      catchError((err) => {
        console.error(endPoint, 'error', err.message)
        if (channels.length) {
          return this.txBootstrap(channels.pop(), payload, storage, endPoint)
        }
        return throwError(() => err)
      })
    )
  }

  private txBootstrap(channel: string, payload: any, storage: any, endPoint: string): Observable<string> {
    const url = `${storage.bebopUploaderProtocol}://${storage.txChannelDNS[channel]}${endPoint}?\
session=${payload.sessionId}&user=${payload.userId}&project=${payload.projectId}&token=${payload.token}`
    console.log('txBootstrap url', url)
    // Optionally handle auto shutdown or additional logic here
    return new Observable((observer) => {
      observer.next(url)
      observer.complete()
    })
  }

  getStreamUrlWithToken(data: any, file: any): Observable<any> {
    const url = `${this.bebopConfig.apiUrl}/api/v1/projects/${file.projectId}/stream-token`

    // Make the HTTP call and process the response using observables
    return this.http.post(url, data)
  }

  downloadFile(file: any): Observable<string> {
    const data: any = {
      sessionId: Math.random().toString(36).substring(2, 17),
      streamFile: file.relativePath,
    }

    return this.getStreamUrlWithToken(data, file).pipe(
      switchMap((res) => {
        const token = res?.token

        // Validate token
        if (!token) {
          return throwError(() => new Error('Unable to generate token'))
        }

        // Assign values to the data object
        data.userId = file.userId
        data.projectId = file.projectId
        data.token = token
        data.sessionId = res.session

        // Fetch the stream URL
        return this.getStreamUrl(data, file.project)
      }),
      catchError((err) => {
        console.error('Error fetching video URL:', err)
        return throwError(() => err)
      })
    )
  }

  deleteFile(file: any, machineId?: string, linkId?: string): Promise<any> {
    let transferSessionID = Math.random().toString(36).substring(2, 17)
    const dataToken: RocketTokenProps = {
      linkId: linkId,
      machineId: machineId,
      projectId: file.projectId,
      transferSessionID,
      userId: file.userId,
    }

    return new Promise((resolve, reject) => {
      this.newRocketToken(dataToken, (err, data) => {
        if (err) {
          console.error('Error creating token:', err)
          return reject(err) // Reject the promise on error
        }
        const payload = {
          projectId: file.projectId,
          relativePath: file.relativePath,
          token: data.token,
          transferSessionID,
          userId: file.userId,
        }

        this.deleteFolderOrFile(payload, file.project, null, '/v1/tree/file/delete').subscribe({
          // Resolve the promise with the response from deleteFolderOrFile
          error: (err) => reject(new Error(err?.message || 'Failed to delete')),
          next: (response) => resolve(response), // Reject the promise with an error message
        })
      })
    })
  }

  private deleteFolderOrFile(payload: any, project: any, channelInfo: any, endPoint: string): Observable<any> {
    if (!project) {
      return throwError(() => new Error(`project is missing for ${endPoint}`))
    }

    const storage = project.storage
    if (!storage) {
      return throwError(() => new Error(`storage is missing for ${endPoint}`))
    }

    let channels = ['SYNC', 'MEDIA', 'DL', 'CLIENT']

    if (channelInfo && Array.isArray(channelInfo.channelsOrder) && channelInfo.channelsOrder.length) {
      channels = [...channelInfo.channelsOrder].reverse() // Copy and reverse the array
    }

    if (!storage.txChannelDNS) {
      storage.txChannelDNS = { CLIENT: storage.bebopUploaderEndpoint }
      channels = ['CLIENT']
    }

    return this.http.post(`${storage.bebopUploaderProtocol}://${storage.txChannelDNS[channels.pop()]}${endPoint}`, {
      projectId: payload.projectId,
      relativePath: payload.relativePath,
      token: payload.token,
      transferSessionID: payload.transferSessionID,
      userId: payload.userId,
    })
  }
}
