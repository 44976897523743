<div class="container">
  <div class="clients">
    <div class="image-scroller">
      <div class="image-wrapper">
        <ng-container *ngFor="let item of repeatedClients">
          <img [src]="item.src" [srcset]="item.srcset" [alt]="item.name" />
        </ng-container>
      </div>
    </div>
  </div>
</div>
