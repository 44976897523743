import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { RocketBusy, RocketSession, UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { Organization } from 'src/app/models/bebop.model'
import { ResponseError } from 'src/app/models/response.model'
import { DownloadQueueRequest, DownloadQueueResponse } from 'src/app/models/rocket-rest.model'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'
import { ElasticSearchService } from 'src/app/services/rocket/elastic-search.service'

import {
  createDefaultNavigator,
  createDownloadSession,
  createInitialState,
  DownloaderState,
  DownloaderStore,
} from './downloader.store'

@Injectable({ providedIn: 'root' })
export class DownloaderService implements RocketBusy {
  list: (d: DownloadQueueRequest) => Observable<DownloadQueueResponse>
  add: (d: DownloadQueueRequest) => Observable<DownloadQueueResponse>
  remove: (d: DownloadQueueRequest) => Observable<DownloadQueueResponse>
  removeMany: (d: DownloadQueueRequest) => Observable<DownloadQueueResponse>
  removeAll: (d: DownloadQueueRequest) => Observable<DownloadQueueResponse>

  constructor(
    private dstore: DownloaderStore,
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    private es: ElasticSearchService,
    private toast: ToastService
  ) {
    // download queue service
    this.list = this.queuePostBuilder('/api/v1/download/queue/list')
    this.add = this.queuePostBuilder('/api/v1/download/queue/add')
    this.remove = this.queuePostBuilder('/api/v1/download/queue/remove')
    this.removeMany = this.queuePostBuilder('/api/v1/download/queue/removeMany')
    this.removeAll = this.queuePostBuilder('/api/v1/download/queue/removeAll')
  }

  get elasticService() {
    return this.es
  }

  get toastService() {
    return this.toast
  }

  forceLogout() {
    this.dstore.update((store) => createInitialState())
  }

  createDownloadInstance(rocket: any, org: Organization, props?: Partial<RocketSession<any, UiDownloadFile>>) {
    let downloader = createDownloadSession(rocket, org, props)
    this.dstore.update((store) => ({
      ...store,
      downloads: [...store.downloads, downloader],
    }))

    return downloader
  }

  queuePostBuilder<T, S extends ResponseError>(suffix: string) {
    let self = this
    return (data: T) => {
      let url = this.bebopConfig.apiUrl + suffix

      return self.http.post<S>(url, { data }).pipe(
        catchError((error: any): Observable<S> => {
          console.error(`On ${suffix}`, error.message)
          return of<S>(<S>{
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
    }
  }

  updateSession(download: RocketSession<any, UiDownloadFile>) {
    this.dstore.update((store) => {
      let downloads = store.downloads?.filter((u) => u.id != download.id)

      return {
        ...store,
        downloads: [
          ...downloads,
          {
            ...download,
          },
        ],
      }
    })
  }

  update(data: Partial<DownloaderState>) {
    this.dstore.update((store) => ({
      ...store,
      ...data,
    }))
  }

  selectProjectAndPod(data: Partial<DownloaderState>) {
    let value = this.dstore.getValue()
    let project = data.selectedProject
    let browseNav = { ...value.browseNav }

    if (project) {
      if (!browseNav[project._id]) {
        browseNav[project._id] = createDefaultNavigator()
      }
      data.browseNav = browseNav
    }

    this.update(data)
  }

  setLoading() {
    this.update({ serverState: 'Loading' })
  }

  setConnected() {
    this.update({ serverState: 'Connected' })
  }

  isBusy(session: RocketSession<any, UiDownloadFile>) {
    return (
      session?.overallProgress &&
      !!(
        session?.overallProgress?.progressList.length ||
        session?.overallProgress?.nextList.length ||
        session?.overallProgress?.errorList.length ||
        session?.downloadQueue?.length
      )
    )
  }

  clear() {
    this.dstore.reset()
  }
}
