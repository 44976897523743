<div class="bebop-filter-workstation" @slideAnim *ngIf="!_closed">
  <div class="header">
    <div class="header-icon">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 5.786A2.786 2.786 0 0 1 5.786 3h12.428A2.786 2.786 0 0 1 21 5.786c0 .807-.289 1.587-.814 2.2l-2.834 3.307a7 7 0 0 0-1.685 4.555V18a3 3 0 0 1-3 3h-1.334a3 3 0 0 1-3-3v-2.152a7 7 0 0 0-1.685-4.555L3.814 7.986A3.381 3.381 0 0 1 3 5.786ZM5.786 5A.786.786 0 0 0 5 5.786c0 .33.118.648.332.899L8.167 9.99a9 9 0 0 1 2.166 5.857V18a1 1 0 0 0 1 1h1.334a1 1 0 0 0 1-1v-2.152a9 9 0 0 1 2.166-5.857l2.835-3.306c.214-.25.332-.57.332-.9A.786.786 0 0 0 18.214 5H5.786Z"
          fill="#7F7F8C"
        />
      </svg>
    </div>
    <div class="header-title h5">Filters</div>

    <div class="header-close" (click)="cancel()">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.293 5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293Z"
          fill="white"
        />
      </svg>
    </div>
  </div>

  <div class="action-section">
    <div class="filter-title">
      <span class="h4 filter-title-label">Filter by</span>
      <div class="filtered-ws paragraph" *ngIf="hasFiltered" [class.no-result]="guessCount == 0">
        {{ guessCount }} workstations found
      </div>
    </div>

    <div class="filter-state">
      <div class="title filter-label">Workstation State</div>
      <bebop-input-select placeholder="" [value]="stateSelect.value" selectOnly="true">
        <div dropdown-options>
          <bebop-input-select-option *ngFor="let item of stateSelect.items" (click)="onSelectState(item)">
            <div option-text>
              {{ item.value }}
            </div>
          </bebop-input-select-option>
        </div>
      </bebop-input-select>
    </div>
    <div class="filter-user">
      <div class="title filter-label">User</div>
      <bebop-input-select placeholder="" [value]="userSelect.value" selectOnly="true">
        <div dropdown-options>
          <bebop-input-select-option *ngFor="let item of userSelect.items" (click)="onSelectUser(item)">
            <div option-text>
              {{ item.value }}
            </div>
          </bebop-input-select-option>
        </div>
      </bebop-input-select>
    </div>
    <div class="divider"></div>

    <div class="filter-actions">
      <bebop-button class="clear-all" size="small" outlined="true" (on-click)="clearAll()">Clear All</bebop-button>
      <bebop-button class="apply-all" size="small" (on-click)="applyAll()">Apply</bebop-button>
    </div>
  </div>
</div>
