import { Component, Input, Output, EventEmitter } from '@angular/core'
import { ICardPrice } from './card-price.model'

@Component({
  selector: 'card-price',
  standalone: false,
  templateUrl: './card-price.component.html',
  styleUrl: './card-price.component.scss',
})
export class CardPriceComponent {
  @Input() data: ICardPrice = {} as ICardPrice
  @Output() clickBtn: EventEmitter<ICardPrice> = new EventEmitter<ICardPrice>()

  checkingTooltip(item: number): string {
    return this.data.uptoDescription[item] || ''
  }

  checkingPlusDescription(item: number): string {
    return this.data.plusDescription[item] || ''
  }
}
