<cree8-modal [modal]="selectModal" size="normal">
  <div class="container">
    <div tabindex="0" role="button" class="org-select" *ngFor="let item of orgSelect.items"
      (click)="onSelectOrganization(item)" (keydown.enter)="onSelectOrganization(item)">
      <div class="org">
        <img class="img-circle" loading="lazy" [src]="item.logo" *ngIf="item.logo" />
        <div class="img-circle" *ngIf="!item.logo">
          <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1474_33082" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32"
              height="32">
              <circle cx="16" cy="16" r="15.5" fill="url(#paint0_linear_1474_33082)"
                stroke="url(#paint1_linear_1474_33082)" />
            </mask>
            <g mask="url(#mask0_1474_33082)">
              <path
                d="M33.875 14.25H27.75C27.0596 14.25 26.5 13.6904 26.5 13V12.625C26.5 12.1418 26.1082 11.75 25.625 11.75V11.75C25.1418 11.75 24.75 12.1418 24.75 12.625V15.75C24.75 16.3023 24.3023 16.75 23.75 16.75V16.75C23.1977 16.75 22.75 16.3023 22.75 15.75V12.25C22.75 10.5931 21.4069 9.25 19.75 9.25V9.25H18.75C17.6454 9.25 16.75 8.35457 16.75 7.25V7.25C16.75 6.14543 17.6454 5.25 18.75 5.25V5.25C19.8546 5.25 20.75 6.14543 20.75 7.25V7.25C20.75 8.35457 19.8546 9.25 18.75 9.25H17.75V9.25C16.0931 9.25 14.75 10.5931 14.75 12.25V14.25"
                stroke="#5A5A68" />
            </g>
            <mask id="mask1_1474_33082" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32"
              height="32">
              <circle cx="16" cy="16" r="15.5" fill="url(#paint2_linear_1474_33082)"
                stroke="url(#paint3_linear_1474_33082)" />
            </mask>
            <g mask="url(#mask1_1474_33082)">
              <path
                d="M31 22.25H24.75C24.1977 22.25 23.75 21.8023 23.75 21.25V21.125C23.75 20.6418 23.3582 20.25 22.875 20.25V20.25C22.3918 20.25 22 20.6418 22 21.125V23.125C22 23.7463 21.4963 24.25 20.875 24.25V24.25C20.2537 24.25 19.75 23.7463 19.75 23.125V18.3962C19.75 17.2109 18.7891 16.25 17.6038 16.25H16.7375C15.9391 16.25 15.3134 15.5638 15.3869 14.7689V14.7689C15.5374 13.1406 17.9082 13.1208 18.0857 14.7464V14.7464C18.1734 15.549 17.5448 16.25 16.7375 16.25H15.8775C14.7025 16.25 13.75 17.2025 13.75 18.3775V20.125C13.75 20.8844 13.1344 21.5 12.375 21.5V21.5C11.6156 21.5 11 20.8844 11 20.125V17C11 15.3431 9.65685 14 8 14V14H7C5.89543 14 5 13.1046 5 12V12C5 10.8954 5.89543 10 7 10V10C8.10457 10 9 10.8954 9 12V12C9 13.1046 8.10457 14 7 14H6V14C4.34315 14 3 15.3431 3 17V17.75"
                stroke="#7F7F8C" />
            </g>
            <defs>
              <linearGradient id="paint0_linear_1474_33082" x1="28.25" y1="5.125" x2="13.875" y2="14.375"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#2B2B33" />
                <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
                <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint1_linear_1474_33082" x1="0.441719" y1="0.18492" x2="10.7291" y2="36.916"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0.08" />
                <stop offset="1" stop-color="white" stop-opacity="0.04" />
              </linearGradient>
              <linearGradient id="paint2_linear_1474_33082" x1="24.125" y1="9.25" x2="6.75" y2="19.375"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#2B2B33" />
                <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
                <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
                <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
                <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint3_linear_1474_33082" x1="32" y1="25.6" x2="0.568464" y2="24.2474"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0.04" />
                <stop offset="1" stop-color="white" stop-opacity="0.08" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="body2">
          {{ item.value }}
        </div>
      </div>
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Chevron_Single_Forward'"></use>
        </svg>
      </div>
    </div>
  </div>
</cree8-modal>
