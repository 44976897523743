import { SafeUrl } from '@angular/platform-browser'

import { VmPowerStatus } from '../components/workstation/classes/workstation-types'
import { MountOption } from '../store/flex/flex.select.store'

export type LockType = 'READ_WRITE' | 'WRITE'

export type AnnouncementKey =
  | 'bebop-client:banner:siginin'
  | 'bebop-client:banner:dashboard'
  | 'bebop-client:banner:workstations'

export const LucidKey = 'bbp:lucid:instance-map'
export const LucidRootPath = 'bbp:lucid:instance-root-path' // add .user-id
export const LucidCacheRootFolder = '.lucid'

export const LucidDefaults = {
  cacheSize: 5 * 1024 ** 3,
} as const

export type ObjectId = string
export interface BebopEntity {
  _id: ObjectId
  name: string
  displayName?: string
}
export interface LoginInfo {
  username?: string
  password?: string
  confirmPassword?: string
  correlationKey?: string
  totpSetupURI?: string
  token?: string
  auth_key?: string
  auth_code?: string
  clientVersion?: string
  eula?: string
  lastViewedReleaseNote?: string
}
export interface Provider {
  name: string
  provider: string
  redirectUrl: string
}

export interface LoginProvider {
  type?: string
  correlationKey?: string
  providers?: Provider[]
}

export interface EULAInfo extends Partial<BebopEntity> {
  region: EulaRegion
  eulaText: string
  version: string
}
export interface EULA {
  eulas: EULAInfo[]
  region: string
}

export interface ReleaseNote extends Partial<BebopEntity> {
  version: string
  title: string
  releaseDateStr?: string
  mediaType?: string
  mediaUrl?: string
  releaseNoteComponents?: {
    ALL?: string
    BEBOP_CLIENT?: string
    MCP?: string
    BEBOP_WORKSTATION_CLIENT?: string
  }
  releaseNotes?: string
}

export interface UserPreference {
  jumpUserID?: string
  canDownload?: boolean
  canUpload?: boolean
  canHotFolder?: boolean
  canBebopReceiveLink?: boolean
  canBebopShareLink?: boolean
  canBebopRevLink?: boolean
  canAccessDesktop?: boolean
  canBebopCastBroadcast?: boolean
  canBebopCastView?: boolean
  canUseAppLibrary?: boolean
  isDesktopSupportAdmin?: boolean
  assignedToWorkstations?: boolean
  openDevTools?: boolean
}

export interface User extends BebopEntity {
  firstname: string
  lastname: string
  pictureIcon?: SafeUrl
  picture: string
  email: string
  lastViewedReleaseNote?: string
  username: string
  organizationsAsUser: Organization[]
  userPreferences: UserPreference
  role?: {
    name?: string
  }
  [key: string]: any
}

export type BebopCloud =
  | 'AMAZON'
  | 'SOFTLAYER'
  | 'AZURE'
  | 'GOOGLE'
  | 'ALIBABA'
  | 'WASABI'
  | 'LYVE'
  | 'ICONIK'
  | 'PARSEC'
  | 'JUMP'
  | 'ELUVIO'
  | 'LUCID'

export interface AWSCloudCred {
  region?: string
  apiVersion?: string
  key?: string
  secret?: string
}

export interface LucidCloudCred {
  fileSpace?: string
  username?: string
  password?: string
  readonly_username?: string
  readonly_password?: string
  root_mount?: string
}

export interface JumpCloudCred {
  teamId?: string
  apiKey?: string
  apiurl?: string
}

export interface ParsecCloudCred {
  teamId?: string
  teamComputerKey?: string
  apiKey?: string
}

export interface EluvioCloudCred {
  fabricUrl: string
  elvGeo: string
  privateKey: string
  libraryId: string
  mezLibraryId: string
  contentType: string
  mezContentType: string
}

export interface AzureCloudCred {
  blobSASUrl: {
    type: string
    maxlength: 1500
  }
}

export interface GoogleCloudCred {
  projectId?: string
  client_email?: string
  private_key?: string
}

export interface IconikCloudCred {
  iconikUrl?: string
  appId?: string
  storageId?: string
  authToken?: string
}

export interface BebopCredential extends BebopEntity {
  cloud?: BebopCloud

  credentials?: {
    aws?: AWSCloudCred
    lyve?: AWSCloudCred
    wasabi?: AWSCloudCred

    azure?: AzureCloudCred
    google?: GoogleCloudCred
    iconik?: IconikCloudCred
    parsec?: ParsecCloudCred
    jump?: JumpCloudCred
    eluvio?: EluvioCloudCred
    lucid?: LucidCloudCred
  }
  is_internal?: boolean
  is_active?: boolean
  organization?: Organization
  storage?: Storage
  created_by?: User
  date_creds_updated?: string | Date
  date_created?: string | Date
  date_modified?: string | Date

  verification?: {
    status?: false
    lastVerified?: Date | string
  }
}

export interface BebopLinkSecurity {
  open: boolean
  login: boolean
  password: boolean
}

export interface Organization extends BebopEntity {
  logo?: SafeUrl
  pods?: Pod[]
  suspended?: boolean
  is_active?: boolean
  osOneBlockRootPath: string
  flexMountCredential?: BebopCredential
  bebopLinkSecurity?: BebopLinkSecurity
}

export interface PodUserInfo {
  total: number
  users: string[] // few 2 letter user names
  colors?: string[] // filled my client
}

export interface PodUser extends BebopEntity {
  name: string
}
export interface Pod extends BebopEntity {
  targetUploadSpeed?: number

  region?: Region
  storage?: BebopStorage
  workstationConfig?: WorkstationConfig

  workstations?: Workstation[]

  organizationsAsPod?: string[]

  podUsers?: PodUser[]

  users?: PodUserInfo

  location?: string

  projectCount?: number
}

export interface Region extends BebopEntity {
  adDomainUrl?: string

  name: string
  displayName?: string
  cidr?: string
  cgUrlArr: [
    {
      uri?: string
    },
  ]
  cgDNS?: string
  cgUrlLocArr?: [
    {
      location?: string
      dns?: string
      cgUrlArr: [
        {
          uri: string
        },
      ]
    },
  ]
  cloud?: string
  regionAgentArr?: string[]
  adServerName?: string
  adFQDN?: string
  adUsername?: string
  adPassword?: string
  adSecurePassword?: string
  adHost?: string
  adBaseDn?: string
  adPodsOu?: string
  adInstanceId?: string
  description?: string
  opsgenieHeartbeatName?: string
  dbSyncUrl?: string
  isPrivate?: boolean
  pingTestUrl?: string
  latitude?: number
  longitude?: number
  testDriveEnabled?: boolean
  is_active?: boolean
  organizationsAsRegion?: Organization[]
}

export interface Solutions {
  adobeCache?: boolean
  adobeCacheType?: string
  globalProject?: boolean
  osOneBlock?: boolean
  osOneFlex?: boolean
  s3sync?: boolean
  simLink?: boolean
  smartcat?: boolean
  transcoding?: boolean
}

export interface RocketOverrideOptions {
  downloadVerificationOption?: number
  parallelUploads?: number
  parallelDownloads?: number
  parallelVerifications?: number
  uploadVerificationOption?: number
}

export interface Project extends BebopEntity {
  pod?: Pod
  organization?: Organization
  storage?: BebopStorage
  targetUploadSpeed?: number

  folderLabel?: string
  folderName?: string
  folderSize?: { gb?: number; mb?: number; bytes?: number }
  maxSyncObjects?: number
  sync_active?: boolean

  mountPermissions?: {
    readOnly?: boolean
    canLock?: boolean
  }

  mountLockedBy?: User
  mountLockType?: LockType

  preferredLetter?: string
  mountedIndex?: string | number
  mountPoint?: string
  mountedTime?: number
  mountingTime?: number

  solutions?: Solutions

  rocket?: RocketOverrideOptions

  users?: User[]
  autoMount?: boolean
}

export interface UpdateProjectsSettigs extends Project {
  sync_filters: SyncFilters
  sync_scheduler: SyncScheduler
  externalSystems: {
    ad: {
      sgName: string
      active: boolean
    }
  }
  integrationHealth: {
    s3: {
      available: boolean
      message: string
    }
  }
  storageGroup: string
  fromBucket: string
  syncDirection: string
  sync_down_delete: boolean
  sync_up_delete: boolean
  sync_up_delete_file_only: boolean
  indexing: boolean
  folder_tags: any[]
  file_tags: any[]
  type: string
  oAuthGroups: any[]
  usersManagedByoAuthGroups: boolean
  credentials: any[]
  requireBilling: boolean
  autoMount: boolean
  date_created: string
  date_modified: string
  created_by: string
  is_template: boolean
  is_ready: boolean
  is_active: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

export interface SyncFilters {
  folder_tags: any[]
  file_tags: any[]
  blacklist_folder_tags: any[]
  blacklist_file_tags: any[]
}

export interface SyncScheduler {
  type: string
  fixedTime: number
}

export interface Parsec {
  peerID?: string
}

export interface Jump {
  deviceID?: string
}

export interface WorkstationGroup extends BebopEntity {
  is_active?: boolean
  pod?: Pod
}

export interface WorkstationConfig {
  workstationGroups?: WorkstationGroup[]
}

export interface WorkstationScheduleLog extends BebopEntity {}
export interface ActiveWorkstationLog extends BebopEntity {
  totalRuntime?: number
}

export interface AwsInfo {
  AvailabilityZone?: string
  ImageId?: string
  VpcId?: string
  SubnetId?: string
}

export interface CastSource {
  idx?: number
  total?: number
  name?: string
  url?: string
}

export type CastQuality = 'lowQ' | 'highQ'
export type CastOperatorType = 'BEBOP_BROADCAST' | 'INBOUND' | 'SWITCHER' | 'MEDIASERVER'

export interface CastStation {
  ip?: string
  port?: number
  privateIp?: string
  workstation?: Workstation
  isAdditional?: boolean
}

export interface CastClient {
  castOperatorInstance?: CastOperator
  loggedInUser?: User
  ip?: string
  os?: string
  currentQuality?: string

  is_active?: boolean

  _id?: string
}

export interface CastBroadcast {
  isLive?: boolean
  livelink?: string
  liveRoomLink?: string
  liveRoomLinkUrl?: string // comes from api
  started?: Date
  ended?: Date
  castSources?: CastSource[]
  selectedSource?: {
    name?: string
    url?: string
  }
  preferences?: {
    quality?: CastQuality
  }
}

export interface CastInbound {
  endpoint?: string //rtmp://...
  streamKey?: string
  inType?: string //rtmp
  quality?: CastQuality
}

export interface CastOperator extends BebopEntity {
  broadcast?: CastBroadcast

  clientsCount?: number // not in the model, comes from api end point

  castStation?: CastStation

  tokens?: {
    broadcaster?: string
    viewer?: string
    livelink?: string
  }
  is_active?: boolean

  broadcastStation?: CastStation & {
    loggedInUser?: User
    ACTIVE_WORKSTATION_LOG?: any
  }

  castStationAdditional: CastStation[]
  castInbound?: CastInbound
  operatorType?: CastOperatorType
  connectedClients: CastClient[]
  activeClientsCount?: number
  organization: Organization[]
}

export interface Workstation extends BebopEntity {
  HOSTNAME?: string
  WINDOWS_NAME?: string
  COMPUTER_UUID?: string
  VMI?: string
  NAME?: string
  DESKTOP_ID?: string
  PRIVATE_IP?: string
  POD_ID?: Pod
  REGION_ID?: Region
  EXPERIMENTAL?: {
    CAN_FLEX?: boolean

    CAST_STATION: {
      CAST_IP?: string
      CAST_PORT?: string
      CAST_ACTIVE?: boolean
      CAST_BROADCAST_TOKEN?: string
      CAST_VIEWER_TOKEN?: string
    }
  }
  PREFS?: {
    CAN_FLEX?: boolean
    LOCK_EARTH_STREAM_KEY?: boolean
    IGNORE_USAGE?: boolean
    PCOIP_SETTINGS?: {
      ULTRA?: boolean
    }
    CAN_JUMP?: boolean
  }
  SESSION_LAUNCH_CHANNEL?: {
    DEFAULT_CHANNEL?: string
    PARSEC?: Parsec
    JUMP?: Jump
  }
  restrictUsers?: any[]
  UPDATED?: Date
  LAUNCHED_AT?: Date
  CREATED?: Date
  FORCE_DISCONNECT?: boolean
  IS_ACTIVE?: boolean
  IN_MAINTENANCE?: boolean
  EBS_WARMING?: boolean
  READY_TO_LAUNCH?: boolean
  NEW_LAUNCH?: boolean
  POWER_STATUS_CODE?: VmPowerStatus
  WORKSTATION_GROUP?: string
  TX_CHANNEL?: string
  WORKSTATION_CHANNEL?: string
  VM_ROLE?: string
  VM_TYPE?: string
  IDLE_PAUSED?: boolean
  APP_PROFILE?: string
  SGCM_ENDPOINT_ACTIVE?: any
  LOGGED_IN_USERNAME?: any
  DISPLAY_NAME?: string
  AWS_INFO?: AwsInfo
  USER_ID?: User
  ACTIVE_WORKSTATION_LOG?: ActiveWorkstationLog
  DEFAULT_ASSIGNED_USER?: string
  RUNNING_AT?: Date
  IDLE_TIME?: Date
  RUNTIME_ASSIGNED_ORG?: Organization
  workstation_schedule_log?: WorkstationScheduleLog[]
  retryLockSince?: string | Date | number

  CAST_OPERATOR?: CastOperator

  selectedLocation?: string
}

export interface UserWorkstation {
  _id: ObjectId
  user: Partial<User>
  workstation: Partial<Workstation>
  favorite: boolean
  nickname?: string
}

export type StorageType = 'SMB' | 'LUCIDLINK' | 'JUICE_FS'
export interface BebopStorage extends BebopEntity {
  bebopUploaderProtocol?: string
  bebopUploaderEndpoint?: string
  numberOfTransferServers?: number
  type?: StorageType
  rocket?: RocketOverrideOptions

  asperaServerEndpoint?: string
  directories?: {
    aspera?: string
    projects?: string
  }
  transferServerMount?: string
  txChannelDNS?: {
    SYNC?: string
    CLIENT?: string
    DL?: string
  }
  storageSize?: {
    allocated?: number
    current?: number
    percentUsed?: number
    storageWarningSent?: Date | string
  }

  mountPermissions?: {
    readOnly?: boolean
    readonlyCreds?: {
      username: string
      password: string
    }
  }

  noParallelServerUpload?: boolean
}

export enum UserLevelPermission {
  ACCESS_BEBOP_LINKS_VIA_CLIENT = 'ACCESS_BEBOP_LINKS_VIA_CLIENT',
}

export interface Entitlement {
  ACCESS_APP_LIBRARY?: boolean
  ACCESS_BEBOP_CREDENTIALS?: boolean
  ACCESS_BEBOP_LINKS?: boolean
  ACCESS_BEBOP_LIVEROOM_LINK?: boolean
  ACCESS_BEBOP_PROJECTS?: boolean
  ACCESS_BEBOP_QIK_NOTES_LINK?: boolean
  ACCESS_BEBOP_RECEIVE_LINK?: boolean
  ACCESS_BEBOP_REVPLAY_LINK?: boolean
  ACCESS_BEBOP_SEND_LINK?: boolean
  ACCESS_CAST_OPERATORS?: boolean
  ACCESS_DESKTOP?: boolean
  ACCESS_OTS?: boolean
  ACCESS_REV_TOOLS?: boolean
  ACCESS_BEBOP_FLEX_LINKS?: boolean
  ACCESS_BEBOP_FLEX_PROJECTS?: boolean
  ADMIN_ORGANIZATION?: boolean
  BROWSER_FILE_DOWNLOAD?: boolean
  BROWSER_FILE_STREAMING?: boolean
  COMMENT_REV_NOTES?: boolean
  CREATE_BEBOP_CREDENTIALS?: boolean
  CREATE_BEBOP_LIVEROOM_LINK?: boolean
  CREATE_BEBOP_PROJECTS?: boolean
  CREATE_BEBOP_QIK_NOTES_LINK?: boolean
  CREATE_BEBOP_RECEIVE_LINK?: boolean
  CREATE_BEBOP_REVPLAY_LINK?: boolean
  CREATE_BEBOP_SEND_LINK?: boolean
  CREATE_BEBOP_FLEX_LINK?: boolean
  ADD_TO_PRODUCTION_LIBRARY?: boolean
  DOWNLOAD?: boolean
  DOWNLOAD_BEBOP_RECEIVE_LINK?: boolean
  FLEX_CLIENT_MOUNT?: boolean
  ACCESS_FLEX_CLIENT_MOUNT?: boolean
  ACCESS_STORAGE_PROFILE?: boolean
  FLEX_CLIENT_MOUNT_LOCKED?: boolean
  HOTFOLDER_DOWNLOAD?: boolean
  HOTFOLDER_TWO_WAY?: boolean
  HOTFOLDER_UPLOAD?: boolean
  RECEIVE_LINK_ALLOW_UNSAFE?: boolean
  REV_LIVE_MAX_VIEWER?: boolean
  REV_LIVE_START_BROADCAST?: boolean
  REV_NOTES_ALLOW_DOWNLOAD?: boolean
  UPLOAD?: boolean
  UPLOAD_BEBOP_SEND_LINK?: boolean
  VIEW_REV_NOTES?: boolean

  [key: string]: boolean
}

export interface OrgEntitlements {
  [key: ObjectId]: Entitlement
}

export enum EulaRegion {
  US = 'US',
  EU = 'EU',
}

export interface UserPermission {
  links?: boolean
  [key: string]: boolean
}

export enum LinkViewState {
  None,
  Init,
  LinkPassword,
  ShareLink,
  ReceiveLink,
  CastMediaServer,
  QikNotes,
  ProductionLibNotes,
  FlexLink,
}

export enum LinkType {
  None = -1,
  Share = 1 << 0,
  OTS = 1 << 1,
  Review = 1 << 2,
  Receive = 1 << 3,
  LiveRoom = 1 << 4,
  CastMediaServer = 1 << 5,
  QikNotes = 1 << 6,
  ProductionLibNotes = 1 << 7,
  FlexLink = 1 << 8,
}

export const LinkTypeLabel = {
  [LinkType.None]: 'None',
  [LinkType.Receive]: 'Receive',
  [LinkType.OTS]: 'OTS',
  [LinkType.Review]: 'Review',
  [LinkType.Share]: 'Share',
  [LinkType.LiveRoom]: 'LiveRoom',
  [LinkType.CastMediaServer]: 'Cast Media Server',
  [LinkType.QikNotes]: 'QikNotes',
  [LinkType.ProductionLibNotes]: 'Production Lib Notes',
  [LinkType.FlexLink]: 'Flex',
}

export enum TranscodingStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum LinkProtection {
  NONE = 'NONE',
  USER = 'USER',
  PASSWORD = 'PASSWORD',
}

export enum ReviewLinkStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  IN_PROGRESS = 'IN_PROGRESS',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  NOT_APPROVED = 'NOT_APPROVED',
  APPROVED = 'APPROVED',
}

export type DateStr = string
export type UrlStr = string

export interface LinkStats {
  uploads?: {
    total: number
  }
  downloads?: any
  ots?: any
  review?: any
}

export interface LinkSettingsUpload {
  allowedFileTypes?: string[]
  allowUnsafeFiles?: boolean
  dropPath: string
  // this is not for client upload
  // max <= 0  means no restriction
  maxSize?: number
  maxFiles?: number
}

export interface LinkFile {
  name: string
  size: number

  path?: string
  hlsPath?: string
  transcodeStatus?: TranscodingStatus
  metadata?: {
    type: 'audio' | 'video'
    format: string
    formatLevel?: number
    bitRate?: number
    frameRate?: number
    totalFrames?: number
    width?: number
    height?: number
    duration?: number
    scanType?: string
    colorSpace: string
    bitDepth?: number
  }
}

export interface LinkSettingsOTS {}
export interface LinkSettingsReview {
  files: LinkFile[]
  allowDownload?: boolean
}

export interface LinkSettingsDownload {
  files: LinkFile[]
}

export interface LinkSettingsQikNotes {
  files: LinkFile[]
}

export interface LinkSettingsLiveRoom {
  liveLinkUrl: string
}

export interface LinkSettingsFlex {
  readOnly?: boolean
  relativePath?: string
}

export interface LinkSettings {
  // decide which one to keep at the time of implementation
  allowedIPs?: [
    {
      // ip4 or country code
      type: string
    },
  ]
  disallowedIPs?: [
    {
      // ip4 or country code
      type: string
    },
  ]
  notes?: string
  uploads?: LinkSettingsUpload
  downloads?: LinkSettingsDownload
  ots?: LinkSettingsOTS
  review?: LinkSettingsReview
  qikNotes?: LinkSettingsQikNotes
  liveRoom?: LinkSettingsLiveRoom
  flexLink?: LinkSettingsFlex
}

export interface BebopLink {
  _id: ObjectId
  organization?: Organization
  project?: Project
  link?: string
  created_by?: User
  linkType?: LinkType
  name: string
  is_active?: boolean
  date_modified?: DateStr
  date_created?: DateStr
  settings?: LinkSettings
  expires?: Date
  status?: ReviewLinkStatus
  views?: number
  stats?: LinkStats
  password?: string
  protected?: LinkProtection
  fullLink?: UrlStr
  for?: 'all' | 'expiry' | 'password' | 'settings' | 'users'
  organizationId?: ObjectId
  allowedUsers?: User[]

  mountedIndex?: string | number
  mountPoint?: string
  mountedTime?: number
  mountingTime?: number
}

export const CacheSizes = ['MB', 'GB', 'TB']

export type FlexMountActivityType = 'MOUNT REQUEST' | 'MOUNTED' | 'UNMOUNTED' | 'MOUNT FAILED' | 'UNMOUNT FAILED'
export interface FlexMountActivityData {
  [key: string]: any
  user?: string
  reason?: string
  path?: string
  projectId?: string
  locked?: boolean
  readOnly?: boolean
  meta?: Partial<MountOption> & {
    sessionDurationInMillis?: number
    reason?: string
    readOnly?: boolean
    isLink?: boolean
  }
  type?: FlexMountActivityType
}

export interface FlexMountActivity {
  title?: string
  description?: string
  date_created?: Date
  data?: FlexMountActivityData
}

export type UpdateLinkProtectPayload = {
  for: string
  linkId: string
  protected: LinkProtection
  projectId: string
  organizationId: string
  linkType: LinkType
  password?: string
}

export interface IPayloadSettingsLinks {
  for: string
  linkId: string
  projectId: string
  organizationId: string
  name: string
  settings: ISettingsLinks
  linkType: number
}

export interface ISettingsLinks {
  uploads: {
    allowedFileTypes: string[]
    allowUnsafeFiles: boolean
    maxSize: number
    maxFiles: number
    dropPath: string
    maxSizeInGb: number
  }
  downloads: {
    files: any[]
  }
  review: {
    files: any[]
    allowDownload: boolean
  }
  qikNotes: {
    files: any[]
  }
  prdLibNotes: {
    files: any[]
  }
  flexLink: {
    files: any[]
  }
  allowedIPs: any[]
  disallowedIPs: any[]
  notes: string
}

export interface ListUsers {
  data: User[]
  hasMore: boolean
}

export interface User {
  _id: string
  email: string
  name: string
  username: string
  is_active: boolean
  totp2fa: {
    enabled: boolean
  }
  bebop2fa: {
    enabled: boolean
  }
  sms2fa: {
    enabled: boolean
  }
  lockOut: number
  excludeFromUsage: boolean
  date_created: string
  last_login: string
  status: string
  lastSeen: string
}

// some modules still use Project interface, for new modules will use IProject
export type IProject = Project
