<div
  class="bebop-workstation-card"
  [class.not-started]="!isRetryRequested && !isMaintenance && station.state == 'Not Started'"
  [class.maintenance]="!isRetryRequested && isMaintenance"
  [class.started]="!isRetryRequested && !isMaintenance && station.state == 'Started'"
  [class.running]="!isRetryRequested && !isMaintenance && station.state == 'Running'"
  [class.stopping]="!isRetryRequested && !isMaintenance && station.state == 'Stopping'"
  [class.starting]="!isRetryRequested && !isMaintenance && station.state == 'Starting'"
  [class.auto-retry]="isRetryRequested"
  [class.auto-retry-someone]="isRetryRequested && isRetryingBySomeoneElse"
>
  <div class="workstation-banner"></div>

  <div class="workstation-main-area">
    <div class="top-area">
      <svg
        class="fav-icon"
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [class.hidden]="!isFavorite"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 -3C6.29086 -3 4.5 -1.21453 4.5 0.987969V15.0025C4.5 16.6244 6.33874 17.5678 7.66248 16.6251L11.3398 14.0063C12.0342 13.5118 12.9658 13.5118 13.6602 14.0063L17.3375 16.6251C18.6613 17.5678 20.5 16.6244 20.5 15.0025V0.987969C20.5 -1.21453 18.7091 -3 16.5 -3H8.5Z"
          fill="#EA864E"
        />
      </svg>

      <div
        class="turn-off"
        (click)="stopWorkstation($event)"
        *ngIf="!isMaintenance && (station.state == 'Started' || station.state == 'Running')"
      >
        <svg viewBox="0 0 16 16" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.333 8a.667.667 0 0 0 1.334 0V3.333a.667.667 0 0 0-1.334 0V8ZM4.717 6.38a.667.667 0 1 0-1.094-.763 5.333 5.333 0 1 0 8.754 0 .667.667 0 0 0-1.093.763 4 4 0 1 1-6.567 0Z"
            fill="#F1344B"
          />
        </svg>
      </div>
    </div>
    <div class="label-area">
      <div class="h2 label-name" [title]="station.name">
        {{ station.name }}
      </div>
      <div class="small label-nickname" [title]="station.nickname || ''">
        {{ station.nickname }}
      </div>
    </div>
    <div class="middle-area">
      <div class="middle-label-area">
        <ng-container *ngIf="isRetryRequested">
          <ng-container *ngIf="!isRetryingBySomeoneElse">
            <div class="usage-label small">
              Trying to relaunch
              <svg
                width="12"
                height="12"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="retry-log-icon"
                (click)="showRetryWorkstationLog()"
              >
                <path
                  d="M14 2.66667C14 2.29849 13.7015 2.00001 13.3333 2.00001L9.33333 2C8.96514 2 8.66667 2.29848 8.66667 2.66667C8.66667 3.03486 8.96514 3.33333 9.33333 3.33333L11.7333 3.33334L7.60506 7.46159C7.34471 7.72194 7.34471 8.14405 7.60506 8.4044C7.86541 8.66475 8.28752 8.66475 8.54787 8.4044L12.6683 4.28398L12.6723 6.59967C12.6723 6.96786 12.9707 7.26633 13.3389 7.26633C13.7071 7.26634 14.0056 6.96786 14.0056 6.59967L14 2.66667Z"
                  fill="#41B1F0"
                />
                <path
                  d="M2 4.66667V11.3333C2 12.8061 3.19391 14 4.66667 14H11.3333C12.8061 14 14 12.8061 14 11.3333V10C14 9.63181 13.7015 9.33333 13.3333 9.33333C12.9651 9.33333 12.6667 9.63181 12.6667 10V11.3333C12.6667 12.0697 12.0697 12.6667 11.3333 12.6667H4.66667C3.93029 12.6667 3.33333 12.0697 3.33333 11.3333V4.66667C3.33333 3.93029 3.93029 3.33333 4.66667 3.33333H6C6.36819 3.33333 6.66667 3.03486 6.66667 2.66667C6.66667 2.29848 6.36819 2 6 2H4.66667C3.19391 2 2 3.19391 2 4.66667Z"
                  fill="#41B1F0"
                />
              </svg>
            </div>
            <div class="user-name h5">
              <div class="time-capsule">
                <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.28563 3.28587C4.49133 2.08017 6.15891 1.33325 7.99967 1.33325C9.84044 1.33325 11.508 2.08017 12.7137 3.28587C13.9194 4.49157 14.6663 6.15915 14.6663 7.99992C14.6663 9.84068 13.9194 11.5083 12.7137 12.714C11.508 13.9197 9.84044 14.6666 7.99967 14.6666C6.15891 14.6666 4.49133 13.9197 3.28563 12.714C2.07993 11.5083 1.33301 9.84068 1.33301 7.99992C1.33301 6.15915 2.07993 4.49157 3.28563 3.28587ZM7.99967 2.66659C6.52673 2.66659 5.19431 3.26281 4.22844 4.22868C3.26256 5.19456 2.66634 6.52698 2.66634 7.99992C2.66634 9.47286 3.26256 10.8053 4.22844 11.7712C5.19431 12.737 6.52673 13.3333 7.99967 13.3333C9.47262 13.3333 10.805 12.737 11.7709 11.7712C12.7368 10.8053 13.333 9.47286 13.333 7.99992C13.333 6.52698 12.7368 5.19456 11.7709 4.22868C10.805 3.26281 9.47262 2.66659 7.99967 2.66659ZM7.99967 4.66659C8.36786 4.66659 8.66634 4.96506 8.66634 5.33325V7.72378L9.80441 8.86185C10.0648 9.1222 10.0648 9.54431 9.80441 9.80466C9.54406 10.065 9.12195 10.065 8.8616 9.80466L7.52827 8.47132C7.46435 8.40741 7.41613 8.33374 7.38359 8.25511C7.35124 8.17708 7.33327 8.09158 7.33301 8.00192C7.33301 8.00125 7.33301 8.00059 7.33301 7.99992V7.99946V5.33325C7.33301 4.96506 7.63148 4.66659 7.99967 4.66659Z"
                    fill="#7F7F8C"
                  />
                </svg>
                <div class="time-label description">{{ retryTimeLeft }}</div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="isRetryingBySomeoneElse">
            <div class="usage-label small">
              {{ powerCodeLabel }}
            </div>
            <div class="user-name h5" *ngIf="assignedUser">
              {{ assignedUser }}
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isRetryRequested">
          <div class="usage-label small">
            {{ !isMaintenance ? powerCodeLabel : 'In Maintenance' }}
          </div>
          <div
            class="user-name h5"
            [title]="!isMaintenance ? assignedUser : ''"
            *ngIf="!isMaintenance ? assignedUser : ''"
          >
            {{ !isMaintenance ? assignedUser : '' }}
          </div>
        </ng-container>
      </div>
      <div class="middle-action-area">
        <ng-container *ngIf="isRetryRequested">
          <ng-container *ngIf="!isRetryingBySomeoneElse">
            <div class="action-button">
              <bebop-button outlined="true" (on-click)="dismissRetryWorkstation()" class="dismiss"
                >Dismiss</bebop-button
              >
            </div>
          </ng-container>

          <ng-container *ngIf="isRetryingBySomeoneElse">
            <!-- retry view -->
            <div class="action-label small">
              {{ powerCodeDescription }}
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isRetryRequested">
          <div class="action-button" *ngIf="!isMaintenance">
            <ng-container *ngIf="station.state == 'Not Started'">
              <bebop-button outlined="true" (on-click)="startWorkstation($event)">Start Now</bebop-button>
            </ng-container>
            <ng-container *ngIf="station.state != 'Not Started' && station.state != 'Running'">
              <bebop-button outlined="true" icon-size="small">
                <bebop-apple-spinner size="small"> </bebop-apple-spinner>
              </bebop-button>
            </ng-container>
            <ng-container *ngIf="station.state == 'Running'">
              <bebop-button outlined="true" icon-size="small" *ngIf="isLaunching">
                <bebop-apple-spinner size="small"> </bebop-apple-spinner>
              </bebop-button>
              <bebop-button outlined="true" (on-click)="launchWorkstation($event)" *ngIf="!isLaunching"
                >Launch</bebop-button
              >
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="bottom-area">
      <!-- <div class="divider" *ngIf="isReadyToLaunch && (station.badges?.length || station.broadcast)"></div> -->
      <div class="divider" *ngIf="station.badges?.length || station.broadcast"></div>
      <div class="badges">
        <div
          class="broadcast"
          *ngIf="(station.state == 'Started' || station.state == 'Running') && station.broadcast && !isMaintenance"
          (click)="onClickBroadcast()"
        >
          <svg viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.334 11.333a2 2 0 0 1-2 2H10a.667.667 0 1 0 0 1.334h1.334a3.333 3.333 0 0 0 3.333-3.334V4.667a3.333 3.333 0 0 0-3.333-3.334H4.667a3.333 3.333 0 0 0-3.334 3.334v.666a.667.667 0 1 0 1.334 0v-.666a2 2 0 0 1 2-2h6.667a2 2 0 0 1 2 2v6.666ZM2 14.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM4 14c0-1.245-.98-2-2-2a.667.667 0 0 1 0-1.333c1.646 0 3.333 1.245 3.333 3.333A.667.667 0 1 1 4 14ZM2 9c2.95 0 4.989 2.543 4.724 4.878a.667.667 0 0 0 1.324.15C8.407 10.875 5.717 7.667 2 7.667A.667.667 0 1 0 2 9Z"
              fill="#32BA78"
            />
          </svg>
        </div>

        <div class="badge" *ngFor="let badge of station.badges; let index" (click)="onSelectBadge(badge, $event)">
          <bebop-tag
            [color]="station.state != 'Running' || isMaintenance ? 'var(--text-primary)' : 'white'"
            [bg-color]="getBgColor(badge)"
            [label]="true"
          >
            {{ badge }}
          </bebop-tag>
        </div>

        <div class="more-actions">
          <div class="action-wrapper" [class.inactive]="!showMoreActionDropdown">
            <bebop-button
              size="xsmall"
              icon-size="small"
              outlined="true"
              [bebopDropdownTriggerFor]="dropdown"
              placement="bottom"
              placement-overlay-x-position="end"
              placement-origin-x-position="end"
              placement-tooltip-position="right"
              [placement-tooltip-offset]="12"
              [placement-tooltip-offset2]="10"
              (bebopClickOutside)="onClickOutside()"
              (on-click)="onClickMoreActionDropdown()"
              class="action-dropdown"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="action-icon"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.33329 3.33333C9.33329 2.59695 8.73634 2 7.99996 2C7.26358 2 6.66663 2.59695 6.66663 3.33333C6.66663 4.06971 7.26358 4.66667 7.99996 4.66667C8.73634 4.66667 9.33329 4.06971 9.33329 3.33333ZM7.99996 6.66667C8.73634 6.66667 9.33329 7.26362 9.33329 8C9.33329 8.73638 8.73634 9.33333 7.99996 9.33333C7.26358 9.33333 6.66663 8.73638 6.66663 8C6.66663 7.26362 7.26358 6.66667 7.99996 6.66667ZM7.99996 11.3333C8.73634 11.3333 9.33329 11.9303 9.33329 12.6667C9.33329 13.403 8.73634 14 7.99996 14C7.26358 14 6.66663 13.403 6.66663 12.6667C6.66663 11.9303 7.26358 11.3333 7.99996 11.3333Z"
                  fill="white"
                  fill-opacity="0.6"
                />
              </svg>
            </bebop-button>
          </div>

          <bebop-dropdown #dropdown [panelClasses]="['ws-card-action-dd']">
            <div (click)="addToFavorites()" class="action-item" *ngIf="!isFavorite">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.66675 3.99992C2.66675 2.52716 3.86066 1.33325 5.33341 1.33325H10.6667C12.1395 1.33325 13.3334 2.52716 13.3334 3.99992V13.3711C13.3334 14.4556 12.1076 15.0865 11.2251 14.4561L8.00008 12.1525L4.77507 14.4561C3.89257 15.0865 2.66675 14.4556 2.66675 13.3711V3.99992ZM5.33341 2.66659C4.59703 2.66659 4.00008 3.26354 4.00008 3.99992V13.3711L7.2251 11.0675C7.68869 10.7364 8.31147 10.7364 8.77507 11.0675L12.0001 13.3711V3.99992C12.0001 3.26354 11.4031 2.66659 10.6667 2.66659H5.33341Z"
                  fill="#7F7F8C"
                />
              </svg>

              <div class="paragraph action-item-label">Add to Favorite</div>
            </div>

            <div (click)="removeFromFavorites()" class="action-item" *ngIf="isFavorite">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.33317 1.33325C3.86041 1.33325 2.6665 2.52357 2.6665 3.9919V13.3349C2.6665 14.4162 3.89233 15.0451 4.77482 14.4166L7.2264 12.6708C7.68933 12.3411 8.31035 12.3411 8.77328 12.6708L11.2249 14.4166C12.1073 15.0451 13.3332 14.4162 13.3332 13.3349V3.9919C13.3332 2.52357 12.1393 1.33325 10.6665 1.33325H5.33317Z"
                  fill="#7F7F8C"
                />
              </svg>

              <div class="paragraph action-item-label">Remove from Favorite</div>
            </div>

            <div (click)="editWorkstationNickname($event)" class="action-item">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0118 3.33333C11.8381 3.33333 11.6715 3.40233 11.5487 3.52516L10.3417 4.73215L11.2679 5.65835L12.4749 4.45136C12.5357 4.39055 12.5839 4.31835 12.6168 4.23889C12.6497 4.15943 12.6667 4.07427 12.6667 3.98826C12.6667 3.90225 12.6497 3.81709 12.6168 3.73763C12.5839 3.65817 12.5357 3.58597 12.4749 3.52516C12.414 3.46434 12.3418 3.4161 12.2624 3.38319C12.1829 3.35027 12.0978 3.33333 12.0118 3.33333ZM4.28338 10.7904L9.39885 5.67496L10.3251 6.60116L5.20958 11.7166C4.95326 11.973 4.6321 12.1548 4.28044 12.2427L3.58293 12.4171L3.7573 11.7196C3.84522 11.3679 4.02706 11.0468 4.28338 10.7904ZM10.6058 2.58235C10.9787 2.20948 11.4844 2 12.0118 2C12.2729 2 12.5314 2.05143 12.7726 2.15135C13.0139 2.25127 13.233 2.39772 13.4177 2.58235C13.6023 2.76697 13.7487 2.98616 13.8487 3.22739C13.9486 3.46861 14 3.72716 14 3.98826C14 4.24936 13.9486 4.50791 13.8487 4.74913C13.7487 4.99036 13.6023 5.20955 13.4177 5.39417L6.15239 12.6594C5.7252 13.0866 5.18993 13.3897 4.60382 13.5362L2.82837 13.9801C2.60119 14.0369 2.36086 13.9703 2.19528 13.8047C2.02969 13.6392 1.96312 13.3988 2.01992 13.1716L2.46378 11.3962C2.61031 10.8101 2.91337 10.2748 3.34057 9.84762L10.6058 2.58235ZM8.66667 12.6667C8.29848 12.6667 8 12.9651 8 13.3333C8 13.7015 8.29848 14 8.66667 14H13.3333C13.7015 14 14 13.7015 14 13.3333C14 12.9651 13.7015 12.6667 13.3333 12.6667H8.66667Z"
                  fill="#5A5A68"
                />
              </svg>

              <div class="paragraph action-item-label">Edit Workstation Nickname</div>
            </div>

            <div (click)="terminateWorkstation($event)" class="action-item" *ngIf="isTerminateAllowed">
              <svg class="ico" viewBox="0 0 24 24">
                <use xlink:href="app/common/assets/icons/_sprite.svg#Delete"></use>
              </svg>

              <div class="paragraph action-item-label">Terminate Workstation</div>
            </div>
          </bebop-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>
