<div class="bebop-retry-workstation-log">
  <div class="modal-header">
    <div class="modal-title h2">Log of Retries</div>
    <div class="header-cancel-btn">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="cancel()">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="#0C0C0F"
          fill-opacity="0.32"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5283 7.52851C15.7886 7.26816 16.2107 7.26816 16.4711 7.52851C16.7314 7.78886 16.7314 8.21097 16.4711 8.47132L12.9425 11.9999L16.4711 15.5285C16.7314 15.7889 16.7314 16.211 16.4711 16.4713C16.2107 16.7317 15.7886 16.7317 15.5283 16.4713L11.9997 12.9427L8.47108 16.4713C8.21073 16.7317 7.78862 16.7317 7.52827 16.4713C7.26792 16.211 7.26792 15.7889 7.52827 15.5285L11.0569 11.9999L7.52827 8.47132C7.26792 8.21097 7.26792 7.78886 7.52827 7.52851C7.78862 7.26816 8.21073 7.26816 8.47108 7.52851L11.9997 11.0571L15.5283 7.52851Z"
          fill="white"
          fill-opacity="0.8"
        />
      </svg>
    </div>
  </div>
  <div class="modal-content">
    <div class="main-section">
      <div class="retry-log">
        <div class="ws-title">
          <div class="ws-label h3-lite">Workstation:</div>
          <div class="ws-name h3">{{ instance?.workstation?.DISPLAY_NAME || instance?.workstation?.NAME || '…' }}</div>
        </div>

        <div class="ws-mins">
          <div class="ws-min-label">
            <div class="ws-label paragraph">Retry to launch workstation for:</div>
            <div class="ws-name h5" *ngIf="!addingTime">{{ instance?.durationInMinutes ?? 0 }} minutes</div>
            <div class="ws-name h5" *ngIf="addingTime">…</div>
          </div>
          <div class="ws-min-action">
            <ng-container *ngIf="!addingTime">
              <div class="progress-retry" [style]="{ width: '281px', height: '6px' }">
                <bebop-progress-bar [progress]="progress" [type]="'active'"></bebop-progress-bar>
              </div>

              <div class="time-capsule">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.28563 3.28587C4.49133 2.08017 6.15891 1.33325 7.99967 1.33325C9.84044 1.33325 11.508 2.08017 12.7137 3.28587C13.9194 4.49157 14.6663 6.15915 14.6663 7.99992C14.6663 9.84068 13.9194 11.5083 12.7137 12.714C11.508 13.9197 9.84044 14.6666 7.99967 14.6666C6.15891 14.6666 4.49133 13.9197 3.28563 12.714C2.07993 11.5083 1.33301 9.84068 1.33301 7.99992C1.33301 6.15915 2.07993 4.49157 3.28563 3.28587ZM7.99967 2.66659C6.52673 2.66659 5.19431 3.26281 4.22844 4.22868C3.26256 5.19456 2.66634 6.52698 2.66634 7.99992C2.66634 9.47286 3.26256 10.8053 4.22844 11.7712C5.19431 12.737 6.52673 13.3333 7.99967 13.3333C9.47262 13.3333 10.805 12.737 11.7709 11.7712C12.7368 10.8053 13.333 9.47286 13.333 7.99992C13.333 6.52698 12.7368 5.19456 11.7709 4.22868C10.805 3.26281 9.47262 2.66659 7.99967 2.66659ZM7.99967 4.66659C8.36786 4.66659 8.66634 4.96506 8.66634 5.33325V7.72378L9.80441 8.86185C10.0648 9.1222 10.0648 9.54431 9.80441 9.80466C9.54406 10.065 9.12195 10.065 8.8616 9.80466L7.52827 8.47132C7.46435 8.40741 7.41613 8.33374 7.38359 8.25511C7.35124 8.17708 7.33327 8.09158 7.33301 8.00192C7.33301 8.00125 7.33301 8.00059 7.33301 7.99992V7.99946V5.33325C7.33301 4.96506 7.63148 4.66659 7.99967 4.66659Z"
                    fill="#7F7F8C"
                  />
                </svg>
                <div class="time-label small">{{ timeLabel }}</div>
              </div>
              <div class="vdivider"></div>

              <div class="ws-min-add-btn">
                <bebop-link-button underline="false" (on-click)="addTime()">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.66667 2.66667C8.66667 2.29848 8.36819 2 8 2C7.63181 2 7.33333 2.29848 7.33333 2.66667L7.33333 7.33333H2.66667C2.29848 7.33333 2 7.63181 2 8C2 8.36819 2.29848 8.66667 2.66667 8.66667H7.33333V13.3333C7.33333 13.7015 7.63181 14 8 14C8.36819 14 8.66667 13.7015 8.66667 13.3333V8.66667H13.3333C13.7015 8.66667 14 8.36819 14 8C14 7.63181 13.7015 7.33333 13.3333 7.33333H8.66667V2.66667Z"
                      fill="#4574F5"
                    />
                  </svg>
                  Add Time
                </bebop-link-button>
              </div>
            </ng-container>

            <ng-container *ngIf="addingTime">
              <div class="dropdown-retry">
                <bebop-input-select
                  class="input-select-retry-interval"
                  placeholder="Select retry interval…"
                  [value]="retryInterval?.name"
                  selectOnly="true"
                >
                  <div dropdown-icon>
                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.10728 4.10752C5.6144 2.6004 7.69888 1.66675 9.99984 1.66675C12.3008 1.66675 14.3853 2.6004 15.8924 4.10752C17.3995 5.61465 18.3332 7.69913 18.3332 10.0001C18.3332 12.301 17.3995 14.3855 15.8924 15.8926C14.3853 17.3998 12.3008 18.3334 9.99984 18.3334C7.69888 18.3334 5.6144 17.3998 4.10728 15.8926C2.60016 14.3855 1.6665 12.301 1.6665 10.0001C1.6665 7.69913 2.60016 5.61465 4.10728 4.10752ZM9.99984 3.33341C8.15866 3.33341 6.49313 4.07869 5.28579 5.28604C4.07845 6.49338 3.33317 8.1589 3.33317 10.0001C3.33317 11.8413 4.07845 13.5068 5.28579 14.7141C6.49313 15.9215 8.15866 16.6667 9.99984 16.6667C11.841 16.6667 13.5065 15.9215 14.7139 14.7141C15.9212 13.5068 16.6665 11.8413 16.6665 10.0001C16.6665 8.1589 15.9212 6.49338 14.7139 5.28604C13.5065 4.07869 11.841 3.33341 9.99984 3.33341ZM9.99984 5.83342C10.4601 5.83342 10.8332 6.20651 10.8332 6.66675V9.6549L12.2558 11.0775C12.5812 11.4029 12.5812 11.9306 12.2558 12.256C11.9303 12.5814 11.4027 12.5814 11.0772 12.256L9.41058 10.5893C9.33068 10.5094 9.2704 10.4174 9.22974 10.3191C9.18929 10.2215 9.16684 10.1147 9.16651 10.0026C9.16651 10.0017 9.1665 10.0009 9.1665 10.0001V9.99951V6.66675C9.1665 6.20651 9.5396 5.83342 9.99984 5.83342Z"
                        fill="#5A5A68"
                      />
                    </svg>
                  </div>
                  <div dropdown-options>
                    <bebop-input-select-option
                      *ngFor="let interval of retryIntervals"
                      (click)="updateRetryInterval(interval)"
                    >
                      <div option-text>
                        {{ interval?.name }}
                      </div>
                    </bebop-input-select-option>
                  </div>
                </bebop-input-select>
              </div>

              <div class="dropdown-action">
                <div class="save-action small" (click)="saveTime()">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.106 4.16496C13.3831 4.40741 13.4112 4.82859 13.1687 5.10568L7.33539 11.7723C7.09383 12.0484 6.67462 12.0775 6.3973 11.8374L2.8973 8.80705C2.61894 8.56605 2.58866 8.14502 2.82966 7.86667C3.07066 7.58831 3.49168 7.55803 3.77004 7.79903L6.76866 10.3952L12.1653 4.22767C12.4077 3.95058 12.8289 3.9225 13.106 4.16496Z"
                      fill="#32BA78"
                      fill-opacity="0.8"
                    />
                  </svg>
                  Save
                </div>
                <div class="vdivider"></div>
                <div class="cancel-action small" (click)="cancelTime()">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.5283 3.52851C11.7886 3.26816 12.2107 3.26816 12.4711 3.52851C12.7314 3.78886 12.7314 4.21097 12.4711 4.47132L8.94248 7.99992L12.4711 11.5285C12.7314 11.7889 12.7314 12.211 12.4711 12.4713C12.2107 12.7317 11.7886 12.7317 11.5283 12.4713L7.99968 8.94273L4.47108 12.4713C4.21073 12.7317 3.78862 12.7317 3.52827 12.4713C3.26792 12.211 3.26792 11.7889 3.52827 11.5285L7.05687 7.99992L3.52827 4.47132C3.26792 4.21097 3.26792 3.78886 3.52827 3.52851C3.78862 3.26816 4.21073 3.26816 4.47108 3.52851L7.99967 7.05711L11.5283 3.52851Z"
                      fill="#7F7F8C"
                    />
                  </svg>
                  Cancel
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="divider"></div>

        <ng-container *ngTemplateOutlet="logEntries"></ng-container>
      </div>
    </div>
    <div class="divider"></div>
    <div class="action-section">
      <bebop-button size="large" outlined="true" (on-click)="dismiss()">Dismiss Relaunch</bebop-button>
      <bebop-button size="large" (on-click)="keepRetrying()">Keep Trying</bebop-button>
    </div>
  </div>
</div>

<ng-template #logEntries>
  <div class="log-entries">
    <div class="log-entry" *ngFor="let log of logRetryEntries" [class.incomplete]="log.incomplete">
      <div class="entry-date paragraph">{{ log?.logDate || '' }}</div>
      <div class="entry-time">
        <div class="time-capsule">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.28563 3.28587C4.49133 2.08017 6.15891 1.33325 7.99967 1.33325C9.84044 1.33325 11.508 2.08017 12.7137 3.28587C13.9194 4.49157 14.6663 6.15915 14.6663 7.99992C14.6663 9.84068 13.9194 11.5083 12.7137 12.714C11.508 13.9197 9.84044 14.6666 7.99967 14.6666C6.15891 14.6666 4.49133 13.9197 3.28563 12.714C2.07993 11.5083 1.33301 9.84068 1.33301 7.99992C1.33301 6.15915 2.07993 4.49157 3.28563 3.28587ZM7.99967 2.66659C6.52673 2.66659 5.19431 3.26281 4.22844 4.22868C3.26256 5.19456 2.66634 6.52698 2.66634 7.99992C2.66634 9.47286 3.26256 10.8053 4.22844 11.7712C5.19431 12.737 6.52673 13.3333 7.99967 13.3333C9.47262 13.3333 10.805 12.737 11.7709 11.7712C12.7368 10.8053 13.333 9.47286 13.333 7.99992C13.333 6.52698 12.7368 5.19456 11.7709 4.22868C10.805 3.26281 9.47262 2.66659 7.99967 2.66659ZM7.99967 4.66659C8.36786 4.66659 8.66634 4.96506 8.66634 5.33325V7.72378L9.80441 8.86185C10.0648 9.1222 10.0648 9.54431 9.80441 9.80466C9.54406 10.065 9.12195 10.065 8.8616 9.80466L7.52827 8.47132C7.46435 8.40741 7.41613 8.33374 7.38359 8.25511C7.35124 8.17708 7.33327 8.09158 7.33301 8.00192C7.33301 8.00125 7.33301 8.00059 7.33301 7.99992V7.99946V5.33325C7.33301 4.96506 7.63148 4.66659 7.99967 4.66659Z"
              fill="#7F7F8C"
            />
          </svg>
          <div class="time-label small">{{ log?.logTime }}</div>
        </div>
      </div>
      <div class="entry-status">
        <div
          class="entry-badge small"
          *ngIf="log.error"
          [bebop-tooltip]="log.errorMessage"
          bebop-tooltip-placement="left"
          bebop-tooltip-type="error"
          bebop-tooltip-contained-layout="true"
          bebop-tooltip-width="200"
          bebop-tooltip-bg-border-color="rgba(255, 255, 255, 0.08)"
          bebop-tooltip-bg-color="#0C0C0F"
        >
          Failed
        </div>
      </div>
    </div>
  </div>
</ng-template>
