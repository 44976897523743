import { Component, OnInit } from '@angular/core'
import { ElectronService } from 'src/app/services/electron.service'

@Component({
  selector: 'bebop-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent implements OnInit {
  // title = 'Bebop Client'
  isMaximized = false
  constructor(private electronService: ElectronService) {}

  get title() {
    return this.electronService.getAppName()
  }

  ngOnInit(): void {
    // this.title = this.electronService.getAppName()
  }

  minimize() {
    this.electronService.minimize()
  }

  maximize() {
    this.isMaximized = !this.isMaximized
    this.electronService.maximize()
  }

  closeWindow() {
    this.electronService.closeWindow()
  }

  get isWin() {
    return this.electronService.isWindows
  }
}
