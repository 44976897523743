const mcpClientTransmitPass = 'batman0nTh3r0cks!'
export const config = {
  cree8beta: {
    devToolsSecret: 'OpenDevTools!1',
    mcpClientTransmitPass: `${mcpClientTransmitPass}`,
    rootUser: 'batman',
    serverUrl: 'https://api.cree8.io',
    storeUrl: 'https://store.cree8.io',
    supportUrl: 'https://support.cree8.io',
    type: 'beta',
  },
  cree8dev: {
    devToolsSecret: 'OpenDevTools!1',
    mcpClientTransmitPass: `${mcpClientTransmitPass}`,
    rootUser: 'batman',
    serverUrl: 'https://api-dev.cree8.io',
    storeUrl: 'https://store-dev.cree8.io',
    supportUrl: 'https://support.cree8.io',
    type: 'dev',
  },
  cree8prod: {
    devToolsSecret: 'OpenDevTools!1',
    mcpClientTransmitPass: `${mcpClientTransmitPass}`,
    rootUser: 'batman',
    serverUrl: 'https://api.cree8.io',
    storeUrl: 'https://store.cree8.io',
    supportUrl: 'https://support.cree8.io',
    type: 'prod',
  },
  local: {
    devToolsSecret: 'OpenDevTools!1',
    mcpClientTransmitPass: `${mcpClientTransmitPass}`,
    rootUser: 'batman',
    serverUrl: 'https://localhost:3008',
    storeUrl: 'https://store-dev.cree8.io',
    supportUrl: 'https://localhost:3008',
    type: 'local',
  },
}
