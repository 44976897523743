import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

import { RemoteNavTree } from 'src/app/components/rocket/common/classes/rocket-types'
import { IProject } from 'src/app/models/bebop.model'
import { IParamsProjects, IProjectDetail } from 'src/app/models/projects.model'

export interface ProjectsState {
  listProjects: IProjectDetail[]
  hasMore: boolean
  queryParamsList: IParamsProjects
  selectedProject: IProject | null
  membersProps: {
    load: boolean
    search: string
    sort: '-date_created' | 'date_created' | 'name' | '-name'
  }
  filesProps: {
    navTree: RemoteNavTree[]
  }
}

export function createInitialState(): ProjectsState {
  return {
    filesProps: {
      navTree: [],
    },
    hasMore: false,
    listProjects: [],
    membersProps: {
      load: false,
      search: '',
      sort: '-date_created',
    },
    queryParamsList: {
      page: 1,
      searchText: '',
      size: 25,
      sort: '-date_created',
      user: '',
    },
    selectedProject: null,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'project-store', resettable: true })
export class ProjectsStore extends Store<ProjectsState> {
  constructor() {
    super(createInitialState())
  }
}
