<div class="bebop-client-update-install">
  <div class="workstation-banner bebop"></div>
  <div class="modal-content">
    <div class="modal-icon">
      <img src="/assets/img/updater/updating.gif" />
    </div>

    <div class="h2 message" *ngIf="!patch">Download complete. You can continue to use the app, the update will be applied when you restart.</div>
    <div class="h2 message" *ngIf="patch">New patch version available!</div>

    <div class="action-section">
      <bebop-button size="large" (on-click)="install()"> {{ patch ? 'Apply Now' : 'Ok' }} </bebop-button>
    </div>
  </div>
</div>
