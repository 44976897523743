<div class="activity-section">
  <div class="recent-activity">
    <div class="h4">Recent activities </div>
    <!-- <a href="#" class="activity-all" (click)="seeAllActivities()">See all</a> -->
  </div>

  <div class="activities">
    <table st-table="rowCollection" class="cree8-table" st-pipe="loadGridDataValues" st-table="mc.items">
      <thead>
        <tr>
          <th>Member</th>
          <th st-sort="title">Activity</th>
          <th>Project</th>
          <th width="22%">Time</th>
        </tr>
      </thead>
      <tbody class="scrollbar">
        <tr *ngFor="let activity of activities; let i = index">
          <td class="td-name">
            <img [src]="getUserImage(activity)" alt="{{ activity?.created_by?.name }}" width="32">
            {{ activity?.created_by?.name || 'System' }}
          </td>
          <td>{{ activity.description }}</td>
          <td>{{ activity.data.projectName }}</td>
          <td>{{ activity.date_created | date:'dd-MMM-yyyy hh:mm:ss a' }}</td>
        </tr>
        <tr *ngIf="noActivities">
          <td colspan="4">
            <no-data [title]="'it\'s a bit quiet here...'" [icon]="'Quite'"
              [description]="'Your team\'s activities will show up here once they begin. Let\'s start collaborating!'">
            </no-data>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
