<div class="cree8-theme-toggle" (click)="toggleTheme()">
  <span *ngIf="showLabel">{{ getThemeText() }}</span>
  <div class="toggle-switch" [class.dark]="isDarkMode" [class.light]="!isDarkMode">
    <div class="icon">
      <svg *ngIf="isDarkMode" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Moon"></use>
      </svg>
      <svg *ngIf="!isDarkMode" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Sun"></use>
      </svg>
    </div>
  </div>
</div>
