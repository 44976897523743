/* eslint-disable @stylistic/js/max-len */
import { ScrollingModule } from '@angular/cdk/scrolling'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { NgxEchartsModule } from 'ngx-echarts'
import { ISlimScrollOptions, NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll'

import { AppLayoutComponent } from './components/app-layout/app-layout.component'
import { BroadcastsComponent } from './components/broadcasts/broadcasts.component'
import { BestPracticeBroadcastComponent } from './components/broadcasts/modals/best-practice/best-practice-broadcast.component'
import { InfoBroadcastComponent } from './components/broadcasts/modals/info-broadcast/info-broadcast.component'
import {
  ActivitiesComponent as DashboardActivitiesComponent,
  CardComponent as DashboardCardComponent,
  DashboardComponent,
  EarthDashboardComponent,
  NewOrUpdateBroadcastModalComponent,
  ProjectsComponent as DashboardProjectsComponent,
  TeamMembersComponent as DashboardTeamMembersComponent,
  WorkStationsComponent as DashboardWorkStationsComponent,
} from './components/dashboard'
import { ProfileComponent } from './components/dropdown/profile/profile.component'
import { HeaderComponent } from './components/header/header.component'
import { LayoutComponent } from './components/layout/layout.component'
import { EulaModalComponent } from './components/login/modal/eula-modal/eula-modal.component'
import { ReleaseModalComponent } from './components/login/modal/release-modal/release-modal.component'
import { LogoutComponent } from './components/logout/modals/logout/logout.component'
import { LogoutPromptComponent } from './components/logout/modals/logout-prompt/logout-prompt.component'
import { ClientUpdateInstallComponent } from './components/modals/client-update-install/client-update-install.component'
import { OrgSelectionComponent } from './components/org-selection/org-selection.component'
import { ProfileComponent as Cree8ProfileComponent } from './components/profile/profile.component'
import { CardActionsListComponent } from './components/projects/components/card-actions-list/card-actions-list.component'
import { CardFileComponent } from './components/projects/components/card-file/card-file.component'
import { CardProjectsComponent } from './components/projects/components/card-projects/card-projects.component'
import { CardProjectsListComponent } from './components/projects/components/card-projects-list/card-projects-list.component'
import { CreateFolderComponent } from './components/projects/components/create-folder/create-folder.component'
import { ShareLinkComponent } from './components/projects/components/share-link/share-link.component'
import { LinkExpirationComponent } from './components/projects/pages/links-projects/link-expiration/link-expiration.component'
import { LinkProtectComponent } from './components/projects/pages/links-projects/link-protect/link-protect.component'
import { LinkSettingsComponent } from './components/projects/pages/links-projects/link-settings/link-settings.component'
import { LinkStatsComponent } from './components/projects/pages/links-projects/link-stats/link-stats.component'
import { LinksProjectsComponent } from './components/projects/pages/links-projects/links-projects.component'
import { ProjectFilesSettingsComponent } from './components/projects/pages/projects-detail/project-files-settings/project-files-settings.component'
import { ProjectFilesUsersComponent } from './components/projects/pages/projects-detail/project-files-users/project-files-users.component'
import { ProjectFilesViewComponent } from './components/projects/pages/projects-detail/project-files-view/project-files-view.component'
import { ProjectsDetailComponent } from './components/projects/pages/projects-detail/projects-detail.component'
import { AllProjectsComponent } from './components/projects/pages/projects-view/all-projects/all-projects.component'
import { ProjectsViewComponent } from './components/projects/pages/projects-view/projects-view.component'
import { ProjectsComponent } from './components/projects/projects.component'
import { EmptyTabStateComponent } from './components/rocket/common/empty-tab-state/empty-tab-state.component'
import { FileIconComponent } from './components/rocket/common/file-icon/file-icon.component'
import { CancelResumeActionModalComponent } from './components/rocket/common/modals/cancel-resume-action-modal/cancel-resume-action-modal.component'
import { ServerStatusComponent } from './components/rocket/common/server-status/server-status.component'
import { FileDetailsRocketDownloadComponent } from './components/rocket/downloads/modals/file-details-rocket-download/file-details-rocket-download.component'
import { AddProjectModalComponent } from './components/rocket/project/modals/add-project-modal/add-project-modal.component'
import { AppSettingsComponent, OrganizationSettingsComponent, SettingsComponent } from './components/settings'
import { SharedModule } from './components/shared/shared.module'
import { SidebarNavigationComponent } from './components/sidebar-navigation/sidebar-navigation.component'
import {
  InvitesComponent,
  MembersComponent,
  TeamComponent,
  UnassignedComponent,
  UserInviteComponent,
  UserProjectsComponent,
  UserSettingsComponent,
  UserWorkstationsComponent,
} from './components/team'
import { TitleBarComponent } from './components/title-bar/title-bar.component'
import { UpdaterComponent } from './components/updater/updater.component'
import { EditWorkstationNicknameComponent } from './components/workstation/modals/edit-workstation-nickname/edit-workstation-nickname.component'
import { FilterWorkstationComponent } from './components/workstation/modals/filter-workstation/filter-workstation.component'
import { InfoWorkstationComponent } from './components/workstation/modals/info-workstation/info-workstation.component'
import { NoAppWorkstationComponent } from './components/workstation/modals/no-app-workstation/no-app-workstation.component'
import { RetryWorkstationComponent } from './components/workstation/modals/retry-workstation/retry-workstation.component'
import { RetryWorkstationLogComponent } from './components/workstation/modals/retry-workstation-log/retry-workstation-log.component'
import { StartWorkstationComponent } from './components/workstation/modals/start-workstation/start-workstation.component'
import { StopWorkstationComponent } from './components/workstation/modals/stop-workstation/stop-workstation.component'
import { TerminateWorkstationComponent } from './components/workstation/modals/terminate-workstation/terminate-workstation.component'
import { WorkstationCardComponent } from './components/workstation/workstation-card/workstation-card.component'
import { TabWorkstationsComponent } from './components/workstations/tabs/tab-workstations/tab-workstations.component'
import { WorkstationsComponent } from './components/workstations/workstations.component'
import { ElectronService } from './services/electron.service'
import { MainService } from './services/main.service'
import { RecaptchaService } from './services/recaptcha.browser.service'
import { ServicesModule } from './services/services.module'
import { AppComponent } from './app.component'
import { AppCustomElementsModule } from './app-custom-element.module'
import { AppRoutingModule } from './app-routing.module'

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    LayoutComponent,
    AppLayoutComponent,
    UpdaterComponent,
    SidebarNavigationComponent,
    WorkstationCardComponent,
    StopWorkstationComponent,
    StartWorkstationComponent,
    TerminateWorkstationComponent,
    HeaderComponent,
    DashboardComponent,
    ProfileComponent,
    SettingsComponent,
    AppSettingsComponent,
    WorkstationsComponent,
    ProjectsComponent,
    InfoWorkstationComponent,
    FilterWorkstationComponent,
    ServerStatusComponent,
    EmptyTabStateComponent,
    BroadcastsComponent,
    InfoBroadcastComponent,
    EulaModalComponent,
    ReleaseModalComponent,
    EarthDashboardComponent,
    NewOrUpdateBroadcastModalComponent,
    LogoutComponent,
    NoAppWorkstationComponent,
    ClientUpdateInstallComponent,
    CancelResumeActionModalComponent,
    TitleBarComponent,
    BestPracticeBroadcastComponent,
    LogoutPromptComponent,
    RetryWorkstationComponent,
    RetryWorkstationLogComponent,
    TabWorkstationsComponent,
    EditWorkstationNicknameComponent,
    AllProjectsComponent,
    LinksProjectsComponent,
    LinkSettingsComponent,
    ProjectsViewComponent,
    LinkProtectComponent,
    LinkStatsComponent,
    LinkExpirationComponent,
    CardProjectsListComponent,
    CardProjectsComponent,
    DashboardActivitiesComponent,
    DashboardCardComponent,
    DashboardProjectsComponent,
    DashboardTeamMembersComponent,
    DashboardWorkStationsComponent,
    FileDetailsRocketDownloadComponent,
    FileIconComponent,
    AddProjectModalComponent,
    ProjectFilesViewComponent,
    ProjectFilesSettingsComponent,
    ProjectFilesUsersComponent,
    ProjectsDetailComponent,
    TeamComponent,
    InvitesComponent,
    MembersComponent,
    UnassignedComponent,
    UserInviteComponent,
    UserProjectsComponent,
    UserWorkstationsComponent,
    UserSettingsComponent,
    CreateFolderComponent,
    CardFileComponent,
    OrganizationSettingsComponent,
    Cree8ProfileComponent,
    ShareLinkComponent,
    CardActionsListComponent,
    OrgSelectionComponent,
  ],
  imports: [
    BrowserModule,
    AppCustomElementsModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgSlimScrollModule,
    ServicesModule,
    ScrollingModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      // TODO - custom import
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
  ],
  providers: [
    {
      deps: [ElectronService, MainService, RecaptchaService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
    },
    {
      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysPreventDefaultScroll: true,
        alwaysVisible: false,
        barBackground: 'var(--scrollbar-color-0)',
        barBorderRadius: '6',
        barOpacity: '1',
        barWidth: '2',
        gridBackground: 'transparent',
        gridBorderRadius: '6',
        gridMargin: '1',
        gridOpacity: '1',
        gridWidth: '6',
        position: 'right',
        scrollSensitivity: 1,
        visibleTimeout: 1000,
      } as ISlimScrollOptions,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
