/* eslint-disable @stylistic/js/max-len */
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'cree8-projects-card',
  styleUrls: ['./projects.component.scss'],
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit {
  @Input() data: { info: string; available: boolean; desc: string }

  sanitizedIcon: any // Store sanitized SVG

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon)
  }

  onButtonClick = () => {
    this.router.navigate(['app/projects'])
  }

  get icon() {
    return `
        <svg width="150" height="118" viewBox="0 0 150 118" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_15_7176)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.641 33.0857C36.4635 33.0857 33.0769 36.4756 33.0769 40.6571V113.343C33.0769 117.524 36.4635 120.914 40.641 120.914H125.359C129.537 120.914 132.923 117.524 132.923 113.343V57.3143H79.2603C75.2493 57.3141 71.5825 55.0457 69.7888 51.4547L69.1745 50.2249C69.1587 50.1941 69.1432 50.1632 69.1281 50.1321L62.7038 37.2711C61.4225 34.706 58.8033 33.0857 55.9382 33.0857H40.641ZM73.209 37.9856L70.8225 33.2078C68.0036 27.5646 62.2414 24 55.9382 24H40.641C31.4504 24 24 31.4577 24 40.6571V113.343C24 122.542 31.4504 130 40.641 130H125.359C134.55 130 142 122.542 142 113.343V40.6571C142 31.4577 134.55 24 125.359 24H90.4798C84.1767 24 78.4145 27.5646 75.5956 33.2078L73.209 37.9856ZM78.3816 47.9468C78.6334 48.1267 78.9391 48.2285 79.2603 48.2286H132.923V40.6571C132.923 36.4756 129.537 33.0857 125.359 33.0857H90.4798C87.6147 33.0857 84.9955 34.706 83.7142 37.2711L78.3816 47.9468Z" fill="url(#paint0_linear_15_7176)"/>
</g>
<defs>
<linearGradient id="paint0_linear_15_7176" x1="83" y1="24" x2="83" y2="142.114" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_15_7176">
<rect width="150" height="118" rx="10" fill="white"/>
</clipPath>
</defs>
</svg>
`
  }
}
