import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cree8-btn-icon',
  styleUrls: ['./cree8-btn-icon.component.scss'],
  templateUrl: './cree8-btn-icon.component.html',
})
export class Cree8BtnIconComponent implements OnInit {
  constructor() {}

  @Input('disabled') disabled = false
  @Input('size') size: 'small' | 'normal' | 'large' = 'normal'
  @Input() ariaLabel: string | null = null

  @Output() clicked = new EventEmitter<MouseEvent>()

  @HostListener('keydown.enter', ['$event'])
  onKeydown(e: KeyboardEvent) {
    this.handleClick(<MouseEvent>(<unknown>e))
  }

  ngOnInit(): void {}

  get generateClass() {
    return `${this.size}`
  }

  handleClick(e: MouseEvent) {
    if (!this.disabled) {
      this.clicked.emit(e)
    }
  }
}
