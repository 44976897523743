import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import { UiBebopLink } from 'src/app/components/rocket/common/classes/rocket-types'
import { UiProject } from 'src/app/models/ui.model'

export type FlexView = 'Grid' | 'List'
export interface FlexState {
  mounts: (UiProject | UiBebopLink)[]
  selected?: UiProject | UiBebopLink
  panelView?: FlexView
  pinnedView?: FlexView
  hubView?: FlexView
}

export function createInitialState(): FlexState {
  return {
    mounts: [],
    selected: null,
    panelView: 'Grid',
    pinnedView: 'List',
    hubView: 'Grid',
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'flex' })
export class FlexStore extends Store<FlexState> {
  constructor() {
    super(createInitialState())
  }
}
