import { Component, OnDestroy, OnInit } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { Router } from '@angular/router'

import { combineLatest, Subject } from 'rxjs'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { LinkAction } from 'src/app/common/enums'
import { User } from 'src/app/models/bebop.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { MainService } from 'src/app/services/main.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { environment } from 'src/environments/environment'

export type ProfileDropdownAction =
  | 'DownloadClient'
  | 'Help'
  | 'Log Out'
  | 'LookerStudio'
  | 'OrgSelection'
  | 'Organization'
  | 'Profile'
  | 'Refresh'
  | 'Settings'
  | 'Support'

@Component({
  selector: 'bebop-profile',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit, OnDestroy {
  toggleOpen = false
  user: User
  picture: string | SafeUrl = null
  environment = environment
  orgSelectionModal = false
  analyticsEnabled = false
  hasOrgAdminAccess = false
  private destroy$ = new Subject<void>()

  constructor(
    private router: Router,
    private sessionQuery: SessionQuery,
    private config: BebopConfigService,
    private mainService: MainService,
    private sessionService: SessionService,
    private util: BebopClientUtilsService,
    private uiQuery: UIQuery
  ) {}

  ngOnInit(): void {
    this.orgSelectionModal = false

    const user$ = this.sessionQuery.getUser()
    const org$ = this.uiQuery.getSelectedOrg()
    const entitlements$ = this.sessionQuery.getEntitlements()

    combineLatest([user$, org$])
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((prev, curr) => prev[0]?._id === curr[0]?._id && prev[1]?._id === curr[1]?._id)
      )
      .subscribe(([user, org]) => {
        if (!user || !org) return
        this.user = user
        this.picture = this.mainService.getUserImage()
      })

    combineLatest([org$, entitlements$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([org, entitlements]) => {
        this.analyticsEnabled = org?._id ? entitlements?.[org._id]?.ACCESS_LOOKER || false : false
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onToggleProfile() {
    this.toggleOpen = !this.toggleOpen
  }

  onCloseProfileDropdown() {
    this.toggleOpen = false
  }

  openHelpCenter() {
    this.util.openExternalLink(LinkAction.SUPPORT_MAIN, { userId: this.user?._id })
  }

  goToSupportPage() {
    this.util.openExternalLink(LinkAction.SUPPORT_PORTAL)
  }

  async onClickAction(action: ProfileDropdownAction, id?: string) {
    this.toggleOpen = false

    switch (action) {
      case 'LookerStudio':
        if (this.analyticsEnabled) {
          this.router.navigate(['app/analytics'])
        }
        break
      case 'Profile':
        this.router.navigate([`app/profile/${id}`])
        break
      case 'Organization':
        this.router.navigate(['app/org-profile'])
        break
      case 'Settings':
        this.router.navigate(['app/settings'])
        break
      case 'OrgSelection':
        this.orgSelectionModal = true
        break
      case 'DownloadClient':
        this.util.openExternalLink(LinkAction.BBP_CLIENT_DOWNLOAD)
        break
      case 'Log Out': {
        const ok = await this.mainService.promptBeforeTeardown()
        if (!ok) return
        this.sessionService.triggerLogout()
        break
      }
      case 'Support':
        this.goToSupportPage()
        break
      case 'Help':
        this.openHelpCenter()
        break
      case 'Refresh':
        this.mainService.refreshSession()
        break
      default:
        break
    }
  }

  onSelectOrganization(item: any) {
    this.orgSelectionModal = false
  }
}
