import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

import { Entitlement, Organization, Pod, User, UserPermission } from 'src/app/models/bebop.model'

export interface NavPermissions extends UserPermission {
  userDashboard: boolean
  earthDashboard: boolean
  earthMediaServerDashboard: boolean
  desktop: boolean
  projects: boolean
  users: boolean
  cast: boolean
  upload: boolean
  download: boolean
  hotfolder: boolean
  links: boolean // applied from user Permission
  flex: boolean
  settings: boolean
  help: boolean
  //[key: string]: boolean
}

export interface UIState {
  loggedIn: boolean
  currentUser: User
  selectedOrg: Organization
  selectedPod: Pod
  selectedEntitlement: Entitlement
  navPermissions: NavPermissions
  userSettingFirstTimeApply: {
    Pod: boolean
    Organization: boolean
  }
  userPermissions: UserPermission
  loadingPods: boolean
}

export function createInitialState(): UIState {
  return {
    currentUser: null,
    loadingPods: false,
    loggedIn: false,
    navPermissions: {
      cast: false,
      desktop: false,
      download: false,
      earthDashboard: false,
      earthMediaServerDashboard: false,
      flex: false,
      help: true,
      hotfolder: false,
      links: false,
      projects: false,
      settings: true,
      upload: false,
      userDashboard: true,
      users: false,
    },
    selectedEntitlement: null,
    selectedOrg: null,
    selectedPod: null,
    userPermissions: {
      links: false,
    },
    userSettingFirstTimeApply: {
      Organization: true,
      Pod: true,
    },
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui-store' })
export class UIStore extends Store<UIState> {
  constructor() {
    super(createInitialState())
  }
}
