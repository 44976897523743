import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { RadioItem } from 'src/app/common/components/radio-button-group/radio-button-group.component'
import { OnExternalModalClose, BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'
import { EULAInfo, EulaRegion } from 'src/app/models/bebop.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'

export interface EulaModalAction {
  name: 'Cancel' | 'Close' | 'Deny' | 'Accept'
  eula?: EULAInfo
}

@Component({
  selector: 'bebop-eula-modal',
  templateUrl: './eula-modal.component.html',
  styleUrls: ['./eula-modal.component.scss'],
})
export class EulaModalComponent implements OnInit, OnExternalModalClose, OnDestroy, AfterViewInit {
  eulas: EULAInfo[]
  eula: EULAInfo

  region: EulaRegion

  regions: EulaRegion[]

  radioItems: RadioItem[]
  termsOfUses: boolean[]

  readTerms: boolean[]

  selectedIdx = 0

  @ViewChild('eulaSection') eulaSection

  constructor(
    public ref: ModalOverlayRef<EulaModalComponent, EulaModalAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private util: BebopClientUtilsService,
    private bebopConfig: BebopConfigService
  ) {
    this.eulas = data.eulas as EULAInfo[]

    this.eula = this.eulas?.length ? this.eulas[0] : null
    this.region = this.eula?.region
    this.regions = [...new Set(this.eulas?.map((e) => e.region) || [])]

    this.termsOfUses = this.eulas?.map((e) => false)
    this.readTerms = [...this.termsOfUses]

    this.radioItems = this.eulas?.map((e) => ({
      name: '',
      value: e.region,
      selected: e._id == this.eula._id,
    }))

    this.selectedIdx = 0
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.ref?.close()
  }

  ngAfterViewInit() {
    this.updateReadScroll()
  }

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  accept() {
    this.ref.send({ name: 'Accept', eula: this.eula })
    this.ref.close()
  }

  deny() {
    this.ref.send({ name: 'Deny' })
    this.ref.close()
  }

  print() {
    // this.util.openPrintWindow({
    //   title: 'BeBop Subscription Agreement',
    //   urlSegment: 'eulaprint'
    // })
    this.util.openShell(this.bebopConfig.apiUrl + '/eulaprint')
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // console.log(event)
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 10) {
      this.readTerms[this.selectedIdx] = true
    }
  }

  onSelectRegion(r: EulaRegion) {
    this.onChangeOption(r)
  }

  onChangeOption(ev: string) {
    this.radioItems = this.eulas?.map((e) => ({
      name: '',
      value: e.region,
      selected: e.region == ev,
    }))

    this.eula = this.eulas?.find((e) => e.region == ev)
    this.region = this.eula.region
    this.selectedIdx = this.radioItems.findIndex((e) => e.selected)
    this.updateReadScroll()
  }

  acceptTermsOfUse(idx: number, b: boolean) {
    this.termsOfUses[idx] = b
  }

  updateReadScroll() {
    window.setTimeout(() => {
      if (this.readTerms[this.selectedIdx]) return

      let el = this.eulaSection?.nativeElement

      this.readTerms[this.selectedIdx] = el?.scrollHeight - el?.clientHeight <= 10
    }, 200)
  }
}
