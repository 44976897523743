import { Component, Input, OnInit } from '@angular/core'
@Component({
  selector: 'card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.scss'],
})
export class CardContainerComponent implements OnInit {
  @Input('size') size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' =
    'medium'
  @Input('active') active = false
  @Input('fullHeight') fullHeight = false
  @Input('titleHeader') header = ''
  @Input('footer') footer = false
  @Input('variant') variant: 'light' | 'dark' = 'dark'
  @Input('negative') negative = false
  constructor() {}

  ngOnInit(): void {}

  get generateClass() {
    return `card-container ${this.size} ${this.active ? 'actived' : ''} ${
      this.fullHeight ? 'fullHeight' : ''
    }`
  }

  get generateClassBody(): string {
    return `body ${this.size} ${
      this.header.length === 0 ? 'rounded' : ''
    }`
  }

  get generateClassHeader(): string {
    return `header ${this.header.length === 0 ? 'empty': ''}`
  }
}
