import { Component, OnInit } from '@angular/core'
import { ChildrenOutletContexts } from '@angular/router'
import { routeAnimations } from '../../common/animations/route-animations'
@Component({
  selector: 'bebop-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [routeAnimations],
})
export class AppLayoutComponent implements OnInit {
  constructor(private contexts: ChildrenOutletContexts) {}

  ngOnInit(): void {}

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation']
  }
}
