<button
  [class.btn]="true"
  [class.light]="variant === 'light'"
  [class.dark]="variant === 'dark'"
  [class.danger]="variant === 'danger'"
  [class.small]="size === 'small'"
  [class.medium]="size === 'medium'"
  [class.large]="size === 'large'"
  [class.xsmall]="size === 'xsmall'"
  [class.outlined]="outlined"
  [class.border]="border"
  [class.loading]="loading"
  [disabled]="disabled || loading">
  @if(loading){
  <cree8-spinner [variant]="spinnerVariant"></cree8-spinner>
  }@else {
  <ng-content></ng-content>
  }
</button>
