import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { EStorageType } from 'src/app/common/components/cree8-card-project/types'
import { IProjectDetail } from 'src/app/models/projects.model'
import { LiveFilesQuery } from 'src/app/store/projects/live-files.query'
import { ProjectsService } from 'src/app/store/projects/projects.service'

import { ICardActionsList } from '../card-actions-list/card-actions-list.component'

@Component({
  selector: 'card-projects',
  styleUrl: './card-projects.component.scss',
  templateUrl: './card-projects.component.html',
})
export class CardProjectsComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject()

  @Input() type: string = 'grid'
  @Input() project: IProjectDetail = {} as IProjectDetail
  @Input() activeProject: IProjectDetail
  @Output() actionProject: EventEmitter<{
    type: ICardActionsList
    data: any
  }> = new EventEmitter<{ type: ICardActionsList; data: any }>()
  listCover: string[] = ['folder-cover-1', 'folder-cover-2', 'folder-cover-3', 'folder-cover-4']
  projectCover: string
  observer!: MutationObserver

  showActionButtons = false

  projectLiveFile = {
    hasLiveFile: false,
    isLive: false,
  }

  constructor(
    private router: Router,
    private projectsService: ProjectsService,
    private liveFilesQuery: LiveFilesQuery
  ) {}

  ngOnInit(): void {
    this.generateCover()
    this.observer = new MutationObserver(() => this.generateCover())
    this.observer.observe(document.body, { attributeFilter: ['class'], attributes: true })

    this.liveFilesQuery
      .selectProjectActiveStatus(this.project._id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.projectLiveFile = response
      })
  }

  // hideToggleActionButtons() {
  //   this.actionProject.emit({ data: null, type: 'toggle' })
  // }

  ngOnDestroy(): void {
    this.observer.disconnect()
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  deleteProject(project: IProjectDetail) {
    this.actionProject.emit({ data: project, type: 'delete' })
  }

  detailProject(project: IProjectDetail) {
    this.projectsService.setProjectPage('details')
    console.log(project)
    if (project.solutions.archive) {
      this.router.navigate(['/app/projects/archive'], { queryParams: { projectId: project._id } })
    } else {
      this.router.navigate(['/app/projects/view'], { queryParams: { projectId: project._id } })
    }
  }

  // toggleProject(project: IProjectDetail) {
  //   this.actionProject.emit({ data: project, type: 'toggle' })
  // }

  generateCover() {
    const index = Math.floor(Math.random() * this.listCover.length)
    const isLightMode = document.body.classList.contains('light')
    const themePrefix = isLightMode ? '_light' : '' // Adjust suffix as needed
    this.projectCover = `assets/img/folder-cover/${this.listCover[index]}${themePrefix}.png`
  }

  onOpenDropdown(project: IProjectDetail) {
    if (!project.dropDownActive) project.dropDownActive = true
    else project.dropDownActive = true
  }

  onCloseDropdown(project: IProjectDetail) {
    if (!project.dropDownActive) project.dropDownActive = false
    else project.dropDownActive = false
  }

  actionsList(): ICardActionsList[] {
    let projectActions = ['link', 'members', 'settings', 'delete']
    if (!this.project.solutions.archive) {
      projectActions.push('archive-project')
    }
    return projectActions as ICardActionsList[]
  }

  onAction(props: { action: ICardActionsList; data: any }) {
    this.actionProject.emit({ data: props.data, type: props.action })
  }

  getStorageType(project: any): any {
    if (project.solutions.archive) {
      return EStorageType.ARCHIVE
    }
    return null
  }

  getStorageClass(project: any): string {
    if (project.solutions.archive) {
      return 'inverted glacier'
    }

    return project.storage.highPerformance ? 'high-performance' : 'standard'
  }

  allowBadge(type: string): boolean {
    return type.toLowerCase() === 'archive'
  }
}
