<div class="bebop-best-practice-caststation">
  <div class="modal-header">
    <div class="modal-title h2">CREE8 Cast Best Practices</div>
    <div class="header-cancel-btn">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="cancel()">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="#0C0C0F"
          fill-opacity="0.32"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5283 7.52851C15.7886 7.26816 16.2107 7.26816 16.4711 7.52851C16.7314 7.78886 16.7314 8.21097 16.4711 8.47132L12.9425 11.9999L16.4711 15.5285C16.7314 15.7889 16.7314 16.211 16.4711 16.4713C16.2107 16.7317 15.7886 16.7317 15.5283 16.4713L11.9997 12.9427L8.47108 16.4713C8.21073 16.7317 7.78862 16.7317 7.52827 16.4713C7.26792 16.211 7.26792 15.7889 7.52827 15.5285L11.0569 11.9999L7.52827 8.47132C7.26792 8.21097 7.26792 7.78886 7.52827 7.52851C7.78862 7.26816 8.21073 7.26816 8.47108 7.52851L11.9997 11.0571L15.5283 7.52851Z"
          fill="white"
          fill-opacity="0.8"
        />
      </svg>
    </div>
  </div>
  <div class="modal-content">
    <div class="main-section">
      <div class="paragraph message">
        CREE8 Cast provides a low-latency, high-quality video and multi-channel audio stream to your local computer.
      </div>
      <ul class="paragraph li-messages">
        <li class="li-message">
          <span class="hq highlight">HQ:</span> supports up to 1920x1080 HD with approx.
          <span class="bandwidth">105 Mbps</span> peak bandwidth utilization bandwidth.
        </li>

        <li class="li-message">
          <span class="sq">SQ:</span> streams a fixed-size SD image with approx.
          <span class="bandwidth">20 Mbps</span> peak bandwidth utilization bandwidth.
        </li>
      </ul>

      <div class="paragraph message">For the best experience:</div>
      <ul class="paragraph li-messages">
        <li class="li-message">
          Please ensure that you have a minimum of <span class="bandwidth">20 Mbps</span> of consistent internet
          bandwidth.
        </li>

        <li class="li-message">Please use a <span class="highlight2">wired ethernet connection</span>, if possible.</li>

        <!-- <li class="li-message">
          Visit our support page
          <span class="ref-link large-link-text" (click)="onAction('Best Practices')"
            >Best Practices for working with CREE8 Cast</span
          >.
        </li> -->
      </ul>
    </div>
    <div class="divider"></div>
    <div class="action-section">
      <div class="no-show paragraph">
        <bebop-checkbox-button
          [checked]="doNotShowAgain"
          (on-change)="onDoNotShowAgain($event)"
        ></bebop-checkbox-button>
        Don’t show this message again
      </div>
      <bebop-button size="large" (on-click)="continue()">Continue</bebop-button>
    </div>
  </div>
</div>
