import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { IActionType } from 'src/app/common/components/actions-list/actions-list.component'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'
import { TeamQuery } from 'src/app/store/team/team.query'
import { TeamService } from 'src/app/store/team/team.service'
@Component({
  selector: 'cree8-team',
  styleUrl: './team.component.scss',
  templateUrl: './team.component.html',
})
export class TeamComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject()

  inviteMemberModal = { active: false }

  tabs: ITabs[] = [
    { active: false, label: 'Members', value: 'members' },
    { active: false, label: 'Invites sent', value: 'invites' },
    { active: false, label: 'Unassigned', value: 'unassigned' },
  ]

  listSortingProjects: { label: string; value: string }[] = [
    { label: 'From A to Z', value: 'name' },
    { label: 'From Z to A', value: '-name' },
  ]

  activeSorting: string = this.listSortingProjects[0].value

  allowedActions: IActionType[] = ['search', 'refresh', 'sorting', 'created']

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamService: TeamService,
    private teamQuery: TeamQuery
  ) {}

  ngOnInit(): void {
    // Get the current route and set the active tab based on it
    const currentTabValue = this.route.snapshot.firstChild?.url[0]?.path || 'members' // default to 'members'
    this.setActiveTab(currentTabValue)
    this.teamService.updateActiveTab(currentTabValue)
    this.teamQuery
      .selectActiveTab()
      .pipe(takeUntil(this._destroy$))
      .subscribe((activeTab) => {
        this.setActiveTab(activeTab)
      })
  }

  onTabClick(value: string) {
    this.teamService.updateActiveTab(value)
    this.router.navigate([value], { relativeTo: this.route })
    this.setActiveTab(value)
  }

  setActiveTab(value: string) {
    this.tabs.forEach((tab) => {
      tab.active = tab.value === value
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  onRefresh() {
    this.teamService.triggerRefresh()
  }

  onSearchValue(searchText: string) {
    this.teamService.triggerSearch(searchText)
  }

  onSorting(sorting: string) {
    const activeTab = this.tabs.find((tab) => tab.active)
    if (activeTab.value == 'invites') {
      sorting = sorting == 'name' ? 'email' : '-email'
    }
    this.teamService.triggerSort(sorting)
  }

  onInviteMember() {
    this.inviteMemberModal.active = true
  }

  onInviteConfirmed() {
    this.inviteMemberModal.active = false
  }
}
