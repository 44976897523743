import { Component, Input } from '@angular/core';
import { BebopLink } from 'src/app/models/bebop.model';

@Component({
  selector: 'link-stats',
  templateUrl: './link-stats.component.html',
  styleUrl: './link-stats.component.scss'
})
export class LinkStatsComponent {
  @Input() link: BebopLink;
}
