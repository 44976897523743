<div class="section">
  <website-container>
    <div class="copyright">
      <div class="content">
        <span>© 2024 CREE8. All Right Reserved</span>
        <span>2350 Keystone Dr El Dorado Hills CA 95762-9543</span>
      </div>
      <div class="image-section">
        <ng-container *ngTemplateOutlet="bubble8"></ng-container>
        <div>A Bubble8 Venture Studio Company</div>
      </div>
    </div>
  </website-container>
</div>

<ng-template #bubble8>
  <svg
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47 94C72.9574 94 94 72.9574 94 47C94 21.0426 72.9574 0 47 0C21.0426 0 0 21.0426 0 47C0 72.9574 21.0426 94 47 94Z"
      fill="black"
    />
    <path
      d="M49.7091 23.0565V40.4586H44.286V23.0565C45.1778 22.9553 46.0792 22.8975 46.9951 22.8975C47.911 22.8975 48.8173 22.9505 49.7043 23.0565H49.7091Z"
      fill="white"
    />
    <path
      d="M49.7091 44.291V70.9485C48.8173 71.0497 47.9159 71.1075 47 71.1075C46.0841 71.1075 45.1778 71.0545 44.2908 70.9485V53.5512L31.9889 65.858C31.2755 65.2843 30.6006 64.6818 29.9643 64.0455C29.3231 63.4092 28.7158 62.7343 28.1566 62.0209L40.4585 49.7141H23.0517C22.9504 48.8223 22.9022 47.9209 22.9022 47.005C22.9022 46.0891 22.9504 45.1876 23.0517 44.291H49.7139H49.7091Z"
      fill="white"
    />
    <path
      d="M71.1025 47.0001C71.1025 47.916 71.0543 48.8222 70.9531 49.7092H53.551V44.2861H70.9531C71.0543 45.1779 71.1025 46.0842 71.1025 47.0001Z"
      fill="white"
    />
    <path
      d="M53.551 44.291V40.4587H49.7139L62.0158 28.1567C63.4379 29.2847 64.7201 30.5718 65.8529 31.989L53.5462 44.291H53.551Z"
      fill="white"
    />
    <path
      d="M44.2907 40.4489L36.6261 40.4537L28.1516 31.9841C28.7108 31.2755 29.3182 30.6006 29.9593 29.9595C30.6004 29.3184 31.2705 28.7206 31.9839 28.147L44.2859 40.4489H44.2907Z"
      fill="white"
    />
    <path
      d="M65.853 62.0158C64.7202 63.433 63.4379 64.7201 62.0158 65.8481L53.551 57.3833V49.7139L65.8578 62.0206L65.853 62.0158Z"
      fill="white"
    />
    <path
      d="M9.42404 33.71L16.3704 36.3516L15.7919 37.8701L8.84558 35.2285L9.42404 33.71ZM14.0325 33.9896L13.3576 35.7635L12.0801 35.2767L12.7261 33.5799C12.8321 33.3051 12.8466 33.0689 12.7695 32.8761C12.6923 32.6832 12.5285 32.5386 12.2778 32.4422C12.0319 32.3506 11.8198 32.3506 11.6415 32.447C11.4631 32.5434 11.3185 32.7266 11.2173 33.0062L10.6099 34.6018L9.27943 34.0956L9.94466 32.341C10.2098 31.642 10.5906 31.1551 11.0871 30.89C11.5836 30.6249 12.1283 30.6008 12.7213 30.8273C13.1262 30.9816 13.4347 31.213 13.642 31.5215C13.8493 31.83 13.936 32.1964 13.9119 32.6206L13.7818 32.5724C14.0228 32.2012 14.3217 31.9553 14.6832 31.8445C15.0399 31.7336 15.4352 31.7577 15.8691 31.9216C16.274 32.0759 16.5873 32.2976 16.8091 32.5868C17.0308 32.8761 17.1465 33.2183 17.161 33.6232C17.1754 34.0282 17.0887 34.4765 16.8958 34.9778L16.2306 36.7325L14.9001 36.2263L15.5365 34.5536C15.6425 34.274 15.6618 34.033 15.5847 33.8402C15.5075 33.6473 15.3436 33.5027 15.0881 33.4063C14.8519 33.3147 14.6447 33.3195 14.4663 33.4208C14.2879 33.522 14.1481 33.71 14.0421 33.9848L14.0325 33.9896Z"
      fill="white"
    />
    <path
      d="M16.3464 29.4003L12.5382 26.7539L13.4686 25.4186L17.1852 28.0024C17.5708 28.2675 17.9324 28.3736 18.2794 28.3109C18.6265 28.2482 18.9254 28.0313 19.1857 27.6553C19.4508 27.2745 19.5472 26.9129 19.4846 26.561C19.4171 26.2092 19.2002 25.9055 18.8242 25.6452L15.1075 23.0614L16.0379 21.7261L19.8461 24.3725C20.3282 24.71 20.6801 25.1053 20.8922 25.5584C21.1091 26.0115 21.1814 26.4984 21.1139 27.0142C21.0464 27.53 20.8391 28.0458 20.4824 28.5567C20.1305 29.0629 19.7256 29.4341 19.2725 29.6751C18.8193 29.9161 18.3373 30.0125 17.8311 29.9691C17.325 29.9258 16.8333 29.7329 16.3512 29.4003H16.3464Z"
      fill="white"
    />
    <path
      d="M20.0582 17.6191L24.9414 23.2157L23.717 24.2858L18.829 18.6892L20.0534 17.6191H20.0582ZM23.9917 20.0341L22.5601 21.2827L21.6634 20.2559L23.0325 19.0604C23.2542 18.8676 23.3747 18.6651 23.3988 18.4578C23.4229 18.2505 23.3458 18.0481 23.1723 17.8456C22.9987 17.648 22.8107 17.5516 22.6083 17.5516C22.4058 17.5516 22.1889 17.648 21.9671 17.8408L20.6801 18.964L19.7401 17.889L21.1525 16.655C21.7165 16.1633 22.2805 15.9126 22.8445 15.9126C23.4085 15.9126 23.8953 16.1488 24.3147 16.626C24.5991 16.9538 24.763 17.3009 24.7968 17.6673C24.8353 18.0336 24.7389 18.4 24.5172 18.7663L24.4256 18.6603C24.8161 18.4434 25.1921 18.3711 25.5584 18.4434C25.9248 18.5157 26.2622 18.723 26.5659 19.07C26.8503 19.3978 27.0239 19.7401 27.0817 20.0968C27.1395 20.4535 27.0817 20.8151 26.9033 21.1766C26.725 21.5381 26.4357 21.8949 26.0356 22.2468L24.6232 23.4808L23.6832 22.4058L25.0282 21.2296C25.2547 21.032 25.3801 20.8295 25.409 20.6271C25.4331 20.4246 25.356 20.2173 25.1776 20.01C25.0089 19.8172 24.8257 19.7256 24.6184 19.7304C24.4111 19.7353 24.199 19.8365 23.9773 20.0293L23.9917 20.0341Z"
      fill="white"
    />
    <path
      d="M27.5445 12.2154L31.0249 18.7809L29.5884 19.5425L26.1128 12.977L27.5493 12.2154H27.5445ZM30.8224 15.4644L29.1449 16.3514L28.5038 15.1462L30.109 14.2978C30.3693 14.158 30.5332 13.9941 30.6055 13.7965C30.6778 13.5988 30.6489 13.3819 30.5236 13.1457C30.403 12.9143 30.2392 12.7745 30.0415 12.7311C29.8439 12.6829 29.6125 12.7311 29.3522 12.8709L27.8434 13.6711L27.1781 12.413L28.8364 11.5357C29.4968 11.1838 30.1042 11.0729 30.6537 11.1982C31.2033 11.3236 31.6226 11.6706 31.9215 12.2298C32.124 12.6155 32.2011 12.9915 32.1529 13.3578C32.1047 13.7242 31.9312 14.0616 31.6275 14.3605L31.56 14.2351C31.989 14.1146 32.3746 14.1291 32.7169 14.2834C33.0592 14.4376 33.3387 14.7172 33.5557 15.1221C33.7581 15.5078 33.8497 15.8789 33.8256 16.2405C33.8015 16.602 33.6617 16.9395 33.4062 17.2528C33.1507 17.5661 32.7892 17.8457 32.3168 18.0964L30.6585 18.9737L29.9933 17.7156L31.5744 16.8816C31.8396 16.7418 32.0083 16.5731 32.0806 16.3803C32.1529 16.1875 32.124 15.9705 31.9938 15.7247C31.8733 15.4981 31.7142 15.368 31.5166 15.3246C31.3141 15.2812 31.0876 15.3294 30.8273 15.4692L30.8224 15.4644Z"
      fill="white"
    />
    <path
      d="M36.0044 8.68652L37.9905 15.845L36.4238 16.2788L34.4377 9.12037L36.0044 8.68652ZM36.7034 16.2065L36.3033 14.7555L40.4152 13.6131L40.8153 15.0641L36.7034 16.2065Z"
      fill="white"
    />
    <path
      d="M47.4772 14.4808L42.7676 14.7701L42.3145 7.35613L47.0241 7.06689L47.1157 8.57089L43.5774 8.78782L44.0016 8.33951L44.1173 10.2147L46.8506 10.046L46.9373 11.468L44.2041 11.6367L44.3246 13.589L43.8474 13.1938L47.3856 12.9768L47.4772 14.4808Z"
      fill="white"
    />
    <path
      d="M49.6368 12.3983C49.7187 11.8969 49.9308 11.492 50.2779 11.1787C50.625 10.8702 51.0347 10.7063 51.5119 10.6966L51.4782 10.9569C51.0781 10.7786 50.7937 10.5279 50.6153 10.2049C50.437 9.88195 50.3791 9.52041 50.4466 9.11067C50.5141 8.70575 50.6828 8.37313 50.9576 8.108C51.2324 7.84287 51.5794 7.65969 52.0085 7.55364C52.4375 7.44759 52.9099 7.43795 53.4305 7.5199C53.9608 7.60667 54.4043 7.76575 54.7706 7.99713C55.137 8.22852 55.4021 8.51775 55.5708 8.86C55.7395 9.20226 55.7926 9.57826 55.7251 9.988C55.6576 10.3977 55.4889 10.7255 55.2141 10.9666C54.9393 11.2124 54.5826 11.3474 54.1439 11.3763L54.1729 11.1353C54.4814 11.2317 54.7417 11.3908 54.9538 11.6125C55.1659 11.8343 55.3153 12.0897 55.4021 12.3838C55.4889 12.6778 55.5081 12.9912 55.4503 13.3286C55.378 13.7866 55.1948 14.1626 54.9008 14.4614C54.6067 14.7603 54.2307 14.9676 53.7631 15.0785C53.2955 15.1942 52.7653 15.2038 52.1724 15.1026C51.5794 15.0062 51.0781 14.8326 50.678 14.5771C50.2731 14.3265 49.9838 14.0131 49.8007 13.6371C49.6175 13.2611 49.5645 12.8466 49.6368 12.3886V12.3983ZM51.2324 12.5477C51.1986 12.7453 51.2275 12.9237 51.3095 13.078C51.3914 13.2322 51.5264 13.3672 51.7144 13.4732C51.8976 13.5793 52.1338 13.6564 52.4086 13.7046C52.6881 13.748 52.9292 13.7528 53.1413 13.7046C53.3534 13.6564 53.5221 13.5793 53.6474 13.4588C53.7776 13.3383 53.8547 13.1792 53.8885 12.9767C53.9367 12.6778 53.8547 12.4272 53.6474 12.2199C53.4401 12.0126 53.1268 11.8776 52.7171 11.8102C52.3073 11.7427 51.9699 11.7764 51.7048 11.9066C51.4396 12.0367 51.2806 12.2488 51.2324 12.5477ZM51.8687 9.50595C51.8301 9.7518 51.9024 9.9639 52.0904 10.1423C52.2784 10.3206 52.5532 10.4411 52.9147 10.499C53.2762 10.5568 53.5751 10.5327 53.8113 10.4218C54.0475 10.311 54.1825 10.1326 54.2259 9.88677C54.2645 9.6361 54.1921 9.41918 54.0041 9.236C53.8161 9.05282 53.5414 8.93231 53.1798 8.87446C52.8183 8.81662 52.5146 8.84554 52.2832 8.95641C52.047 9.0721 51.9072 9.25528 51.8687 9.50595Z"
      fill="white"
    />
    <path
      d="M14.8567 70.6011L19.0746 63.8813L20.1303 65.26L17.9997 68.6537C17.8695 68.8609 17.7297 69.0682 17.5803 69.2755C17.426 69.4828 17.2477 69.719 17.0404 69.9793C17.3778 69.8395 17.667 69.7286 17.8984 69.6467C18.1298 69.5647 18.3612 69.4924 18.5829 69.4201L22.4104 68.2439L23.442 69.5937L15.8786 71.9316L14.8615 70.6011H14.8567Z"
      fill="white"
    />
    <path
      d="M23.7121 79.471L20.0533 76.4871L24.7485 70.7314L28.4073 73.7153L27.4528 74.8819L24.7051 72.6404L25.3222 72.597L24.1363 74.0528L26.2622 75.7833L25.3607 76.8872L23.2349 75.1567L21.996 76.6751L21.9141 76.0629L24.6618 78.3045L23.7121 79.471Z"
      fill="white"
    />
    <path
      d="M28.4699 82.3487L27.0189 81.6594L30.2053 74.9492L31.6563 75.6386L32.5866 82.1511L32.1335 81.9341L34.4859 76.9835L35.9369 77.6728L32.7505 84.383L31.2995 83.6936L30.3644 77.1908L30.8175 77.4077L28.4699 82.3487Z"
      fill="white"
    />
    <path
      d="M37.3541 79.688L37.6578 78.2129L43.3846 79.3843L43.0809 80.8594L37.3589 79.688H37.3541ZM41.1575 79.7313L39.8222 86.2439L38.2266 85.9161L39.5619 79.4036L41.1527 79.7313H41.1575Z"
      fill="white"
    />
    <path
      d="M45.3127 84.1853L45.1488 79.548L46.7733 79.4901L46.9372 84.0166C46.9517 84.4842 47.0915 84.8361 47.347 85.0771C47.6024 85.3181 47.9592 85.429 48.4123 85.4097C48.8751 85.3953 49.227 85.2555 49.468 84.9903C49.709 84.73 49.8247 84.3685 49.8054 83.9154L49.6415 79.3889L51.266 79.3311L51.4299 83.9684C51.4492 84.5565 51.3432 85.0723 51.107 85.5158C50.8707 85.9593 50.5285 86.3112 50.0754 86.5666C49.6222 86.8221 49.0872 86.9619 48.4653 86.986C47.8483 87.0101 47.3084 86.9089 46.8408 86.6872C46.3732 86.4654 46.0069 86.1424 45.7417 85.7134C45.4766 85.2844 45.332 84.7734 45.3079 84.1853H45.3127Z"
      fill="white"
    />
    <path
      d="M57.1762 85.5929L55.6144 86.0508L53.5271 78.9213L56.2844 78.1163C57.1521 77.8608 57.88 77.8656 58.4681 78.1308C59.0562 78.3959 59.4612 78.8972 59.6781 79.6347C59.813 80.0975 59.8323 80.5217 59.7311 80.9074C59.6299 81.293 59.4129 81.6449 59.0755 81.9679L61.158 84.436L59.4515 84.9373L57.6535 82.7536L56.4483 83.1055L57.1762 85.6025V85.5929ZM55.4939 79.8517L56.0434 81.7317L57.2293 81.3846C57.5715 81.2834 57.8125 81.1243 57.9523 80.9025C58.0921 80.6808 58.121 80.4157 58.0295 80.1168C57.9427 79.8131 57.7836 79.6106 57.5522 79.5094C57.3257 79.4082 57.0364 79.4034 56.6894 79.5094L55.4939 79.8613V79.8517Z"
      fill="white"
    />
    <path
      d="M68.6344 80.6133L64.5129 82.9079L60.9023 76.4147L65.0287 74.1201L65.7614 75.4361L62.6618 77.157L62.8498 76.5689L63.7609 78.2079L66.1567 76.8775L66.8509 78.1211L64.4551 79.4516L65.4047 81.1629L64.807 81.0135L67.9065 79.2925L68.6393 80.6085L68.6344 80.6133Z"
      fill="white"
    />
    <path
      d="M71.1508 71.9655C70.8134 71.6666 70.5964 71.3195 70.4904 70.9146C70.3843 70.5097 70.3988 70.0903 70.5241 69.6516C70.6495 69.213 70.8857 68.7936 71.2376 68.3983C71.5943 67.9982 71.9703 67.7138 72.3656 67.5451C72.7609 67.3763 73.161 67.3281 73.5562 67.3956C73.9515 67.4631 74.3227 67.6559 74.6746 67.9644L73.6044 69.1744C73.4068 69.0009 73.1947 68.9334 72.9633 68.9671C72.7319 69.0009 72.5102 69.1358 72.3077 69.3672C72.0908 69.6131 71.9703 69.8541 71.9414 70.0951C71.9124 70.3361 71.9944 70.5386 72.1824 70.7025C72.3511 70.8519 72.5247 70.9242 72.6934 70.9146C72.8621 70.905 73.0549 70.8182 73.267 70.6591L74.1781 69.9794C74.718 69.5697 75.2193 69.3576 75.6773 69.3383C76.1352 69.319 76.5883 69.5022 77.0318 69.8926C77.3885 70.206 77.6199 70.5723 77.7356 70.9821C77.8465 71.3966 77.8369 71.8257 77.7019 72.2692C77.5669 72.7175 77.3066 73.1513 76.9306 73.5803C76.5691 73.9901 76.1786 74.289 75.764 74.4721C75.3495 74.6553 74.9397 74.718 74.5348 74.6553C74.1299 74.5926 73.7491 74.4095 73.4068 74.1058L74.477 72.8958C74.6794 73.0742 74.8963 73.1368 75.1374 73.0886C75.3784 73.0404 75.6146 72.8814 75.8556 72.6114C76.1015 72.3366 76.2413 72.0715 76.2798 71.8209C76.3184 71.5702 76.2509 71.3677 76.0774 71.2086C75.9231 71.0689 75.7689 71.0014 75.6146 71.0062C75.4603 71.0062 75.282 71.0881 75.0843 71.2424L74.1588 71.9414C73.6189 72.3511 73.0983 72.5536 72.5921 72.5584C72.086 72.5632 71.6088 72.3656 71.1508 71.9606V71.9655Z"
      fill="white"
    />
    <path
      d="M75.2435 66.1135L73.9275 65.3808L76.7716 60.2759L78.0876 61.0086L75.2435 66.1135ZM76.4293 62.4981L82.2381 65.7327L81.4475 67.1547L75.6388 63.9202L76.4293 62.4981Z"
      fill="white"
    />
    <path
      d="M81.9245 59.8853L77.4559 58.6464L77.8898 57.0797L82.2523 58.2897C82.7055 58.415 83.0815 58.3909 83.3852 58.2174C83.6889 58.0438 83.9058 57.7401 84.0263 57.3015C84.1516 56.858 84.1227 56.4772 83.9443 56.1687C83.766 55.8601 83.4575 55.6432 83.0188 55.5227L78.6562 54.3128L79.0901 52.7461L83.5587 53.985C84.1275 54.144 84.5855 54.3995 84.9374 54.7562C85.2893 55.113 85.5207 55.5468 85.6267 56.0578C85.7328 56.5688 85.7087 57.1231 85.5399 57.7209C85.376 58.3138 85.1157 58.8007 84.7638 59.1767C84.4119 59.5575 83.9926 59.8081 83.5009 59.9287C83.0092 60.054 82.4837 60.0395 81.9149 59.8805L81.9245 59.8853Z"
      fill="white"
    />
    <path
      d="M86.8849 47.6799L86.8464 50.5915L79.418 50.4855L79.4565 47.6751C79.4662 46.9472 79.6349 46.3061 79.953 45.7614C80.276 45.2118 80.7195 44.7876 81.2883 44.4791C81.8572 44.1706 82.5079 44.026 83.2406 44.0356C83.9637 44.0452 84.6 44.2091 85.1496 44.5177C85.6991 44.831 86.1281 45.26 86.4366 45.7999C86.7451 46.3446 86.8946 46.9713 86.8801 47.6799H86.8849ZM80.1844 48.8706L86.1474 48.9525L85.352 49.7142L85.3761 47.8149C85.3809 47.3859 85.299 47.0195 85.1303 46.711C84.9616 46.4025 84.7109 46.1663 84.3879 46.0024C84.0649 45.8385 83.6697 45.7517 83.2165 45.7469C82.7489 45.7421 82.3488 45.8192 82.0114 45.9879C81.6788 46.1518 81.4185 46.3928 81.2401 46.711C81.0569 47.0243 80.9654 47.41 80.9557 47.8583L80.9316 49.6563L80.1796 48.8706H80.1844Z"
      fill="white"
    />
    <path
      d="M78.7721 40.2852L86.0752 38.9258L86.374 40.5262L79.071 41.8856L78.7721 40.2852Z"
      fill="white"
    />
    <path
      d="M79.1432 30.0608C79.8421 29.7764 80.5218 29.6656 81.1726 29.7331C81.8234 29.8005 82.4066 30.0271 82.9128 30.4127C83.419 30.7984 83.8094 31.3286 84.0842 31.9939C84.3541 32.6591 84.4409 33.3002 84.3445 33.9317C84.2481 34.5584 83.9926 35.1224 83.5732 35.6237C83.1538 36.1251 82.5946 36.5155 81.8957 36.7999C81.1967 37.0843 80.517 37.1952 79.8662 37.1277C79.2155 37.0602 78.637 36.8337 78.126 36.4528C77.6199 36.072 77.2294 35.5466 76.9595 34.8814C76.6847 34.2113 76.5979 33.5654 76.6943 32.9339C76.7908 32.3072 77.0462 31.7432 77.4608 31.2371C77.8802 30.7357 78.4345 30.3404 79.1383 30.056L79.1432 30.0608ZM79.7891 31.6516C79.3504 31.83 79.0034 32.0565 78.7479 32.3265C78.4924 32.5964 78.3333 32.8953 78.2755 33.2279C78.2176 33.5605 78.261 33.9124 78.4104 34.2836C78.5599 34.6548 78.7768 34.9392 79.0516 35.1368C79.3263 35.3345 79.6493 35.4405 80.0205 35.4598C80.3917 35.4791 80.7966 35.3923 81.2353 35.214C81.6739 35.0356 82.0258 34.8091 82.2813 34.5391C82.5416 34.2692 82.6959 33.9655 82.7585 33.628C82.8164 33.2954 82.773 32.9387 82.6187 32.5675C82.4693 32.1963 82.2524 31.9119 81.9776 31.7191C81.698 31.5263 81.3751 31.4202 80.9991 31.4058C80.6231 31.3913 80.223 31.4733 79.7891 31.6516Z"
      fill="white"
    />
    <path
      d="M47 76.6463C30.6537 76.6463 17.3539 63.3465 17.3539 47.0002C17.3539 30.6538 30.6537 17.354 47 17.354C63.3464 17.354 76.6462 30.6538 76.6462 47.0002C76.6462 63.3465 63.3464 76.6463 47 76.6463ZM47 18.8002C31.4491 18.8002 18.8 31.4492 18.8 47.0002C18.8 62.5511 31.4491 75.2002 47 75.2002C62.551 75.2002 75.2 62.5511 75.2 47.0002C75.2 31.4492 62.551 18.8002 47 18.8002Z"
      fill="white"
    />
    <path
      d="M47 91.1075C22.6805 91.1075 2.89233 71.3193 2.89233 46.9998C2.89233 22.6803 22.6805 2.89209 47 2.89209C71.3195 2.89209 91.1077 22.6803 91.1077 46.9998C91.1077 71.3193 71.3195 91.1075 47 91.1075ZM47 4.33824C23.4759 4.33824 4.33849 23.4757 4.33849 46.9998C4.33849 70.5239 23.4759 89.6613 47 89.6613C70.5241 89.6613 89.6616 70.5239 89.6616 46.9998C89.6616 23.4757 70.5241 4.33824 47 4.33824Z"
      fill="white"
    />
  </svg>
</ng-template>
