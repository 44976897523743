import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { StepperSignupService } from './stepper-signup.service'

@Component({
  selector: 'app-stepper-signup',
  templateUrl: './stepper-signup.component.html',
  styleUrls: ['./stepper-signup.component.scss'],
})
export class StepperSignupComponent implements OnInit, OnDestroy {
  listStepper: { step: string; value: string }[] = []
  descStepper: string[] = []
  currentStepper = 1
  @Input() activeFooter = false
  @Input() idScroll = ''

  private destroy$ = new Subject<void>()

  constructor(private stepperService: StepperSignupService) {}

  ngOnInit(): void {
    this.stepperService.listStepper$
      .pipe(takeUntil(this.destroy$))
      .subscribe((steps) => {
        this.listStepper = steps
        this.descStepper = steps.map((step) => step.value)
      })

    this.stepperService.currentStepper$
      .pipe(takeUntil(this.destroy$))
      .subscribe((step) => (this.currentStepper = step))
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  hasPasssed(value: number): boolean {
    return this.currentStepper > value
  }

  activedStep(value: number): boolean {
    return this.currentStepper === value
  }

  activedAndComeStep(value: number): boolean {
    return this.currentStepper >= value
  }

  scrollToElement(): void {
    const element = document.getElementById(this.idScroll)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
