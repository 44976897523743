<div [ngClass]="cardClass" (click)="onAction()">
  <span class="plan-title" [ngClass]="format === 'default' ? 'body3' : 'body2'">{{title}}</span>

  <div class="plan-content" *ngIf="activated">
    <ng-container *ngIf="format === 'setup'">
      <cree8-btn (clicked)="$event.stopPropagation(); onAction()" appearance="text" size="small">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Plus"></use>
        </svg>
        {{button}}
      </cree8-btn>
    </ng-container>

    <ng-container *ngIf="format !== 'setup'">
      <span class="headline1">{{subtitle}}</span>
    </ng-container>
    <span class="body3 plan-description">{{description}}</span>
  </div>

  <div class="plan-content" *ngIf="!activated">
    <span class="body3 plan-description">{{activatedText}}</span>
    <cree8-btn (clicked)="$event.stopPropagation(); onAction()" size="small">
      {{button}}
    </cree8-btn>
  </div>

</div>
