import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-split-n-button',
  templateUrl: './split-n-button.component.html',
  styleUrls: ['./split-n-button.component.scss'],
})
export class SplitNButtonComponent implements OnInit, OnChanges {
  @Output('on-select') click = new EventEmitter<number>()
  @Input('disabled') disabled: String

  @Input('templates') templates: TemplateRef<any>[]
  @Input('disabledItems') disabledItems: Boolean[]
  @Input('selected') selected: number

  @Input('duplicate-events') dups: boolean

  active = 0

  _lastActive = -1

  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {}

  get isDisabled() {
    return this.disabled == 'disabled' || this.disabled == 'true'
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.onClick(changes['selected']?.currentValue)
      this.cdRef.detectChanges()
    }
  }

  updateDisabledItems() {
    if (this.disabledItems?.length != this.templates?.length) {
      this.disabledItems = this.templates.map(t => false)
    }
  }

  get disabledList() {
    if (this.disabledItems?.length) return this.disabledItems
    this.updateDisabledItems()
    return this.disabledItems
  }

  ngOnInit(): void {
    if (this.disabledItems?.length != this.templates?.length) {
      this.disabledItems = this.templates.map(t => false)
    }
    
    this.onClick(this.active)
  }

  onClick(idx: number) {
    if (!this.dups && this._lastActive == idx) return

    // console.log('split button click', idx)
    if (this.isDisabled) return
    this.active = this._lastActive = idx
    this.click.emit(this.active)
  }
}
