import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'

import { BehaviorSubject, interval, Subject, Subscription, take, takeUntil } from 'rxjs'
import { IConfirmationModal } from 'src/app/common/components/confirmation-modal/confirmation-modal.component'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { RocketSession, UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import {
  SelectRocketUploadPathAction,
  SelectRocketUploadPathComponent,
} from 'src/app/components/rocket/uploads/modals/select-rocket-upload-path/select-rocket-upload-path.component'
import { Project, User } from 'src/app/models/bebop.model'
import { IProjectDetail } from 'src/app/models/projects.model'
import { ProjectsService } from 'src/app/services/projects.service'
import { DownloaderLifecycleService } from 'src/app/services/rocket/download/downloader-lifecycle.service'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'

import { ProjectFilesSettingsComponent } from '../../projects-detail/project-files-settings/project-files-settings.component'

@Component({
  selector: 'cree8-all-projects',
  styleUrl: './all-projects.component.scss',
  templateUrl: './all-projects.component.html',
})
export class AllProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() projectId: string = ''

  @Input() isHomePage: boolean = false
  @ViewChild('projectFilesSettings')
  projectFilesSettings: ProjectFilesSettingsComponent
  listProjects: IProjectDetail[] = []
  project: Project | undefined = undefined
  organization: any
  selectedProject: IProjectDetail | undefined = undefined
  loading = true
  loadingSettings = false
  viewType = 'grid'

  public counter: number = 10
  private countdownSubscription: Subscription | undefined

  private user: User
  private _destroy$ = new Subject()

  confirmation: IConfirmationModal = {
    description:
      'Are you sure you want to delete your project? All files within it will be permanently removed. This action cannot be undone.',
    title: 'Delete This Project?',
    type: 'danger',
  }

  modalDeleteActive = false
  @Output() addProject: EventEmitter<void> = new EventEmitter<void>()

  modal: Cree8Modal = {
    counting: 0,
    current: 0,
    loading: false,
    title: '',
    type: 'close',
  } as Cree8Modal

  MODAL_TITLE_DELETE: string = 'Deleting Project'
  MODAL_TITLE_CANCEL: string = 'Project Deletion Canceled'
  MODAL_TITLE_SUCCESS_DELETE: string = 'Project Deleted'
  MODAL_TITLE_SETTINGS: string = 'Project Settings'

  buttonPreviousDisabled$ = new BehaviorSubject<boolean>(true)
  buttonNextDisabled$ = new BehaviorSubject<boolean>(false)

  session$: Subscription
  session: RocketSession<any, UiDownloadFile>

  constructor(
    private sessionQuery: SessionQuery,
    private projectsStoreService: ProjectsStoreService,
    private projectsQuery: ProjectsQuery,
    private toastService: ToastService,
    private uiQuery: UIQuery,
    private router: Router,
    private projectsService: ProjectsService,
    private modalService: ComponentModalService,
    private lcService: DownloaderLifecycleService
  ) {}

  ngOnInit(): void {
    this.projectsStoreService.resetStore()

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this._destroy$))
      .subscribe((org) => {
        // Ensure org exists
        if (!org) {
          this.projectsStoreService.resetStore()
          return
        }

        this.session = this.lcService.newRocketSession(org)
        this.user = this.sessionQuery.getUserValue()
        this.organization = org
        this.onRefresh()
      })

    this.projectsQuery
      .getViewTypeProjects()
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.viewType = response
      })

    this.projectsQuery.getQueryParamsList().subscribe((queryParamsList) => {
      this.buttonPreviousDisabled$.next(queryParamsList.page <= 1)
    })

    this.projectsQuery.getStatusHasMore().subscribe((hasMore) => {
      this.buttonNextDisabled$.next(!hasMore)
    })
  }

  ngAfterViewInit(): void {
    this.renderProjects()
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
    this.stopCountdown()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectId']) {
      this.projectId = changes['projectId'].currentValue
    }
  }

  onActionProject(props: any) {
    if (props.type === 'delete') {
      this.counter = 10
      this.modalDeleteActive = true
      this.selectedProject = props.data
      return
    }

    if (props.type === 'link') {
      this.selectedProject = props.data
      this.router.navigate(['/app/projects/view'], {
        queryParams: { activeTab: 'links', projectId: this.selectedProject?._id },
      })
      return
    }

    if (props.type === 'members') {
      this.selectedProject = props.data
      this.router.navigate(['/app/projects/view'], {
        queryParams: { activeTab: 'members', projectId: this.selectedProject?._id },
      })
      return
    }

    if (props.type === 'settings') {
      this.selectedProject = props.data
      this.modal.title = this.MODAL_TITLE_SETTINGS
      this.loadingSettings = true
      this.projectsService
        .getProject(this.selectedProject?._id)
        .pipe(takeUntil(this._destroy$))
        .subscribe((response) => {
          this.projectsStoreService.setSelectedProject(response.data)
          this.modal.type = 'confirmation'
          this.loadingSettings = false
        })

      // })
      return
    }

    if (props.type === 'archive-project') {
      this.selectedProject = props.data
      this.archiveProject()
    }
  }

  archiveProject(ev?: Event): void {
    const modalRef = this.modalService.open<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>(
      SelectRocketUploadPathComponent,
      {
        animateFrom: ev?.target as Element,
        data: {
          archive: true,
          glacier: true,
          project: this.selectedProject,
          session: this.session,
          title: 'Select location to archive the project',
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )
    modalRef.events().subscribe((e) => {
      if (e?.name == 'Cancel' || e?.name == 'Close') {
        return
      }

      if (!e.project) {
        // show notification
        this.toastService.show({
          text: 'No project selected',
          type: 'error',
        })
        return
      }

      let relativePath = e.path || '/'
      // Call API to create sync settings in the selected project with the selected file/folder
      let archiveFromProject = this.selectedProject
      let archiveToProject = e.project

      this.projectsService
        .saveToSyncQueue(archiveFromProject._id, {
          credentials: archiveToProject.credentials,
          fromBucket: (archiveToProject.fromBucket + '/' + relativePath).replace(/\/\/$/, '/'),
          organization: this.organization._id,
          sync_active: true,
          sync_down_delete: false,
          sync_filters: { blacklist_file_tags: [], blacklist_folder_tags: [], file_tags: [], folder_tags: [] },
          sync_scheduler: { type: 'ONCE', value: 'ALWAYS' },
          sync_up_delete: true,
          sync_up_delete_file_only: false,
          syncBasePath: '/',
          syncDestination: relativePath === '/' ? '' : relativePath,
          syncDirection: 'up',
          syncFiles: [],
        })
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          error: () => {
            this.toastService.show({
              text: 'Request to archive file/folder failed',
              type: 'error',
            })
          },
          next: (res) => {
            this.toastService.show({
              text: 'Request queued, you can check the status in the transfer status tab on the archive project',
              type: 'success',
            })
          },
        })
    })
  }

  onRefresh() {
    this.projectsStoreService.resetStore()
    this.loadFirstData()
  }

  loadFirstData() {
    this.loading = true
    this.projectsStoreService
      .getListProjects(this.user?._id, this.projectId, this.organization._id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.loading = false
        if (isErrorResponse(response)) {
          this.listProjects = []
        }
      })
  }

  renderProjects() {
    this.projectsQuery.getListProjects().subscribe((response) => {
      if (isErrorResponse(response)) {
        this.listProjects = []
      } else {
        if (this.isHomePage) {
          //only show 5 projects for homepage
          this.listProjects = response.slice(0, 5)
        } else {
          this.listProjects = response
        }
      }
    })
  }

  deleteProject() {
    this.modalDeleteActive = false
    this.modal.title = this.MODAL_TITLE_DELETE
    this.modal.labelConfirm = 'CANCEL DELETION'
    this.startCountdown()
  }

  onNext(event: any) {
    this.modal.title = ''
  }

  onCancelModal(event: any) {
    if (this.modal.title === this.MODAL_TITLE_CANCEL || this.modal.title === this.MODAL_TITLE_SUCCESS_DELETE) {
      this.modal.title = ''
      return
    }

    this.modal.title = this.MODAL_TITLE_CANCEL
    this.modal.labelConfirm = 'OK'

    this.stopCountdown()
    this.resetCountdown()
  }

  startCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe()
    }

    const countdown$ = interval(1000).pipe(take(this.counter + 1))

    this.countdownSubscription = countdown$.subscribe((value) => {
      this.counter = this.counter - 1
      if (this.counter === 0) {
        this.processDeleteProject()
      }
    })
  }

  stopCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe()
    }
  }

  resetCountdown() {
    this.stopCountdown()
    this.counter = 10
  }

  private processDeleteProject() {
    this.projectsStoreService.deleteProject(this.selectedProject._id).subscribe({
      error: (err) => {
        this.modal.title = ''
        this.toastService.show({
          text: err.error.msg,
          type: 'error',
        })
      },
      next: (res) => {
        this.modal.title = this.MODAL_TITLE_SUCCESS_DELETE
        this.modal.labelConfirm = 'OK'
        this.onRefresh()
      },
    })
  }

  onClickPreviousPage() {
    console.log('onClickPreviousPage')
    const queryParamsList = this.projectsQuery.getQueryParamsListValue()

    if (queryParamsList.page > 1 && !this.loading) {
      this.projectsStoreService.previousPage()
      this.loading = true

      this.projectsStoreService.getListProjects(this.user?._id, this.projectId, this.organization._id, true).subscribe({
        error: () => {
          this.loading = false
        },
        next: () => {
          this.loading = false
        },
      })
    }
  }

  onClickNextPage() {
    const hasMore = this.projectsQuery.getStatusHasMoreValue()
    if (hasMore && !this.loading) {
      this.projectsStoreService.nextPage()
      this.loading = true
      this.projectsStoreService.getListProjects(this.user?._id, this.projectId, this.organization._id, true).subscribe({
        error: () => {
          this.loading = false
        },
        next: () => {
          this.loading = false
        },
      })
    }
  }

  onCloseModal() {
    this.modal.title = ''
  }

  onCancelSettings(event: any) {
    this.modal.title = ''
  }

  onNextSettings(event: any) {
    this.projectFilesSettings.saveSettings()
  }

  onSuccessSubmitSettings(event: any) {
    this.modal.title = ''
    this.onRefresh()
  }

  onCreatedProject() {
    if (this.isHomePage) {
      this.router.navigate(['/app/projects'], { queryParams: { addModal: true } })
    } else {
      this.addProject.emit()
    }
  }

  onViewAllProjects() {
    this.router.navigate(['/app/projects'])
  }
}
