import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { lastValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'

import { LoginResponse } from '../models/response.model'
import { SessionQuery } from '../store/session/session.query'
import { SessionService } from '../store/session/session.service'
import { UIService } from '../store/ui/ui.service'

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivateChild {
  isFirstRun = true
  loggedIn = false
  loggedInStatusFetched = false
  environment = environment

  constructor(
    private uiService: UIService,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private router: Router
  ) {}

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.environment.browser && state.url == '/' && this.isFirstRun) {
      this.isFirstRun = false
      return Promise.resolve(this.router.parseUrl('/update'))
    }

    this.loggedIn = this.sessionQuery.getLoggedInValue()

    if (!this.loggedInStatusFetched && !this.loggedIn) {
      let res = <LoginResponse>await lastValueFrom(this.sessionService.getCurrentUser())
      this.loggedInStatusFetched = true
      if (res.user) {
        this.loggedIn = true
        this.sessionService.updateUserInfo(res.user)
        this.uiService.setCurrentUser(res.user)
      } else {
        this.loggedIn = false
      }
    }

    if (this.loggedIn) {
      return this.guardLoggedIn(state)
    } else {
      return this.guardNotLoggedIn(state)
    }
  }

  guardLoggedIn(state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      if (state.url.startsWith('/update')) return resolve(true)
      else if (state.url.startsWith('/app')) {
        return resolve(true)
      } else {
        return resolve(this.router.parseUrl('/app/dashboard'))
      }
    })
  }

  guardNotLoggedIn(state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      if (state.url.startsWith('/update')) return resolve(true)
      else if (!state.url.startsWith('/app')) {
        return resolve(true)
      } else {
        return resolve(this.router.parseUrl('/'))
      }
    })
  }
}
