<div class="bebop-edit-workstation-nickname">
  <div class="modal-header">
    <div class="modal-title h4">Edit Workstation Nickname</div>
  </div>
  <div class="modal-content">
    <div class="h4 message">Computer: {{ workstation.name }}</div>

    <div class="nickname">
      <bebop-input
        type="text"
        placeholder="Nickname"
        noInputLabel="true"
        [(binding)]="nickname"
        [autofocus]="true"
        (on-keyup)="onKeyupUsername($event)"
        [error]="!!error"
      >
        <div input-error-label>{{ error }}</div>
      </bebop-input>
    </div>

    <div class="description sub-message" *ngIf="!error">
      The nickname will only be visible to you. Limit - 50 characters
    </div>

    <div class="action-section" [class.error]="!!error">
      <bebop-button size="large" outlined="true" (on-click)="cancel()">Cancel</bebop-button>
      <bebop-button size="large" [disabled]="!!error" (on-click)="ok()">Save</bebop-button>
    </div>
  </div>
</div>
