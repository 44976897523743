import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { WorkstationState, WorkstationStore } from './workstation.store'

@Injectable({ providedIn: 'root' })
export class WorkstationQuery extends Query<WorkstationState> {
  constructor(protected store: WorkstationStore) {
    super(store)
  }

  getLoaderWorkstations() {
    return this.select('loaderWorkstations')
  }

  getActiveWorkstations() {
    return this.select('activeWorkstations')
  }

  getActiveWorkstationSessionsValue() {
    return this.getValue().activeWorkstationSessions ?? 0
  }

  getNdiStatus() {
    return this.select('ndi')
  }

  getRetryWorkstations() {
    return this.select('retryWorkstations')
  }

  getRetryWorkstationsValue() {
    return this.getValue().retryWorkstations
  }

  getActiveWorkstationsValue() {
    return this.getValue().activeWorkstations
  }

  getWorkstationTabInfo() {
    return this.select('tabInfo')
  }
}
