import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getActivities(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    // Set the default sort if it's not provided
    params.sort = params.sort || '-date_created'
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/activities`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getActivities', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
