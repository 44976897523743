import { trigger, transition, style, query, animateChild, group, animate, keyframes, state } from '@angular/animations'

// const upDownloadAnim = [
//   style({
//     position: 'relative',
//     top: 0,
//     transform: 'scale (1, 1)',
//     opacity: 1,
//   }),
//   animate(
//     '750ms ease-out',
//     keyframes([
//       style({ transform: 'scale(0.8, 0.9)', offset: 0.25 }),
//       style({ transform: 'scale(.92, 0.8)', offset: 0.5 }),
//       style({ transform: 'scale(0.95, .95)', offset: 0.75 }),
//       style({ transform: 'scale(1, 1)', offset: 1 }),
//     ])
//   ),
// ]

// // jelly
// const jello = [
//   style({
//     position: 'relative',
//     top: 0,
//     transform: 'none',
//     transformOrigin: 'center',
//     opacity: 1,
//   }),
//   animate(
//     '750ms ease-out',
//     keyframes([
//       style({ transform: 'none', offset: 0.111 }),
//       style({ transform: 'skewX(-12.5deg) skewY(-12.5deg)', offset: 0.222 }),
//       style({ transform: 'skewX(6.25deg) skewY(6.25deg)', offset: 0.333 }),
//       style({ transform: 'skewX(-3.125deg) skewY(-3.125deg)', offset: 0.444 }),
//       style({ transform: 'skewX(1.5625deg) skewY(1.5625deg)', offset: 0.555 }),
//       style({ transform: 'skewX(-0.78125deg) skewY(-0.78125deg)', offset: 0.666 }),
//       style({ transform: 'skewX(0.390625deg) skewY(0.390625deg)', offset: 0.777 }),
//       style({ transform: 'skewX(-0.1953125deg) skewY(-0.1953125deg)', offset: 0.888 }),
//       style({ transform: 'none', offset: 1 }),
//     ])
//   ),
// ]

let optional = { optional: true }
export const routeAnimations = trigger('routeAnimations', [
  // transition('Broadcast => *, DashboardEarth => *', []),
  // Splash to login
  transition('Splash => Login', [
    style({ position: 'relative' }),
    query(
      ':enter',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }),
      ],
      optional
    ),
    query(':leave', animateChild(), optional),
    group([
      query(
        '.logo-section, .state-login',
        [
          style({
            position: 'relative',
            top: 100,
            left: 0,
          }),
          animate('600ms ease-out', keyframes([style({ top: 50, offset: 0.5 }), style({ top: 0, offset: 1 })])),
        ],
        optional
      ),
      query('@*', animateChild(), optional),
    ]),
  ]),

  transition('* => Settings, * => Dashboard, * => Downloads, * => Uploads, * => Hotfolders, * => BebopFlex, * => Broadcast, * => DashboardEarth, * => Workstations, * => BebopLink', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      optional
    ),
    query(':enter', [style({ top: '0%' })], optional),
    query(':leave', [style({ opacity: 0 })], optional),
    // query(':leave', animateChild(), optional),
    group([
      // query(':leave', [animate('0ms ease-out', style({ left: '100%', opacity: 0 }))], optional),
      query(
        ':enter',
        [
          query(
            '.anim-panel',
            [
              style({ position: 'relative', opacity: 0 }),
              animate('150ms ease-in', keyframes([ style({ opacity: 1 }) ])),
            ],
            optional
          ),
        ],

        optional
      ),
      query('@*', animateChild(), optional),
    ]),
  ]),

  // transition('* => Workstations', [
  //   style({ position: 'relative' }),
  //   query(
  //     ':enter',
  //     [
  //       style({
  //         position: 'absolute',
  //         top: 0,
  //         left: 0,
  //         width: '100%',
  //       }),
  //     ],
  //     optional
  //   ),
  //   query(':leave', animateChild(), optional),

  //   group([
  //     query(
  //       '.wcard-0',
  //       [
  //         style({
  //           position: 'relative',
  //           top: 0,
  //           left: 299 + 19,
  //         }),
  //         animate('750ms ease-out', keyframes([style({ left: 159, offset: 0.5 }), style({ left: 0, offset: 1 })])),
  //       ],
  //       optional
  //     ),
  //     query(
  //       '.wcard-1',
  //       [
  //         style({
  //           position: 'relative',
  //           top: 0,
  //           left: -299 - 19,
  //         }),
  //         animate('750ms ease-out', keyframes([style({ left: -159, offset: 0.5 }), style({ left: 0, offset: 1 })])),
  //       ],
  //       optional
  //     ),
  //     query('@*', animateChild(), optional),
  //   ]),
  // ]),

  // transition('* => Downloads, * => Uploads, * => Hotfolders, * => BebopFlex', [
  //   style({ position: 'relative' }),
  //   query(
  //     ':enter, :leave',
  //     [
  //       style({
  //         position: 'absolute',
  //         top: 0,
  //         left: 0,
  //         width: '100%',
  //       }),
  //     ],
  //     optional
  //   ),
  //   query(
  //     ':leave',
  //     style({
  //       opacity: 0,
  //     })
  //   ),
  //   query(
  //     ':enter',
  //     group([
  //       query('.bebop-rocket-select-project', upDownloadAnim, optional),
  //       query('.rocket-panel', upDownloadAnim, optional),
  //       query('.select-panel', upDownloadAnim, optional),
  //       query('.drive-panel', upDownloadAnim, optional),
  //       query('@*', animateChild(), optional),
  //     ]),
  //     optional
  //   ),
  // ]),
])
