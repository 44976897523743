import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss'
})
export class ToggleComponent {
  @Input() isActive = false;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle() {
    this.isActive = !this.isActive;
    this.toggleChange.emit(this.isActive);
  }
}
