<div class="web-container inverted" id="web-container">
  <website-hero></website-hero>

  <div class="section">
    <website-section
      title="Powerful workstations on demand"
      description="Up and running in minutes. Pre-installed with all your favorite tools, plug-ins, and more. Just bring your license, we'll handle the rest."
      image="assets/img/content-web/sections/Img_WS@1x.png"
      [image]="{
        src: 'assets/img/content-web/sections/Img_WS.png',
        width: 480,
        height: 480,
        alt: 'Powerful workstations on demand',
        priority: true
      }"
    ></website-section>
  </div>

  <div class="section">
    <website-pre-installed></website-pre-installed>
  </div>

  <website-testimoni [testimoni]="testimoni"></website-testimoni>

  <div class="section n-pb">
    <website-section
      title="Secure, Flexible Storage"
      description="From camera to CREE8. No more shipping hard drives. No egress fees.
    Work locally or in the cloud, solo or with your team"
      image="assets/img/content-web/sections/Img_Storage.png"
      [image]="{
        src: 'assets/img/content-web/sections/Img_Storage.png',
        width: 480,
        height: 480,
        alt: 'Secure, Flexible Storage'
      }"
    ></website-section>

    <div class="ai">
      <website-ai-training></website-ai-training>
    </div>
  </div>

  <div class="testimoni-details">
    <website-testimoni
      [testimoni]="testimoniDetails"
      [large]="true"
    ></website-testimoni>
  </div>

  <website-section
    title="No IT Required"
    description="Ideal for globally dispersed teams collaborating on projects for
  maximum productivity and delivery speed."
    [right]="true"
    [image]="{
      src: 'assets/img/content-web/sections/Img_No_IT_Required.png',
      width: 480,
      height: 480,
      alt: 'No IT Required'
    }"
  ></website-section>

  <div class="section">
    <website-clients></website-clients>
  </div>

  <website-footer [directLink]="false"></website-footer>
  <website-copyright></website-copyright>
</div>
