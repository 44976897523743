import { Component, Inject, OnInit } from '@angular/core'

import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { LinkAction } from 'src/app/common/enums'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { environment } from 'src/environments/environment'

export type StartWorkstationActionType = 'Cancel' | 'Start' | 'Link'
export type StartWorkstationLinkActionType = 'Best Practices' | 'Zero Client' | 'PCoIP_Download'

export type StartWorkstationActionName = Exclude<StartWorkstationActionType | StartWorkstationLinkActionType, 'Link'>
export interface StartWorkstationAction {
  name: StartWorkstationActionType
  doNotShowAgain?: boolean
  linkAction?: 'Best Practices' | 'Zero Client'
}

@Component({
  selector: 'bebop-start-workstation',
  styleUrls: ['./start-workstation.component.scss'],
  templateUrl: './start-workstation.component.html',
})
export class StartWorkstationComponent implements OnInit, OnExternalModalClose {
  doNotShowAgain = false
  environment = environment

  constructor(
    private util: BebopClientUtilsService,
    public ref: ModalOverlayRef<StartWorkstationComponent, StartWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  continue() {
    this.ref.send({ doNotShowAgain: this.doNotShowAgain, name: 'Start' })
    this.ref.close()
  }

  onDoNotShowAgain(value: boolean) {
    this.doNotShowAgain = value
  }

  onAction(name: StartWorkstationActionName) {
    switch (name) {
      case 'Best Practices':
      case 'Zero Client':
        this.ref.send({ linkAction: name, name: 'Link' })
        break
      case 'Start':
        this.ref.send({ doNotShowAgain: this.doNotShowAgain, name: 'Start' })
        this.ref.close()
        break
      case 'PCoIP_Download':
        this.util.openExternalLink(LinkAction.PCOIP_CLIENTS_DOWNLOAD)
        this.ref.send({ doNotShowAgain: this.doNotShowAgain, name: 'Start' })
        this.ref.close()
        break
      case 'Cancel':
        this.ref.send({ name: 'Cancel' })
        this.ref.close()
    }
  }
}
