import { Injectable } from '@angular/core'
import { ChildProcess } from 'child_process'
import { WriteStream } from 'fs'
import { ToastService } from '../common/components/toast/toast.service'
import { ComponentModalService } from '../common/services/component-modal.service'
import { UiBebopLink } from '../components/rocket/common/classes/rocket-types'
import { BebopLink } from '../models/bebop.model'
import { UiProject } from '../models/ui.model'
import { BebopConfigService } from './bebop-config.service'
import { ElectronService } from './electron.service'
import { ExecutableService } from './executable.service'
import { UserService } from './user.service'

export const FLEX_PERFORMANCE_MONITOR = {
  FLEX_FOLDER_NAME: 'bbp-network-logs',
  LATENCY: [
    '5ms',
    '10ms',
    '25ms',
    '50ms',
    '100ms',
    '250ms',
    '500ms',
    '1s',
    '2.50s',
    '5s',
    '10s',
    '25s',
    '50s',
    '100s',
    '>',
  ] as const,
  LATENCY_INTERVAL_IN_SEC: 1,
  TIME_SERIES_WINDOW: 30 * 1000,
  SLIDE_WINDOW_SIZE_IN_MINUTES: 30,
  NORMALIZE_DATA: true, // Average out values
}

export type LatValue = 'low' | 'medium' | 'high' | 'extreme'

export type Lat = (typeof FLEX_PERFORMANCE_MONITOR.LATENCY)[number]
export type CumulativeLatency = {
  [key in Lat]: number
}

export type PerformanceMonitoringChartColorScheme = {
  [key in keyof CumulativeLatency]: string
}

export const newCumulativeLatencyData: () => CumulativeLatency = () => ({
  '5ms': 0,
  '10ms': 0,
  '25ms': 0,
  '50ms': 0,
  '100ms': 0,
  '250ms': 0,
  '500ms': 0,
  '1s': 0,
  '2.50s': 0,
  '5s': 0,
  '10s': 0,
  '25s': 0,
  '50s': 0,
  '100s': 0,
  '>': 0,
})

export const latMapping: Record<Lat, LatValue> = {
  '5ms': 'low',
  '10ms': 'low',
  '25ms': 'low',
  '50ms': 'low',
  '100ms': 'medium',
  '250ms': 'medium',
  '500ms': 'medium',
  '1s': 'high',
  '2.50s': 'high',
  '5s': 'high',
  '10s': 'high',
  '25s': 'extreme',
  '50s': 'extreme',
  '100s': 'extreme',
  '>': 'extreme',
} as const

export const latMappings: Record<LatValue, Lat[]> = (() => {
  let o: Record<LatValue, Lat[]> = {} as Record<LatValue, Lat[]>
  for (const [key, value] of Object.entries(latMapping)) {
    o[key] = o[key] || []
    o[key].push(value)
  }
  return o
})()

export const newChartColorScheme = [
  // green
  '#6EC281', //'rgba(50, 186, 120, 1)',
  // yellow
  '#FEC971', //'rgba(255, 193, 76, 0.8)',
  // orange
  '#F48C58', //'rgba(224, 133, 82, 0.8)',
  // red
  '#EB675B', //'rgba(241, 52, 75, 0.8)',
]

export interface FlexPerformanceMonitoringContext {
  project: UiProject | UiBebopLink
  instanceId: number
  err?: {
    get?: string
    put?: string
    write?: string
    read?: string
  }
}

export class LatencyMonitor {
  // cdata: CumulativeLatency

  cdata: Record<string, Record<LatValue, number>> = {}
  ncdata: Record<string, Record<LatValue, number>> = {}

  headerRead = false

  baseTs = Date.now()

  currentWindow = '0.5m'

  constructor() {
    this.onData = this.onData.bind(this)
  }

  private getWindow(d: Date) {
    let ts = d.getTime()
    let offset = ts - this.baseTs

    let secs = (offset / 1000) | 0 // remove millis
    let hmins = (secs / 30) | 0 // chop by half seconds
    let mins = hmins * 0.5 + 0.5 // start from 0.5m

    if (mins < 60) return `${mins}m`

    let hrs = (mins / 60) | 0
    mins = hrs * 60 - mins

    return `${hrs}h:${mins}m`
  }

  private getCurrentWindowData() {
    let cw = this.cdata[this.currentWindow]
    if (!cw) {
      cw = this.cdata[this.currentWindow] = {
        extreme: 0,
        low: 0,
        high: 0,
        medium: 0,
      }
    }

    return cw
  }

  private getCurrentNormalizedWindowData() {
    let cw = this.ncdata[this.currentWindow]
    if (!cw) {
      cw = this.ncdata[this.currentWindow] = {
        extreme: 0,
        low: 0,
        high: 0,
        medium: 0,
      }
    }

    return cw
  }

  onData(d: any) {
    if (!this.headerRead) {
      this.headerRead = true
      return
    }

    let data: string = d?.toString?.().trim?.()

    if (!data) return

    let [_ts, ...lat] = data.split(/\s+/)

    if (_ts == 'Timestamp') return

    let w = this.getWindow(new Date(_ts))

    this.currentWindow = w

    let ds = this.getCurrentWindowData()

    let latData = lat.map((l: string, i: number) => +l || 0)

    latData.forEach((d: number, idx: number) => {
      let pos = (idx + 1) % (FLEX_PERFORMANCE_MONITOR.LATENCY.length + 1)
      if (pos == 0) return
      let lvalue = latMapping[FLEX_PERFORMANCE_MONITOR.LATENCY[pos - 1]]
      ds[lvalue] += d
    })

    if (FLEX_PERFORMANCE_MONITOR.NORMALIZE_DATA) {
      let nds = this.getCurrentNormalizedWindowData()

      let total = 0
      Object.keys(ds).forEach((k) => {
        total += ds[k]
      })

      Object.keys(ds).forEach((k) => {
        nds[k] = +((100 * ds[k]) / (total || 1))?.toFixed(1)
      })
    }
  }

  get data() {
    return FLEX_PERFORMANCE_MONITOR.NORMALIZE_DATA ? this.ncdata : this.cdata
  }
}

export class LucidPerformanceMonitoring {
  perfStream: WriteStream
  infoStream: WriteStream
  latencyWriteStream: WriteStream
  latencyReadStream: WriteStream
  latencyGetStream: WriteStream
  latencyPutStream: WriteStream

  infoProc: ChildProcess
  perfProc: ChildProcess
  latencyWriteProc: ChildProcess
  latencyReadProc: ChildProcess
  latencyGetProc: ChildProcess
  latencyPutProc: ChildProcess

  latencyWriteMonitor: LatencyMonitor
  latencyReadMonitor: LatencyMonitor
  latencyGetMonitor: LatencyMonitor
  latencyPutMonitor: LatencyMonitor

  ele: ElectronService

  pathPrefix = ''
  basePath = ''
  perfFilename: string
  infoFilename: string
  latWriteFilename: string
  latGetFilename: string
  latPutFilename: string
  latReadFilename: string

  constructor(private ft: FlexPerformanceMonitoringService, private ctx: FlexPerformanceMonitoringContext) {
    if (!ft) throw new Error('FlexPerformanceMonitoring instance is missing')
    if (!ctx) throw new Error('FlexPerformanceMonitoringContext is missing')

    this.ele = ft?.ele
    this.init()
  }

  get fs() {
    return this.ele?.fs
  }

  get context() {
    return this.ctx
  }

  private createWriteStream(
    filename: string,
    opts: any = {
      flags: this.ele?.fs?.constants?.O_WRONLY | this.ele?.fs.constants?.O_CREAT,
      encoding: 'utf8',
      mode: 0o777,
      autoClose: true,
      emitClose: true,
      start: 0,
      highWaterMark: 1024,
    }
  ) {
    return this.ele?.fs?.createWriteStream(filename, opts)
  }

  private async init() {
    let path = this.ele.path

    let project = this.ctx.project

    this.ctx.err = {
      get: '',
      put: '',
      write: '',
    }

    let dstr = new Date().toJSON().replace(/[-:.]/g, '_')
    let basePath = (this.basePath = path.join(
      // do not use lucid path
      // this.ft.lucidLinkPath(project),
      // Use userDate path
      this.ft.ele.getUserDataPath(),
      FLEX_PERFORMANCE_MONITOR.FLEX_FOLDER_NAME,
      // include user/macid
      this.ft.user.username,
      this.ft.ele?.getMachineId?.()?.replace(/:/g, '_') ?? '',
      dstr
    ))

    await this.ele.fs.promises.mkdir(basePath, { recursive: true }).catch((e) => {
      if (e.code == 'EEXIST') return
      this.ft.onError('Creating flex performance monitoring directory failed.', e)
    })

    // this.pathPrefix = `${dstr}_${project.name.toLocaleLowerCase().replace(/[^\w\d]+/g, '_')}`
    this.pathPrefix = `${project.name.toLocaleLowerCase().replace(/[^\w\d]+/g, '_')}`
    this.perfFilename = path.join(basePath, `${this.pathPrefix}-perf.log`)
    this.infoFilename = path.join(basePath, `${this.pathPrefix}-info.log`)
    this.latWriteFilename = path.join(basePath, `${this.pathPrefix}-latency-write.log`)
    this.latReadFilename = path.join(basePath, `${this.pathPrefix}-latency-read.log`)
    this.latGetFilename = path.join(basePath, `${this.pathPrefix}-latency-get.log`)
    this.latPutFilename = path.join(basePath, `${this.pathPrefix}-latency-put.log`)

    this.perfStream = this.createWriteStream(this.perfFilename)
    this.infoStream = this.createWriteStream(this.infoFilename)
    this.latencyGetStream = this.createWriteStream(this.latGetFilename)
    this.latencyPutStream = this.createWriteStream(this.latPutFilename)
    this.latencyWriteStream = this.createWriteStream(this.latWriteFilename)
    this.latencyReadStream = this.createWriteStream(this.latReadFilename)

    this.connectPerfStream()
    this.connectInfoStream()
    this.connectLatGetStream()
    this.connectLatPutStream()
    this.connectLatWriteStream()
    this.connectLatReadStream()
  }

  private async connectPerfStream() {
    let cmd = await this.ft.exe.getLucidBaseCommand({
      instanceId: this.ctx.instanceId,
      command: 'perf',
    })

    this.perfProc = this.ele.exec(cmd)
    this.perfProc?.stdout?.pipe(this.perfStream)
  }

  private connectInfoStream() {
    let project = this.ctx.project

    let data = ['<status>\n']
    data.push(project.statusDetails?.ALL)

    data.push('\n\n<cache>\n')
    data.push(project.cacheDetails?.output?.stdout)

    data.push('\n\n<info>\n\n')

    this.infoStream.write(data.join('\n'), async () => {
      let cmd = await this.ft.exe.getLucidBaseCommand({
        instanceId: this.ctx.instanceId,
        command: 'info',
      })

      this.infoProc = this.ele.exec(cmd)
      this.infoProc?.stdout?.pipe(this.infoStream)
    })
  }

  private async connectLatStream(type: 'get' | 'put' | 'write' | 'read') {
    let cmd = await this.ft.exe.getLucidBaseCommand({
      instanceId: this.ctx.instanceId,
      command: 'latency',
      qs: `--${type} --seconds ${FLEX_PERFORMANCE_MONITOR.LATENCY_INTERVAL_IN_SEC}`,
    })

    return this.ele.exec(cmd)
  }

  private async connectLatGetStream() {
    this.latencyGetProc = await this.connectLatStream('get')
    this.latencyGetProc?.stdout?.pipe(this.latencyGetStream)
    this.latencyGetMonitor = new LatencyMonitor()
    this.latencyGetProc?.stdout?.on('data', this.latencyGetMonitor.onData)
    let onError = (d: any) => {
      let err = d?.message || (d?.toString?.() ?? '')
      this.context.err.get += err
      if (this.context.err.get.length > 1024)
        this.context.err.get = '…' + this.context.err.get.substring(this.context.err.write.length - 1024)

      console.log(`-- Flex PerformanceMonitoring error | ${this.latGetFilename}:`, err)
    }

    this.latencyGetProc?.stderr?.on('data', onError)
    this.latencyGetStream.on('error', onError)
  }

  private async connectLatPutStream() {
    this.latencyPutProc = await this.connectLatStream('put')
    this.latencyPutProc?.stdout?.pipe(this.latencyPutStream)
    this.latencyPutMonitor = new LatencyMonitor()
    this.latencyPutProc?.stdout?.on('data', this.latencyPutMonitor.onData)

    let onError = (d: any) => {
      let err = d?.message || (d?.toString?.() ?? '')
      this.context.err.put += err
      if (this.context.err.put.length > 1024)
        this.context.err.put = '…' + this.context.err.put.substring(this.context.err.write.length - 1024)

      console.log(`-- Flex PerformanceMonitoring error | ${this.latPutFilename}:`, err)
    }
    this.latencyPutProc?.stderr?.on('data', onError)
    this.latencyPutStream.on('error', onError)
  }

  private async connectLatWriteStream() {
    this.latencyWriteProc = await this.connectLatStream('write')
    this.latencyWriteProc?.stdout?.pipe(this.latencyWriteStream)
    this.latencyWriteMonitor = new LatencyMonitor()
    this.latencyWriteProc?.stdout?.on('data', this.latencyWriteMonitor.onData)

    let onError = (d: any) => {
      let err = d?.message || (d?.toString?.() ?? '')
      this.context.err.write += err

      if (this.context.err.write.length > 1024)
        this.context.err.write = '…' + this.context.err.write.substring(this.context.err.write.length - 1024)

      console.log(`-- Flex PerformanceMonitoring error | ${this.latWriteFilename}:`, err)
    }

    this.latencyWriteProc?.stderr?.on('data', onError)

    this.latencyWriteStream.on('error', onError)
  }

  private async connectLatReadStream() {
    this.latencyReadProc = await this.connectLatStream('read')
    this.latencyReadProc?.stdout?.pipe(this.latencyReadStream)
    this.latencyReadMonitor = new LatencyMonitor()
    this.latencyReadProc?.stdout?.on('data', this.latencyReadMonitor.onData)

    let onError = (d: any) => {
      let err = d?.message || (d?.toString?.() ?? '')
      this.context.err.read += err

      if (this.context.err.read.length > 1024)
        this.context.err.read = '…' + this.context.err.read.substring(this.context.err.read.length - 1024)

      console.log(`-- Flex PerformanceMonitoring error | ${this.latReadFilename}:`, err)
    }

    this.latencyReadProc?.stderr?.on('data', onError)
    this.latencyReadStream.on('error', onError)
  }

  close() {
    let err = this.ctx.err

    let out = [
      err.get ? `Get errors:\n${err.get}` : '',
      err.put ? `Put errors:\n${err.put}` : '',
      err.write ? `Write errors:\n${err.write}` : '',
    ].filter((x) => x)

    let errStream: WriteStream
    if (out.length) {
      let path = this.ele.path

      errStream = this.createWriteStream(path.join(this.basePath, `${this.pathPrefix}-errors.log`))
      errStream.write(out.join('\n'))
      errStream.end()
    }

    ;[
      this.infoProc,
      this.perfProc,
      this.latencyReadProc,
      this.latencyGetProc,
      this.latencyPutProc,
      this.latencyWriteProc,
    ].forEach((s) => s?.kill?.())
    ;[
      errStream,
      this.perfStream,
      this.infoStream,
      this.latencyGetStream,
      this.latencyPutStream,
      this.latencyReadStream,
      this.latencyWriteStream,
    ].forEach((s) => s?.close?.())
  }

  streams() {
    return {
      perf: this.perfStream,
      info: this.infoStream,
      lat: {
        get: this.latencyGetStream,
        put: this.latencyPutStream,
        write: this.latencyWriteStream,
        read: this.latencyReadStream,
      },
    }
  }

  latData() {
    return {
      get: this.latencyGetMonitor,
      put: this.latencyPutMonitor,
      write: this.latencyWriteMonitor,
      read: this.latencyReadMonitor,
    }
  }
}

interface PerformanceMonitoringMap {
  [key: string]: LucidPerformanceMonitoring
}

@Injectable({
  providedIn: 'root',
})
export class FlexPerformanceMonitoringService {
  constructor(
    private electronService: ElectronService,
    private bebopConfig: BebopConfigService,
    private modalService: ComponentModalService,
    private exeService: ExecutableService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  tsMap: PerformanceMonitoringMap = {}

  get ele() {
    return this.electronService
  }

  get exe() {
    return this.exeService
  }

  get user() {
    return this.userService.user
  }

  get running() {
    return Object.keys(this.tsMap).length != 0
  }

  onError(msg: string, e: Error) {
    console.log(msg, e.message)
    this.toastService.show({
      text: msg || 'PerformanceMonitoring error: ' + e.message,
      type: 'error',
    })
  }

  lucidLinkPath(item: UiProject | UiBebopLink) {
    return this.exeService.lucidLinkPath(item)
  }

  getInstance(item: UiProject | UiBebopLink) {
    return this.tsMap[item?.source?._id]
  }

  start(ctx: FlexPerformanceMonitoringContext) {
    let project = ctx.project

    if (!project?.source?._id || ctx?.instanceId < 0) {
      console.error('Invalid params for flex performance monitor', ctx)
      return
    }

    let its = this.tsMap[project?.source?._id]

    if (its?.context?.instanceId == ctx.instanceId) return its

    its?.close?.()

    its = this.tsMap[project?.source?._id] = new LucidPerformanceMonitoring(this, ctx)
    return its
  }

  stop(ctx: FlexPerformanceMonitoringContext) {
    this.tsMap[ctx.project?.source?._id]?.close?.()
    delete this.tsMap[ctx.project?.source?._id]
  }

  stopAll() {
    for (let id in this.tsMap) {
      this.tsMap[id]?.close?.()
    }
  }
}
