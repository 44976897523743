<cree8-modal [modal]="modal" (cancelModal)="onCancel()" (next)="submit()">
  <div class="user-settings-modal">

    <div class="user">
      <img [src]="getUserImage(user)" alt="{{ user?.name }}" width="32">
      <div class="info">
        <div class="body3">{{user.name}}</div>
        <div class="captions">{{user.email}}</div>
      </div>
    </div>

    <div class="workstation">
      <h4 class="body3">Workstation</h4>
      <div class="limit">
        <span class="body3">Access limited to select workstations</span>
        <cree8-toggle [checked]="user?.userPreferences?.assignedToWorkstations"
          (checkedChange)="onLimitWorkstations($event)">
        </cree8-toggle>
      </div>
    </div>

    <div class="subscription">
      <div>
        <h4 class="body3">Subscription</h4>
        <div class="unassigned-section">
          <div tabindex="0" class="tab" [ngClass]="assignSubscription ? 'active' : ''" (click)="assign()">
            Assigned
          </div>
          <div tabindex="0" class="tab" [ngClass]="!assignSubscription ? 'active' : ''" (click)="unassign()">
            Unassigned
          </div>
        </div>
      </div>

      <div class="subscription-section" *ngIf="assignSubscription">
        <bebop-input-select class="input-select-org" placeholder="Select subscription"
          [value]="selected.entitlement?.name || selected.entitlement?.description" selectOnly="true">
          <div dropdown-options>
            <bebop-input-select-option *ngFor="let entitlement of entitlementsAll"
              (click)="onSelectEntitlement(entitlement)">
              <div option-text>
                {{entitlement?.name || entitlement?.description }}
              </div>
            </bebop-input-select-option>
          </div>
        </bebop-input-select>

        <div class="role-list" *ngIf="entitlementGroups.length > 0">
          <h3 class="captions">Group</h3>
          <ul class="scrollbar">
            <li *ngFor="let group of entitlementGroups">
              <cree8-checkbox [label]="group.name" [isChecked]="this.selected.entitlementGroups.includes(group._id)"
                (isCheckedChange)="onSelectGroup($event, group)">
              </cree8-checkbox>
            </li>
          </ul>
        </div>
      </div>


    </div>
  </div>
</cree8-modal>
