import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'cree8-card-folder',
  styleUrls: ['./cree8-card-folder.component.scss'],
  templateUrl: './cree8-card-folder.component.html',
})
export class Cree8CardFolderComponent implements OnInit {
  constructor() {}

  @Input('title') title!: string
  @Input('folderSize') folderSize?: string
  @Input('items') items?: number

  @Output() titleClick = new EventEmitter<void>()

  ngOnInit(): void {}

  onTitleClick(): void {
    this.titleClick.emit()
  }
}
