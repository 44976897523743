import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
} from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit, AfterViewInit {
  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {}

  hasIcon = true
  isSmall = true
  canClose = false
  isError = false
  isClient = false
  isContained = false

  // colored tags are client tags (PcoIP, Jump etc)
  @Input('color') color: string
  @Input('bg-color') bgColor: string
  @Input('label') label: boolean | string
  @Input('add-class') clazz: string

  @Input('size') size: string

  // client tags should not have error set
  @Input('error') error: string | boolean
  @Input('error-message') errorMessage: string
  @Input('closable') closeable: string | boolean
  @Output('close') onClose = new EventEmitter<Event>()
  @Input('contained-layout') containedLayout: string | boolean
  @Input('tooltip-left-offset-percent-value') tooltipLeftOffsetValue: number | string

  @ViewChild('tagIcon') tagIcon

  ngOnInit(): void {
    this.isSmall = !['large', 'lg'].includes(this.size)
    this.canClose = this.util.isTrueLike(this.closeable)
    this.isError = this.util.isTrueLike(this.error)

    this.isContained = this.util.isTrueLike(this.containedLayout)

    if (this.isError) {
      this.color = ''
      this.bgColor = ''
    }

    this.isClient = !(this.color || this.bgColor)
  }

  ngAfterViewInit() {
    this.hasIcon = this.tagIcon?.nativeElement && this.tagIcon.nativeElement.children.length > 0
    this.cdRef.detectChanges()
  }

  onCloseEvent(ev: Event) {
    this.onClose.emit(ev)
  }
}
