import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'cree8-card-file',
  styleUrls: ['./cree8-card-file.component.scss'],
  templateUrl: './cree8-card-file.component.html',
})
export class Cree8CardFileComponent implements OnInit {
  constructor() {}

  @Input('title') title!: string

  @Input('image') image?: string
  @Input('icon') icon?: string
  @Input('folderSize') folderSize?: string
  @Output() titleClick = new EventEmitter<void>()

  ngOnInit(): void {}

  onTitleClick(): void {
    this.titleClick.emit()
  }
}
