const defaultColors = ['#c21c59', '#ed1454', '#fc4040', '#0538de', '#0932b8', '#0369b5']

const anime = (window as any).anime

const removeAnimation = (animations: any[]) => (animation: any) => {
  let index = animations.indexOf(animation)
  if (index > -1) animations.splice(index, 1)
}

const getFontSize = () => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize)
}

const createParticule = (
  ctx: CanvasRenderingContext2D,
  loc: { x: number; y: number },
  options: {
    numberOfParticles?: number
    distance?: number
    colors?: string[]
  }
) => {
  let p: {
    x: number
    y: number
    color?: string
    radius?: number
    alpha?: number
    lineWidth?: number
    draw?: () => void
  } = { ...loc }

  p.color = options.colors[anime.random(0, options.colors.length - 1)]
  p.radius = anime.random(getFontSize(), getFontSize() * 2)
  p.draw = function () {
    ctx.beginPath()
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true)
    ctx.fillStyle = p.color
    ctx.fill()
  }
  return p
}

const createParticles = (
  ctx: CanvasRenderingContext2D,
  loc: { x: number; y: number },
  options: {
    numberOfParticles?: number
    distance?: number
    colors?: string[]
  }
) => {
  let particules = []
  for (let i = 0; i < options.numberOfParticles; i++) {
    let p = createParticule(ctx, loc, options)
    particules.push(p)
  }
  return particules
}

const createCircle = (
  ctx: CanvasRenderingContext2D,
  loc: { x: number; y: number },
  options: {
    numberOfParticles?: number
    distance?: number
    colors?: string[]
  }
) => {
  let p: {
    x: number
    y: number
    color?: string
    radius?: number
    alpha?: number
    lineWidth?: number
    draw?: () => void
  } = { ...loc }

  p.color = options.colors[anime.random(0, options.colors.length - 1)]
  p.color = '#FFF'
  p.radius = 0
  p.alpha = 1
  p.lineWidth = 6
  p.draw = function () {
    ctx.globalAlpha = p.alpha
    ctx.beginPath()
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, true)
    ctx.lineWidth = p.lineWidth
    ctx.strokeStyle = p.color
    ctx.stroke()
    ctx.globalAlpha = 1
  }
  return p
}

const defaultFireworkOptions = {
  numberOfParticles: 24,
  colors: defaultColors,
  distance: 200,
  noCircles: false,
  delay: 0
}

export const fireworks = (
  canvas: HTMLCanvasElement,
  loc: { x: number; y: number },
  options: {
    numberOfParticles?: number
    distance?: number
    colors?: string[]
    noCircles?: boolean
    delay?: number
  } = defaultFireworkOptions
) => {
  let ctx = canvas.getContext('2d')
  let animations = []

  options = { ...defaultFireworkOptions, ...options }

  canvas.width = window.innerWidth
  canvas.height = window.innerHeight

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  let particules = createParticles(ctx, loc, options)
  let particulesAnimation = anime({
    targets: particules,
    x: (p: { x: number }) => p.x + anime.random(-options.distance, options.distance),
    y: (p: { y: number }) => p.y + anime.random(-options.distance, options.distance),
    radius: 0,
    duration: () => anime.random(1200, 1800),
    easing: 'easeOutExpo',
    complete: removeAnimation(animations),
  })

  animations.push(particulesAnimation)

  if (!options.noCircles) {
    let circle = createCircle(ctx, loc, options)

    let circleAnimation = anime({
      targets: circle,
      radius: () => anime.random(getFontSize() * 8.75, getFontSize() * 11.25),
      lineWidth: 0,
      alpha: {
        value: 0,
        easing: 'linear',
        duration: () => anime.random(400, 600),
      },
      duration: function () {
        return anime.random(1200, 1800)
      },
      easing: 'easeOutExpo',
      complete: removeAnimation,
    })
    animations.push(circleAnimation)
  }

  anime({
    duration: 60000,// one min
    update: function () {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      animations.forEach(function (anim) {
        anim.animatables.forEach(function (animatable) {
          animatable.target.draw()
        })
      })
    },
    delay: options.delay
  })

  return () => {
    anime.remove(canvas)
  }
}
