import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { SafeHtmlPipe } from 'src/app/common/pipes/safe-html.pipe'
import { BrowserUtilsService } from 'src/app/common/services/browser-utils.service'
import { OnExternalModalClose, BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'
import { ReleaseNote } from 'src/app/models/bebop.model'
import { ElectronService } from 'src/app/services/electron.service'

export interface ReleaseModalAction {
  name: 'Cancel' | 'Close' | 'Ok'
}

@Component({
  selector: 'bebop-release-modal',
  templateUrl: './release-modal.component.html',
  styleUrls: ['./release-modal.component.scss'],
  providers: [SafeHtmlPipe],
})
export class ReleaseModalComponent implements OnInit, OnExternalModalClose, OnDestroy {
  note: ReleaseNote
  mediaTag = ''
  releaseNotes: SafeHtml
  title = ''

  constructor(
    public ref: ModalOverlayRef<ReleaseModalComponent, ReleaseModalAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private util: BrowserUtilsService,
    private electronService: ElectronService
  ) {}

  ngOnInit(): void {
    if (!this.data.note) return

    this.note = this.data.note as ReleaseNote
    let note = this.note
    if (note.mediaUrl && note.mediaType) {
      switch (note.mediaType) {
        //iframe ?
        case 'image':
          this.mediaTag = '<img src="' + note.mediaUrl + '"/>'
          break
        case 'video':
          this.mediaTag = '<video controls autoplay><source src="' + note.mediaUrl + '"> </video>'
          break
      }
    }

    let releaseNotes = ''
    this.title = note.title

    if (note.releaseNoteComponents) {
      if (note.releaseNoteComponents.ALL) {
        releaseNotes = note.releaseNoteComponents.ALL + '<br>'
      }

      if (note.releaseNoteComponents.BEBOP_CLIENT) {
        releaseNotes += note.releaseNoteComponents.BEBOP_CLIENT
      }
    }

    if (!releaseNotes) {
      releaseNotes = note.releaseNotes || ''
    }

    this.releaseNotes = this.domSanitizer.bypassSecurityTrustHtml(this.util.decodeEntities(releaseNotes))
  }

  ngOnDestroy(): void {
    this.ref?.close()
  }

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  ok() {
    this.ref.send({ name: 'Ok' })
    this.ref.close()
  }

  changelog() {
    // hard coded
    this.electronService.openDefaultBrowser('https://changelog.beboptechnology.com')
  }
}
