<div class="bebop-tab-workstations" [class.no-filter]="!filteredWorkstations?.length || tabGroups?.length == 0">
  <div class="workstation-empty" *ngIf="filteredWorkstations?.length == 0 && !freeTier">
    <bebop-empty-tab-state [show]="ready" [type]="filteredResult ? 'Search Result' : 'Workstation'">
      <ng-container *ngIf="!filteredResult"> {{ noWorkstationsLabel }} </ng-container>
      <ng-container *ngIf="filteredResult">
        <div class="filter-no-result">
          <div class="filter-opt">
            <span class="selected-filter">Selected filters:</span>
            <ng-container *ngIf="filterApply?.state != 'All'">
              <bebop-tag [color]="'white'" [bg-color]="'rgba(45, 93, 224, 0.4)'" [label]="true">
                {{ filterApply?.state }}
              </bebop-tag>
            </ng-container>
            <ng-container *ngIf="filterApply?.users?.length">
              <bebop-tag [color]="'white'" [bg-color]="'rgba(99, 99, 224, 0.4)'" [label]="true">
                {{ filterApply?.users[0] }}
              </bebop-tag>
            </ng-container>
          </div>
          <span class="filter-text"
            >No results. We didn't find any workstation according to your filters request.<br />
            Try other filters or <a (click)="clearAllFilter()">clear all</a></span
          >
        </div>
      </ng-container>
    </bebop-empty-tab-state>
  </div>
  <div class="workstation-free-tier" *ngIf="freeTier">
      <svg class="ico" viewBox="0 0 48 48"></svg>
      <h2 class="headline2">Need high-performance workstations?</h2>
      <p class="body3">Workstation hours aren’t included in the free plan. Upgrade to unlock full power.</p>
      <div class="actions">
        <cree8-btn [appearance]="'text'" (click)="chatWithUs()">Chat with us</cree8-btn>
        <cree8-btn [appearance]="'filled'" (click)="upgrade()">Upgrade</cree8-btn>
      </div>
  </div>

  <div class="workstation-filter" [hidden]="!filteredWorkstations?.length || tabGroups?.length == 0">
    <div class="workstation-labels" #wsLabels [hidden]="tabGroups?.length == 0">
      <ng-container *ngIf="tabGroups?.length > 0">
        <div class="ws-label title fixed" (click)="onSelectTabGroup('All')" [class.active]="selectedTabGroup == 'All'">
          All
          <div class="badge small title">
            {{ filteredWorkstations?.length }}
          </div>
        </div>
        <div class="rest" (scroll)="onScroll($event)">
          <div
            class="ws-label title"
            (click)="onSelectTabGroup(g.name)"
            [class.active]="selectedTabGroup == g.name"
            *ngFor="let g of tabGroups; trackBy: byGroupName"
          >
            {{ g?.name }}
            <div class="badge small title" *ngIf="g?.count">
              {{ g?.count }}
            </div>
          </div>
        </div>
      </ng-container>
      <!-- <ng-container *ngIf="tabGroups?.length == 0">
        <div class="ws-label title single active" (click)="onSelectTabGroup('All')">
          All
          <div class="badge small title">
            {{ filteredWorkstations?.length }}
          </div>
        </div>
      </ng-container> -->
      <div class="ws-indicator" #wsIndicator></div>
    </div>
    <div class="scroll-button" #scrollbtn [hidden]="!showScrollButton">
      <bebop-button
        [icon-size]="'small'"
        class="left-scroll"
        [disabled]="!scroll.left"
        (on-click)="scrollTabGroupLeft()"
      >
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.35355 2.64645C5.54882 2.84171 5.54882 3.15829 5.35355 3.35355L2.70711 6H10.5C10.7761 6 11 6.22386 11 6.5C11 6.77614 10.7761 7 10.5 7H2.70711L5.35355 9.64645C5.54882 9.84171 5.54882 10.1583 5.35355 10.3536C5.15829 10.5488 4.84171 10.5488 4.64645 10.3536L1.14645 6.85355C0.951184 6.65829 0.951184 6.34171 1.14645 6.14645L4.64645 2.64645C4.84171 2.45118 5.15829 2.45118 5.35355 2.64645Z"
            fill="#5A5A68"
          />
        </svg>
      </bebop-button>
      <bebop-button
        [icon-size]="'small'"
        class="right-scroll"
        [disabled]="!scroll.right"
        (on-click)="scrollTabGroupRight()"
      >
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.64645 2.64645C6.84171 2.45118 7.15829 2.45118 7.35355 2.64645L10.8536 6.14645C11.0488 6.34171 11.0488 6.65829 10.8536 6.85355L7.35355 10.3536C7.15829 10.5488 6.84171 10.5488 6.64645 10.3536C6.45118 10.1583 6.45118 9.84171 6.64645 9.64645L9.29289 7H1.5C1.22386 7 1 6.77614 1 6.5C1 6.22386 1.22386 6 1.5 6H9.29289L6.64645 3.35355C6.45118 3.15829 6.45118 2.84171 6.64645 2.64645Z"
            fill="white"
          />
        </svg>
      </bebop-button>
    </div>
  </div>
  <div class="workstation-group-container" [hidden]="!filteredWorkstations?.length" slimScroll>
    <div class="workstation-group" *ngFor="let group of filteredGroups; trackBy: byGroupName">
      <div
        class="workstation-header"
        *ngIf="group.name != defaultGroup && group.workstations?.length > 0 && selectedTabGroup == 'All'"
      >
        <div class="divider"></div>
        <div class="group-details">
          <div class="group-name title">
            {{ group.name }}
          </div>

          <div class="group-count title">
            {{ group.workstations?.length }}
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="workstations">
        <div
          class="wcard"
          *ngFor="let station of group.workstations; let i = index; trackBy: byWorkstationId"
          [class]="i % 2 == 0 ? 'wcard-0' : 'wcard-1'"
        >
          <bebop-workstation-card
            [station]="station"
            [on-user-interaction]="cardUpdateLastUserInteraction"
          ></bebop-workstation-card>
        </div>
      </div>
    </div>
  </div>
</div>
