import { NgModule, ModuleWithProviders } from '@angular/core'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'

import { ToastComponent } from './toast.component'
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast.types'

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [ToastComponent],
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    }
  }
}
