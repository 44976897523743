import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'

import { Subject, takeUntil } from 'rxjs'
import { Option } from 'src/app/common/components/option/option.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BebopLink, LinkProtection } from 'src/app/models/bebop.model'
import { LinkService } from 'src/app/store/rocket/link/link.service'

export type ProtectStatus = 'success' | 'loading' | 'error' | 'password' | 'disable' | 'enable'
@Component({
  selector: 'link-protect',
  styleUrl: './link-protect.component.scss',
  templateUrl: './link-protect.component.html',
})
export class LinkProtectComponent implements OnChanges, OnDestroy {
  @Input() protectValue: string = 'NONE'
  @Input() link: BebopLink
  @Output() statusUpdateProtect: EventEmitter<ProtectStatus> = new EventEmitter<ProtectStatus>()
  @Output() changeOption: EventEmitter<string> = new EventEmitter<string>()
  showPasswordForm: boolean = false
  password: string = ''
  confirmPassword: string = ''
  errorConfirm: string = ''
  options: Option[] = [
    {
      description: 'Open to everyone with the link',
      title: 'Public',
      value: 'NONE',
    },
    {
      description: 'Access restricted to those with the password',
      title: 'Password Protected',
      value: 'PASSWORD',
    },
    {
      description: 'Limited to authenticated CREE8 users only',
      title: 'Log in required',
      value: 'USER',
    },
  ]

  private destroy$: Subject<void> = new Subject<void>()

  constructor(
    private linkService: LinkService,
    private toastService: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['protectValue']) {
      this.protectValue = changes['protectValue'].currentValue
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onUpdateProtect() {
    const status = this.protectValue as LinkProtection
    const data = { ...this.link, password: this.password }
    this.statusUpdateProtect.emit('loading')
    this.linkService
      .updateLinkProtect(data, status)
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {
        if (e.error) {
          this.toastService.show({
            text: 'CREE8 Link update failed',
            type: 'error',
          })
          this.statusUpdateProtect.emit('error')
          return
        }

        this.toastService.show({
          text: 'CREE8 Link has been updated',
          type: 'success',
        })
        this.statusUpdateProtect.emit('success')
      })
  }

  onProtectOptionSelected(value: string) {
    this.protectValue = value
    this.changeOption.emit(value)
  }

  onKeyupConfirmPassword(e: KeyboardEvent) {
    console.log(e, this.password, this.confirmPassword)
    this.errorConfirm = ''
    if (this.password !== this.confirmPassword) {
      this.statusUpdateProtect.emit('disable')
      this.errorConfirm = 'Password not match'
    } else {
      this.statusUpdateProtect.emit('enable')
    }
  }

  getValue() {
    const status = this.protectValue as LinkProtection
    const password = this.password

    return {
      password,
      status,
    }
  }
}
