import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { UploaderStore, UploaderState } from './uploader.store'
import { map, distinctUntilChanged, find, filter, first } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class UploaderQuery extends Query<UploaderState> {
  constructor(protected store: UploaderStore) {
    super(store)
  }

  getUploadSession(id: number) {
    return this.select('uploads').pipe(map((u) => u.find((m) => m.id == id)))
  }

  getUploadOnlySessions() {
    return this.select('uploads').pipe(map((u) => u.filter((m) => !m.hotfolder)))
  }

  getHotfolderSessions() {
    return this.select('uploads').pipe(map((u) => u.filter((m) => !!m.hotfolder)))
  }

  getSelectedTuple() {
    return this.select([(s) => s.selectedProject, (s) => s.selectedPod, (s) => s.selectedUpload])
  }

  getAny<K extends keyof UploaderState>(value: K) {
    //  : Observable<UploaderState[K]>
    return this.select(value)
  }

  // upload only - not hot folder
  getUploadOnlySessionByProjectValue(id: string) {
    let value = this.getValue()

    return value.uploads?.find((u) => u.project?._id == id && !u.hotfolder)
  }

  getComponentFor(router: Router) {
    return router.isActive('/app/upload', {
      matrixParams: 'exact',
      queryParams: 'exact',
      paths: 'subset',
      fragment: 'ignored',
    })
      ? 'Upload'
      : 'Link'
  }
}
