import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent implements OnInit {
  @Output('on-click-left') left = new EventEmitter<MouseEvent>()
  @Output('on-click-right') right = new EventEmitter<MouseEvent>()
  @Input('disabled') disabled: String

  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {}

  get isDisabled() {
    return this.disabled == 'disabled' || this.disabled == 'true'
  }

  ngOnInit(): void {}

  onClickLeft(e: MouseEvent) {
    if (this.isDisabled) return
    this.left.emit(e)
  }

  onClickRight(e: MouseEvent) {
    if (this.isDisabled) return
    this.right.emit(e)
  }
}
