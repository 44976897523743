import { Component, Inject, OnInit } from '@angular/core'

import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { SessionService } from 'src/app/store/session/session.service'

export interface LogoutPromptAction {
  name: 'Cancel' | 'Logout'
}

@Component({
  selector: 'bebop-logout-prompt',
  styleUrls: ['./logout-prompt.component.scss'],
  templateUrl: './logout-prompt.component.html',
})
export class LogoutPromptComponent implements OnInit, OnExternalModalClose {
  constructor(
    public ref: ModalOverlayRef<LogoutPromptComponent, LogoutPromptAction>,
    private sessionService: SessionService,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  busy = false
  ngOnInit(): void {
    // TODO
    this.busy = true // this.data.busy ?? false
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  stop() {
    this.sessionService.logout().subscribe(() => {
      this.ref.send({ name: 'Logout' })
      this.ref.close()
    })
  }
}
