<div class="bebop-input-date-picker datepicker" #datePicker>
  <bebop-input
    noInputLabel="true"
    leftIconSeparator="true"
    type="text"
    placeholder="mm-dd-yyyy"
    [binding]="dateStr"
    (click)="openDatePicker()"
    class="date-input"
  >
    <div class="icon-left" input-icon-left>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.6665 0.666992C6.12674 0.666992 6.49984 1.04009 6.49984 1.50033H11.4998C11.4998 1.04009 11.8729 0.666992 12.3332 0.666992C12.7934 0.666992 13.1665 1.04009 13.1665 1.50033C15.4677 1.50033 17.3332 3.36581 17.3332 5.66699V13.167C17.3332 15.4682 15.4677 17.3337 13.1665 17.3337H4.83317C2.53198 17.3337 0.666504 15.4682 0.666504 13.167V5.66699C0.666504 3.36581 2.53198 1.50033 4.83317 1.50033C4.83317 1.04009 5.20627 0.666992 5.6665 0.666992ZM11.4998 3.16699V4.00033C11.4998 4.46056 11.8729 4.83366 12.3332 4.83366C12.7934 4.83366 13.1665 4.46056 13.1665 4.00033V3.16699C14.5472 3.16699 15.6665 4.28628 15.6665 5.66699V13.167C15.6665 14.5477 14.5472 15.667 13.1665 15.667H4.83317C3.45246 15.667 2.33317 14.5477 2.33317 13.167V5.66699C2.33317 4.28628 3.45246 3.16699 4.83317 3.16699V4.00033C4.83317 4.46056 5.20627 4.83366 5.6665 4.83366C6.12674 4.83366 6.49984 4.46056 6.49984 4.00033V3.16699H11.4998ZM4.83317 6.50033C4.37293 6.50033 3.99984 6.87342 3.99984 7.33366C3.99984 7.7939 4.37293 8.16699 4.83317 8.16699H13.1665C13.6267 8.16699 13.9998 7.7939 13.9998 7.33366C13.9998 6.87342 13.6267 6.50033 13.1665 6.50033H4.83317ZM11.4998 13.167C11.4998 12.7068 11.8729 12.3337 12.3332 12.3337H13.1665C13.6267 12.3337 13.9998 12.7068 13.9998 13.167C13.9998 13.6272 13.6267 14.0003 13.1665 14.0003H12.3332C11.8729 14.0003 11.4998 13.6272 11.4998 13.167Z"
          fill="#2D5DE0"
        />
      </svg>
    </div>
  </bebop-input>
</div>
