import { Pipe, PipeTransform } from '@angular/core'
import { BrowserUtilsService } from '../services/browser-utils.service'

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  constructor(private util: BrowserUtilsService) {}

  transform(value: number | Date, opts: Intl.RelativeTimeFormatOptions, lang?: string): unknown {
    return this.util.getRelativeTimeString(value, opts, lang)
  }
}
