import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cree8-btn',
  styleUrls: ['./cree8-btn.component.scss'],
  templateUrl: './cree8-btn.component.html',
})
export class Cree8BtnComponent implements OnInit {
  constructor() {}

  @Input('disabled') disabled = false
  @Input('loading') loading = false
  @Input('appearance') appearance: 'filled' | 'outlined' | 'text' | 'danger' | 'secondary' = 'filled'
  @Input('size') size: 'small' | 'normal' = 'normal'
  @Input('fullwidth') fullwidth = false
  @Input() ariaLabel: string | null = null

  @Output() clicked = new EventEmitter<MouseEvent>()

  @HostListener('keydown.enter', ['$event'])
  onKeydown(e: KeyboardEvent) {
    this.handleClick(<MouseEvent>(<unknown>e))
  }

  ngOnInit(): void {}

  get generateClass() {
    return `${this.appearance} ${this.size} ${this.loading ? 'loading' : ''}`
  }

  handleClick(e: MouseEvent) {
    if (!this.disabled && !this.loading) {
      this.clicked.emit(e)
    }
  }
}
