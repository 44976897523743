<div class="user-container">
  <table class="cree8-table" *ngIf="users?.length > 0; else noUsers">
    <thead>
      <tr>
        <th>User</th>
        <th>Email</th>
        <th>Last activity</th>
        <th style="text-align: center;">Quick links</th>
      </tr>
    </thead>
    <tbody class="scrollbar">
      <tr *ngFor=" let user of users"  (click)="navigateToProfile(user._id)">
        <td>
          <div class="td-name">
            <div class="image" title="{{user.lastSeen}}">
              <img [src]="getUserImage(user)" alt="{{ user?.name }}">
              <span class="status" [ngClass]="user.status"></span>
            </div>
            {{ user.name }}
          </div>
        </td>
        <td>{{ user.email }}</td>
        <td>{{ user.last_login | date:'dd-MMM-yyyy hh:mm a' }}</td>
        <td>
          <div class="td-icons">
            <cree8-btn-icon cree8-tooltip-text="Settings" cree8-tooltip-position="top" (click)="onSettingsModal(user, $event)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Settings'"></use>
              </svg>
            </cree8-btn-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Template for when there are no invites -->
  <ng-template #noUsers>
    <no-data [title]="'No unassigned users'" [icon]="'Team'" [description]="''"></no-data>
  </ng-template>
</div>

<bebop-loader [status]="'Loading'" [rounded]="false" *ngIf="loading"></bebop-loader>

<user-settings *ngIf="active" [user]="selectedUser" [(active)]="active" (confirmed)="onCancelModal()"></user-settings>
