import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subject } from 'rxjs'
import { E } from 'src/app/common/classes/lib/tw-elements/util/keycodes'
import { IActionType } from 'src/app/common/components/actions-list/actions-list.component'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import {
  AddProjectModalAction,
  AddProjectModalComponent,
} from 'src/app/components/rocket/project/modals/add-project-modal/add-project-modal.component'
import { Project } from 'src/app/models/bebop.model'
import { LiveFilesQuery } from 'src/app/store/projects/live-files.query'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService } from 'src/app/store/projects/projects.service'

import { LinksProjectsComponent } from '../links-projects/links-projects.component'

import { AllProjectsComponent } from './all-projects/all-projects.component'

@Component({
  selector: 'projects-view',
  styleUrl: './projects-view.component.scss',
  templateUrl: './projects-view.component.html',
})
export class ProjectsViewComponent implements OnInit, OnDestroy {
  @ViewChild('linkProject') linkProjectComponent!: LinksProjectsComponent
  @ViewChild('allProjects') allProjectsComponent!: AllProjectsComponent

  _destroy$ = new Subject()
  hasLiveFiles: boolean = false

  headerTabs: ITabs[] = [
    { active: true, label: 'All Projects', value: 'all' },
    { label: 'Links', value: 'links' },
  ]

  filterTabsOnLinkProject: ITabs[] = [
    { active: true, label: 'Receive', value: '8' },
    { label: 'Shared', value: '1' },
    { label: 'Notes', value: '4' },
  ]

  listSortingProjects: { label: string; value: string }[] = [
    { label: 'Newest to oldest', value: '-date_created' },
    { label: 'Oldest to newest', value: 'date_created' },
    { label: 'From A to Z', value: 'name' },
    { label: 'From Z to A', value: '-name' },
  ]

  activeSorting: string = this.listSortingProjects[0].value
  activedViewType = 'grid'

  allowedActions: IActionType[] = ['search', 'refresh', 'sorting', 'view-type', 'created']
  activeParamsTab = 'all'
  project: Project | undefined

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private liveFilesQuery: LiveFilesQuery,
    private router: Router,
    private modalService: ComponentModalService,
    private projectsQuery: ProjectsQuery
  ) {
    this.projectsService.resetStore()
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      if (res['activeTab']) {
        this.activeParamsTab = res['activeTab']
        this.mappingActionListByHeaderTabs(this.activeParamsTab)
      } else if (res['addModal']) {
        this.addNewProject()
      }
    })

    this.liveFilesQuery
      .select((state) => state.liveFiles)
      .subscribe((liveFiles) => {
        if (liveFiles && liveFiles.length > 0) {
          // Check if any live file has a status different from 'start'
          const hasNonStartStatus = liveFiles.some((file) => file.status !== 'start')
          // Check if the 'Live' tab is already in the headerTabs array
          const liveTabExists = this.headerTabs.some((tab) => tab.value === 'live')

          // Push the tab only if it doesn't already exist
          if (!liveTabExists) {
            this.headerTabs.push({
              class: hasNonStartStatus ? 'not-active-live-file' : 'active-live-file', // Apply the class conditionally
              label: 'Live Status',
              value: 'live',
            })
            this.hasLiveFiles = true
          } else if (hasNonStartStatus) {
            // Update class if live tab exists and hasNonStartStatus is true
            this.headerTabs = this.headerTabs.map((tab) =>
              tab.value === 'live' ? { ...tab, class: 'not-active-live-file' } : tab
            )
          }
        }
      })

    this.projectsQuery.getViewTypeProjects().subscribe((response) => {
      this.activedViewType = response
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  onChooseHeaderTab(value: string) {
    this.router.navigate([], {
      queryParams: { activeTab: value },
    })
  }

  filterTabsLinks(value: string) {
    this.filterTabsOnLinkProject.forEach((tab) => {
      tab.active = tab.value === value
    })

    this.linkProjectComponent.filterByType(Number(value))
  }

  onRefresh() {
    this.allProjectsComponent.onRefresh()
  }

  onSearchValue(searchText: string) {
    this.projectsService.searchProjects(searchText)
  }

  onSorting(sorting: string) {
    if (this.activeParamsTab === 'links') {
      this.linkProjectComponent.sorting(sorting)
    } else {
      this.projectsService.sortProjects(sorting)
    }

    this.activeSorting = sorting
  }

  onViewType(viewType: string) {
    this.activedViewType = viewType
    this.projectsService.setViewTypeProjects(viewType)
  }

  onCreatedProject() {
    this.addNewProject()
  }

  addProject(e: MouseEvent) {
    this.addNewProject()
  }

  async addNewProject() {
    let ref = this.modalService.open<AddProjectModalComponent, AddProjectModalAction>(
      AddProjectModalComponent,

      {
        data: {},
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
      }
    )

    ref.events().subscribe(async (e) => {
      if (e.name == 'Add') {
        this.onRefresh()
      }
    })
  }

  private mappingActionListByHeaderTabs(valueTab: string) {
    if (valueTab === 'all') {
      this.allowedActions = ['search', 'refresh', 'sorting', 'view-type', 'created']

      this.listSortingProjects = [
        { label: 'Newest to oldest', value: '-date_created' },
        { label: 'Oldest to newest', value: 'date_created' },
        { label: 'From A to Z', value: 'name' },
        { label: 'From Z to A', value: '-name' },
      ]

      this.activeSorting = this.listSortingProjects[0].value
    }

    if (valueTab === 'links') {
      this.allowedActions = ['sorting']

      this.listSortingProjects = [
        { label: 'Active links only', value: 'active' },
        { label: 'Show deactivated', value: 'deactivated' },
        { label: 'Show expired only', value: 'expired' },
      ]

      this.activeSorting = this.listSortingProjects[0].value
    }

    if (valueTab === 'live') {
      this.allowedActions = []
      this.listSortingProjects = []

      this.activeSorting = ''
    }

    this.headerTabs.forEach((tab) => {
      tab.active = tab.value === this.activeParamsTab
    })
  }
}
