<div class="bebop-info-workstation" @slideAnim *ngIf="!_closed">
  <div class="header">
    <div class="header-icon">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.267 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-10 8c0-5.523 4.477-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10Zm10-1a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm0-4a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
          fill="#7F7F8C"
        />
      </svg>
    </div>
    <div class="header-title h5">Info</div>

    <div class="header-close" (click)="cancel()">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.293 5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293Z"
          fill="white"
        />
      </svg>
    </div>
  </div>

  <div class="action-section">
    <div class="top-section">
      <div class="h4 gateway-title">Connection Gateway</div>
      <div class="gateway paragraph">DNS: {{ data.gateway || '' }}</div>
    </div>

    <div class="bottom-section">
      <div class="h4 gateway-title">Installers</div>
      <div class="download-details">
        <div class="download-label paragraph">Download</div>
        <div class="download-link large-link green">
          <bebop-link-button (on-click)="onDownload('PCoIP')" underline="true">PCoIP Client</bebop-link-button>
        </div>
      </div>
    </div>
  </div>
</div>
