import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface ClientUpdateInstallAction {
  name: 'Cancel' | 'Install'
}

@Component({
  selector: 'bebop-client-update-install',
  templateUrl: './client-update-install.component.html',
  styleUrls: ['./client-update-install.component.scss'],
})
export class ClientUpdateInstallComponent implements OnInit, OnExternalModalClose {
  patch = false
  constructor(
    public ref: ModalOverlayRef<ClientUpdateInstallComponent, ClientUpdateInstallAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.patch = this.data.patch || false
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  install() {
    this.ref.send({ name: 'Install' })
    this.ref.close()
  }
}
