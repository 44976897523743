<div class="container">

  <div class="loader" *ngIf="isLoading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <!-- Folders -->
  <ng-container *ngIf="!noFolders">
    <div class="collapse-container">
      <div class="collapse-header">
        <div class="collapse-title headline4" (click)="toggleFolderCollapse()">
          <svg viewBox="0 0 24 24" class="chevron" [class.rotated]="!isFolderCollapsed">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Chevron_Single_Down"></use>
          </svg>
          Folders
        </div>
      </div>
      <div class="collapse-content" *ngIf="isFolderCollapsed">
        <!-- Folders -->
        <div class="folders-gallery-entries">
          <ng-container *ngFor="let folder of listFolders;">
            <card-file [type]="viewType" (actionFile)="onActionFile($event, folder)" [activeProject]="project"
              [file]="folder"></card-file>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!noFiles">
    <div class="collapse-container">
      <div class="collapse-header ">
        <div class="collapse-title headline4" (click)="toggleFileCollapse()">
          <svg viewBox="0 0 24 24" class="chevron" [class.rotated]="!isFileCollapsed">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Chevron_Single_Down"></use>
          </svg>
          Files
        </div>

        <cree8-btn-icon class="view-type" (click)="activeView(viewType === 'grid' ? 'list' : 'grid')" title="View type"
          size="small">
          <svg class="ico" viewBox="0 0 24 24">
            <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#' + (viewType === 'grid' ? 'List' : 'Grid')">
            </use>
          </svg>
        </cree8-btn-icon>
      </div>
      <div class="collapse-content" *ngIf="isFileCollapsed">
        <!-- Files -->
        <div class="file-gallery-entries" [class.files-grid]="viewType === 'grid'"
          [class.files-list]="viewType === 'list'">
          <ng-container *ngFor="let file of listFiles; let i = index; let isFirst = first; let isLast = last">
            <card-file [type]="viewType" [isFirst]="isFirst" [isLast]="isLast" (actionFile)="onActionFile($event, file)"
              [activeProject]="project" [file]="file"></card-file>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>


  <no-data [title]="'No files yet?'" [icon]="'Folder_Single'"
    [description]="'There are no files in this folder yet. You can upload files via the CREE8 client or create more folders.'"
    *ngIf="noFiles && noFolders && !isLoading"></no-data>
</div>

<cree8-modal [modal]="modalSetting" (cancelModal)="onCloseModal()" (next)="onSubmitModal()" [size]="
    modalSetting.title === MODAL_TITLE_SHARE_LINK ||
    modalSetting.title === MODAL_TITLE_RECEIVE_LINK ||
    modalSetting.title === MODAL_TITLE_NOTES_LINK
      ? 'normal'
      : 'large'
  " *ngIf="modalSetting.title !== ''">
  <ng-container *ngIf="modalSetting.title !== MODAL_TITLE_SHARE_LINK && modalSetting.title !== MODAL_TITLE_NOTES_LINK">
    <video width="100%" height="auto" controls *ngIf="viewSupportedVideoType(selectedFile) && streamUrl !== ''">
      <source [src]="streamUrl" [type]="extensionVideo(selectedFile)">
      Your browser does not support the video tag.
    </video>
    <audio width="100%" controls autoplay *ngIf="viewSupportedAudioType(selectedFile) && streamUrl !== ''">
      <source [src]="streamUrl" [type]="extensionAudio(selectedFile)">
    </audio>
    <div class="image-container">
      <img [src]="streamUrl" *ngIf="selectedFile?.type === 'image' && streamUrl !== ''" class="image-preview" />
    </div>
  </ng-container>

  <cree8-files-share-link
    *ngIf="modalSetting.title === MODAL_TITLE_SHARE_LINK || modalSetting.title === MODAL_TITLE_NOTES_LINK"
    [data]="selectedFile" [isLoading]="mediaInfoLoading" [mediaInfo]="mediaInfo" #shareLinkComponent
    (successCreate)="onSuccessCreateLink()"
    [isNotesLink]="modalSetting.title === MODAL_TITLE_NOTES_LINK"></cree8-files-share-link>

  <cree8-files-share-link *ngIf="modalSetting.title === MODAL_TITLE_RECEIVE_LINK" [data]="selectedFile"
    [receiveLink]="true" #shareLinkComponent (successCreate)="onSuccessCreateLink()"></cree8-files-share-link>
</cree8-modal>

<confirmation-modal *ngIf="modalDeleteActive" [data]="confirmationDeleteModal" (closeModal)="modalDeleteActive = false"
  (delete)="deleteFolderOrFile()"></confirmation-modal>
