import { Injectable } from '@angular/core'

import {
  ChildProcess as tChildProcess,
  ElectronRemote,
  fs as tfs,
  os as tos,
  path as tpath,
  url as turl,
} from 'src/@types/nodejs'
import { environment } from 'src/environments/environment'

import { MachineInfo } from '../components/rocket/common/classes/rocket-types'
export type ElectronHookCallback = (...args: any) => void

export type AutoUpdateState =
  | 'login'
  | 'checking-for-update'
  | 'update-available'
  | 'update-not-available'
  | 'update-cancelled'
  | 'download-progress'
  | 'update-downloaded'
  | 'error'

export interface PackageDomainUrls {
  support: string
  changelog: string
}

@Injectable({
  providedIn: 'root',
})
export class ElectronService {
  packageInfo: {
    version: string
    fullVersion: string
    title: string
    env: string
    enableConsoleDebug: boolean | string
    enableDebug: boolean | string
    enableNetworkErrorLog: boolean
    metadata: {
      name: string
      defaultClientProtocol: string
      domain: string
      server: string
      urls: PackageDomainUrls
    }
  }

  _platform = ''

  devToolsOpened = false

  helpCenterWindow: any
  printWindow: any

  private hooks: {
    [key: string]: ElectronHookCallback[]
  } = {}

  constructor() {}

  init() {
    return
  }

  onIpcRenderer(eventName: string, binding: (event: any, ...args: any[]) => void) {
    return
  }

  sendSyncIpcRenderer(eventName: string, data: any) {
    return null
  }

  sendIpcRenderer(eventName: string, data?: any) {
    return
  }

  get osone() {
    return true
  }

  get metadata() {
    return null
  }

  get app() {
    return null
  }

  get jwt() {
    return null
  }

  get path(): tpath {
    return {} as tpath
  }

  get url(): turl {
    return {} as turl
  }

  get fs(): tfs {
    return {} as tfs
  }

  checkForUpdates() {
    return
  }

  registerHookFor(action: string, hook: ElectronHookCallback) {
    return () => {}
  }

  onPackageInfo(event, res): void {
    return
  }

  getAppName() {
    return 'CREE8'
  }

  private onMessage(event, res) {
    return
  }

  get appVersion(): string {
    return '0.0.0'
  }

  get appFullVersion(): string {
    return '0.0.0'
  }

  get os(): tos {
    return {} as tos
  }

  get platform() {
    return navigator.platform
  }

  get isWindows() {
    return navigator.platform.toLowerCase() == 'win32'
  }

  get isMac() {
    return navigator.platform.toLowerCase() != 'win32'
  }

  get env(): string {
    return environment.name
  }

  get enableDebug() {
    return false
  }

  get remote(): any {
    return {}
  }

  get ChildProcess(): any {
    return {} as tChildProcess
  }

  exec(arg1?, arg2?, arg3?): any {
    return
  }

  execSync(arg1?, arg2?, arg3?): any {
    return
  }

  get spawn() {
    return (arg1?, arg2?, arg3?) => {}
  }

  getAppNameForProtocol(name: string) {
    return ''
  }

  notifyMain(event: string, value?: object): void {
    return
  }

  openExternal(url: string): Promise<void> {
    let target = '_self'
    if (url?.startsWith('https')) target = '_blank'
    window.open(url, target)
    return Promise.resolve()
  }

  openDefaultBrowser(location: string): Promise<void> {
    window.open(location, '_blank')
    return Promise.resolve()
  }

  oAuthRedirect(location: string): Promise<void> {
    window.open(location, '_self')
    return Promise.resolve()
  }

  prepareExitWindow() {
    return () => {}
  }

  openDevTools(): void {
    return
  }

  get machineName() {
    return 'Browser - User portal'
  }

  _machineInfo: MachineInfo = {
    arch: '',
    cpus: { model: '', speed: 0, total: 0 },
    platform: '',
    release: '',
    sysUser: '',
    type: '',
  }

  get machineInfo() {
    return this._machineInfo
  }

  private _downloadPath: string
  get downloadPath() {
    return ''
  }

  _machineId: Promise<string>

  getMachineId() {
    return 'ff:ff:ff:ff:ff:ff'
  }

  getUserDataPath() {
    return ''
  }

  getHome() {
    return ''
  }

  get MachineId() {
    return Promise.resolve('ff:ff:ff:ff:ff:ff')
  }

  pathInfo(name: string) {
    return {
      basename: '',
      dirname: '',
    }
  }

  isDevToolsOpened() {
    return false
  }

  get defaultDownloadPath(): string {
    return ''
  }

  showOpenDialog(options: any): Promise<any> {
    return Promise.resolve({})
  }

  showSaveDialog(options: any): Promise<any> {
    return Promise.resolve({})
  }

  getPrintWindow() {
    return {}
  }

  getHelpWindow() {
    return {}
  }

  _bindHooks = false
  private bindHooks() {
    return
  }

  handleHooks(action: string) {
    return () => {}
  }

  runHooks(action: string, ...args: any) {
    return
  }

  listenOnEvents() {
    return
  }

  decrypt(text: string, password: string): string | Error {
    return 'decrypted text'
  }

  encrypt(text: string, password: string): string | Error {
    return 'encrypted text'
  }

  quitAndInstall() {
    return
  }

  updateBadgeCount(n: number) {
    return
  }

  beep() {
    return
  }

  getPath(
    name:
      | 'home'
      | 'appData'
      | 'userData'
      | 'sessionData'
      | 'temp'
      | 'exe'
      | 'module'
      | 'desktop'
      | 'documents'
      | 'downloads'
      | 'music'
      | 'pictures'
      | 'videos'
      | 'recent'
      | 'logs'
      | 'crashDumps'
  ) {
    return ''
  }

  getJWTToken(data: any) {
    return 'dummy-jwt-token'
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
  }

  get isDevMode() {
    return false
  }

  isRunning(query: string, cb: (running: boolean, pid: string) => void) {
    cb(false, '')
  }

  minimize() {
    return
  }

  maximize() {
    return
  }

  closeWindow() {
    return
  }

  getWindowsDrives() {
    return Promise.resolve([])
  }

  applyPatchRelease() {
    return
  }

  async downloadUpdate() {
    return true
  }
}
