<div
  class="bebop-link-button"
  [class.disabled]="isDisabled"
  [class.icon-small]="isIconSmall"
  [class.icon-large]="isIconLarge"
  (click)="onClick($event)"
>
  <div
    class="button-container"
    [class.link-text]="textSize == 'xsmall'"
    [class.button-text]="textSize == 'small'"
    [class.large-button-text]="textSize == 'large'"
    [class.underlined]="isUnderlined"
  >
    <ng-content></ng-content>
  </div>
</div>
