<div class="container scrollbar">
  <div class="header">
    <h1 class="headline1">Organization settings</h1>
  </div>

  <div class="organization-settings" *ngIf="selectedOrg && organizationForm">
    <div class="left">
      <div class="information" [formGroup]="organizationForm">
        <div class="main-logo">
          <div *ngIf="!watermarkBase64['DEFAULT']" class="img-circle"></div>
          <img class="img-circle" *ngIf="watermarkBase64['DEFAULT']" [src]="getWaterMarkUrl('DEFAULT')" />

          <div class="profile-image-buttons">
            <div class="buttons">
              <cree8-image-upload [maxWidth]="500" [maxHeight]="500"
                (imageUploadSuccess)="handleImageUploadSuccess('DEFAULT',$event)"
                (imageUploadError)="handleImageUploadError($event)">
                <button class="btn">Upload</button>
              </cree8-image-upload>
              <button (click)="deleteWatermark('DEFAULT')" *ngIf="watermarkBase64['DEFAULT']"
                class="btn">Remove</button>
            </div>

            <span class="captions">Recommaned size: 500×500 px. Formats: PNG and JPG</span>
          </div>

        </div>

        <div class="inputs">
          <!-- Name Input -->
          <cree8-input class="input" type="text" placeholder="Name" [binding]="organizationForm.get('name').value"
            (bindingChange)="organizationForm.get('name').setValue($event)"
            [error]="organizationForm.get('name')?.invalid">
            <div input-label class="name-label">Name</div>
            <div input-error-label>Name is required.</div>
          </cree8-input>

          <!-- Email Input -->
          <cree8-input class="input" type="text" placeholder="Email Address"
            [binding]="organizationForm.get('email').value"
            (bindingChange)="organizationForm.get('email').setValue($event)"
            [error]="organizationForm.get('email')?.invalid">
            <div input-label class="email-label">Email</div>
            <div input-error-label>Invalid email format.</div>
          </cree8-input>

          <!-- Phone Input -->
          <cree8-input class="input" type="text" placeholder="Phone" [binding]="organizationForm.get('phone').value"
            (bindingChange)="organizationForm.get('phone').setValue($event)">
            <div input-label class="phone-label">Phone</div>
          </cree8-input>

          <!-- Website Input -->
          <cree8-input class="input" type="text" placeholder="Website" [binding]="organizationForm.get('website').value"
            (bindingChange)="organizationForm.get('website').setValue($event)">
            <div input-label class="website-label">Website</div>
          </cree8-input>
        </div>
        <div class="button">
          <cree8-btn (click)="updateProfile()" [disabled]="organizationForm.invalid">
            Update
          </cree8-btn>
        </div>
      </div>

      <div class="images">
        <h3 class="headline3">Customize your links</h3>
        <p class="body3" style="color: var(--text-secondary);">
          Enhance your links by incorporating your brand's logo, ensuring it pops against both light and
          dark
          backgrounds. Recommended dimensions: 700x200 px in PNG format for optimal clarity.
        </p>
        <div class="image">
          <div class="head">
            <span class="headline5">Horizontal light logo</span>
            <div *ngIf="!watermarkBase64['HORIZONTAL_LIGHT']" class="img-rectangle"> Your logo </div>
            <img class="img-rectangle" *ngIf="watermarkBase64['HORIZONTAL_LIGHT']"
              [src]=" getWaterMarkUrl('HORIZONTAL_LIGHT')" />
          </div>
          <div class="buttons">
            <cree8-image-upload [maxWidth]="700" [maxHeight]="200"
              (imageUploadSuccess)="handleImageUploadSuccess('HORIZONTAL_LIGHT',$event)"
              (imageUploadError)="handleImageUploadError($event)">
              <button class="btn">Upload</button>
            </cree8-image-upload>
            <button class="btn" (click)="deleteWatermark('HORIZONTAL_LIGHT')"
              *ngIf="watermarkBase64['HORIZONTAL_LIGHT']">Remove</button>
          </div>
        </div>

        <div class="image">
          <div class="head">
            <span class="headline5">Horizontal dark logo</span>
            <div *ngIf="!watermarkBase64['HORIZONTAL_DARK']" class="img-rectangle"> Your logo </div>
            <img class="img-rectangle" *ngIf="watermarkBase64['HORIZONTAL_DARK']"
              [src]="getWaterMarkUrl('HORIZONTAL_DARK')" />
          </div>
          <div class="buttons">
            <cree8-image-upload [maxWidth]="700" [maxHeight]="200"
              (imageUploadSuccess)="handleImageUploadSuccess('HORIZONTAL_DARK',$event)"
              (imageUploadError)="handleImageUploadError($event)">
              <button class="btn">Upload</button>
            </cree8-image-upload>
            <button class="btn" (click)="deleteWatermark('HORIZONTAL_DARK')"
              *ngIf="watermarkBase64['HORIZONTAL_DARK']">Remove</button>
          </div>
        </div>
      </div>

      <div class="workstation" *ngIf="podSettings">
        <h3 class="headline2">Workstation settings</h3>
        <!-- pod-settings.component.html -->
        <form [formGroup]="podSettings">
          <div class="settings">
            <cree8-input class="input" type="text" placeholder="Idle Timeout"
              [binding]="podSettings.get('idleTimeout').value"
              (bindingChange)="podSettings.get('idleTimeout').setValue($event)"
              [error]="podSettings.get('idleTimeout')?.invalid">
              <div input-label class="idleTimeout-label">Idle Timeout (in minutes)</div>
              <div input-error-label>Idle timeout is required.</div>
            </cree8-input>
            <div class="timezone">
              <div class="body3" for="workstationTimezone">Workstation Timezone</div>
              <div class="dropdown scrollbar">
                <select class="scrollbar" id="workstationTimezone" formControlName="workstationTimezone">
                  <option *ngFor="let timezone of workstationTimezoneList" [value]="timezone">
                    {{ timezone }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <cree8-btn (click)="updatePod()" [disabled]="podSettings.invalid">
            Update
          </cree8-btn>
        </form>
        <hr class="divider" />
        <div class="wallpaper">
          <h3>Workstation Wallpaper</h3>
          <div class="image-container">
            <div *ngIf="!podWatermarkBase64" class="img-rectangle"> No wallpaper yet </div>
            <img class="img-rectangle" *ngIf="podWatermarkBase64"
              [src]="'data:image/png;base64,' + podWatermarkBase64" />
          </div>

          <div class="buttons">
            <cree8-image-upload [maxWidth]="4000" [maxHeight]="2500"
              (imageUploadSuccess)="handleImageUploadSuccess('DESKTOP_WALLPAPER',$event)"
              (imageUploadError)="handleImageUploadError($event)">
              <button class="btn">Upload</button>
            </cree8-image-upload>
            <button class="btn" (click)="deleteWatermark('DESKTOP_WALLPAPER')"
              *ngIf="podWatermarkBase64">Remove</button>
          </div>
        </div>
      </div>
    </div>

    <div class="right" *ngIf="subscriptionDetails?.productName !== 'Custom plan'">

      <div class="ossst">
        <!-- Only show if subscriptionDetails exists -->
        <ng-container *ngIf="subscriptionDetails">
          <span class="h1">{{subscriptionDetails.productName}}</span>
          <!-- Format the amount as currency -->
          <div class="h1 money" *ngIf="subscriptionDetails.amount">
            ${{(subscriptionDetails.amount/100).toFixed(2)}}
          </div>
          <div class="dur" *ngIf="subscriptionDetails.interval">
            / {{subscriptionDetails.interval}}
          </div>
        </ng-container>
      </div>

      <div class="osssl-seats">

        <div class="osssl-seats-row">
          <div class="h3">Plan Details</div>
          <div class="dropdown dropdown-hub">
            <button class="btn btn-default save-pay" (click)="manageSubscription()">
              Manage Subscription
            </button>
          </div>
        </div>

        <!-- Only show if subscriptionDetails exists -->
        <ng-container *ngIf="subscriptionDetails">
          <div class="osssl-seats-row">
            <div class="seat-header h5">Overview</div>
            <div class="divider"></div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Subscription Type</div>
            <div class="seat-sub-header-value paragraph">
              {{subscriptionDetails.interval}}ly Subscription
            </div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Status</div>
            <div class="seat-sub-header-value paragraph text-u-c">
              {{subscriptionDetails.status}}
              <ng-container *ngIf="subscriptionDetails.cancellationInfo">
                (Valid until {{subscriptionDetails.cancellationInfo.validUntil | date:'MMM dd, yyyy'}})
              </ng-container>
            </div>
          </div>
          <div class="osssl-seats-row row-entry" *ngIf="!subscriptionDetails.cancellationInfo">
            <div class="seat-sub-header paragraph">Next Payment</div>
            <div class="seat-sub-header-value paragraph">
              {{subscriptionDetails.nextPaymentDate | date:'MMM dd, yyyy'}}
            </div>
          </div>
        </ng-container>

        <ng-container>
          <div class="osssl-seats-row">
            <div class="seat-header h5">Includes - Hours</div>
            <div class="divider"></div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Included With Your Plan</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                {{orgUsage?.workstation?.purchased ?? '0.00'}} Hours
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Total Number of Hours Used</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                {{(orgUsage?.workstation?.used === '0.00' ? '0.00' : orgUsage?.workstation?.used) ?? '0.00'}} Hours
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Cost Per Extra Hour</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                $ {{orgUsage?.workstation?.costPerUnit ?? '0.00' }}
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry" *ngIf="orgUsage?.workstation?.used > orgUsage?.workstation?.purchased">
            <div class="seat-sub-header paragraph">Extra Cost For Workstation Hours Overage</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                $ {{(orgUsage?.workstation?.used > orgUsage?.workstation?.purchased ? 
                    (orgUsage?.workstation?.used - orgUsage?.workstation?.purchased) * orgUsage?.workstation?.costPerUnit : 
                    0.00) ?? 0.00}}
              </div>
            </div>
          </div>
          <div class="osssl-seats-row">
            <div class="seat-header h5">Includes - Working Storage</div>
            <div class="divider"></div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Included With Your Plan</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                {{orgUsage?.workingStorage?.purchased / 1000 ?? '0.00'}} TB
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Used</div>
            <div class="seat-sub-header-value">
              <div class="paragraph badge-blue">
                {{orgUsage?.workingStorage?.used / 1000 ?? '0.00'}} TB
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Cost Per Extra GB</div>
            <div class="seat-sub-header-value">
              <div class="paragraph badge-blue">
                $ {{orgUsage?.workingStorage?.costPerUnit || '0.00'}}
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry" *ngIf="orgUsage?.workingStorage?.used > orgUsage?.workingStorage?.purchased">
            <div class="seat-sub-header paragraph">Extra Cost For Storage Overage</div>
            <div class="seat-sub-header-value">
              <div class="paragraph badge-blue">
                $ {{(orgUsage?.workingStorage?.used > orgUsage?.workingStorage?.purchased ? 
                    (orgUsage?.workingStorage?.used - orgUsage?.workingStorage?.purchased) * orgUsage?.workingStorage?.costPerUnit : 
                    0.00) ?? 0.00}}
              </div>
            </div>
          </div>
          <div class="osssl-seats-row">
            <div class="seat-header h5">Includes - Archive Storage</div>
            <div class="divider"></div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Included With Your Plan</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                {{orgUsage?.archiveStorage?.purchased / 1000 ?? '0.00'}} TB
              </div>
            </div>
          </div>    
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Used</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                {{orgUsage?.archiveStorage?.used / 1000 ?? '0.00'}} TB
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry">
            <div class="seat-sub-header paragraph">Cost Per Extra GB</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                $ {{orgUsage?.archiveStorage?.costPerUnit || '0.00'}}
              </div>
            </div>
          </div>
          <div class="osssl-seats-row row-entry" *ngIf="orgUsage?.archiveStorage?.used > orgUsage?.archiveStorage?.purchased">
            <div class="seat-sub-header paragraph">Extra Cost For Archive Storage Overage</div>
            <div class="seat-sub-header-value">
              <div class="paragraph">
                $ {{(orgUsage?.archiveStorage?.used > orgUsage?.archiveStorage?.purchased ? 
                    (orgUsage?.archiveStorage?.used - orgUsage?.archiveStorage?.purchased) * orgUsage?.archiveStorage?.costPerUnit : 
                    0.00) ?? 0.00}}
              </div>
            </div>
          </div>
        </ng-container>

        <div class="osssl-seats-row" *ngIf="subscriptionDetails?.addons.length">
          <div class="seat-header h5">Add-Ons</div>
          <div class="divider"></div>
        </div>
        <div class="osssl-seats-row row-entry" *ngFor="let addon of subscriptionDetails?.addons">
          <div class="seat-sub-header paragraph">{{addon.name}}</div>
          <div class="seat-sub-header-value">
            <div class="paragraph badge-grey">
              $ {{addon.price/100 }}
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>
</div>
