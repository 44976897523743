import { SafeUrl } from '@angular/platform-browser'

import { ChildProcess } from 'child_process'

import { ConnectionStatus, StorageStatus } from '../components/rocket/common/classes/rocket-types'
import { MountOption } from '../store/flex/flex.select.store'

import { BebopStorage, CastOperator, Organization, Pod, PodUserInfo, Project, Workstation } from './bebop.model'

export const UiNavStates = [
  'Dashboard',
  'Dashboard-Earth',
  'Workstations',
  'Projects',
  'Broadcast',
  'Upload',
  'Download',
  'HotFolder',
  'BebopLink',
  'BebopFlex',
  'Help',
  'Settings',
  'Logout',
  'Team',
] as const

export type UiNavState = (typeof UiNavStates)[number]

export type ServerState = 'Connected' | 'Connecting' | 'Error' | 'Loading' | 'Logout'

export interface UiWrapper<T> {
  _id: string
  name: string
  displayName?: string
  source?: T
}

export interface UiSelectable {
  selected?: boolean
}

export interface MountTypeSwitchable {
  get isSharedMount(): boolean
  get isLockedMount(): boolean
  get isReadableOthersMount(): boolean
  get isReadonlyMount(): boolean

  showContextDropdown?: boolean

  lockReadOnlyProject: () => void
  unlockProject: () => void
  lockProject: () => void

  switchTo: (s: 'lock' | 'unlock' | 'readable-others') => void
}

export interface UiFlexMetaFileSpace {
  // filespaces
  fsName?: string
  rootPoint?: string
  fsFormatVersion?: number
  fsBlockSize?: string
  fsState?: {
    service?: string
    store?: string
  }
  fsMode?: string
  versions?: {
    major: number
    minor: number
    revision: number
  }
}

export interface UiFlexMeta extends UiFlexMetaFileSpace {
  readOnly?: boolean
  readableByOthers?: boolean
  canLock?: boolean
  locked?: boolean
  lockedBy?: string
  createdBy?: string
  expiryLabel?: string
  almostExpired?: boolean
  isLink?: boolean
  syncing?: boolean
  selfLockOwned?: boolean

  indexNotReady?: boolean // indexStatus == 'up-to-date'
  mountTypeSwitcher?: MountTypeSwitchable

  totalUploadInProgress?: number // in bytes
  syncUploadCompletePercent?: number // totalUploadInProgress - remainingUpload in %
  remainingUpload?: string
  syncStatus?: string
  indexStatus?: string
  cacheSize?: string

  mounted?: boolean
  mounting?: boolean
  unmounting?: boolean

  lockFeature?: boolean
  mountOption?: MountOption
}

export interface UiOrganization extends UiWrapper<Organization>, UiSelectable {
  activePodCount?: number
  logo?: SafeUrl
}

export interface UiPod extends UiWrapper<Pod>, UiSelectable {
  location?: string
  users?: PodUserInfo
  projectsCount?: number
  iname?: string
}

export interface UiStorage extends UiWrapper<BebopStorage>, UiSelectable {
  iname?: string
}

export type FlexPinStatus = 'Pinned' | 'Pinning' | 'Failed'
export interface FlexPin {
  file: string
  status: FlexPinStatus
}
export interface UiProject extends UiWrapper<Project>, UiSelectable, UiFlexMeta {
  sizeStr?: string
  iname?: string

  storageUsed?: number
  storageStatus?: StorageStatus
  storageStatusMessage?: string
  connectionStatus?: ConnectionStatus
  pinnedFiles?: string[]
  pinnedList?: FlexPin[]
  statusDetails?: any
  cacheDetails?: any

  isLink?: boolean
  link?: string
  expires?: string
  notes?: string

  flexLandingTab?: string
}

export enum UiBroadcastStatus {
  Live = 'Live',
  Starting = 'Starting',
  Pending = 'Pending',
  Stopped = 'Stopped',
  Unspecified = '',
}

export enum StreamQuality {
  HQ = 'HQ',
  SQ = 'SQ',
}

export interface UiBroadcast {
  status?: UiBroadcastStatus
  streamSource?: string
  streamSourceFullLink?: string
  username?: string
  runtime?: string // Date
  quality?: StreamQuality
  allowedQuality?: StreamQuality
  clients?: {
    active?: number
    total?: number
  }

  securitySettings?: BroadcastLinkSecurity

  source?: CastOperator
  started?: Date

  earth?: ChildProcess
  activeNdi?: boolean
}

export interface BroadcastLinkSecurity {
  linkSecurity: LinkSecurity
  expiry: LinkSecurityExpiry
  password?: string
  confirmPassword?: string
  endTime?: Date
}

export enum LinkSecurity {
  Public,
  Password,
  BebopLogin,
}

export enum LinkSecurityExpiry {
  Infinite,
  SetTime,
}

export enum GridSort {
  Up = 'Up',
  Down = 'Down',
  Default = 'Default',
}

export interface NdiStatus {
  message?: string
  quality?: StreamQuality
  castActive?: boolean
  workstation?: Workstation
  castOperator?: CastOperator
  currentCastClientId?: string
}

export const ALL_POD_LABEL = 'All Pods'
export const ALL_POD_ID = '(sentinal)'
