import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { NavigationExtras, Router, RouterOutlet } from '@angular/router'
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs'
import { routeAnimations } from '../../animations/route-animations'
import { CSSSize } from '../../types'

export interface TabItem<T = any> {
  name: string
  route: string
  extras?: NavigationExtras
  routeAction?: () => void
  badgeCount?: () => number | string
  badgeColor?: string
  minWidth?: CSSSize
  defaultBadgeColor?: string
  iconTemplate?: TemplateRef<T>
  selected?: boolean
  cssClass?: string
}

export type TabType = 'box' | 'outline' | 'box-header' | 'outline-header'

@Component({
  selector: 'bebop-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [routeAnimations],
})
export class TabsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('tabs') tabs: TabItem[]

  @Input('type') type: TabType

  tabType: TabType

  @Input('refreshTrigger') refreshTrigger: (s: Subject<boolean>) => void

  @Input('secondaryOutlet') secondaryOutlet: boolean

  @ViewChild('navTabs') navTabs: ElementRef<HTMLElement>
  @ViewChild('navIndicator') navIndicator: ElementRef<HTMLElement>

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.clearTimeout()
    this._timeout = window.setTimeout(() => this.updateSelectedRoute(), 360)
  }

  @ViewChild('o') routlet: RouterOutlet

  refresh$ = new BehaviorSubject<boolean>(true)
  _refresh$: Subscription

  initialTabSet = false

  constructor(private router: Router, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.tabs?.length <= 0) {
      console.warn('<bebop-tabs> without tabs attr or tabs attr is of zero length')
    }

    this.tabType = this.type?.includes('outline') ? 'outline' : 'box'

    this._refresh$ = this.refresh$.pipe(
      debounceTime(200)
    ).subscribe(b => {
      this.updateSelectedRoute()
    })
  }

  ngAfterViewInit(): void {
    this.clearTimeout()
    this._timeout = window.setTimeout(() => this.updateSelectedRoute(), 96)
    // this.updateSelectedRoute()

    this.refreshTrigger?.(this.refresh$)
  }

  ngOnDestroy(): void {
    ;[this.refresh$, this._refresh$].forEach(x => x?.unsubscribe())
  }

  trackByItem(index: number, item: TabItem<any>) {
    return item?.name || item?.route || index
  }

  updateSelectedRoute() {
    let cs: HTMLCollection = this.navTabs?.nativeElement?.children
    let pos = this.tabs?.findIndex((t) => t.selected) ?? 0
    if (cs) {
      let next = cs[pos]

      let parentRect = this.navTabs.nativeElement.getBoundingClientRect()

      let sty = getComputedStyle(this.navTabs.nativeElement)

      let left = +sty.marginLeft.replace('px', '')
      let pleft = +sty.paddingLeft.replace('px', '')

      let distances = Array.from(cs).reduce((acc, el, idx) => {
        let rect = el.getBoundingClientRect()
        acc.push([
          rect.top - parentRect.top + (this.tabType == 'outline' ? rect.height : 0),
          left + rect.left - parentRect.left - (this.type == 'outline-header' ? pleft : 0),
        ])
        return acc
      }, [])

      let nrect = next.getBoundingClientRect()

      if (this.tabType == 'box') {
        this.navIndicator.nativeElement.style.width = `${nrect.width}px`
        this.navIndicator.nativeElement.style.height = `${nrect.height}px`
      }

      if (this.type == 'outline-header') {
        this.navIndicator.nativeElement.style.width = `${nrect.width + pleft * 2}px`
      }

      this.navIndicator.nativeElement.style.top = `${distances[pos][0]}px`
      this.navIndicator.nativeElement.style.left = `${distances[pos][1]}px`
      this.navIndicator.nativeElement.style.visibility = 'visible'
    }
  }

  onRouteChange(tab: TabItem, pos: number) {
    this.tabs?.forEach((t) => (t.selected = false))
    tab.selected = true

    // pseudo navigation
    if (tab.route) {
      if (this.secondaryOutlet) {
        let segments = tab.route.split('/')
        this.router
          .navigate([
            ...segments.slice(0, segments.length - 1),
            { ...tab.extras, outlets: { secondary: segments[segments.length - 1] } },
          ])
          .catch(console.error)
      } else {
        this.router.navigate([tab.route], tab.extras).catch(console.error)
      }
    }

    tab.routeAction?.()
    this.clearTimeout()
    this._timeout = window.setTimeout(() => this.updateSelectedRoute(), 360)
  }

  clearTimeout() {
    if (this._timeout == -1) return
    window.clearTimeout(this._timeout)
    this._timeout = -1
  }

  _timeout = -1
  ngOnChanges(changes: SimpleChanges) {
    if (!changes['tabs']?.currentValue?.length) return

    let idx = this.tabs?.findIndex(t => t.selected)
    if (this.initialTabSet || idx != -1) {
      this.clearTimeout()


      if (idx != -1) {
        this.onRouteChange(this.tabs[idx], idx)
      }
      // this._timeout = window.setTimeout(() => this.updateSelectedRoute(), 360)
      return
    }

    // first time set 0th index
    if (this.tabs?.length) {
      let selectedIndex = this.tabs.findIndex(t => t.route == this.router.url)
      if (selectedIndex != -1) {
        this.initialTabSet = true
        this.tabs[selectedIndex].selected = true
        this.onRouteChange(this.tabs[selectedIndex], 0)
        return
      } 
      this.initialTabSet = true
      this.tabs[0].selected = true
      this.onRouteChange(this.tabs[0], 0)
      // this.router.navigate([this.tabs[0].route]).catch(console.error)
    }

    this.cdRef.detectChanges()
  }
}
