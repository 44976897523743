import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { LinkAction } from '../common/enums'
import { TokenResponse } from '../models/response.model'

import { BebopConfigService } from './bebop-config.service'
import { ElectronService } from './electron.service'

const compactNumFormatter = Intl.NumberFormat('en', { notation: 'compact' })

// for hh:mm:ss 00:10:03
const timeFormatter = new Intl.DateTimeFormat('en-GB', {
  hour: '2-digit',
  hour12: false,
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'Etc/UTC',
})

@Injectable({
  providedIn: 'root',
})
export class BebopClientUtilsService {
  constructor(
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    private http: HttpClient
  ) {}

  isTrueLike(o: string | boolean) {
    return o == 'true' || o == true
  }

  toStr(o: any) {
    try {
      return typeof o === 'string' ? o : JSON.stringify(o)
    } catch (e) {
      return o
    }
  }

  viewSupportedVideoType(file: { name: string }) {
    let ext = file.name.toLowerCase().split('.').pop()
    return ['mp4', 'mov'].indexOf(ext) != -1
  }

  viewSupportedAudioType(file: { name: string }) {
    let ext = file.name.toLowerCase().split('.').pop()
    return ['mp3', 'wav', 'aac', 'aacp', 'flac', 'caf'].indexOf(ext) != -1
  }

  revSupportedType(file: { name: string }) {
    let revTypes = ['mp4', 'mov', 'mkv', 'wav', 'mp3']
    let extension = file.name.toLowerCase().split('.').pop()

    return revTypes.indexOf(extension) != -1
  }

  generateRandomASCIIBytes(len: number = 5) {
    let min = 0x20,
      max = 0x7f,
      range = max - min + 1,
      i = 0,
      result = []
    for (; i < len; i++) {
      result.push(String.fromCharCode(Math.floor(Math.random() * range) + min))
    }
    return result.join('')
  }

  readablizeBytes(bytes, fixed: number = 2) {
    if (bytes === 0) return '0 B'
    if (!bytes) return '-'
    let s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let e = Math.floor(Math.log(bytes) / Math.log(1024))
    return (bytes / Math.pow(1024, e)).toFixed(fixed) + ' ' + s[e]
  }

  readablizeBytesRounded(bytes: number = 0, ceil = true) {
    if (!bytes) return '0 B'
    let s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let e = Math.floor(Math.log(bytes) / Math.log(1024))
    return Math[ceil ? 'ceil' : 'floor'](bytes / Math.pow(1024, e)) + ' ' + s[e]
  }

  readablizeBits(bytes: number = 0, fixed: number = 2) {
    let bits = bytes * 8
    if (!bits) return '0 b'
    let s = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']
    let e = Math.floor(Math.log(bits) / Math.log(1024))
    return (bits / Math.pow(1024, e)).toFixed(fixed) + ' ' + s[e]
  }

  getCompactNumber(num: number) {
    return compactNumFormatter.format(num)
  }

  geBadgeNumber(num: number) {
    let n = this.getCompactNumber(num)
    return n == '0' ? '' : n
  }

  secondsToStr(sec: number) {
    const numberEnding = (n: number) => (n > 1 ? 's' : '')

    let years = Math.floor(sec / 31536000)
    if (years) {
      return years + ' yr' + numberEnding(years)
    }
    let days = Math.floor((sec %= 31536000) / 86400)
    if (days) {
      return days + ' day' + numberEnding(days)
    }
    let hours = Math.floor((sec %= 86400) / 3600)
    if (hours) {
      return hours + ' hr' + numberEnding(hours)
    }
    let minutes = Math.floor((sec %= 3600) / 60)
    if (minutes) {
      return minutes + ' min' + numberEnding(minutes)
    }
    let seconds = sec % 60
    return seconds + ' sec' + numberEnding(seconds)
  }

  getFormattedDate(date = new Date()) {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      '-' +
      date.getHours() +
      '-' +
      date.getMinutes() +
      '-' +
      date.getSeconds()
    )
  }

  // MMM dd, yyyy hh:mm:ss
  getDateTimeString(date = new Date()) {
    if (!date) return ''
    let ds = date?.toDateString?.() ?? ''

    let [, m, d, y] = ds.split(' ')

    return `${m} ${d}, ${y} ${this.getTimeString(date)}`
  }

  // dd MMM yyyy (29 Jun 2022)
  getFormattedDateOnly(date: Date) {
    if (!date) return ''
    let ds = date?.toDateString?.() ?? ''

    let [, m, d, y] = ds.split(' ')

    return `${d} ${m} ${y}`
  }

  // hh:mm:ss (00:10:02)
  getTimeStringWithoutOffset(millis: number | Date) {
    let d = millis instanceof Date ? millis : new Date(millis ?? 0)
    return timeFormatter.format(d)
  }

  // d(d):hh(h):mm(m) (7d:12h:32m)
  getDateTimeMinString(millis: number | Date) {
    let now = Date.now()
    let d = millis instanceof Date ? millis : new Date(millis ?? 0)

    if (d.getFullYear() >= 2099) return 'No expiry'

    let diff = d.getTime() - now

    if (diff < 0) return '-0d:00h:00m'

    let dys = (diff / (24 * 60 * 60 * 1000)) | 0
    diff -= dys * (24 * 60 * 60 * 1000)
    let hrs = (diff / (60 * 60 * 1000)) | 0
    diff -= hrs * 60 * 60 * 1000
    let mins = (diff / (60 * 1000)) | 0

    let yr = (dys / 365) | 0
    dys -= yr * 365

    let denom = ['d', 'h', 'm']
    let out = [dys, hrs, mins]
      .map((x, i) => (i == 0 ? `${x}${denom[i]}` : x < 10 ? `0${x}${denom[i]}` : `${x}${denom[i]}`))
      .join(':')

    if (yr) {
      out = `${yr}y:${out}`
    }

    return out
  }

  // hh:mm:ss of date
  getTimeString(millis: number | Date) {
    let d = millis instanceof Date ? millis : new Date(millis ?? 0)
    return d.toTimeString().substring(0, 8)
  }

  getHelpCenterToken(data: { user: string }) {
    return this.http.post<TokenResponse>(`${this.bebopConfig.apiUrl}/api/v1/login/token/new`, data).pipe(
      catchError((error: any) => {
        console.error('On getHelpCenterToken', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getLastNumber(val: string) {
    if (!val) return 0

    let lastNum = 0
    let lastNumStr = val.substring(val.lastIndexOf('-') + 1)
    try {
      lastNum = parseInt(lastNumStr)
    } catch (err) {}

    return lastNum
  }

  naturalSort<T>(property: string) {
    let sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substring(1)
    }

    let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    return (a: T, b: T) => {
      let aIndex = a[property]
      let bIndex = b[property]

      let result = collator.compare(aIndex, bIndex)
      return result * sortOrder
    }
  }

  dynamicSortLastIndex<T>(property: string) {
    let sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substring(1)
    }

    return (a: T, b: T) => {
      let aIndex = this.getLastNumber(a[property])
      let bIndex = this.getLastNumber(b[property])

      //let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      let result = aIndex < bIndex ? -1 : aIndex > bIndex ? 1 : 0
      return result * sortOrder
    }
  }

  getLocalStorageItem(key: string, noParse = true) {
    let item = localStorage.getItem(key)
    if (noParse) return item
    try {
      return JSON.parse(item)
    } catch (e) {
      console.log('local storage error', key, item)
    }
    return item
  }

  setLocalStorageItem(key: string, value: any) {
    return localStorage.setItem(key, typeof value == 'string' ? value : JSON.stringify(value))
  }

  removeLocalStorageItem(key: string) {
    return localStorage.removeItem(key)
  }

  openHelpCenter(articleId?: string, context?: { userId: string }) {
    if (environment.browser) {
      window.open('https://support.cree8.io/en/', '_blank')
    } else {
      let helpWindow: any = this.electronService.getHelpWindow()
      helpWindow.loadURL('https://support.cree8.io/en/')
      return
    }
    // Get login token for login to new window
    if (!context?.userId) return
    this.getHelpCenterToken({ user: context?.userId }).subscribe((e: TokenResponse) => {
      if (e.error) {
        console.error('Fetch token to login failed for help center')
        return
      }

      let token = e.token
      let helpWindow: any = this.electronService.getHelpWindow()
      let helpWindowURL = this.bebopConfig.apiUrl + '/tokenLogin/' + token
      helpWindowURL += '?articleId=' + (articleId || 'home')
      this.electronService.enableDebug && helpWindow.openDevTools({ mode: 'detach' })
      helpWindow.loadURL(helpWindowURL)
      helpWindow.setMenu(null)
      helpWindow.show()
      helpWindow.webContents.on('did-finish-load', function () {
        helpWindow.setTitle('CREE8 Help Center')
      })
    })
  }

  // public page - print is not enabled by default in electron - ignore for now
  openPrintWindow(context: { title: string; urlSegment: string }) {
    let printWindow: any = this.electronService.getPrintWindow()
    let printWindowURL = this.bebopConfig.apiUrl + '/' + context?.urlSegment
    this.electronService.enableDebug && printWindow.openDevTools({ mode: 'detach' })
    printWindow.loadURL(printWindowURL)
    // printWindow.setMenu(null)
    printWindow.show()
    printWindow.webContents.on('did-finish-load', function () {
      printWindow.setTitle(context.title || 'CREE8 MCP')
    })
  }

  openShell(url: string) {
    return this.electronService.openExternal(url)
  }

  openExternalLink(action: LinkAction, context?: { userId: string }) {
    switch (action) {
      case LinkAction.LAUNCH_SESSION_BEST_PRACTICES:
        this.openHelpCenter('4000157386', context)
        break
      case LinkAction.ZERO_CLIENT:
        this.openHelpCenter('4000029329', context)
        break
      case LinkAction.CAST_BEST_PRACTICES:
      case LinkAction.SUPPORT_WORKSTATION:
        this.openHelpCenter('4000157386', context)
        break
      case LinkAction.SUPPORT_UPLOAD:
        this.openHelpCenter('4000095434', context)
        break
      case LinkAction.SUPPORT_BROADCASTS:
        this.openHelpCenter('4000184025', context)
        break
      case LinkAction.SUPPORT_FLEX:
        this.openHelpCenter('4000174438', context)
        break
      case LinkAction.SUPPORT_DOWNLOAD:
        this.openHelpCenter('4000144315', context)
        break
      case LinkAction.SUPPORT_HOT_FOLDER:
        this.openHelpCenter('4000144323', context)
        break

      case LinkAction.NDI_OSX_DOWNLOAD:
        this.openShell('https://ndi.video/tools/')
        break
      case LinkAction.NDI_WIN_DOWNLOAD:
        this.openShell('https://ndi.video/tools/')
        break
      case LinkAction.SUPPORT_PORTAL:
        this.openShell(this.bebopConfig.supportUrl)
        break
      case LinkAction.PCOIP_CLIENTS_DOWNLOAD:
        this.openShell('https://docs.teradici.com/find/product/hp-anyware')
        break
      case LinkAction.PARSEC_DOWNLOAD:
        this.openShell('https://parsec.app/downloads')
        break
      case LinkAction.JUMP_DOWNLOAD:
        this.openShell('https://jumpdesktop.com')
        break
      case LinkAction.LUCID_DOWNLOAD:
        this.openShell('https://www.lucidlink.com/download#other-platforms-classic')
        break
      case LinkAction.BBP_BROADCASTER_DOWNLOAD_OSX:
        this.openShell(this.bebopConfig.apiUrl + '/installers/bbpbroadcaster/osx')
        break
      case LinkAction.BBP_BROADCASTER_DOWNLOAD_WIN:
        this.openShell(this.bebopConfig.apiUrl + '/installers/bbpbroadcaster/win')
        break
      case LinkAction.BBP_CLIENT_DOWNLOAD: {
        let url = this.bebopConfig.apiUrl + '/installers/firefly/auto'
        if (window.location.href?.indexOf('beta') != -1) {
          url = this.bebopConfig.apiUrl + '/installers/firefly/auto/beta'
        }
        this.openShell(url)
        break
      }
      case LinkAction.QUICK_START_GUIDE_BEBOP_SOLO_FREE_TRIAL:
        this.openShell(
          `${this.bebopConfig.supportUrl}/support/solutions/articles/4000094964-quick-start-guide-bebop-solo-free-trial`
        )
        break

      case LinkAction.SUPPORT_BEBOP_LINK:
      case LinkAction.SUPPORT_MAIN:
      default:
        this.openHelpCenter('', context)
        break
    }
  }

  isValidBase64(str: string) {
    return !!str?.replace(/\r?\n/g, '')?.match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/)
  }

  extractLinkID(url: string) {
    if (typeof url != 'string') return ''
    let linkToken = url
    try {
      linkToken = new URL(linkToken).pathname
    } catch (e) {}

    return linkToken.replaceAll('/', '')
  }
}
