import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

export interface IEntitlementForUserPayload {
  organization: string
  entitlement: string
  groups: string[]
  users: string[]
}
@Injectable({
  providedIn: 'root',
})
export class UserEntitlementsService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getUserEntitlementsByOrganization(userId: string): Observable<any> {
    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/user/${userId}/entitlementsByOrganization`).pipe(
      catchError((error: any) => {
        console.error('On getUsers', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateEntitlementForUser(data: IEntitlementForUserPayload) {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/user_entitlement/edit_user`, data).pipe(
      catchError((error: any) => {
        console.error('On updateEntitlementForUser', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
