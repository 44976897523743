export const INDUSTRIES_ITEMS = [
  {
    id: 'media',
    label: 'Media & Entertainment',
    href: 'https://cree8.io/media-entertainment/',
    type: 'item',
  },
  {
    id: 'in-house-agencies',
    label: 'In House Agencies',
    href: 'https://cree8.io/in-house-agencies/',
    type: 'item',
  },
  {
    id: 'live-sports',
    label: 'Live Sports',
    href: 'https://cree8.io/live-sports/',
    type: 'item',
  },
  {
    id: 'gaming-studios',
    label: 'Gaming Studios',
    href: 'https://cree8.io/gaming-studios/',
    type: 'item',
  },
]

export const RESOURCE_ITEMS = [
  {
    id: 'blog',
    label: 'Blog',
    href: 'https://cree8.io/blog/',
    type: 'item',
  },
  {
    id: 'help',
    label: 'Help Center',
    href: 'https://support.cree8.io/en/',
    type: 'item',
  },
  {
    id: 'trust',
    label: 'Trust Center',
    href: 'https://trust.cree8.io/',
    type: 'item',
  },
]

export const CENTER_MENU_ITEMS = [
  {
    id: 'pricing',
    label: 'Pricing',
    href: 'https://cree8.io/pricing/',
    type: 'item',
  },
  {
    id: 'blog',
    label: 'Blog',
    href: 'https://blog.cree8.io/blog',
    type: 'item',
  },
  {
    id: 'industries',
    label: 'Industries',
    href: '#',
    type: 'item',
    showDropdown: true,
    items: INDUSTRIES_ITEMS,
  },
  {
    id: 'company',
    label: 'Company',
    href: 'https://cree8.io/company/',
    type: 'item',
  },
  {
    id: 'resources',
    label: 'Resources',
    href: '#',
    type: 'item',
    showDropdown: true,
    items: RESOURCE_ITEMS,
  },
  {
    id: 'contact',
    label: 'Contact',
    href: 'https://cree8.io/contact',
    type: 'item',
  },
]

export const RIGHT_MENU_ITEMS = [
  {
    id: 'demo',
    label: 'Book A Demo',
    href: 'https://calendly.com/cree8/cree8-demo?month=2024-07',
    type: 'item',
  },
  {
    id: 'login',
    label: 'Log in',
    href: 'https://my.cree8.io/',
    type: 'item',
  },
  {
    id: 'signup',
    label: 'Sign Up',
    href: 'https://my.cree8.io/signup/personal-details?free=true',
    type: 'item',
  },
]

export const SIGNUP_BUTTON = {
  id: 'signup-for-free',
  label: 'Sign Up For Free',
  href: 'https://store.cree8.io/signup',
  type: 'button',
}

export const DEMO_BUTTON = {
  id: 'demo',
  label: 'Book A Demo',
  href: 'https://calendly.com/cree8/cree8-demo?month=2024-07',
  type: 'button',
}
