import { Injectable } from '@angular/core'

import { TeamStore } from './team.store'

@Injectable({ providedIn: 'root' })
export class TeamService {
  constructor(private teamStore: TeamStore) {}

  updateActiveTab(activeTab: string) {
    this.teamStore.update({ activeTab })
  }

  triggerRefresh() {
    this.teamStore.update({ action: 'refresh' })
  }

  // Trigger search action with search query
  triggerSearch(search: string) {
    this.teamStore.update({
      action: 'search',
      queryParams: {
        ...this.teamStore.getValue().queryParams, // Keep other queryParams unchanged
        searchText: search,
      },
    })
  }

  // Trigger sort action with sort value
  triggerSort(sort: string) {
    this.teamStore.update({
      action: 'sort',
      queryParams: {
        ...this.teamStore.getValue().queryParams, // Keep other queryParams unchanged
        sort: sort,
      },
    })
  }

  clear() {
    this.teamStore.reset()
  }
}
