import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { Pagination } from 'src/app/common/types'
import { ObjectId, Organization, OrgEntitlements, Pod, Project, User, Workstation } from 'src/app/models/bebop.model'
import {
  BebopClientLogRequest,
  ForgotPasswordRequest,
  LoginProviderRequest,
  LoginRequest,
  OrgWatermarkRequest,
} from 'src/app/models/request.model'
import {
  AddProjectResponse,
  AnnouncementsResponse,
  EntitlementsResponse,
  GenericResponse,
  IntercomSettingResponse,
  LoginProviderResponse,
  LoginResponse,
  LogoutResponse,
  OrganizationsResponse,
  OrgWatermarkResponse,
  PasswordResetResponse,
  PermissionsResponse,
  PodResponse,
  PodsResponse,
  ProjectResponse,
  ProjectsResponse,
  RecaptchaSettingResponse,
  StoragesResponse,
  WorkstationsResponse,
} from 'src/app/models/response.model'
import { IDashboardCache } from 'src/app/models/session.model'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'
import { IntercomService } from 'src/app/services/intercom.service'

import { BebopClientAnnouncement, createInitialState, SessionStore } from './session.store'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
}

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private sessionStore: SessionStore,
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    private intercomService: IntercomService
  ) {}

  updateUserInfo(user: Partial<User>) {
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          loggedIn: true,
          user: {
            ...store.user,
            ...user,
          },
        })
    )
  }

  // it gives time for teardown
  triggerLogout() {
    this.intercomService.shutdownIntercom()
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          loggedIn: false,
        })
    )
  }

  forceLogout() {
    this.sessionStore.update(() => createInitialState())
  }

  setOrganizations(organizations: Organization[]) {
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          organizations: organizations,
        })
    )
  }

  updatePodsToOrganization(orgId: string, pods: Pod[] = []) {
    const orgs = this.sessionStore.getValue()?.organizations || []
    const org = orgs?.find((o) => o._id == orgId)

    if (!org) return
    // orgs = orgs?.filter((o) => o._id != orgId)
    org.pods = pods ?? []
    this.sessionStore.update((store) => {
      return (store = {
        ...store,
        organizations: [...orgs],
      })
    })
  }

  setPods(pods: Pod[]) {
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          pods: pods,
        })
    )
  }

  updatePod(pod: Pod) {
    const value = this.sessionStore.getValue()
    const pods = value.pods
    const idx = pods.findIndex((p) => p._id == pod._id)
    if (idx == -1) return
    pods[idx] = { ...pods[idx], ...pod }
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          pods: [...pods],
        })
    )
  }

  updateAnnouncements(announcements: BebopClientAnnouncement) {
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          announcements,
        })
    )
  }

  updatePodWorkstation(podId: string, workstations: Workstation[] = []) {
    const value = this.sessionStore.getValue()
    const pods = value.pods
    const idx = pods.findIndex((p) => p._id == podId)
    if (idx == -1) return
    pods[idx] = { ...pods[idx], workstations }
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          pods: [...pods],
        })
    )
  }

  setToken(token: string) {
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          token,
        })
    )
  }

  setEntitlements(entitlements: OrgEntitlements) {
    this.sessionStore.update(
      (store) =>
        (store = {
          ...store,
          entitlements: entitlements,
        })
    )
  }

  getIntercomSettings() {
    return this.http.get<IntercomSettingResponse>(`${this.bebopConfig.apiUrl}/api/v1/settings/intercom-settings`).pipe(
      catchError((error: any) => {
        console.error('error get intercom settings', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getRecaptchaSettings() {
    return this.http
      .get<RecaptchaSettingResponse>(`${this.bebopConfig.apiUrl}/api/v1/settings/recaptcha-settings`)
      .pipe(
        catchError((error: any) => {
          console.error('On getRecaptchaSettings', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getCurrentUser() {
    return this.http.get<LoginResponse>(`${this.bebopConfig.apiUrl}/api/v1/users/me`).pipe(
      catchError((error: any) => {
        console.error('On getCurrentUser', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getLoginProvider(data: LoginProviderRequest) {
    return this.http.post<LoginProviderResponse>(`${this.bebopConfig.apiUrl}/api/v1/oauth2/fetch`, data).pipe(
      catchError((error: any) => {
        console.error('On getLoginProvider', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  login(data: LoginRequest) {
    return this.http.post<LoginResponse>(`${this.bebopConfig.apiUrl}/login`, data).pipe(
      catchError((error: any) => {
        console.error('On login', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  loginSMS2FA(data: LoginRequest) {
    return this.http.post<LoginResponse>(`${this.bebopConfig.apiUrl}/login-sms2fa`, data).pipe(
      catchError((error: any) => {
        console.error('On loginSMS2FA', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  loginTOTP2FA(data: LoginRequest) {
    return this.http.post<LoginResponse>(`${this.bebopConfig.apiUrl}/login-totp2fa`, data).pipe(
      catchError((error: any) => {
        console.error('On loginTOTP2FA', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  resendSMS2FA(data: LoginRequest) {
    return this.http.post<LoginResponse>(`${this.bebopConfig.apiUrl}/resend-sms2fa`, data).pipe(
      catchError((error: any) => {
        console.error('On resendSMS2FA', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  sendPasswordResetEmail(data: ForgotPasswordRequest) {
    return this.http.post<PasswordResetResponse>(`${this.bebopConfig.apiUrl}/api/v1/users/resetPassword`, data).pipe(
      catchError((error: any) => {
        console.error('On sendPasswordResetEmail', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  oAuthLogin(location: string) {
    this.electronService.openDefaultBrowser(`${this.bebopConfig.apiUrl}${location}`)
  }

  openDevTools() {
    this.electronService.openDevTools()
  }

  pollAuthStatus(authType: string, data: LoginRequest) {
    return this.http.post<LoginResponse>(`${this.bebopConfig.apiUrl}/${authType}-status`, data).pipe(
      catchError((error: any) => {
        console.error('On pollEmail2FA', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getEntitlements() {
    return this.http
      .get<EntitlementsResponse>(`${this.bebopConfig.apiUrl}/api/v1/user/entitlementsByOrganization`)
      .pipe(
        catchError((error: any) => {
          console.error('On getEntitlements', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getUserPermissions(userId: string) {
    return this.http.get<PermissionsResponse>(`${this.bebopConfig.apiUrl}/api/v1/permission_group/${userId}`).pipe(
      catchError((error: any) => {
        console.error('On fetchUserPermissions', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOrgs(userId: string) {
    return this.http.get<OrganizationsResponse>(`${this.bebopConfig.apiUrl}/api/v1/organizations/user/${userId}`).pipe(
      catchError((error: any) => {
        console.error('On getOrgs', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOrgWatermark(data: OrgWatermarkRequest) {
    return this.http
      .post<OrgWatermarkResponse>(`${this.bebopConfig.apiUrl}/api/v1/organizations/watermarks`, data)
      .pipe(
        catchError((error: any) => {
          console.error('On getOrgWatermark', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  logActivity(data: BebopClientLogRequest) {
    return this.http.post<GenericResponse>(`${this.bebopConfig.apiUrl}/api/v1/bebopClient/log`, data).pipe(
      catchError((error: any) => {
        console.error('On logActivity', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getAnnouncements() {
    return this.http.get<AnnouncementsResponse>(`${this.bebopConfig.apiUrl}/api/v1/bebop/announcements`).pipe(
      tap((res) => {
        this.updateAnnouncements(res?.bebopClient ?? {})
      }),
      catchError((error: any) => {
        console.error('On getAnnouncements', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getPodById(podId: string): Observable<Partial<PodResponse>> {
    return this.http.get<PodsResponse>(`${this.bebopConfig.apiUrl}/api/v1/client/pods/${podId}`).pipe(
      tap((res) => {
        //this.updatePod(<Pod>(<unknown>res))
      }),
      catchError((error: any) => {
        console.error('On getPodById', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  // pod lite
  filterPods(params: { pageOptions?: Pagination; searchText?: string } = {}): Observable<Partial<PodsResponse>> {
    let queryParams = new HttpParams().set('size', params?.pageOptions?.size || 1000)
    queryParams = queryParams.append('page', params?.pageOptions?.page || 1)
    queryParams = queryParams.append('searchText', params?.searchText || '')

    return this.http
      .get<PodsResponse>(`${this.bebopConfig.apiUrl}/api/v1/pods/lite`, {
        headers: httpOptions.headers,
        params: queryParams,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On filterPod', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  //myprojects
  searchProjects(
    params: {
      searchText?: string
      pageOptions?: Pagination
      orgs?: string[]
      podId?: string
      uploadOnly?: boolean
      downloadOnly?: boolean
    } = {}
  ): Observable<Partial<ProjectsResponse>> {
    const data = {
      downloadOnly: params?.downloadOnly || false,
      organizations: params?.orgs || [],
      podId: params?.podId,
      searchText: params?.searchText || '',
      uploadOnly: params?.uploadOnly || false,
      userPage: params?.pageOptions?.page || 1,
      userSize: params?.pageOptions?.size || 10,
    }
    return this.http.post<ProjectsResponse>(`${this.bebopConfig.apiUrl}/api/v1/myprojects`, data).pipe(
      catchError((error: any) => {
        console.error('On searchProjects', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  searchFlexProjects(params: {
    searchText?: string
    pageOptions?: Pagination
    orgs: string[]
    podId?: string
    filterBy?: string[]
  }): Observable<Partial<ProjectsResponse>> {
    const data = {
      filterBy: params.filterBy ?? [],
      organizations: params?.orgs || [],
      podId: params?.podId,
      searchText: params?.searchText || '',
      userPage: params?.pageOptions?.page || 1,
      userSize: params?.pageOptions?.size || 10,
    }
    return this.http.post<ProjectsResponse>(`${this.bebopConfig.apiUrl}/api/v1/myflexprojects`, data).pipe(
      catchError((error: any) => {
        console.error('On searchFlexProjects', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getPods(orgId: string): Observable<Partial<PodsResponse>> {
    return this.http.get<PodsResponse>(`${this.bebopConfig.apiUrl}/api/v1/client/organizations/${orgId}/pods`).pipe(
      tap((res) => {
        this.setPods(res?.data || [])
        this.updatePodsToOrganization(orgId, res?.data || [])
      }),
      catchError((error: any) => {
        this.setPods([])
        console.error('On getPods', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getStoragesByPod(qs: { podId: ObjectId; searchText?: string }): Observable<Partial<StoragesResponse>> {
    let params = new HttpParams().set('podId', qs?.podId)
    params = params.append('searchText', qs?.searchText || '')

    return this.http
      .get<StoragesResponse>(`${this.bebopConfig.apiUrl}/api/v1/storage-servers/searchAssignedPodsV2`, {
        headers: httpOptions.headers,
        params,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On getStoragesByPod', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getProject(projectId: string): Observable<Partial<ProjectResponse>> {
    return this.http.get<ProjectResponse>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}`).pipe(
      catchError((error: any) => {
        console.error('On getProject', projectId, error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  addProject(data: Partial<Project>) {
    const payload = { ...data, pod: data?.pod?._id, storage: data?.storage?._id }
    return this.http.post<AddProjectResponse>(`${this.bebopConfig.apiUrl}/api/v1/client/projects`, payload).pipe(
      catchError((error: any) => {
        console.error('On addProject', data.name, error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getWorkstationsByPod(podId: string): Observable<Partial<WorkstationsResponse>> {
    return this.http.get<WorkstationsResponse>(`${this.bebopConfig.apiUrl}/api/v1/workstations?podId=${podId}`).pipe(
      tap((res) => {
        // this.updatePodWorkstation(podId, <Workstation[]>(<unknown>res.data))
      }),
      catchError((error: any) => {
        console.error('On getWorkstationsByPod', podId, error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  sendNotificationActivity(data: { topic: string; [key: string]: any }) {
    return this.http.post<GenericResponse>(`${this.bebopConfig.apiUrl}/api/v1/client/task`, data).pipe(
      catchError((error: any) => {
        console.error(`SendNotificationActivity`, data, error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  logout() {
    return this.http.get<LogoutResponse>(`${this.bebopConfig.apiUrl}/logout`).pipe(
      catchError((error: any) => {
        console.error('On logout', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  initializeDashboard(orgId: string) {
    this.sessionStore.update((store) => {
      const dashboard = store.dashboards || {}
      if (!dashboard[orgId]) {
        this.updateDashboard(orgId, {})
      }
    })
  }

  updateDashboard(orgId: string, data: Partial<IDashboardCache>) {
    this.sessionStore.update((store) => {
      const dashboards = store.dashboards || {}

      const currentCache = dashboards[orgId] || {
        activities: [],
        cachedAt: new Date().getTime(),
        uiMembers: { available: false, desc: '', info: '' },
        uiProjects: { available: false, desc: '', info: '' },
        uiWorkstations: { available: false, desc: '', info: '' }, // Store current timestamp
      }

      const updatedCache = {
        ...currentCache,
        ...data,
        cachedAt: new Date().getTime(), // Update the cache time
      }

      return (store = {
        ...store,
        dashboards: {
          ...dashboards,
          [orgId]: updatedCache,
        },
      })
    })
  }
}
