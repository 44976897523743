<ng-container *ngIf="project">
  @if (type === "grid") {
    <div class="projects-container" *ngIf="project">
      <div class="thumbnail-projects" (click)="detailProject(project)">
        <img [src]="projectCover" [alt]="project.name" />
        <div class="label-projects-container">
          <live-indicator
            [type]="project.isLive ? 'red' : 'grey'"
            [showLabel]="false"
          ></live-indicator>
          <div
            class="label-project-type"
            [class.high-performance]="project.storage.highPerformance"
          >
            <span>{{
              project.storage.highPerformance ? "High Performance" : "Standard"
            }}</span>
          </div>
        </div>
      </div>

      <div class="project-description">
        <div class="project-info">
          <span class="project-name body2" (click)="detailProject(project)">{{
            project.name
          }}</span>
          <span class="project-size body3"
            >Size: {{ project.folderSize.gb }}GB</span
          >
          <span class="project-storage body3"
            >Storage usage: {{ project.storage.storageSize.percentUsed }}%</span
          >
        </div>

        <cree8-card-actions-list
        (closeDropdown)="onCloseDropdown(project)"
          (openDropdown)="onOpenDropdown(project)"
          [data]="project"
          [actionsList]="actionsList()"
          [type]="'project'"
          [active]="project.dropDownActive"
          (action)="onAction($event)"
      ></cree8-card-actions-list>
      </div>
    </div>
  } @else {
    <!-- disable right now -->
    <!-- <div class="projects-container-list">
      <div class="name-container">
        <div class="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.3971 8.08231L7.79926 10.9606C7.534 11.1728 7.15159 11.1516 6.91139 10.9114L5.08858 9.08859C4.84837 8.84839 4.82719 8.46599 5.0394 8.20073L7.91767 4.60289C9.23382 2.95771 11.2265 2 13.3333 2V2C13.7015 2 14 2.29848 14 2.66667V2.66667C14 4.77353 13.0423 6.76617 11.3971 8.08231Z"
              stroke="#ADB5BD"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.6666 8.6665V11.5878C10.6666 11.8404 10.5239 12.0712 10.2981 12.1841L8.68744 12.9894C8.51458 13.0759 8.31273 13.083 8.13418 13.0091C7.95563 12.9351 7.81796 12.7873 7.75685 12.604L7.33325 11.3332"
              stroke="#ADB5BD"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.66656 8.66683L3.39579 8.24324C3.21245 8.18212 3.06465 8.04445 2.99069 7.8659C2.91673 7.68736 2.92389 7.4855 3.01032 7.31264L3.81565 5.70202C3.92858 5.47618 4.1594 5.3335 4.41189 5.3335H7.33323"
              stroke="#ADB5BD"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.8334 13.7247L2 14.0001L2.27543 12.1667C2.36478 11.5719 2.83167 11.1051 3.42643 11.0157V11.0157C3.85621 10.9511 4.29115 11.0944 4.59846 11.4017C4.90578 11.709 5.04901 12.1439 4.98443 12.5737V12.5737C4.89506 13.1685 4.42816 13.6353 3.8334 13.7247V13.7247Z"
              stroke="#ADB5BD"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="name body2">
          {{ project.name }}
        </div>
      </div>
      <div class="description-container">
        <div class="description body3">
          <span>Size: {{ project.folderSize.gb }}GB</span>
          <span>•</span>
          <span
            >Storage usage: {{ project.storage.storageSize.percentUsed }}%</span
          >
        </div>
        <div class="performance">
          <live-indicator
            [type]="project.isLive ? 'red' : 'grey'"
            [showLabel]="false"
          ></live-indicator>
          <div
            class="label-project-type captions"
            [class.high-performance]="project.storage.highPerformance"
          >
            <span>{{
              project.storage.highPerformance ? "High Performance" : "Standard"
            }}</span>
          </div>
        </div>
      </div>
    </div> -->
  }
</ng-container>
