<footer>
  <website-container>
    <div class="cta">
      <img
        src="assets/img/footer.png"
        alt="Start creating anything, anywhere today"
      />
      <div class="content">
        <div class="title">Start creating anything, anywhere today.</div>
        <div class="button">
          <cree8-button variant="dark" (click)="onGetStarted()"
            >GET STARTED</cree8-button
          >
        </div>
      </div>
    </div>

    <div class="footer-nav">
      <div class="nav">
        <div class="links">
          <span>Company</span>
          <ul>
            <li *ngFor="let link of listFooterLinks">
              <a title="{{ link.title }}" (click)="onClickLinks(link.href)">{{
                link.title
              }}</a>
            </li>
          </ul>
        </div>

        <div class="links">
          <span>CREE8</span>
          <ul>
            <li *ngFor="let link of links">
              <a
                (click)="onGenerateLink(link.href)"
                [title]="link.title"
                [class.disabled]="disableMobileLink(link.href)"
                >{{ link.title }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="dnb">
        <div class="social">
          <span>Follow Us</span>
          <social-media></social-media>
        </div>
        <iframe
          [src]="safeIframeSrc"
          width="120px"
          height="100px"
          frameborder="0"
        >
        </iframe>
      </div>
    </div>
  </website-container>
</footer>
