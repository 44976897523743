import { Component, Inject, OnInit } from '@angular/core'

import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface TermianateWorkstationAction {
  name: 'Cancel' | 'Terminate'
}

@Component({
  selector: 'bebop-terminate-workstation',
  styleUrls: ['./terminate-workstation.component.scss'],
  templateUrl: './terminate-workstation.component.html',
})
export class TerminateWorkstationComponent implements OnInit, OnExternalModalClose {
  constructor(
    public ref: ModalOverlayRef<TerminateWorkstationComponent, TermianateWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  terminate() {
    this.ref.send({ name: 'Terminate' })
    this.ref.close()
  }
}
