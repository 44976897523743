<div class="bebop-client-eula">
  <div class="header h3">BeBop Subscription Agreement</div>

  <div class="eula-body">
    <!-- <div class="eula-region">
      <div class="region-label h4">Please choose your region:</div>
      <ng-container *ngIf="eulas?.length > 2">
        <bebop-input-select selectOnly="true" placeholder="Select Region…" [value]="region" class="region-select">
          <div dropdown-icon>
            <img [src]="'/assets/img/regions/' + region.toLowerCase() + '.png'" />
          </div>
          <div dropdown-options>
            <bebop-input-select-option *ngFor="let r of regions" (click)="onSelectRegion(r)">
              <div option-icon>
                <img [src]="'/assets/img/regions/' + r.toLowerCase() + '.png'" />
              </div>
              <div option-text>
                {{ r }}
              </div>
            </bebop-input-select-option>
          </div>
        </bebop-input-select>
      </ng-container>

      <ng-container *ngIf="eulas?.length <= 2">
        <div
          *ngFor="let e of eulas; let i = index"
          class="region-card"
          [class.active]="e._id == eula?._id"
          (click)="onSelectRegion(e.region); $event.stopPropagation()"
        >
          <img [src]="'/assets/img/regions/' + e.region?.toLowerCase() + '.png'" class="region-img" />
          <div class="paragraph name">{{ e.region }}</div>
          <bebop-radio-button-group name="fv-radios" [items]="[radioItems[i]]" (on-select)="onChangeOption($event)">
          </bebop-radio-button-group>
        </div>
      </ng-container>
    </div> -->
    <div class="eula-read-note h3">Please review the license agreement below and scroll to the bottom to continue.</div>

    <div class="eula-section" (scroll)="onScroll($event)" #eulaSection>
      <div [innerHtml]="eula?.eulaText | safeHtml" class="eula-content"></div>
    </div>

    <div class="action-section">
      <div class="terms-section">
        <div class="paragraph terms" [class.selected]="readTerms[selectedIdx]">
          <bebop-checkbox-button
            [checked]="termsOfUses[selectedIdx]"
            [disabled]="!readTerms[selectedIdx] ? 'disabled' : 'false'"
            (on-change)="acceptTermsOfUse(selectedIdx, $event)"
            [pulse]="readTerms[selectedIdx]"
          ></bebop-checkbox-button>
          <div (click)="readTerms[selectedIdx] && acceptTermsOfUse(selectedIdx, !termsOfUses[selectedIdx])">
            Acceptance of Subscription Agreement
          </div>
        </div>
      </div>

      <bebop-button size="large" outlined="true" class="print-btn" (on-click)="print()">Print</bebop-button>
      <bebop-button size="large" outlined="true" class="deny-btn" (on-click)="deny()">Deny</bebop-button>
      <bebop-button size="large" (on-click)="accept()" [disabled]="!termsOfUses[selectedIdx] || !readTerms[selectedIdx]"
        >Accept</bebop-button
      >
    </div>
  </div>
</div>
