<!-- [@fadeAnimation]="{value: animationState, params:
    { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
 (@fadeAnimation.done)="onFadeFinished($event)"> -->

<div class="bebop-toast-wrapper" *ngIf="data.type && data.text">
  <div class="bebop-toast" [class]="data.type">
    <div class="toast-icon">
      <svg viewBox="0 0 24 24" fill="none" [attr.type]="'success'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm12.768-2.36a1 1 0 1 0-1.536-1.28L10.9 13.558l-2.227-2.025a1 1 0 0 0-1.346 1.48l3 2.727a1 1 0 0 0 1.441-.1l5-6Z"
          fill="#32BA78"
        />
      </svg>
  
      <svg viewBox="0 0 24 24" fill="none" [attr.type]="'error'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.267 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm8-10c-5.523 0-10 4.477-10 10s4.477 10 10 10c5.522 0 10-4.477 10-10s-4.478-10-10-10Zm1 6a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
          fill="#F1344B"
          fill-opacity=".8"
        />
      </svg>
  
      <svg viewBox="0 0 24 24" fill="none" [attr.type]="'info'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.267 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-10 8c0-5.523 4.477-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10Zm10-1a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm0-4a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
          fill="#4574F5"
        />
      </svg>
  
      <svg viewBox="0 0 24 24" fill="none" [attr.type]="'warning'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V7a5 5 0 0 0-5-5H7ZM4 7a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7Zm9.01 1a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0V8ZM12 15a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
          fill="#FFC14C"
        />
      </svg>
    </div>
  
    <div class="toast-content paragraph">{{ data.text }}</div>
  
    <div class="toast-close" (click)="close()">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.293 5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293Z"
          fill="white"
          fill-opacity="0.4"
        />
      </svg>
    </div>
  </div>

</div>