import { Component, Input, OnInit } from '@angular/core'
import { UiFileType } from '../classes/rocket-types'

@Component({
  selector: 'bebop-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
})
export class FileIconComponent implements OnInit {
  @Input('type') type: UiFileType
  @Input('pulse') pulse: boolean
  // some icon supports filled svg - e.g. folder
  @Input('filled') filled: boolean

  constructor() {}

  ngOnInit(): void {
    this.type = this.type || 'others'
    this.filled = this.filled || false
  }
}
