import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'bebop-input-select-option',
  templateUrl: './input-select-option.component.html',
  styleUrls: ['./input-select-option.component.scss'],
})
export class InputSelectOptionComponent implements OnInit, AfterViewInit {
  showInputIcon = true
  @ViewChild('inputIcon') inputSelectOption
 
  // Update all usages to use on-click and remove this
  @Output('click') click_legacy = new EventEmitter<MouseEvent>()

  @Output('on-click') click = new EventEmitter<MouseEvent>()
  @Output('on-active') onActive = new EventEmitter<boolean>()
  @HostBinding('tabindex') tabindex = -1

  @HostListener('focus')
  focusHandler() {
    this.el.nativeElement.children?.[0]?.focus?.()
    this.el.nativeElement.children?.[0]?.classList?.add?.('active')
    this.onActive.emit(true)
  }

  @HostListener('blur', ['$event'])
  blurHandler(e: FocusEvent) {

    let isActive = (<HTMLElement>e?.relatedTarget)?.tagName == 'INPUT'

    this.el.nativeElement.children?.[0]?.blur?.()
    if (!isActive) this.el.nativeElement?.children?.length && this.el.nativeElement.children[0].classList.remove('active')
    this.onActive.emit(false)
  }

  constructor(private cdRef: ChangeDetectorRef, private el: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.showInputIcon = this.inputSelectOption?.nativeElement?.children?.length > 0

    this.cdRef.detectChanges()
  }

  onClick(e: MouseEvent) {
    this.click.emit(e)
    this.click_legacy.emit(e)
  }
}
