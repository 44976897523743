import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { tap } from 'lodash'
import { Subject, takeUntil } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { IOrganization, IOrgSubscriptionDetails, IOrgUsage } from 'src/app/models/organization.model'
import { ResponseError } from 'src/app/models/response.model'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { PodsService } from 'src/app/services/pods.service'
import { WatermarkService } from 'src/app/services/watermark.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'
import { workstationTimezoneList } from 'src/app/utils/workstation-utils'

@Component({
  selector: 'organization-settings',
  styleUrls: ['./organization-settings.component.scss'],
  templateUrl: './organization-settings.component.html',
})
export class OrganizationSettingsComponent implements OnInit, OnDestroy {
  selectedOrg: any = {}
  orgUsage: any = {}
  subscriptionDetails: IOrgSubscriptionDetails
  watermarkBase64: any = {}
  podWatermarkBase64: string = ''
  organizationForm: FormGroup
  podSettings: FormGroup
  podModel: any

  workstationTimezoneList = workstationTimezoneList
  private destroy$ = new Subject<void>()

  constructor(
    private uiQuery: UIQuery,
    private toastService: ToastService,
    private organizationsService: OrganizationsService,
    private watermarkService: WatermarkService,
    private podService: PodsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        this.resetState()
        if (!org) {
          return
        }

        this.selectedOrg = org

        this.getOrgData()
        this.loadWatermarks()
        this.getSubscriptionData()
        this.getUsage()
        this.loadPod()
      })
  }

  getOrgData() {
    this.organizationsService
      .getOrg(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.selectedOrg = null
        } else {
          this.selectedOrg = response
          this.organizationForm = this.fb.group({
            email: [this.selectedOrg.email, [Validators.required, Validators.email]],
            name: [this.selectedOrg.name, Validators.required],
            phone: [this.selectedOrg.phone],
            website: [this.selectedOrg.website],
          })
        }
      })
  }

  loadWatermarks() {
    this.organizationsService
      .getWatermarkByOrgId(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.watermarkBase64 = {}
        } else {
          this.watermarkBase64 = response.watermark
        }
      })
  }

  getSubscriptionData() {
    this.organizationsService
      .getOrgSubscriptionDetails(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: IOrgSubscriptionDetails) => {
        if (isErrorResponse(response)) {
          console.log(response.error)
          return
        }

        this.subscriptionDetails = response
      })
  }

  getUsage() {
    this.organizationsService
      .getOrgUsage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: IOrgUsage) => {
        if (isErrorResponse(response)) {
          console.log(response.error)
          return
        }

        this.orgUsage = response
      })
  }

  updateProfile() {
    if (this.organizationForm.valid) {
      const updatedOrg = {
        ...this.selectedOrg, // existing properties from selectedOrg
        ...this.organizationForm.value, // updated values from the form
      }

      this.organizationsService
        .updateOrg(updatedOrg)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.toastService.show({
              text: 'Organization updated successfully',
              type: 'success',
            })
          }
        })
    }
  }

  loadPodWatermark() {
    this.watermarkService
      .getWatermarkByKey(this.podModel._id, 'DESKTOP_WALLPAPER')
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          console.log(response.error)
        } else {
          this.podWatermarkBase64 = response.watermark
        }
      })
  }

  loadPod() {
    this.podService
      .getPodByOrganization()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          console.log(response.error)
        } else {
          this.podModel = response
          this.podSettings = this.fb.group({
            idleTimeout: [this.podModel.idleTimeout, [Validators.required, Validators.pattern('^[0-9]*$')]],
            workstationTimezone: [this.podModel.appConfigs.workstationTimezone],
          })

          this.loadPodWatermark()
        }
      })
  }

  updatePod() {
    if (this.podSettings.valid) {
      if (this.podSettings.value.workstationTimezone)
        this.podModel.appConfigs.workstationTimezone = this.podSettings.value.workstationTimezone
      this.podModel.idleTimeout = Number(this.podSettings.value.idleTimeout)

      this.podService
        .updateProfile(this.podModel._id, this.podModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.toastService.show({
              text: 'Workstation settings updated',
              type: 'success',
            })
          }
        })
    } else {
      this.toastService.show({
        text: 'Invalid workstation settings',
        type: 'error',
      })
    }
  }

  deleteWatermark(type) {
    const id = type === 'DESKTOP_WALLPAPER' ? this.podModel._id : this.selectedOrg._id
    this.watermarkService
      .deleteWatermarkByKey(id, type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.toastService.show({
            text: response.error.msg,
            type: 'error',
          })
        } else {
          if (type === 'DESKTOP_WALLPAPER') {
            this.loadPodWatermark()
          } else {
            this.loadWatermarks()
          }
          this.toastService.show({
            text: 'Watermark removed successfully',
            type: 'success',
          })
        }
      })
  }

  handleImageUploadSuccess(type: string, base64Image: string): void {
    if (type === 'DESKTOP_WALLPAPER') {
      if (this.podModel)
        this.watermarkService
          .updateWatermarkByKey(this.podModel._id, {
            type,
            watermark: base64Image,
          })
          .subscribe((response) => {
            if (isErrorResponse(response)) {
              this.toastService.show({
                text: response.error.msg,
                type: 'error',
              })
            } else {
              this.loadPodWatermark()
              this.toastService.show({
                text: 'Watermark is saved successfully.',
                type: 'success',
              })
            }
          })
    } else {
      this.organizationsService
        .updateWatermarkByOrgId(this.selectedOrg._id, {
          type,
          watermark: base64Image,
        })
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.loadWatermarks()
            this.toastService.show({
              text: 'Watermark is saved successfully.',
              type: 'success',
            })
          }
        })
    }
  }

  handleImageUploadError(error: string): void {
    console.log(error)
    this.toastService.show({
      text: error,
      type: 'error',
    })
  }

  getWaterMarkUrl(type) {
    return 'data:image/png;base64, ' + this.watermarkBase64[type]
  }

  manageSubscription() {
    this.toastService.show({
      text: 'Please contact customer support using the chat option to manage your subscription',
      type: 'info',
    })
    setTimeout(() => window.Intercom('show'), 1500)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  resetState() {}
}
