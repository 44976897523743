import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { CdkPortal, ComponentPortal } from '@angular/cdk/portal'
import { ComponentRef, Injectable, InjectionToken, Injector } from '@angular/core'
import { ModalOverlayRef } from '../classes/modal-overlay-ref'
import { CSSSize } from '../types'

export interface OnExternalModalClose {
  cancel: () => void
  beforeClose?: () => Promise<void>
}

export interface CustomOverlayConfig {
  hasBackdropClick?: boolean
  hasEscapeClose?: boolean
  isCentered?: boolean
  size?: {
    width?: number
    height?: number
    maxHeight?: number
    minHeight?: number
    maxWidth?: number
    minWidth?: number
  }

  position?: {
    top?: CSSSize
    left?: CSSSize
    bottom?: CSSSize
    right?: CSSSize
  }

  // offsets
  top?: number
  left?: number
}

export interface ModalConfig {
  panelClass?: string | string[]
  hasBackdrop?: boolean
  backdropClass?: string | string[]
  data?: any
  animateFrom?: Element
  animateOptions?: {
    beginTransformOrigin?: string
    endTransformOrigin?: string
    durationInMillis?: number
    easing?: string
    fill?: FillMode

  }
}

export const DEFAULT_CONFIG: ModalConfig = {
  hasBackdrop: true,
  backdropClass: 'bebop-modal-backdrop',
  panelClass: 'bebop-modal-panel',
  animateOptions: {
    beginTransformOrigin: 'top left',
    endTransformOrigin: 'top left',
    durationInMillis: 300,
    fill: 'both'
  },
  data: {},
}

const DEFAULT_CUSTOM_CONFIG: CustomOverlayConfig = {
  hasBackdropClick: true,
  hasEscapeClose: true,
  isCentered: true,
  size: null,
}

export const BEBOP_MODAL_DATA = new InjectionToken<any>('BEBOP_MODAL_DATA')

/**
Use component modal or template ref as below

For component modal

it must be an entry component

constructor(
  public ref: ModalOverlayRef<T>,
  @Inject(BEBOP_MODAL_DATA) public data: any
) { }

ref.actions is a Behaviour subject for callback events


For template reference case,

<ng-template cdkPortal #modalTemplate="cdkPortal">
  <app-custom-bebop-modal (close)="closeThisModal($event)"></app-custom-bebop-modal>
</ng-template>

@ViewChild('modalTemplate') modalTemplate: TemplatePortal<any>;

*/

@Injectable({
  providedIn: 'root',
})
export class ComponentModalService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  // T is component type, V is component's injected data type (BEBOP_MODAL_DATA)
  open<T = any, V = any>(
    componentOrTemplateRef: ComponentType<T> | CdkPortal,
    config: ModalConfig = {},
    customOverlayConfig: CustomOverlayConfig = {}
  ) {
    const modalConfig = { ...DEFAULT_CONFIG, ...config }
    const oconfig = { ...DEFAULT_CUSTOM_CONFIG, ...customOverlayConfig }
    const overlayRef = this.createOverlay(modalConfig, oconfig)
    const modalRef = new ModalOverlayRef<T, V>(overlayRef)

    this.flipAnimate(modalConfig, oconfig, overlayRef.overlayElement, () => {
      const overlayComponent = this.attachModalContainer<T, V>(
        overlayRef,
        modalConfig,
        modalRef,
        componentOrTemplateRef
      )

      modalRef.setOverlayComponent(overlayComponent)
    })

    if (oconfig.hasBackdropClick) {
      overlayRef.backdropClick().subscribe((_) => {
        modalRef.onExternalClick()
        modalRef.close()
      })
    }

    if (oconfig.hasEscapeClose) {
      overlayRef.keydownEvents().subscribe((e) => {
        if (e.key == 'Esc' || e.key == 'Escape') {
          modalRef.onExternalClick()
          modalRef.close()
        }
      })
    }

    return modalRef
  }

  private attachModalContainer<T, V>(
    overlayRef: OverlayRef,
    config: ModalConfig,
    modalRef: ModalOverlayRef<T, V>,
    componentOrTemplateRef: ComponentType<T> | CdkPortal
  ) {
    const injector = this.createInjector<T, V>(config, modalRef)

    if (componentOrTemplateRef instanceof CdkPortal) {
      const containerRef = overlayRef.attach(componentOrTemplateRef)
      return containerRef
    }

    const containerPortal = new ComponentPortal(componentOrTemplateRef, null, injector)
    const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal)
    return containerRef.instance
  }

  private createInjector<T, V>(config: ModalConfig, modalRef: ModalOverlayRef<T, V>): Injector {
    const injectionTokens = new WeakMap()

    injectionTokens.set(ModalOverlayRef, modalRef)

    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: ModalOverlayRef, useValue: modalRef },
        { provide: BEBOP_MODAL_DATA, useValue: config.data },
      ],
    })
  }

  private getOverlayConfig(config: ModalConfig, oconfig: CustomOverlayConfig): OverlayConfig {
    let positionStrategy = this.overlay.position().global()

    let pconfig = <Partial<OverlayConfig>>{ positionStrategy }

    if (oconfig.position) {
      if (oconfig.position?.top) positionStrategy = positionStrategy.top(oconfig.position.top)
      if (oconfig.position?.bottom) positionStrategy = positionStrategy.bottom(oconfig.position.bottom)

      if (oconfig.position?.left) positionStrategy = positionStrategy.left(oconfig.position.left)
      if (oconfig.position?.right) positionStrategy = positionStrategy.right(oconfig.position.right)
    } else if (oconfig.isCentered) {
      pconfig.positionStrategy = positionStrategy.centerHorizontally().centerVertically()
    } else if (oconfig.top || oconfig.left) {
      pconfig.positionStrategy = positionStrategy
        .centerVertically((oconfig.top || 0) + 'px')
        .centerHorizontally((oconfig.left || 0) + 'px')
    }

    if (oconfig.size) {
      // TODO: not tested this use case
      pconfig = { ...pconfig, ...oconfig.size }
    }

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      // setting noop as default, pass explicitly for different strategy
      // scrollStrategy: this.overlay.scrollStrategies.block(),
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      ...pconfig,
    })

    return overlayConfig
  }

  private createOverlay(config: ModalConfig, oconfig: CustomOverlayConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config, oconfig)

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig)
  }

  private easeInFadeInAnimationFromTopCenter(config: ModalConfig, overlayConfig: CustomOverlayConfig, elm: Element, action: () => void){

    if (!overlayConfig.isCentered) return action()

    action()

    // Last: get the final bounds
    const last = elm.getBoundingClientRect()
    let wwidth = window.outerWidth
    let wheight = window.outerHeight

    let wd = Math.max(wwidth - last.width, 0)
    let hd = Math.max(wheight - last.height, 0)

    // Top/center to center/center
    const deltaX = 0 // - wd/2
    const deltaY = - hd/2

    elm.animate(
      [
        {
          transform: `
    translate(${deltaX}px, ${deltaY}px)
  `,
          opacity: 0
        },
        {
          transform: 'none',
          opacity: 1
        },
      ],
      {
        duration: config?.animateOptions?.durationInMillis ?? 300,
        easing: config?.animateOptions?.easing ?? 'ease-in',
        fill: config?.animateOptions?.fill ?? 'both',
      }
    )

  }


  private flipAnimate(config: ModalConfig, overlayConfig: CustomOverlayConfig, elm: Element, action: () => void) {

    return this.easeInFadeInAnimationFromTopCenter(config, overlayConfig, elm, action)


    let srcElm = config?.animateFrom

    if (!srcElm) return this.easeInFadeInAnimationFromTopCenter(config, overlayConfig, elm, action)

    // First: get the current bounds
    const first = srcElm.getBoundingClientRect()

    action()

    // Last: get the final bounds
    const last = elm.getBoundingClientRect()

    let lLeft = last.left
    let lTop = last.top
    let wwidth = window.outerWidth
    let wheight = window.outerHeight

    if (overlayConfig.isCentered) {

      let cWidth = last.width
      let cHeight = last.height

      if (wwidth > cWidth) {
        lLeft = (wwidth - cWidth) / 2
      }

      if (wheight > cHeight) {
        lTop = (wheight - cHeight) / 2
      }
    } else if (overlayConfig.top || overlayConfig.left) {

      if (overlayConfig.top && !lTop) {
        lTop = overlayConfig.top
      }

      if (overlayConfig.left && !lLeft) {
        lLeft = overlayConfig.left
      } 

    }
    // TODO calculate based on position strategy 


    // Invert: determine the delta between the
    // first and last bounds to invert the element
    const deltaX = first.left - lLeft
    const deltaY = first.top - lTop
    const deltaW = (first.width / last.width) * .5
    const deltaH = (first.height / last.height) * .5

    // Play: animate the final element from its first bounds
    // to its last bounds (which is no transform)
    elm.animate(
      [
        {
          transformOrigin: config?.animateOptions?.beginTransformOrigin ?? 'center center',
          transform: `
    translate(${deltaX}px, ${deltaY}px)
    scale(${deltaW}, ${deltaH})
  `,
          opacity: 0
        },
        {
          transformOrigin: config?.animateOptions?.endTransformOrigin ?? 'center center',
          transform: 'none',
          opacity: 1
        },
      ],
      {
        duration: config?.animateOptions?.durationInMillis ?? 300,
        easing: config?.animateOptions?.easing ?? 'ease-in',
        fill: config?.animateOptions?.fill ?? 'both',
      }
    )
  }
}
