import { CdkScrollable, ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import {
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'

import { merge, Observable, Subscription } from 'rxjs'

import { DropdownPanel, Placement, PlacementPositionX, PlacementPositionY, PlacementTooltipPosition } from '../../types'

@Directive({
  host: {
    '(click)': 'toggleDropdown()',
  },
  selector: '[Cree8DropdownTriggerFor]',
})
export class Cree8DropdownTriggerDirective implements OnDestroy, OnInit, OnDestroy {
  private isDropdownOpen = false
  private overlayRef: OverlayRef
  private onClose$: Subscription

  private offset = 0
  private offset2 = 0

  @Input('Cree8DropdownTriggerFor') public panel: DropdownPanel

  @Input('placement') public placement: Placement
  @Input('no-alt-placement') public noAltPlacement: boolean
  @Input('placement-origin-x-position') public placementX: PlacementPositionX
  @Input('placement-origin-y-position') public placementY: PlacementPositionY
  @Input('placement-overlay-x-position') public placementOverlayX: PlacementPositionX
  @Input('placement-overlay-y-position') public placementOverlayY: PlacementPositionY

  @Input('placement-tooltip-position') public tooltipPosition: PlacementTooltipPosition
  @Input('placement-tooltip-offset') public tooltipOffset: number
  @Input('placement-tooltip-offset2') public tooltipOffset2: number

  constructor(
    private overlay: Overlay,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    @Optional() @Host() private scrollContainer: CdkScrollable
  ) {}

  ngOnDestroy(): void {
    this.onClose$?.unsubscribe()
    this.overlayRef?.dispose()
  }

  ngOnInit(): void {
    this.offset = this.tooltipOffset ? this.tooltipOffset : this.tooltipPosition == 'none' ? 8 : 16
    this.offset2 = this.tooltipOffset2 ? this.tooltipOffset2 : 0
  }

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown()
  }

  getAlternativePositions(): ConnectedPosition[] {
    if (this.noAltPlacement) return []
    let placementMap: Record<Placement, Placement[]> = {
      bottom: ['top', 'left', 'right'],
      left: ['right', 'bottom', 'top'],
      right: ['left', 'bottom', 'top'],
      top: ['bottom', 'left', 'right'],
    }

    let alts: Placement[] = placementMap[this.placement] || placementMap['bottom']
    return alts.map((a) => ({ ...this.getPosition(a), panelClass: this.getPanelClasses(a) }))
  }

  getPosition(alt?: Placement): ConnectedPosition {
    switch (alt || this.placement) {
      case 'left':
        return {
          offsetX: -this.offset,
          originX: this.placementX || 'start',
          originY: this.placementY || 'center',
          overlayX: this.placementOverlayX || 'end',
          overlayY: this.placementOverlayY || 'center',
        }

      case 'right':
        return {
          offsetX: this.el.nativeElement.offsetWidth + this.offset,
          offsetY: 0,
          originX: this.placementX || 'start',
          originY: this.placementY || 'center',
          overlayX: this.placementOverlayX || 'start',
          overlayY: this.placementOverlayY || 'center',
        }

      case 'top':
        return {
          offsetX: 0,
          offsetY: -this.offset,
          originX: this.placementX || 'center',
          originY: this.placementY || 'top',
          overlayX: this.placementOverlayX || 'center',
          overlayY: this.placementOverlayY || 'bottom',
        }

      case 'bottom':
      default:
        return {
          offsetX: this.offset2,
          offsetY: 0,
          originX: this.placementX || 'end',
          originY: this.placementY || 'bottom',
          overlayX: this.placementOverlayX || 'end',
          overlayY: this.placementOverlayY || 'top',
        }
    }
  }

  getPanelClasses(alt?: Placement) {
    return [
      'bebop-dropdown-panel',
      this.tooltipPosition != 'none' ? `bebop-dropdown-panel-${alt || this.placement}` : '',
      this.tooltipPosition != 'none' ? `bebop-dropdown-panel-style-${this.tooltipPosition}` : '',
    ].filter((x) => x)
  }

  openDropdown(): void {
    this.isDropdownOpen = true
    this.overlayRef = this.overlay.create({
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.el)
        .setOrigin(this.el)
        // .withScrollableContainers([this.scrollContainer])
        .withPositions([
          { ...this.getPosition(), panelClass: this.getPanelClasses() },
          ...this.getAlternativePositions(),
        ]),
      scrollStrategy: this.overlay.scrollStrategies.close(),
    })

    const templatePortal = new TemplatePortal(this.panel.templateRef, this.viewContainerRef)
    this.overlayRef.attach(templatePortal)

    this.onClose$ = this.onClose().subscribe(() => this.destroyDropdown())
  }

  private onClose(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick()
    const detachment$ = this.overlayRef.detachments()
    const dropdownClosed = this.panel.closed

    return merge(backdropClick$, detachment$, dropdownClosed)
  }

  private destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return
    }

    this.onClose$.unsubscribe()
    this.isDropdownOpen = false
    this.overlayRef.detach()
  }
}
