import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface ClientUpdateInstallAction {
  name: 'Cancel' | 'Download' | 'Install'
}

@Component({
  selector: 'bebop-client-update-install',
  templateUrl: './client-update-install.component.html',
  styleUrls: ['./client-update-install.component.scss'],
})
export class ClientUpdateInstallComponent implements OnInit, OnExternalModalClose {
  patch = false
  downloadUpdate = false
  installUpdate = false
  constructor(
    public ref: ModalOverlayRef<ClientUpdateInstallComponent, ClientUpdateInstallAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.patch = this.data.patch || false
    this.downloadUpdate = this.data.downloadUpdate || false
    this.installUpdate = this.data.installUpdate || false
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  download() {
    this.ref.send({ name: 'Download' })
    this.ref.close()
  }

  install() {
    if (!this.patch) {
      return this.cancel()
    }
    this.ref.send({ name: 'Install' })
    this.ref.close()
  }
}
