<div class="bebop-projects">
  <div class=" header-project">
    <div class="header-text">
      <div class="headline1">Projects</div>

      <div class="breadcrumb">
        <div class="breadcrumb-item">
          <span class="breadcrumb-item-text body3" (click)="browseAll()">All</span>
        </div>

        <ng-container *ngFor="let segment of navTree; let i = index; let c = count">
          <div class="breadcrumb-item">
            <span class="breadcrumb-item-text body3">/</span>
          </div>

          <div class="breadcrumb-item">
            <span class="breadcrumb-item-text body3" [class.last]="i + 1 == c"
              (click)="i + 1 !== c ? onGoDirectory(segment) : null">{{ segment?.name || "" }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="tabs-panel">
    <cree8-tabs [tabs]="headerTabs" [activeTab]="this.activeTab" (tabClick)="onSelectHeaderTabs($event)"></cree8-tabs>
    <div class="actions-list">
      <cree8-tabs [tabs]="linkProjectTabs" (tabClick)="onFilterTabsLink($event)" type="outlined" size="extra-small"
        *ngIf="activeTab === 'links'"></cree8-tabs>
      <actions-list *ngIf="activeTab !== 'live'" (refresh)="onRefresh()" (searchValue)="onSearchValue($event)"
        (sorting)="onSorting($event)" (settings)="onSettings()" (created)="onCreated()" (viewType)="onViewType($event)"
        [allowedActions]="allowedActions" [labelCreated]="labelCreated" [listSorting]="listSortingProjects"
        [activedSort]="activeSorting" [activedViewType]="activedViewType"></actions-list>
    </div>
  </div>

  <div class="content">
    <div class="projects-container scrollbar" (scroll)="onScroll($event)">
      <cree8-links-projects *ngIf="activeTab === 'links'" [projectId]="projectId"
        #linksProjectsComponent></cree8-links-projects>
      <cree8-project-files-users *ngIf="activeTab === 'members' && this.permissions.users" [organization]="organization"
        #projectFilesUsers></cree8-project-files-users>
      <cree8-project-files-view *ngIf="activeTab === 'files'" #projectFilesView></cree8-project-files-view>
      <cree8-live-files *ngIf="activeTab === 'live' && projectLiveFile.hasLiveFile"
        [project]="project"></cree8-live-files>
    </div>
    <div class="projects-team scrollbar" *ngIf="activeTab === 'files'">
      <div class="team-header">
        <h3 class="headline4">Team</h3>
        <ng-container *ngIf="permissions.users">
          <cree8-btn (click)="onSelectHeaderTabs('members')">Add</cree8-btn>
        </ng-container>
      </div>

      <div class="team-list" *ngIf="!isLoadingTeam">
        <ng-container *ngFor="let user of team">
          <cree8-card-member [name]="user.name" [image]="getUserImage(user)" [email]="user.email" [status]="user.status"
            (copyEmailClick)="copyEmail(user.email)"></cree8-card-member>
        </ng-container>
      </div>
    </div>
  </div>

</div>

<cree8-modal [modal]="modalSetting" (cancelModal)="onCloseModal()" (next)="onSubmitModal()"
  [size]="modalSetting.title === teamModalTitle ? 'large' : 'normal'" *ngIf="modalSetting.title !== ''"
  [noPaddingTop]="modalSetting.title === teamModalTitle">
  <cree8-project-files-settings *ngIf="modalSetting.title === settingsModalTitle" #projectFilesSettings
    (successSubmit)="onCloseModal()"></cree8-project-files-settings>

  <ng-container *ngIf="modalSetting.title === teamModalTitle">
    <cree8-project-files-users [assign]="false" [showSearch]="true"
      [organization]="organization"></cree8-project-files-users>
  </ng-container>

  <cree8-create-folder #createFolder *ngIf="modalSetting.title === createFolderModalTitle"></cree8-create-folder>
</cree8-modal>
