<div class="cree8-input-chips-widget" [class]="{ disabled: isDisabled, multiline: isMultilined }">
  <div class="input-top-section" *ngIf="showLabel">
    <div class="input-sm">
      <ng-content select="[input-label]"></ng-content>
    </div>
  </div>

  <div class="input-main-section">
    <div class="tags-list">
      <div class="tag-entry" *ngFor="let e of all; let i = index">
        <cree8-chip [closable]="true" (close)="onRemoveTagWithError(i)" [error]="e.error">
          <div cree8-tooltip-text="{{e.message}}" cree8-tooltip-position="top">{{e.tag}}</div>
        </cree8-chip>
      </div>
      <div class="input-main-editor" *ngIf="isMultilined">
        <input [bebopAutoFocus]="isAutoFocused" [placeholder]="placeholder" [disabled]="isDisabled" class="input-text"
          [(ngModel)]="tag" (keyup)="onKeyup($event)" />
      </div>
    </div>

    <div class="input-main-editor" *ngIf="!isMultilined">
      <input [bebopAutoFocus]="isAutoFocused" [placeholder]="placeholder" [disabled]="isDisabled" class="input-text"
        [(ngModel)]="tag" (keyup)="onKeyup($event)" />
    </div>
  </div>
</div>
