export const LEFT_ARROW = 37
export const UP_ARROW = 38
export const RIGHT_ARROW = 39
export const DOWN_ARROW = 40
export const HOME = 36
export const END = 35
export const PAGE_UP = 33
export const PAGE_DOWN = 34
export const ENTER = 13
export const SPACE = 32
export const ESCAPE = 27
export const TAB = 9
export const BACKSPACE = 8
export const DELETE = 46
export const A = 65
export const B = 66
export const C = 67
export const D = 68
export const E = 69
export const F = 70
export const G = 71
export const H = 72
export const I = 73
export const J = 74
export const K = 75
export const L = 76
export const M = 77
export const N = 78
export const O = 79
export const P = 80
export const Q = 81
export const R = 82
export const S = 83
export const T = 84
export const U = 85
export const V = 86
export const W = 87
export const X = 88
export const Y = 89
export const Z = 90
