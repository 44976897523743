<ul [class.outlined]="type === 'outlined'">
  <li
    [class.active]="activeTab.length > 0 ? activeTab === tab.value : tab.active"
    [class.small]="size === 'small'"
    [class.full]="width === 'full'"
    [ngClass]="tab.class ? tab.class : ''"
    *ngFor="let tab of tabs"
    (click)="onTabClick(tab.value)"
  >
    {{ tab.label }}
  </li>
</ul>
