import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'

import { SvgInjectorService } from '../services/svg-injector.service'

@Directive({
  selector: '[appSvgInject]',
})
export class SvgInjectDirective implements OnChanges {
  @Input('appSvgInject') svgUrl!: string

  constructor(
    private el: ElementRef,
    private svgInjector: SvgInjectorService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['svgUrl'] && this.svgUrl) {
      this.loadSvg()
    }
  }

  private loadSvg(): void {
    this.svgInjector.fetchSvg(this.svgUrl).subscribe((svg) => {
      this.el.nativeElement.innerHTML = svg
    })
  }
}
