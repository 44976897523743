<div class="option-list">
  <div
    class="option-container"
    *ngFor="let option of options"
    (click)="onOptionSelected(option.value)"
  >
    <div class="option-content">
      <span class="option-title headline4">{{ option.title }}</span>
      <span class="option-description body3">{{ option.description }}</span>
    </div>
    <div class="option-actions">
      <label class="radio-container">
        <input type="radio" name="radio-example"
        [value]="option.value"
        [checked]="option.value === selectedOption"
        (change)="onOptionSelected(option.value)"/>
        <span class="custom-radio"></span>
      </label>
    </div>
  </div>
</div>
