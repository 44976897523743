import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { catchError, Observable, of } from 'rxjs'

import { Organization, OrgEntitlements, User, UserPermission } from '../models/bebop.model'
import { ResponseError } from '../models/response.model'
import { SessionQuery } from '../store/session/session.query'
import { UIQuery } from '../store/ui/ui.query'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: User

  entitlements: OrgEntitlements
  selectedOrg: Organization
  userPermissions: UserPermission

  constructor(
    private sessionQuery: SessionQuery,
    private uiQuery: UIQuery,
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {
    this.sessionQuery.getUser().subscribe((user) => {
      this._user = user
      this._userRole = new UserRole(user)

      this._userEntitlement = new UserEntitlement(this._userRole, this)
    })

    this.sessionQuery.getEntitlements().subscribe((e) => {
      this.entitlements = e
    })

    this.uiQuery.getUserPermissions().subscribe((u) => {
      this.userPermissions = u
    })

    this.uiQuery.getSelectedOrg().subscribe((org) => (this.selectedOrg = org))
  }

  getSelectedOrg() {
    return this.selectedOrg
  }

  getEntitlement(): { [key: string]: boolean } {
    if (!this.selectedOrg) return {}
    return { ...this.entitlements?.[this.selectedOrg._id] }
  }

  getUsers(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }

    params.sort = params.sort || '-date_created'
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/users`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getUsers', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getUserById(id: string, params?: any): Observable<any | ResponseError> {
    if (!params) {
      params = {}
    }

    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/users/${id}`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getUserById', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateProfile(userId: string, data: any) {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/users/${userId}`, data).pipe(
      catchError((error: any) => {
        console.error('On updateProfile', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updatePassword(userId: string, data: { currentPassword: string; password: string; confirmPassword: string }) {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/users/${userId}/password`, data).pipe(
      catchError((error: any) => {
        console.error('On updatePassword', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOnlineStatus(id: string, params?: any): Observable<any | ResponseError> {
    if (!params) {
      params = {}
    }

    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/users/${id}/online-status`, { params: httpParams })
      .pipe(
        catchError((error: any) => {
          console.error('On getOnlineStatus', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  get id() {
    return this._user?._id || ''
  }

  get user() {
    return this._user
  }

  get username() {
    return this._user?.username ?? ''
  }

  _userRole: UserRole

  get userRole() {
    return this._userRole
  }

  _userEntitlement: UserEntitlement
  get userEntitlement() {
    return this._userEntitlement
  }
}

export class UserRole {
  private _isUser = false
  private _isRegularUser = false
  private _isAdmin = false
  private _isSuperAdmin = false
  private _isL1Admin = false
  private _isL2Admin = false
  private _isAdministrator = false
  private _isOrganizationAdministrator = false
  private _isSales = false
  private _isBillingManager = false
  private _isAdminOrL1OrL2 = false
  private _isAdminOrL1OrL2OrSales = false
  private _isAdminOrL2 = false
  private _isAdminOrOrganizationOrL1OrL2 = false

  constructor(private user: User) {
    if (!user) return

    // copied from mcp
    // some legacy roles are there - e.g org admin based on role. let it be
    this._isUser = this.user?.role?.name === 'user'
    this._isRegularUser = this.user?.role?.name === 'user'
    this._isAdmin = this.user?.role?.name === 'administrator' || this.user?.role?.name === 'super_user'
    this._isSuperAdmin = this.user?.role?.name === 'super_user'
    this._isL1Admin = this.user?.role?.name === 'l1_admin'
    this._isL2Admin = this.user?.role?.name === 'l2_admin'
    this._isAdministrator = this.user?.role?.name === 'administrator'

    this._isOrganizationAdministrator = this.user?.role?.name === 'organization_administrator'

    this._isSales = this.user?.role?.name === 'sales'
    this._isBillingManager = this.user?.role?.name === 'billing_manager'

    this._isAdminOrL1OrL2 = this._isAdmin || this._isL1Admin || this._isL2Admin
    this._isAdminOrL1OrL2OrSales = this._isAdminOrL1OrL2 || this._isSales
    this._isAdminOrL2 = this._isAdmin || this._isL2Admin
    this._isAdminOrOrganizationOrL1OrL2 = this._isAdminOrL1OrL2 || this._isOrganizationAdministrator
  }

  get isUser() {
    return this._isUser
  }

  get isRegularUser() {
    return this._isRegularUser
  }

  get isAdmin() {
    return this._isAdmin
  }

  get isSuperAdmin() {
    return this._isSuperAdmin
  }

  get isL1Admin() {
    return this._isL1Admin
  }

  get isL2Admin() {
    return this._isL2Admin
  }

  get isAdministrator() {
    return this._isAdministrator
  }

  get isOrganizationAdministrator() {
    return this._isOrganizationAdministrator
  }

  get isSales() {
    return this._isSales
  }

  get isBillingManager() {
    return this._isBillingManager
  }

  get isAdminOrL1OrL2() {
    return this._isAdminOrL1OrL2
  }

  get isAdminOrL1OrL2OrSales() {
    return this._isAdminOrL1OrL2OrSales
  }

  get isAdminOrL2() {
    return this._isAdminOrL2
  }

  get isAdminOrOrganizationOrL1OrL2() {
    return this._isAdminOrOrganizationOrL1OrL2
  }
}

export class UserEntitlement {
  constructor(
    private userRole: UserRole,
    private service: UserService
  ) {}

  has(key: string) {
    return this.service?.getEntitlement()?.[key] ?? false
  }

  isOrgAdmin() {
    return this.has('ADMIN_ORGANIZATION')
  }

  canAccessLinksViaClient() {
    return this.service?.userPermissions?.links
  }

  canFlexMount() {
    return this.has('FLEX_CLIENT_MOUNT') || this.has('ACCESS_FLEX_CLIENT_MOUNT') || this.has('ACCESS_STORAGE_PROFILE')
  }

  canDownload() {
    if (this.userRole?.isAdminOrL1OrL2OrSales) return true
    return this.has('BROWSER_FILE_DOWNLOAD')
  }

  canStream() {
    if (this.userRole?.isAdminOrL1OrL2OrSales) return true
    return this.has('BROWSER_FILE_STREAMING')
  }

  canStreamOrDownload() {
    return this.canDownload() || this.canStream()
  }

  canCreateReceiveLink() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('CREATE_BEBOP_RECEIVE_LINK')
  }

  canCreateFlexLink() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('CREATE_BEBOP_FLEX_LINK')
  }

  canCreateShareLink() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('CREATE_BEBOP_SEND_LINK')
  }

  canCreateRevPlayLink() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('CREATE_BEBOP_REVPLAY_LINK')
  }

  canCreateQikNotes() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('CREATE_BEBOP_QIK_NOTES_LINK')
  }

  canCreateLiveRoom() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('CREATE_BEBOP_LIVEROOM_LINK')
  }

  canAddToProductionLibrary() {
    if (this.userRole?.isAdminOrL1OrL2) return true
    return this.has('ADD_TO_PRODUCTION_LIBRARY')
  }

  canAccessFlexProjects() {
    return this.canFlexMount() || this.has('ACCESS_BEBOP_FLEX_PROJECTS')
  }

  canAccessFlexLinks() {
    return this.canAccessLinksViaClient() || this.has('ACCESS_BEBOP_FLEX_LINKS')
  }
}
