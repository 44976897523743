<div class="bebop-add-new-project">
  <div class="action-section">
    <bebop-server-status
      status="Loading"
      *ngIf="isLoading"
      [loadingStyles]="{
        width: '96px',
        height: '96px',
        filter: 'contrast(0.75)',
      }"
    ></bebop-server-status>

    <ng-container *ngTemplateOutlet="mainSection"></ng-container>
  </div>
</div>

<ng-template #mainSection>
  <div class="main-section">
    <div class="proj-item">
      <div class="proj-label title">Name:</div>
      <div class="proj-value">
        <bebop-input
          type="text"
          placeholder="Name"
          [(binding)]="bindings.name"
          [autofocus]="true"
          (on-keyup)="onKeyupProjectName($event)"
          [error]="!!formErrors.name"
          noInputLabel="true"
        >
          <div input-error-label>{{ formErrors.name }}</div>
        </bebop-input>
      </div>
    </div>

    <div class="proj-item">
      <div class="proj-label title">Folder label:</div>
      <div class="proj-value">
        <bebop-input
          type="text"
          placeholder="Folder name"
          [(binding)]="bindings.folderLabel"
          (on-keyup)="onKeyupFolderLabel($event)"
          [error]="!!formErrors.folderLabel"
          noInputLabel="true"
        >
          <div input-error-label>{{ formErrors.folderLabel }}</div>
        </bebop-input>

        <div class="hint small-bold">
          Choose a folder name for the project. Max 20 Characters. No special
          characters.
        </div>
      </div>
    </div>

    <div class="proj-item">
      <div class="proj-label title">Drive letter:</div>
      <div class="proj-value">
        <div class="drives">
          <div
            class="drive paragraph"
            *ngFor="let d of drives"
            (click)="onSelectDrive(d)"
            [class.selected]="d.selected"
          >
            {{ d.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="proj-item">
      <div class="saver-checkbox">
        <div class="proj-label title">Auto Mount: </div>
        <div class="saver-checkbox-container">
          <p class="body3">Automatically open this project upon workstation login</p>
          <cree8-toggle
            [checked]="bindings.autoMount"
            (checkedChange)="onChangeAutoMount()"
            [ariaLabel]="'Auto Mount'"
          ></cree8-toggle>
        </div>
      </div>
    </div>
  </div>
</ng-template>
