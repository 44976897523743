import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ICree8Toast } from './cree8-toast.types'
import { Cree8ToastService } from './cree8-toast.service'
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-cree8-toast',
  templateUrl: './cree8-toast.component.html',
  styleUrls: ['./cree8-toast.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0, transform: 'translateY(-20px)' })),
      state('enter', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateY(-20px)' }))
      ])
    ])
  ]
})
export class Cree8ToastComponent implements OnInit, OnDestroy {
  private toastSubscription: Subscription
  toastList: ICree8Toast[] = []

  constructor(private toastService: Cree8ToastService) {}

  ngOnInit(): void {
    this.toastSubscription = this.toastService.getList().subscribe({
      next: (toasts) => {
        this.toastList = toasts;
      },
    })
  }


  ngOnDestroy(): void {
    if (this.toastSubscription) {
      this.toastSubscription.unsubscribe()
    }
  }

  onClose(i: number){
    this.toastService.close(i);
  }

  generateClass(value: any){
    return value as string;
  }
}
