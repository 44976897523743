import { Component, Inject, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { Slide } from 'src/app/common/components/image-carousel/image-carousel.component'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface FileDetailsRocketDownloadAction {
  name: 'Cancel' | 'Close' | 'Save'
}

@Component({
  selector: 'bebop-file-details-rocket-download',
  templateUrl: './file-details-rocket-download.component.html',
  styleUrls: ['./file-details-rocket-download.component.scss'],
})
export class FileDetailsRocketDownloadComponent implements OnInit, OnExternalModalClose {
  slides: Slide[] = []
  showGeneralKeys = true
  showTracks = true

  constructor(
    public ref: ModalOverlayRef<FileDetailsRocketDownloadComponent, FileDetailsRocketDownloadAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    let thumbnails = this.data.thumbnails || (this.data.thumbnail ? [{ img: this.data.thumbnail }] : [])

    this.slides = thumbnails.map(
      (o) => <Slide>{ src: this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + o.img) }
    )
  }

  get hasGeneralKeys(): boolean {
    return this.data.info?.general && Object.keys(this.data.info?.general).length != 0
  }

  get hasTracks() {
    return this.data.info?.tracks?.length != 0
  }

  get tracks() {
    return this.data.info?.tracks || []
  }

  get generalKeys() {
    return Object.keys(this.data.info?.general)
  }

  getTrackKeys(track: any) {
    return Object.keys(track)
  }

  toggleGenericKeys() {
    this.showGeneralKeys = !this.showGeneralKeys
  }

  toggleTracks() {
    this.showTracks = !this.showTracks
  }

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }
}
