<div class="container scrollbar" *ngIf="showForm">
  <div class="header">
    <h1 class="headline1">Profile</h1>
  </div>
  <div class="profile" *ngIf="user && profileForm">
    <div class="information" [formGroup]="profileForm">
      <div class="user">
        <div class="image" title="{{onlineStatus}} {{lastSeen}}">
          <img class="img-circle" [src]="getUserImage(user)" alt="{{ user?.name }}">
          <span class="status" [ngClass]="onlineStatus"></span>
        </div>

        <div>
          <div class="headline3">{{user.username}}</div>
          <div class="body3">{{user.email}}</div>
        </div>
      </div>


      <cree8-input class="input" type="text" placeholder="First name" [binding]="profileForm.get('firstname').value"
        (bindingChange)="profileForm.get('firstname').setValue($event)" [error]="profileForm.get('firstname')?.invalid">
        <div input-label>First name</div>
        <div input-error-label>First name is required.</div>
      </cree8-input>
      <cree8-input class="input" type="text" placeholder="Last name" [binding]="profileForm.get('lastname').value"
        (bindingChange)="profileForm.get('lastname').setValue($event)" [error]="profileForm.get('lastname')?.invalid">
        <div input-label>Last name</div>
        <div input-error-label>Last name is required.</div>
      </cree8-input>

      <div class="avatar-input">
        <div class="body3">Avatar</div>
        <div class="avatars scrollbar">
          <label *ngFor="let avatar of avatars" class="avatar">
            <input type="radio" name="avatar" [value]="avatar" (change)="onAvatarSelected(avatar)"
              [checked]="profileForm.get('picture').value === avatar || 'assets'+profileForm.get('picture').value === avatar" />
            <img [src]="avatar" alt="Avatar Image">
          </label>
        </div>
      </div>

      <div class="button">
        <cree8-btn (click)="updateProfile()" [disabled]="profileForm.invalid">
          Update
        </cree8-btn>
      </div>

    </div>
    <div class="password" *ngIf="isSelf">
      <form [formGroup]="changePassForm">
        <h3 class="headline2">Change password</h3>
        <!-- Current Password Field -->
        <div class="form-group">
          <cree8-input type="password" [binding]="changePassForm.get('currentPassword').value"
            (bindingChange)="changePassForm.get('currentPassword').setValue($event)"
            (on-keyup)="changePassForm.get('currentPassword').markAsDirty()" placeholder="Enter current password"
            [error]="f.currentPassword.dirty && f.currentPassword.invalid">
            <div input-label>Enter current password</div>
            <div input-error-label>Current password is required.</div>
          </cree8-input>
        </div>

        <!-- New Password Field with Validation -->
        <div class="form-group">
          <cree8-input type="password" [binding]="changePassForm.get('newPassword').value"
            (bindingChange)="changePassForm.get('newPassword').setValue($event)"
            (on-keyup)="changePassForm.get('newPassword').markAsDirty()" placeholder="Enter new password"
            [error]="f.newPassword.dirty && f.newPassword.invalid">
            <div input-label>New Password</div>
            <div input-error-label>
              <div class="validation-messages">
                <div *ngIf="!passValidations.isLen">{{ passValidations.lenMessage }}</div>
                <div *ngIf="!passValidations.isCap">{{ passValidations.capMessage }}</div>
                <div *ngIf="!passValidations.hasLetters">{{ passValidations.lettersMessage }}</div>
                <div *ngIf="!passValidations.hasNumber">{{ passValidations.numberMessage }}</div>
                <div *ngIf="!passValidations.hasSpecialChars">{{ passValidations.specialCharsMessage }}</div>
              </div>
            </div>
          </cree8-input>
        </div>

        <!-- Confirm Password Field with Validation -->
        <div class="form-group">
          <cree8-input type="password" [binding]="changePassForm.get('confirmPassword').value"
            (bindingChange)="changePassForm.get('confirmPassword').setValue($event)" placeholder="Confirm new password"
            (on-keyup)="changePassForm.get('confirmPassword').markAsDirty()"
            [error]="f.confirmPassword.dirty && f.confirmPassword.invalid">
            <div input-label>Confirm Password</div>
            <div input-error-label>
              {{ passValidationsConfirm.confirmedMessage }}
            </div>
          </cree8-input>
        </div>
        <div class="form-group">
          <cree8-btn [disabled]="changePassForm.invalid" (click)="onChangePassword()">
            Change
          </cree8-btn>
        </div>
      </form>
    </div>
  </div>
</div>
