<div *ngIf="message" class="bebop-banner paragraph" [ngClass]="bannerClass">
  <div class="banner-container">
    <div class="banner-text" [innerHtml]="message | safeHtml"></div>

    <svg
      class="close-icon"
      (click)="close()"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4107 4.41009C14.7361 4.08466 15.2638 4.08466 15.5892 4.41009C15.9147 4.73553 15.9147 5.26317 15.5892 5.5886L11.1785 9.99935L15.5892 14.4101C15.9147 14.7355 15.9147 15.2632 15.5892 15.5886C15.2638 15.914 14.7361 15.914 14.4107 15.5886L9.99996 11.1779L5.58922 15.5886C5.26378 15.914 4.73614 15.914 4.4107 15.5886C4.08527 15.2632 4.08527 14.7355 4.4107 14.4101L8.82145 9.99935L4.4107 5.5886C4.08527 5.26317 4.08527 4.73553 4.4107 4.41009C4.73614 4.08466 5.26378 4.08466 5.58922 4.41009L9.99996 8.82084L14.4107 4.41009Z"
        fill="white"
        fill-opacity="0.8"
      />
    </svg>
  </div>
</div>
