import { Component, EventEmitter, Input, input, Output } from '@angular/core'

export interface ITabs {
  label: string
  value: string
  active?: boolean
  class?: string
}
@Component({
  selector: 'cree8-tabs',
  styleUrl: './cree8-tabs.component.scss',
  templateUrl: './cree8-tabs.component.html',
})
export class Cree8TabsComponent {
  @Input() type: 'primary' | 'outlined' = 'primary'
  @Input() size: 'small' | 'medium' | 'large' = 'medium'
  @Input() width: 'normal' | 'full' = 'normal'
  @Input() tabs: ITabs[] = []
  @Input() activeTab: string = ''
  @Output() tabClick = new EventEmitter<string>()

  onTabClick(value: string) {
    this.activeTab = value
    this.tabClick.emit(value)

    this.tabs.forEach((tab) => {
      tab.active = tab.value === value
    })
  }
}
