import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { FlexStore, FlexState } from './flex.store'
import { map, distinctUntilChanged } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class FlexQuery extends Query<FlexState> {
  constructor(protected store: FlexStore) {
    super(store)
  }

  getMounts() {
    return this.select('mounts')
  }

  getMountsValue() {
    return this.getValue().mounts
  }

  getSelectedMount() {
    return this.select('selected')
  }

  getSelectedMountValue() {
    return this.getValue().selected
  }

  getPanelView() {
    return this.select('panelView')
  }

  getPinnedView() {
    return this.select('pinnedView')
  }
}
