<div class="cree8-modal-overlay" *ngIf="data">
  <div class="cree8-modal">
    <div class="cree8-modal-content">
      <svg class="ico" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Caution"></use>
      </svg>
      <h2 class="headline2">{{ data.title }}</h2>
      <p class="body3">{{ data.description }}</p>
    </div>

    <div class="cree8-modal-actions">
      <ng-container *ngIf="data.type === 'danger'">
        <cree8-btn (click)="onDelete()" [appearance]="'danger'" [fullwidth]="true" [loading]="data?.loading">DELETE</cree8-btn>
        <cree8-btn (click)="onCloseModal()" [appearance]="'outlined'" [fullwidth]="true" [disabled]="data?.loading">BACK</cree8-btn>
      </ng-container>
    </div>
  </div>
</div>
