<div
  [class.projects-grid]="type === 'grid'"
  [class.projects-list]="type === 'list'"
  *ngIf="listProjects.length > 0"
>
  <ng-container *ngFor="let project of listProjects">
    <card-projects
      [project]="project"
      [type]="type"
      [activeProject]="selectedProject"
      (actionProject)="onActionProject($event)"
    ></card-projects>
  </ng-container>
</div>
