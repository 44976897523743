import { Component, EventEmitter, Input, Output, output } from '@angular/core'

import { IProjectDetail } from 'src/app/models/projects.model'

@Component({
  selector: 'card-projects-list',
  styleUrl: './card-projects-list.component.scss',
  templateUrl: './card-projects-list.component.html',
})
export class CardProjectsListComponent {
  @Input() listProjects: IProjectDetail[] = []
  @Input() type: 'list' | 'grid' = 'grid'
  @Output() actionProject: EventEmitter<any> = new EventEmitter<any>()
  selectedProject: IProjectDetail | null = null

  renderProjectsName(project: IProjectDetail) {
    if (this.type === 'grid') {
      return this.truncateString(project.name)
    }

    return project.name
  }

  truncateString(str: string, maxLength: number | undefined = 22) {
    return str.length > maxLength ? str.slice(0, maxLength) + '...' : str
  }

  onActionProject(data: any) {
    this.selectedProject = data.data
    this.actionProject.emit(data)
  }
}
