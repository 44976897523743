<div
  class="bebop-rocket-server-status"
  [class.connected]="status == 'Connected'"
  [class.loading]="status == 'Loading'"
  [class.connecting]="status == 'Connecting'"
  [class.error]="status == 'Error'"
  [class.roundless]="!rounded"
>
  <div class="status-section">
    <ng-container *ngIf="status == 'Loading'">
      <img loading="lazy" class="loader" [style]="loadingStyles" src="/assets/img/loader/loader.gif" />
    </ng-container>

    <ng-container *ngIf="status != 'Loading'">
      <div class="status-icon">
        <svg class="icon-connecting" *ngIf="status == 'Connecting'" viewBox="0 0 144 144" fill="none">
          <circle cx="72" cy="72" r="71.5" fill="#2B2B33" stroke="url(#a)" />
          <mask id="d" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="-1" y="0" width="145" height="144">
            <path
              d="M143.5 72c0 39.488-32.012 71.5-71.5 71.5C32.51 143.5.5 111.488.5 72S32.51.5 72 .5c39.488 0 71.5 32.012 71.5 71.5Z"
              fill="url(#b)"
              stroke="url(#c)"
            />
          </mask>
          <g mask="url(#d)" stroke="#5A5A68" stroke-width="3">
            <rect x="71.25" y="30.75" width="42" height="15" rx="4.5" />
            <rect x="80.25" y="52.125" width="42" height="15" rx="4.5" />
          </g>
          <mask id="g" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="144" height="144">
            <circle cx="72" cy="72" r="71.5" fill="url(#e)" stroke="url(#f)" />
          </mask>
          <g mask="url(#g)">
            <path
              d="M155.318 87.75h-50.062a8.438 8.438 0 0 0-8.438 8.438v3.937a4.5 4.5 0 0 1-9 0V87.187a6.188 6.188 0 0 0-12.375 0v26.063a6 6 0 0 1-6 6 7.5 7.5 0 0 1-7.5-7.5V74.565a5.94 5.94 0 1 0-11.88 0v3.623A9.563 9.563 0 0 1 40.5 87.75H2.25"
              stroke="#7F7F8C"
              stroke-width="3"
            />
            <circle cx="88.313" cy="38.25" r="1.688" fill="#32BA78" />
            <circle opacity=".22" cx="97.313" cy="59.625" r="1.688" fill="#7F7F8C" />
            <circle opacity=".22" cx="97.313" cy="38.25" r="1.688" fill="#7F7F8C" />
            <circle cx="106.312" cy="59.625" r="1.688" fill="#2D5DE0" />
          </g>
          <defs>
            <linearGradient id="a" x1="1.988" y1=".832" x2="48.281" y2="166.122" gradientUnits="userSpaceOnUse">
              <stop stop-color="#fff" stop-opacity=".08" />
              <stop offset="1" stop-color="#fff" stop-opacity=".04" />
            </linearGradient>
            <linearGradient id="b" x1="127.125" y1="23.063" x2="62.437" y2="64.688" gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B2B33" />
              <stop offset=".835" stop-color="#2B2B33" stop-opacity=".112" />
              <stop offset=".901" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="c" x1="1.987" y1=".832" x2="48.281" y2="166.122" gradientUnits="userSpaceOnUse">
              <stop stop-color="#fff" stop-opacity=".08" />
              <stop offset="1" stop-color="#fff" stop-opacity=".04" />
            </linearGradient>
            <linearGradient id="e" x1="108.563" y1="41.625" x2="30.375" y2="87.188" gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B2B33" />
              <stop offset=".516" stop-color="#2B2B33" stop-opacity=".854" />
              <stop offset=".88" stop-color="#2B2B33" stop-opacity=".073" />
              <stop offset=".906" stop-color="#2B2B33" stop-opacity=".032" />
              <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="f" x1="144" y1="115.2" x2="2.558" y2="109.113" gradientUnits="userSpaceOnUse">
              <stop stop-color="#fff" stop-opacity=".04" />
              <stop offset="1" stop-color="#fff" stop-opacity=".08" />
            </linearGradient>
          </defs>
        </svg>

        <svg class="icon-error" *ngIf="status == 'Error'" viewBox="0 0 144 145" fill="none">
          <circle cx="72" cy="72.5" r="71.5" fill="#2B2B33" stroke="url(#a)" />
          <mask id="d" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="-1" y="0" width="145" height="145">
            <circle cx="72" cy="72.5" r="71.5" transform="rotate(-42.932 72 72.5)" fill="url(#b)" stroke="url(#c)" />
          </mask>
          <g mask="url(#d)" stroke="#7F7F8C" stroke-width="3">
            <path d="M94.5 57a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-3.5a8 8 0 0 1 8-8h2a8 8 0 0 1 8 8V57Z" />
            <path
              d="M-20.182 54.5h56.813a6.188 6.188 0 0 0 6.187-6.188v-.562a5.625 5.625 0 0 1 11.25 0v3.938a7.313 7.313 0 0 0 14.625 0v-15.75a8.438 8.438 0 0 1 16.875 0v10.687"
            />
          </g>
          <mask id="g" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="144" height="145">
            <circle cx="72" cy="72.5" r="71.5" fill="url(#e)" stroke="url(#f)" />
          </mask>
          <g mask="url(#g)" stroke="#7F7F8C" stroke-width="3">
            <path
              d="M160.943 90.5h-51.187a7.313 7.313 0 0 0-7.313 7.313v2.812a4.5 4.5 0 0 1-9 0V89.937a6.188 6.188 0 0 0-12.375 0V113a6.75 6.75 0 1 1-13.5 0V89.375"
            />
            <path d="M58.5 77.875a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v3.5a8 8 0 0 1-8 8h-2a8 8 0 0 1-8-8v-3.5Z" />
            <path d="M64.125 75.875V70.25M70.875 75.875V70.25" stroke-linecap="round" />
          </g>
          <defs>
            <linearGradient id="a" x1="1.988" y1="1.332" x2="48.281" y2="166.622" gradientUnits="userSpaceOnUse">
              <stop stop-color="#fff" stop-opacity=".08" />
              <stop offset="1" stop-color="#fff" stop-opacity=".04" />
            </linearGradient>
            <linearGradient id="b" x1="127.125" y1="23.562" x2="62.437" y2="65.187" gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B2B33" />
              <stop offset=".835" stop-color="#2B2B33" stop-opacity=".112" />
              <stop offset=".901" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="c" x1="1.988" y1="1.332" x2="48.281" y2="166.622" gradientUnits="userSpaceOnUse">
              <stop stop-color="#fff" stop-opacity=".08" />
              <stop offset="1" stop-color="#fff" stop-opacity=".04" />
            </linearGradient>
            <linearGradient id="e" x1="108.563" y1="42.125" x2="30.375" y2="87.688" gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B2B33" />
              <stop offset=".516" stop-color="#2B2B33" stop-opacity=".854" />
              <stop offset=".88" stop-color="#2B2B33" stop-opacity=".073" />
              <stop offset=".906" stop-color="#2B2B33" stop-opacity=".032" />
              <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="f" x1="144" y1="115.7" x2="2.558" y2="109.613" gradientUnits="userSpaceOnUse">
              <stop stop-color="#fff" stop-opacity=".04" />
              <stop offset="1" stop-color="#fff" stop-opacity=".08" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div class="status-message paragraph">
        {{ status == 'Connecting' ? 'Connecting to your servers…' : '' }}
        {{ status == 'Error' ? 'Failed to connect to server. ' : '' }}
      </div>

      <div class="try-message paragraph">Please try again…</div>
    </ng-container>
  </div>
</div>
