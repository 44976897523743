
<card-dashboard *ngIf="data.available" 
[title]="data.info" 
[subtitle]="'Team members'" 
[description]="data.desc" 
[icon]="sanitizedIcon" 
[available]="data.available"
[onButtonClick]="onButtonClick"/>

<card-dashboard *ngIf="!data.available" 
[title]="'Step 03'" 
[subtitle]="'Invite your team'" 
[description]="" 
[icon]="sanitizedIcon" 
[available]="data.available"  
[buttonText]="'INVITE'"
[onButtonClick]="onButtonClick"/>