import { Component, OnInit, Renderer2, RendererFactory2 } from '@angular/core'
import { ActivatedRoute, ChildrenOutletContexts, Router } from '@angular/router'

import { Subscription } from 'rxjs'
import { routeAnimations } from 'src/app/common/animations/route-animations'
import { MainService } from 'src/app/services/main.service'
import { environment } from 'src/environments/environment'

@Component({
  animations: [routeAnimations],
  selector: 'app-layout',
  styleUrls: ['./layout.component.scss'],
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  theme = 'dark'
  loggedIn = false
  online = true
  appEvent$: Subscription
  private renderer: Renderer2
  environment = environment

  constructor(
    private route: ActivatedRoute,
    private rendererFactory: RendererFactory2,
    private router: Router,
    private mainService: MainService,
    private contexts: ChildrenOutletContexts
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, this.theme)

    this.appEvent$ = this.mainService.appEvents((n) => {
      if (n == 'network:online') this.online = true
      else if (n == 'network:offline') this.online = false
      else {
        this.online = this.mainService.getInternetOnlineStatus()
      }
    })
  }

  ngOnDestroy(): void {
    this.appEvent$?.unsubscribe()
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation']
  }
}
