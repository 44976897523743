import { Pipe, PipeTransform } from '@angular/core'

/*
 * Convert the time difference between now and the given date to hh:mm:ss format.
 * Usage:
 *   date | timeDiff:format
 * Example:
 *   {{ new Date() |  timeDiff}}
 *   formats to: 10:01:00
 */
@Pipe({ name: 'timeDiff' })
export class TimeDiffPipe implements PipeTransform {
  transform(date: number = 0, format: string = 'hh:mm:ss'): string {
    const time = new Date(date)
    const now = new Date()

    const diff = Math.floor(Math.abs(now.getTime() - time.getTime()) / 1000)

    const seconds = diff % 60
    const minutes = Math.floor(diff / 60 ) % 60
    const hours = Math.floor(diff / (60 * 60))
   
    const pad = (t: number) => {
      return t < 10 ? '0' + t : t
    }

    return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds)
  }
}
