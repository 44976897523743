import { Component, OnInit } from "@angular/core";

@Component({
  selector: "website-clients",
  templateUrl: "./website-clients.component.html",
  styleUrl: "./website-clients.component.scss",
})
export class WebsiteClientsComponent implements OnInit {
  path = "assets/img/content-web/client-logos/";

  repeatedClients: any[] = [];
  clients = [
    {
      name: "Wayfair",
      src: "Wayfair-1",
    },
    {
      name: "Warner Bros",
      src: "Warner-Bros-Discovery-1",
    },
    {
      name: "Grupo Globo",
      src: "Grupo-Globo-1",
    },
    {
      name: "Glass Poets",
      src: "Glass-Poets-1",
    },
    {
      name: "disney",
      src: "Disney-1",
    },
    {
      name: "Base",
      src: "Base-1",
    },
    {
      name: "Checkpoint",
      src: "Checkpoint-1",
    },
    {
      name: "Culture House",
      src: "Culture-house-1",
    },
    {
      name: "Ispire",
      src: "Ispire-1",
    },
    {
      name: "White Noise",
      src: "White-Noise-1",
    },
  ];

  ngOnInit(): void {
    this.repeatClients();
  }

  repeatClients() {
    for (let i = 0; i < 30; i++) {
      this.repeatedClients = [
        ...this.repeatedClients,
        ...this.clients.map((client) => {
          const baseName = client.src.replace("@1x.png", "");
          return {
            name: client.name,
            src: `${this.path}${baseName}@1x.png`,
            srcset: `${this.path}${baseName}@1x.png 1x, ${this.path}${baseName}@2x.png 2x, ${this.path}${baseName}@3x.png 3x`,
          };
        }),
      ];
    }
  }
}
