/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */

export const getTimepickerTemplate = ({
  okLabel,
  cancelLabel,
  headID,
  footerID,
  bodyID,
  pickerID,
  clearLabel,
  showClearBtn,
  amLabel,
  pmLabel,
}) => {
  const normalTemplate = `<div id='${pickerID}' class='timepicker-wrapper tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-fixed'>
      <div class="tw-flex tw-items-center tw-justify-center tw-flex-col timepicker-container">
        <div class="tw-flex tw-flex-col timepicker-elements tw-justify-around">
        <div id='${headID}' class='timepicker-head tw-flex tw-flex-row tw-items-center tw-justify-center'>
        <div class='timepicker-head-content tw-flex tw-w-100 tw-justify-evenly'>
            <div class="timepicker-current-wrapper">
              <span class="tw-relative tw-h-100">
                <button type='button' class='timepicker-current timepicker-hour tw-active' tabindex="0">21</button>
              </span>
              <button type='button' class='timepicker-dot' disabled>:</button>
            <span class="relative h-100">
              <button type='button' class='timepicker-current timepicker-minute' tabindex="0">21</button>
            </span>
            </div>
            <div class="tw-flex tw-flex-col tw-justify-center timepicker-mode-wrapper">
              <button type='button' class="timepicker-hour-mode timepicker-am" tabindex="0">${amLabel}</button>
              <button class="timepicker-hour-mode timepicker-pm" tabindex="0">${pmLabel}</button>
            </div>
        </div>
      </div>
      <div id='${bodyID}' class='timepicker-clock-wrapper tw-flex tw-justify-center tw-flex-col tw-items-center'>
        <div class='timepicker-clock'>
          <span class='timepicker-middle-dot tw-absolute'></span>
          <div class='timepicker-hand-pointer tw-absolute'>
            <div class='timepicker-circle tw-absolute'></div>
          </div>
        </div>
      </div>
    </div>
    <div id='${footerID}' class='timepicker-footer'>
      <div class="tw-w-full tw-flex tw-justify-between tw-gap-6 timepicker-footer-wrapper">
        ${
          showClearBtn
            ? `<button type='button' class='timepicker-button timepicker-clear' tabindex="0">${clearLabel}</button>`
            : ''
        }
        <button type='button' class='timepicker-button timepicker-cancel' tabindex="0">${cancelLabel}</button>
        <button type='button' class='timepicker-button timepicker-submit' tabindex="0">${okLabel}</button>
      </div>
    </div>
  </div>
</div>`

  return normalTemplate
}

export const getToggleButtonTemplate = (options, id) => {
  const { iconClass } = options

  return `
  <button id="${id}" tabindex="0" type="button" class="timepicker-toggle-button" data-mdb-toggle="timepicker"  >
    <i class="${iconClass}"></i>
  </button>
`
}
