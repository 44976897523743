import { Injectable } from '@angular/core'

import { environment } from 'src/environments/environment'

import { RecaptchaSettingResponse } from '../models/response.model'
import { SessionService } from '../store/session/session.service'

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  recaptchaReady: boolean = false
  recaptchaKey: string
  constructor(private sessionService: SessionService) {
    this.getToken = this.getToken.bind(this)
    this.init()
  }

  init(): void {
    this.sessionService.getRecaptchaSettings().subscribe((settings: RecaptchaSettingResponse) => {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=' + settings?.recaptchaKey)
      document.head.appendChild(recaptchaScript)
      this.recaptchaKey = settings?.recaptchaKey
      setTimeout(() => this.checkReady(), 1000)
    })
  }

  checkReady() {
    window.grecaptcha.ready((_) => (this.recaptchaReady = true))
  }

  getToken(): Promise<string> {
    let _this = this
    return new Promise((resolve, reject) => {
      if (!environment.browser) return resolve('')

      if (!this.recaptchaKey || !window.grecaptcha || !this.recaptchaReady) {
        console.error('Recaptcha is not loaded correctly')
        return reject(new Error('Recaptcha key not available'))
      }

      try {
        window.grecaptcha.execute(_this.recaptchaKey, { action: 'submit' }).then(function (token) {
          resolve(token)
        })
      } catch (e) {
        console.error('captcha error', e)
        reject(e)
      }
    })
  }
}
