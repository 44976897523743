import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
// import intlTelInput from 'intl-tel-input'
// import utilsScript from 'intl-tel-input/build/js/utils.js'
import { timezones } from './timezones'
import { UtilsService } from '../../services/utils.service'

declare var window: any;

@Component({
  selector: 'bebop-phone-input',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./input-phone-number.component.scss'],
})
export class PhoneNumberInputComponent implements OnInit, AfterViewInit {
  showInputGuide = true
  showInputIconLeft = true
  showInputIconRight = true
  showInputError = true
  showInputLabel = true
  inputErrorIconAvailable = true
  inputErrorLabelAvailable = true
  isPasswordField = false
  hidePasswordField = false
  isClearActionEnabled = false


  public inputInstance
  public phoneError: boolean = false
  private currentDialCode: string = '+1'
  private currentCountry: string = 'US'
  private currentCountryName: string = 'United States'

  get isDisabled() {
    return this.util.isTrueLike(this.disabled)
  }

  isLeftIconSeparator = false

  @ViewChild('inputGuide') inputGuide
  @ViewChild('inputIconLeft') inputIconLeft
  @ViewChild('inputIconRight') inputIconRight
  @ViewChild('inputErrorIcon') inputErrorIcon
  @ViewChild('inputErrorLabel') inputErrorLabel

  @Input('type') type: string
  @Input('placeholder') placeholder: string
  @Input('disabled') disabled: string | boolean
  @Input('error') error: string | boolean
  @Input('autofocus') autofocus: string | boolean

  @Input('noInputLabel') noInputLabel: string

  @Input('clearButton') clearButton: string | boolean

  @Input('leftIconSeparator') leftIconSeparator: string | boolean

  @Input('binding') binding = { number: '', dialCode: '', countryCode: '', countryName: '' }
  @Output('bindingChange') onChange = new EventEmitter<any>() // string | number

  @Output('on-error') inputError = new EventEmitter<ErrorEvent>()
  @Output('on-click') click = new EventEmitter<MouseEvent>()
  @Output('on-dblclick') dblclick = new EventEmitter<MouseEvent>()
  @Output('on-submit') submit = new EventEmitter<Event>()
  @Output('on-blur') blur = new EventEmitter<FocusEvent>()
  @Output('on-focus') focus = new EventEmitter<FocusEvent>()
  @Output('on-scroll') scroll = new EventEmitter<Event>()
  @Output('on-cut') cut = new EventEmitter<ClipboardEvent>()
  @Output('on-copy') copy = new EventEmitter<ClipboardEvent>()
  @Output('on-paste') paste = new EventEmitter<ClipboardEvent>()
  @Output('on-keyup') keyup = new EventEmitter<KeyboardEvent>()
  @Output('on-keypress') keypress = new EventEmitter<KeyboardEvent>()
  @Output('on-keydown') keydown = new EventEmitter<KeyboardEvent>()
  @Output('on-mouseup') mouseup = new EventEmitter<MouseEvent>()
  @Output('on-mousedown') mousedown = new EventEmitter<MouseEvent>()
  @Output('on-mouseenter') mouseenter = new EventEmitter<MouseEvent>()
  @Output('on-drag') drag = new EventEmitter<DragEvent>()
  @Output('on-drop') drop = new EventEmitter<DragEvent>()
  @Output('on-dragover') dragover = new EventEmitter<DragEvent>()

  constructor(private cdRef: ChangeDetectorRef, private http: HttpClient, private util: UtilsService) {
    this.onCountryChange = this.onCountryChange.bind(this)
  }

  ngOnInit(): void {

    let input = document.querySelector<HTMLInputElement>('#phone')
    let countryData = window.intlTelInputGlobals.getCountryData()
    for (var i = 0; i < countryData.length; i++) {
      var country = countryData[i];
      country.name = country?.name?.split('(')[0]?.trim();
    }
    
    this.placeholder = this.placeholder || ''
    this.showInputLabel = this.noInputLabel != 'true'


    let internationalPhoneInputOptions = {
      initialCountry: 'auto',
      separateDialCode: true,
      numberType: 'MOBILE',
      // utilsScript,
      geoIpLookup: function(success, failure) {
        let userTimeZone = timezones.find(t => {
          return t.timezones.includes(Intl.DateTimeFormat().resolvedOptions().timeZone)
        })
        return success(userTimeZone.code || 'US')
      },
    }

    // this.inputInstance = intlTelInput(
    //   input,
    //   internationalPhoneInputOptions
    // )

    this.onCountryChange()
    input.addEventListener('countrychange', this.onCountryChange)
  }

  get isAutoFocused() {
    return this.util.isTrueLike(this.autofocus)
  }

  ngAfterViewInit() {
    this.showInputGuide = this.inputGuide?.nativeElement?.children?.length > 0
    this.showInputIconLeft = this.inputIconLeft?.nativeElement?.children?.length > 0
    this.showInputIconRight = this.inputIconRight?.nativeElement?.children?.length > 0
    this.inputErrorIconAvailable = this.inputErrorIcon?.nativeElement?.children?.length > 0
    this.inputErrorLabelAvailable = this.inputErrorLabel?.nativeElement?.children?.length > 0
    this.cdRef.detectChanges()
  }

  onCountryChange() {
    const countryData = this.inputInstance.getSelectedCountryData()
    this.currentDialCode = `+${countryData.dialCode}`
    this.currentCountry = countryData.iso2
    this.currentCountryName = countryData.name
  }

  clearUserInput() {
    this.binding = { number: '', dialCode: '', countryCode: '', countryName: '' }
    this.onChangeUserInput()
  }

  onChangeUserInput() {
    var error = this.inputInstance.getValidationError()
    this.onError(error)
    this.onChange.emit({number: this.binding.number, dialCode: this.currentDialCode, countryCode: this.currentCountry, countryName: this.currentCountryName})
  }

  onClick(ev: MouseEvent) {
    this.click.emit(ev)
  }

  onDblclick(ev: MouseEvent) {
    this.dblclick.emit(ev)
  }

  onSubmit() {
    this.submit.emit()
  }

  onBlur(ev: FocusEvent) {
    this.blur.emit(ev)
  }

  onFocus(ev: FocusEvent) {
    this.focus.emit(ev)
  }

  onScroll(ev: Event) {
    this.scroll.emit(ev)
  }

  onCut(ev: ClipboardEvent) {
    this.cut.emit(ev)
  }

  onCopy(ev: ClipboardEvent) {
    this.copy.emit(ev)
  }

  onPaste(ev: ClipboardEvent) {
    this.paste.emit(ev)
  }

  onKeyup(ev: KeyboardEvent) {
    this.keyup.emit(ev)
  }

  onError(e: ErrorEvent) {
    this.inputError.emit(e)
  }
  onKeypress(ev: KeyboardEvent) {
    this.keypress.emit(ev)
  }

  onKeydown(ev: KeyboardEvent) {
    this.keydown.emit(ev)
  }

  onMouseup(ev: MouseEvent) {
    this.mouseup.emit(ev)
  }

  onMousedown(ev: MouseEvent) {
    this.mousedown.emit(ev)
  }

  onMouseenter(ev: MouseEvent) {
    this.mouseenter.emit(ev)
  }

  onDrag(ev: DragEvent) {
    this.drag.emit(ev)
  }

  onDrop(ev: DragEvent) {
    this.drop.emit(ev)
  }

  onDragover(ev: DragEvent) {
    this.dragover.emit(ev)
  }
}
