import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { debounceTime, Subject, Subscription } from 'rxjs'
import { E } from '../../classes/lib/tw-elements/util/keycodes'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
})
export class InputSearchComponent implements OnInit, OnDestroy {
  @Input('placeholder') placeholder: string
  @Input('disabled') disabled: string | boolean
  @Input('autofocus') autofocus: string | boolean

  @Input('clearButton') clearButton: string

  @Input('leftIconSeparator') leftIconSeparator: string

  @Input('binding') binding: string
  @Output('bindingChange') onChange = new EventEmitter<string>()

  @Output('on-enter') onEnter = new EventEmitter<string>()
  @Output('on-keyup') keyup = new EventEmitter<string>()

  @Input('debounceTime') debounceTimeInMillis: number // in millis
  private debounceHandle: Subject<string> = new Subject()
  debounceHandle$: Subscription

  isClearActionEnabled = true
  isLeftIconSeparator = true

  get isDisabled() {
    return this.util.isTrueLike(this.disabled)
  }

  constructor(private util: UtilsService) {}

  get isAutoFocused() {
    return this.util.isTrueLike(this.autofocus)
  }

  ngOnInit(): void {
    this.isClearActionEnabled = this.clearButton == 'true' || this.clearButton == undefined

    this.placeholder = this.placeholder || 'Search…'
    this.isLeftIconSeparator = this.leftIconSeparator == 'true'

    if (+this.debounceTimeInMillis) {
      this.debounceHandle$ = this.debounceHandle
        .pipe(debounceTime(+this.debounceTimeInMillis || 0))
        .subscribe((v) => this.keyup.emit(v))
    } else {
      this.debounceHandle$ = this.debounceHandle.subscribe((v) => this.keyup.emit(v))
    }
  }

  ngOnDestroy() {
    this.debounceHandle$?.unsubscribe()
  }

  onBindingChange(s: string) {
    if (s == this.binding) return
    this.binding = s

    this.onChange.emit(s)
    if (!s) {
      // on clear event ?
      this.debounceHandle.next(this.binding)
      this.onEnter.emit(this.binding)
    }
  }

  onKeyup(ev: KeyboardEvent) {

    if (ev.key == 'ArrowUp' || ev.key == 'ArrowDown') {
      return
    }

    this.debounceHandle.next(this.binding)
    if (ev.key === 'Enter') {
      this.onEnter.emit(this.binding)
    }
  }
}
