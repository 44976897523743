import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface RetryWorkstationAction {
  name: 'Dismiss' | 'Retry'
  payload?: {
    retryTill: Date
    durationInMinutes: number
  }
}

@Component({
  selector: 'bebop-retry-workstation',
  templateUrl: './retry-workstation.component.html',
  styleUrls: ['./retry-workstation.component.scss'],
})
export class RetryWorkstationComponent implements OnInit, OnExternalModalClose {
  retryIntervals = [5, 10, 15, 30].map((v) => ({
    name: `${v} minutes`,
    value: v,
  }))

  retryInterval = this.retryIntervals[1]

  message = ''

  constructor(
    public ref: ModalOverlayRef<RetryWorkstationComponent, RetryWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.message = this.data.message || 'Failed to launch desktop due to temporary capacity limits.'
  }

  updateRetryInterval(interval: { name: string; value: number }) {
    this.retryInterval = interval
  }

  cancel() {
    this.ref.send({ name: 'Dismiss' })
    this.ref.close()
  }

  keepRetrying() {
    this.ref.send({
      name: 'Retry',
      payload: {
        retryTill: new Date(Date.now() + 1000 * 60 * (this.retryInterval.value ?? 0)),
        durationInMinutes: this.retryInterval.value,
      },
    })
    this.ref.close()
  }
}
