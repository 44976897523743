import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import {
  HotFolder,
  RocketChannel,
  RocketSession,
  RocketTransferProgress,
  UiUploadFile,
} from 'src/app/components/rocket/common/classes/rocket-types'
import { Pod, Project } from 'src/app/models/bebop.model'

let uploadId = 0
export interface UploaderState {
  selectedProject: Project
  selectedUpload: RocketSession<any, UiUploadFile>
  selectedPod: Pod
  projectSelected: boolean

  selectedHotFolder?: HotFolder

  uploads: RocketSession<any, UiUploadFile>[]
}

export function createInitialState(): UploaderState {
  return {
    selectedPod: null,
    selectedProject: null,
    selectedUpload: null,
    selectedHotFolder: null,
    projectSelected: false,
    uploads: [],
  }
}

export function createInitialUploadOverallProgress(): Partial<RocketTransferProgress<UiUploadFile>> {
  return {
    transferred: 0,
    transferredBytes: '0 bytes',
    size: 0,
    sizeBytes: '0 bytes',
    progressCount: 0,
    error: 0,
    completed: 0,
    paused: false,
    completeList: [],
    errorList: [],
    progressList: [],
    verifyingList: [],
    nextList: [],
    queuedList: [],
    durationSeconds: 0,
    durationReadable: '0 Sec',
    transferRate: '0 Mbps',

    pendingCount: 0,
    verifying: 0,

    progress: 0,
    total: 100,
  }
}

export function createUploadSession(
  rocket: any,
  project: Project,
  props?: Partial<RocketSession<any, UiUploadFile>>
): RocketSession<any, UiUploadFile> {
  rocket.$sessionID = rocket.$sessionID || Math.random().toString(36).substring(2, 17)
  return {
    id: ++uploadId,
    rocket,
    entries: [],
    overallProgress: createInitialUploadOverallProgress(),
    newSession: false,
    abort: false,
    sessionID: rocket.$sessionID,
    metricsInfo: {
      $modifiedList: {},
    },
    sessionLastCleared: 0,
    newSessionStarted: 0,
    organization: project?.organization,
    project,
    channel: RocketChannel.Bebop,
    totalFiles: 0,
    busy: [],
    checksumFileList: [],
    remoteDirectory: '/',
    rootPath: '/',
    resetRootPath: '/',
    transferStatus: 'init',
    transferLabel: '0 OF 0',
    ...props,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rocket:uploader' })
export class UploaderStore extends Store<UploaderState> {
  constructor() {
    super(createInitialState())
  }
}
