import { Component, HostListener, Input } from '@angular/core'

@Component({
  selector: 'cree8-theme-toggle',
  styleUrls: ['./cree8-theme-toggle.component.scss'],
  templateUrl: './cree8-theme-toggle.component.html',
})
export class Cree8ThemeToggleComponent {
  @Input('showLabel') showLabel: boolean = true
  isDarkMode: boolean = true

  constructor() {
    // Check initial theme from localStorage or default to dark
    this.isDarkMode = localStorage.getItem('theme') !== 'light'
    this.updateBodyClass()
  }

  toggleTheme() {
    this.isDarkMode = !this.isDarkMode
    this.updateBodyClass()
    // Store preference in localStorage
    localStorage.setItem('theme', this.isDarkMode ? 'dark' : 'light')
  }

  updateBodyClass() {
    const body = document.body
    body.className = 'theme'
    body.classList.add(this.isDarkMode ? 'dark' : 'light')
  }

  getThemeText(): string {
    return this.isDarkMode ? 'Dark theme' : 'Light theme'
  }

  /*
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 't' && event.altKey) {
      this.toggleTheme()
    }
    event.stopPropagation()
    event.preventDefault()
  }
  */
}
