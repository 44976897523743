import { Component, OnDestroy, OnInit } from '@angular/core'

import { Subject } from 'rxjs'
@Component({
  selector: 'bebop-projects',
  styleUrl: './projects.component.scss',
  templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject()

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }
}
