<div class="bebop-checkbox-button" (click)="onChange($event)" [class.rounded]="isRounded" [class.disabled]="isDisabled">
  <div class="select-icon" [class.inactive]="!checked" [class.pulsed]="pulsed" [class.partial]="isPartial">
    <svg viewBox="0 0 12 12" fill="none" class="tick-icon">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.83 3.124a.5.5 0 0 1 .046.705l-4.375 5a.5.5 0 0 1-.703.049L2.173 6.605a.5.5 0 0 1 .654-.756l2.25 1.947 4.047-4.625a.5.5 0 0 1 .705-.047Z"
        fill="#fff"
      />
    </svg>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="dash-icon">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 6.5L2 6.5C1.72386 6.5 1.5 6.27614 1.5 6C1.5 5.72386 1.72386 5.5 2 5.5L10 5.5C10.2761 5.5 10.5 5.72386 10.5 6C10.5 6.27614 10.2761 6.5 10 6.5Z"
        fill="white"
      />
    </svg>
  </div>

  <div class="select-label" [class]="labelClass || 'small'" [class.selected]="checked">
    <ng-content></ng-content>
  </div>
</div>
