<div class="bebop-file-details-rocket-download">
  <div class="header">
    <div class="header-icon">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.267 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-10 8c0-5.523 4.477-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10Zm10-1a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm0-4a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
          fill="#7F7F8C"
        />
      </svg>
    </div>
    <div class="header-title h5">File details</div>

    <div class="header-close" (click)="cancel()">
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.293 5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293Z"
          fill="white"
        />
      </svg>
    </div>
  </div>

  <div class="action-section">
    <div class="block">
      <bebop-image-carousel class="img-carousel" [slides]="slides"></bebop-image-carousel>
      <div class="image-name h4">{{ data.filename }}</div>
    </div>
    <div class="divider"></div>
    <div class="block scroll">
      <div class="general key-value-section" *ngIf="hasGeneralKeys">
        <div class="key-header" (click)="toggleGenericKeys()">
          <div class="header-title h4">General Information</div>
          <div class="arrow-icon">
            <svg *ngIf="showGeneralKeys" viewBox="0 0 24 24" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.78 15.53a.75.75 0 0 0 0-1.06l-5.25-5.25a.75.75 0 0 0-1.06 0l-5.25 5.25a.75.75 0 1 0 1.06 1.06L12 10.81l4.72 4.72a.75.75 0 0 0 1.06 0Z"
                fill="white"
              />
            </svg>
            <svg *ngIf="!showGeneralKeys" viewBox="0 0 24 24" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.78 9.22a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 0 1-1.06 0l-5.25-5.25a.75.75 0 1 1 1.06-1.06L12 13.94l4.72-4.72a.75.75 0 0 1 1.06 0Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="key-values" *ngIf="showGeneralKeys">
          <div class="key-value" *ngFor="let key of generalKeys">
            <div class="key paragraph">{{ data.info?.general?.[key]?.[0] }}</div>
            <div class="value paragraph">{{ data.info?.general?.[key]?.[1] }}</div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="hasTracks">
        <div class="track key-value-section">
          <div class="key-header" (click)="toggleTracks()">
            <div class="header-title h4">Track Information</div>
            <div class="arrow-icon">
              <svg *ngIf="showTracks" viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.78 15.53a.75.75 0 0 0 0-1.06l-5.25-5.25a.75.75 0 0 0-1.06 0l-5.25 5.25a.75.75 0 1 0 1.06 1.06L12 10.81l4.72 4.72a.75.75 0 0 0 1.06 0Z"
                  fill="white"
                />
              </svg>
              <svg *ngIf="!showTracks" viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.78 9.22a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 0 1-1.06 0l-5.25-5.25a.75.75 0 1 1 1.06-1.06L12 13.94l4.72-4.72a.75.75 0 0 1 1.06 0Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <ng-container *ngIf="showTracks">
            <div class="track key-value-section" *ngFor="let track of tracks; let i = index">
              <div class="key-header">
                <div class="header-title h4">Track {{ i }}</div>
              </div>
              <div class="key-values">
                <div class="key-value" *ngFor="let key of getTrackKeys(track)">
                  <div class="key paragraph">{{ track[key]?.[0] }}</div>
                  <div class="value paragraph">{{ track[key]?.[1] }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
