<div class="pre-installed-software">
  <div class="container">
    <div class="title-pre">Preinstalled with the software you love</div>
  </div>
  <div class="container">
    <div class="image-slider">
      <div class="image-row">
        <img
          *ngFor="let image of images"
          [src]="image.src"
          [srcset]="image.srcset"
          [alt]="image.alt"
        />
      </div>

      <div class="image-row reverse">
        <img
          *ngFor="let image of imagesReversed"
          [src]="image.src"
          [srcset]="image.srcset"
          [alt]="image.alt"
        />
      </div>
    </div>
  </div>
</div>
