import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { BehaviorSubject, combineLatest, finalize, Subject, takeUntil, tap } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { User } from 'src/app/models/bebop.model'
import { UiOrganization } from 'src/app/models/ui.model'
import { EntitlementsService } from 'src/app/services/entitlements.service'
import { MainService } from 'src/app/services/main.service'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { UserService } from 'src/app/services/user.service'
import { UserEntitlementsService } from 'src/app/services/user-entitlements.service'
import { TeamService } from 'src/app/store/team/team.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

@Component({
  selector: 'user-settings',
  styleUrls: ['./user-settings.component.scss'],
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  selected = {
    entitlement: null,
    entitlementGroups: null,
    org: null,
  }

  assignSubscription = false
  allGroups: any[] = []
  entitlementsAll: any[] = []
  entitlementsAllTemp: any[] = []
  entitlementGroups: any[] = []
  private originalAssignedToWorkstations: boolean
  private originalAssignedtoSubscription: boolean

  @Input() active = false
  @Input() user: User

  @Output() activeChange = new EventEmitter<boolean>()
  @Output() confirmed = new EventEmitter<void>()

  private updateProfileComplete$ = new BehaviorSubject<boolean>(false)
  private updateSubscriptionComplete$ = new BehaviorSubject<boolean>(false)

  modal = {
    labelCancel: 'CANCEL',
    labelConfirm: 'SAVE',
    title: 'Settings',
    type: 'confirmation',
  } as Cree8Modal

  constructor(
    private userService: UserService,
    private uiQuery: UIQuery,
    private organizationsService: OrganizationsService,
    private entitlementsService: EntitlementsService,
    private userEntitlementsService: UserEntitlementsService,
    private toastService: ToastService,
    private teamService: TeamService,
    private mainService: MainService
  ) {
    // Listen to the completion state changes reactively
    this.watchCompletionStates()
  }

  ngOnInit(): void {
    this.init()
    if (this.user.entitlement) {
      this.originalAssignedtoSubscription = true
      this.assignSubscription = true
    }
    this.originalAssignedToWorkstations = this.user.userPreferences.assignedToWorkstations
  }

  assign() {
    this.assignSubscription = true
  }
  unassign() {
    this.assignSubscription = false
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private init(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (org) {
          this.selected.org = org
          this.onSelectOrg()
        }
      })
  }

  onSelectOrg(): void {
    this.selected.entitlement = this.user.entitlement?.entitlement || null

    console.log(this.user.entitlement)
    this.selected.entitlementGroups = this.user.entitlement?.groups?.map((group) => group._id) || []
    this.listEntitlements()
    this.getEntitlementGroups()
  }

  listEntitlements(): void {
    const query = {
      deactivated: false,
      page: 1,
      size: 100,
      sort: 'name',
    }
    this.entitlementsService
      .getEntitlements(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.entitlementsAllTemp = res.data || []
        this.getEntitlementCapacity()
      })
  }

  getEntitlementCapacity(): void {
    this.organizationsService
      .getOrgEntitlementCapacity()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const capacity = res
        this.entitlementsAll = this.entitlementsAllTemp
          .map((e) => {
            const match = capacity.find((c) => e._id === c.entitlement)
            if (!match) {
              e.capacity = 0
              e.allotted = 0
              return e
            }

            e.expires = match.capacity.expires
            e.capacity = match.capacity.total || 0
            e.allotted = match.capacity.allotted || 0
            e.availableSeats = Math.max(0, e.capacity - e.allotted)
            return e
          })
          .filter((e) => !!e.capacity)
        this.onSelectEntitlement()
      })
  }

  getEntitlementGroups(): void {
    this.entitlementsService
      .getEntitlementGroupsForOrg(this.selected.org?._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allGroups = response
        if (this.selected.entitlement) {
          this.onSelectEntitlement()
        }
      })
  }

  onCancel() {
    this.active = false
    this.activeChange.emit(false)
  }

  onLimitWorkstations(toggled: boolean) {
    // Make sure to check if user and userPreferences exist
    if (this.user && this.user.userPreferences) {
      this.user.userPreferences.assignedToWorkstations = toggled
    }
  }

  onSelectEntitlement(entitlement?: any): void {
    let entitleChanged = false
    if (entitlement) {
      entitleChanged = true
      this.selected.entitlement = entitlement
    }

    // Check if allGroups is properly initialized
    if (!this.allGroups || !this.selected.entitlement || !this.selected.entitlement._id) {
      return
    }

    this.entitlementGroups = this.allGroups[this.selected.entitlement._id] || []

    //reset the groups since the user changed the subscription
    if (entitleChanged) this.selected.entitlementGroups = []
  }

  onSelectGroup(isChecked: boolean, entitlementGroup: any): void {
    if (isChecked) {
      this.selected.entitlementGroups.push(entitlementGroup._id)
    } else {
      this.selected.entitlementGroups = this.selected.entitlementGroups.filter((e) => e !== entitlementGroup._id)
    }
  }

  submit() {
    this.updateProfile()
    if (this.originalAssignedtoSubscription && this.assignSubscription) {
      /**
       * updates the users subscription information which he already had one assign
       */
      this.updateEntitlementForUser()
    } else {
      /**
       * Did not have an assign subsciption
       * updates the subscription status. So removes or add the user to a subscription
       */
      this.updateSubscription()
    }
  }

  updateSubscription() {
    const payload = {} as any

    if (this.assignSubscription) {
      if (this.selected.entitlement && this.selected.entitlementGroups.length > 0) {
        payload.add = {
          entitlement: this.selected.entitlement._id,
          users: [this.user._id],
        }
        payload.groups = this.selected.entitlementGroups
      } else {
        this.toastService.show({
          text: 'Need to select subscription and group',
          type: 'error',
        })
        this.updateSubscriptionComplete$.next(false)
        return
      }
    } else {
      if (this.user.entitlement) {
        payload.remove = {
          entitlement: this.user.entitlement.entitlement._id,
          users: [this.user._id],
        }
      }
    }

    if (payload.remove || payload.add) {
      this.organizationsService
        .updateOrgEntitlementUsers(payload)
        .pipe(
          takeUntil(this.destroy$),
          tap((response) => {
            if (response.success) {
              this.toastService.show({
                text: this.assignSubscription ? 'User assigned to subscription' : 'User unassigned to subscription',
                type: 'success',
              })
              this.updateSubscriptionComplete$.next(true)
            } else {
              this.toastService.show({
                text: response.error.msg,
                type: 'error',
              })
              this.updateSubscriptionComplete$.next(true)
            }
          })
        )
        .subscribe()
    } else {
      this.updateSubscriptionComplete$.next(true)
    }
  }

  updateProfile() {
    const currentAssignedToWorkstations = this.user.userPreferences.assignedToWorkstations

    if (currentAssignedToWorkstations !== this.originalAssignedToWorkstations) {
      const data = {
        email: this.user.email,
        organization: this.selected.org._id,
        userPreferences: { assignedToWorkstations: currentAssignedToWorkstations },
      }

      this.userService
        .updateProfile(this.user._id, data)
        .pipe(
          takeUntil(this.destroy$),
          tap((response) => {
            if (response.error) {
              this.toastService.show({
                text: response.error.msg,
                type: 'error',
              })
              this.updateProfileComplete$.next(false)
            } else {
              this.toastService.show({
                text: currentAssignedToWorkstations
                  ? 'User access limited to select workstations'
                  : 'User access to all workstations',
                type: 'success',
              })
              this.updateProfileComplete$.next(true)
            }
          })
        )
        .subscribe()
    } else {
      this.updateProfileComplete$.next(true)
    }
  }

  updateEntitlementForUser() {
    if (!this.selected.entitlement || this.selected.entitlementGroups.length == 0) {
      this.toastService.show({
        text: 'Need to select subscription and group',
        type: 'error',
      })
      this.updateSubscriptionComplete$.next(false)
      return
    }

    this.userEntitlementsService
      .updateEntitlementForUser({
        entitlement: this.selected.entitlement._id,
        groups: this.selected.entitlementGroups,
        organization: this.selected.org._id,
        users: [this.user._id],
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          if (response.error) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
            this.updateSubscriptionComplete$.next(false)
          } else {
            this.toastService.show({
              text: 'User subscription was updated',
              type: 'success',
            })
            this.updateSubscriptionComplete$.next(true)
          }
        })
      )
      .subscribe()
  }

  // Reactive method to listen to state changes
  private watchCompletionStates() {
    combineLatest([this.updateProfileComplete$, this.updateSubscriptionComplete$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([profileComplete, subscriptionComplete]) => {
        if (profileComplete && subscriptionComplete) {
          this.teamService.triggerRefresh()
          this.onCancel()
        }
      })
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }
}
