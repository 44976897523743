import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { map, Observable, Subject, takeUntil, tap } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { VmPowerStatus } from 'src/app/components/workstation/classes/workstation-types'
import { User } from 'src/app/models/bebop.model'
import { MainService } from 'src/app/services/main.service'
import { WorkstationsService } from 'src/app/services/workstations.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'

@Component({
  selector: 'user-workstations',
  styleUrls: ['./user-workstations.component.scss'],
  templateUrl: './user-workstations.component.html',
})
export class UserWorkstationsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  @Input() active = false
  @Input() user: User
  @Output() activeChange = new EventEmitter<boolean>()
  @Output() confirmed = new EventEmitter<void>()

  modal = {
    title: 'Workstations',
    type: 'close',
  } as Cree8Modal

  workstations = []
  _filteredWorkstations = []
  loading = true

  get filteredWorkstations() {
    return this._filteredWorkstations
  }

  constructor(
    private workstationsService: WorkstationsService,
    private uiQuery: UIQuery,
    private toastService: ToastService,
    private wservice: WorkstationService,
    private mainService: MainService
  ) {}

  ngOnInit(): void {
    this.init()
    this.onSearchWorkstations = this.onSearchWorkstations.bind(this)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onCancel() {
    this.active = false
    this.activeChange.emit(false)
  }

  onSearchWorkstations(value: string) {
    const query = {
      page: 1,
      searchText: value,
      size: 100,
    }
    return this.workstationsService.getAll(query).pipe(
      tap((e) => {
        this._filteredWorkstations = e.error ? [] : (e.data ?? [])
      }),
      map((e) => (e.error ? [] : (e.data?.map((p) => p.DISPLAY_NAME) ?? [])))
    )
  }

  onSelectedWorkstation(workstation: any) {
    this.addWorkstation(workstation)
  }

  resetSearchWorkstations() {
    this._filteredWorkstations = []
  }

  onDropdownWorkstationChange(e: 'Open' | 'Close') {
    this.resetSearchWorkstations()
  }

  addWorkstation(workstation): void {
    this.workstationsService
      .addUserToWorkstation(workstation._id, this.user._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastService.show({ text: 'User added to workstation', type: 'success' })
        this.getUserWorkstations()
      })
  }

  removeWorkstation(workstation): void {
    this.workstationsService
      .deleteUserFromWorkstation(workstation._id, this.user._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastService.show({ text: 'User deleted from workstation', type: 'success' })
        this.getUserWorkstations()
      })
  }

  getPowerCodeLabel(w) {
    return this.wservice.getWorkstationCardPowerCodeLabel(w)
  }

  private init(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (org) {
          this.getUserWorkstations()
        }
      })
  }

  private getUserWorkstations(): void {
    const query = {
      page: 1,
      restrictUsers: this.user._id,
      size: 100,
    }
    this.workstationsService
      .getAll(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.workstations = response.data || []
        this.loading = false
      })
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }
}
