import { Component } from '@angular/core';

@Component({
  selector: 'website-container',
  templateUrl: './website-container.component.html',
  styleUrl: './website-container.component.scss'
})
export class WebsiteContainerComponent {

}
