import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import { RocketSession, UiTransferFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { BebopLink, LinkViewState } from 'src/app/models/bebop.model'

export interface LinkState {
  link: BebopLink
  linkState: LinkViewState
  session: RocketSession<any, UiTransferFile>
}

export function createInitialState(): LinkState {
  return {
    link: null,
    linkState: LinkViewState.Init,
    session: null,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rocket:link' })
export class LinkStore extends Store<LinkState> {
  constructor() {
    super(createInitialState())
  }
}
