<div class="suspended-org" *ngIf="selectedOrg && selectedOrg.suspended">
  <div class="body3">
    Your CREE8 account has been suspended, please contact CREE8 Account Payable to resolve.
  </div>
</div>

<div class="suspended-org" *ngIf="selectedOrg && !selectedOrg.suspended && !hasValidSubscription">
  <div class="body3">
    Sorry, we could not find a valid CREE8 user subscription.
  </div>
</div>



<bebop-loader [status]="'Loading'" [rounded]="false" *ngIf="loading"></bebop-loader>

<div *ngIf="!loading && selectedOrg && !selectedOrg.suspended && hasValidSubscription" class="dashboard scrollbar">

  <div class="banner" *ngIf="hasBanner">
    <bebop-banner *ngIf="hasBanner" [message]="bannerText" (on-close)="onCloseBanner()"></bebop-banner>
  </div>

  <div class="headline1"
    *ngIf="((!uiProjects.available || !uiMembers.available || !uiWorkstations.available) && isOrgAdmin) || (!isOrgAdmin && !uiProjects.available) ">
    Get started</div>
  <div class="headline1"
    *ngIf="(uiProjects.available && uiMembers.available && uiWorkstations.available) || (!isOrgAdmin && uiProjects.available)">
    Hi {{userFirstName}}</div>


  <div class="header">
    <div>
      <cree8-projects-card [data]="uiProjects" />
    </div>

    <div *ngIf="isOrgAdmin">
      <cree8-workstations-card [data]="uiWorkstations" />
    </div>

    <div *ngIf="isOrgAdmin">
      <cree8-team-members-card [data]="uiMembers" />
    </div>
  </div>
  <div class="download-section" *ngIf="!downloadCardClose && environment.browser">
    <div class="dcard-close">
      <cree8-btn-icon (click)="closeDownloadCard()">
        <svg class="ico" viewBox="0 0 24 24">
          <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Xmark_Single'"></use>
        </svg>
      </cree8-btn-icon>
    </div>



    <svg class="download-box" xmlns="http://www.w3.org/2000/svg" width="200" height="97" viewBox="0 0 200 97"
      fill="none">
      <g opacity="0.1">
        <path
          d="M99.9999 41.3407C99.6291 41.7168 99.2583 42.0928 98.8763 42.4916L98.4269 42.9474C94.9325 46.4912 91.1909 50.2743 87.337 54.1942C87.1235 54.3993 86.9212 54.6158 86.719 54.8209L85.5842 55.9718C85.3932 56.1655 85.1909 56.3706 84.9999 56.5643C84.1235 57.4417 83.2359 58.3533 82.3482 59.2535L46.8314 23.2229C52.5954 22.5734 58.483 22.5164 64.2808 23.0633C65.1347 23.1431 65.9887 23.2343 66.8314 23.3368C67.3932 23.4052 67.9437 23.485 68.4943 23.5647C70.5055 23.8496 72.483 24.2142 74.4606 24.6472C75.1235 24.7954 75.7639 24.9435 76.4156 25.103C77.0786 25.2739 77.719 25.4449 78.3594 25.6158C79.6291 25.969 80.8875 26.3565 82.1235 26.7553C84.0336 27.4048 85.8988 28.1113 87.7302 28.8975L99.9999 41.3521V41.3407Z"
          fill="url(#paint0_linear_1395_4023)" />
        <path
          d="M87.7191 28.8749C85.8876 28.1114 84.0225 27.3935 82.1123 26.744C80.8764 26.3338 79.618 25.9578 78.3483 25.6045C77.7078 25.4336 77.0674 25.2627 76.4045 25.0918C75.764 24.9322 75.1348 24.7955 74.4831 24.6474C72.5505 24.2144 70.6067 23.8611 68.6404 23.5763C68.0449 23.4851 67.4382 23.4053 66.8202 23.3256C65.9775 23.223 65.1236 23.1318 64.2696 23.0521C58.4607 22.5051 52.573 22.5621 46.8202 23.2116C39.1798 24.0662 31.7416 25.9578 24.9663 28.8749V46.5711H0V12.603C16.9663 4.01122 36.8539 -0.0339699 56.5393 0.000214813C58.3034 0.000214813 59.9887 0.740883 61.2359 2.00572L87.7191 28.8749Z"
          fill="url(#paint1_linear_1395_4023)" />
        <path
          d="M153.146 23.2344C161.146 24.1118 168.562 26.0831 175.034 28.8977V46.5825H200V12.603C183.034 4.01122 163.146 -0.0339699 143.461 0.000214813C141.697 0.000214813 140.011 0.740883 138.764 2.00572L46.8539 95.2729C38.8539 94.3955 31.4382 92.4242 24.9663 89.6097V71.9248H0V105.904C16.9663 114.507 36.8652 118.541 56.5393 118.507C58.3034 118.507 59.9888 117.766 61.2359 116.502L153.146 23.2344Z"
          fill="url(#paint2_linear_1395_4023)" />
        <path
          d="M153.18 95.2841C147.404 95.9336 141.528 95.9906 135.719 95.4436C134.865 95.3639 134.011 95.2727 133.18 95.1702C132.618 95.1018 132.067 95.022 131.506 94.9423C129.506 94.6574 127.528 94.2928 125.551 93.8598C124.876 93.7116 124.236 93.5635 123.595 93.404C122.921 93.233 122.281 93.0621 121.652 92.8912C120.371 92.5379 119.124 92.1505 117.876 91.7517C115.966 91.1022 114.112 90.3957 112.27 89.6095L100 77.1548C100.404 76.7332 100.91 76.2205 101.494 75.6393C101.966 75.1493 102.472 74.6366 103.034 74.0782L104.584 72.5057C104.888 72.1981 105.202 71.879 105.494 71.5827C109.36 67.6629 113.449 63.5038 117.64 59.2421L153.18 95.2841Z"
          fill="url(#paint3_linear_1395_4023)" />
        <path
          d="M112.281 89.6323C114.112 90.3958 115.978 91.1136 117.888 91.7631C119.124 92.1734 120.382 92.5494 121.652 92.9026C122.292 93.0735 122.933 93.2445 123.596 93.4154C124.236 93.5749 124.865 93.7117 125.517 93.8598C127.449 94.2928 129.393 94.646 131.36 94.9309C131.955 95.0221 132.562 95.1018 133.18 95.1816C134.023 95.2842 134.876 95.3753 135.73 95.4551C141.539 96.002 147.427 95.9451 153.18 95.2955C160.82 94.4409 168.258 92.5494 175.034 89.6323V71.936H200V105.916C183.045 114.519 163.157 118.553 143.472 118.518C141.708 118.518 140.023 117.778 138.775 116.513L112.281 89.6323Z"
          fill="url(#paint4_linear_1395_4023)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_1395_4023" x1="90.0285" y1="31.9088" x2="194.302" y2="131.054"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="0.655" stop-color="#14141A" />
        </linearGradient>
        <linearGradient id="paint1_linear_1395_4023" x1="90.0285" y1="31.9088" x2="194.302" y2="131.054"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="0.655" stop-color="#14141A" />
        </linearGradient>
        <linearGradient id="paint2_linear_1395_4023" x1="90.0285" y1="31.9088" x2="194.302" y2="131.054"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="0.655" stop-color="#14141A" />
        </linearGradient>
        <linearGradient id="paint3_linear_1395_4023" x1="90.0285" y1="31.9088" x2="194.302" y2="131.054"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="0.655" stop-color="#14141A" />
        </linearGradient>
        <linearGradient id="paint4_linear_1395_4023" x1="90.0285" y1="31.9088" x2="194.302" y2="131.054"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="0.655" stop-color="#14141A" />
        </linearGradient>
      </defs>
    </svg>
    <div class="content" *ngIf="environment.browser">
      <div class="download-title">
        <div class="cree8-monogram-logo">
          <img src="/assets/img/bebop_monogram.png" />
        </div>
        <div class="body2">Desktop app</div>
      </div>
      <div class="body3">Launch workstations, manage all your files natively on the desktop client</div>
      <cree8-btn (click)="downloadApp()">Download Now</cree8-btn>
    </div>
  </div>

  <cree8-activities [activities]="activities" [noActivities]="noActivities"></cree8-activities>
</div>
