import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'

import { Subscription } from 'rxjs'
import { CircularProgressOptions } from 'src/app/common/components/circular-progress/circular-progress.component'
import { InputItemsSelect, InputOptionItem } from 'src/app/common/types'
import { Organization, OrgEntitlements, Pod } from 'src/app/models/bebop.model'
import { UiProject } from 'src/app/models/ui.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { FlexQuery } from 'src/app/store/flex/flex.query'
import { DownloaderQuery } from 'src/app/store/rocket/downloader/downloader.query'
import { UploaderQuery } from 'src/app/store/rocket/uploader/uploader.query'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { UIService } from 'src/app/store/ui/ui.service'
import { environment } from 'src/environments/environment'

import {
  RocketSession,
  TransferOverview,
  UiBebopLink,
  UiDownloadFile,
  UiUploadFile,
} from '../rocket/common/classes/rocket-types'

type RockInfo = Pick<HeaderComponent, '_uploadInfo' | '_downloadInfo' | '_hotInfo'>
const FLEX_TICK_INTERVAL = 1000
@Component({
  selector: 'bebop-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  organizations: Organization[] = []
  pods: Pod[] = []
  flexMounts: (UiProject | UiBebopLink)[] = []
  syncFlexMounts: (UiProject | UiBebopLink)[] = []
  flexMounts$: Subscription
  environment = environment

  downloadOptions = { path: 'app/common/assets/animated-icons/Download.gif' }
  uploadOptions = { path: 'app/common/assets/animated-icons/Upload.gif' }
  lucidlinkOptions = { path: 'app/common/assets/animated-icons/Lucidlink.gif' }
  watchFolderOptions = { path: 'app/common/assets/animated-icons/Watch_Folder.gif' }

  constructor(
    private sessionQuery: SessionQuery,
    private uiService: UIService,
    private uiQuery: UIQuery,
    private uploaderQuery: UploaderQuery,
    private downloaderQuery: DownloaderQuery,
    private util: BebopClientUtilsService,
    private flexQuery: FlexQuery,
    private cdRef: ChangeDetectorRef
  ) {
    this.updateDownloadSessions = this.updateDownloadSessions.bind(this)
    this.updateHotfolderSessions = this.updateHotfolderSessions.bind(this)
    this.updateUploadSessions = this.updateUploadSessions.bind(this)
    this.onFlexTickInterval = this.onFlexTickInterval.bind(this)
  }

  podSelect: InputItemsSelect = {
    items: [],
    value: '',
  }

  selectedOrg: Organization

  org$: Subscription
  currentOrg$: Subscription
  selectedOrg$: Subscription
  selectedPod$: Subscription
  uploadSessions$: Subscription
  downloadSessions$: Subscription
  hotSessions$: Subscription
  entitlements$: Subscription

  uploadSessions: RocketSession<any, UiUploadFile>[]
  hotSessions: RocketSession<any, UiUploadFile>[]
  downloadSessions: RocketSession<any, UiDownloadFile>[]
  entitlements: OrgEntitlements

  get hasValidSubscription() {
    return this.entitlements?.[this.selectedOrg?._id] ? true : false
  }

  get isSuspendedOrg() {
    return this.selectedOrg?.suspended == true
  }

  ngOnInit(): void {
    this.flexMounts$ = this.flexQuery.getMounts().subscribe((m) => {
      this.flexMounts = m
      this.updateFlexTick()
    })

    this.org$ = this.sessionQuery.getOrganizations().subscribe((orgs) => {
      this.organizations = orgs
    })

    this.currentOrg$ = this.sessionQuery.getPodsForCurrentOrg().subscribe((pods) => {
      this.pods = pods = this.hasValidSubscription && !this.isSuspendedOrg ? pods : []
      this.podSelect.items = pods.map((pod) => ({
        _id: pod._id,
        selected: this.podSelect.value == pod.name,
        value: pod.name,
      }))
    })

    this.selectedOrg$ = this.uiQuery.getSelectedOrg().subscribe((org) => {
      this.selectedOrg = org
      if (org) {
        console.log('Selected Org updated', org.name)
        this.podSelect.value = ''
      } else {
        this.podSelect.value = ''
      }

      this.updateSessions()
    })

    this.selectedPod$ = this.uiQuery.getSelectedPod().subscribe((pod) => {
      if (pod) {
        console.log('Selected pod updated', pod.name)
        this.podSelect.value = pod.name
      } else {
        this.podSelect.value = ''
      }

      this.onSelectPod(
        this.podSelect?.items?.find((p) => p._id == pod?._id),
        true
      )
    })

    this.entitlements$ = this.sessionQuery
      .getEntitlements()
      .subscribe((entitlements) => (this.entitlements = entitlements))

    this.uploadSessions$ = this.uploaderQuery.getUploadOnlySessions().subscribe(this.updateUploadSessions)
    this.hotSessions$ = this.uploaderQuery.getHotfolderSessions().subscribe(this.updateHotfolderSessions)
    this.downloadSessions$ = this.downloaderQuery.getSessions().subscribe(this.updateDownloadSessions)
  }

  ngOnDestroy(): void {
    ;[
      this.org$,
      this.currentOrg$,
      this.selectedOrg$,
      this.selectedPod$,
      this.uploadSessions$,
      this.hotSessions$,
      this.entitlements$,
      this.downloadSessions$,
      this.flexMounts$,
    ].forEach((u) => u?.unsubscribe())
  }

  get showOverview() {
    return this.hotfolder || this.upload || this.download
  }

  updateUploadSessions(s: RocketSession<any, UiUploadFile>[]) {
    // filter by org ?
    this.uploadSessions = s
    this.updateTransferOverview('_uploadInfo', s)
  }

  updateHotfolderSessions(s: RocketSession<any, UiUploadFile>[]) {
    // filter by org ?
    this.hotSessions = s
    this.updateTransferOverview('_hotInfo', s)
  }

  updateDownloadSessions(s: RocketSession<any, UiDownloadFile>[]) {
    // filter by org ?
    this.downloadSessions = s
    this.updateTransferOverview('_downloadInfo', s)
  }

  updateSessions() {
    this.updateUploadSessions(this.uploadSessions ?? [])
    this.updateHotfolderSessions(this.hotSessions ?? [])
    this.updateDownloadSessions(this.downloadSessions ?? [])
  }

  updateTransferOverview(prop: keyof RockInfo, sessions: RocketSession<any, UiUploadFile>[]) {
    this[prop] = sessions?.reduce(
      (acc: TransferOverview, session) => {
        acc.transferred += session.overallProgress?.transferred ?? 0
        acc.total += session.overallProgress?.size ?? 0
        acc.paused &&= session.overallProgress?.paused ?? false
        return acc
      },
      { label: '', paused: true, progress: 0, total: 0, totalBytes: '0 KB', transferred: 0, transferredBytes: '0 KB' }
    )

    this[prop].progress = Math.floor((this[prop].transferred * 100) / this[prop].total || 0) | 0
    this[prop].totalBytes = this.util.readablizeBytesRounded(this[prop].total)
    this[prop].transferredBytes = this.util.readablizeBytesRounded(this[prop].transferred)

    this[prop].label = `${this[prop].progress}% (${this[prop].transferredBytes})`
  }

  _flexTick = -1
  flexTick() {
    this.clearFlexTick()
    this._flexTick = window.setInterval(this.onFlexTickInterval, FLEX_TICK_INTERVAL)
  }

  updateFlexTick() {
    this.onFlexTickInterval()
    if (this.flexMounts?.length) {
      this.flexTick()
    } else {
      this.clearFlexTick()
    }
  }

  onFlexTickInterval() {
    this.syncFlexMounts = this.flexMounts?.filter(
      (fm) => fm.mounted && (fm.syncStatus != 'up-to-date' || fm.syncing || fm.indexStatus != 'up-to-date'),
      0
    )
  }

  clearFlexTick() {
    if (this._flexTick != -1) window.clearInterval(this._flexTick)
    this._flexTick == -1
  }

  get hotfolder() {
    return this._hotInfo.total != 0
  }

  get upload() {
    return this._uploadInfo.total != 0
  }

  get activeUpload() {
    return this._uploadInfo.progress != 0 && !this._uploadInfo.paused //&& this._uploadInfo.progress != 100
  }

  get activeFlex() {
    return this.syncFlexMounts?.length != 0
  }

  get download() {
    return this._downloadInfo.total != 0
  }

  get activeDownload() {
    return this._downloadInfo.progress != 0 && !this._downloadInfo.paused //&& this._downloadInfo.progress != 100
  }

  get upIdle() {
    return this.activeUpload && (this._uploadInfo.progress == 100 || this._uploadInfo.progress == 0)
  }

  get hotIdle() {
    return this.hotfolder && (this._hotInfo.progress == 100 || this._hotInfo.progress == 0)
  }

  get downIdle() {
    return this.activeDownload && (this._downloadInfo.progress == 100 || this._downloadInfo.progress == 0)
  }

  sliderOpts: Partial<CircularProgressOptions> = {
    initialOffset: -0.34,
    space: -2,
  }

  dropdowns: Record<'up' | 'down' | 'hot' | 'flex', boolean> = {
    down: false,
    flex: false,
    hot: false,
    up: false,
  }

  _hotInfo: TransferOverview = {
    label: '',
    progress: 0,
    total: 0,
    totalBytes: '0 KB',
    transferred: 0,
    transferredBytes: '0 KB',
  }
  get hotFolderInfo() {
    return this._hotInfo.label
  }

  _uploadInfo: TransferOverview = {
    label: '',
    progress: 0,
    total: 0,
    totalBytes: '0 KB',
    transferred: 0,
    transferredBytes: '0 KB',
  }
  get uploadInfo() {
    return this._uploadInfo.label
  }

  _downloadInfo: TransferOverview = {
    label: '',
    progress: 0,
    total: 0,
    totalBytes: '0 KB',
    transferred: 0,
    transferredBytes: '0 KB',
  }
  get downloadInfo() {
    return this._downloadInfo.label
    // return '87% (1.3 KB)'
  }

  onSelectPod(item: InputOptionItem, noBroadcast: boolean = false) {
    this.podSelect.value = item?.value
    this.podSelect.items.forEach((i) => {
      i.selected = i.value == item?.value
    })

    // selected should come first
    this.podSelect.items.sort((l, r) => (l.selected ? -1 : 0))
    this.cdRef.detectChanges()

    let pod = this.pods.find((o) => o._id == item?._id)
    !noBroadcast && this.uiService.setSelectedPod(pod || null)
  }

  onKeyDown(n: number) {
    console.log('on key down', n)
  }

  onKeyUp(n: number) {
    console.log('on key down', n)
  }

  toggleClick(ev: MouseEvent) {
    let e = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })

    ;(<any>ev.currentTarget)?.children?.[0]?.dispatchEvent?.(e)
  }

  _trackObj: any = {}
  onMouseEnter(ev: MouseEvent, k: 'up' | 'down' | 'hot' | 'flex') {
    // console.log('mouse enter')

    if (this._trackObj[<any>ev.currentTarget]) return
    // console.log('mouse enter to click')

    this._trackObj[<any>ev.currentTarget] = true
    this.dropdowns[k] = true

    this.toggleClick(ev)
  }

  onMouseLeave(ev: MouseEvent, k: 'up' | 'down' | 'hot' | 'flex') {
    // console.log('mouse leave')
    if (!this._trackObj[<any>ev.currentTarget]) return
    this._trackObj[<any>ev.currentTarget] = false
    // console.log('mouse leave to click')

    this.dropdowns[k] = false

    this.toggleClick(ev)
  }
}
