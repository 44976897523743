import { trigger, transition, style, group, query, animate, keyframes } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { LinkAction } from 'src/app/common/enums'
import { BrowserUtilsService } from 'src/app/common/services/browser-utils.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { UtilsService } from 'src/app/common/services/utils.service'
import { PageSizes, Pagination } from 'src/app/common/types'
import {
  BebopLink,
  CastBroadcast,
  CastOperator,
  CastOperatorType,
  CastQuality,
  LinkProtection,
  LinkType,
  ObjectId,
  User,
} from 'src/app/models/bebop.model'
import { CastOperatorsResponse, LinkResponse, StandardResponse } from 'src/app/models/response.model'
import {
  BroadcastLinkSecurity,
  LinkSecurity,
  LinkSecurityExpiry,
  StreamQuality,
  UiBroadcast,
  UiBroadcastStatus,
} from 'src/app/models/ui.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { ElectronService } from 'src/app/services/electron.service'
import { ExecutableService } from 'src/app/services/executable.service'
import { UserService } from 'src/app/services/user.service'
import { LinkService } from 'src/app/store/rocket/link/link.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { NavPermissions } from 'src/app/store/ui/ui.store'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'
import {
  InfoBroadcastComponent,
  InfoBroadcastAction,
} from '../../broadcasts/modals/info-broadcast/info-broadcast.component'
import { WORKSTATION_POLL_INTERVAL } from '../../workstation/classes/workstation-types'
import { AppInstallType } from '../../workstation/modals/no-app-workstation/no-app-workstation.component'
import {
  NewOrUpdateBroadcastModalAction,
  NewOrUpdateBroadcastModalComponent,
} from './modals/new-update-broadcast-modal/new-update-broadcast-modal.component'

@Component({
  selector: 'bebop-earth-dashboard',
  templateUrl: './earth-dashboard.component.html',
  styleUrls: ['./earth-dashboard.component.scss'],
  animations: [
    trigger('switchAnim', [
      transition('* <=> *', [
        style({
          position: 'relative',
        }),

        group([
          query(
            '.active',
            [
              style({
                'z-index': 10,
              }),
            ],
            { optional: true }
          ),

          query(
            '.high-def',
            [
              style({
                position: 'relative',
                top: 0,
                left: 40 + 8,
                opacity: 0,
              }),
              animate(
                '500ms ease-out',
                keyframes([style({ left: 24, offset: 0.5, opacity: 0.5 }), style({ left: 0, offset: 1, opacity: 1 })])
              ),
            ],
            { optional: true }
          ),
          query(
            '.std-def',
            [
              style({
                position: 'relative',
                top: 0,
                left: -40 - 8,
                opacity: 0,
              }),
              animate(
                '500ms ease-out',
                keyframes([style({ left: -24, offset: 0.5, opacity: 0.5 }), style({ left: 0, offset: 1, opacity: 1 })])
              ),
            ],
            { optional: true }
          ),
        ]),
      ]),
    ]),
  ],
})
export class EarthDashboardComponent implements OnInit, OnDestroy {
  showRef: ModalOverlayRef<InfoBroadcastComponent, InfoBroadcastAction>
  castRef: ModalOverlayRef<NewOrUpdateBroadcastModalComponent, NewOrUpdateBroadcastModalAction>
  showDropdown = false
  permissions$: Subscription
  permissions: NavPermissions

  broadcasts: UiBroadcast[] = []

  loading = false
  intervalTimeout = -1

  stoppingBroadcast: {
    [key: ObjectId]: boolean
  } = {}

  castLiveRooms: {
    [key: string]: BebopLink
  } = {}

  pageOptions: Pagination = {
    page: 1,
    total: 0,
    size: PageSizes[2],
  }

  get noBroadcasts() {
    return this.broadcasts?.length == 0
  }

  constructor(
    private util: BebopClientUtilsService,
    private modalService: ComponentModalService,
    private uiQuery: UIQuery,
    private userService: UserService,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private wservice: WorkstationService,
    private linkService: LinkService,
    private browserUtil: BrowserUtilsService,
    private toastService: ToastService,
    private electronService: ElectronService,
    private exeService: ExecutableService,
    private utilService: UtilsService
  ) {}

  ngOnInit(): void {
    this.restartTimer()

    this.permissions$ = this.uiQuery.getNavPermissions().subscribe((permissions) => {
      this.permissions = permissions
    })
  }

  ngOnDestroy(): void {
    ;[this.castRef, this.showRef].forEach((ref) => ref?.close())
    ;[this.permissions$].forEach((ref) => ref?.unsubscribe())
    window.clearTimeout(this.intervalTimeout)
  }

  restartTimer() {
    window.clearInterval(this.intervalTimeout)

    this.broadcasts = []

    this.pollCastStations()
    this.intervalTimeout = window.setInterval(() => {
      this.pollCastStations()
    }, WORKSTATION_POLL_INTERVAL)
  }

  pollCastStations() {
    let params: {
      ignoreLiveFilter: boolean
      operatorType: CastOperatorType
    } = {
      operatorType: 'INBOUND',
      ignoreLiveFilter: true,
    }

    this.wservice.getCastOperators(this.pageOptions, params).subscribe((s: CastOperatorsResponse) => {
      if (this.loading) return
      if (s?.error) {
        // no toast as its a poll
        console.error('Unable to fetch cast operators list')
        return
      }

      this.pageOptions.total = s?.total ?? 0
      this.updateBroadcasts(s?.data)
    })
  }

  async getBebopLinkDetails(s: CastOperator) {
    return new Promise<boolean>((r, _) => {
      this.linkService.getLink(s?.broadcast?.liveRoomLink, LinkType.LiveRoom).subscribe((e: LinkResponse) => {
        if (e.error) {
          console.error('Error getting live room link', e.error)
          r(false)
          return
        }
        this.castLiveRooms[s.broadcast.liveRoomLink] = e.link
        r(true)
      })
    })
  }

  // unused client code
  displayStartBroadcast() {
    return this.broadcasts?.length < 1 || (this.permissions?.EARTH_MULTIPLE_BROADCAST && this.broadcasts?.length >= 1)
  }

  updateBebopLink(link: BebopLink) {
    link.for = 'all'
    this.linkService.updateLink(link).subscribe((e) => {
      if (e.error) {
        this.toastService.show({
          type: 'error',
          text: 'CREE8 Link update failed',
        })
        return
      }

      this.toastService.show({
        type: 'success',
        text: 'CREE8 Link has been updated',
      })
    })
  }

  async updateBroadcasts(cops: CastOperator[]) {
    if (!cops?.length) {
      this.broadcasts = []
      this.pageOptions.total = 0

      return
    }

    this.broadcasts = cops?.map((co) => {
      let { broadcast, castStation, operatorType, broadcastStation } = co

      let started = broadcast?.started ? new Date(broadcast?.started) : null
      let ended = broadcast?.ended ? new Date(broadcast?.ended) : null

      started = this.utilService.isDate(started) ? started : null
      ended = this.utilService.isDate(ended) ? ended : null

      let runtime =
        broadcast?.isLive && started
          ? this.util.secondsToStr((Date.now() - started?.getTime()) / 1000)
          : ended
          ? this.browserUtil.getRelativeTimeString(ended)
          : ''

      let streamSource = this.getStreamSource(co) ?? ''

      let status = this.getStatus(co?.broadcast)
      let securitySettings = this.getSecuritySettings(co)

      return {
        status,
        streamSource,
        username: broadcastStation?.loggedInUser?.name ?? '',
        runtime: broadcast?.isLive ? `Running ${runtime}` : `Started ${runtime}`,
        clients: {
          active: co.activeClientsCount,
          total: co.clientsCount || (co.connectedClients?.length ?? 0),
        },
        streamSourceFullLink: broadcast?.liveRoomLinkUrl || this.castLiveRooms[broadcast?.liveRoomLink]?.fullLink,
        quality: this.getQuality(broadcast),
        started: started ?? new Date(0),
        source: co,
        securitySettings,
      }
    })

    // TODO - too many network request can be fired
    // let it be one by one
    for await (const b of this.broadcasts) {
      let source = b?.source
      if (
        source?.broadcast?.isLive &&
        source?.broadcast?.liveRoomLink &&
        !this.castLiveRooms[source?.broadcast?.liveRoomLink]
      ) {
        await this.getBebopLinkDetails(source)
      }
    }

    // Note: castLiveRooms are not cleaned up, it will only be done on page unload/nav change
  }

  getSecuritySettings(co: CastOperator): BroadcastLinkSecurity {
    let link = this.castLiveRooms[co?.broadcast?.liveRoomLink]
    if (!link) return

    return {
      linkSecurity: this.geLinkSecurity(link.protected),
      expiry: this.getExpiry(link.expires),
      password: link.protected == LinkProtection.PASSWORD ? link.password : '',
      // ????
      confirmPassword: link.protected == LinkProtection.PASSWORD ? link.password : '',
      endTime: link.expires,
    }
  }

  getStatus(b: CastBroadcast) {
    // TODO - revisit - not sure about starting, pending states
    if (b?.isLive && b?.livelink && this.castLiveRooms[b?.liveRoomLink]) {
      return UiBroadcastStatus.Live
    }

    return b?.isLive ? UiBroadcastStatus.Pending : UiBroadcastStatus.Starting
  }

  getQuality(b: CastBroadcast) {
    switch (b?.preferences?.quality) {
      case 'lowQ':
        return StreamQuality.SQ
      case 'highQ':
        return StreamQuality.HQ
      default:
        return StreamQuality.SQ
    }
  }

  getCastQuality(b: StreamQuality): CastQuality {
    switch (b) {
      case StreamQuality.SQ:
        return 'lowQ'
      case StreamQuality.HQ:
        return 'highQ'
    }
  }

  openHelpCenter() {
    this.util.openExternalLink(LinkAction.SUPPORT_MAIN, { userId: this.userService.id })
  }

  get broadcastStatusEnum(): typeof UiBroadcastStatus {
    return UiBroadcastStatus
  }

  appNotAvailable(channel: AppInstallType, ev?: Event) {
    this.exeService.appNotAvailable(channel, ev?.target as Element)
  }

  async launchBroadcaster(b: UiBroadcast, ev: Event) {
    let available = this.exeService.isBebopBroadcasterAvailableAsync()

    if (!available) {
      this.appNotAvailable('BebopEarth', ev)
      return
    }

    let cmd = this.exeService.openBebopBroadcasterCommand({
      stream: b?.source,
      user: this.userService.user,
    })

    let subprocess = this.electronService.exec(cmd, (error, stdout, stderr) => {
      console.log('[Launch broadcaster]', {
        cmd,
        error: error?.message ?? '',
        stderr: stderr?.toString() ?? '',
        stdout: stdout?.toString() ?? '',
      })
    })
    b.earth = subprocess

    subprocess.on('exit', () => {
      b.earth = null
    })

    subprocess.stdout.on('data', (data) => {
      console.log('[Launch broadcaster]', 'Output for command:', cmd, data)
    })

    subprocess.stderr.on('data', (data) => {
      console.log('[Launch broadcaster]', 'Error output for command:', cmd, data)
    })

    subprocess.on('close', () => {
      b.earth = null
    })
  }

  closeBroadcaster(b: UiBroadcast) {
    let subprocess = b.earth

    subprocess?.stdin?.end?.()
    subprocess?.stdout?.destroy?.()
    subprocess?.stderr?.destroy?.()
    subprocess?.kill?.()

    b.earth = null

    this.electronService.isRunning('obs64', (status, pid) => {
      //console.log('--------------------------------obs64> ', status, pid); // true|false
      if (!pid || !+pid) return
      process.kill(+pid, 'SIGINT')
      process.kill(+pid)
    })
  }

  stopBroadcast(b: UiBroadcast) {
    let id = b?.source?.castStation?.workstation?._id
    if (!id) return
    this.stoppingBroadcast[b?.source?._id] = true
    this.wservice.stopBroadcast(b?.source?.castStation?.workstation?._id).subscribe((e: StandardResponse) => {
      this.stoppingBroadcast[b?.source?._id] = false
      let error = e.error || e.errObj ? e.message || 'Could not stop the broadcast.' : e.success ? '' : e.message
      if (error) {
        this.toastService.show({
          type: 'error',
          text: error,
        })

        return
      }

      this.pollCastStations()

      this.toastService.show({
        type: 'success',
        text: 'Stopped',
      })
    })
  }

  onClickOutside() {
    this.showDropdown = false
  }

  onClickInstallDropdown() {
    this.showDropdown = !this.showDropdown
  }

  downloadMac() {
    this.util.openExternalLink(LinkAction.BBP_BROADCASTER_DOWNLOAD_OSX, { userId: this.userService.id })
  }

  downloadWin() {
    this.util.openExternalLink(LinkAction.BBP_BROADCASTER_DOWNLOAD_WIN, { userId: this.userService.id })
  }

  showInfo() {
    this.showRef = this.modalService.open<InfoBroadcastComponent, InfoBroadcastAction>(
      InfoBroadcastComponent,
      {
        hasBackdrop: false,
        data: {},
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: false,
        position: {
          top: '86px',
          right: '0px',
        },
      }
    )
  }

  getLinkSecurityLabel(b: UiBroadcast) {
    switch (b.securitySettings?.linkSecurity) {
      case LinkSecurity.Public:
        return 'None'
      case LinkSecurity.Password:
        return 'Protected'
      case LinkSecurity.BebopLogin:
        return 'User Auth'
      default:
        return 'None'
    }
  }

  // ???
  openStream(b: UiBroadcast) {}
  copyStream(b: UiBroadcast) {}

  openRoom(b: UiBroadcast) {
    this.electronService
      .openExternal(b.streamSourceFullLink)
      .catch((e) => this.toastService.show({ text: `Launch failed: ${e.message}`, type: 'error' }))
  }
  copyRoom(b: UiBroadcast) {
    this.electronService.copyToClipboard(b.streamSourceFullLink)

    this.toastService.show({
      type: 'info',
      text: 'Copied to clipboard',
    })
  }

  stdQuality(b: UiBroadcast) {
    if (StreamQuality.SQ == b.quality) return

    b.quality = StreamQuality.SQ
    this.onSelectQualityUpdates(b)
  }

  highQuality(b: UiBroadcast) {
    if (StreamQuality.HQ == b.quality) return

    b.quality = StreamQuality.HQ
    this.onSelectQualityUpdates(b)
  }

  addOrEditBroadcast(ev: Event, b?: UiBroadcast) {
    this.castRef = this.modalService.open<NewOrUpdateBroadcastModalComponent, NewOrUpdateBroadcastModalAction>(
      NewOrUpdateBroadcastModalComponent,
      {
        hasBackdrop: true,
        backdropClass: 'bebop-modal-backdrop',
        animateFrom: ev.target as Element,
        data: {
          broadcast: b,
        },
      },
      {
        hasBackdropClick: true,
        hasEscapeClose: true,
        isCentered: true,
      }
    )

    this.castRef.events().subscribe((e) => {
      console.log(e)
      if (e.name == 'New') {
        let payload = { ...this.linkDefaults(), organization: this.uiQuery.getSelectedOrgValue()?._id }
        let data = e.data
        if (data.endTime) payload.expires = data.endTime

        payload.protected = this.getLinkProtection(data.linkSecurity)

        if (data.password) {
          payload.password = data.password
        }

        this.loading = true
        this.wservice.startBroadcast(payload.organization, payload).subscribe((e: StandardResponse) => {
          if (e.errObj || e.error) {
            this.loading = false
            this.toastService.show({
              type: 'error',
              text: 'Could not start the Broadcast.',
            })
            return
          }

          this.toastService.show({
            type: 'success',
            text: 'Broadcast Starting.',
          })

          setTimeout(() => {
            this.loading = false
            this.pollCastStations()
          }, 3000)
        })
      } else if (e.name == 'Update') {
        let data = e.data
        let payload = this.castLiveRooms[b?.source?.broadcast?.liveRoomLink]

        if (data.endTime) payload.expires = data.endTime

        payload.protected = this.getLinkProtection(data.linkSecurity)

        if (data.password) {
          payload.password = data.password
        }

        this.updateBebopLink(payload)
      }
    })
  }

  get viewStreamOnly() {
    return !this.permissions?.earthMediaServerDashboard
  }

  getLinkProtection(ls: LinkSecurity) {
    switch (ls) {
      case LinkSecurity.Public:
        return LinkProtection.NONE
      case LinkSecurity.Password:
        return LinkProtection.PASSWORD
      case LinkSecurity.BebopLogin:
        return LinkProtection.USER
      default:
        return LinkProtection.NONE
    }
  }

  geLinkSecurity(ls: LinkProtection) {
    switch (ls) {
      case LinkProtection.NONE:
        return LinkSecurity.Public
      case LinkProtection.USER:
        return LinkSecurity.BebopLogin
      case LinkProtection.PASSWORD:
        return LinkSecurity.Password
      default:
        return LinkSecurity.Public
    }
  }

  getExpiry(e: Date) {
    let ts = e?.getFullYear()
    return ts != 2299 ? LinkSecurityExpiry.SetTime : LinkSecurityExpiry.Infinite
  }

  linkDefaults(): BebopLink {
    return {
      _id: null,
      name: '',
      organization: this.uiQuery.getSelectedOrgValue(),
      protected: LinkProtection.NONE,
      linkType: LinkType.LiveRoom,
      expires: new Date('2299-01-01'),
      settings: {},
    }
  }

  onSelectQualityUpdates(b: UiBroadcast) {
    let quality = b.quality
    this.loading = true
    this.wservice
      .updateCastQuality({
        operatorId: b?.source?._id,
        quality: this.getCastQuality(b.quality),
      })
      .subscribe((e) => {
        this.loading = false

        if (e.error) {
          this.toastService.show({
            type: 'error',
            text: 'Could not update view quality.',
          })

          b.quality = quality == StreamQuality.HQ ? StreamQuality.SQ : StreamQuality.HQ
          return
        }

        this.toastService.show({
          type: 'success',
          text: 'View quality updated',
        })
      })
  }

  getStreamSource(co: CastOperator) {
    switch (co.operatorType) {
      case 'BEBOP_BROADCAST':
        return co?.broadcast?.selectedSource?.name ?? ''
      case 'INBOUND':
        return 'Earth Broadcast'
      case 'MEDIASERVER':
        return 'Earth MS Broadcast'
      case 'SWITCHER':
      default:
        return ''
    }
  }
}
