import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { map, Observable, Subject, takeUntil, tap } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { User } from 'src/app/models/bebop.model'
import { MainService } from 'src/app/services/main.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

@Component({
  selector: 'user-projects',
  styleUrls: ['./user-projects.component.scss'],
  templateUrl: './user-projects.component.html',
})
export class UserProjectsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  @Input() active = false
  @Input() user: User
  @Output() activeChange = new EventEmitter<boolean>()
  @Output() confirmed = new EventEmitter<void>()

  modal = {
    title: 'Projects',
    type: 'close',
  } as Cree8Modal

  projects = []
  _filteredProjects = []
  loading = true

  get filteredProjects() {
    return this._filteredProjects
  }

  constructor(
    private projectsService: ProjectsService,
    private uiQuery: UIQuery,
    private toastService: ToastService,
    private mainService: MainService
  ) {}

  ngOnInit(): void {
    this.init()
    this.onSearchProject = this.onSearchProject.bind(this)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onCancel() {
    this.active = false
    this.activeChange.emit(false)
  }

  onSearchProject(value: string) {
    const query = {
      page: 1,
      searchText: value,
      size: 25,
    }
    return this.projectsService.getProjects(query).pipe(
      tap((e) => {
        this._filteredProjects = e.error ? [] : e.data?.filter((p) => p.storage)
      }),
      map((e) => (e.error ? [] : e.data?.filter((p) => p.storage)?.map((p) => p.name)))
    )
  }

  onSelectedProject(project: any) {
    this.addProject(project)
  }

  resetSearchProject() {
    this._filteredProjects = []
  }

  onDropdownProjectChange(e: 'Open' | 'Close') {
    this.resetSearchProject()
  }

  addProject(project): void {
    this.projectsService
      .addUserToProject(project._id, this.user._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastService.show({ text: 'User added to project', type: 'success' })
        this.getUserProjects()
      })
  }

  removeProject(project): void {
    this.projectsService
      .deleteUserFromProject(project._id, this.user._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastService.show({ text: 'User removed from project', type: 'success' })
        this.getUserProjects()
      })
  }

  private init(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (org) {
          this.getUserProjects()
        }
      })
  }

  private getUserProjects(): void {
    this.projectsService
      .getMyProjectsByUserId(this.user._id, { page: 1, size: 100 })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.projects = res.data || []
        this.loading = false
      })
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }
}
