<bebop-loader *ngIf="loading && isHomePage" [status]="'Loading'" [rounded]="false"></bebop-loader>

<div class="container">
  <div class="loader" *ngIf="loading && !isHomePage">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <!-- <no-data [title]="'No projects yet?'" [icon]="'Projects'" [description]="
      'Let\'s not wait any longer—start setting up your first project now!'
    " *ngIf="listProjects.length === 0 && !loading"><cree8-btn (click)="onCreatedProject()">ADD
      PROJECT</cree8-btn></no-data> -->

  <div *ngIf="!loading">

    <card-projects-list [type]="viewType" [listProjects]="listProjects" [isHomePage]="isHomePage"
      (actionProject)="onActionProject($event)" (addProject)="onCreatedProject()"
      (viewAllProjects)="onViewAllProjects()"></card-projects-list>


    <div *ngIf="!isHomePage">
      <div class="pagination" *ngIf="!(buttonPreviousDisabled$ | async) || !(buttonNextDisabled$ | async)">
        <cree8-btn-icon size="small" [disabled]="buttonPreviousDisabled$ | async" (click)="onClickPreviousPage()"
          title="previous page">
          <svg class="ico" viewBox="0 0 24 24">
            <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Chevron_Single_Backward'"></use>
          </svg>
        </cree8-btn-icon>

        <cree8-btn-icon size="small" [disabled]="buttonNextDisabled$ | async" (click)="onClickNextPage()"
          title="next page">
          <svg class="ico" viewBox="0 0 24 24">
            <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Chevron_Single_Forward'"></use>
          </svg>
        </cree8-btn-icon>
      </div>
    </div>

  </div>
</div>

<confirmation-modal *ngIf="modalDeleteActive" [data]="confirmation" (closeModal)="modalDeleteActive = false"
  (delete)="deleteProject()"></confirmation-modal>

<cree8-modal *ngIf="modal.title !== '' && modal.title !== this.MODAL_TITLE_SETTINGS" [modal]="modal"
  (cancelModal)="onCancelModal($event)" (next)="onNext($event)">
  <div class="countar" *ngIf="modal.title === this.MODAL_TITLE_DELETE">
    <div class="body3">
      This project will be deleted in
      <span class="headline1 counter">{{ counter }}</span> seconds
    </div>
  </div>

  <div class="cancel-delete" *ngIf="modal.title !== this.MODAL_TITLE_DELETE">
    <div class="icon" [class.success]="modal.title === this.MODAL_TITLE_SUCCESS_DELETE">
      <ng-container *ngIf="modal.title === this.MODAL_TITLE_CANCEL">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Caution"></use>
        </svg>
      </ng-container>

      <ng-container *ngIf="modal.title === this.MODAL_TITLE_SUCCESS_DELETE">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Checkmark"></use>
        </svg>
      </ng-container>
    </div>
    <span class="body3">
      <ng-container *ngIf="modal.title === this.MODAL_TITLE_CANCEL">
        Project deletion has been successfully canceled
      </ng-container>

      <ng-container *ngIf="modal.title === this.MODAL_TITLE_SUCCESS_DELETE">
        Your project has been successfully deleted.
      </ng-container>
    </span>
  </div>
</cree8-modal>

<cree8-modal *ngIf="modal.title === this.MODAL_TITLE_SETTINGS" [modal]="modal" (cancelModal)="onCancelSettings($event)"
  (next)="onNextSettings($event)">
  <cree8-project-files-settings #projectFilesSettings [isLoading]="loadingSettings"
    (successSubmit)="onSuccessSubmitSettings($event)"></cree8-project-files-settings>
</cree8-modal>