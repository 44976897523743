import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'

import { debounceTime, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { TabItem } from 'src/app/common/components/tabs/tabs.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { LinkAction } from 'src/app/common/enums'
import { BrowserUtilsService } from 'src/app/common/services/browser-utils.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { ObjectId, Organization, OrgEntitlements, Pod, UserWorkstation, Workstation } from 'src/app/models/bebop.model'
import { PodResponse, StandardDataResponse, WorkstationsResponse } from 'src/app/models/response.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { MainService } from 'src/app/services/main.service'
import { UserService } from 'src/app/services/user.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { BebopClientAnnouncement } from 'src/app/store/session/session.store'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { UIService } from 'src/app/store/ui/ui.service'
import { WorkstationQuery } from 'src/app/store/workstation/workstation.query'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'

import {
  FETCH_DELAY_SINCE_LAST_INTERACTION,
  FilterWorkstationActionPayload,
  UiWorkstation,
  UiWorkstationGroup,
  VmPowerStatus,
  WORKSTATION_POLL_INTERVAL,
  WorkstationFilterApplyDefault,
  WorkstationGroupDefault,
  WorkstationLaunchState,
  WorkstationSort,
  WorkstationSortByLabel,
  WorkstationTabKind,
  WorkstationWidgetHandle,
} from '../workstation/classes/workstation-types'
import {
  FilterWorkstationAction,
  FilterWorkstationComponent,
} from '../workstation/modals/filter-workstation/filter-workstation.component'
import {
  InfoWorkstationAction,
  InfoWorkstationComponent,
} from '../workstation/modals/info-workstation/info-workstation.component'

@Component({
  selector: 'bebop-workstations',
  styleUrls: ['./workstations.component.scss'],
  templateUrl: './workstations.component.html',
})
export class WorkstationsComponent implements OnInit, OnDestroy, WorkstationWidgetHandle {
  // workstation search
  search = ''

  filtered = false

  showSortDropdown = false

  ready = false

  filterApply: FilterWorkstationActionPayload

  lastUserInteraction = 0

  groups: UiWorkstationGroup[] = []
  groupMap: Record<string, UiWorkstationGroup> = {}

  favorites: UserWorkstation[] = []
  favoriteMap: Record<ObjectId, UserWorkstation> = {}

  workstations: UiWorkstation[] = []
  filteredWorkstations: UiWorkstation[] = []
  showRef: ModalOverlayRef<InfoWorkstationComponent, InfoWorkstationAction>
  filterRef: ModalOverlayRef<FilterWorkstationComponent, FilterWorkstationAction>
  selectedPod$: Subscription
  annoucement$: Subscription
  clientAnnouncements: BebopClientAnnouncement
  entitlements: OrgEntitlements
  entitlements$: Subscription
  organization: Organization
  organization$: Subscription

  private debounceApplyFilter: Subject<void> = new Subject()
  debounceApplyFilter$: Subscription

  selectedPod: Pod
  intervalTimeout = -1

  sortByType: WorkstationSort = 'Recently Used'

  sortByItems: WorkstationSort[] = ['Recently Used', 'Alphabetical', 'Running Time']

  get defaultGroup() {
    return WorkstationGroupDefault
  }

  get filteredResult() {
    return this.filtered || this.search?.length != 0
  }

  count: { browseAll: number; favCount: number; runningCount: number; notStartedCount: number } = {
    browseAll: 0,
    favCount: 0,
    notStartedCount: 0,
    runningCount: 0,
  }

  get browseAllCountView() {
    return this.count.browseAll ?? 0
  }

  get browseAllTab(): TabItem<any> {
    return {
      badgeColor: '#fff',
      badgeCount: () => this.count.browseAll ?? 0,
      minWidth: '120px',
      name: 'Browse All',
      route: '/app/workstations/all',
    }
  }

  get favTab(): TabItem<any> {
    return {
      badgeColor: '#fff',
      badgeCount: () => this.count.favCount ?? 0,
      minWidth: '90px',
      name: 'Favorites',
      route: '/app/workstations/favs',
    }
  }

  get runningTab(): TabItem<any> {
    return {
      badgeColor: '#fff',
      badgeCount: () => this.count.runningCount ?? 0,
      minWidth: '85px',
      name: 'Running',
      route: '/app/workstations/running',
    }
  }

  get notStartedTab(): TabItem<any> {
    return {
      badgeColor: '#fff',
      badgeCount: () => this.count.notStartedCount ?? 0,
      minWidth: '120px',
      name: 'Not Started',
      route: '/app/workstations/not-started',
    }
  }

  tabs: TabItem[] = [this.browseAllTab, this.favTab, this.runningTab, this.notStartedTab]

  constructor(
    private util: BebopClientUtilsService,
    private modalService: ComponentModalService,
    private browserUtil: BrowserUtilsService,
    private uiService: UIService,
    private uiQuery: UIQuery,
    private sessionService: SessionService,
    private userService: UserService,
    private sessionQuery: SessionQuery,
    private wquery: WorkstationQuery,
    private wservice: WorkstationService,
    private toastService: ToastService,
    private mainService: MainService,
    private cdRef: ChangeDetectorRef
  ) {
    // TODO
    // on log out - do teardown activity which is not possible here
    // some service has to keep track of all connected workstations and clean that up

    this.toUiWorkstation = this.toUiWorkstation.bind(this)
  }

  updateCount(kind: WorkstationTabKind, count: number) {
    setTimeout(() => {
      switch (kind) {
        case 'All':
          this.count.browseAll = count || 0
          break
        case 'Running':
          this.count.runningCount = count || 0
          break
        case 'Not-Started':
          this.count.notStartedCount = count || 0
          break
        case 'Favorite':
          this.count.favCount = count || 0
          break
      }
    }, 50)
  }

  get user() {
    return this.userService.user
  }

  get workstationSortLabel() {
    return `${WorkstationSortByLabel}.${this.user?._id}`
  }

  ngOnInit(): void {
    this.cardUpdateLastUserInteraction = this.cardUpdateLastUserInteraction.bind(this)

    this.sortByType = this.util.getLocalStorageItem(this.workstationSortLabel) || this.sortByType

    this.annoucement$ = this.sessionQuery.getAnnouncements().subscribe((announcements) => {
      this.clientAnnouncements = announcements
      let oldBannerText = this.mainService.getAnnouncementItem('bebop-client:banner:workstations')
      this._bannerText = this.clientAnnouncements?.workstations ?? ''

      if (oldBannerText == this._bannerText) {
        this._bannerText = ''
      }
    })

    this.organization$ = this.uiQuery.getSelectedOrg().subscribe((org) => {
      this.organization = org
    })

    // no initial cache view
    // this.workstations = this.uiQuery.getValue().selectedPod?.workstations?.map(this.toUiWorkstation) ?? []
    // this.buildWorkstationGroups()

    // if (this.workstations?.length) {
    //   this.ready = true
    // }

    this.selectedPod$ = this.uiQuery.getSelectedPod().subscribe((selectedPod) => {
      if (!selectedPod || this.selectedPod?._id == selectedPod?._id) return
      this.selectedPod = selectedPod
      this.restartTimer()
    })

    this.entitlements$ = this.sessionQuery
      .getEntitlements()
      .subscribe((entitlements) => (this.entitlements = entitlements))
    this.debounceApplyFilter$ = this.debounceApplyFilter.pipe(debounceTime(200)).subscribe(() => this.applyFilter())
  }

  restartTimer() {
    window.clearInterval(this.intervalTimeout)

    // this.workstations = []
    // this.groups = []
    // this.groupMap = {}

    if (!this.selectedPod) {
      return
    }

    // this.ready = true
    this.poll()

    this.intervalTimeout = window.setInterval(() => {
      this.poll()
    }, WORKSTATION_POLL_INTERVAL)
  }

  poll() {
    this.pollWorkstation()
    this.pollUserWorkstation()
    this.pollPod()
  }

  _pollingUserWorkstations = false
  pollUserWorkstation() {
    if (this._pollingUserWorkstations || !this.selectedPod?._id) {
      return
    }

    if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) {
      window.clearInterval(this.intervalTimeout)
      window.setTimeout(() => this.restartTimer(), Date.now() - this.lastUserInteraction)
      return
    }

    this._pollingUserWorkstations = true

    let id = this.selectedPod?._id
    this.wservice
      .userFavsAndNicknames({
        podId: id,
      })
      .subscribe((s: StandardDataResponse<UserWorkstation[]>) => {
        this._pollingUserWorkstations = false
        if (s?.error) return

        if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) return

        if (this.selectedPod?._id != id) {
          this.pollUserWorkstation()
          return
        }

        this.favorites = s.data ?? []
        this.favoriteMap = this.favorites.reduce((acc: Record<ObjectId, UserWorkstation>, f: UserWorkstation) => {
          acc[f.workstation?._id] = f
          return acc
        }, {})
        this.buildWorkstationGroups()
      })
  }

  _pollingWorkstations = false
  pollWorkstation() {
    if (this._pollingWorkstations || !this.selectedPod?._id) {
      this.ready = true
      return
    }

    if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) {
      window.clearInterval(this.intervalTimeout)
      window.setTimeout(() => this.restartTimer(), Date.now() - this.lastUserInteraction)
      return
    }

    this._pollingWorkstations = true

    let id = this.selectedPod?._id
    this.sessionService.getWorkstationsByPod(id).subscribe((s: WorkstationsResponse) => {
      this._pollingWorkstations = false
      this.ready = true
      if (s?.error) return

      if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) return

      if (this.selectedPod?._id != id) {
        this.pollWorkstation()
        return
      }

      this.workstations = s.data?.map(this.toUiWorkstation) ?? []
      this.buildWorkstationGroups()
      this.wservice.notifyWorkstationStatus(this.workstations)
      this.checkDisconnect()
    })
  }

  _pollingPod = false
  pollPod() {
    if (this._pollingPod || !this.selectedPod?._id) return
    if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) return

    this._pollingPod = true
    let id = this.selectedPod?._id

    if (!id) return

    this.sessionService.getPodById(id).subscribe((p: PodResponse) => {
      this._pollingPod = false
      if (p?.error) return

      if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) return

      if (this.selectedPod?._id != id) {
        this.pollPod()
        return
      }

      this.selectedPod = <Pod>(<unknown>p)
      this.buildWorkstationGroups()
    })
  }

  addNewWorkstation() {
    window.showLaunchWorkstationModal()
  }

  checkDisconnect() {
    let ws = this.wquery.getActiveWorkstationsValue()

    this.workstations.forEach((uw) => {
      let w = uw?.source

      if (w.FORCE_DISCONNECT && ws[w._id]) {
        this.wservice.removeActiveAppSession(w._id)
      }
    })
  }

  openSupportFreeHrTrial() {
    this.util.openExternalLink(LinkAction.QUICK_START_GUIDE_BEBOP_SOLO_FREE_TRIAL)
  }

  toUiWorkstation(w: Workstation, idx: number = 0): UiWorkstation {
    let name = w.DISPLAY_NAME || w.NAME || `Workstation-${idx + 1}`
    return {
      badges: this.getBadges(w),
      iname: name?.toLowerCase() ?? '',
      name,
      ...this.getPowerCodeLabel(w),
      broadcast: this.getBroadcastStatus(w),
      recentUsed: w.LAUNCHED_AT ? new Date(w.LAUNCHED_AT) : new Date(0),
      runningTime: w.RUNNING_AT ? ((new Date().getTime() - new Date(w.RUNNING_AT).getTime()) / 1000) | 0 : 0,
      source: w,
    }
  }

  getBadges(w: Workstation) {
    return this.wservice.getWorkstationCardBadges(w)
  }

  getBroadcastStatus(w: Workstation) {
    return this.wservice.getBroadcastStatus(w)
  }

  patchWorkstations() {
    this.workstations?.forEach((w) => {
      let uws = this.favoriteMap[w.source?._id]
      if (uws) {
        w.favorite = uws.favorite ?? false
        w.nickname = uws.nickname ?? ''
      }
    })
  }

  sortWorkstations() {
    let fn: (l: UiWorkstation, r: UiWorkstation) => number = null
    switch (this.sortByType) {
      case 'Recently Used':
        fn = (l: UiWorkstation, r: UiWorkstation) => {
          let lv = l.recentUsed?.getTime()
          let rv = r.recentUsed?.getTime()
          // descending
          return lv < rv ? 1 : lv > rv ? -1 : 0
        }

        break
      case 'Alphabetical':
        fn = this.util.naturalSort('name')
        break
      case 'Running Time':
        fn = (l: UiWorkstation, r: UiWorkstation) => {
          let lv = l.runningTime || 0
          let rv = r.runningTime || 0
          // descending
          return lv < rv ? 1 : lv > rv ? -1 : 0
        }
        break
      default:
        console.log('Invalid sort type')
    }

    if (!fn) return
    this.workstations.sort(fn)
  }

  buildWorkstationGroups() {
    this.patchWorkstations()

    let wgroups = this.selectedPod?.workstationConfig?.workstationGroups || []
    let groupKeyMap = wgroups?.reduce(
      (acc, g) => {
        if (!acc[g._id]) {
          acc[g._id] = g.name
        }
        return acc
      },
      <Record<string, string>>{}
    )

    let searchText = this.search?.trim()?.toLowerCase() ?? ''

    // trim down - new / ebs warming instances
    this.workstations = this.workstations.filter((w) => !w.source?.NEW_LAUNCH && !w.source?.EBS_WARMING)

    this.sortWorkstations()
    let noStateWs = this.getNoStateFilteredWorkstations(searchText)

    this.filteredWorkstations = noStateWs?.filter((w) => {
      if (!this.filtered && !searchText?.length) return true

      let { state } = this.filterApply ?? WorkstationFilterApplyDefault

      return w.state == state || state == 'All' || !state
    })

    this.groupMap = this.filteredWorkstations?.reduce(
      (g, w) => {
        let name = groupKeyMap[w.source.WORKSTATION_GROUP] || WorkstationGroupDefault
        if (!g[name]) {
          g[name] = {
            name,
            workstations: [w],
          }
        } else {
          g[name].workstations.push(w)
        }
        w.group = name
        return g
      },
      <Record<string, UiWorkstationGroup>>{ default: { name: WorkstationGroupDefault, workstations: [] } }
    )

    let wgroupNames = wgroups.map((g) => g.name)
    this.groups = wgroupNames.map((n) => this.groupMap[n]).filter((x) => x)

    let favs = this.filteredWorkstations?.filter((w) => w.favorite)

    this.count = {
      browseAll: this.filteredWorkstations?.length || 0,
      favCount: favs?.length || 0,
      notStartedCount: noStateWs?.filter((w) => w.source?.POWER_STATUS_CODE == VmPowerStatus.STOPPED)?.length || 0,
      runningCount: noStateWs?.filter((w) => w.state == 'Running')?.length || 0,
    }

    this.wservice.updateWorkstationTabInfo({
      allWorkstations: this.workstations,
      favorites: favs,
      filterApply: this.filterApply,
      parentWidget: this,
      searchText,
      workstationGroups: wgroups,
    })
  }

  getNoStateFilteredWorkstations(searchText: string) {
    return this.workstations?.filter((w) => {
      if (!this.filtered && !searchText?.length) return true

      // no state filter
      let { users } = this.filterApply ?? WorkstationFilterApplyDefault

      let ret = true

      let uname = w.source?.USER_ID?.name ?? ''

      if (users.length) {
        ret = users?.includes(uname)
      }
      if (!ret) return false

      // By workstation name or user name or nick name
      if (searchText) {
        let nickname = w.nickname?.toLowerCase() ?? ''
        if (
          w.iname?.indexOf(searchText) == -1 &&
          uname.indexOf(searchText) == -1 &&
          nickname.indexOf(searchText) == -1
        ) {
          return false
        }
      }

      return true
    })
  }

  getPowerCodeLabel(w: Workstation): Partial<UiWorkstation> {
    return this.wservice.getWorkstationCardPowerCodeLabel(w)
  }

  ngOnDestroy(): void {
    ;[this.showRef, this.filterRef].forEach((u) => u?.close())
    ;[this.selectedPod$, this.entitlements$, this.organization$, this.debounceApplyFilter$].forEach((u) =>
      u?.unsubscribe()
    )

    window.clearInterval(this.intervalTimeout)

    // no cache persistence on route change
    // let pod = this.uiQuery.getValue().selectedPod

    // if (!pod) return

    // if (this.selectedPod?._id != pod?._id) return

    // pod.workstations = this.workstations.map((w) => w.source).filter((w) => w)
    // this.uiService.setSelectedPod(pod)
  }

  onSearchWorkstation(value: string) {
    this.search = value?.trim?.()
    this.debounceApplyFilter.next()
    // console.log(value)
  }

  openHelpCenter() {
    this.util.openExternalLink(LinkAction.SUPPORT_WORKSTATION, { userId: this.user?._id })
  }

  applyFilter() {
    this.buildWorkstationGroups()
  }

  cardUpdateLastUserInteraction(ev: UiWorkstation) {
    // its not workstation specific, at the moment
    this.lastUserInteraction = Date.now()

    let idx = this.workstations.findIndex((w) => w.source?._id == ev?.source?._id)

    if (idx != -1) {
      Object.assign(this.workstations[idx], ev)
    }

    // avoid patch issue

    let uw = this.favoriteMap[ev.source?._id]
    if (uw) {
      uw.favorite = ev.favorite
      uw.nickname = ev.nickname ?? ''
    }
    this.buildWorkstationGroups()
    // this.workstations = this.workstations.map(w => w.source?._id == ev?.source._id ? ev : w).filter(w => w)
    // console.log(ev, idx)
    this.cdRef.detectChanges()
  }

  showInfo() {
    this.showRef = this.modalService.open<InfoWorkstationComponent, InfoWorkstationAction>(
      InfoWorkstationComponent,
      {
        data: {
          gateway: this.selectedPod?.region?.cgDNS,
        },
        hasBackdrop: false,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: false,
        position: {
          right: '0px',
          top: '86px',
        },
      }
    )
  }

  showFilter() {
    this.filterRef = this.modalService.open<FilterWorkstationComponent, FilterWorkstationAction>(
      FilterWorkstationComponent,
      {
        data: {
          filterApply: this.filterApply,
          parentWidget: this,
          users: [...new Set<string>(this.workstations?.map((x) => x.source?.USER_ID?.name).filter((x) => x))],
        },
        hasBackdrop: false,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: false,
        position: {
          right: '0px',
          top: '86px',
        },
      }
    )

    this.filterRef.events().subscribe((e) => {
      if (e.name == 'Cancel') return
      console.log(e.name, e.payload)
      this.filtered = e.name == 'Apply' && (e.payload.state != 'All' || e.payload.users?.length != 0)

      this.filterApply = e.name == 'Apply' ? e.payload : WorkstationFilterApplyDefault

      this.applyFilter()
    })
  }

  clearAllFilter() {
    this.filterApply = WorkstationFilterApplyDefault
    this.filtered = false
    this.applyFilter()
    this.filterRef?.close()
  }

  onClickOutside() {
    this.showSortDropdown = false
  }

  onClickSortDropdown() {
    this.showSortDropdown = !this.showSortDropdown
  }

  sortBy(by: WorkstationSort) {
    this.sortByType = by
    this.util.setLocalStorageItem(this.workstationSortLabel, by)

    this.buildWorkstationGroups()
  }

  onCloseBanner() {
    this.mainService.setAnnouncementItem('bebop-client:banner:workstations', this._bannerText)
    this._bannerText = ''
  }

  getUsers() {
    return [...new Set<string>(this.workstations?.map((x) => x.source?.USER_ID?.name).filter((x) => x))]
  }

  getGuessFilteredWorkstations(filterApply: FilterWorkstationActionPayload) {
    return this.workstations?.filter((w) => {
      // no state filter
      let { state, users } = filterApply ?? WorkstationFilterApplyDefault

      let ret = true

      let uname = w.source?.USER_ID?.name ?? ''

      if (users.length) {
        ret = users?.includes(uname)
      }
      if (!ret) return false

      return w.state == state || state == 'All' || !state
    })
  }

  guessFilter(apply: FilterWorkstationActionPayload): number {
    return this.getGuessFilteredWorkstations(apply)?.length ?? 0
  }

  private _bannerText: string
  get bannerText() {
    return this._bannerText ?? ''
  }

  get hasBanner() {
    return !!this._bannerText
  }

  get isLaunchNewAllowed() {
    let entitlement = this.entitlements?.[this.organization?._id]
    return entitlement?.['LAUNCH_NEW_WORKSTATION'] ?? false
  }
}
