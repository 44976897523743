import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'

@Component({
  selector: 'cree8-button',
  templateUrl: './cree8-button.component.html',
  styleUrls: ['./cree8-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Cree8ButtonComponent implements OnInit {
  constructor() {}
  @Input('size') size: 'small' | 'medium' | 'large' | 'xsmall' = 'medium'
  @Input('outlined') outlined = false
  @Input('border') border = false
  @Input('disabled') disabled = false
  @Input('loading') loading = false
  @Input('variant') variant: 'light' | 'dark' | 'danger' = 'light'
  ngOnInit(): void {}

  get spinnerVariant(): 'light' | 'dark' {
    return this.outlined ? 'light' : 'dark';
  }
}
