import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'

import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'cree8-input',
  styleUrls: ['./cree8-input.component.scss'],
  templateUrl: './cree8-input.component.html',
})
export class Cree8InputComponent implements OnInit, AfterViewInit {
  showInputGuide = true
  showInputIconLeft = true
  showInputIconRight = true
  showInputError = true
  showInputLabel = true
  inputErrorIconAvailable = true
  inputErrorLabelAvailable = true
  isPasswordField = false
  hidePasswordField = false
  isClearActionEnabled = false

  showIconOnFocus = false // New property to track focus state of the input

  get isDisabled() {
    return this.util.isTrueLike(this.disabled)
  }

  isLeftIconSeparator = false

  @ViewChild('inputGuide') inputGuide?: any
  @ViewChild('inputIconLeft') inputIconLeft?: any
  @ViewChild('inputIconRight') inputIconRight?: any
  @ViewChild('inputErrorIcon') inputErrorIcon?: any
  @ViewChild('inputErrorLabel') inputErrorLabel?: any

  // type is ignored when textarea is set
  @Input('type') type: 'text' | 'password' = 'text'
  @Input('placeholder') placeholder = ''
  @Input('disabled') disabled = false
  @Input('error') error = false
  @Input('autofocus') autofocus = false

  // multiline, visible text only
  @Input('textarea') textarea = false
  // valid only if textarea is set
  @Input('teaxtarea-cols') textareaCols?: number
  @Input('teaxtarea-row') textareaRows?: number
  @Input('textarea-resize') textareaResize?: 'none' | 'horizontal' | 'vertical' | 'both'

  @Input('noInputLabel') noInputLabel?: string

  @Input('clearButton') clearButton = true

  @Input('leftIconSeparator') leftIconSeparator: string | boolean = false

  @Input('binding') binding?: string | number
  @Output('bindingChange') onChange = new EventEmitter<any>() // string | number

  @Output('on-click') click = new EventEmitter<MouseEvent>()
  @Output('on-dblclick') dblclick = new EventEmitter<MouseEvent>()
  @Output('on-submit') submit = new EventEmitter<Event>()
  @Output('on-blur') blur = new EventEmitter<FocusEvent>()
  @Output('on-focus') focus = new EventEmitter<FocusEvent>()
  @Output('on-scroll') scroll = new EventEmitter<Event>()
  @Output('on-cut') cut = new EventEmitter<ClipboardEvent>()
  @Output('on-copy') copy = new EventEmitter<ClipboardEvent>()
  @Output('on-paste') paste = new EventEmitter<ClipboardEvent>()
  @Output('on-keyup') keyup = new EventEmitter<KeyboardEvent>()
  @Output('on-keypress') keypress = new EventEmitter<KeyboardEvent>()
  @Output('on-keydown') keydown = new EventEmitter<KeyboardEvent>()
  @Output('on-mouseup') mouseup = new EventEmitter<MouseEvent>()
  @Output('on-mousedown') mousedown = new EventEmitter<MouseEvent>()
  @Output('on-mouseenter') mouseenter = new EventEmitter<MouseEvent>()
  @Output('on-drag') drag = new EventEmitter<DragEvent>()
  @Output('on-drop') drop = new EventEmitter<DragEvent>()
  @Output('on-dragover') dragover = new EventEmitter<DragEvent>()

  @Input('border') border = true

  constructor(
    private cdRef: ChangeDetectorRef,
    private util: UtilsService
  ) {}

  ngOnInit(): void {
    this.isPasswordField = this.type == 'password'
    this.isClearActionEnabled = this.clearButton
    this.placeholder = this.placeholder || ''
    this.hidePasswordField = this.isPasswordField
    this.isLeftIconSeparator = this.util.isTrueLike(this.leftIconSeparator)

    this.showInputLabel = this.noInputLabel != 'true'
  }

  get isAutoFocused() {
    return this.util.isTrueLike(this.autofocus)
  }

  ngAfterViewInit() {
    this.showInputGuide = this.inputGuide?.nativeElement?.children?.length > 0
    this.showInputIconLeft = this.inputIconLeft?.nativeElement?.children?.length > 0
    this.showInputIconRight = this.inputIconRight?.nativeElement?.children?.length > 0
    this.inputErrorIconAvailable = this.inputErrorIcon?.nativeElement?.children?.length > 0
    this.inputErrorLabelAvailable = this.inputErrorLabel?.nativeElement?.children?.length > 0
    this.cdRef.detectChanges()
  }

  togglePasswordFieldView() {
    if (!this.isPasswordField) return
    this.hidePasswordField = !this.hidePasswordField
  }

  clearUserInput() {
    this.binding = ''
    this.onChangeUserInput()
  }

  onChangeUserInput() {
    this.onChange.emit(this.binding)
  }

  onClick(ev: MouseEvent) {
    this.click.emit(ev)
  }

  onDblclick(ev: MouseEvent) {
    this.dblclick.emit(ev)
  }

  onSubmit() {
    this.submit.emit()
  }

  onBlur(ev: FocusEvent) {
    this.showIconOnFocus = false // Hide icon on blur
    this.blur.emit(ev)
  }

  onFocus(ev: FocusEvent) {
    this.showIconOnFocus = true // Show icon on focus
    this.focus.emit(ev)
  }

  onScroll(ev: Event) {
    this.scroll.emit(ev)
  }

  onCut(ev: ClipboardEvent) {
    this.cut.emit(ev)
  }

  onCopy(ev: ClipboardEvent) {
    this.copy.emit(ev)
  }

  onPaste(ev: ClipboardEvent) {
    this.paste.emit(ev)
  }

  onKeyup(ev: KeyboardEvent) {
    this.keyup.emit(ev)
  }

  onKeypress(ev: KeyboardEvent) {
    this.keypress.emit(ev)
  }

  onKeydown(ev: KeyboardEvent) {
    this.keydown.emit(ev)
  }

  onMouseup(ev: MouseEvent) {
    this.mouseup.emit(ev)
  }

  onMousedown(ev: MouseEvent) {
    this.mousedown.emit(ev)
  }

  onMouseenter(ev: MouseEvent) {
    this.mouseenter.emit(ev)
  }

  onDrag(ev: DragEvent) {
    this.drag.emit(ev)
  }

  onDrop(ev: DragEvent) {
    this.drop.emit(ev)
  }

  onDragover(ev: DragEvent) {
    this.dragover.emit(ev)
  }
}
