<div class="actions-list">

  <div class="expandable-search">
    <label for="search-input"></label>
    <cree8-btn-icon size="large" title="Search" class="icon-button">
      <svg class="ico" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Search"></use>
      </svg>
    </cree8-btn-icon>
    <input type="text" class="input-box" id="search-input" placeholder="Search..." [formControl]="searchControl" />
  </div>

  <cree8-btn-icon class="sorting" (click)="isSortingActive = !isSortingActive"
    *ngIf="allowedActions.includes('sorting')" title="Sorting" size="large">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Filters_Default"></use>
    </svg>
  </cree8-btn-icon>

  <cree8-btn-icon (click)="onRefresh()" *ngIf="allowedActions.includes('refresh')" title="Refresh" size="large">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Refresh"></use>
    </svg>
  </cree8-btn-icon>

  <cree8-btn-icon class="view-type" *ngIf="allowedActions.includes('view-type')"
    (click)="activeView(activedViewType === 'grid' ? 'list' : 'grid')" title="View type" size="large">
    <svg class="ico" viewBox="0 0 24 24">
      <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#' + (activedViewType === 'grid' ? 'List' : 'Grid')">
      </use>
    </svg>
  </cree8-btn-icon>


  <div class="sorting-container" [class.active]="isSortingActive" *ngIf="allowedActions.includes('sorting')"
    #sortingContainer>
    <ul>
      <li [class.active]="item.value === activedSort" *ngFor="let item of listSorting"
        (click)="activeSorting(item.value)">
        {{ item.label }}
        <ng-container *ngIf="item.value === activedSort">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3333 4.33334L6.00001 11.6667L2.66667 8.33334" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </ng-container>
      </li>
    </ul>
  </div>

  <div class="view-type-container" [class.active]="isViewTypeActive" *ngIf="allowedActions.includes('view-type')"
    #viewTypeContainer>
    <ul>
      <li [class.active]="item.value === activedViewType" *ngFor="let item of listViewType"
        (click)="activeView(item.value)">
        {{ item.label }}
        <ng-container *ngIf="item.value === activedViewType">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.3333 4.33334L6.00001 11.6667L2.66667 8.33334" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </ng-container>
      </li>
    </ul>
  </div>

  <cree8-btn-icon class="settings" (click)="onSettings()" *ngIf="allowedActions.includes('settings')" title="Settings"
    size="large">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Settings"></use>
    </svg>
  </cree8-btn-icon>

  <cree8-btn *ngIf="allowedActions.includes('created')" (click)="onCreated()">
    {{ labelCreated }}
  </cree8-btn>
</div>
