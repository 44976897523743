<bebop-input
  class="search-input"
  [disabled]="isDisabled"
  type="text"
  [autofocus]="isAutoFocused"
  [placeholder]="placeholder"
  [binding]="binding"
  (bindingChange)="onBindingChange($event)"
  (on-keyup)="onKeyup($event)"
  noInputLabel="true"
  [leftIconSeparator]="isLeftIconSeparator"
  [clearButton]="isClearActionEnabled"
>
  <div class="icon-left" input-icon-left>
    <svg viewBox="0 0 20 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.333 9.167a5.833 5.833 0 1 1 11.667 0 5.833 5.833 0 0 1-11.667 0Zm5.833-7.5a7.5 7.5 0 1 0 4.682 13.36l3.063 3.062a.833.833 0 1 0 1.178-1.178l-3.063-3.063a7.5 7.5 0 0 0-5.86-12.181Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
</bebop-input>
