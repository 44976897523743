<div class="container">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M20.764 14.472L22.8944 10.2111C23.572 8.856 24.957 7.99999 26.4721 8H38C40.2091 8 42 9.79086 42 12V36C42 38.2091 40.2091 40 38 40H10C7.79086 40 6 38.2091 6 36V12C6 9.79086 7.79086 8 10 8H15.0559C16.571 7.99999 17.956 8.856 18.6336 10.2111L20.9752 14.8944C21.314 15.572 22.0065 16 22.764 16H42"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>

<div class="form-container">
  <div class="proj-item">
    <div class="proj-label title">Name:</div>
    <div class="proj-value">
      <bebop-input
        type="text"
        placeholder="Name"
        [(binding)]="bindings.name"
        [autofocus]="true"
        (on-keyup)="onKeyupProjectName($event)"
        [error]="!!formErrors.name"
        noInputLabel="true"
      >
        <div input-error-label>{{ formErrors.name }}</div>
      </bebop-input>
    </div>
  </div>
</div>
