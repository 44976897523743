<div class="looker-container">
  <!-- Loading state -->
  <div *ngIf="isLoading" class="loading">
    <div class="loading-spinner"></div>
    <p>Loading report...</p>
  </div>
  
  <!-- Error message -->
  <div *ngIf="loadError" class="error-message">
    <p>{{ loadError }}</p>
    <button (click)="reloadReport()" class="retry-button">Retry</button>
  </div>

  <!-- Report iframe -->
  <div *ngIf="reportUrl && !isLoading && !loadError" class="report-content">
    <iframe
      [src]="reportUrl"
      frameborder="0"
      style="border:0"
      allowfullscreen
      class="report-frame">
    </iframe>
  </div>
</div> 