import { Injectable } from '@angular/core'
import { RocketLifeCycle } from 'src/app/components/rocket/common/classes/rocket-types'

@Injectable({
  providedIn: 'root',
})
export class RocketLifecycleService implements RocketLifeCycle {
  lcServices: RocketLifeCycle[]

  constructor() { }

  hasActiveTransfers(): boolean {
    return false
  }

  onLogin() { }

  onLogout() { }
}
