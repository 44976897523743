<div class="bebop-tag-container">
  <div
    class="bebop-tag"
    [class]="clazz"
    [class.label-only]="label == true || label == 'true'"
    [class.can-close]="canClose"
    [class.has-icon]="hasIcon"
    [style.background-color]="bgColor"
    [class.error]="isError"
    [class.client]="isClient"
    [bebop-tooltip]="errorMessage && isError ? errorMessage : null"
    [bebop-tooltip-placement]="errorMessage && isError ? 'bottom' : null"
    [bebop-tooltip-contained-layout]="isContained ? 'true' : 'false'"
    [bebop-tooltip-left-offset-percent-value]="tooltipLeftOffsetValue"
  >
    <div
      class="tag-icon"
      [class.small]="isSmall"
      [class.paragraph]="!isSmall"
      #tagIcon
      [style.color]="color"
      *ngIf="hasIcon"
    >
      <ng-content select="[tag-icon]"></ng-content>
    </div>
    <div class="tag-label" [class.small]="isSmall" [class.paragraph]="!isSmall" [style.color]="color">
      <ng-content></ng-content>
    </div>
    <div class="tag-close-icon" *ngIf="canClose" (click)="onCloseEvent($event)" [class.is-small]="isSmall">
      <svg viewBox="0 0 14 14" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.913 3.088a.583.583 0 0 0-.825.824L6.175 7l-3.087 3.088a.583.583 0 0 0 .825.825L7 7.824l3.088 3.087a.583.583 0 0 0 .825-.825L7.825 7l3.088-3.088a.583.583 0 1 0-.825-.824L7 6.175 3.913 3.088Z"
          fill="#fff"
        />
      </svg>
    </div>
  </div>
</div>
