import { Component, OnInit } from '@angular/core'

import { Subject, takeUntil } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ElectronService } from 'src/app/services/electron.service'
import { UserInviteService } from 'src/app/services/user-invite.service'
import { TeamQuery } from 'src/app/store/team/team.query'
import { TeamService } from 'src/app/store/team/team.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

@Component({
  selector: 'cree8-team-invites',
  styleUrl: './invites.component.scss',
  templateUrl: './invites.component.html',
})
export class InvitesComponent implements OnInit {
  invites: any[] = []
  private destroy$ = new Subject<void>()

  constructor(
    private userInviteService: UserInviteService,
    private teamQuery: TeamQuery,
    private teamService: TeamService,
    private uiQuery: UIQuery,
    private toastService: ToastService,
    private electronService: ElectronService
  ) {}

  defaultQuery = {
    page: 1,
    size: 50,
    sort: '-lastInviteSent',
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit() {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        this.invites = []
        if (!org) {
          return
        }

        this.fetchData(this.defaultQuery)

        this.teamQuery
          .selectAll()
          .pipe(takeUntil(this.destroy$))
          .subscribe((state) => {
            if (state.action === 'refresh') {
              this.fetchData(this.defaultQuery)
              this.teamService.clear()
            } else if (state.action === 'sort' || state.action === 'search') {
              this.fetchData(state.queryParams)
              this.teamService.clear()
            }
          })
      })
  }

  fetchData(data) {
    this.userInviteService
      .getInvites(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.invites = res.data
        },
      })
  }

  resendInvite(invite: any): void {
    this.userInviteService
      .resendInvite({
        inviteCode: invite.inviteCode,
        organization: invite.organization._id,
      })
      .subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.show({
              text: response.error.msg || 'Resend invite failed',
              type: 'error',
            })
          } else {
            this.toastService.show({
              text: 'Invite resent',
              type: 'success',
            })
            this.fetchData(this.defaultQuery)
          }
        },
      })
  }

  revokeInvite(invite: any): void {
    this.userInviteService
      .updateInvite({
        inviteCode: invite.inviteCode,
        inviteStatus: 'Revoked',
        organization: invite.organization._id,
      })
      .subscribe({
        error: (error) => {
          console.error('Revoke invite failed', error)
          this.toastService.show({
            text: 'Revoke invite failed',
            type: 'error',
          })
        },
        next: () => {
          this.toastService.show({
            text: 'Invite revoked',
            type: 'success',
          })
          this.fetchData(this.defaultQuery)
        },
      })
  }

  copyInviteURL(invite: any): void {
    this.electronService.copyToClipboard(invite.inviteUrl)
    navigator.clipboard.writeText(invite.inviteUrl)

    this.toastService.show({
      text: 'Invite URL copied to clipboard',
      type: 'success',
    })
  }
}
