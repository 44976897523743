import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'cree8-label',
  styleUrls: ['./cree8-label.component.scss'],
  templateUrl: './cree8-label.component.html',
})
export class Cree8LabelComponent implements OnInit {
  @Input('size') size: 'small' | 'medium' = 'small'
  @Input('type') type: 'subtle' | 'accent' = 'accent'

  ngOnInit(): void {}

  getClass(): string {
    let classes = 'cree8-label'

    if (this.size === 'small') {
      classes += ' cree8-label--small'
    } else if (this.size === 'medium') {
      classes += ' cree8-label--medium'
    }

    if (this.type === 'subtle') {
      classes += ' cree8-label--subtle'
    } else if (this.type === 'accent') {
      classes += ' cree8-label--accent'
    }

    return classes
  }
}
