<div class="bebop-input-tags-widget" [class]="{ disabled: isDisabled, multiline: isMultilined }">
  <div class="input-top-section" *ngIf="showInputLabel">
    <div class="input-label small" #inputTagLabel>
      <ng-content select="[tags-label]"></ng-content>
    </div>
  </div>

  <div class="input-main-section">
    <div class="tags-list">
      <div class="tag-entry" *ngFor="let e of all; let i = index">
        <bebop-tag [contained-layout]="isContainedLayout" [tooltip-left-offset-percent-value]="tooltipLeftOffsetValue" closable="true" (close)="onRemoveTagWithError(i)" [error]="e.error" [error-message]="e.message">{{
          e.tag
        }}</bebop-tag>
      </div>
      <div class="input-main-editor" *ngIf="isMultilined">
        <input
          [bebopAutoFocus]="isAutoFocused"
          [placeholder]="placeholder"
          [disabled]="isDisabled"
          class="input-text"
          [(ngModel)]="tag"
          (keyup)="onKeyup($event)"
        />
      </div>
    </div>

    <div class="input-main-editor" *ngIf="!isMultilined">
      <input
        [bebopAutoFocus]="isAutoFocused"
        [placeholder]="placeholder"
        [disabled]="isDisabled"
        class="input-text"
        [(ngModel)]="tag"
        (keyup)="onKeyup($event)"
      />
    </div>
  </div>
</div>
