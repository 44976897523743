import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

export interface ITestimoni {
  quote: string;
  name: string;
  profession: string;
  photo: string;
  quoteWidthClass: string;
}
@Component({
  selector: 'website-testimoni',
  templateUrl: './website-testimoni.component.html',
  styleUrl: './website-testimoni.component.scss'
})
export class WebsiteTestimoniComponent implements AfterViewInit {
  @Input() testimoni: ITestimoni = {} as ITestimoni;
  @Input() large: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements =
      this.elementRef.nativeElement.querySelectorAll(".fade-in-up");
    elements.forEach((el: Element) => observer.observe(el));
  }
}
