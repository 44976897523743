import {
  ChangeDetectorRef,
  Component,
  Input,
  Renderer2,
  OnInit,
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import {
  CENTER_MENU_ITEMS,
  RIGHT_MENU_ITEMS,
  SIGNUP_BUTTON,
  DEMO_BUTTON,
} from './navbar.constants'
import {
  NAVBAR_IMAGES,
  INDUSTRY_CARDS,
  RESOURCES_FEATURED,
} from './navbar.image.constants'

declare let gtag: Function

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  show = false
  activeMenu = 'home'
  showCalendly = false
  isSignupRoute = false

  @Input() disableOverlay = false
  @Input() direct = false
  @Input() backToHome = false

  centerMenus: any[] = []
  rightMenus: any[] = []
  @Input() listMenus: any[] = []

  private readonly defaultMenus = [...CENTER_MENU_ITEMS, ...RIGHT_MENU_ITEMS]

  readonly images = NAVBAR_IMAGES
  readonly industryCards = INDUSTRY_CARDS
  readonly resourcesFeatured = RESOURCES_FEATURED

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setupMenus(event.urlAfterRedirects)
      }
    })
  }

  ngOnInit(): void {
    this.setupMenus(this.router.url)
  }

  private setupMenus(url: string): void {
    const path = url.split('?')[0]
    this.isSignupRoute = path.startsWith('/signup')

    const baseUrl = window.location.origin

    if (this.isSignupRoute) {
      this.centerMenus = CENTER_MENU_ITEMS.filter(
        (menu) => !['pricing', 'blog'].includes(menu.id)
      )
      this.rightMenus = [
        ...RIGHT_MENU_ITEMS.filter((menu) => menu.id !== 'signup'),
        SIGNUP_BUTTON,
      ]
    } else {
      this.centerMenus = CENTER_MENU_ITEMS.filter((menu) =>
        ['pricing', 'contact', 'blog'].includes(menu.id)
      )
      this.rightMenus = [
        ...RIGHT_MENU_ITEMS.map((menu) => {
          if (menu.id === 'signup') {
            return {
              ...menu,
              href: `${baseUrl}/signup/personal-details?free=true`,
            }
          }
          return menu
        }),
        DEMO_BUTTON,
      ]
    }

    this.listMenus = [...this.centerMenus, ...this.rightMenus]

    this.cdr.detectChanges()
  }

  toggle(): void {
    this.show = !this.show
    this.cdr.detectChanges()
  }

  hasDropdown(menu: any): boolean {
    return menu.showDropdown && menu.items && menu.items.length > 0
  }

  redirectTo(item: any): void {
    if (item.target === 'scroll') {
      const element = document.getElementById(item.href)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        if (item.href === 'pricing') {
          this.activeMenu = 'pricing'
        }
      } else {
        this.router.navigate(['/'], { fragment: item.href })
      }
      return
    }

    if (item.target === 'current') {
      this.router.navigate([item.href])
      return
    }

    if (item.id === 'demo') {
      gtag('event', 'DEMO_BUTTON', {
        event_category: 'Form Submission',
        event_label: 'Lead Form',
        value: 1,
      })

      gtag('event', 'conversion', {
        send_to: 'AW-11321015870/D2P4CO2-pNMZEL78o5Yq',
        event_category: 'Demo Button Click',
        event_label: 'Book appointments',
      })

      this.showCalendly = true
      return
    }

    window.location.replace(item.href)
  }

  loadCalendlyScript(): void {
    const link = this.renderer.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://assets.calendly.com/assets/external/widget.css'
    this.renderer.appendChild(document.head, link)

    const script = this.renderer.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    this.renderer.appendChild(document.body, script)

    script.onload = () => {
      ;(window as any).Calendly.initBadgeWidget({
        url: 'https://calendly.com/cree8/cree8-demo/30min',
        text: 'Schedule time with me',
        color: '#0069ff',
        textColor: '#ffffff',
      })
    }
  }

  onRedirectHome(): void {
    if (this.direct) {
      const element = document.getElementById('hero')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        this.activeMenu = 'home'
      } else {
        this.router.navigate(['/'])
      }
    } else {
      window.location.replace('https://cree8.io/')
    }
  }

  sanitizeIcon(icon: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(icon)
  }
}
