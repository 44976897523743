import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss',
  animations: [
    trigger('slideToggle', [
      state('void', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden',
      })),
      state('*', style({
        height: '*',
        opacity: 1,
      })),
      transition('void <=> *', [
        animate('300ms ease-in-out')
      ]),
    ]),
  ]

})
export class AccordionComponent {
  @Input() show = false;
  @Input() title = 'Features'
  @Input() variant: 'light' | 'dark' = 'dark'

  toggleDiv() {
    this.show = !this.show;
  }
}
