import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { UIStore, UIState } from './ui.store'
import { map, distinctUntilChanged } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class UIQuery extends Query<UIState> {
  constructor(protected store: UIStore) {
    super(store)
  }

  isLoggedIn() {
    return this.select('loggedIn').pipe(distinctUntilChanged())
  }

  getCurrentUser() {
    return this.select('currentUser')
  }

  getSelectedOrg() {
    return this.select('selectedOrg')
  }

  getSelectedPod() {
    return this.select('selectedPod')
  }

  getNavPermissions() {
    return this.select('navPermissions')
  }

  getSelectedPodValue() {
    return this.getValue().selectedPod
  }

  getNavPermissionsValue() {
    return this.getValue().navPermissions
  }

  getSelectedOrgValue() {
    return this.getValue().selectedOrg
  }

  getUserPermissions() {
    return this.select('userPermissions')
  }

  getUserPermissionsValue() {
    return this.getValue().userPermissions
  }

  getSelectedEntitlement() {
    return this.select('selectedEntitlement')
  }

  getLoadingPods() {
    return this.select('loadingPods')
  }
}
