import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from '../../../classes/modal-overlay-ref'
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from '../../../services/component-modal.service'

export interface WarningPromptAction {
  name: 'Cancel' | 'Ok'
}

export interface WarningPromptPayload {
  message: string
  subMessage?: string
  leftActionLabel?: 'Cancel' | string
  rightActionLabel?: 'Ok' | string
  overrideActionContainerStyle?: object
  warningClass?: string
}

@Component({
  selector: 'bebop-warning-prompt',
  templateUrl: './warning-prompt.component.html',
  styleUrls: ['./warning-prompt.component.scss'],
  providers: [SafeHtmlPipe]
})
export class WarningPromptComponent implements OnInit, OnExternalModalClose {
  constructor(
    public ref: ModalOverlayRef<WarningPromptComponent, WarningPromptAction>,
    @Inject(BEBOP_MODAL_DATA) public data: WarningPromptPayload
  ) {}

  message = ''
  subMessage = ''
  leftAction = 'Cancel'
  rightAction = 'Ok'
  overrideActionContainerStyle: object

  ngOnInit(): void {
    this.message = this.data.message ?? ''
    this.subMessage = this.data.subMessage ?? ''
    this.leftAction = this.data.leftActionLabel ?? 'Cancel'
    this.rightAction = this.data.rightActionLabel ?? 'Ok'
    this.overrideActionContainerStyle = this.data.overrideActionContainerStyle ?? {}
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  ok() {
    this.ref.send({ name: 'Ok' })
    this.ref.close()
  }
}
