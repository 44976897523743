<div class="hero">
  <div class="part-of-content animate-up-1" *ngIf="fromBebop">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 20"
      id="start"
      width="24"
      height="24"
    >
      <path
        d="M 9.714 17.778 L 6.857 11.667 L 0.571 8.889 L 6.857 6.111 L 9.714 0 L 12.571 6.111 L 18.857 8.889 L 12.571 11.667 Z M 18.857 20 L 17.429 16.944 L 14.286 15.556 L 17.429 14.167 L 18.857 11.111 L 20.286 14.167 L 23.429 15.556 L 20.286 16.944 Z"
        fill="rgb(0,0,0)"
      />
    </svg>
    BeBop is now part of
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 578.68 104"
      width="86"
      height="16"
    >
      <defs>
        <linearGradient
          id="a"
          x1="-28.65"
          x2="164.22"
          y1="78.67"
          y2="-16.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#39ff14" />
          <stop offset=".21" stop-color="#0c79f9" />
          <stop offset=".77" stop-color="#ff007f" />
          <stop offset="1" stop-color="#f5df4d" />
        </linearGradient>
        <linearGradient
          xlink:href="#a"
          id="c"
          x1="-39.39"
          x2="153.48"
          y1="56.88"
          y2="-38.22"
        />
        <linearGradient
          xlink:href="#a"
          id="d"
          x1="-11.62"
          x2="181.25"
          y1="101.62"
          y2="6.51"
        />
        <linearGradient
          xlink:href="#a"
          id="e"
          x1="-3.78"
          x2="189.09"
          y1="129.1"
          y2="33.99"
        />
        <linearGradient
          xlink:href="#a"
          id="f"
          x1="6.96"
          x2="199.83"
          y1="150.88"
          y2="55.78"
        />
        <linearGradient
          id="b"
          x1="128.05"
          x2="98.83"
          y1="91.05"
          y2="61.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#0f1039" stop-opacity=".8" />
          <stop offset="1" stop-color="#0f1039" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          xlink:href="#b"
          id="g"
          x1="48.9"
          x2="79.34"
          y1="11.89"
          y2="42.33"
        />
      </defs>
      <g stroke-width="0">
        <path
          fill="#0f1039"
          d="M225.53 73.09c6.63 3.13 12.64 4.38 19.91 4.38 8.14 0 17.53-1.75 25.29-4.76v15.65c-7.89 3-16.9 4.63-27.42 4.63s-21.65-2.49-32.77-8.37a4.757 4.757 0 0 1-2.53-4.19V23.57c0-1.76.98-3.37 2.53-4.19 11.13-5.88 21.95-8.37 32.77-8.37s19.53 1.75 27.42 4.76v15.65c-7.76-3-17.15-4.88-25.29-4.88-7.26 0-13.27 1.25-19.91 4.38v42.19zm73.35-5.5v23.54h-17.15V12.88c8.51-1.13 17.15-1.88 28.04-1.88 13.79 0 27.05 2.79 37.58 8.37 1.54.81 2.48 2.43 2.48 4.17v33.04c0 1.73-.94 3.34-2.47 4.16-3.98 2.14-8.08 3.67-12.43 4.6l16.27 25.79h-19.03L317.9 67.84c-1.5.25-3 .25-4.51.25-5.26 0-10.02-.13-14.52-.5zm0-13.9c4.13.38 8.76.63 14.15.63.75 0 1.63 0 2.5-.13 6.89-.13 12.39-1.63 17.53-4.51V31.15c-5.13-2.88-10.64-4.38-17.53-4.63h-2.5c-5.38 0-10.14.25-14.15.5v26.67zM425.7 91.12h-62.34V12.88h62.34v14.65h-45.19v17.53h33.9v13.65h-33.9v17.78h45.19v14.65zm73.48 0h-62.34V12.88h62.34v14.65h-45.19v17.53h33.9v13.65h-33.9v17.78h45.19v14.65zM543.62 93c-11.63 0-22.51-2.38-32.73-8.34a4.705 4.705 0 0 1-2.33-4.08V60.83c0-1.77.99-3.41 2.57-4.21 3.97-2.02 8.8-3.56 14.21-4.68-4.71-1.1-9.1-2.69-12.66-4.56-1.54-.81-2.49-2.43-2.49-4.17V23.4c0-1.67.88-3.23 2.32-4.08 9.71-5.75 19.98-8.32 31.1-8.32s21.4 2.58 31.1 8.32c1.44.85 2.32 2.41 2.32 4.08v19.81c0 1.74-.95 3.36-2.49 4.17-3.56 1.87-7.95 3.46-12.66 4.56 5.31 1.12 10.2 2.66 14.2 4.68 1.59.8 2.58 2.44 2.58 4.22v19.74c0 1.67-.88 3.24-2.33 4.08-10.22 5.96-21.1 8.34-32.73 8.34zm0-34.43c-6.38 0-12.52 1.5-17.65 4.51v11.77c5.51 3 11.27 4.26 17.65 4.26s12.14-1.25 17.65-4.26V63.08c-5.13-3-11.39-4.51-17.65-4.51zm16.65-29.17c-4.88-3.13-10.52-4.51-16.65-4.51s-11.77 1.38-16.65 4.51v11.52c4.76 2.88 10.64 4.51 16.65 4.51s11.89-1.63 16.65-4.51V29.4z"
        />
        <path
          fill="url(#a)"
          d="M89 36.28c-.33.33-.66.66-1 1.01l-.4.4c-3.11 3.11-6.44 6.43-9.87 9.87-.19.18-.37.37-.55.55l-1.01 1.01-.52.52c-.78.77-1.57 1.57-2.36 2.36L41.68 20.38c5.13-.57 10.37-.62 15.53-.14.76.07 1.52.15 2.27.24.5.06.99.13 1.48.2a72.816 72.816 0 0 1 7.05 1.35c.59.15 1.16.3 1.73.45 1.13.31 2.25.65 3.35 1 1.7.57 3.36 1.19 4.99 1.88L89 36.29z"
        />
        <path
          fill="url(#c)"
          d="M78.07 25.34a80.1 80.1 0 0 0-4.99-1.87c-1.1-.36-2.22-.69-3.35-1-.57-.15-1.14-.3-1.73-.45-.57-.14-1.13-.26-1.71-.39a69.87 69.87 0 0 0-5.2-.94c-.53-.08-1.07-.15-1.62-.22-.75-.09-1.51-.17-2.27-.24-5.17-.48-10.41-.43-15.53.14-6.8.75-13.42 2.41-19.45 4.97v15.53H0V11.06C15.1 3.52 32.8-.03 50.32 0c1.57 0 3.07.65 4.18 1.76l23.57 23.58z"
        />
        <path
          fill="url(#d)"
          d="M136.3 20.39c7.12.77 13.72 2.5 19.48 4.97v15.52H178V11.06C162.9 3.52 145.2-.03 127.68 0c-1.57 0-3.07.65-4.18 1.76L41.7 83.61c-7.12-.77-13.72-2.5-19.48-4.97V63.12H0v29.82c15.1 7.55 32.81 11.09 50.32 11.06 1.57 0 3.07-.65 4.18-1.76l81.8-81.85z"
        />
        <path
          fill="url(#e)"
          d="M136.33 83.62c-5.14.57-10.37.62-15.54.14-.76-.07-1.52-.15-2.26-.24-.5-.06-.99-.13-1.49-.2a73.522 73.522 0 0 1-7.04-1.35c-.6-.15-1.17-.3-1.73-.45-1.14-.31-2.25-.65-3.36-1a71.05 71.05 0 0 1-4.99-1.88L89 67.71c.36-.37.81-.82 1.33-1.33.42-.43.87-.88 1.37-1.37l1.38-1.38.81-.81c3.44-3.44 7.08-7.09 10.81-10.83l31.62 31.62z"
        />
        <path
          fill="url(#f)"
          d="M99.93 78.66a80.1 80.1 0 0 0 4.99 1.87c1.1.36 2.22.69 3.35 1 .57.15 1.14.3 1.73.45.57.14 1.13.26 1.71.39 1.72.38 3.45.69 5.2.94.53.08 1.07.15 1.62.22.75.09 1.51.17 2.27.24 5.17.48 10.41.43 15.53-.14 6.8-.75 13.42-2.41 19.45-4.97V63.13H178v29.82c-15.09 7.55-32.79 11.09-50.31 11.06-1.57 0-3.07-.65-4.18-1.76L99.94 78.67z"
        />
        <path
          fill="url(#b)"
          d="M136.33 83.62c-5.14.57-10.37.62-15.54.14-.76-.07-1.52-.15-2.26-.24-.5-.06-.99-.13-1.49-.2a73.522 73.522 0 0 1-7.04-1.35c-.6-.15-1.17-.3-1.73-.45-1.14-.31-2.25-.65-3.36-1a71.05 71.05 0 0 1-4.99-1.88L89 67.71c.36-.37.81-.82 1.33-1.33.42-.43.87-.88 1.37-1.37l1.38-1.38.81-.81c3.44-3.44 7.08-7.09 10.81-10.83l31.62 31.62z"
        />
        <path
          fill="url(#g)"
          d="M89 36.28c-.33.33-.66.66-1 1.01l-.4.4c-3.11 3.11-6.44 6.43-9.87 9.87-.19.18-.37.37-.55.55l-1.01 1.01-.52.52c-.78.77-1.57 1.57-2.36 2.36L41.68 20.38c5.13-.57 10.37-.62 15.53-.14.76.07 1.52.15 2.27.24.5.06.99.13 1.48.2a72.816 72.816 0 0 1 7.05 1.35c.59.15 1.16.3 1.73.45 1.13.31 2.25.65 3.35 1 1.7.57 3.36 1.19 4.99 1.88L89 36.29z"
        />
      </g>
    </svg>
  </div>
  <div class="desc">
    <h1 class="animate-up-1">Creativity. Unleashed.</h1>
    <span class="animate-up-2"
      >CREE8 is a centralized workspace providing virtual computing and
      collaboration solutions for creatives and creators.</span
    >
  </div>
  <video class="rounded-video animate-fade-in" autoplay muted loop playsinline>
    <source src="assets/video/CREE8-Homepage-Video.webm" type="video/webm" />
    Your browser does not support the video tag.
  </video>
</div>
