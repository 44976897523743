import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { ResponseError } from '../models/response.model'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class PodsService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getPodByOrganization(): Observable<any | ResponseError> {
    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/pods/by/organization`).pipe(
      catchError((error: any) => {
        console.error('On getPodByOrganization', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateProfile(id: string, data: any) {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/pods/${id}`, data).pipe(
      catchError((error: any) => {
        console.error('On updateProfile', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
