import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'

import { ProjectsLinksState, ProjectsLinksStore } from './projects-links.store'

@Injectable({ providedIn: 'root' })
export class ProjectsLinksQuery extends Query<ProjectsLinksState> {
  constructor(protected store: ProjectsLinksStore) {
    super(store)
  }

  getListLinksProjects() {
    return this.select('listProjectsLinks')
  }

  getQueryParamsListValue() {
    return this.select('queryParamsList')
  }

  getLoadingStatus() {
    return this.select('loading')
  }
}
