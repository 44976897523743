import { animate, group, keyframes, query, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { LinkAction } from 'src/app/common/enums'
import { BrowserUtilsService } from 'src/app/common/services/browser-utils.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { UtilsService } from 'src/app/common/services/utils.service'
import { PageSizes, Pagination } from 'src/app/common/types'
import { CastBroadcast, CastInbound, CastOperator, Organization, Pod } from 'src/app/models/bebop.model'
import { CastOperatorsResponse } from 'src/app/models/response.model'
import { GridSort, NdiStatus, StreamQuality, UiBroadcast, UiBroadcastStatus } from 'src/app/models/ui.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { ElectronService } from 'src/app/services/electron.service'
import { UserSettingsService } from 'src/app/services/user-settings.service'
import { UserService } from 'src/app/services/user.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { WorkstationQuery } from 'src/app/store/workstation/workstation.query'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'
import { FETCH_DELAY_SINCE_LAST_INTERACTION, WORKSTATION_POLL_INTERVAL } from '../workstation/classes/workstation-types'
import { InfoBroadcastAction, InfoBroadcastComponent } from './modals/info-broadcast/info-broadcast.component'
import {
  BestPracticeBroadcastAction,
  BestPracticeBroadcastComponent,
} from './modals/best-practice/best-practice-broadcast.component'
import { SafeHtmlPipe } from 'src/app/common/pipes/safe-html.pipe'

interface GridSortFields {
  runtime: GridSort
}
@Component({
  selector: 'bebop-broadcasts',
  templateUrl: './broadcasts.component.html',
  styleUrls: ['./broadcasts.component.scss'],
  providers: [SafeHtmlPipe],
  animations: [
    trigger('switchAnim', [
      transition('HQ <=> SQ', [
        style({
          position: 'relative',
        }),

        group([
          query(
            '.active',
            [
              style({
                'z-index': 10,
              }),
            ],
            { optional: true }
          ),

          query(
            '.high-def',
            [
              style({
                position: 'relative',
                top: 0,
                left: 40 + 8,
                opacity: 0,
              }),
              animate(
                '500ms ease-out',
                keyframes([style({ left: 24, offset: 0.5, opacity: 0.5 }), style({ left: 0, offset: 1, opacity: 1 })])
              ),
            ],
            { optional: true }
          ),
          query(
            '.std-def',
            [
              style({
                position: 'relative',
                top: 0,
                left: -40 - 8,
                opacity: 0,
              }),
              animate(
                '500ms ease-out',
                keyframes([style({ left: -24, offset: 0.5, opacity: 0.5 }), style({ left: 0, offset: 1, opacity: 1 })])
              ),
            ],
            { optional: true }
          ),
        ]),
      ]),
    ]),
  ],
})
export class BroadcastsComponent implements OnInit, OnDestroy {
  broadcasts: UiBroadcast[] = []
  sort: GridSortFields = {
    runtime: GridSort.Down,
  }

  sortFieldProxy = {
    runtime: 'started',
  }

  intervalTimeout = -1

  pageOptions: Pagination = {
    page: 1,
    total: 0,
    size: PageSizes[2],
  }

  get total() {
    return Math.max(this.pageOptions.total, this.broadcasts?.length || 0)
  }

  showRef: ModalOverlayRef<InfoBroadcastComponent, InfoBroadcastAction>

  settings$: Subscription
  ndi$: Subscription
  settings: any

  ndi: NdiStatus

  lastUserInteraction = 0

  pod: Pod
  pod$: Subscription

  organization: Organization

  constructor(
    private util: BebopClientUtilsService,
    private modalService: ComponentModalService,
    private userService: UserService,
    private uiQuery: UIQuery,
    private wquery: WorkstationQuery,
    private wservice: WorkstationService,
    private toastService: ToastService,
    private browserUtil: BrowserUtilsService,
    private electronService: ElectronService,
    private utilService: UtilsService,
    private userSettings: UserSettingsService
  ) {
    this.sortFields = this.sortFields.bind(this)
  }

  ngOnInit(): void {
    this.pod = this.uiQuery.getSelectedPodValue()
    this.organization = this.uiQuery.getSelectedOrgValue()
    this.restartTimer()

    this.pod$ = this.uiQuery.getSelectedPod().subscribe((p) => (this.pod = p))
    this.settings$ = this.userSettings.getUserSettings().subscribe((s) => (this.settings = s))

    this.ndi$ = this.wquery.getNdiStatus().subscribe((n) => {
      this.ndi = n
    })
  }

  restartTimer() {
    window.clearInterval(this.intervalTimeout)

    this.broadcasts = []

    this.pollCastStations()
    this.intervalTimeout = window.setInterval(() => {
      if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) return
      this.pollCastStations()
    }, WORKSTATION_POLL_INTERVAL)
  }

  _pollingCastStations = false
  pollCastStations() {
    if (this._pollingCastStations || !this.pod) return

    this._pollingCastStations = true
    this.wservice
      .getCastOperators(this.pageOptions, {
        // cast operator doesnot have pod relationship
        podId: this.pod?._id,
        organizationId: this.organization?._id,
      })
      .subscribe((s: CastOperatorsResponse) => {
        this._pollingCastStations = false
        if (s?.error) {
          // no toast as its a poll
          console.error('Unable to fetch cast operators list')
          return
        }

        if (Date.now() - this.lastUserInteraction <= FETCH_DELAY_SINCE_LAST_INTERACTION) return

        this.pageOptions.total = s?.total ?? 0
        this.updateBroadcasts(s?.data)
        this.checkBroadcast()
      })
  }

  get broadcastStatusEnum(): typeof UiBroadcastStatus {
    return UiBroadcastStatus
  }

  ngOnDestroy(): void {
    this.showRef?.close()
    ;[this.ndi$, this.settings$, this.pod$].forEach((x) => x?.unsubscribe())
    window.clearInterval(this.intervalTimeout)
  }

  openHelpCenter() {
    this.util.openExternalLink(LinkAction.SUPPORT_BROADCASTS, { userId: this.userService.id })
  }

  sortBy(e: keyof GridSortFields) {
    let cur = this.sort[e]
    Object.keys(this.sort).forEach((k) => {
      this.sort[k] = GridSort.Default
    })

    if (cur == GridSort.Down) {
      this.sort[e] = GridSort.Up
    } else {
      this.sort[e] = GridSort.Down
    }

    this.sortFields()
  }

  sortFields() {
    let key = Object.keys(this.sort).find((k) => this.sort[k] != GridSort.Default)

    if (!key) return

    let sortBy = this.sort[key]

    let sortKey = this.sortFieldProxy[key] || key

    this.broadcasts?.sort((l, r) => {
      if (l[sortKey] == r[sortKey]) return 0

      if (sortBy == GridSort.Down) {
        return l[sortKey] > r[sortKey] ? -1 : 1
      }

      return l[sortKey] > r[sortKey] ? 1 : -1
    })
  }

  showInfo(ev: Event) {
    this.showRef = this.modalService.open<InfoBroadcastComponent, InfoBroadcastAction>(
      InfoBroadcastComponent,
      {
        hasBackdrop: false,
        data: {},
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: false,
        position: {
          top: '86px',
          right: '0px',
        },
      }
    )
  }

  showBestPracticesModal(ev: Event, cb: () => void) {
    if (this.settings?.doNotShowAgainCastStation) return cb?.()

    let ref = this.modalService.open<BestPracticeBroadcastComponent, BestPracticeBroadcastAction>(
      BestPracticeBroadcastComponent,
      {
        hasBackdrop: true,
        animateFrom: ev.target as Element,
      },
      {}
    )

    let subs$ = ref.events().subscribe(({ name, doNotShowAgain, linkAction }) => {
      if (name != 'Link') {
        subs$.unsubscribe()
      }

      if (doNotShowAgain) {
        this.settings.doNotShowAgainCastStation = true
        this.userSettings.saveSettings('UserSettings', this.userService.id, this.settings, () => {})
      }

      if (name == 'Link') {
        if (linkAction == 'Best Practices') {
          this.util.openExternalLink(LinkAction.CAST_BEST_PRACTICES, { userId: this.userService.id })
          return
        }

        return
      }

      if (name == 'Continue') {
        cb?.()
      }
    })
  }

  stdQuality(ev: Event, b: UiBroadcast) {
    if (this.ndi?.castActive) return
    // if (StreamQuality.SQ == b?.quality) return

    this.afterBestPracticeModal(ev, b, (ub: UiBroadcast) => {
      b.quality = StreamQuality.SQ
      this.updateQuality(b)
    })
  }

  afterBestPracticeModal(ev: Event, b: UiBroadcast, cb: (ub: UiBroadcast) => void) {
    this.showBestPracticesModal(ev, () => {
      this.lastUserInteraction = Date.now()
      cb?.(
        this.broadcasts.find(
          (x) => x?.source?.broadcastStation?.workstation?._id == b.source?.broadcastStation?.workstation?._id
        ) || b
      )
    })
  }

  highQuality(ev: Event, b: UiBroadcast) {
    if (this.ndi?.castActive) return
    // if (StreamQuality.HQ == b?.quality) return
    this.afterBestPracticeModal(ev, b, (ub: UiBroadcast) => {
      b.quality = StreamQuality.HQ
      this.updateQuality(b)
    })
  }

  copy(b: UiBroadcast) {
    this.electronService.copyToClipboard(b.streamSourceFullLink)

    this.toastService.show({
      type: 'info',
      text: 'Copied to clipboard',
    })
  }

  openExternal(b: UiBroadcast) {
    this.electronService
      .openExternal(b.streamSourceFullLink)
      .catch((e) => this.toastService.show({ text: `Launch failed: ${e.message}`, type: 'error' }))
  }

  updateQuality(b: UiBroadcast) {
    this.wservice.ndiConnectByCastOperator(b.source, b.quality)
  }

  updateBroadcasts(cops: CastOperator[]) {
    if (!cops?.length) {
      this.broadcasts = []
      this.pageOptions.total = 0
      this.disconnectCast()
      return
    }

    this.broadcasts = cops
      // filter is right ?
      ?.filter((co) => co.operatorType == 'BEBOP_BROADCAST')
      ?.map((co) => {
        let { broadcast, castStation, operatorType, broadcastStation, castInbound } = co

        let started = broadcast?.started ? new Date(broadcast?.started) : null
        let ended = broadcast?.ended ? new Date(broadcast?.ended) : null

        started = this.utilService.isDate(started) ? started : null
        ended = this.utilService.isDate(ended) ? ended : null

        let runtime =
          broadcast?.isLive && started
            ? this.util.secondsToStr((Date.now() - started?.getTime()) / 1000)
            : ended
            ? this.browserUtil.getRelativeTimeString(ended)
            : ''

        let streamSource = this.getStreamSource(co) ?? ''

        let ub: UiBroadcast = {
          status: broadcast?.isLive ? UiBroadcastStatus.Live : UiBroadcastStatus.Pending,
          streamSource,
          username: broadcastStation?.loggedInUser?.name ?? '',
          runtime: broadcast?.isLive ? `Running ${runtime}` : `Started ${runtime}`,
          clients: {
            active: co.activeClientsCount,
            total: co.clientsCount || (co.connectedClients?.length ?? 0),
          },
          streamSourceFullLink: broadcast?.liveRoomLinkUrl,
          allowedQuality: this.getAllowedQuality(co),
          quality: this.getQuality(broadcast),
          started: started ?? new Date(0),
          source: co,
          activeNdi:
            broadcastStation?.workstation &&
            this.ndi?.workstation &&
            broadcastStation?.workstation?._id == this.ndi?.workstation?._id,
        }

        return ub
      })
    this.sortFields()
  }

  checkBroadcast() {
    let cco = this.ndi?.castOperator
    if (!cco) return
    this.broadcasts?.forEach((b) => {
      let co = b?.source
      if (co?._id == cco?._id) {
        if (!co?.broadcast?.isLive) {
          this.wservice.stopNdiCasting()
          this.toastService.show({
            type: 'error',
            text: 'Broadcast Disconnected…',
          })
        }
      }
    })
  }

  disconnectCast() {
    this.wservice.stopNdiCasting()
    // this.wservice.stopNdiStreamReceiver()
  }

  getAllowedQuality(b: CastOperator) {
    if (!this.ndi?.castActive || this.ndi?.castOperator?._id != b?._id) return this.getQuality(b?.broadcast)

    return this.ndi?.quality
  }

  getQuality(b: CastBroadcast) {
    switch (b?.preferences?.quality) {
      case 'lowQ':
        return StreamQuality.SQ
      case 'highQ':
        return null
      default:
        // when not set
        return StreamQuality.SQ
    }
  }

  getStreamSource(co: CastOperator) {
    switch (co.operatorType) {
      case 'BEBOP_BROADCAST':
        return co?.broadcast?.selectedSource?.name ?? ''
      case 'INBOUND':
        return 'Earth Broadcast'
      case 'MEDIASERVER':
        return 'Earth MS Broadcast'
      case 'SWITCHER':
      default:
        return ''
    }
  }
}
