import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subject } from 'rxjs'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'

@Component({
  selector: 'cree8-team',
  styleUrl: './settings.component.scss',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject()

  inviteMemberModal = { active: false }

  tabs: ITabs[] = [
    { active: false, label: 'Subscription', value: 'subscription' },
    { active: false, label: 'Organization', value: 'organization' },
    { active: false, label: 'Profile', value: 'profile' },
    { active: false, label: 'App', value: 'app-settings' },
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Get the current route and set the active tab based on it
    const currentTabValue = this.route.snapshot.firstChild?.url[0]?.path || 'subscription' // default to 'members'
    this.setActiveTab(currentTabValue)
  }

  onTabClick(value: string) {
    this.router.navigate([value], { relativeTo: this.route })
    this.setActiveTab(value)
  }

  setActiveTab(value: string) {
    this.tabs.forEach((tab) => {
      tab.active = tab.value === value
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }
}
