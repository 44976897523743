<div class="team scrollbar">

  <h1 class="headline1">Team</h1>

  <div class="nav">
    <cree8-tabs [tabs]="tabs" (tabClick)="onTabClick($event)"></cree8-tabs>

    <actions-list (refresh)="onRefresh()" (searchValue)="onSearchValue($event)" (sorting)="onSorting($event)"
      [allowedActions]="allowedActions" (created)="onInviteMember()" [labelCreated]="'INVITE'"
      [listSorting]="listSortingProjects" [activedSort]="activeSorting"></actions-list>
  </div>

  <div class="team-container">
    <router-outlet></router-outlet>
  </div>

  <user-invite *ngIf="inviteMemberModal.active" [(active)]="inviteMemberModal.active"
    (confirmed)="onInviteConfirmed()"></user-invite>

</div>
