import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { NotificationService } from './notification.service'
import { NotificationData } from './notification.types'

@Component({
  selector: 'bebop-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {

  showCloseAll = false

  active = false
  get hasActive() {
    return this.active
  }

  get hasReminder() {
    return this.notifications?.length
  }

  notify$: Subscription
  showNotify$: Subscription
  notifications: NotificationData[] = []

  constructor(private nservice: NotificationService) {}

  ngOnInit(): void {
    this.notify$ = this.nservice.getNotifications((m) => {
      this.notifications = m
      this.showCloseAll = m?.some(x => x.closeable)
    })

    this.showNotify$ = this.nservice.showOrHideNotification((v) => {
      if (!v) return
      // trigger open, no close
      this.active = true
    })
  }

  ngOnDestroy(): void {
    ;[this.notify$, this.showNotify$].forEach((e) => e?.unsubscribe())
  }

  toggleDropDown() {
    this.active = !this.active
  }

  onClickOutside() {
    this.active = false
  }

  async closeAll(e: Event) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
    // this.nservice.closeAll()
    // loop one by one in async fashion 
    // which let the user to close prompting notification one by one
    
    // prevent loop counter invalidation
    let notifications = [...this.notifications ]
    for await(const n of notifications) {
      if (n.closeable) await this._onClose(n)
    }

  }

  close(n: NotificationData) {
    this.nservice.removeNotification(n)
  }

  onSelect(n: NotificationData, e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    if (n.onSelect) {
      let res = n.onSelect?.()
      if (!res) return
      res?.then(x => {
        if (x) {
          this.close(n)
          // close the notification window
          // its for cases where the navigation event happens
          // we can't deduct nav happend from here as it could be
          // internal tab navigation
          this.onClickOutside()
        }
      })
    }

  }

  async _onClose(n: NotificationData) {
    if (n.onClose) {
      // let the app decide 
      let res = n.onClose?.()
      if (!res) return
      return res?.then(x => x && this.close(n))
    } else if (n.closeable) {
      return this.close(n)
    }
  }

  onClose(n: NotificationData, e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
    this._onClose(n)
  }
}
