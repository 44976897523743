import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@Component({
  selector: 'bebop-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  providers: [SafeHtmlPipe]
})
export class BannerComponent implements OnInit {
  @Input('message') message:string
  @Input('banner-class') bannerClass: string
  @Output('on-close') onClose = new EventEmitter<void>()

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.onClose.emit()
  }

}

