<ng-container *ngIf="file">
  <div class="projects-container" *ngIf="file">
    <div class="thumbnail-projects" [class.no-flex]="file.type === 'image'&& file.media?.thumbsCount > 0"
      (click)="onAction({ action: 'detail', data: file })">

      <div class="thumbnail-image" *ngIf="file.type === 'image' && file.media?.thumbsCount > 0; else otherThumbnail">
        <img *ngIf="file.media?.thumbnail && file.media?.thumbsCount !== 1"
          [src]="'data:image/png;base64,' + file.media.thumbnails[1].img" width="100%" height="auto" />

        <img *ngIf="file.media?.thumbnail && file.media?.thumbsCount === 1"
          [src]="'data:image/png;base64,' + file.media.thumbnail" width="100%" height="auto" />
      </div>
      <ng-template #otherThumbnail>
        <div class="thumbnail-icon" [class.active]="file.type === 'video'">
          @if (file.type === 'folder') {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Folder_Single"></use>
          </svg>
          } @else if (file.type === 'video') {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Play"></use>
          </svg>
          } @else if (file.type === 'audio') {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Audio"></use>
          </svg>
          } @else if (file.type === 'image'){
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
          </svg>
          }
          @else {
          <svg class="ico" viewBox="0 0 24 24">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
          </svg>
          }
        </div>
      </ng-template>

    </div>
    <div class="project-description">
      <div class="project-info">
        <span class="body2 file-name" (click)="onAction({ action: 'detail', data: file })">{{ file.name }}</span>
        <span class="desc body3">Size: {{ mappingSize(file).size }}</span>
        <span class="desc body3">Storage usage: {{ mappingSize(file).storage }}</span>
      </div>
      <cree8-card-actions-list (closeDropdown)="onCloseDropdown(file)" (openDropdown)="onOpenDropdown(file)"
        [data]="file" [actionsList]="actionsList()" [type]="file.type === 'folder' ? 'folder' : 'file'"
        [active]="file.ui?.dropDownActive" (action)="onAction($event)"></cree8-card-actions-list>
    </div>
  </div>
</ng-container>
