import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'

import { ProjectsState, ProjectsStore } from './projects.store'

@Injectable({ providedIn: 'root' })
export class ProjectsQuery extends Query<ProjectsState> {
  constructor(protected store: ProjectsStore) {
    super(store)
  }

  getListProjects() {
    return this.select('listProjects')
  }

  getStatusHasMore() {
    return this.select('hasMore')
  }

  getSelectedProject() {
    return this.select('selectedProject')
  }

  getQueryParamsList() {
    return this.select('queryParamsList')
  }

  getMembersProps() {
    return this.select('membersProps')
  }

  getFilesProps() {
    return this.select('filesProps')
  }
}
