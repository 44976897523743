import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Option {
  title: string;
  description: string;
  value: string;
}

@Component({
  selector: 'cree8-option',
  templateUrl: './option.component.html',
  styleUrl: './option.component.scss'
})
export class OptionComponent {
  @Input() options: Option[] = [];
  @Input() selectedOption: string = '';
  @Output() selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

  onOptionSelected(value: string) {
    this.selectedOptionChange.emit(value);
  }
}
