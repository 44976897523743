import { Component, EventEmitter, Input, Output } from '@angular/core'

export interface IConfirmationModal {
  title: string
  description: string
  type: 'danger' | 'success' | 'warning' | 'info' | 'primary'
  loading?: boolean
}

@Component({
  selector: 'confirmation-modal',
  styleUrl: './confirmation-modal.component.scss',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  @Input() data: IConfirmationModal = {} as IConfirmationModal
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() delete: EventEmitter<boolean> = new EventEmitter<boolean>()

  onCloseModal() {
    if (this.data?.loading) return
    this.closeModal.emit(true)
  }

  onDelete() {
    if (this.data?.loading) return
    this.delete.emit(true)
  }
}
