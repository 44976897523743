<div class="link-expiration">
  <span>Link Expiry:</span>
  <cree8-tabs
    [tabs]="expirationTabs"
    (tabClick)="onLinkTabClick($event)"
    [activeTab]="activeTab"
    type="outlined"
    size="small"
    width="full"
  ></cree8-tabs>

  <!-- CHANGE NEW COMPONENT DATE & TIME-->
  <ng-container *ngIf="activeTab === 'set-time'">
    <bebop-input-date
      class="input-date-expiry"
      [options]="dateOptions"
      [date]="_date"
      (onChange)="onChangeEndDate($event)"
    >
    </bebop-input-date>
    <bebop-input-time
      class="input-time-expiry"
      [options]="timeOptions"
      [date]="_time"
      (onChange)="onChangeEndTime($event)"
    >
    </bebop-input-time>
  </ng-container>
</div>
