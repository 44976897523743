<div class="cree8-card-member" (click)="onCopyEmail()">
  <div class="image">
    <img class="img-circle" [src]="image" alt="{{ name }}">
    <span class="status" [ngClass]="status"></span>
  </div>
  <div class="info">
    <div class="name body3">
      {{ name }}
    </div>
    <div class="email captions">
      {{email}}
    </div>
  </div>
  <div class="actions">
    <cree8-btn-icon (click)="$event.stopPropagation(); onCopyEmail()" size="small">
      <svg class="ico" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Copy"></use>
      </svg>
    </cree8-btn-icon>
  </div>
</div>
