import { Component, OnDestroy, OnInit } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import { Router } from '@angular/router'

import { Subscription } from 'rxjs'
import { LinkAction } from 'src/app/common/enums'
import { User } from 'src/app/models/bebop.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { MainService } from 'src/app/services/main.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { environment } from 'src/environments/environment'

export type ProfileDropdownAction =
  | 'Help'
  | 'Settings'
  | 'Log Out'
  | 'Refresh'
  | 'Support'
  | 'Profile'
  | 'DownloadClient'
  | 'OrgSelection'
  | 'Organization'
  | 'Support'

@Component({
  selector: 'bebop-profile',
  styleUrls: ['./profile.component.scss'],
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit, OnDestroy {
  toggleOpen = false
  user: User
  user$: Subscription
  picture: string | SafeUrl = null
  environment = environment
  orgSelectionModal = false

  constructor(
    private router: Router,
    private sessionQuery: SessionQuery,
    private config: BebopConfigService,
    private mainService: MainService,
    private sessionService: SessionService,
    private util: BebopClientUtilsService
  ) {}

  ngOnInit(): void {
    this.orgSelectionModal = false
    this.user$ = this.sessionQuery.getUser().subscribe((user) => {
      this.user = user
      this.picture = this.mainService.getUserImage()
    })
  }

  ngOnDestroy() {
    this.user$.unsubscribe()
  }

  onToggleProfile() {
    this.toggleOpen = !this.toggleOpen
  }

  onCloseProfileDropdown() {
    this.toggleOpen = false
  }

  openHelpCenter() {
    this.util.openExternalLink(LinkAction.SUPPORT_MAIN, { userId: this.user?._id })
  }

  goToSupportPage() {
    this.util.openExternalLink(LinkAction.SUPPORT_PORTAL)
  }

  async onClickAction(action: ProfileDropdownAction, id?: string) {
    this.toggleOpen = false
    // console.log(action)

    switch (action) {
      case 'Profile':
        this.router.navigate([`app/profile/${id}`], {})
        break
      case 'Organization':
        this.router.navigate(['app/org-profile'], {})
        break
      case 'Settings':
        this.router.navigate(['app/settings'], {})
        break
      case 'OrgSelection':
        this.orgSelectionModal = true
        break
      case 'DownloadClient':
        this.util.openExternalLink(LinkAction.BBP_CLIENT_DOWNLOAD)
        break
      case 'Log Out':
        {
          const ok = await this.mainService.promptBeforeTeardown()
          if (!ok) return
          this.sessionService.triggerLogout()
        }
        break
      case 'Support':
        this.goToSupportPage()
        break
      case 'Help':
        this.openHelpCenter()
        break
      case 'Refresh':
        // TODO refresh
        this.mainService.refreshSession()
        break
      default:
        break
    }
  }

  onSelectOrganization(item: any) {
    this.orgSelectionModal = false
  }
}
