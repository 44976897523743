import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { Organization } from '../models/bebop.model'
import { IOrganization, IOrgSubscriptionDetails, IOrgUsage } from '../models/organization.model'
import { ResponseError } from '../models/response.model'
import { UIQuery } from '../store/ui/ui.query'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  org: Organization

  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient,
    private uiQuery: UIQuery
  ) {
    this.uiQuery.getSelectedOrg().subscribe((org: Organization) => {
      this.org = org
    })
  }

  usersOnlineCount(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }

    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/${this.org._id}/users-online-count`, {
        params: httpParams,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On usersOnlineCount', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getOrgUsage(): Observable<IOrgUsage | ResponseError> {
    return this.http.get<IOrgUsage>(`${this.bebopConfig.apiUrl}/api/v1/organizations/usage`).pipe(
      catchError((error: any) => {
        console.error('On getOrgUsage', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOrgEntitlementCapacity(): Observable<any> {
    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/${this.org._id}/entitlement_capacity`)
      .pipe(
        catchError((error: any) => {
          console.error('On getOrgEntitlementCapacity', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getOrgEntitlementsUsers(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }

    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/${this.org._id}/entitlement_users`, {
        params: httpParams,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On getOrgEntitlementCapacity', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  updateOrgEntitlementUsers(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/${this.org._id}/entitlement_users`, data)
      .pipe(
        catchError((error: any) => {
          console.error('On deleteUserFromProject', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  updateOrg(data: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/${this.org._id}`, data).pipe(
      catchError((error: any) => {
        console.error('On updateOrg', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOrg(orgId: string): Observable<IOrganization | ResponseError> {
    return this.http.get<{ data: IOrganization }>(`${this.bebopConfig.apiUrl}/api/v1/organizations/${orgId}`).pipe(
      map((response: { data: IOrganization }) => response.data),
      catchError((error: any) => {
        console.error('On getOrg', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getWatermarkByOrgId(orgId: string): Observable<any | ResponseError> {
    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/watermark/${orgId}`).pipe(
      catchError((error: any) => {
        console.error('On getWatermarkByOrgId', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateWatermarkByOrgId(
    orgId: string,
    data: {
      watermark: string
      type: string
    }
  ): Observable<any | ResponseError> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/api/v1/organizations/watermark/${orgId}`, data).pipe(
      catchError((error: any) => {
        console.error('On updateWatermarkByOrgId', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  /**
   * Gets a Chargebee portal session URL for the given email
   * @param organization The organization Id
   * @returns Observable containing the portal access URL
   */
  getPortalSession(orgId: string): Observable<string | ResponseError> {
    return this.http
      .post<{ access_url: string }>(`${this.bebopConfig.apiUrl}/api/v1/chargebee/portal-session`, { orgId })
      .pipe(
        map((response) => response.access_url),
        catchError((error: any) => {
          console.error('On getPortalSession', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getOrgSubscriptionDetails(orgId: string): Observable<IOrgSubscriptionDetails | ResponseError> {
    return this.http
      .get<IOrgSubscriptionDetails>(`${this.bebopConfig.apiUrl}/api/v1/chargebee/subscription-details/${orgId}`)
      .pipe(
        map((response: any) => response.data || response),
        catchError((error: any) => {
          console.error('On getOrgSubscriptionDetails', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }
}
