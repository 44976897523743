import { Component, EventEmitter, Input, Output } from '@angular/core'

export interface Cree8Modal {
  title: string
  counting: number
  current: number
  type: 'confirmation' | 'close' | 'logout'
  loading?: boolean
  disableNext?: boolean
  disableCancel?: boolean
  labelConfirm?: string
  labelCancel?: string
  contentOnly?: boolean
}

@Component({
  selector: 'cree8-modal',
  styleUrl: './cree8-modal.component.scss',
  templateUrl: './cree8-modal.component.html',
})
export class Cree8ModalComponent {
  @Input() modal: Cree8Modal = {} as Cree8Modal
  @Output() cancelModal: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() next: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Input('hideOverlay') hideOverlay = false
  @Input('size') size: 'full' | 'large' | 'normal' = 'normal'
  @Input('noPaddingTop') noPaddingTop = false

  onCloseModal() {
    if (this.modal.disableCancel) return
    this.cancelModal.emit(true)
  }

  onNext() {
    if (this.modal.loading) return
    if (this.modal.disableNext) return
    this.next.emit(true)
  }
}
