<div class="bebop-rocket-empty-state">
  <div class="empty-view" *ngIf="show">
    <div class="empty-icon">
      <svg class="icon-upload" viewBox="0 0 160 161" fill="none" *ngIf="type == 'Upload'">
        <circle cx="80" cy="80.5" r="79.5" fill="#2B2B33" stroke="url(#paint0_linear_795_63048)" />
        <mask
          id="mask0_795_63048"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="162"
          height="161"
        >
          <circle
            cx="80.0001"
            cy="80.4999"
            r="79.5"
            transform="rotate(-64 80.0001 80.4999)"
            fill="url(#paint1_linear_795_63048)"
            stroke="url(#paint2_linear_795_63048)"
          />
        </mask>
        <g mask="url(#mask0_795_63048)">
          <path
            d="M106.875 66.1249H108.452C114.139 66.1249 118.75 61.5141 118.75 55.8264V55.8264C118.75 50.149 114.155 45.5425 108.478 45.528L107.5 45.5255C101.981 45.5114 97.5 49.9812 97.5 55.4999V55.4999V54.1056C97.5 38.9718 85.2237 26.7077 70.09 26.7228V26.7228C54.9775 26.7378 42.7344 38.9931 42.7344 54.1056V67.0217V64.9378C42.7344 61.0761 39.6039 57.9456 35.7422 57.9456V57.9456C31.8805 57.9456 28.75 61.0761 28.75 64.9378V70.4999"
            [attr.stroke]="alert ? '#F1344B' : '#5A5A68'"
            stroke-width="3"
          />
        </g>
        <mask
          id="mask1_795_63048"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="160"
          height="161"
        >
          <circle
            cx="80"
            cy="80.5"
            r="79.5"
            fill="url(#paint3_linear_795_63048)"
            stroke="url(#paint4_linear_795_63048)"
          />
        </mask>
        <g mask="url(#mask1_795_63048)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M69.2387 72.8739C69.0409 72.698 68.7402 72.7068 68.553 72.894L68.4471 72.9999H69.3805L69.2387 72.8739ZM70.3258 73.8401L79.0793 81.621L81.0723 79.3788L71.2318 70.6316C69.8467 69.4005 67.7421 69.4623 66.4317 70.7727L57.7651 79.4392L59.8865 81.5605L67.3258 74.1212V120.917C67.3258 126.347 71.7283 130.75 77.1591 130.75C81.6695 130.75 85.3258 127.094 85.3258 122.583V93.6249C85.3258 90.6564 87.7323 88.2499 90.7008 88.2499C93.6693 88.2499 96.0758 90.6564 96.0758 93.6249V108C96.0758 111.59 98.986 114.5 102.576 114.5C106.166 114.5 109.076 111.59 109.076 108V103.625C109.076 99.2756 112.602 95.7499 116.951 95.7499H172.576V92.7499H116.951C110.945 92.7499 106.076 97.6188 106.076 103.625V108C106.076 109.933 104.509 111.5 102.576 111.5C100.643 111.5 99.0758 109.933 99.0758 108V93.6249C99.0758 88.9995 95.3262 85.2499 90.7008 85.2499C86.0754 85.2499 82.3258 88.9995 82.3258 93.6249V122.583C82.3258 125.437 80.0126 127.75 77.1591 127.75C73.3852 127.75 70.3258 124.691 70.3258 120.917V73.8401Z"
            [attr.fill]="alert ? '#F1344B' : '#7F7F8C'"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_795_63048"
            x1="2.2086"
            y1="1.4246"
            x2="53.6453"
            y2="185.08"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_795_63048"
            x1="141.25"
            y1="26.1249"
            x2="69.3751"
            y2="72.3749"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
            <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_795_63048"
            x1="2.20866"
            y1="1.42451"
            x2="53.6454"
            y2="185.08"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_795_63048"
            x1="120.625"
            y1="46.75"
            x2="33.75"
            y2="97.375"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
            <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
            <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
            <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_795_63048"
            x1="160"
            y1="128.5"
            x2="2.84232"
            y2="121.737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.04" />
            <stop offset="1" stop-color="white" stop-opacity="0.08" />
          </linearGradient>
        </defs>
      </svg>

      <svg class="icon-download" viewBox="0 0 160 160" fill="none" *ngIf="type == 'Download'">
        <circle cx="80" cy="80" r="79.5" fill="#2B2B33" stroke="url(#paint0_linear_795_36765)" />
        <mask
          id="mask0_795_36765"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="-1"
          width="162"
          height="162"
        >
          <circle
            cx="80.0001"
            cy="80.0002"
            r="79.5"
            transform="rotate(-64 80.0001 80.0002)"
            fill="url(#paint1_linear_795_36765)"
            stroke="url(#paint2_linear_795_36765)"
          />
        </mask>
        <g mask="url(#mask0_795_36765)">
          <path
            d="M114.375 65.6247H115.951C121.639 65.6247 126.25 61.0139 126.25 55.3262V55.3262C126.25 49.6488 121.655 45.0423 115.977 45.0278L114.999 45.0252C109.481 45.0111 105 49.481 105 54.9997V54.9997V53.6053C105 38.4716 92.7232 26.2075 77.5895 26.2225V26.2225C62.477 26.2375 50.2339 38.4928 50.2339 53.6053V66.5214V64.4375C50.2339 60.5759 47.1034 57.4453 43.2417 57.4453V57.4453C39.38 57.4453 36.2495 60.5759 36.2495 64.4375V69.9997"
            [attr.stroke]="alert ? '#F1344B' : '#5A5A68'"
            stroke-width="3"
          />
        </g>
        <mask
          id="mask1_795_36765"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="160"
          height="160"
        >
          <circle
            cx="80"
            cy="80"
            r="79.5"
            fill="url(#paint3_linear_795_36765)"
            stroke="url(#paint4_linear_795_36765)"
          />
        </mask>
        <g mask="url(#mask1_795_36765)">
          <path
            d="M172.5 82.5H116.875C111.697 82.5 107.5 86.6973 107.5 91.875V93.75C107.5 97.8921 104.142 101.25 100 101.25V101.25C95.8579 101.25 92.5 97.8921 92.5 93.75V74.375C92.5 70.578 89.422 67.5 85.625 67.5V67.5C81.828 67.5 78.75 70.578 78.75 74.375V117.5"
            [attr.stroke]="alert ? '#F1344B' : '#7F7F8C'"
            stroke-width="3"
          />
          <path
            d="M90 110L80.1595 118.747C79.368 119.451 78.1653 119.415 77.4165 118.667L68.75 110"
            [attr.stroke]="alert ? '#F1344B' : '#7F7F8C'"
            stroke-width="3"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_795_36765"
            x1="2.2086"
            y1="0.9246"
            x2="53.6453"
            y2="184.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_795_36765"
            x1="141.25"
            y1="25.6251"
            x2="69.3751"
            y2="71.8752"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
            <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_795_36765"
            x1="2.20866"
            y1="0.924752"
            x2="53.6454"
            y2="184.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_795_36765"
            x1="120.625"
            y1="46.25"
            x2="33.75"
            y2="96.875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
            <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
            <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
            <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_795_36765"
            x1="160"
            y1="128"
            x2="2.84232"
            y2="121.237"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.04" />
            <stop offset="1" stop-color="white" stop-opacity="0.08" />
          </linearGradient>
        </defs>
      </svg>

      <svg class="icon-hotfolder" viewBox="0 0 144 144" fill="none" *ngIf="type == 'Hot Folder'">
        <circle cx="72" cy="72" r="71.5" fill="#2B2B33" stroke="url(#paint0_linear_795_23040)" />
        <mask
          id="mask0_795_23040"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="145"
          height="144"
        >
          <circle
            cx="71.9997"
            cy="72"
            r="71.5"
            fill="url(#paint1_linear_795_23040)"
            stroke="url(#paint2_linear_795_23040)"
          />
        </mask>
        <g mask="url(#mask0_795_23040)">
          <path
            d="M49.4998 75.375V31.625C49.4998 27.2067 53.0815 23.625 57.4998 23.625H66.9373C69.1119 23.625 70.8748 25.3879 70.8748 27.5625V27.5625C70.8748 29.7371 72.6376 31.5 74.8123 31.5H96.6248C101.043 31.5 104.625 35.0817 104.625 39.5V46.125V64.6875"
            [attr.stroke]="alert ? '#F1344B' : '#5A5A68'"
            stroke-width="3"
            stroke-linecap="round"
          />
        </g>
        <mask
          id="mask1_795_23040"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="144"
          height="144"
        >
          <circle
            cx="72"
            cy="72"
            r="71.5"
            fill="url(#paint3_linear_795_23040)"
            stroke="url(#paint4_linear_795_23040)"
          />
        </mask>
        <g mask="url(#mask1_795_23040)">
          <path
            d="M86.625 64.125V57.5C86.625 53.0817 83.0433 49.5 78.625 49.5H56.8125C54.6379 49.5 52.875 47.7371 52.875 45.5625V45.5625C52.875 43.3879 51.1121 41.625 48.9375 41.625H39.5C35.0817 41.625 31.5 45.2067 31.5 49.625V85.375C31.5 89.7933 35.0817 93.375 39.5 93.375H80.4375C83.8548 93.375 86.625 90.6048 86.625 87.1875V86.625C86.625 83.5184 89.1434 81 92.25 81V81C95.3566 81 97.875 83.5184 97.875 86.625V107.438C97.875 110.233 100.142 112.5 102.938 112.5V112.5C105.733 112.5 108 110.233 108 107.438V91.125C108 88.6397 110.015 86.625 112.5 86.625V86.625C114.985 86.625 117 88.6397 117 91.125V91.6875C117 94.4834 119.267 96.75 122.063 96.75H142.312"
            [attr.stroke]="alert ? '#F1344B' : '#7F7F8C'"
            stroke-width="3"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_795_23040"
            x1="1.98774"
            y1="0.83214"
            x2="48.2808"
            y2="166.122"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_795_23040"
            x1="127.125"
            y1="23.0625"
            x2="62.4372"
            y2="64.6875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
            <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_795_23040"
            x1="1.98749"
            y1="0.832139"
            x2="48.2805"
            y2="166.122"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_795_23040"
            x1="108.563"
            y1="41.625"
            x2="30.375"
            y2="87.1875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
            <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
            <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
            <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_795_23040"
            x1="144"
            y1="115.2"
            x2="2.55809"
            y2="109.113"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.04" />
            <stop offset="1" stop-color="white" stop-opacity="0.08" />
          </linearGradient>
        </defs>
      </svg>

      <svg
        class="icon-broadcast"
        viewBox="0 0 160 160"
        fill="none"
        *ngIf="type == 'Broadcast' || type == 'Workstation'"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="80" cy="80" r="79.5" fill="#2B2B33" stroke="url(#paint0_linear_2107_28044)" />
        <mask
          id="mask0_2107_28044"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="160"
          height="160"
        >
          <circle
            cx="80"
            cy="80"
            r="79.5"
            fill="url(#paint1_linear_2107_28044)"
            stroke="url(#paint2_linear_2107_28044)"
          />
        </mask>
        <g mask="url(#mask0_2107_28044)">
          <circle cx="93.75" cy="52.5" r="3.75" fill="#5A5A68" />
          <circle cx="108.75" cy="52.5" r="3.75" fill="#5A5A68" />
          <circle cx="123.75" cy="52.5" r="3.75" fill="#5A5A68" />
        </g>
        <mask
          id="mask1_2107_28044"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="160"
          height="160"
        >
          <circle
            cx="80"
            cy="80"
            r="79.5"
            fill="url(#paint3_linear_2107_28044)"
            stroke="url(#paint4_linear_2107_28044)"
          />
        </mask>
        <g mask="url(#mask1_2107_28044)">
          <path
            d="M172.576 87.5H116.951C111.773 87.5 107.576 91.6973 107.576 96.875V101.25C107.576 104.011 105.337 106.25 102.576 106.25V106.25C99.8145 106.25 97.5759 104.011 97.5759 101.25V86.875C97.5759 83.078 94.4979 80 90.7009 80V80C86.904 80 83.8259 83.078 83.8259 86.875V115.833C83.8259 119.515 80.8412 122.5 77.1593 122.5V122.5C72.5569 122.5 68.8259 118.769 68.8259 114.167V72.8504C68.8259 69.2051 65.8708 66.25 62.2255 66.25V66.25C58.5802 66.25 55.6251 69.2051 55.6251 72.8504V76.875C55.6251 82.743 50.8681 87.5 45.0001 87.5H2.50009"
            stroke="#7F7F8C"
            stroke-width="3"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2107_28044"
            x1="2.2086"
            y1="0.9246"
            x2="53.6453"
            y2="184.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2107_28044"
            x1="141.25"
            y1="25.625"
            x2="69.375"
            y2="71.875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
            <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2107_28044"
            x1="2.2086"
            y1="0.9246"
            x2="53.6453"
            y2="184.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.08" />
            <stop offset="1" stop-color="white" stop-opacity="0.04" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2107_28044"
            x1="120.625"
            y1="46.25"
            x2="33.75"
            y2="96.875"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#2B2B33" />
            <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
            <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
            <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
            <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2107_28044"
            x1="160"
            y1="128"
            x2="2.84232"
            y2="121.237"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stop-opacity="0.04" />
            <stop offset="1" stop-color="white" stop-opacity="0.08" />
          </linearGradient>
        </defs>
      </svg>

      <svg class="icon-search" viewBox="0 0 128 128" fill="none" *ngIf="type == 'Search Result'">
        <circle cx="64" cy="64" r="63.5" fill="#2B2B33" stroke="url(#a)" />
        <mask id="d" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
          <circle cx="64" cy="64" r="63.5" fill="url(#b)" stroke="url(#c)" />
        </mask>
        <g mask="url(#d)" fill-rule="evenodd" clip-rule="evenodd" fill="#5A5A68">
          <path
            d="M68 31.5a6.5 6.5 0 1 0 0 13h26a6.5 6.5 0 1 0 0-13H68ZM58.5 38a9.5 9.5 0 0 1 9.5-9.5h26a9.5 9.5 0 0 1 0 19H68a9.5 9.5 0 0 1-9.5-9.5Z"
          />
          <path d="M107.5 44.5H105v-3h2.5a8 8 0 0 1 0 16h-23a8 8 0 0 1-8-8h3a5 5 0 0 0 5 5h23a5 5 0 0 0 0-10Z" />
        </g>
        <mask id="g" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
          <circle cx="64" cy="64" r="63.5" fill="url(#e)" stroke="url(#f)" />
        </mask>
        <g mask="url(#g)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30 58.5C30 51.044 36.044 45 43.5 45S57 51.044 57 58.5 50.956 72 43.5 72 30 65.956 30 58.5ZM43.5 42C34.387 42 27 49.387 27 58.5S34.387 75 43.5 75c2.995 0 5.805-.798 8.226-2.194L61.95 93.254A7.68 7.68 0 0 0 76.5 89.82V74a2.5 2.5 0 0 1 5 0v3a8.5 8.5 0 0 0 8.5 8.5h45.5v-3H90a5.5 5.5 0 0 1-5.5-5.5v-3a5.5 5.5 0 1 0-11 0v15.82a4.68 4.68 0 0 1-8.867 2.093L54.205 71.056A16.463 16.463 0 0 0 60 58.5C60 49.387 52.613 42 43.5 42Z"
            fill="#7F7F8C"
          />
        </g>
        <defs>
          <linearGradient id="a" x1="1.767" y1=".74" x2="42.916" y2="147.664" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" stop-opacity=".08" />
            <stop offset="1" stop-color="#fff" stop-opacity=".04" />
          </linearGradient>
          <linearGradient id="b" x1="113" y1="20.5" x2="55.5" y2="57.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2B2B33" />
            <stop offset=".835" stop-color="#2B2B33" stop-opacity=".112" />
            <stop offset=".901" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient id="c" x1="1.767" y1=".74" x2="42.916" y2="147.664" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" stop-opacity=".08" />
            <stop offset="1" stop-color="#fff" stop-opacity=".04" />
          </linearGradient>
          <linearGradient id="e" x1="96.5" y1="37" x2="27" y2="77.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2B2B33" />
            <stop offset=".516" stop-color="#2B2B33" stop-opacity=".854" />
            <stop offset=".88" stop-color="#2B2B33" stop-opacity=".073" />
            <stop offset=".906" stop-color="#2B2B33" stop-opacity=".032" />
            <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
          </linearGradient>
          <linearGradient id="f" x1="128" y1="102.4" x2="2.274" y2="96.99" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" stop-opacity=".04" />
            <stop offset="1" stop-color="#fff" stop-opacity=".08" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="empty-label paragraph">
      <ng-content></ng-content>
    </div>
  </div>
</div>
