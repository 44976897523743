import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LinkAction } from 'src/app/common/enums'
import { OrgEntitlements } from 'src/app/models/bebop.model'
import { IOrgUsage } from 'src/app/models/organization.model'
import { ActivitiesService } from 'src/app/services/activities.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { DashboardService } from 'src/app/services/dashboard.service'
import { MainService } from 'src/app/services/main.service'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { UserService } from 'src/app/services/user.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { BebopClientAnnouncement } from 'src/app/store/session/session.store'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { environment } from 'src/environments/environment'
@Component({
  selector: 'bebop-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  uiProjects = {
    available: false,
    desc: '',
    info: '',
  }
  environment = environment
  uiMembers = { available: false, desc: '', info: '' }
  uiWorkstations = { available: false, desc: '', info: '' }
  totalUsers: number
  totalProjects: number
  totalServerItems: number
  noActivities: boolean = true
  activities = []
  loading = false
  isOrgAdmin = false
  userFirstName = ''
  selectedOrg: any
  entitlements: OrgEntitlements
  clientAnnouncements: BebopClientAnnouncement
  private _bannerText: string
  downloadCardClose: boolean = false
  // Subject to signal when to unsubscribe
  private destroy$ = new Subject<void>()

  get hasValidSubscription() {
    return this.entitlements?.[this.selectedOrg?._id] ? true : false
  }

  constructor(
    private activitiesService: ActivitiesService,
    private dashboardService: DashboardService,
    private projectsService: ProjectsService,
    private organizationsService: OrganizationsService,
    private userService: UserService,
    private mainService: MainService,
    private sessionQuery: SessionQuery,
    private uiQuery: UIQuery,
    private cdRef: ChangeDetectorRef,
    private util: BebopClientUtilsService
  ) {
    const isCardClosed = sessionStorage.getItem('downloadCardClose')
    this.downloadCardClose = isCardClosed === 'true'
  }

  ngOnInit() {
    this.sessionQuery
      .getAnnouncements()
      .pipe(takeUntil(this.destroy$))
      .subscribe((announcements) => {
        this.clientAnnouncements = announcements
        const oldBannerText = this.mainService.getAnnouncementItem('bebop-client:banner:dashboard')
        this._bannerText = this.clientAnnouncements?.dashboard ?? ''

        if (oldBannerText == this._bannerText) {
          this._bannerText = ''
        }
      })

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }
        this.sessionQuery
          .getEntitlements()
          .pipe(takeUntil(this.destroy$))
          .subscribe((entitlements) => (this.entitlements = entitlements))

        this.resetState()
        this.selectedOrg = org
        if (!this.selectedOrg.suspended && this.hasValidSubscription) {
          this.isOrgAdmin = this.userService.userEntitlement.isOrgAdmin()
          this.loading = true
          this.loadDashboardData()
        }
      })

    this.uiQuery
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (!user) return
        this.userFirstName = user.firstname
      })
  }

  loadDashboardData() {
    this.loadData()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  loadData() {
    let pendingRequests = this.isOrgAdmin ? 4 : 2

    const completeLoading = () => {
      pendingRequests--
      if (pendingRequests === 0) {
        this.loading = false
      }
    }

    const handleError = (error: any) => {
      completeLoading()
    }

    this.activitiesService
      .getActivities({ page: 1, size: 25 })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: handleError,
        next: (res) => {
          if (res.data) {
            this.activities = res.data
            this.noActivities = !(res.data.length > 0)
          }
          completeLoading()
        },
      })

    if (!this.isOrgAdmin) {
      this.projectsService
        .getOrgProjects({
          page: 1,
          size: 1,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: handleError,
          next: (res) => {
            if (res.data) {
              const totalProjects = res.data.length
              this.uiProjects.info = totalProjects || 0
              this.uiProjects.available = totalProjects != 0
            }

            this.cdRef.detectChanges()
            completeLoading()
          },
        })
    }

    this.projectsService
      .getProjectsStorageByPod()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: handleError,
        next: (res) => {
          this.uiProjects.desc = (res.totalInGb || 0) + 'GB files stored'
          this.cdRef.detectChanges()
          completeLoading()
        },
      })

    if (this.isOrgAdmin) {
      this.dashboardService
        .getDashboardData({ page: 1, size: 1 })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: handleError,
          next: (res) => {
            const dashboardCountsData = res.dashboardData?.data
            this.totalProjects = dashboardCountsData?.totalProjects || 0
            this.uiProjects.info = `${this.totalProjects}`
            this.uiProjects.available = this.totalProjects !== 0

            this.totalUsers = dashboardCountsData?.totalUsers || 0
            this.uiMembers.available = this.totalUsers !== 0
            this.uiMembers.info = `${this.totalUsers}`
            completeLoading()
          },
        })

      this.organizationsService
        .usersOnlineCount()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: handleError,
          next: (res) => {
            this.uiMembers.desc = (res.count || 1) + ' online'
            this.cdRef.detectChanges()
            completeLoading()
          },
        })

      this.organizationsService
        .getOrgUsage()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: handleError,
          next: (res: IOrgUsage) => {
            const { workstation } = res
            if (workstation.used) this.uiWorkstations.available = true

            this.uiWorkstations.desc = (workstation.purchased || 0) + ' included per month'
            this.uiWorkstations.info = `${Math.max(workstation.hoursRemaining || 0, 0)} hrs left`
            completeLoading()
          },
        })
    }
  }

  resetState() {
    this.uiProjects = { available: false, desc: '', info: '' }
    this.uiMembers = { available: false, desc: '', info: '' }
    this.uiWorkstations = { available: false, desc: '', info: '' }
    this.activities = []
    this.loading = false // or false based on your logic
    this.noActivities = true
  }

  onCloseBanner() {
    this.mainService.setAnnouncementItem('bebop-client:banner:dashboard', this._bannerText)
    this._bannerText = ''
  }

  get bannerText() {
    return this._bannerText ?? ''
  }

  get hasBanner() {
    return !!this._bannerText
  }

  closeDownloadCard(): void {
    sessionStorage.setItem('downloadCardClose', 'true')
    this.downloadCardClose = true
  }

  downloadApp(): void {
    this.util.openExternalLink(LinkAction.BBP_CLIENT_DOWNLOAD)
  }
}
