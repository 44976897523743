import { InjectionToken, TemplateRef } from '@angular/core'

import { OverlayRef } from '@angular/cdk/overlay'
import { ToastService } from './toast.service'

export class ToastRef {
  timerId: number
  id = Date.now()
  constructor(private readonly overlay: OverlayRef, private readonly service: ToastService) {
    this.timerId = window.setInterval(() => {
      this.updatePosition()
    }, 1000)
  }

  getId() {
    return this.id
  }

  close() {
    this.overlay.dispose()
    window.clearInterval(this.timerId)
    this.service.removeToast()
  }

  updatePosition() {
    this.overlay.updatePositionStrategy(this.service.getPositionStrategy(this))
    this.overlay.updatePosition()
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement
  }

  getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect()
  }
}

export class ToastData {
  type: ToastType
  text?: string
  template?: TemplateRef<any>
  templateContext?: {}
  duration?: number
}

export type ToastType = 'error' | 'warning' | 'info' | 'success'

export interface ToastConfig {
  position?: {
    top: number
    right: number
    firstTop: number
  }
  animation?: {
    fadeOut: number
    fadeIn: number
  }
  maxToastsInViewPort: number
  timeout: number
  maxCharacters: number
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 16,
    right: 20,
    firstTop: 56
  },
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
  maxToastsInViewPort: 8,
  timeout: 5000,
  maxCharacters: 350, // update element height
}

export const TOAST_CONFIG_TOKEN = new InjectionToken<any>('bebop-toast-config')
