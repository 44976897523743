import { Component, Inject, Input, OnInit } from '@angular/core'

import { Observable, of } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { PageSize, PageSizes, Pagination, SortBy } from 'src/app/common/types'
import { Organization, Project } from 'src/app/models/bebop.model'
import { UiProject } from 'src/app/models/ui.model'
import { ArchiveService } from 'src/app/services/archive.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { UploadConstants } from 'src/app/services/rocket/classes/rocket-constants'
import { RocketService } from 'src/app/services/rocket/rocket.service'
import { UserService } from 'src/app/services/user.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

import {
  RemoteFile,
  RemoteNavigator,
  RemoteNavTree,
  RocketSession,
  UiUploadFile,
} from '../../../common/classes/rocket-types'

export interface SelectRocketUploadPathAction {
  name: 'Cancel' | 'Close' | 'Select'
  path?: string
  project?: Project
}

@Component({
  selector: 'bebop-select-rocket-upload-path',
  styleUrls: ['./select-rocket-upload-path.component.scss'],
  templateUrl: './select-rocket-upload-path.component.html',
})
export class SelectRocketUploadPathComponent implements OnInit, OnExternalModalClose {
  title = 'Select Upload Path'

  loading = false

  project: Project

  session: RocketSession<any, UiUploadFile>
  rocket: any

  search = ''
  projectName: string

  folderName: string = ''

  folders: RemoteFile[] = []
  filtered: RemoteFile[] = []

  isAddState = false
  errorFolder = false
  tooltipErrMsg = 'Folder name can only contain alphabets, numbers, a space and the following characters . , _ ( )'

  searchProject = ''
  filteredProjectCount = 0
  projects: UiProject[] = []
  selectedProject: UiProject
  selectedOrg: Organization

  pageOptions: Pagination<RemoteFile> = {
    list: [],
    page: 1,
    size: <PageSize>PageSizes[1],
    sort: {
      reverse: false,
    },
    total: 0,
  }

  sortBy: SortBy = {}
  dirOnly = true
  searchEnabled = true

  noPagination = true

  navTree: RemoteNavTree[] = []

  navigator: RemoteNavigator = {
    path: '/',
    search: '',
    sort: [],
  }

  credentials: any
  bucketName: string

  get user() {
    return this.userService.user
  }

  get selectedPath() {
    return this.navigator?.path || ''
  }

  constructor(
    public ref: ModalOverlayRef<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private uiQuery: UIQuery,
    private projectsService: ProjectsService,
    private rocketService: RocketService,
    private toastService: ToastService,
    private userService: UserService,
    private archiveService: ArchiveService
  ) {
    this.onError = this.onError.bind(this)
    this.sort = this.sort.bind(this)
    this.onProjectSearch = this.onProjectSearch.bind(this)
  }

  ngOnInit(): void {
    this.title = this.data.title || this.title
    this.project = this.data.project
    this.session = this.data.session
    this.rocket = this.session?.rocket
    if (!this.project || !this.session || !this.rocket) {
      console.error('project/session/rocket is missing for upload rocket path selection')
      this.ref?.close()
      return
    }

    this.selectedOrg = this.uiQuery.getSelectedOrgValue()

    if (this.data.archive) {
      // Do not load files list for archive/retrieve view (load only after project select)
      this.onProjectSearch('')
      return
    }

    this.projectName = this.project.name || ''

    this.navTree = [
      {
        name: this.projectName,
        path: '/',
      },
    ]

    this.onRefresh()
  }

  toProjectSize(project: Project) {
    let sizeStr = ''
    let gb = project.folderSize?.gb | 0
    let mb = project.folderSize?.mb | 0
    // no bytes level

    sizeStr = gb ? `${gb} GB` : `${mb} MB`

    return sizeStr
  }

  onProjectSearch(value: string): Observable<string[]> {
    let projectParams: any = {
      page: 1,
      searchText: value,
      size: 1000,
      sort: '-date_created',
      user: this.userService.id,
    }
    if (this.data.archive && !this.data.glacier) {
      // projectParams.highPerformanceOnly = true
    } else if (this.data.archive && this.data.glacier) {
      projectParams.archiveOnly = true
    } else {
      projectParams = {
        orgs: [this.selectedOrg._id],
        searchText: this.searchProject,
      }
    }

    this.projectsService
      .getOrgProjects(projectParams)

      .subscribe((projects) => {
        this.projects = projects.data ?? []
        if (this.data.archive && !this.data.glacier) {
          this.projects = this.projects.filter((p: any) => p.storage?.highPerformance) ?? []
        }

        this.filteredProjectCount = this.projects.length || 0
        if (this.filteredProjectCount === 1) {
          this.onSelectProject(this.projects[0])
        }
      })

    return of(this.projects.map((p) => p.name))
  }

  onDropdownProjectChange(e: 'Open' | 'Close') {
    this.searchProject = ''
  }

  onSelectProject(p: any) {
    let credentialId = p.credentials
    if (credentialId?.length > 0) {
      this.credentials = credentialId
      this.bucketName = p.fromBucket
    }

    this.selectedProject = p
    this.project = p
    this.projectName = this.project.name || ''
    this.navigator = {
      path: '/',
      search: '',
      sort: [],
    }
    this.navTree = [
      {
        name: this.projectName,
        path: '/',
      },
    ]

    this.onRefresh()
  }

  toggleSort() {
    this.pageOptions.sort.reverse = !this.pageOptions.sort.reverse
    let folders = this.search ? this.filtered : this.folders
    folders?.sort(this.sort)

    this.onPageChange(this.pageOptions.page)
  }

  validateFolderName() {
    this.folderName = this.folderName ? this.folderName.trim() : ''
    if (!this.folderName || this.folderName.length > UploadConstants.MAX_FOLDER_NAME_LENGTH) {
      this.tooltipErrMsg = 'Folder name length should be between 1 and 50 characters'
      return false
    }

    let regEx = new RegExp('^([a-zA-Z0-9 ._()-]{1,50})$')

    if (!regEx.test(this.folderName)) {
      this.tooltipErrMsg =
        'Folder name can only contain alphabets, numbers, a space, and the following characters . , _ - ( )'
      return false
    }

    return true
  }

  onAddFolder() {
    if (!this.validateFolderName()) {
      this.onError({
        message: this.tooltipErrMsg,
      })

      return
    }

    this.navigator.search = this.search = ''
    this.isAddState = false
    let folderName = this.folderName
    let options = {
      newDirectory: folderName,
      projectId: this.project._id,
      recursive: true,
      relativePath: this.navigator.path,
      userId: this.userService.id,
    }

    this.loading = true

    if (this.data.glacier) {
      let options = {
        bucketName: this.bucketName,
        credentialId: this.credentials,
        key: this.navigator.path.replace(/^\//, '') + folderName + '/',
      }
      this.archiveService.createFolder(options).subscribe({
        error: (e) => {
          this.onError(e)
        },
        next: (result) => {
          this.loading = false
          console.log('Folder created', folderName, result)
          window.setTimeout(() => {
            this.loading = false
            let createdPath =
              this.navigator.path == '/' ? this.navigator.path + folderName : this.navigator.path + '/' + folderName
            createdPath = createdPath + '/'
            this.goToDirectory({
              name: this.folderName,
              path: createdPath,
            })
            this.folderName = ''
            this.onClearFolder()
          }, 16)
        },
      })
    } else {
      this.rocket
        .createFolderOnProject(
          {
            project: this.project,
          },
          options
        )
        .then((result: any) => {
          console.log('Folder created', folderName, result)

          if (result?.error?.description && result.error.description.indexOf('EEXIST') != -1) {
            this.toastService.show({
              text: 'Directory already exists!',
              type: 'warning',
            })
          }

          window.setTimeout(() => {
            this.loading = false
            let createdPath =
              this.navigator.path == '/' ? this.navigator.path + folderName : this.navigator.path + '/' + folderName
            createdPath = createdPath + '/'
            this.goToDirectory({
              name: this.folderName,
              path: createdPath,
            })
            this.folderName = ''
            this.onClearFolder()
          }, 16)
        })
        .catch(this.onError)
    }
  }

  onClearFolder() {
    this.folderName = ''
    this.errorFolder = false
    this.isAddState = false
  }

  cancel() {
    this.loading = false
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  select() {
    this.navigator.path = this.navigator.path.replace(/\/\/+/g, '/').trim()
    this.ref.send({ name: 'Select', path: this.selectedPath?.substring(1), project: this.project })
    this.ref.close()
  }

  onSearch(s: string) {
    this.search = s

    let filtered = this.folders.filter((f) => {
      let regx = RegExp(this.search, 'i')
      if (f.name.search(regx) != -1) {
        return true
      }
      return false
    })

    filtered.sort(this.sort)
    this.filtered = filtered

    this.pageOptions.list = this.noPagination ? filtered : filtered?.slice(0, this.pageOptions.size)
    this.pageOptions.total = filtered.length
    this.pageOptions.page = 1
  }

  onAddTrigger() {
    this.isAddState = true
  }

  onKeyUpFolder(e: KeyboardEvent) {
    // console.log(this.folderName)
    this.errorFolder = !this.validateFolderName()

    if (e.key == 'Escape') {
      this.onClearFolder()
      return
    }

    if (!this.errorFolder && e.key == 'Enter') {
      this.onAddFolder()
      this.onClearFolder()
    }
  }

  onPageChange(s: number) {
    let folders = this.search ? this.filtered : this.folders

    this.pageOptions.list = this.noPagination
      ? folders
      : folders?.slice((s - 1) * this.pageOptions.size, s * this.pageOptions.size)
    this.pageOptions.page = s
  }

  selectFolder(f: RemoteFile) {
    // console.log(f)
    this.goToDirectory(f)
  }

  onRefresh() {
    this.onClearFolder()
    this.getDirectoryList()
  }

  onNavUp() {
    if (this.navTree.length <= 1) return

    this.navTree.pop()

    this.goToDirectory(this.navTree[this.navTree.length - 1])
  }

  onError(error: { message: string }) {
    window.setTimeout(() => {
      this.loading = false
      error = error || {
        message: 'Error while select directory',
      }
      console.error(error)
      this.rocketService.onNotification({
        message: error.message || 'Error getting details from server',
        type: 'error',
      })
    }, 16)
  }

  getDirectoryList() {
    this.loading = true
    let nav = this.navigator

    this.search = ''

    // unused ? - left over code from elastic search based browse?
    if (this.sortBy && Object.keys(this.sortBy).length) {
      nav.sort = [this.sortBy]
    } else {
      if (!this.sortBy) this.sortBy = {}

      // set default sort file name by asc
      this.sortBy.name = {
        order: 'asc',
      }
      nav.sort = [this.sortBy]
    }

    this.navTree = [
      {
        name: this.project.name,
        path: '/',
      },
    ]

    if (nav.path != '/') {
      let currentRoot = ''
      this.navTree = this.navTree.concat(
        nav.path
          .split('/')
          .filter((f) => f?.length)
          .map((p, idx) => {
            currentRoot = currentRoot + '/' + p
            return {
              name: p,
              path: currentRoot + '/',
            }
          })
      )
    }

    this.pageOptions.list = this.folders.slice(0, this.pageOptions.size)

    let rocket = this.session?.rocket

    let options = {
      limit: Number.MAX_SAFE_INTEGER,
      projectId: this.project._id,
      relativePath: nav.path,
      showFiles: false,
      skip: 0,
      userId: this.userService.id,
    }

    this.loading = true
    if (this.data.archive && this.data.glacier) {
      this.getDirectoryGlacier(nav)
    } else {
      this.getDirectoryRocket(nav, options)
    }
  }

  getDirectoryGlacier(nav: any) {
    let prefix = ''
    if (nav.path.length > 0) {
      prefix = nav.path.slice(1)
    }

    this.archiveService
      .listFiles({
        bucketName: this.bucketName,
        credentialId: this.credentials,
        maxKeys: 50,
        page: 1,
        prefix: prefix,
      })
      .subscribe({
        error: (e) => {
          window.setTimeout(() => {
            this.loading = false
            this.pageOptions.total = 0
            this.folders = []
            this.pageOptions.list = []
            this.pageOptions.page = 1
            this.onError(e)
          }, 16)
        },
        next: (result) => {
          window.setTimeout(() => {
            this.loading = false

            const transformedResponse = {
              entries:
                result.folderList?.map((folder) => {
                  folder.Prefix = folder.Prefix.replace(/\/$/, '')
                  return {
                    dir: true, // Indicate it's a directory
                    name: folder.Prefix.substring(folder.Prefix.lastIndexOf('/') + 1), // Remove the dynamic prefix
                  }
                }) || [],
              total: result.folderList?.length || 0,
            }

            this.pageOptions.total = transformedResponse.total
            this.folders = transformedResponse.entries || []
            // Add relative path to result
            this.folders = this.folders.map((f) => {
              f.path = nav.path + f.name + '/'
              return f
            })
            this.folders.sort(this.sort)
            this.pageOptions.list = this.noPagination ? this.folders : this.folders.slice(0, this.pageOptions.size)
            this.pageOptions.page = 1
          }, 16)
        },
      })
  }

  getDirectoryRocket(nav: any, options: any) {
    this.rocket
      .listDirectory(
        {
          project: this.project,
        },
        options
      )
      .then((result: { total: number; entries: RemoteFile[] }) => {
        window.setTimeout(() => {
          this.loading = false
          this.pageOptions.total = result.total
          this.folders = result.entries || []
          // Add relative path to result
          this.folders = this.folders.map((f) => {
            f.path = nav.path + f.name + '/'
            return f
          })
          this.folders.sort(this.sort)
          this.pageOptions.list = this.noPagination ? this.folders : this.folders.slice(0, this.pageOptions.size)
          this.pageOptions.page = 1
        }, 16)
      })
      .catch((e: any) => {
        window.setTimeout(() => {
          this.loading = false
          this.pageOptions.total = 0
          this.folders = []
          this.pageOptions.list = []
          this.pageOptions.page = 1
          this.onError(e)
        }, 16)
      })
  }

  sort(l: RemoteFile, r: RemoteFile) {
    return this.pageOptions.sort?.reverse ? r.name.localeCompare(l.name) : l.name.localeCompare(r.name)
  }

  goToDirectory(folder: RemoteFile) {
    let nav = this.navigator
    nav.path = folder.path
    this.search = ''

    this.pageOptions.page = 1

    this.getDirectoryList()
  }

  goBackDirectory(folder: RemoteFile) {
    let nav = this.navigator
    if (folder.path == nav.path) return
    nav.path = folder.path
    this.pageOptions.page = 1
    this.search = ''
    this.getDirectoryList()
  }
}
