import { Component, Input, OnInit } from '@angular/core'
import { ServerState } from 'src/app/models/ui.model'

@Component({
  selector: 'bebop-server-status',
  templateUrl: './server-status.component.html',
  styleUrls: ['./server-status.component.scss'],
})
export class ServerStatusComponent implements OnInit {
  @Input('status') status: ServerState

  @Input('loadingStyles') loadingStyles

  constructor() {}

  ngOnInit(): void {
    this.status = this.status || 'Connected'
  }
}
