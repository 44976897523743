<div class="container">
  <div class="loader" *ngIf="isLoading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <div class="action-section">
    <ng-container *ngIf="showSearch && !isLoading">
      <bebop-input-search *ngIf="showSearch" (on-keyup)="searchUser($event)"></bebop-input-search>
      <div class="devider"></div>
    </ng-container>

    <table class="cree8-table" *ngIf="getListMappingUsers().length > 0 && !isLoading">
      <thead>
        <tr>
          <th>Member</th>
          <th>Email</th>
          <th>Last activity</th>
          <th>Access</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let user of getListMappingUsers(); let i = index">
          <tr (click)="navigateToProfile(user._id)">
            <td class="td-name">
              <img [src]="getUserImage(user)" alt="{{ user?.name }}" width="32" />
              {{ user.name }}
            </td>
            <td>{{ user.email }}</td>
            <td>
              <display-date-time [value]="user.last_login"></display-date-time>
            </td>
            <td>
              <cree8-btn-icon ariaLabel="{{ assign ? 'Unassign' : 'Assign' }}"
                cree8-tooltip-text="{{ assign ? 'Unassign' : 'Assign' }}" cree8-tooltip-position="top"
                (click)="assignUser(user._id, assign, $event)">
                <ng-container *ngIf="assign">
                  <svg class="ico" viewBox="0 0 24 24">
                    <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Unassign'"></use>
                  </svg>
                </ng-container>

                <ng-container *ngIf="!assign">
                  <svg class="ico" viewBox="0 0 24 24">
                    <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Assign'"></use>
                  </svg>
                </ng-container>
              </cree8-btn-icon>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <no-data [title]="'No members yet?'" [icon]="'Team'"
      [description]="'Let\'s not wait any longer—start inviting members'"
      *ngIf="getListMappingUsers().length === 0 && !isLoading"></no-data>
  </div>
</div>

<cree8-modal [modal]="modalSetting" (cancelModal)="onCloseModal()" (next)="onSubmitModal()" size="normal"
  *ngIf="modalSetting.title !== ''">
  <p>Are you sure to remove {{ selectedMember.name }} from member ?</p>
</cree8-modal>
