<div class="cree8-modal-overlay" [class.no-overlay]="hideOverlay" *ngIf="modal">
  <div class="cree8-modal" [ngClass]="size">
    <div class="header">
      <span class="headline4">{{ modal.title }}</span>
      <span class="captions" *ngIf="modal.counting > 0">{{ modal.current }} / {{ modal.counting }}</span>
    </div>

    <div class="content scrollbar" [class.no-padding-top]="noPaddingTop">
      <ng-content></ng-content>
    </div>

    <div class="actions" *ngIf="!modal.contentOnly">
      @if (modal.type === 'confirmation') {
      <cree8-btn appearance="text" [disabled]="modal.disableCancel" (click)="onCloseModal()">{{modal.labelCancel ||
        'CANCEL'}}</cree8-btn>
      <cree8-btn (click)="onNext()" [disabled]="modal.loading || modal.disableNext"
        [loading]="modal.loading">{{modal.labelConfirm || 'NEXT'}}</cree8-btn>
      }

      @if (modal.type === 'close') {
      <cree8-btn (click)="onCloseModal()" [loading]="modal.loading">{{ modal.labelConfirm || 'OK' }}</cree8-btn>
      }

      @if (modal.type === 'logout') {
      <cree8-btn appearance="text" [disabled]="modal.disableCancel" (click)="onCloseModal()">{{modal.labelCancel ||
        'Stay Logged In'}}</cree8-btn>
      <cree8-btn (click)="onNext()" [appearance]="'filled'" [loading]="modal.loading">{{ modal.labelConfirm || 'Yes,
        Log Out' }}</cree8-btn>
      }
    </div>
  </div>
</div>
