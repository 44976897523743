<div class="section">
  <website-container>
    <div class="container fade-up">
      <h3>Scale your subscription to fit your needs</h3>
      <span class="description-subs" id="pricing">
        We have a range of pricing levels to suit every creative business.<br>From
        freelance to studio, we've got you covered.
      </span>
      <div class="intro-container">
        <span class="intro">Introductory pricing, subject to change</span>
        <span class="ask">*Ask us about our high-speed storage add-on</span>
      </div>
    </div>

    <div class="subscription-container">
      <ng-container *ngIf="!isErrFetchSubs; else notError">
        <div class="subscription-card">
          <div class="layout fade-up">
            <ng-container
              *ngFor="let subscription of dataSubscriptions; let i = index"
            >
              <div class="card-price-container">
                <card-price
                [data]="subscription"
                (clickBtn)="onSelect(subscription)"
              ></card-price>
              </div>

            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #notError>
        <reload></reload>
      </ng-template>
    </div>

    <div class="solutions">
      <div class="solutions-header">
        <span class="title-solutions fade-up">Enterprise Solutions</span>
        <span class="description-solutions fade-up">
          Designed for dynamic organizations requiring bespoke solutions, our
          Enterprise plan delivers unmatched performance and support.
        </span>
      </div>

      <div class="solutions-list-container fade-up">
        <span>What we offer</span>
        <ul class="solutions-list">
          <li *ngFor="let item of solutions; let i = index">
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.3333 1.3335L4.00001 8.66683L0.666672 5.3335"
                stroke="#494E55"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ item }}
          </li>
        </ul>
      </div>

      <div class="button-solutions fade-up">
        <cree8-button variant="dark" (click)="onContact()">
          <div class="support">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1977_1606)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6667 11.3332H12C11.632 11.3332 11.3333 11.0345 11.3333 10.6665V7.33317C11.3333 6.96517 11.632 6.6665 12 6.6665H12.6667C13.4033 6.6665 14 7.26317 14 7.99984V9.99984C14 10.7365 13.4033 11.3332 12.6667 11.3332Z"
                  stroke="#F5F5F5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 11.3332H3.33333C2.59667 11.3332 2 10.7365 2 9.99984V7.99984C2 7.26317 2.59667 6.6665 3.33333 6.6665H4C4.368 6.6665 4.66667 6.96517 4.66667 7.33317V10.6665C4.66667 11.0345 4.368 11.3332 4 11.3332Z"
                  stroke="#F5F5F5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.3333 6.66667V6.33333C12.3333 3.94 10.3933 2 8.00001 2V2C5.60667 2 3.66667 3.94 3.66667 6.33333V6.66667"
                  stroke="#F5F5F5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.41667 14.1667H7.58333C7.12333 14.1667 6.75 13.7933 6.75 13.3333V13.3333C6.75 12.8733 7.12333 12.5 7.58333 12.5H8.41667C8.87667 12.5 9.25 12.8733 9.25 13.3333V13.3333C9.25 13.7933 8.87667 14.1667 8.41667 14.1667Z"
                  stroke="#F5F5F5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.25 13.3335H10.6667C11.4033 13.3335 12 12.7368 12 12.0002V11.3335"
                  stroke="#F5F5F5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1977_1606">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            CONTACT US
          </div></cree8-button
        >
      </div>
    </div>
  </website-container>
</div>
