<div class="reload" [ngClass]="{'center': centeredPage}">
  <a (click)="reloadPage()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="18" height="18" rx="5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9.5 10.056H7V7.556" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.12421 13C7.6396 15.394 9.826 17.0529 12.2704 16.9045C14.7147 16.7562 16.6844 14.845 16.9065 12.4062C17.1285 9.96743 15.5364 7.73198 13.159 7.14461C10.7816 6.55723 8.33155 7.79398 7.39229 10.0556" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

  </a>

  <p>{{ description }}</p>
</div>
