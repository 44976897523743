<div class="testimoni" *ngIf="testimoni">
  <website-container>
    <div class="container">
      <div class="description fade-in-up" [class.large]="large">
        <span class="says">{{ testimoni.quote }}</span>
        <div class="profile">
          <div class="img">
            <img [src]="testimoni.photo" [alt]="testimoni.name" />
          </div>
          <div class="identity">
            <span class="bold">{{ testimoni.name }}</span>
            <span class="profession"
              >{{ testimoni.profession }}, {{ testimoni.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </website-container>
</div>
