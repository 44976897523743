import { Component, Input } from '@angular/core'

@Component({
  selector: 'cree8-live-indicator',
  styleUrl: './cree8-live-indicator.component.scss',
  templateUrl: './cree8-live-indicator.component.html',
})
export class Cree8LiveIndicatorComponent {
  @Input() type: 'grey' | 'green' | 'red' = 'grey'
  @Input() showLabel: boolean = true
  @Input() label: string = 'Live'
}
