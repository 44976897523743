<div class="bebop-pagination" [class.hidden]="total <= 1">
  <!-- <div class="page-item" [class.disabled]="!canMoveToPreviousPage" (click)="moveToFirstPage()">
<svg viewBox="0 0 24 24" fill="none" >
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.53 7.28a.75.75 0 0 0-1.06-1.06l-5.25 5.25a.75.75 0 0 0 0 1.06l5.25 5.25a.75.75 0 1 0 1.06-1.06L7.81 12l4.72-4.72Zm5 0a.75.75 0 0 0-1.06-1.06l-5.25 5.25a.75.75 0 0 0 0 1.06l5.25 5.25a.75.75 0 1 0 1.06-1.06L12.81 12l4.72-4.72Z" fill="#fff"/>
</svg>
  </div> -->
  <div class="page-item noselect" [class.disabled]="!canMoveToPreviousPage" (click)="moveToPreviousPage()">
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M13.25 8.75 9.75 12l3.5 3.25"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>

  <div
    class="page-item noselect small index"
    [class.selected]="item.selected"
    *ngFor="let item of pageItems"
    (click)="moveTo(item.label)"
  >
    {{ item.label }}
  </div>

  <div class="page-item noselect" [class.disabled]="!canMoveToNextPage" (click)="moveToNextPage()">
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="m10.75 8.75 3.5 3.25-3.5 3.25"
        stroke="#fff"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <!-- <div class="page-item" [class.disabled]="!canMoveToNextPage" (click)="moveToLastPage()">
<svg viewBox="0 0 24 24" fill="none" >
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.47 7.28a.75.75 0 0 1 1.06-1.06l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 0 1-1.06-1.06L11.19 12 6.47 7.28Zm5 0a.75.75 0 0 1 1.06-1.06l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L16.19 12l-4.72-4.72Z" fill="#fff"/>
</svg>
  </div> -->
</div>
