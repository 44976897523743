import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { catchError, throwError } from 'rxjs'
import { UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { RocketService } from 'src/app/services/rocket/rocket.service'

import { ICardActionsList } from '../card-actions-list/card-actions-list.component'

export type IFileAction = 'delete' | 'receive-link' | 'share' | 'rename' | 'media-info' | 'download-file' | 'detail'

@Component({
  selector: 'cree8-card-file',
  styleUrl: './card-file.component.scss',
  templateUrl: './card-file.component.html',
})
export class CardFileComponent implements OnInit {
  @Input() type: 'grid' | 'list' = 'grid'
  @Input() file: UiDownloadFile = {} as UiDownloadFile
  @Input() cover: string = 'assets/img/pre-set-up-cover.svg'
  @Input() activeProject: any
  @Output() actionFile: EventEmitter<{
    type: ICardActionsList
    data: any
  }> = new EventEmitter<{ type: ICardActionsList; data: any }>()

  showActionButtons = false
  streamUrl: string = ''

  constructor(private rocketService: RocketService) {}

  ngOnInit() {}

  deleteFile(file: UiDownloadFile) {
    this.actionFile.emit({ data: file, type: 'delete' })
  }

  mappingIcon(type: string) {
    if (type === 'folder') {
      return `Folder_Single`
    }

    if (type === 'audio') {
      return `Audio`
    }

    if (type === 'video') {
      return `Play`
    }

    return `Generic_File`
  }

  actionsList(): Array<ICardActionsList> {
    if (this.file.type === 'folder') {
      return ['receive-link', 'rename', 'delete']
    }
    const options: ICardActionsList[] = ['download-file', 'share', 'delete']
    if (this.file.media) {
      options.push('media-info')
    }
    return options
  }

  onAction(props: { action: ICardActionsList; data: any }) {
    this.actionFile.emit({ data: this.file, type: props.action })
  }

  mappingSize(file: any): {
    size: string
    storage: string
  } {
    const storagePercentage = (file.size / file.project.folderSize.bytes) * 100
    const storageValue = isNaN(storagePercentage) ? '-' : storagePercentage.toFixed(1).toString() + '%'

    return {
      size: file.sizeBytes || '-',
      storage: storageValue,
    }
  }

  onOpenDropdown(file: UiDownloadFile) {
    if (!file.ui) file.ui = { dropDownActive: true }
    else file.ui.dropDownActive = true
  }

  onCloseDropdown(file: UiDownloadFile) {
    if (!file.ui) file.ui = { dropDownActive: false }
    else file.ui.dropDownActive = false
  }

  getStreamMedia(file: any) {
    this.rocketService
      .downloadFile(file)
      .pipe(
        catchError((error) => {
          console.log(error)
          return throwError(() => new Error(error))
        })
      )
      .subscribe({
        error: (err) => {
          console.error('Error downloading video:', err)
        },
        next: (res) => {
          this.streamUrl = res
        },
      })
  }
}
