import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'cree8-card-member',
  styleUrls: ['./cree8-card-member.component.scss'],
  templateUrl: './cree8-card-member.component.html',
})
export class Cree8CardMemberComponent implements OnInit {
  constructor() {}

  @Input('name') name: string
  @Input('image') image: string
  @Input('email') email: string
  @Input('status') status: string = 'away'

  @Output() copyEmailClick = new EventEmitter<void>()

  ngOnInit(): void {}

  onCopyEmail(): void {
    this.copyEmailClick.emit()
  }
}
