import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {
  private basePath = 'api/v1/invites/org-invite'

  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getInvites(params?: any): Observable<any> {
    params = params || {}
    params.sort = params.sort || '-date_created'

    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/${this.basePath}/list`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getInvites', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  validateEmail(data: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/${this.basePath}/validate-email`, data).pipe(
      catchError((error: any) => {
        console.error('On validateEmail', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  sendInvite(data: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/${this.basePath}/send`, data).pipe(
      catchError((error: any) => {
        console.error('On sendInvite', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  resendInvite(data: any): Observable<any> {
    return this.http.post<any>(`${this.bebopConfig.apiUrl}/${this.basePath}/resend`, data).pipe(
      catchError((error: any) => {
        console.error('On resendInvite', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateInvite(data: any): Observable<any> {
    return this.http.put<any>(`${this.bebopConfig.apiUrl}/${this.basePath}/update`, data).pipe(
      catchError((error: any) => {
        console.error('On updateInvite', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
