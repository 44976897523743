import { ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core'
import { UtilsService } from '../../services/utils.service'

/**
 
 <bebop-link-button (on-click)="onDownload('PCoIP')" underline="true">PCoIP Client</bebop-link-button>

 */
@Component({
  selector: 'bebop-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss'],
})
export class LinkButtonComponent implements OnInit {
  isIconLarge = false
  isIconSmall = false
  isUnderlined = false

  @Output('on-click') click = new EventEmitter<MouseEvent>()
  @Input('disabled') disabled: string | boolean

  @Input('icon-size') iconSize: 'small' | 'large'
  @Input('size') size: 'xsmall' | 'small' | 'large'

  @Input('underline') underline: string

  @Input('tab-index') tabIdx: number

  get textSize() {
    return this.size ? this.size : 'large'
  }


  @HostBinding('tabindex') tabindex = 0

  @HostListener('focus')
  focusHandler() {}

  @HostListener('keydown.enter', ['$event']) 
  onKeydown(e: KeyboardEvent) {
    this.onClick(<MouseEvent><unknown>e)
  }


  get isDisabled() {
    return this.disabled == 'disabled' || this.disabled == 'true' || this.disabled == true
  }

  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {}

  ngOnInit(): void {
    this.tabindex = this.tabIdx || 0
    this.isIconLarge = this.iconSize == 'large'
    this.isIconSmall = this.iconSize == 'small'
    this.isUnderlined = this.underline == 'true'
  }

  onClick(e: MouseEvent) {
    if (this.isDisabled) return
    this.click.emit(e)
  }
}
