<cree8-modal class="user-invite-modal" [size]="'large'" *ngIf="active" [modal]="modal" (cancelModal)="onCancel()"
  (next)="onNext()">
  <!-- Step 1 Content -->
  <div class="email-selection" *ngIf="modal.current === 1">
    <div *ngIf="entitlementsAll.length > 1">
      <label class="captions" for="name">Select a subscription:</label>
      <bebop-input-select class="input-select-org" placeholder="Select subscription"
        [value]="selected.entitlement?.name || selected.entitlement?.description" selectOnly="true">
        <div dropdown-options>
          <bebop-input-select-option *ngFor="let entitlement of entitlementsAll"
            (click)="onSelectEntitlement(entitlement)">
            <div option-text>
              {{entitlement?.name || entitlement?.description }}
            </div>
          </bebop-input-select-option>
        </div>
      </bebop-input-select>
    </div>

    <div>
      <cree8-input-chips [multiline]="true" [showLabel]="true" placeholder="e.g. name@email.com" [autofocus]="true"
        [validate]="isValidEmail" [(tags)]="emails" (tagsChange)="limitEmails()">
        <div class="captions" input-label>Enter emails</div>
      </cree8-input-chips>
      <!-- <span class="captions" *ngIf="selected.entitlement">{{selected.entitlement.availableSeats}} seats available</span> -->
    </div>

    <div>


      <div class="role-list" *ngIf="entitlementGroups.length > 0">
        <h3 class="captions">Select roles</h3>
        <ul class="scrollbar">
          <li *ngFor="let group of entitlementGroups">
            <cree8-checkbox [label]="group.name" [isChecked]="this.selected.entitlementGroups.includes(group._id)"
              (isCheckedChange)="onSelectGroup($event, group)">
            </cree8-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Step 2 Content -->
  <div class="project-selection" *ngIf="modal.current === 2">
    <div>
      <h3 class="captions">Access to Projects</h3>
      <div class="allowAll">
        <div class="body2">Grant access to all projects</div> <cree8-toggle [checked]="false"
          (checkedChange)="toggleAllProjects($event)"></cree8-toggle>
      </div>
    </div>
    <div class="project-list" *ngIf="!allProjectsSelected">
      <h3 class="captions">Projects</h3>
      <ul class="scrollbar">
        <li *ngFor="let project of projects">
          <cree8-checkbox [label]="project.name" [isChecked]="this.selected.projects.includes(project.id)"
            (isCheckedChange)="onCheckboxChange($event, project)">
          </cree8-checkbox>
        </li>
      </ul>
    </div>
  </div>
</cree8-modal>
