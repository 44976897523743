import { Component } from '@angular/core';

@Component({
  selector: 'website-copyright',
  templateUrl: './website-copyright.component.html',
  styleUrl: './website-copyright.component.scss'
})
export class WebsiteCopyrightComponent {

}
