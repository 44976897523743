import { Component, Input, OnInit } from '@angular/core'

type ServerState = 'Connected' | 'Connecting' | 'Error' | 'Loading'

@Component({
  selector: 'bebop-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input('status') status: ServerState

  @Input('loadingStyles') loadingStyles
  @Input('rounded') rounded = true;

  constructor() {}

  ngOnInit(): void {
    this.status = this.status || 'Connected'
  }
}
