import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { LinkStore, LinkState } from './link.store'
import { map, distinctUntilChanged } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LinkQuery extends Query<LinkState> {
  constructor(protected store: LinkStore) {
    super(store)
  }

  getLink() {
    return this.select()
  }

  getRocketSession() {
    return this.select('session')
  }
}
