import { Injectable } from '@angular/core'
import { UiFileType } from 'src/app/components/rocket/common/classes/rocket-types'

@Injectable({
  providedIn: 'root',
})
export class FileBadgeService {
  constructor() {}

  getFileType(file: string): UiFileType {
    if (!file || typeof file != 'string') return 'others'
    let suffix = file.replace(/.*\./, '')
    // console.log(file, suffix);
    suffix = suffix.toLocaleLowerCase()

    if (suffix.length && suffix[suffix.length - 1] === '/') return 'folder'

    if (file === suffix) return 'others'

    if (suffix === 'pdf') return suffix

    let audioTypes = [
      'wav',
      'bwf',
      'raw',
      'aiff',
      'flac',
      'm4a',
      'pac',
      'tta',
      'wv',
      'ast',
      'aac',
      'mp2',
      'mp3',
      'mp4',
      'amr',
      's3m',
      '3gp',
      'act',
      'au',
      'dct',
      'dss',
      'gsm',
      'm4p',
      'mmf',
      'mpc',
      'ogg',
      'oga',
      'opus',
      'ra',
      'sln',
      'vox',
    ]

    let videoTypes = [
      '3g2',
      '3gp',
      'aaf',
      'asf',
      'avchd',
      'avi',
      'drc',
      'flv',
      'm2v',
      'm4p',
      'm4v',
      'mkv',
      'mng',
      'mov',
      'mp2',
      'mp4',
      'mpe',
      'mpeg',
      'mpg',
      'mpv',
      'mxf',
      'nsv',
      'ogg',
      'ogv',
      'qt',
      'rm',
      'rmvb',
      'roq',
      'svi',
      'vob',
      'webm',
      'wmv',
      'yuv',
    ]

    let archiveTypes = ['7z', 'bz2', 'gz', 'rar', 'tar', 'zip', 'xz', 'gz']

    // differentiate code type for code icon?
    let txtTypes = [
      'applescript',
      'asp',
      'aspx',
      'atom',
      'bashrc',
      'bat',
      'bbcolors',
      'bib',
      'bowerrc',
      'c',
      'cc',
      'cfc',
      'cfg',
      'cfm',
      'cmd',
      'cnf',
      'coffee',
      'conf',
      'cpp',
      'cson',
      'css',
      'csslintrc',
      'csv',
      'curlrc',
      'cxx',
      'diff',
      'eco',
      'editorconfig',
      'ejs',
      'emacs',
      'eml',
      'erb',
      'erl',
      'eslintignore',
      'eslintrc',
      'gemrc',
      'gitattributes',
      'gitconfig',
      'gitignore',
      'go',
      'gvimrc',
      'h',
      'haml',
      'hbs',
      'hgignore',
      'hpp',
      'htaccess',
      'htm',
      'html',
      'iced',
      'ini',
      'ino',
      'irbrc',
      'itermcolors',
      'jade',
      'js',
      'jscsrc',
      'jshintignore',
      'jshintrc',
      'json',
      'jsonld',
      'jsx',
      'less',
      'log',
      'ls',
      'm',
      'markdown',
      'md',
      'mdown',
      'mdwn',
      'mht',
      'mhtml',
      'mkd',
      'mkdn',
      'mkdown',
      'nfo',
      'npmignore',
      'npmrc',
      'nvmrc',
      'patch',
      'pbxproj',
      'pch',
      'php',
      'phtml',
      'pl',
      'pm',
      'properties',
      'py',
      'rb',
      'rdoc',
      'rdoc_options',
      'ron',
      'rss',
      'rst',
      'rtf',
      'rvmrc',
      'sass',
      'scala',
      'scss',
      'seestyle',
      'sh',
      'sls',
      'sql',
      'sss',
      'strings',
      'styl',
      'stylus',
      'sub',
      'sublime-build',
      'sublime-commands',
      'sublime-completions',
      'sublime-keymap',
      'sublime-macro',
      'sublime-menu',
      'sublime-project',
      'sublime-settings',
      'sublime-workspace',
      'svg',
      'terminal',
      'tex',
      'text',
      'textile',
      'tmLanguage',
      'tmTheme',
      'tsv',
      'txt',
      'vbs',
      'vim',
      'viminfo',
      'vimrc',
      'webapp',
      'xht',
      'xhtml',
      'xml',
      'xsl',
      'yaml',
      'yml',
      'zsh',
      'zshrc',
    ]

    let imgTypes = [
      'ase',
      'art',
      'bmp',
      'blp',
      'cd5',
      'cit',
      'cpt',
      'cr2',
      'cut',
      'dds',
      'dib',
      'djvu',
      'egt',
      'exif',
      'gif',
      'gpl',
      'grf',
      'icns',
      'ico',
      'iff',
      'jng',
      'jpeg',
      'jpg',
      'jfif',
      'jp2',
      'jps',
      'lbm',
      'max',
      'miff',
      'mng',
      'msp',
      'nitf',
      'ota',
      'pbm',
      'pc1',
      'pc2',
      'pc3',
      'pcf',
      'pcx',
      'pdn',
      'pgm',
      'PI1',
      'PI2',
      'PI3',
      'pict',
      'pct',
      'pnm',
      'pns',
      'ppm',
      'psb',
      'psd',
      'pdd',
      'psp',
      'px',
      'pxm',
      'pxr',
      'qfx',
      'raw',
      'rle',
      'sct',
      'sgi',
      'rgb',
      'int',
      'bw',
      'tga',
      'tiff',
      'tif',
      'vtf',
      'xbm',
      'xcf',
      'xpm',
      '3dv',
      'amf',
      'ai',
      'awg',
      'cgm',
      'cdr',
      'cmx',
      'dxf',
      'e2d',
      'egt',
      'eps',
      'fs',
      'gbr',
      'odg',
      'svg',
      'stl',
      'vrml',
      'x3d',
      'sxd',
      'v2d',
      'vnd',
      'wmf',
      'emf',
      'art',
      'xar',
      'png',
      'webp',
      'jxr',
      'hdp',
      'wdp',
      'cur',
      'ecw',
      'iff',
      'lbm',
      'liff',
      'nrrd',
      'pam',
      'pcx',
      'pgf',
      'sgi',
      'rgb',
      'rgba',
      'bw',
      'int',
      'inta',
      'sid',
      'ras',
      'sun',
      'tga',
    ]

    if (audioTypes.indexOf(suffix) !== -1) return 'audio'

    if (videoTypes.indexOf(suffix) !== -1) return 'video'

    if (archiveTypes.indexOf(suffix) !== -1) return 'zip'

    if (txtTypes.indexOf(suffix) !== -1) return 'document'

    if (imgTypes.indexOf(suffix) !== -1) return 'image'

    return 'others'
  }

  getFileBadgeType(f: any): UiFileType {
    if (f.badgeType) return f.badgeType
    f.badgeType = f.dir ? 'folder' : this.getFileType(f.name || f.file)
    return f.badgeType
  }

  getPinnedFileBadgeType(f: string): UiFileType {
    return f.match(/.*\.[^\\/]{1,}$/) ? this.getFileType(f) : 'folder'
  }
}
