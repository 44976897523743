import { Component, Input } from '@angular/core';

@Component({
  selector: 'website-pre-installed',
  templateUrl: './website-pre-installed.component.html',
  styleUrl: './website-pre-installed.component.scss'
})
export class WebsitePreInstalledComponent {
  @Input() path = 'assets/img/content-web/software-logos/';
  @Input() listImages = [
    '3d_max',
    'adobe-after-effects',
    'adobe-animator',
    'adobe-audotion',
    'adobe-encoder',
    'adobe-illustrator',
    'adobe-indesign',
    'adobe-lightroom',
    'adobe-photoshop',
    'adobe-pro',
    'blender',
    'Boris-FX',
    'cinema',
    'davinchi',
    'ffmpeg',
    'fusion',
    'katana',
    'mari',
    'maya',
    'modo',
    'mpeg-streamclip',
    'nuke',
    'Red-GIant',
    'V-copilot',
    'V-Enhance',
  ];

  images = this.listImages.map((image) => {
    const baseName = image;
    const src1x = `${this.path}${baseName}@1x.png`;
    const src2x = `${this.path}${baseName}@2x.png`;
    const src3x = `${this.path}${baseName}@3x.png`;
    const altText = baseName.replace(/[-_]/g, ' ').replace('.png', '');

    return {
      src: src1x,
      srcset: `${src1x} 1x, ${src2x} 2x, ${src3x} 3x`,
      alt: altText,
    };
  });

  imagesReversed = [...this.images].reverse();
}
