import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core'

@Directive({
  selector: '[cree8-tooltip-text]',
})
export class Cree8TooltipDirective {
  @Input('cree8-tooltip-text') tooltipText: string = ''
  @Input('cree8-tooltip-position') position: 'top' | 'bottom' | 'left' | 'right' = 'top'
  @Input('cree8-tooltip-delay') delay: number = 500
  tooltip!: HTMLElement | null
  offset = 10

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip && this.tooltipText !== '') {
      this.show()
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide()
    }
  }

  ngOnDestroy() {
    if (this.tooltip) {
      this.removeTooltip()
    }
  }

  private removeTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip)
      this.tooltip = null
    }
  }

  show() {
    this.create()
    this.setPosition()
    this.renderer.addClass(this.tooltip, 'show')
  }

  hide() {
    this.renderer?.removeClass(this.tooltip, 'show')
    window.setTimeout(() => {
      if (this.tooltip) this.renderer?.removeChild(document.body, this.tooltip)
      this.tooltip = null
    }, this.delay)
  }

  create() {
    this.tooltip = this.renderer.createElement('span')

    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipText))

    this.renderer.appendChild(document.body, this.tooltip)

    this.renderer.addClass(this.tooltip, 'captions')
    this.renderer.addClass(this.tooltip, 'cree8-tooltip')
    this.renderer.addClass(this.tooltip, `${this.position}`)

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`)
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`)
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`)
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`)
    this.renderer.setStyle(this.tooltip, 'z-index', `10000`)
  }

  private setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect()

    const tooltipPos: any = this.tooltip?.getBoundingClientRect()

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    let top, left

    if (this.position === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2
    }

    if (this.position === 'bottom') {
      top = hostPos.bottom + this.offset
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2
    }

    if (this.position === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2
      left = hostPos.left - tooltipPos.width - this.offset
    }

    if (this.position === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2
      left = hostPos.right + this.offset
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`)
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`)
  }
}
