import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

import { combineLatest, Subject, takeUntil } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { IOrgSubscriptionDetails, IOrgUsage } from 'src/app/models/organization.model'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { PodsService } from 'src/app/services/pods.service'
import { WatermarkService } from 'src/app/services/watermark.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'
import { workstationTimezoneList } from 'src/app/utils/workstation-utils'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'organization-settings',
  styleUrls: ['./organization-settings.component.scss'],
  templateUrl: './organization-settings.component.html',
})
export class OrganizationSettingsComponent implements OnInit, OnDestroy {
  selectedOrg: any = {}
  orgUsage: any = {}
  subscriptionDetails: IOrgSubscriptionDetails
  watermarkBase64: any = {}
  podWatermarkBase64: string = ''
  organizationForm: FormGroup
  podSettings: FormGroup
  podModel: any
  hasOrgAdminAccess = false

  workstationTimezoneList = workstationTimezoneList
  private destroy$ = new Subject<void>()
  private chargebeeInstance: any

  constructor(
    private uiQuery: UIQuery,
    private sessionQuery: SessionQuery,
    private toastService: ToastService,
    private organizationsService: OrganizationsService,
    private watermarkService: WatermarkService,
    private podService: PodsService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadChargebee()
    const org$ = this.uiQuery.getSelectedOrg()
    const entitlements$ = this.sessionQuery.getEntitlements()

    combineLatest([org$, entitlements$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([org, entitlements]) => {
        if (!org) return

        this.selectedOrg = org
        const orgEntitlements = entitlements[org._id]
        this.hasOrgAdminAccess = orgEntitlements?.ADMIN_ORGANIZATION || false

        this.getOrgData()
        this.loadWatermarks()
        this.getSubscriptionData()
        this.getUsage()
        this.loadPod()
      })

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params['manage'] === 'true') {
        if (this.chargebeeInstance) {
          this.manageSubscription()
        } else {
          const interval = setInterval(() => {
            if (this.chargebeeInstance) {
              clearInterval(interval)
              this.manageSubscription()
            }
          }, 1000)
        }
      }
    })
  }

  manageSubscription() {
    if (!this.chargebeeInstance) {
      this.toastService.show({
        text: 'Unable to open subscription portal. Please try again later.',
        type: 'error',
      })
      return
    }

    this.organizationsService
      .getPortalSession(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: () => {
          this.toastService.show({
            text: 'Unable to open subscription portal. Please try again later.',
            type: 'error',
          })
        },
        next: (result: any) => {
          try {
            const url = typeof result === 'string' ? result : result?.access_url
            const token = new URL(url).searchParams.get('token')

            if (!token) {
              throw new Error('No token found in response')
            }

            const cbInstance = (window as any).Chargebee.getInstance()
            cbInstance.setPortalSession(() => Promise.resolve({ token }))
            cbInstance.createChargebeePortal().open({
              close: () => {
                setTimeout(() => {
                  this.getUsage()
                  this.getSubscriptionData()
                }, 3500)
              },
            })
          } catch {
            this.toastService.show({
              text: 'Unable to open subscription portal. Please try again later.',
              type: 'error',
            })
          }
        },
      })
  }

  private loadChargebee(): void {
    const script = document.createElement('script')
    script.src = 'https://js.chargebee.com/v2/chargebee.js'
    script.async = true
    script.onload = () => {
      ;(window as any).Chargebee.init({
        site: environment.chargebeeSite,
      })
      this.chargebeeInstance = (window as any).Chargebee.getInstance()
      console.log('Chargebee initialized:', this.chargebeeInstance)
    }
    document.head.appendChild(script)
  }

  getOrgData() {
    this.organizationsService
      .getOrg(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.selectedOrg = null
        } else {
          this.selectedOrg = response
          this.organizationForm = this.fb.group({
            email: [this.selectedOrg.email, [Validators.required, Validators.email]],
            name: [this.selectedOrg.name, Validators.required],
            phone: [this.selectedOrg.phone],
            website: [this.selectedOrg.website],
          })
        }
      })
  }

  loadWatermarks() {
    this.organizationsService
      .getWatermarkByOrgId(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.watermarkBase64 = {}
        } else {
          this.watermarkBase64 = response.watermark
        }
      })
  }

  getSubscriptionData() {
    this.organizationsService
      .getOrgSubscriptionDetails(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          console.log('Subscription details error:', error)
          this.subscriptionDetails = null
        },
        next: (response: IOrgSubscriptionDetails) => {
          console.log('response details:', response)
          if (!response) {
            this.subscriptionDetails = null
            return
          }

          // Convert date strings to Date objects
          this.subscriptionDetails = {
            ...response,
            // Make sure amounts is properly initialized if not present
            amounts: response.amounts || {
              addons: 0,
              base: 0,
              discounts: 0,
              finalAmount: 0,
              subtotalBeforeDiscounts: 0,
              total: 0,
            },
            cancellationInfo: response.cancellationInfo
              ? {
                  cancelledAt: new Date(response.cancellationInfo.cancelledAt),
                  validUntil: new Date(response.cancellationInfo.validUntil),
                }
              : null,
            nextPaymentDate: response.nextPaymentDate ? new Date(response.nextPaymentDate) : null,
          }
          console.log('Subscription details:', this.subscriptionDetails)
        },
      })
  }

  getUsage() {
    this.organizationsService
      .getOrgUsage()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          console.log('Usage error:', error)
        },
        next: (response: IOrgUsage) => {
          if (!response) return
          this.orgUsage = response
        },
      })
  }

  updateProfile() {
    if (this.organizationForm.valid) {
      const updatedOrg = {
        ...this.selectedOrg, // existing properties from selectedOrg
        ...this.organizationForm.value, // updated values from the form
      }

      this.organizationsService
        .updateOrg(updatedOrg)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.toastService.show({
              text: 'Organization updated successfully',
              type: 'success',
            })
          }
        })
    }
  }

  loadPodWatermark() {
    this.watermarkService
      .getWatermarkByKey(this.podModel._id, 'DESKTOP_WALLPAPER')
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          console.log(response.error)
        } else {
          this.podWatermarkBase64 = response.watermark
        }
      })
  }

  loadPod() {
    this.podService
      .getPodByOrganization()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          console.log(response.error)
        } else {
          this.podModel = response
          this.podSettings = this.fb.group({
            idleTimeout: [this.podModel.idleTimeout, [Validators.required, Validators.pattern('^[0-9]*$')]],
            workstationTimezone: [this.podModel.appConfigs.workstationTimezone],
          })

          this.loadPodWatermark()
        }
      })
  }

  updatePod() {
    if (this.podSettings.valid) {
      if (this.podSettings.value.workstationTimezone)
        this.podModel.appConfigs.workstationTimezone = this.podSettings.value.workstationTimezone
      this.podModel.idleTimeout = Number(this.podSettings.value.idleTimeout)

      this.podService
        .updateProfile(this.podModel._id, this.podModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.toastService.show({
              text: 'Workstation settings updated',
              type: 'success',
            })
          }
        })
    } else {
      this.toastService.show({
        text: 'Invalid workstation settings',
        type: 'error',
      })
    }
  }

  deleteWatermark(type) {
    const id = type === 'DESKTOP_WALLPAPER' ? this.podModel._id : this.selectedOrg._id
    this.watermarkService
      .deleteWatermarkByKey(id, type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.toastService.show({
            text: response.error.msg,
            type: 'error',
          })
        } else {
          if (type === 'DESKTOP_WALLPAPER') {
            this.loadPodWatermark()
          } else {
            this.loadWatermarks()
          }
          this.toastService.show({
            text: 'Watermark removed successfully',
            type: 'success',
          })
        }
      })
  }

  handleImageUploadSuccess(type: string, base64Image: string): void {
    if (type === 'DESKTOP_WALLPAPER') {
      if (this.podModel)
        this.watermarkService
          .updateWatermarkByKey(this.podModel._id, {
            type,
            watermark: base64Image,
          })
          .subscribe((response) => {
            if (isErrorResponse(response)) {
              this.toastService.show({
                text: response.error.msg,
                type: 'error',
              })
            } else {
              this.loadPodWatermark()
              this.toastService.show({
                text: 'Watermark is saved successfully.',
                type: 'success',
              })
            }
          })
    } else {
      this.organizationsService
        .updateWatermarkByOrgId(this.selectedOrg._id, {
          type,
          watermark: base64Image,
        })
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.loadWatermarks()
            this.toastService.show({
              text: 'Watermark is saved successfully.',
              type: 'success',
            })
          }
        })
    }
  }

  handleImageUploadError(error: string): void {
    console.log(error)
    this.toastService.show({
      text: error,
      type: 'error',
    })
  }

  formatPrice(price: number | undefined) {
    if (!price) return '$0.00'
    return new Intl.NumberFormat('en-US', {
      currency: 'USD',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(price / 100)
  }

  formatTB(value: number | undefined): string {
    if (!value) return '0.00'
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value / 1000)
  }

  formatHours(value: number | undefined): string {
    if (!value) return '0.00'
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value)
  }

  formatCostPerUnit(value: number | undefined): string {
    if (!value) return '0.00'
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value)
  }

  getWaterMarkUrl(type) {
    return 'data:image/png;base64, ' + this.watermarkBase64[type]
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  resetState() {}

  calculateTotal(): number {
    if (!this.subscriptionDetails?.amounts) return 0
    return this.subscriptionDetails.amounts.finalAmount
  }

  toNumber(value: any): number {
    return Number(value) || 0
  }

  abs(value: number): number {
    return Math.abs(value)
  }

  getPlanAmount(): number {
    if (!this.subscriptionDetails?.amounts) return 0
    return this.subscriptionDetails.amounts.base / 100
  }

  calculateWorkstationOverage(): number {
    if (!this.orgUsage?.workstation) return 0

    const usedHours = this.toNumber(this.orgUsage.workstation.used)
    const purchasedHours = this.toNumber(this.orgUsage.workstation.purchased)
    const costPerHour = this.toNumber(this.orgUsage.workstation.costPerUnit)

    if (usedHours <= purchasedHours) return 0

    const overageHours = usedHours - purchasedHours
    const overageCost = overageHours * costPerHour

    return Math.round(overageCost * 100)
  }

  calculateArchiveStorageOverage(): number {
    if (!this.orgUsage?.archiveStorage) return 0

    const usedGB = this.toNumber(this.orgUsage.archiveStorage.used)
    const purchasedGB = this.toNumber(this.orgUsage.archiveStorage.purchased)
    const costPerGB = this.toNumber(this.orgUsage.archiveStorage.costPerUnit)

    if (usedGB <= purchasedGB) return 0

    const overageGB = usedGB - purchasedGB
    const overageCost = overageGB * costPerGB

    return Math.round(overageCost * 100)
  }

  calculateWorkingStorageOverage(): number {
    if (!this.orgUsage?.workingStorage) return 0

    const usedGB = this.toNumber(this.orgUsage.workingStorage.used)
    const purchasedGB = this.toNumber(this.orgUsage.workingStorage.purchased)
    const costPerGB = this.toNumber(this.orgUsage.workingStorage.costPerUnit)

    if (usedGB <= purchasedGB) return 0

    const overageGB = usedGB - purchasedGB
    const overageCost = overageGB * costPerGB

    return Math.round(overageCost * 100)
  }

  calculateCouponDiscount(amount: number, coupon: any): number {
    if (!coupon) return 0

    if (coupon.type === 'percentage') {
      return Math.round((amount * coupon.amount) / 100)
    }

    return coupon.amount // Fixed amount in cents
  }

  calculatePlanDiscount(): number {
    if (!this.subscriptionDetails?.coupons) return 0

    const planCoupons = this.subscriptionDetails.coupons.filter((c) => c.type === 'plan')
    const baseAmount = this.subscriptionDetails?.amounts?.base || 0

    return planCoupons.reduce((total, coupon) => {
      return total + this.calculateCouponDiscount(baseAmount, coupon)
    }, 0)
  }

  calculateInvoiceDiscount(): number {
    if (!this.subscriptionDetails?.coupons) return 0

    const invoiceCoupons = this.subscriptionDetails.coupons.filter((c) => c.type === 'invoice')
    const subtotalBeforeDiscount = this.calculateSubtotalBeforeDiscount()

    return invoiceCoupons.reduce((total, coupon) => {
      return total + this.calculateCouponDiscount(subtotalBeforeDiscount, coupon)
    }, 0)
  }

  calculateSubtotalBeforeDiscount(): number {
    if (!this.subscriptionDetails?.amounts) return 0

    const baseAmount = this.subscriptionDetails.amounts.base // $2,499.00
    const addonsAmount = (this.subscriptionDetails.addons || []).reduce((sum, addon) => sum + addon.price, 0) // $350.00
    const workstationOverage = this.calculateWorkstationOverage() // $1,350.00
    const archiveStorageOverage = this.calculateArchiveStorageOverage() // $1,000.00
    const workingStorageOverage = this.calculateWorkingStorageOverage() // $1,400.00

    return baseAmount + addonsAmount + workstationOverage + archiveStorageOverage + workingStorageOverage // $6,599.00
  }

  calculateSubtotal(): number {
    const subtotalBeforeDiscount = this.calculateSubtotalBeforeDiscount()
    const planDiscount = this.calculatePlanDiscount()
    const invoiceDiscount = this.calculateInvoiceDiscount()

    return subtotalBeforeDiscount - planDiscount - invoiceDiscount
  }

  calculateFinalTotal(): number {
    const subtotalBeforeDiscount = this.calculateSubtotalBeforeDiscount()
    const discountAmount = this.subscriptionDetails?.amounts?.discounts || 0
    return subtotalBeforeDiscount - discountAmount
  }

  getCouponDurationText(coupon: any): string {
    if (!coupon?.durationType) return ''

    switch (coupon.durationType) {
      case 'one_time':
        return '(Only for this Month)'
      case 'limited_period':
        return `(For ${coupon.durationInMonths} months)`
      case 'forever':
        return '(Forever)'
      default:
        return ''
    }
  }
}
