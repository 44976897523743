<card-container
  [fullHeight]="true"
  [footer]="true"
  [class.inverted]="data.actived"
  [negative]="data.actived"
  [titleHeader]="data.titlePromotion"
>
  <div>
    <div class="title-card">
      <span class="name">{{ data.title }}</span>
      <span class="desc" [class.negative]="data.actived" *ngIf="data.titleCategory.length > 0">{{
        data.titleCategory
      }}</span>
    </div>

    <div class="original-price">
      <span class="value">{{ data.originalPrice }}</span>
      <span class="periode">{{ data.originalPeriode }}</span>
    </div>

    <div class="pricing-container">
      <span class="price">{{ data.currentPrice }}</span>
      <span class="interval">{{ data.currentPeriode }}</span>
    </div>

    <div class="feature">
      <span class="name">Up to</span>
      <ul>
        <li *ngFor="let item of data.upto; let index = index">
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3332 1.3335L3.99984 8.66683L0.666504 5.3335"
              [attr.stroke]="data.actived ? 'black' : 'white'"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ item }}

          <ng-container
            *ngIf="checkingTooltip(index) && checkingTooltip(index).length > 0"
          >
            <cree8-tooltip
              [tooltip]="checkingTooltip(index)"
              [variant]="data.actived ? 'dark' : 'light'"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1750_9305)">
                  <circle
                    cx="8"
                    cy="8"
                    r="6"
                    [attr.stroke]="data.actived ? 'black' : 'white'"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.33337 10.3335H8.87358"
                    [attr.stroke]="data.actived ? 'black' : 'white'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.10602 10.3333V7.5H7.33936"
                    [attr.stroke]="data.actived ? 'black' : 'white'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.06686 5.49851C8.06686 5.5906 7.99221 5.66525 7.90012 5.66525C7.80804 5.66525 7.73339 5.5906 7.73339 5.49851C7.73339 5.40643 7.80804 5.33178 7.90012 5.33178"
                    [attr.stroke]="data.actived ? 'black' : 'white'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.90013 5.33165C7.99221 5.33165 8.06686 5.4063 8.06686 5.49838"
                    [attr.stroke]="data.actived ? 'black' : 'white'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1750_9305">
                    <rect
                      width="16"
                      height="16"
                      [attr.fill]="data.actived ? 'black' : 'white'"
                    />
                  </clipPath>
                </defs>
              </svg>
            </cree8-tooltip>
          </ng-container>
        </li>
      </ul>
    </div>

    <div class="for">
      <span class="name">Perfect for</span>
      <p *ngFor="let item of data.for">{{ item }}</p>
    </div>

    <div footer-card>
      <cree8-btn (click)="clickBtn.emit(data)" [class.inverted]="data.actived" [fullwidth]="true"
        >SUBSCRIBE
      </cree8-btn>

      <accordion [variant]="data.actived ? 'light' : 'dark'" title="Features">
        <div class="feature acc">
          <span class="name">{{ data.titlePlus }}</span>
          <ul>
            <li *ngFor="let item of data.plus; let indexPlus = index">
              <span class="plus-description">{{ item }}</span>
              <ng-container
                *ngIf="
                  checkingPlusDescription(indexPlus) &&
                  checkingPlusDescription(indexPlus).length > 0
                "
              >
                <cree8-tooltip
                  [tooltip]="checkingPlusDescription(indexPlus)"
                  [variant]="data.actived ? 'dark' : 'light'"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1750_9305)">
                      <circle
                        cx="8"
                        cy="8"
                        r="6"
                        [attr.stroke]="data.actived ? 'black' : 'white'"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.33337 10.3335H8.87358"
                        [attr.stroke]="data.actived ? 'black' : 'white'"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.10602 10.3333V7.5H7.33936"
                        [attr.stroke]="data.actived ? 'black' : 'white'"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.06686 5.49851C8.06686 5.5906 7.99221 5.66525 7.90012 5.66525C7.80804 5.66525 7.73339 5.5906 7.73339 5.49851C7.73339 5.40643 7.80804 5.33178 7.90012 5.33178"
                        [attr.stroke]="data.actived ? 'black' : 'white'"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.90013 5.33165C7.99221 5.33165 8.06686 5.4063 8.06686 5.49838"
                        [attr.stroke]="data.actived ? 'black' : 'white'"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1750_9305">
                        <rect
                          width="16"
                          height="16"
                          [attr.fill]="data.actived ? 'black' : 'white'"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </cree8-tooltip>
              </ng-container>
            </li>
          </ul>
        </div>

        <div class="feature acc">
          <span class="name">{{ data.titleSupport }}</span>
          <ul>
            <li *ngFor="let item of data.supports">{{ item }}</li>
          </ul>
        </div>
      </accordion>
    </div>
  </div>
</card-container>
