import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable, OnInit } from '@angular/core'

import { Observable } from 'rxjs'
import { Organization } from 'src/app/models/bebop.model'

import { SessionQuery } from '../store/session/session.query'
import { UIQuery } from '../store/ui/ui.query'

@Injectable({
  providedIn: 'root',
})
export class BebopHTTPInterceptor implements HttpInterceptor {
  org: Organization

  constructor(
    private uiQuery: UIQuery,
    private sessionQuery: SessionQuery
  ) {
    this.uiQuery.getSelectedOrg().subscribe((org: Organization) => {
      this.org = org
    })
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('http')) {
      return next.handle(req)
    }

    let allowCredentials = false

    if (
      req.url?.indexOf('cree8.io') != -1 ||
      req.url?.indexOf('intercom.io') != -1 ||
      req.url?.indexOf('pendo.io') != -1
    ) {
      allowCredentials = true
    }

    let params,
      url,
      apiPaths = /\/api\//

    if (apiPaths.test(req.url)) {
      let tempRequest = req
      if (this.org) {
        params = req.params.append('organization', this.org._id)
        tempRequest = req.clone({ params })
      } else {
        // for debugging
        // console.log(`${req.url} request without org`)
      }
      url = new URL(tempRequest.urlWithParams)
    } else {
      url = new URL(req.urlWithParams)
    }

    // add the token in all requests
    let csrfToken = this.sessionQuery.getTokenValue()
    let clonedRequest
    if (csrfToken) {
      clonedRequest = req.clone({
        headers: req.headers.append('x-csrf-token', csrfToken),
        params,
        withCredentials: allowCredentials,
      })
    } else {
      clonedRequest = req.clone({ params, withCredentials: allowCredentials })
    }

    return next.handle(clonedRequest)
  }
}
