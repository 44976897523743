<button
[ngClass]="generateClass"
[disabled]="disabled || loading"
[attr.aria-label]="ariaLabel"
(click)="handleClick($event)"
[class.fullwidth]="fullwidth"
>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle cx="12" cy="12" r="9" stroke="#EAECEE" stroke-width="2.5"/>
    <path d="M21 12C21 7.02944 16.9706 3 12 3" stroke="#14141A" stroke-width="2.5" stroke-linecap="round"/>
  </svg>
  <div class="button__text">
    <ng-content></ng-content>
  </div>
</button>
