import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface LogoutAction {
  name: 'Cancel' | 'Close' | 'Ok'
}

@Component({
  selector: 'bebop-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnExternalModalClose, OnDestroy {
  timerHandle = -1

  constructor(public ref: ModalOverlayRef<LogoutComponent, LogoutAction>, @Inject(BEBOP_MODAL_DATA) public data: any) {}

  ngOnInit(): void {
    this.timerHandle = window.setTimeout(() => this.ref?.close(), 15 * 1000)
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timerHandle)
    this.ref?.close()
  }

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }
}
