import { NgModule } from '@angular/core'
import { BebopHTTPInterceptor } from './interceptor.service'
import { BebopConfigService } from './bebop-config.service'
import { ElectronService } from './electron.service'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { MainService } from './main.service'

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    MainService,
    BebopConfigService,
    ElectronService,
    { provide: HTTP_INTERCEPTORS, useClass: BebopHTTPInterceptor, multi: true },
  ],
  exports: [],
})
export class ServicesModule {}
