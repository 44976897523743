import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { UtilsService } from '../../services/utils.service'

interface TagWithError {
  tag: string
  error: boolean
  message?: string
}

@Component({
  selector: 'bebop-input-tags',
  templateUrl: './input-tags.component.html',
  styleUrls: ['./input-tags.component.scss'],
})
export class InputTagsComponent implements OnInit, AfterViewInit {
  @Input('autofocus') autofocus: string
  @Input('placeholder') placeholder: string
  @Input('disabled') disabled: string | boolean
  @Input('multiline') multiline: string
  @Input('noduplicate') noDuplicate: string
  @Input('contained-layout') containedLayout: string | boolean
  @Input('tooltip-left-offset-percent-value') tooltipLeftOffsetValue: number | string

  @Input('tags') tags: string[]
  @Input('validate') validate: (s: string, arr: string[]) => boolean | string
  @Output('tagsChange') onChange = new EventEmitter<string[]>()

  @Output('enter') onEnter = new EventEmitter<string>()

  @ViewChild('inputTagLabel') inputTagLabel

  get isDisabled() {
    return this.util.isTrueLike(this.disabled)
  }

  showInputLabel = true
  isAutoFocused = true
  isMultilined = true

  isContainedLayout = false

  tag = ''
  all: TagWithError[] = []

  lastKey = ''
  lastTs = 0
  lastTag = ''

  constructor(private cdRef: ChangeDetectorRef, private el: ElementRef, private util: UtilsService) {}

  ngOnInit(): void {
    this.isAutoFocused = this.autofocus == 'true'
    this.isMultilined = this.multiline == 'true' || !this.multiline

    this.isContainedLayout = this.util.isTrueLike(this.containedLayout)

    this.placeholder = this.placeholder || ''

    this.all = this.tags.map((tag) => ({ tag, error: false }))

    if (!this.validate) {
      console.error('<bebop-input-tags> validate attribute is missing')
    }
  }

  ngAfterViewInit() {
    this.showInputLabel = this.inputTagLabel?.nativeElement?.children?.length > 0
    this.cdRef.detectChanges()
  }

  _validate() {
    try {
      // True when validate is not provided
      return this.validate ? this.validate(this.tag, this.tags) : true
    } catch (e) {
      console.error('validate callback error', e.message, e.stack)
    }

    return false
  }

  onRemoveTag(pos: number) {
    if (pos < 0) return
    this.tags.splice(pos, 1)
  }

  onRemoveTagWithError(pos: number) {
    if (pos < 0) return
    let [e] = this.all.splice(pos, 1)
    if (!e.error) {
      let i = this.tags.findIndex((t) => t == e.tag)
      if (i != -1) this.onRemoveTag(i)
    }
  }

  onKeyup(ev: KeyboardEvent) {
    if (ev.key === 'Backspace') {
      if (this.lastKey == ev.key && this.tag == '' && this.lastTag == '' && Date.now() - this.lastTs < 1000) {
        this.lastKey = ''
        this.lastTs = Date.now()

        if (this.all.length) {
          this.onRemoveTagWithError(this.all.length - 1)
        }
        return
      }
    }

    this.lastKey = ev.key
    this.lastTs = Date.now()
    this.lastTag = this.tag

    if (ev.key === 'Enter') {
      // TODO handle whitespace ? input document does it ?

      if (this.tags?.includes(this.tag) && this.noDuplicate != 'true') {
        // duplicate case
        console.error('Ignore duplicate tag', this.tag, this.tags)
        return
      }

      let reply = this._validate()
      if (reply == true) {
        this.onEnter.emit(this.tag)
        this.tags.push(this.tag)
        this.onChange.emit(this.tags)
      }

      this.all.push({ tag: this.tag, error: reply != true, message: reply == true ? '' : reply || 'Invalid tag' })

      this.tag = ''

      if (this.isMultilined) return
      let e = this.el.nativeElement?.children[0]?.children[1]?.children[0]

      setTimeout(() => e?.scrollTo({ left: e?.scrollWidth + 10000, behavior: 'smooth' }), 100)
    }
  }
}
