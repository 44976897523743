<div class="container">
  <bebop-loader [status]="'Loading'" *ngIf="isLoading"></bebop-loader>
  <ng-container *ngIf="step === 1">
    <div class="container">
      <bebop-input
        type="text"
        [autofocus]="false"
        placeholder="Name"
        [(binding)]="name"
      >
        <div input-label class="email-label">Name</div>
      </bebop-input>
      <bebop-input
        type="text"
        [autofocus]="false"
        placeholder="File"
        [(binding)]="file"
        [disabled]="true"
      >
        <div
          input-label
          class="email-label"
          [innerText]="receiveLink ? 'Path' : 'File'"
        ></div>
      </bebop-input>
      <link-expiration #linkExpirationComponent></link-expiration>
      <ng-container *ngIf="receiveLink">
        <p class="body3">Allow Unsafe Files Notes</p>
        <cree8-toggle
          [checked]="unSafeFiles"
          (checkedChange)="onChangeUnsafeFiles()"
          [ariaLabel]="'AllowUnsafe Files'"
        ></cree8-toggle>
      </ng-container>

      <bebop-input
        [textarea]="true"
        [autofocus]="false"
        placeholder="Notes for the link"
        [(binding)]="notes"
      >
        <div input-label class="email-label">Notes for the link</div>
      </bebop-input>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <link-protect #linkProtectComponent></link-protect>
  </ng-container>

  <ng-container *ngIf="step === 3">
    <div class="result-link">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Checkmark"></use>
        </svg>
      </div>
      <div class="body3">Your Share link has been successfully created.</div>
      <div class="link-copy body3">
        {{ linkResult.fullLink }}
      </div>
    </div>
  </ng-container>
</div>
