<div class="bebop-input-widget" [class]="{ disabled: isDisabled && !isSkipDisabledStyle }">
  <div class="input-top-section" tabindex="-1" *ngIf="showInputLabel">
    <div class="input-label small">
      <ng-content select="[input-label]"></ng-content>
    </div>

    <div class="input-guide small" #inputGuide tabindex="-1" *ngIf="showInputGuide">
      <ng-content select="[input-guide]"></ng-content>
    </div>
  </div>

  <div class="input-main-section" [class]="{ error: error == 'true' || error === true, borderless: !border }">
    <div
      class="input-main-icon"
      #inputIconLeft
      *ngIf="showInputIconLeft"
      [class]="{ filled: binding && (binding + '').length != 0 }"
    >
      <ng-content select="[input-icon-left]"></ng-content>
    </div>

    <div class="icon-separator" *ngIf="isLeftIconSeparator"></div>

    <div class="input-main-editor">
      <ng-container *ngIf="textarea">
        <textarea
          [attr.cols]="textareaCols || 80"
          [attr.rows]="textareaRows || 4"
          [class]="textareaResize || 'none'"
          [bebopAutoFocus]="isAutoFocused"
          [placeholder]="placeholder"
          [disabled]="isDisabled"
          class="input-text"
          [(ngModel)]="binding"
          (keyup)="onChangeUserInput(); onKeyup($event)"
          (click)="onClick($event)"
          (dblclick)="onDblclick($event)"
          (submit)="onSubmit()"
          (blur)="onBlur($event)"
          (focus)="onFocus($event)"
          (scroll)="onScroll($event)"
          (cut)="onCut($event)"
          (copy)="onCopy($event)"
          (paste)="onPaste($event)"
          (keypress)="onKeypress($event)"
          (keydown)="onKeydown($event)"
          (mouseup)="onMouseup($event)"
          (mousedown)="onMousedown($event)"
          (mouseenter)="onMouseenter($event)"
          (drag)="onDrag($event)"
          (drop)="onDrop($event)"
          (dragover)="onDragover($event)"
        >
        </textarea>
      </ng-container>

      <ng-container *ngIf="!textarea">
        <input
          [bebopAutoFocus]="isAutoFocused"
          [type]="hidePasswordField ? type : 'text'"
          [placeholder]="placeholder"
          [disabled]="isDisabled"
          class="input-text"
          [(ngModel)]="binding"
          (keyup)="onChangeUserInput(); onKeyup($event)"
          (click)="onClick($event)"
          (dblclick)="onDblclick($event)"
          (submit)="onSubmit()"
          (blur)="onBlur($event)"
          (focus)="onFocus($event)"
          (scroll)="onScroll($event)"
          (cut)="onCut($event)"
          (copy)="onCopy($event)"
          (paste)="onPaste($event)"
          (keypress)="onKeypress($event)"
          (keydown)="onKeydown($event)"
          (mouseup)="onMouseup($event)"
          (mousedown)="onMousedown($event)"
          (mouseenter)="onMouseenter($event)"
          (drag)="onDrag($event)"
          (drop)="onDrop($event)"
          (dragover)="onDragover($event)"
        />
      </ng-container>
    </div>
    <div class="input-password-icon" *ngIf="isPasswordField" (click)="togglePasswordFieldView()">
      <svg class="eye-open" viewBox="0 0 14 10" fill="none" *ngIf="hidePasswordField">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.05582 4.71338C2.5093 4.1628 3.22659 3.3876 4.106 2.7549C4.99068 2.11841 5.98271 1.66668 6.99991 1.66668C8.01711 1.66668 9.00915 2.11841 9.89383 2.7549C10.7732 3.3876 11.4905 4.1628 11.944 4.71338C12.0855 4.88521 12.0855 5.11481 11.944 5.28664C11.4905 5.83722 10.7732 6.61242 9.89383 7.24512C9.00915 7.88161 8.01711 8.33334 6.99991 8.33334C5.98271 8.33334 4.99068 7.88161 4.106 7.24512C3.22659 6.61242 2.5093 5.83722 2.05582 5.28664C1.91428 5.11481 1.91428 4.88521 2.05582 4.71338ZM6.99991 0.333344C5.59329 0.333344 4.32934 0.951658 3.32732 1.67257C2.32003 2.39728 1.51988 3.26684 1.02664 3.86569C0.479566 4.5299 0.479565 5.47012 1.02664 6.13433C1.51988 6.73318 2.32002 7.60274 3.32732 8.32745C4.32934 9.04836 5.59329 9.66668 6.99991 9.66668C8.40654 9.66668 9.67048 9.04836 10.6725 8.32745C11.6798 7.60274 12.4799 6.73318 12.9732 6.13433C13.5203 5.47012 13.5203 4.5299 12.9732 3.86569C12.4799 3.26684 11.6798 2.39728 10.6725 1.67257C9.67048 0.951658 8.40654 0.333344 6.99991 0.333344ZM5.66663 5.00001C5.66663 4.26363 6.26358 3.66668 6.99996 3.66668C7.73634 3.66668 8.33329 4.26363 8.33329 5.00001C8.33329 5.73639 7.73634 6.33334 6.99996 6.33334C6.26358 6.33334 5.66663 5.73639 5.66663 5.00001ZM6.99996 2.33334C5.5272 2.33334 4.33329 3.52725 4.33329 5.00001C4.33329 6.47277 5.5272 7.66668 6.99996 7.66668C8.47272 7.66668 9.66663 6.47277 9.66663 5.00001C9.66663 3.52725 8.47272 2.33334 6.99996 2.33334Z"
          fill="#5A5A68"
        />
      </svg>

      <svg class="eye-closed" viewBox="0 0 14 14" fill="none" *ngIf="!hidePasswordField">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.52859 0.52859C0.78894 0.268241 1.21105 0.268241 1.4714 0.52859L13.4714 12.5286C13.7317 12.7889 13.7317 13.2111 13.4714 13.4714C13.2111 13.7317 12.7889 13.7317 12.5286 13.4714L9.88925 10.8321C9.04405 11.3132 8.06316 11.6667 6.99995 11.6667C5.59333 11.6667 4.32938 11.0483 3.32735 10.3274C2.32006 9.60273 1.51992 8.73316 1.02667 8.13431C0.479601 7.47011 0.479601 6.52988 1.02667 5.86568C1.46216 5.33695 2.13836 4.59535 2.98635 3.92916L0.52859 1.4714C0.268241 1.21105 0.268241 0.78894 0.52859 0.52859ZM3.93693 4.87974L8.90379 9.8466C8.2924 10.1484 7.65098 10.3333 6.99995 10.3333C5.98275 10.3333 4.99072 9.8816 4.10604 9.24511C3.22662 8.61241 2.50933 7.83721 2.05585 7.28663C1.91432 7.11479 1.91432 6.8852 2.05585 6.71336C2.48069 6.19756 3.13547 5.4866 3.93693 4.87974ZM6.99995 3.66666C6.8112 3.66666 6.62349 3.68215 6.43705 3.71151C6.07335 3.7688 5.73207 3.52039 5.67478 3.15669C5.61749 2.79298 5.8659 2.4517 6.2296 2.39442C6.48092 2.35483 6.73805 2.33333 6.99995 2.33333C8.40657 2.33333 9.67052 2.95164 10.6725 3.67256C11.6798 4.39726 12.48 5.26683 12.9732 5.86568C13.5203 6.52988 13.5203 7.47011 12.9732 8.13431C12.7524 8.40246 12.4722 8.72217 12.1415 9.05771C11.883 9.31994 11.4609 9.32301 11.1987 9.06456C10.9365 8.80611 10.9334 8.38401 11.1918 8.12178C11.4902 7.81907 11.7439 7.52965 11.944 7.28663C12.0856 7.11479 12.0856 6.8852 11.944 6.71336C11.4906 6.16278 10.7733 5.38758 9.89386 4.75488C9.00918 4.11839 8.01715 3.66666 6.99995 3.66666ZM5.71214 7.34509C5.61685 6.98945 5.25129 6.77839 4.89565 6.87369C4.54 6.96898 4.32895 7.33454 4.42424 7.69018C4.5454 8.14233 4.78343 8.55462 5.11443 8.88562C5.44542 9.21661 5.85771 9.45465 6.30986 9.5758C6.66551 9.6711 7.03106 9.46004 7.12636 9.1044C7.22165 8.74875 7.0106 8.38319 6.65495 8.2879C6.42888 8.22732 6.22273 8.1083 6.05724 7.94281C5.89174 7.77731 5.77272 7.57116 5.71214 7.34509Z"
          fill="#5A5A68"
        />
      </svg>
    </div>
    <div class="input-main-icon" #inputIconRight *ngIf="showInputIconRight">
      <ng-content select="[input-icon-right]"></ng-content>
    </div>

    <div
      class="input-clear-icon"
      *ngIf="isClearActionEnabled && binding && (binding + '').length != 0"
      (click)="clearUserInput()"
    >
      <svg viewBox="0 0 20 20" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.59 4.41a.833.833 0 0 0-1.18 1.18L8.822 10l-4.41 4.41a.833.833 0 1 0 1.178 1.18L10 11.177l4.412 4.411a.833.833 0 1 0 1.178-1.178L11.18 10l4.41-4.41a.833.833 0 0 0-1.178-1.18L10 8.822l-4.41-4.41Z"
          fill="#5A5A68"
        />
      </svg>
    </div>
  </div>

  <div class="input-bottom-section" [class.no-error]="!error" *ngIf="inputErrorLabelAvailable">
    <!-- error section -->
    <div class="input-error">
      <ng-content select="[input-error-icon]" #inputErrorIcon *ngIf="inputErrorIconAvailable && error"></ng-content>
      <div class="input-error-icon-default" *ngIf="!inputErrorIconAvailable && inputErrorLabelAvailable && error">
        <svg viewBox="0 0 12 12" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.133 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0V4Zm-.5 3.5a.5.5 0 0 0 0 1h.005a.5.5 0 0 0 0-1h-.005Z"
            fill="#F1344B"
            fill-opacity=".8"
          />
        </svg>
      </div>
      <div class="description error-description" #inputErrorLabel tabindex="-1" *ngIf="inputErrorLabelAvailable">
        <ng-content select="[input-error-label]"></ng-content>
      </div>
    </div>
  </div>
</div>
