import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core'

@Component({
  selector: 'cree8-image-upload',
  styleUrls: ['./cree8-image-upload.component.scss'],
  templateUrl: './cree8-image-upload.component.html',
})
export class Cree8ImageUploadComponent {
  @ViewChild('uploadImage', { static: true }) uploadImage!: ElementRef

  @Input() label: string = 'Upload' // Default label
  @Input() maxWidth: number = 500 // Default width
  @Input() maxHeight: number = 500 // Default height
  @Input() acceptedFileType: string = 'image/png, image/jpeg' // Accept PNG by default

  @Output() imageUploadSuccess: EventEmitter<string> = new EventEmitter<string>() // Base64 output to parent
  @Output() imageUploadError: EventEmitter<string> = new EventEmitter<string>() // Error message emitter

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.assignBrowse()
  }

  assignBrowse(): void {
    const node = this.uploadImage.nativeElement
    let input = this.renderer.createElement('input')
    this.renderer.setAttribute(input, 'type', 'file')
    this.renderer.setAttribute(input, 'accept', this.acceptedFileType)
    this.renderer.setStyle(input, 'visibility', 'hidden')
    this.renderer.setStyle(input, 'position', 'absolute')
    this.renderer.setStyle(input, 'width', '1px')
    this.renderer.setStyle(input, 'height', '1px')

    this.renderer.appendChild(node, input)

    this.renderer.listen(node, 'click', () => {
      input.click()
    })

    this.renderer.listen(input, 'change', (event: any) => this.onFileSelected(event))
  }

  onFileSelected(event: any): void {
    const files = event.target.files

    if (files.length === 0) {
      return
    }
    const file = files[0]

    if (
      file &&
      !this.acceptedFileType
        .split(',')
        .map((type) => type.trim())
        .includes(file.type)
    ) {
      this.imageUploadError.emit('Invalid file format. Please upload a valid file type.')
      return
    }

    const reader = new FileReader()
    reader.onload = (e: any) => {
      const arrayBuffer = e.target.result
      const array = new Uint8Array(arrayBuffer)
      let imageBase64 = btoa(
        array.reduce(function (acc, byte) {
          return acc + String.fromCharCode(byte)
        }, '')
      )

      const image = new Image()
      image.src = 'data:' + file.type + ';base64,' + imageBase64
      image.onload = () => {
        const wd = image.naturalWidth
        const ht = image.naturalHeight

        if (wd > this.maxWidth || ht > this.maxHeight) {
          this.imageUploadError.emit(
            `Invalid dimension: ${wd}×${ht}. Maximum allowed is ${this.maxWidth}×${this.maxHeight}.`
          )
          return
        }

        this.imageUploadSuccess.emit(imageBase64) // Emit the Base64 to parent
      }
    }
    reader.readAsArrayBuffer(file)
  }
}
