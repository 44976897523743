import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { shareReplay, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class SvgInjectorService {
  private svgCache: { [url: string]: Observable<string> } = {}

  constructor(private http: HttpClient) {}

  fetchSvg(url: string): Observable<string> {
    if (this.svgCache[url]) {
      return this.svgCache[url]
    }

    const svgRequest = this.http.get(url, { responseType: 'text' }).pipe(
      tap(() => {}),
      shareReplay(1)
    )

    this.svgCache[url] = svgRequest

    return svgRequest
  }
}
