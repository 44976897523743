<div class="bebop-input-widget" [class]="{ disabled: isDisabled }">
  <div class="input-top-section" tabindex="-1" *ngIf="showInputLabel">
    <div class="input-label small">
      <ng-content select="[input-label]"></ng-content>
    </div>

    <div class="input-guide small" #inputGuide tabindex="-1" *ngIf="showInputGuide">
      <ng-content select="[input-guide]"></ng-content>
    </div>
  </div>

  <div class="input-main-section" [class]="{ error: error == 'true' || error === true }">
    <div
      class="input-main-icon"
      #inputIconLeft
      *ngIf="showInputIconLeft"
      [class]="{ filled: binding && (binding + '').length != 0 }"
    >
      <ng-content select="[input-icon-left]"></ng-content>
    </div>

    <div class="icon-separator" *ngIf="isLeftIconSeparator"></div>

    <div class="input-main-editor">
      <input
        [bebopAutoFocus]="isAutoFocused"
        [type]="hidePasswordField ? type : 'text'"
        type="tel" id="phone"
        [placeholder]="placeholder"
        [disabled]="isDisabled"
        class="input-text"
        [(ngModel)]="binding.number"
        (keyup)="onChangeUserInput(); onKeyup($event)"
        (click)="onClick($event)"
        (dblclick)="onDblclick($event)"
        (submit)="onSubmit()"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)"
        (scroll)="onScroll($event)"
        (cut)="onCut($event)"
        (copy)="onCopy($event)"
        (paste)="onPaste($event)"
        (keypress)="onKeypress($event)"
        (keydown)="onKeydown($event)"
        (mouseup)="onMouseup($event)"
        (mousedown)="onMousedown($event)"
        (mouseenter)="onMouseenter($event)"
        (drag)="onDrag($event)"
        (drop)="onDrop($event)"
        (dragover)="onDragover($event)"
      />
    </div>
    <div class="input-main-icon" #inputIconRight *ngIf="showInputIconRight">
      <ng-content select="[input-icon-right]"></ng-content>
    </div>

    <div
      class="input-clear-icon"
      *ngIf="isClearActionEnabled && binding && (binding + '').length != 0"
      (click)="clearUserInput()"
    >
      <svg viewBox="0 0 20 20" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.59 4.41a.833.833 0 0 0-1.18 1.18L8.822 10l-4.41 4.41a.833.833 0 1 0 1.178 1.18L10 11.177l4.412 4.411a.833.833 0 1 0 1.178-1.178L11.18 10l4.41-4.41a.833.833 0 0 0-1.178-1.18L10 8.822l-4.41-4.41Z"
          fill="#5A5A68"
        />
      </svg>
    </div>
  </div>

  <div class="input-bottom-section" [class.no-error]="!error" *ngIf="inputErrorLabelAvailable">
    <!-- error section -->
    <div class="input-error">
      <ng-content select="[input-error-icon]" #inputErrorIcon *ngIf="inputErrorIconAvailable && error"></ng-content>
      <div class="input-error-icon-default" *ngIf="!inputErrorIconAvailable && inputErrorLabelAvailable && error">
        <svg viewBox="0 0 12 12" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.133 6a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0V4Zm-.5 3.5a.5.5 0 0 0 0 1h.005a.5.5 0 0 0 0-1h-.005Z"
            fill="#F1344B"
            fill-opacity=".8"
          />
        </svg>
      </div>
      <div class="description error-description" #inputErrorLabel tabindex="-1" *ngIf="inputErrorLabelAvailable">
        <ng-content select="[input-error-label]"></ng-content>
      </div>
    </div>
  </div>
</div>
