import { Component, Input, OnInit } from '@angular/core'

import { BehaviorSubject, interval, Subject, switchMap, takeUntil, timer } from 'rxjs'
import { Organization, Project, User } from 'src/app/models/bebop.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { MainService } from 'src/app/services/main.service'
import { ElasticSearchService } from 'src/app/services/rocket/elastic-search.service'
import { ArchiveQuery } from 'src/app/store/archive/archive.query'
import { SessionQuery } from 'src/app/store/session/session.query.js'
import { UIQuery } from 'src/app/store/ui/ui.query.js'

@Component({
  selector: 'cree8-archive-transfer-status',
  styleUrl: './archive-transfer-status.component.scss',
  templateUrl: './archive-transfer-status.component.html',
})
export class ArchiveTransferStatusComponent implements OnInit {
  private destroy$ = new Subject<void>()

  files = []
  size = 24
  offset = 0
  addSize = 24
  total = 0

  fileStatus = {
    Cancelled: 4,
    Completed: 6,
    Downloading: 7,
    Error: 5,
    Paused: 3,
    Queued: 0,
    Staged: 8,
    Started: 1,
    Uploading: 2,
    Verifying: 9,
  }

  statusNames = [
    'Queued',
    'Started',
    'Uploading',
    'Paused',
    'Cancelled',
    'Error',
    'Completed',
    'Downloading',
    'Staged',
    'Verifying',
  ]

  syncDirections = ['', '⬆ UP', '⬇ DOWN']
  isLoading = false
  isLoadingMore = false
  searchText = ''
  org: Organization
  user: User
  project: Project
  timerStarted = false

  buttonPreviousDisabled$ = new BehaviorSubject<boolean>(true)
  buttonNextDisabled$ = new BehaviorSubject<boolean>(false)

  constructor(
    private mainService: MainService,
    private elasticSearchService: ElasticSearchService,
    private sessionQuery: SessionQuery,
    private archiveQuery: ArchiveQuery,
    private clientUtils: BebopClientUtilsService,
    private uiQuery: UIQuery
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.isLoading = false
    this.searchText = ''

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }

        this.org = org

        this.sessionQuery
          .getUser()
          .pipe(takeUntil(this.destroy$))
          .subscribe((user) => {
            this.user = user

            this.archiveQuery
              .getSelectedProject()
              .pipe(takeUntil(this.destroy$))
              .subscribe((project) => {
                if (!project) return
                this.project = project
                if (this.timerStarted) return

                timer(0, 5000)
                  .pipe(
                    takeUntil(this.destroy$),
                    switchMap(async () => this.getFileTransfer(this.offset, this.size))
                  )
                  .subscribe()
                this.timerStarted = true
              })
          })
      })
  }

  private getFileTransfer(offset: number, size: number) {
    if (!this.mainService.isAppInFocus()) return

    const data = {
      archive: true,
      channel: 3,
      downloadOnly: false,
      offset: offset,
      orgs: [this.org._id],
      size: size,
      sort: [{ lastUpdated: 'desc' }],
      status: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      uploadOnly: false,
      userId: this.user._id,
      view: 1,
      ...(this.searchText?.length > 1 && {
        search: { field: 'file', value: this.searchText },
      }),
    }

    this.elasticSearchService
      .getCompleted({
        data: data,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.files = response.hits
        this.total = response.total
        this.files = this.files.map((file) => ({
          ...file,
          readableSize: this.clientUtils.readablizeBytes(file.size),
        }))
        this.isLoading = false
      })
  }

  calculateStrokeDashArray(percentage: number): string {
    const radius = 16 // Same as 'r' in the circle
    const circumference = 2 * Math.PI * radius // Circle circumference
    const progress = (percentage / 100) * circumference
    return `${progress} ${circumference}` // Remaining part of the circle
  }

  onRefresh() {
    this.size = 24
    this.offset = 0
    this.isLoading = true
    this.buttonPreviousDisabled$.next(true)
    this.getFileTransfer(this.offset, this.size)
  }

  onSearchValue(value: string) {
    this.isLoading = true
    this.searchText = value
    this.size = 24
    this.offset = 0
    this.getFileTransfer(this.offset, this.size)
  }

  onClickPreviousPage() {
    this.isLoading = true
    if (this.offset !== 0) {
      this.offset = Math.max(0, this.offset - this.addSize)
    }

    if (this.offset === 0) {
      this.buttonPreviousDisabled$.next(true)
    }

    if (this.total > this.offset + this.size) {
      this.buttonNextDisabled$.next(false)
    } else {
      this.buttonNextDisabled$.next(true)
    }

    this.getFileTransfer(this.offset, this.size)
  }

  onClickNextPage() {
    this.isLoading = true
    this.offset += this.addSize

    if (this.offset !== 0) {
      this.buttonPreviousDisabled$.next(false)
    }

    if (this.total > this.offset + this.size) {
      this.buttonNextDisabled$.next(false)
    } else {
      this.buttonNextDisabled$.next(true)
    }

    this.getFileTransfer(this.offset, this.size)
  }
}
