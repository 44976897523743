<div class="bebop-image-carousel" [@.disabled]="isDisabled">
  <div class="control prev" *ngIf="hasControl">
    <bebop-button
      class="prev-icon"
      image-size="small"
      (click)="onPrevious()"
      [disabled]="!canGoPrev() ? 'disabled' : 'false'"
    >
      <svg viewBox="0 0 24 24" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.53 6.22a.75.75 0 0 1 0 1.06L10.81 12l4.72 4.72a.75.75 0 1 1-1.06 1.06l-5.25-5.25a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z"
          fill="#fff"
        />
      </svg>
    </bebop-button>
  </div>
  <div class="carousel-container">
    <ng-container *ngIf="!hasControl">
      <div class="slideshow" @.disabled [class.noAnim]="noAnim || slides?.length <= 1" >
        <div class="slide-wrapper" [style]="{ width: slides.length * 100 + '%', 'animation-duration': speedX * slides.length * 1000 + 'ms'}" >
          <ng-container *ngFor="let slide of slides; let i = index">
            <img [src]="slide.src" class="slide" [style]="{ width: 100 / slides.length  + '%' }"/>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="hasControl">
      <ng-container *ngFor="let slide of slides; let i = index">
        <img [@slideAnimation]="animDirection" *ngIf="i == current" [src]="slide.src" class="slide" />
      </ng-container>
    </ng-container>
    <div class="dots" *ngIf="slides?.length > 1 && slides.length < 5 && hasControl">
      <svg
        class="dot"
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
        *ngFor="let item of slides; let i = index"
        [class.active]="i == current"
        (click)="goToSlide(i)"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3Z"
          fill="white"
        />
      </svg>
    </div>
  </div>

  <div class="control next" *ngIf="hasControl">
    <bebop-button
      class="next-icon"
      image-size="small"
      (click)="onNext()"
      [disabled]="!canGoNext() ? 'disabled' : 'false'"
    >
      <svg viewBox="0 0 16 16" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.529 2.862c.26-.26.682-.26.943 0l4.666 4.667c.26.26.26.682 0 .943l-4.666 4.666a.667.667 0 0 1-.943-.942L9.724 8 5.529 3.805a.667.667 0 0 1 0-.943Z"
          fill="#fff"
        />
      </svg>
    </bebop-button>
  </div>
</div>
