import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subject, Subscription, takeUntil } from 'rxjs'
import {
  FilterWorkstationActionPayload,
  UiWorkstation,
  UiWorkstationGroup,
  WorkstationFilterApplyDefault,
  WorkstationGroupDefault,
  WorkstationTabGroup,
  WorkstationTabKind,
  WorkstationWidgetHandle,
} from 'src/app/components/workstation/classes/workstation-types'
import { Workstation, WorkstationGroup } from 'src/app/models/bebop.model'
import { UserService } from 'src/app/services/user.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { WorkstationQuery } from 'src/app/store/workstation/workstation.query'
import { WorkstationTabInfo } from 'src/app/store/workstation/workstation.store'

@Component({
  selector: 'bebop-tab-workstations',
  templateUrl: './tab-workstations.component.html',
  styleUrls: ['./tab-workstations.component.scss'],
})
export class TabWorkstationsComponent implements OnInit, OnDestroy {
  search = ''

  filtered = false
  scroll = { left: false, right: false }

  filterApply: FilterWorkstationActionPayload = WorkstationFilterApplyDefault

  groups: UiWorkstationGroup[] = []

  workstationGroups: WorkstationGroup[] = []

  workstations: UiWorkstation[] = []
  filteredWorkstations: UiWorkstation[] = []
  favorites: UiWorkstation[] = []

  parentWidget: WorkstationWidgetHandle

  kind: WorkstationTabKind = 'All'
  destroy$ = new Subject<void>()
  $tabInfo: Subscription
  entitlement$: Subscription
  tabInfo: WorkstationTabInfo

  tabGroups: WorkstationTabGroup[] = []
  selectedTabGroup: string = 'All'
  freeTier: boolean = false
  ready = true
  @ViewChild('wsLabels') wsLabels: ElementRef<HTMLElement>
  @ViewChild('wsIndicator') wsIndicator: ElementRef<HTMLElement>

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.clearTimeout()
    this._timeout = window.setTimeout(() => this.updateSelectedRoute(), 60)
  }

  get showScrollButton() {
    return this.tabGroups?.length != 0 && (this.scroll.left || this.scroll.right)
  }

  get defaultGroup() {
    return WorkstationGroupDefault
  }

  get filteredResult() {
    return this.filtered || this.search?.length != 0
  }

  get noWorkstationsLabel() {
    return this.filtered
      ? 'No search results'
      : this.kind == 'Favorite'
      ? 'No favorite workstations yet'
      : this.isAssignedToWorkstations()
      ? 'Your user profile is currently set to be assigned to specific workstations.\n Please ensure your organization admin has assigned you to workstations.'
      : 'No workstations yet'
  }

  constructor(
    private uiQuery: UIQuery,
    private userService: UserService,
    private wquery: WorkstationQuery,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private sessionQuery: SessionQuery,
    private router: Router
  ) {
    this.cardUpdateLastUserInteraction = this.cardUpdateLastUserInteraction.bind(this)
  }

  ngOnInit(): void {
    this.updateKind()

    this.$tabInfo = this.wquery.getWorkstationTabInfo().subscribe((tabInfo) => {
      this.tabInfo = tabInfo || {
        allWorkstations: [],
        filterApply: WorkstationFilterApplyDefault,
        searchText: '',
        workstationGroups: [],
        favorites: [],
        parentWidget: null,
      }

      this.workstations = this.tabInfo.allWorkstations
      this.search = this.tabInfo.searchText
      this.workstationGroups = this.tabInfo.workstationGroups
      this.groups = this.tabInfo.groups
      this.favorites = this.tabInfo.favorites
      this.filterApply = this.tabInfo.filterApply || WorkstationFilterApplyDefault
      this.filtered = !(this.filterApply.state == 'All' && this.filterApply.users?.length == 0)
      this.parentWidget = this.tabInfo.parentWidget || this.parentWidget

      this.uiQuery
        .getSelectedOrg()
        .pipe(takeUntil(this.destroy$))
        .subscribe((org) => {
          if (!org) {
            return
          }
          this.entitlement$ = this.sessionQuery.getEntitlements().subscribe((e) => {
            let permissions = e?.[org?._id] ?? {}
            this.freeTier = permissions?.FREE_TIER ?? false
          })
        });

      this.build()
    })
  }

  chatWithUs() {
    window.Intercom?.('show')
  }

  upgrade() {
    this.router.navigate(['app/org-profile'], { queryParams: { manage: true } })
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.changeDetection.detach();
  }

  ngOnDestroy(): void {
    ;[this.$tabInfo, this.entitlement$].forEach((x) => x?.unsubscribe?.())
    this.destroy$.next()
    this.destroy$.complete()
    this.clearTimeout()
  }

  _timeout = -1
  clearTimeout() {
    if (this._timeout == -1) return
    window.clearTimeout(this._timeout)
    this._timeout = -1
  }

  isAssignedToWorkstations() {
    return this.userService?.user?.userPreferences?.assignedToWorkstations ?? false
  }

  updateKind() {
    let kinds = this.route.pathFromRoot
      .flatMap((r) => r.snapshot.data)
      .filter((d) => d.tabKind)
      .map((d) => <WorkstationTabKind>d.tabKind)

    this.kind = kinds?.[0] ?? 'All'
    console.log(this.kind)
  }

  byWorkstationId(index: number, item: UiWorkstation) {
    return item.source._id
  }

  byGroupName(index: number, item: { name: string }) {
    return item.name
  }

  buildWorkstations() {
    let kind = this.kind
    let ws = (kind == 'Favorite' ? this.favorites : this.workstations) ?? []

    let searchText = this.search ?? ''

    this.filteredWorkstations = ws?.filter((w) => {
      if (!this.filtered && !searchText?.length && kind == 'All') return true

      let { users, state } = this.filterApply ?? WorkstationFilterApplyDefault

      let ret = true

      let uname = w.source?.USER_ID?.name ?? ''

      if (kind == 'Favorite') {
        // users filter is irrelevant for favorites
        users = []
      }

      if (kind == 'Not-Started') state = 'Not Started'
      if (kind == 'Running') state = 'Running'

      if (users.length) {
        ret = users?.includes(uname)
      }
      if (!ret) return false

      // By workstation name or user name or nickname
      if (searchText) {
        let nickname = w.nickname?.toLowerCase() ?? ''
        if (
          w.iname?.indexOf(searchText) == -1 &&
          uname.indexOf(searchText) == -1 &&
          nickname.indexOf(searchText) == -1
        ) {
          return false
        }
      }

      return w.state == state || state == 'All' || !state
    })

    switch (this.kind) {
      case 'All':
        break
      case 'Running':
        this.parentWidget?.updateCount('Running', this.filteredWorkstations?.length)
        break
      case 'Not-Started':
        this.parentWidget?.updateCount('Not-Started', this.filteredWorkstations?.length)
        break
      case 'Favorite':
        this.parentWidget?.updateCount('Favorite', this.filteredWorkstations?.length)
        break
    }
  }

  buildTabGroup() {
    let groupKeyMap = this.workstationGroups?.reduce((acc, g) => {
      if (!acc[g._id]) {
        acc[g._id] = g.name
      }
      return acc
    }, <Record<string, string>>{})

    let groupMap = this.filteredWorkstations?.reduce(
      (g, w) => {
        let name = groupKeyMap[w.source.WORKSTATION_GROUP] || WorkstationGroupDefault
        if (!g[name]) {
          g[name] = {
            name,
            workstations: [w],
          }
        } else {
          g[name].workstations.push(w)
        }
        w.group = name
        return g
      },
      <Record<string, UiWorkstationGroup>>{ default: { name: WorkstationGroupDefault, workstations: [] } }
    )

    let wgroupNames = this.workstationGroups?.map((g) => g.name)
    this.groups = wgroupNames.map((n) => groupMap[n]).filter((x) => x)

    this.onSelectTabGroup(this.selectedTabGroup)

    this.tabGroups = this.groups
      ?.map((g) => ({
        name: g.name,
        count: g.workstations?.length || 0,
      }))
      .filter((n) => n.name != WorkstationGroupDefault)
  }

  _filteredGroups: UiWorkstationGroup[] = []
  get filteredGroups() {
    return this._filteredGroups ?? []
  }

  build() {
    this.buildWorkstations()
    this.buildTabGroup()
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    console.log(event.target)
    this.updateScrollButton(event.target)
  }

  getTapGroupScrollElement() {
    if (!this.wsLabels?.nativeElement) return
    let cs: HTMLCollection = this.wsLabels?.nativeElement?.children

    if (cs?.length < 2) return
    return cs[1]
  }

  updateScrollButton(target?: Element) {
    if (!target) {
      target = this.getTapGroupScrollElement()
      if (!target) return
    }

    let hasScroll = target.scrollWidth > target.clientWidth

    if (!hasScroll) {
      this.scroll.left = false
      this.scroll.right = false
      return
    }

    this.scroll.left = target.scrollLeft != 0
    this.scroll.right = target.scrollWidth - target.clientWidth > target.scrollLeft
  }

  scrollTabGroupRight() {
    let target = this.getTapGroupScrollElement()
    if (!target) return

    this.scroll.left = true
    this.scroll.right = false

    setTimeout(() => {
      target?.scrollTo({ left: target.scrollWidth - target.clientWidth, behavior: 'smooth' })
    }, 16)
  }

  scrollTabGroupLeft() {
    let target = this.getTapGroupScrollElement()
    if (!target) return

    this.scroll.left = false
    this.scroll.right = true
    setTimeout(() => {
      target?.scrollTo({ left: 0, behavior: 'smooth' })
    }, 16)
  }

  onSelectTabGroup(group: string) {
    this.selectedTabGroup = group

    this._filteredGroups =
      this.selectedTabGroup == 'All'
        ? this.groups
        : [this.groups?.find((g) => g.name == this.selectedTabGroup)].filter((x) => x)

    this.clearTimeout()
    this._timeout = window.setTimeout(() => this.updateSelectedRoute(), 60)
  }

  updateSelectedRoute() {
    if (!this.wsLabels?.nativeElement) return
    let cs: HTMLCollection = this.wsLabels?.nativeElement?.children

    // flatten
    let hc = []
    let j = 0
    for (let i = 0; i < cs?.length; i++) {
      if (cs[i].classList?.contains('rest')) {
        let ch = cs[i].children
        for (let k = 0; k < ch?.length; k++) {
          hc[j++] = ch[k]
        }
      } else {
        hc[j++] = cs[i]
      }
    }

    let pos = Math.max(this.tabGroups?.findIndex((t) => t.name == this.selectedTabGroup) + 1, 0)
    if (hc) {
      let next = hc[pos]

      let parentRect = this.wsLabels.nativeElement.getBoundingClientRect()

      let sty = getComputedStyle(this.wsLabels.nativeElement)

      let left = +sty.marginLeft.replace('px', '')

      let distances = Array.from(hc).reduce((acc, el, idx) => {
        let rect = el.getBoundingClientRect()
        acc.push([rect.top - parentRect.top, left + rect.left - parentRect.left])
        return acc
      }, [])

      let nrect = next.getBoundingClientRect()

      this.wsIndicator.nativeElement.style.width = `${nrect.width}px`
      this.wsIndicator.nativeElement.style.height = `${nrect.height}px`

      this.wsIndicator.nativeElement.style.top = `${distances[pos][0]}px`
      this.wsIndicator.nativeElement.style.left = `${distances[pos][1]}px`
      this.wsIndicator.nativeElement.style.visibility = 'visible'
    }

    this.updateScrollButton()
  }

  get user() {
    return this.userService.user
  }

  getBadges(w: Workstation) {
    return this.parentWidget?.getBadges(w)
  }

  getBroadcastStatus(w: Workstation) {
    return this.parentWidget?.getBroadcastStatus(w)
  }

  getPowerCodeLabel(w: Workstation): Partial<UiWorkstation> {
    return this.parentWidget?.getPowerCodeLabel(w)
  }

  cardUpdateLastUserInteraction(ev: UiWorkstation) {
    this.parentWidget?.cardUpdateLastUserInteraction(ev)
  }

  clearAllFilter() {
    this.parentWidget?.clearAllFilter()
  }
}
