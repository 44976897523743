import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Organization } from 'src/app/models/bebop.model'

import { LookerAuthService } from '../../services/looker-auth.service'
import { UIQuery } from '../../store/ui/ui.query'

@Component({
  imports: [CommonModule],
  selector: 'app-looker-studio',
  standalone: true,
  styleUrls: ['./looker-studio.component.scss'],
  templateUrl: './looker-studio.component.html',
})
export class LookerStudioComponent implements OnInit, OnDestroy {
  reportUrl: SafeResourceUrl | null = null
  isLoading = true
  loadError: string | null = null
  private destroy$ = new Subject<void>()

  constructor(
    private sanitizer: DomSanitizer,
    private lookerService: LookerAuthService,
    private uiQuery: UIQuery
  ) {}

  ngOnInit() {
    const org = this.uiQuery.getSelectedOrgValue() as Organization
    if (!org?._id) {
      this.loadError = 'No organization selected'
      this.isLoading = false
      return
    }

    this.loadReport(org._id)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private loadReport(orgId: string) {
    this.isLoading = true
    this.loadError = null
    this.reportUrl = null

    this.lookerService
      .getReport(orgId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (error) => {
          console.error('Error loading Looker report:', error)
          this.loadError = error.error?.error || 'Failed to load the report'
          this.isLoading = false
        },
        next: (response) => {
          if (response.embedUrl) {
            this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.embedUrl)
            this.isLoading = false
          } else {
            this.loadError = 'No report URL received'
            this.isLoading = false
          }
        },
      })
  }

  reloadReport() {
    const org = this.uiQuery.getSelectedOrgValue() as Organization
    if (org?._id) {
      this.loadReport(org._id)
    }
  }
}
