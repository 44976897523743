<cree8-modal [size]="'large'" *ngIf="active" [modal]="modal" (cancelModal)="onCancel()">

  <div class="user-projects-modal">

    <div class="header">
      <div class="user">
        <img [src]="getUserImage(user)" alt="{{ user?.name }}" width="32">
        <div class="info">
          <div class="body3">{{user.name}}</div>
          <div class="captions">{{user.email}}</div>
        </div>
      </div>

      <div class="searchBar">
        <bebop-input-select placeholder="Select Project" [value]="" [onSearch]="onSearchProject"
          (bebopClickOutside)="resetSearchProject()" (dropdownState)="onDropdownProjectChange($event)">
          <div dropdown-icon>
            <svg class="ico" width="16" height="16" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Folder_Single"></use>
            </svg>
          </div>
          <div dropdown-options>
            <bebop-input-select-option *ngFor="let project of filteredProjects" (on-click)="onSelectedProject(project)">
              <div option-icon>
                <svg class="ico" width="16" height="16" viewBox="0 0 24 24">
                  <use xlink:href="app/common/assets/icons/_sprite.svg#Folder_Single"></use>
                </svg>
              </div>
              <div option-text>
                {{ project.name }}
              </div>
            </bebop-input-select-option>
          </div>
        </bebop-input-select>
      </div>
    </div>


    <div class="project-list" *ngIf="projects.length > 0; else noProjects">
      <table class="cree8-table">
        <thead>
          <tr>
            <th>Project</th>
            <th>Information</th>
            <th>Access</th>
          </tr>
        </thead>
        <tbody class="scrollbar">
          <tr *ngFor=" let project of projects">
            <td>
              <div class="projects">
                <div>{{project.name}}</div>
                <span class="captions">{{project.folderName}}</span>
              </div>
            </td>
            <td>
              <div class="information">
                <div>{{project.folderSize.gb}} GB</div>
                <span class="captions">Created: {{ project.date_created | date:'dd-MM-yyyy' }}</span>
              </div>
            </td>
            <td>
              <cree8-btn-icon cree8-tooltip-text="Remove project" cree8-tooltip-position="top"
                (click)="removeProject(project)">
                <svg style="color:var(--message-error)" class="ico" viewBox="0 0 24 24">
                  <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Delete'"></use>
                </svg>
              </cree8-btn-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Template for when there are no invites -->
    <ng-template #noProjects>
      <div *ngIf="!loading">
        No projects currently assigned
      </div>
    </ng-template>
  </div>
</cree8-modal>
