<div class="cree8-card-folder">
  <div class="content" (click)="onTitleClick()">
    <div class="icon">
      <svg viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Folder_Single"></use>
      </svg>
    </div>
    <div class="info">
      <span class="card-title headline4">{{ title }}</span>
      <div class="card-subtitle captions">
        <!-- <span *ngIf="items > 0">
          {{ items }} item{{ items > 1 ? 's' : '' }}
        </span>
        <span *ngIf="items > 0">|</span> -->
        {{ folderSize || '0 GB' }}
      </div>
    </div>
  </div>
  <div class="actions">
    <ng-content select="[actions-menu]"></ng-content>
  </div>
</div>
