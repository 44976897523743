import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss'],
})
export class CheckboxButtonComponent implements OnInit {
  isRounded = true

  @Output('on-change') change = new EventEmitter<boolean>()
  @Input('disabled') disabled: String
  @Input('rounded') rounded: String
  @Input('checked') checked: boolean
  @Input('partial') partial: boolean

  @Input('pulse') pulse: boolean

  @Input('labelClass') labelClass: string

  touched = false
  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {}

  get isDisabled() {
    return this.disabled == 'disabled' || this.disabled == 'true'
  }

  get isPartial() {
    return this.partial == true
  }

  ngOnInit(): void {
    this.isRounded = this.rounded == 'true'
  }

  get pulsed() {
    return !this.isDisabled && this.pulse && !this.touched
  }

  onChange(e: MouseEvent) {
    if (this.isDisabled) return
    this.touched = true
    this.checked = !this.checked
    this.change.emit(this.checked)
  }
}
