<div class="bebop-workstations">
  <div class="banner" *ngIf="hasBanner">
    <bebop-banner *ngIf="hasBanner" [message]="bannerText" (on-close)="onCloseBanner()"></bebop-banner>
  </div>

  <div class="header-panel">
    <div class="header h1">
      Workstations
      <!--<div class="help-icon" (click)="openHelpCenter()">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          bebop-tooltip="Get Help"
          bebop-tooltip-placement="right"
          bebop-tooltip-type="info"
          bebop-tooltip-offset="4"
        >
          <path
            d="M11.29 15.29a1.573 1.573 0 0 0-.12.15.762.762 0 0 0-.09.18.643.643 0 0 0-.06.18 1.362 1.362 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 13 16a1 1 0 0 0-1.71-.71ZM12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16.001A8 8 0 0 1 12 20Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 12 9a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-.18A3 3 0 0 0 12 7Z"
            fill="#5A5A68"
          />
        </svg>
      </div>-->
    </div>
    <div class="action-panel">
      <bebop-input-search
        [(binding)]="search"
        class="search-button"
        (on-keyup)="onSearchWorkstation($event)"
      ></bebop-input-search>
      <div class="divider"></div>
      <div class="dropdown-section">
        <bebop-button outlined="true" icon-size="small" (on-click)="showInfo()" class="show-info">
          <svg viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.178 2.667a5.333 5.333 0 1 0 0 10.666 5.333 5.333 0 0 0 0-10.666ZM1.51 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.511 8Zm6.667-.667c.368 0 .666.299.666.667v2.667a.667.667 0 1 1-1.333 0V8c0-.368.299-.667.667-.667Zm0-2.666a.667.667 0 1 0 0 1.333h.006a.667.667 0 0 0 0-1.333h-.006Z"
              fill="#7F7F8C"
            />
          </svg>
        </bebop-button>

        <bebop-button
          outlined="true"
          icon-size="small"
          (on-click)="showFilter()"
          [class.filtered]="filtered"
          class="show-filter"
        >
          <svg viewBox="0 0 16 16" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 3.857C2 2.832 2.832 2 3.857 2h8.286C13.168 2 14 2.832 14 3.857c0 .538-.193 1.059-.543 1.467l-1.89 2.204a4.667 4.667 0 0 0-1.123 3.038V12a2 2 0 0 1-2 2h-.888a2 2 0 0 1-2-2v-1.434a4.667 4.667 0 0 0-1.124-3.038l-1.89-2.204A2.254 2.254 0 0 1 2 3.857Zm1.857-.524a.524.524 0 0 0-.524.524c0 .22.079.433.222.6l1.89 2.204a6 6 0 0 1 1.444 3.905V12c0 .368.298.667.667.667h.888A.667.667 0 0 0 9.111 12v-1.434a6 6 0 0 1 1.445-3.905l1.889-2.205a.92.92 0 0 0 .222-.599.524.524 0 0 0-.524-.524H3.857Z"
              fill="#7F7F8C"
            />
          </svg>
        </bebop-button>
      </div>
    </div>
  </div>

  <div class="workstation-panel anim-panel" [class.ready]="ready">
    <bebop-server-status status="Loading" *ngIf="!ready"></bebop-server-status>
    <div class="tabs-panel">
      <bebop-tabs [tabs]="tabs" type="outline-header"></bebop-tabs>
      <div class="sort-section">
        <div class="dropdown-section">
          <div class="sort-wrapper" [class.inactive]="!showSortDropdown">
            <bebop-button
              outlined="true"
              [bebopDropdownTriggerFor]="dropdown"
              placement="bottom"
              placement-overlay-x-position="end"
              placement-origin-x-position="end"
              placement-tooltip-position="none"
              [placement-tooltip-offset]="4"
              (bebopClickOutside)="onClickOutside()"
              (on-click)="onClickSortDropdown()"
              class="sort-dropdown"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="sort-icon"
              >
                <path
                  d="M6.15376 14.2591V4.03838C6.15376 3.64895 6.46946 3.33325 6.85889 3.33325C7.24832 3.33325 7.56402 3.64895 7.56402 4.03838V14.2591L9.1808 12.6423C9.45618 12.367 9.90264 12.367 10.178 12.6423C10.4534 12.9177 10.4534 13.3642 10.178 13.6395L7.35749 16.4601C7.08212 16.7354 6.63566 16.7354 6.36029 16.4601L3.53978 13.6395C3.26441 13.3642 3.26441 12.9177 3.53978 12.6423C3.81515 12.367 4.26161 12.367 4.53698 12.6423L6.15376 14.2591Z"
                  fill="#5A5A68"
                />
                <path
                  d="M13.0204 5.74071V15.9615C13.0204 16.3509 13.3361 16.6666 13.7256 16.6666C14.115 16.6666 14.4307 16.3509 14.4307 15.9615V5.74071L16.0475 7.35749C16.3228 7.63286 16.7693 7.63286 17.0447 7.35749C17.32 7.08212 17.32 6.63566 17.0447 6.36029L14.2242 3.53978C13.9488 3.26441 13.5023 3.26441 13.227 3.53978L10.4064 6.36029C10.1311 6.63566 10.1311 7.08212 10.4064 7.35749C10.6818 7.63286 11.1283 7.63286 11.4036 7.35749L13.0204 5.74071Z"
                  fill="#5A5A68"
                />
              </svg>

              {{ sortByType }}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                *ngIf="!showSortDropdown"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8169 7.68306C15.061 7.92714 15.061 8.32286 14.8169 8.56694L10.4419 12.9419C10.1979 13.186 9.80214 13.186 9.55806 12.9419L5.18306 8.56694C4.93898 8.32286 4.93898 7.92714 5.18306 7.68306C5.42714 7.43898 5.82286 7.43898 6.06694 7.68306L10 11.6161L13.9331 7.68306C14.1771 7.43898 14.5729 7.43898 14.8169 7.68306Z"
                  fill="#5A5A68"
                />
              </svg>

              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                *ngIf="showSortDropdown"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.8169 12.9419C15.061 12.6979 15.061 12.3021 14.8169 12.0581L10.4419 7.68306C10.1979 7.43898 9.80214 7.43898 9.55806 7.68306L5.18306 12.0581C4.93898 12.3021 4.93898 12.6979 5.18306 12.9419C5.42714 13.186 5.82286 13.186 6.06694 12.9419L10 9.00888L13.9331 12.9419C14.1771 13.186 14.5729 13.186 14.8169 12.9419Z"
                  fill="white"
                  fill-opacity="0.8"
                />
              </svg>
            </bebop-button>

            <bebop-dropdown #dropdown [panelClasses]="['ws-sort-panel-dd']">
              <div (click)="sortBy(item)" class="sort-item" *ngFor="let item of sortByItems">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="tick-svg"
                  [class.selected]="sortByType == item"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.1058 4.16496C13.3829 4.40741 13.4109 4.82859 13.1685 5.10568L7.33514 11.7723C7.09359 12.0484 6.67438 12.0775 6.39705 11.8374L2.89705 8.80705C2.6187 8.56605 2.58842 8.14502 2.82942 7.86667C3.07042 7.58831 3.49144 7.55803 3.7698 7.79903L6.76842 10.3952L12.165 4.22767C12.4075 3.95058 12.8287 3.9225 13.1058 4.16496Z"
                    fill="#3567F0"
                  />
                </svg>

                <div class="paragraph sort-item-label">{{ item }}</div>
              </div>
            </bebop-dropdown>
          </div>
        </div>
        <cree8-btn (click)="addNewWorkstation()" *ngIf="isLaunchNewAllowed">
          NEW WORKSTATION
        </cree8-btn>
      </div>
    </div>
  </div>
</div>
