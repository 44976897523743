import { Injectable } from '@angular/core'

import { BehaviorSubject, filter, map, Observable, Subscription } from 'rxjs'

import { BebopClientUtilsService } from './bebop-client-utils.service'
import { ElectronService } from './electron.service'

export type UserFileType = 'UserSettings' | 'UploadCache'

export type UserSettingEvent = {
  [key in UserFileType]?: any
}

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  cache: any = {}

  _userSetting: {
    [key: string]: UserSettingEvent
  } = {}

  _subject = new BehaviorSubject<UserSettingEvent>(null)

  constructor(
    private electronService: ElectronService,
    private util: BebopClientUtilsService
  ) {}

  getByType(type: UserFileType) {
    return this._subject.pipe(
      filter((x) => x?.[type]),
      map((x) => x?.[type])
    )
  }

  getUserSettings() {
    this._subject = new BehaviorSubject<object>(this.util.getLocalStorageItem('UserSettings', false) || {})
    return this._subject
  }

  getUploadCache() {
    return this.getByType('UploadCache')
  }

  private getSettingsPath(type: UserFileType, userId: string) {
    return ''
  }

  readCachedSettings(type: UserFileType, userId: string) {
    return {}
  }

  readSettings(type: UserFileType, userId: string, cb: (err: NodeJS.ErrnoException, res?: any) => void) {
    return cb(null, '')
  }

  saveSettings(type: UserFileType, userId: string, settings: object, cb: (err: NodeJS.ErrnoException) => void) {
    this.util.setLocalStorageItem(type, settings)
    this._subject.next(settings)
  }
}
