<ng-template #learnContent>
  <h2>Want to learn more about cree8?</h2>

  <div class="btn">
    <cree8-button size="medium" variant="dark" (click)="scrollToElement()">
      <div class="learn-button">
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7.81055C6.77214 7.81055 6.56478 7.7194 6.37793 7.53711L1.17578 2.21191C1.09831 2.139 1.03906 2.05697 0.998047 1.96582C0.961589 1.87012 0.943359 1.76758 0.943359 1.6582C0.943359 1.50781 0.977539 1.37109 1.0459 1.24805C1.11882 1.125 1.21452 1.0293 1.33301 0.960938C1.4515 0.888021 1.58594 0.851562 1.73633 0.851562C1.95508 0.851562 2.14648 0.933594 2.31055 1.09766L7.33496 6.24512H6.67188L11.6895 1.09766C11.8535 0.933594 12.0449 0.851562 12.2637 0.851562C12.4141 0.851562 12.5485 0.888021 12.667 0.960938C12.7855 1.0293 12.8789 1.125 12.9473 1.24805C13.0202 1.37109 13.0566 1.50781 13.0566 1.6582C13.0566 1.8724 12.9792 2.05697 12.8242 2.21191L7.62207 7.53711C7.53548 7.62826 7.43978 7.69661 7.33496 7.74219C7.23014 7.78776 7.11849 7.81055 7 7.81055Z"
            fill="#A1A1A3"
          />
        </svg>
        LEARN MORE
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7.81055C6.77214 7.81055 6.56478 7.7194 6.37793 7.53711L1.17578 2.21191C1.09831 2.139 1.03906 2.05697 0.998047 1.96582C0.961589 1.87012 0.943359 1.76758 0.943359 1.6582C0.943359 1.50781 0.977539 1.37109 1.0459 1.24805C1.11882 1.125 1.21452 1.0293 1.33301 0.960938C1.4515 0.888021 1.58594 0.851562 1.73633 0.851562C1.95508 0.851562 2.14648 0.933594 2.31055 1.09766L7.33496 6.24512H6.67188L11.6895 1.09766C11.8535 0.933594 12.0449 0.851562 12.2637 0.851562C12.4141 0.851562 12.5485 0.888021 12.667 0.960938C12.7855 1.0293 12.8789 1.125 12.9473 1.24805C13.0202 1.37109 13.0566 1.50781 13.0566 1.6582C13.0566 1.8724 12.9792 2.05697 12.8242 2.21191L7.62207 7.53711C7.53548 7.62826 7.43978 7.69661 7.33496 7.74219C7.23014 7.78776 7.11849 7.81055 7 7.81055Z"
            fill="#A1A1A3"
          />
        </svg>
      </div>
    </cree8-button>
  </div>
</ng-template>

<div class="container" id="stepper">
<div class="sidebar">
  <div class="content">
    <div class="learn mobile" *ngIf="activeFooter">
      <ng-container *ngTemplateOutlet="learnContent"></ng-container>
    </div>
    <h1 class="title">CREATE YOUR ACCOUNT</h1>
    <ul class="step-list">
      <li
        *ngFor="let item of listStepper; let i = index"
        class="step-item"
        [ngClass]="activedStep(i) ? 'active-step inverted' : 'inactive-step'"
      >
        <span
          class="step-circle"
          [ngClass]="{
            'active-border': activedAndComeStep(i),
            'inactive-border': !activedAndComeStep(i),
            'passed-bg': hasPasssed(i),
            'not-passed-bg': !hasPasssed(i)
          }"
        >
          <ng-container *ngIf="hasPasssed(i); else notFinished">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.37109 12.1621C5.04297 12.1621 4.7627 12.0163 4.53027 11.7246L0.989258 7.29492C0.902669 7.1901 0.838867 7.08757 0.797852 6.9873C0.761393 6.88704 0.743164 6.78451 0.743164 6.67969C0.743164 6.44271 0.820638 6.24674 0.975586 6.0918C1.13509 5.93685 1.33561 5.85938 1.57715 5.85938C1.85514 5.85938 2.08984 5.9847 2.28125 6.23535L5.34375 10.166L11.2773 0.739258C11.3822 0.579753 11.4893 0.468099 11.5986 0.404297C11.708 0.335938 11.8493 0.301758 12.0225 0.301758C12.2594 0.301758 12.4531 0.376953 12.6035 0.527344C12.7539 0.673177 12.8291 0.864583 12.8291 1.10156C12.8291 1.19727 12.8132 1.29525 12.7812 1.39551C12.7493 1.49121 12.6992 1.59375 12.6309 1.70312L6.20508 11.7178C6.00456 12.014 5.72656 12.1621 5.37109 12.1621Z"
                fill="#007FFF"
              />
            </svg>
          </ng-container>
          <ng-template #notFinished>
            <span class="step-number">{{ i + 1 }}</span>
          </ng-template>
        </span>
        <span class="step-text"
          >{{ item.step }}
          <span *ngIf="hasPasssed(i)" class="step-subtext">{{
            item.value
          }}</span>
        </span>
      </li>
    </ul>

    <div class="learn dekstop" *ngIf="activeFooter">
      <ng-container *ngTemplateOutlet="learnContent"></ng-container>
    </div>
  </div>
</div>
<div class="main-content">
  <ng-content></ng-content>
</div>
</div>
<ng-content select="[outside-template]"></ng-content>
