import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-code-input',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
})
export class InputCodeComponent implements OnInit, AfterViewInit {
  showInputGuide = true
  showInputIconLeft = true
  showInputIconRight = true
  showInputError = true
  showInputLabel = true
  inputErrorIconAvailable = true
  inputErrorLabelAvailable = true
  isPasswordField = false
  hidePasswordField = false
  isClearActionEnabled = false
  get isDisabled() {
    return this.util.isTrueLike(this.disabled)
  }

  isLeftIconSeparator = false

  @ViewChild('inputGuide') inputGuide
  @ViewChild('inputIconLeft') inputIconLeft
  @ViewChild('inputIconRight') inputIconRight
  @ViewChild('inputErrorIcon') inputErrorIcon
  @ViewChild('inputErrorLabel') inputErrorLabel

  @Input('type') type: string
  @Input('numberOfDigits') numberOfDigits: number | string
  @Input('placeholder') placeholder: string
  @Input('disabled') disabled: string | boolean
  @Input('error') error: string | boolean
  @Input('autofocus') autofocus: string | boolean

  @Input('noInputLabel') noInputLabel: string

  @Input('clearButton') clearButton: string | boolean

  @Input('leftIconSeparator') leftIconSeparator: string | boolean

  @Input('binding') binding: string | number
  @Output('bindingChange') onChange = new EventEmitter<any>() // string | number

  @Output('on-click') click = new EventEmitter<MouseEvent>()
  @Output('on-dblclick') dblclick = new EventEmitter<MouseEvent>()
  @Output('on-submit') submit = new EventEmitter<Event>()
  @Output('on-blur') blur = new EventEmitter<FocusEvent>()
  @Output('on-focus') focus = new EventEmitter<FocusEvent>()
  @Output('on-scroll') scroll = new EventEmitter<Event>()
  @Output('on-cut') cut = new EventEmitter<ClipboardEvent>()
  @Output('on-copy') copy = new EventEmitter<ClipboardEvent>()
  @Output('on-paste') paste = new EventEmitter<ClipboardEvent>()
  @Output('on-keyup') keyup = new EventEmitter<KeyboardEvent>()
  @Output('on-keypress') keypress = new EventEmitter<KeyboardEvent>()
  @Output('on-keydown') keydown = new EventEmitter<KeyboardEvent>()
  @Output('on-mouseup') mouseup = new EventEmitter<MouseEvent>()
  @Output('on-mousedown') mousedown = new EventEmitter<MouseEvent>()
  @Output('on-mouseenter') mouseenter = new EventEmitter<MouseEvent>()
  @Output('on-drag') drag = new EventEmitter<DragEvent>()
  @Output('on-drop') drop = new EventEmitter<DragEvent>()
  @Output('on-dragover') dragover = new EventEmitter<DragEvent>()
  @Output('on-error') inputError = new EventEmitter<ErrorEvent>()

  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {
    this.setupCodeInputFields = this.setupCodeInputFields.bind(this)
  }

  ngOnInit(): void {
    if (!this.type) this.type = 'text'

    this.isPasswordField = this.type == 'password'
    this.isClearActionEnabled = this.util.isTrueLike(this.clearButton)

    this.placeholder = this.placeholder || ''
    this.hidePasswordField = this.isPasswordField
    this.isLeftIconSeparator = this.util.isTrueLike(this.leftIconSeparator)

    this.showInputLabel = this.noInputLabel != 'true'
    this.numberOfDigits = +this.numberOfDigits || 5
  }

  setupCodeInputFields() {
    const inputs = document.getElementsByClassName('code-input-control')
    
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === "Backspace") {
          (inputs[i] as HTMLInputElement).value = ''
          if (i !== 0)
          (inputs[i-1] as HTMLInputElement).focus()
        } else if (i === inputs.length - 1 && (inputs[i] as HTMLInputElement).value !== '') {
          return true
        }
        else {
          if (i === inputs.length - 1 && (inputs[i] as HTMLInputElement).value !== '') {
            return true
          } else if (event.keyCode > 47 && event.keyCode < 58 || event.keyCode > 95 && event.keyCode < 106) {
            (inputs[i] as HTMLInputElement).value = event.key
            if (i !== inputs.length - 1)
              (inputs[i+1] as HTMLInputElement).focus()
            event.preventDefault()
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            (inputs[i] as HTMLInputElement).value = String.fromCharCode(event.keyCode)
            if (i !== inputs.length - 1)
              (inputs[i+1] as HTMLInputElement).focus()
            event.preventDefault()
          }
        }

        this.binding = ''
        for (let j = 0; j < inputs.length; j++) {
          this.binding = this.binding + (inputs[j] as HTMLInputElement).value
        }

        return true
      })
    }
  }
  get isAutoFocused() {
    return this.util.isTrueLike(this.autofocus)
  }

  ngAfterViewInit() {
    this.showInputGuide = this.inputGuide?.nativeElement?.children?.length > 0
    this.showInputIconLeft = this.inputIconLeft?.nativeElement?.children?.length > 0
    this.showInputIconRight = this.inputIconRight?.nativeElement?.children?.length > 0
    this.inputErrorIconAvailable = this.inputErrorIcon?.nativeElement?.children?.length > 0
    this.inputErrorLabelAvailable = this.inputErrorLabel?.nativeElement?.children?.length > 0
    this.cdRef.detectChanges()
    setTimeout(this.setupCodeInputFields, 100)
  }

  clearUserInput() {
    this.binding = ''
    this.onChangeUserInput()
  }

  onChangeUserInput() {
    this.onChange.emit(this.binding)
  }

  onClick(ev: MouseEvent) {
    this.click.emit(ev)
  }

  onDblclick(ev: MouseEvent) {
    this.dblclick.emit(ev)
  }

  onSubmit() {
    this.submit.emit()
  }

  onBlur(ev: FocusEvent) {
    this.blur.emit(ev)
  }

  onFocus(ev: FocusEvent) {
    this.focus.emit(ev)
  }

  onScroll(ev: Event) {
    this.scroll.emit(ev)
  }

  onCut(ev: ClipboardEvent) {
    this.cut.emit(ev)
  }

  onCopy(ev: ClipboardEvent) {
    this.copy.emit(ev)
  }

  onPaste(ev: ClipboardEvent) {
    this.paste.emit(ev)
  }

  onError(e: ErrorEvent) {
    this.inputError.emit(e)
  }
  
  onKeyup(ev: KeyboardEvent) {
    this.keyup.emit(ev)
  }

  onKeypress(ev: KeyboardEvent) {
    this.keypress.emit(ev)
  }

  onKeydown(ev: KeyboardEvent) {
    this.keydown.emit(ev)
  }

  onMouseup(ev: MouseEvent) {
    this.mouseup.emit(ev)
  }

  onMousedown(ev: MouseEvent) {
    this.mousedown.emit(ev)
  }

  onMouseenter(ev: MouseEvent) {
    this.mouseenter.emit(ev)
  }

  onDrag(ev: DragEvent) {
    this.drag.emit(ev)
  }

  onDrop(ev: DragEvent) {
    this.drop.emit(ev)
  }

  onDragover(ev: DragEvent) {
    this.dragover.emit(ev)
  }
}
