<div class="bebop-add-new-project">
  <div class="header">
    <div class="header-title h4">Add new project</div>

    <div class="header-close" (click)="cancel()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="#0C0C0F"
          fill-opacity="0.32"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5283 7.52851C15.7886 7.26816 16.2107 7.26816 16.4711 7.52851C16.7314 7.78886 16.7314 8.21097 16.4711 8.47132L12.9425 11.9999L16.4711 15.5285C16.7314 15.7889 16.7314 16.211 16.4711 16.4713C16.2107 16.7317 15.7886 16.7317 15.5283 16.4713L11.9997 12.9427L8.47108 16.4713C8.21073 16.7317 7.78862 16.7317 7.52827 16.4713C7.26792 16.211 7.26792 15.7889 7.52827 15.5285L11.0569 11.9999L7.52827 8.47132C7.26792 8.21097 7.26792 7.78886 7.52827 7.52851C7.78862 7.26816 8.21073 7.26816 8.47108 7.52851L11.9997 11.0571L15.5283 7.52851Z"
          fill="white"
          fill-opacity="0.8"
        />
      </svg>
    </div>
  </div>

  <div class="action-section">
    <bebop-server-status
      status="Loading"
      *ngIf="isLoading"
      [loadingStyles]="{ width: '96px', height: '96px', filter: 'contrast(0.75)' }"
    ></bebop-server-status>

    <ng-container *ngTemplateOutlet="mainSection"></ng-container>

    <div class="divider"></div>
    <div class="action-container">
      <cree8-btn [appearance]="'text'" (clicked)="cancel()" class="cancel-btn">CANCEL</cree8-btn>
      <cree8-btn [appearance]="'filled'" (clicked)="addProject()" class="add-project">ADD PROJECT</cree8-btn>
    </div>
  </div>
</div>

<ng-template #mainSection>
  <div class="main-section">
    <div class="proj-item">
      <div class="proj-label title">Project Name:</div>
      <div class="proj-value">
        <bebop-input
          type="text"
          placeholder="Project name"
          [(binding)]="bindings.name"
          [autofocus]="true"
          (on-keyup)="onKeyupProjectName($event)"
          [error]="!!formErrors.name"
          noInputLabel="true"
        >
          <div input-error-label>{{ formErrors.name }}</div>
        </bebop-input>
      </div>
    </div>

    <div class="proj-item" *ngIf="pods.length > 1">
      <div class="proj-label title">Select Pod:</div>
      <div class="proj-value">
        <div class="input-select-search">
          <bebop-input-select
            placeholder="Select Pod…"
            [value]="bindings.pod?.name"
            [onSearch]="onPodSearch"
            (dropdownState)="onDropdownPodChange($event)"
          >
            <div dropdown-options>
              <bebop-input-select-option *ngFor="let pod of filteredPods; let total = count" (click)="onSelectPod(pod)">
                <div option-text>
                  {{ pod?.name }}
                </div>
              </bebop-input-select-option>
              <div class="no-search-result" *ngIf="!filteredPodCount" option-text>
                <ng-container *ngTemplateOutlet="noSearchResult"></ng-container>
              </div>
            </div>
          </bebop-input-select>
        </div>
      </div>
    </div>

    <div class="proj-item" *ngIf="storages.length > 1">
      <div class="proj-label title">Select storage:</div>
      <div class="proj-value">
        <div class="input-select-search">
          <bebop-input-select
            placeholder="Select Storage…"
            [value]="bindings.storage?.displayName || bindings.storage?.name"
            [onSearch]="onStorageSearch"
            (dropdownState)="onDropdownStorageChange($event)"
            [disabled]="!bindings?.pod"
          >
            <div dropdown-options>
              <bebop-input-select-option
                *ngFor="let st of filteredStorages; let total = count"
                (click)="onSelectStorage(st)"
              >
                <div option-text>
                  {{ st?.displayName || st?.name }}
                </div>
              </bebop-input-select-option>
              <div class="no-search-result" *ngIf="!filteredStorageCount" option-text>
                <ng-container *ngTemplateOutlet="noSearchResult"></ng-container>
              </div>
            </div>
          </bebop-input-select>
        </div>
      </div>
    </div>

    <div class="proj-item">
      <div class="proj-label title">Folder label:</div>
      <div class="proj-value">
        <bebop-input
          type="text"
          placeholder="Folder name"
          [(binding)]="bindings.folderLabel"
          (on-keyup)="onKeyupFolderLabel($event)"
          [error]="!!formErrors.folderLabel"
          noInputLabel="true"
        >
          <div input-error-label>{{ formErrors.folderLabel }}</div>
        </bebop-input>

        <div class="hint small-bold">
          Choose a folder name for the project. Max 15 Characters. No special characters.
        </div>
      </div>
    </div>

    <div class="proj-item">
      <div class="proj-label title">Drive letter:</div>
      <div class="proj-value">
        <div class="drives">
          <ng-container  *ngFor="let d of drives; let i = index">
            <div
              class="drive paragraph"
              (click)="onSelectDrive(d)"
              [class.selected]="d.selected"
            >
              {{ d.name }}
          </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noSearchResult let-label>
  <svg viewBox="0 0 128 128" fill="none">
    <circle cx="64" cy="64" r="63.5" fill="#2B2B33" stroke="url(#a)" />
    <mask id="d" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
      <circle cx="64" cy="64" r="63.5" fill="url(#b)" stroke="url(#c)" />
    </mask>
    <g mask="url(#d)" fill-rule="evenodd" clip-rule="evenodd" fill="#5A5A68">
      <path
        d="M68 31.5a6.5 6.5 0 1 0 0 13h26a6.5 6.5 0 1 0 0-13H68ZM58.5 38a9.5 9.5 0 0 1 9.5-9.5h26a9.5 9.5 0 0 1 0 19H68a9.5 9.5 0 0 1-9.5-9.5Z"
      />
      <path d="M107.5 44.5H105v-3h2.5a8 8 0 0 1 0 16h-23a8 8 0 0 1-8-8h3a5 5 0 0 0 5 5h23a5 5 0 0 0 0-10Z" />
    </g>
    <mask id="g" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="128" height="128">
      <circle cx="64" cy="64" r="63.5" fill="url(#e)" stroke="url(#f)" />
    </mask>
    <g mask="url(#g)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30 58.5C30 51.044 36.044 45 43.5 45S57 51.044 57 58.5 50.956 72 43.5 72 30 65.956 30 58.5ZM43.5 42C34.387 42 27 49.387 27 58.5S34.387 75 43.5 75c2.995 0 5.805-.798 8.226-2.194L61.95 93.254A7.68 7.68 0 0 0 76.5 89.82V74a2.5 2.5 0 0 1 5 0v3a8.5 8.5 0 0 0 8.5 8.5h45.5v-3H90a5.5 5.5 0 0 1-5.5-5.5v-3a5.5 5.5 0 1 0-11 0v15.82a4.68 4.68 0 0 1-8.867 2.093L54.205 71.056A16.463 16.463 0 0 0 60 58.5C60 49.387 52.613 42 43.5 42Z"
        fill="#7F7F8C"
      />
    </g>
    <defs>
      <linearGradient id="a" x1="1.767" y1=".74" x2="42.916" y2="147.664" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" stop-opacity=".08" />
        <stop offset="1" stop-color="#fff" stop-opacity=".04" />
      </linearGradient>
      <linearGradient id="b" x1="113" y1="20.5" x2="55.5" y2="57.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2B2B33" />
        <stop offset=".835" stop-color="#2B2B33" stop-opacity=".112" />
        <stop offset=".901" stop-color="#2B2B33" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="c" x1="1.767" y1=".74" x2="42.916" y2="147.664" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" stop-opacity=".08" />
        <stop offset="1" stop-color="#fff" stop-opacity=".04" />
      </linearGradient>
      <linearGradient id="e" x1="96.5" y1="37" x2="27" y2="77.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2B2B33" />
        <stop offset=".516" stop-color="#2B2B33" stop-opacity=".854" />
        <stop offset=".88" stop-color="#2B2B33" stop-opacity=".073" />
        <stop offset=".906" stop-color="#2B2B33" stop-opacity=".032" />
        <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="f" x1="128" y1="102.4" x2="2.274" y2="96.99" gradientUnits="userSpaceOnUse">
        <stop stop-color="#fff" stop-opacity=".04" />
        <stop offset="1" stop-color="#fff" stop-opacity=".08" />
      </linearGradient>
    </defs>
  </svg>

  <div class="paragraph search-label">{{ label || 'No search results' }}</div>
</ng-template>
