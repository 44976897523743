<div class="member-container" *ngIf="!loading">
  <div class="cree8-table-container" *ngIf="members?.length > 0; else noMembers">
  <table class="cree8-table">
    <thead>
      <tr>
        <th>Member</th>
        <th>Email</th>
        <th>Last activity</th>
        <th style="text-align: center;">Quick links</th>
      </tr>
    </thead>
    <tbody class="scrollbar">
      <tr *ngFor=" let member of members" (click)="navigateToProfile(member._id)">
        <td>
          <div class="td-name">
            <div class="image" title="{{member.status}} {{member.lastSeen}}">
              <img [src]="getUserImage(member)" alt="{{ member?.name }}">
              <span class="status" [ngClass]="member.status"></span>
            </div>
            {{ member.name }}
          </div>
        </td>
        <td>{{ member.email }}</td>
        <td>{{ member.last_login | date:'dd-MMM-yyyy hh:mm a' }}</td>
        <td>
          <div class="td-icons">
            <cree8-btn-icon *ngIf="member.userPreferences?.assignedToWorkstations"
              (click)="onWorkStationsModal(member, $event)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Workstations'"></use>
              </svg>
            </cree8-btn-icon>
            <cree8-btn-icon (click)="onProjectsModal(member, $event)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Projects'"></use>
              </svg>
            </cree8-btn-icon>
            <cree8-btn-icon (click)="onSettingsModal(member, $event)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Settings'"></use>
              </svg>
            </cree8-btn-icon>
          </div>

        </td>
      </tr>
    </tbody>
  </table>
  </div>

  <!-- Template for when there are no invites -->
  <ng-template #noMembers>
    <no-data [title]="'No members yet?'" [icon]="'Team'" [description]="
        'Let\'s not wait any longer—start inviting members'
      "><cree8-btn (click)="onInviteMember()">INVITE</cree8-btn></no-data>

  </ng-template>
</div>

<bebop-loader [status]="'Loading'" [rounded]="false" *ngIf="loading"></bebop-loader>

<user-invite *ngIf="inviteMemberModal.active" [(active)]="inviteMemberModal.active"
  (confirmed)="onCancelModal()"></user-invite>

<user-projects *ngIf="projectsModal.active" [user]="selectedMember" [(active)]="projectsModal.active"
  (confirmed)="onCancelModal()"></user-projects>

<user-workstations *ngIf="workStationsModal.active" [user]="selectedMember" [(active)]="workStationsModal.active"
  (confirmed)="onCancelModal()"></user-workstations>

<user-settings *ngIf="settingsModal.active" [user]="selectedMember" [(active)]="settingsModal.active"
  (closeModal)="onCancelModal()"></user-settings>
