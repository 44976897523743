import { Component } from '@angular/core';

@Component({
  selector: 'cree8-button-icon',
  templateUrl: './cree8-button-icon.component.html',
  styleUrl: './cree8-button-icon.component.scss'
})
export class Cree8ButtonIconComponent {

}
