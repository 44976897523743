import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { catchError, finalize, Observable, of, tap } from 'rxjs'
import { RemoteNavTree } from 'src/app/components/rocket/common/classes/rocket-types'
import { ListUsers, Organization, Project, UpdateProjectsSettigs, User } from 'src/app/models/bebop.model'
import {
  IParamsProjects,
  IProjectDetail,
  IResponseProjects,
  IResponseUpdateProjectSettings,
  PayloadUpdateProjectSettings,
} from 'src/app/models/projects.model'
import { PayloadUpdateUsersProject, ProjectResponse } from 'src/app/models/response.model'
import { BebopConfigService } from 'src/app/services/bebop-config.service'

import { SessionQuery } from '../session/session.query'
import { UIQuery } from '../ui/ui.query'

import { ProjectsQuery } from './projects.query'
import { ProjectsStore } from './projects.store'

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private qParams: IParamsProjects
  private org: Organization

  constructor(
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private projectsStore: ProjectsStore,
    private projectsQuery: ProjectsQuery,
    private uiQuery: UIQuery
  ) {
    this.projectsQuery.getQueryParamsList().subscribe((qParams) => {
      this.qParams = qParams
    })

    this.uiQuery.getSelectedOrg().subscribe((org: Organization) => {
      this.org = org
    })
  }

  getListProjects(
    userId: string,
    projectId: string | null = null,
    organizationId?: string,
    hasMore?: boolean
  ): Observable<IResponseProjects | any> {
    let queryParams = new HttpParams()
      .set('page', this.qParams.page.toString())
      .set('size', this.qParams.size.toString())
      .set('searchText', this.qParams.searchText.trim())
      .set('sort', this.qParams.sort)
      .set('user', userId)

    if (!this.org) {
      queryParams = queryParams.append('organization', organizationId)
    }

    if (projectId !== null && projectId.length > 0) {
      queryParams = queryParams.append('projectId', projectId)
      this.projectsStore.update((state) => ({
        ...state,
        queryParamsList: {
          ...state.queryParamsList,
          projectId,
        },
      }))
    }

    this.projectsStore.update((state) => ({
      ...state,
      isLoading: true,
    }))

    return this.http
      .get<IResponseProjects>(`${this.bebopConfig.apiUrl}/api/v1/orgProjects`, {
        params: queryParams,
      })
      .pipe(
        catchError(() => {
          this.projectsStore.update((state) => ({
            ...state,
            listProjects: [],
          }))
          // Return an empty array to keep the observable stream alive
          return of({ data: [] })
        }),
        tap((response: IResponseProjects) => {
          this.projectsStore.update((state) => ({
            ...state,
            hasMore: response.hasMore,
            listProjects: hasMore ? [...state.listProjects, ...(response.data || [])] : response.data || [],
          }))
        }),
        finalize(() => {
          this.projectsStore.update((state) => ({
            ...state,
            isLoading: false,
          }))
        })
      )
  }

  updatePage() {
    this.projectsStore.update((state) => ({
      ...state,
      queryParamsList: {
        ...state.queryParamsList,
        page: this.qParams.page + 1,
      },
    }))
  }

  searchProjects(searchText: string) {
    this.projectsStore.update((state) => ({
      ...state,
      queryParamsList: {
        ...state.queryParamsList,
        page: 1,
        searchText,
        size: 25,
      },
    }))

    this.getListProjects(this.qParams.user).subscribe()
  }

  sortProjects(sort: string) {
    this.projectsStore.update((state) => ({
      ...state,
      queryParamsList: {
        ...state.queryParamsList,
        page: 1,
        size: 25,
        sort,
      },
    }))

    this.getListProjects(this.qParams.user).subscribe()
  }

  setSelectedProject(project: Project) {
    this.projectsStore.update((state) => ({
      ...state,
      selectedProject: project,
    }))
  }

  deleteProject(projectId: string): Observable<{ msg: string }> {
    return this.http.put<{ msg: string }>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/toggle`, {
      delete: true,
      is_active: false,
    })
  }

  // updateProjectSettings(
  //   projectId: string,
  //   settings: PayloadUpdateProjectSettings
  // ): Observable<IResponseUpdateProjectSettings> {
  //   return this.http.post<IResponseUpdateProjectSettings>(`${this.bebopConfig.apiUrl}/api/v1/myprojects/${projectId}`, {
  //     ...settings,
  //   })
  // }

  setProjectPage(page: 'root' | 'details') {
    this.projectsStore.update((state) => ({
      ...state,
      projectPage: page,
    }))
  }

  updateUsersProject(projectId: string, userId: string, payload: PayloadUpdateUsersProject): Observable<Project> {
    return this.http.post<Project>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/users/${userId}`, {
      ...payload,
    })
  }

  setNavTree(navTree: RemoteNavTree[]) {
    this.projectsStore.update((state) => ({
      ...state,
      filesProps: {
        ...state.filesProps,
        navTree,
      },
    }))
  }

  resetListProject() {
    this.projectsStore.update((state) => ({
      ...state,
      listProjects: [],
    }))
  }

  resetStore() {
    this.projectsStore.update((state) => ({
      ...state,
      filesProps: {
        navTree: [],
      },
      listProjects: [],
      membersProps: {
        load: false,
        search: '',
        sort: '-date_created',
      },
      queryParamsList: {
        page: 1,
        searchText: '',
        size: 25,
        sort: '-date_created',
        user: '',
      },
      selectedProject: null,
    }))
  }

  clear() {
    this.projectsStore.reset()
  }
}
