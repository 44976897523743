import { Injectable } from '@angular/core'

const SPACE_REG = /^(\s*)([\s\S]*?)(\s*)$/
// Regular Expressions for parsing tags and attributes
const START_TAG_REGEXP =
    /^<((?:[a-zA-Z])[\w:-]*)((?:\s+[\w:-]+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)\s*(>?)/
const END_TAG_REGEXP = /^<\/\s*([\w:-]+)[^>]*>/
const ATTR_REGEXP = /([\w:-]+)(?:\s*=\s*(?:(?:"((?:[^"])*)")|(?:'((?:[^'])*)')|([^>\s]+)))?/g
const BEGIN_TAG_REGEXP = /^</
const BEGING_END_TAGE_REGEXP = /^<\//
const COMMENT_REGEXP = /<!--(.*?)-->/g
const DOCTYPE_REGEXP = /<!DOCTYPE([^>]*?)>/i
const CDATA_REGEXP = /<!\[CDATA\[(.*?)]]>/g
const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g
// Match everything outside of normal chars and " (quote character)
const NON_ALPHANUMERIC_REGEXP = /([^\#-~| |!])/g

const LANG = 'en-AU' // navigator.language

@Injectable({
  providedIn: 'root',
})
export class BrowserUtilsService {
  minute = 60
  hour = this.minute * 60
  day = this.hour * 24
  week = this.day * 7
  month = this.day * 30
  year = this.day * 365

  constructor() {}

  /**
   * Convert a date to a relative time string, such as
   * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
   */
  getRelativeTimeString(
    date: Date | number,
    opts: Intl.RelativeTimeFormatOptions = {
      numeric: 'always',
      style: 'long', // 'short', 'long'
    },
    lang = LANG
  ): string {
    const time = date instanceof Date ? date.getTime() : new Date(date).getTime()
    const delta = Math.round((time - Date.now()) / 1000)
    const absoluteDelta = Math.abs(delta)
    const times: [number, Intl.RelativeTimeFormatUnit, number][] = [
      [this.minute, 'second', 1],
      [this.hour, 'minute', this.minute],
      [this.day, 'hour', this.hour],
      [this.week, 'day', this.day],
      [this.month, 'week', this.week],
      [this.year, 'month', this.month],
      [Infinity, 'year', this.year],
    ]
    let divider = this.year
    let timeType: Intl.RelativeTimeFormatUnit = 'year'
    for (const [num, timeInterval, div] of times) {
      if (absoluteDelta < num) {
        divider = div
        timeType = timeInterval
        break
      }
    }
    const rtf = new Intl.RelativeTimeFormat(lang, opts)
    const val = delta / divider

    return rtf.format(val < 0 ? Math.ceil(val) : Math.floor(val), timeType)
  }

  _hiddenPre: HTMLPreElement
  _getHiddenPre() {
    if (this._hiddenPre) return this._hiddenPre

    this._hiddenPre = window.document.createElement('pre')

    return this._hiddenPre
  }

  // Extracted from old angular-sanitizer
  decodeEntities(value: string) {
    if (!value) {
      return ''
    }

    let hiddenPre: any = this._getHiddenPre()

    // Note: IE8 does not preserve spaces at the start/end of innerHTML
    // so we must capture them and reattach them afterward
    let parts = SPACE_REG.exec(value)
    let spaceBefore = parts[1]
    let spaceAfter = parts[3]
    let content = parts[2]
    if (content) {
      hiddenPre.innerHTML = content.replace(/</g, '&lt;')
      content = hiddenPre.textContent
    }
    return spaceBefore + content + spaceAfter
  }

  encodeEntities(value: string) {
    return value
      .replace(/&/g, '&amp;')
      .replace(SURROGATE_PAIR_REGEXP, function (value) {
        var hi = value.charCodeAt(0)
        var low = value.charCodeAt(1)
        return '&#' + ((hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000) + ';'
      })
      .replace(NON_ALPHANUMERIC_REGEXP, function (value) {
        return '&#' + value.charCodeAt(0) + ';'
      })
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
  }
}
