/* eslint-disable @stylistic/js/max-len */
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'cree8-team-members-card',
  styleUrls: ['./team-members.component.scss'],
  templateUrl: './team-members.component.html',
})
export class TeamMembersComponent implements OnInit {
  @Input() data: { info: string; available: boolean; desc: string }

  sanitizedIcon: any // Store sanitized SVG

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon)
  }

  onButtonClick = () => {
    this.router.navigate(['app/team'])
  }

  get icon() {
    return `
           <svg width="150" height="118" viewBox="0 0 150 118" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_15_7170)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M24 116.593C24 102.091 35.6718 90.3351 50.0698 90.3351H83C97.3979 90.3351 109.07 102.091 109.07 116.593V122.121C109.07 124.41 107.227 126.267 104.953 126.267C102.68 126.267 100.837 124.41 100.837 122.121V116.593C100.837 106.671 92.8512 98.627 83 98.627H50.0698C40.2185 98.627 32.2326 106.671 32.2326 116.593V122.121C32.2326 124.41 30.3896 126.267 28.1163 126.267C25.8429 126.267 24 124.41 24 122.121V116.593Z" fill="url(#paint0_linear_15_7170)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M66.5349 32.2919C56.6837 32.2919 48.6977 40.3354 48.6977 50.2577C48.6977 60.1799 56.6837 68.2234 66.5349 68.2234C76.3861 68.2234 84.3721 60.1799 84.3721 50.2577C84.3721 40.3354 76.3861 32.2919 66.5349 32.2919ZM40.4651 50.2577C40.4651 35.756 52.137 24 66.5349 24C80.9328 24 92.6047 35.756 92.6047 50.2577C92.6047 64.7594 80.9328 76.5153 66.5349 76.5153C52.137 76.5153 40.4651 64.7594 40.4651 50.2577Z" fill="url(#paint1_linear_15_7170)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.814 94.4811C111.814 92.1913 113.657 90.3351 115.93 90.3351C130.328 90.3351 142 102.091 142 116.593V122.121C142 124.41 140.157 126.267 137.884 126.267C135.61 126.267 133.767 124.41 133.767 122.121V116.593C133.767 106.671 125.781 98.627 115.93 98.627C113.657 98.627 111.814 96.7708 111.814 94.4811Z" fill="url(#paint2_linear_15_7170)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M95.3488 28.1459C95.3488 25.8562 97.1918 24 99.4651 24C113.863 24 125.535 35.756 125.535 50.2577C125.535 64.7594 113.863 76.5153 99.4651 76.5153C97.1918 76.5153 95.3488 74.6591 95.3488 72.3694C95.3488 70.0796 97.1918 68.2234 99.4651 68.2234C109.316 68.2234 117.302 60.1799 117.302 50.2577C117.302 40.3354 109.316 32.2919 99.4651 32.2919C97.1918 32.2919 95.3488 30.4357 95.3488 28.1459Z" fill="url(#paint3_linear_15_7170)"/>
</g>
<defs>
<linearGradient id="paint0_linear_15_7170" x1="83" y1="34.3332" x2="83" y2="142.492" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_15_7170" x1="83" y1="34.3332" x2="83" y2="142.492" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_15_7170" x1="83" y1="34.3332" x2="83" y2="142.492" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_15_7170" x1="83" y1="34.3332" x2="83" y2="142.492" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_15_7170">
<rect width="150" height="118" rx="10" fill="white"/>
</clipPath>
</defs>
</svg>
`
  }
}
