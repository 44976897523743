import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ICardPrice } from '../../card-price/card-price.model';

@Component({
  selector: 'website-subscription',
  templateUrl: './website-subscription.component.html',
  styleUrl: './website-subscription.component.scss'
})
export class WebsiteSubscriptionComponent implements AfterViewInit {
  solutions = ['Flexible Licensing', 'Customized Quotas', 'Dedicated Hosting', 'Expert Onboarding & Support', 'Exclusive Access'];
  @Input() dataSubscriptions: ICardPrice[] = [];
  @Input() isErrFetchSubs: boolean = false;
  @Output() clickContact: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectSubscription: EventEmitter<ICardPrice> = new EventEmitter<ICardPrice>();

  constructor(private elementRef: ElementRef) {}

  onSelect(subscription: ICardPrice) {
    this.selectSubscription.emit(subscription)
  }

  onContact() {
    this.clickContact.emit(true)
  }

  ngAfterViewInit() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target as HTMLElement;
            const delay = element.getAttribute('data-delay');
            element.style.transitionDelay = delay ? `${delay}ms` : '0ms';
            element.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements =
      this.elementRef.nativeElement.querySelectorAll(".fade-up");
    elements.forEach((el: Element) => observer.observe(el));
  }
}
