
<card-dashboard *ngIf="data.available" 
[title]="data.info" 
[subtitle]="'Projects'" 
[description]="data.desc" 
[icon]="sanitizedIcon" 
[available]="data.available"  
[onButtonClick]="onButtonClick"/>



<card-dashboard *ngIf="!data.available" 
[title]="'Step 01'" 
[subtitle]="'Create a project'" 
[description]="" 
[icon]="sanitizedIcon" 
[available]="data.available"  
[buttonText]="'NEW PROJECT'"
[onButtonClick]="onButtonClick"/>
