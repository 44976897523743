import { RocketService } from '../rocket.service'

export interface RocketTokenProps {
  userId: string
  transferSessionID: string
  projectId?: string
  machineId?: string
  linkId?: string
}

export class RocketToken {
  tokenParams: RocketTokenProps
  _tokenCache: any = {}
  latencyThreshold = 1 * 1000 // 1s

  constructor(protected rocket: RocketService) {}

  updateTokenParams(params: RocketTokenProps) {
    this.tokenParams = params
  }

  newToken(params: RocketTokenProps, cb: (err?: Error, data?: any) => void) {
    return this.rocket.newRocketToken(params, cb)
  }

  resolveDNS(host: string, cb: (err?: Error, data?: any) => void) {
    return this.rocket.resolveDNS(host, cb)
  }

  reset(params: RocketTokenProps) {
    params = Object.assign(params || {}, this.tokenParams)
    if (!params.projectId || !params.transferSessionID || !params.userId) {
      console.error('Invalid reset request', params)
      return
    }

    let key = [params.projectId, params.transferSessionID, params.userId].join('-')
    if (this._tokenCache[key] && this._tokenCache[key].value) delete this._tokenCache[key]
  }

  getToken(params: RocketTokenProps) {
    if (!this.tokenParams) {
      return Promise.reject('No rocket params to generate tokens')
    }

    params = Object.assign(params || {}, this.tokenParams)

    if (!params.projectId || !params.transferSessionID || !params.userId) {
      return Promise.reject('Invalid input')
    }

    let key = [params.projectId, params.transferSessionID, params.userId].join('-')
    let cache = this._tokenCache[key]
    let now = Date.now()

    if (
      cache &&
      cache.promise &&
      (!cache.value || (cache.value && cache.value.validity - this.latencyThreshold > now))
    ) {
      return cache.promise
    }

    this._tokenCache[key] = cache = {}
    cache.promise = new Promise((resolve, reject) => {
      this.newToken(params, (err, result) => {
        let error = err || result?.error
        if (error) {
          // growl notification ?
          console.error('Token creation error', error)
          delete this._tokenCache[key]
          reject(error)
          return
        }

        let data = result || {}
        cache.value = Object.assign({}, params, {
          token: data.token,
          validity: now + +data.validity,
        })
        // console.log(cache.value)
        resolve(cache.value)
      })
    })
    return cache.promise
  }
}

export class RocketFileToken extends RocketToken {
  private _machineId: string
  constructor(rocket: RocketService, machineId?: string) {
    super(rocket)

    if (machineId) {
      this._machineId = machineId
    } else {
      rocket.MachineId.then((id) => (this._machineId = id))
    }
  }

  override newToken(params: RocketTokenProps, cb: (err?: Error, data?: any) => void): void {
    params.machineId = this._machineId
    return this.rocket.newRocketFileToken(params, cb)
  }

  override getToken(data: RocketTokenProps) {
    return new Promise((resolve, reject) => {
      this.newToken(data, (err, result) => {
        let error = err || result?.error
        if (error) {
          // growl notification ?
          console.error('Download token creation error', error)
          reject(error)
          return
        }

        let data = result || {}
        if (data.token) {
          resolve({ downloadToken: data.token })
        } else {
          reject({
            error: {
              msg: 'Invalid token',
            },
          })
        }
      })
    })
  }
}
