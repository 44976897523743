<div id="particle-container">
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
  <div class="particle"></div>
</div>
<canvas class="particles" #particles></canvas>
<div class="bebop-updater">
  <ng-container *ngIf="isSplashView">
    <div class="splash-section">
      <img src="/assets/img/bebop_core_logo_no_tech.png" width="500px" />
    </div>
  </ng-container>

  <ng-container *ngIf="!isSplashView">
    <div class="logo-section">
      <img src="/assets/img/bebop_core_logo_dark.png" />
    </div>

    <div
      class="loading"
      *ngIf="
        updateState == 'Updating' ||
        updateState == 'Install' ||
        updateState == 'InstallPatch' ||
        updateState == 'UpdatingPatch'
      "
    >
      <img src="/assets/img/updater/updating.gif" />
    </div>

    <div class="load-error" *ngIf="updateState == 'Error' || updateState == 'ErrorPatch'">
      <img src="/assets/img/updater/update_error.gif" />
    </div>

    <div class="message h2 install" *ngIf="updateState == 'Install'">Starting client update in 5 seconds</div>
    <div class="message h2 install" *ngIf="updateState == 'InstallPatch'">Starting patch update in 3 seconds</div>

    <div class="message h2" *ngIf="updateState == 'Updating'">New Version Available</div>
    <div class="message h2" *ngIf="updateState == 'UpdatingPatch'">New Patch Available</div>
    <div class="message h2" *ngIf="updateState == 'Error'">Unknown error <span class="spin-label">:(</span></div>
    <div class="message h2" *ngIf="updateState == 'ErrorPatch'">
      Patch Update Error <span class="spin-label">:(</span>. Skipped Patch Update.
    </div>
    <div class="submessage paragraph" *ngIf="updateState == 'Updating' || updateState == 'UpdatingPatch'">
      {{ download == 0 ? 'Downloading…' : 'Downloading ' + download + '％' }}
    </div>
    <div class="submessage error paragraph" *ngIf="updateState == 'Error'">
      Apologies, we were unable to automatically update the CREE8 Client. Please
      <span class="link-download" (click)="downloadClient()">download the latest version</span> and reinstall it.
    </div>
  </ng-container>
</div>
