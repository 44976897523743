
<card-dashboard *ngIf="data.available" 
[title]="data.info" 
[subtitle]="'Workstation hours'" 
[description]="data.desc" 
[icon]="sanitizedIcon" 
[available]="data.available"
[onButtonClick]="onButtonClick"/>


<card-dashboard *ngIf="!data.available" 
[title]="'Step 02'" 
[subtitle]="'Setup a workstation'" 
[description]="" 
[icon]="sanitizedIcon" 
[available]="data.available"  
[buttonText]="'ADD WORKSTATION'"
[onButtonClick]="onButtonClick"/>
