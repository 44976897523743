import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { InputItemsSelect, InputOptionItem } from 'src/app/common/types'
import { Organization } from 'src/app/models/bebop.model'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIService } from 'src/app/store/ui/ui.service'

@Component({
  selector: 'cree8-org-selection',
  styleUrl: './org-selection.component.scss',
  templateUrl: './org-selection.component.html',
})
export class OrgSelectionComponent implements OnInit {
  @Output() organizationSelected = new EventEmitter<any>()

  organizations: Organization[] = []
  orgSelect: InputItemsSelect = {
    items: [],
    value: '',
  }
  private destroy$ = new Subject<void>()

  selectModal = {
    contentOnly: true,
    title: 'Select a workspace',
    type: 'close',
  } as Cree8Modal

  constructor(
    private sessionQuery: SessionQuery,
    private uiService: UIService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.sessionQuery
      .getOrganizations()
      .pipe(takeUntil(this.destroy$))
      .subscribe((orgs) => {
        this.organizations = orgs
        this.orgSelect.items = orgs.map((org) => ({
          _id: org._id,
          logo: org.logo,
          selected: this.orgSelect.value == org.name,
          value: org.name,
        }))
      })
  }

  onSelectOrganization(item: InputOptionItem) {
    let org = this.organizations.find((o) => o._id == item?._id)
    this.organizationSelected.emit(item)
    this.router.navigate(['/app/dashboard'])
    this.uiService.setSelectedOrg(org || null)
  }
}
