import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { LinkAction } from 'src/app/common/enums'
import { OnExternalModalClose, BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { WorkstationLaunchType } from '../../classes/workstation-types'
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations'

export interface InfoWorkstationAction {
  name: 'Cancel' | 'Close'
}

@Component({
  selector: 'bebop-info-workstation',
  templateUrl: './info-workstation.component.html',
  styleUrls: ['./info-workstation.component.scss'],
  animations: [
    trigger('slideAnim', [
      transition(':enter', [
        style({ position: 'relative', right: '-322px' }),
        animate('300ms ease-in', style({ right: '0px' })),
      ]),
      transition(':leave', [
        style({ position: 'relative', right: '0px' }),
        animate('300ms ease-out', style({ right: '-322px' })),
      ]),
    ]),
  ],
})
export class InfoWorkstationComponent implements OnInit, OnExternalModalClose {
  constructor(
    public ref: ModalOverlayRef<InfoWorkstationComponent, InfoWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private util: BebopClientUtilsService
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  onDownload(e: WorkstationLaunchType) {
    switch (e) {
      case 'PCoIP':
        return this.util.openExternalLink(LinkAction.PCOIP_CLIENTS_DOWNLOAD)
      case 'Jump':
        return this.util.openExternalLink(LinkAction.JUMP_DOWNLOAD)
      case 'Parsec':
        return this.util.openExternalLink(LinkAction.PARSEC_DOWNLOAD)
      default:
        console.error('Invalid client', e)
    }
  }

  _closed = false
  async beforeClose() {
    this._closed = true
    await this.ref.sleep(300)
  }
}
