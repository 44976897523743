import { Component, Output, TemplateRef, ViewChild, EventEmitter, Input, OnInit, OnDestroy, SimpleChanges } from '@angular/core'
import { BehaviorSubject, debounce, debounceTime } from 'rxjs'
import { DropdownPanel } from '../../types'

/**

  <div class="wrapper">
    <bebop-button
      [bebopDropdownTriggerFor]="dropdown"
      placement="bottom"
      placement-tooltip-position="right"
      class="dropdown-test"
    >
      Open dropdown
    </bebop-button>

    <bebop-dropdown #dropdown>
      <div (click)="test()" class="my-dropdown-item">Cut</div>
      <div (click)="test()" class="my-dropdown-item">Copy</div>
      <div (click)="test()" class="my-dropdown-item">Paste</div>
      <div class="dropdown-separator"></div> <!-- no need to style dropdown-separator -->
      <div (click)="test()" class="my-dropdown-item">Paste Special</div>
    </bebop-dropdown>
  </div>

  .my-dropdown-item {
    padding: 12px;
  }

  .wrapper button {
    width: 200px;
    height: 50px;
  }

*/

@Component({
  selector: 'bebop-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements DropdownPanel, OnInit {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>
  @Output() closed = new EventEmitter<void>()

  @Input() panelClasses: string[]

  @ViewChild('ddContent') ddContent

  get isEmpty() {
    return this.ddContent?.nativeElement?.childElementCount == 0 || true
  }

  cssClasses: { [key: string]: boolean } = {}

  constructor() {}

  ngOnInit(): void {
    ;(this.panelClasses || []).reduce((prev, curr) => {
      prev[curr] = true
      return prev
    }, this.cssClasses)
  }

}
