export enum LinkAction {
  LAUNCH_SESSION_BEST_PRACTICES,
  ZERO_CLIENT,
  CAST_BEST_PRACTICES,
  SUPPORT_WORKSTATION,
  SUPPORT_UPLOAD,
  SUPPORT_DOWNLOAD,
  SUPPORT_HOT_FOLDER,
  SUPPORT_BEBOP_LINK,
  SUPPORT_BROADCASTS,
  SUPPORT_FLEX,
  SUPPORT_MAIN,
  SUPPORT_PORTAL,
  PCOIP_CLIENTS_DOWNLOAD,
  PARSEC_DOWNLOAD,
  JUMP_DOWNLOAD,
  LUCID_DOWNLOAD_OSX,
  LUCID_DOWNLOAD_WIN,
  BBP_BROADCASTER_DOWNLOAD_OSX,
  BBP_BROADCASTER_DOWNLOAD_WIN,
  BBP_CLIENT_DOWNLOAD,
  QUICK_START_GUIDE_BEBOP_SOLO_FREE_TRIAL,
  NDI_OSX_DOWNLOAD,
  NDI_WIN_DOWNLOAD,
}
