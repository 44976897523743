import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { SafeHtmlPipe } from 'src/app/common/pipes/safe-html.pipe'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { UserWorkstation, Workstation } from 'src/app/models/bebop.model'
import { UiWorkstation } from '../../classes/workstation-types'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'
import { StandardDataResponse } from 'src/app/models/response.model'
import { ToastService } from 'src/app/common/components/toast/toast.service'

export interface EditWorkstationNicknameAction {
  name: 'Cancel' | 'Save' | 'Failed'
  payload?: {
    nickname: string
  }
}

export interface EditWorkstationNicknamePayload {
  workstation: UiWorkstation
}

@Component({
  selector: 'bebop-edit-workstation-nickname',
  templateUrl: './edit-workstation-nickname.component.html',
  styleUrls: ['./edit-workstation-nickname.component.scss'],
})
export class EditWorkstationNicknameComponent implements OnInit, OnExternalModalClose {
  workstation: UiWorkstation
  nickname = ''
  oldNickname = ''

  error = ''

  constructor(
    public ref: ModalOverlayRef<EditWorkstationNicknameComponent, EditWorkstationNicknameAction>,
    @Inject(BEBOP_MODAL_DATA) public data: EditWorkstationNicknamePayload,
    private wservice: WorkstationService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.workstation = this.data.workstation
    this.nickname = this.oldNickname = this.workstation?.nickname || ''
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  ok() {
    if (this.error) return
    let nickname = this.nickname?.trim()
    this.wservice
      .editWorkstationNickname(this.workstation?.source, nickname)
      .subscribe((e: StandardDataResponse<UserWorkstation>) => {
        if (e.error || !e.data) {
          this.toastService.show({
            type: 'error',
            text: 'Failed to edit workstation name',
          })

          this.ref.send({ name: 'Failed' })
          this.ref.close()
          return
        }

        this.workstation.nickname = nickname

        this.toastService.show({
          type: 'success',
          text: 'Nickname is updated',
        })
        this.ref.send({ name: 'Save', payload: { nickname } })
        this.ref.close()
      })
  }

  onKeyupUsername(ev: KeyboardEvent): void {
    if (!this.oldNickname && this.nickname?.trim()?.length < 1) this.error = 'Nickname should not be empty'
    else if (this.nickname?.length >= 50) this.error = 'Nickname should not exceed 50 characters'
    else this.error = ''

    if (ev?.key == 'Enter') {
      this.ok()
    }
  }
}
