// Add type declarations here

import { EventEmitter, TemplateRef } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'

// Ex
export type Participant = {
  id?: string
  name: string
  short_name: string
  muted: boolean
  self: boolean
  email?: string
  image?: string
}

export type Device = {
  id: string
  name: string
  checked: boolean
}

export type AudioDevices = {
  input: Device[]
  output: Device[]
}

export type TooltipType = 'Tooltip' | 'Pullover'
export type Placement = 'top' | 'right' | 'bottom' | 'left'
export type PlacementPositionX = 'center' | 'start' | 'end'
export type PlacementPositionY = 'top' | 'center' | 'bottom'
export type TooltipMessageType = 'error' | 'info' | 'warning' | 'success' | 'none'

export interface TooltipPayload {
  message: string
  messageType: TooltipMessageType
  background?: string
  borderBackground?: string
  width?: number
}

export interface TooltipProps {
  type?: TooltipType
  placement?: Placement
  tipPlacement?: PlacementPositionX | PlacementPositionY
  initialDelay?: number
  tooltip?: TooltipPayload
  parent?: HTMLElement
  offsetFromParent?: number

  leftOffsetPercent?: number

  containedLayout?: boolean
}

export interface DropdownPanel {
  templateRef: TemplateRef<any>
  readonly closed: EventEmitter<void>

  panelClasses?: string[]
}

// left & top, right & bottom are idential depends on the context
export type PlacementTooltipPosition = 'none' | 'left' | 'right' | 'center' | 'top' | 'bottom'

// pagination
export const PageSizes = [10, 25, 50, 100] as const
export type PageSize = (typeof PageSizes)[number]
export const MaxPageElementCount = 16
export const MinPageElementCount = 7 // keep it as odd number and no less than 7

export interface Pagination<T = any> {
  total: number // totalItems
  size: PageSize
  page: number // currentPage
  list?: T[]
  sort?: { reverse?: boolean }
}

export interface SortBy {
  [k: string]: {
    order: 'asc' | 'desc'
  }
}

export interface InputOptionItem {
  _id: string
  value: string
  selected?: boolean
  logo?: SafeUrl
}
export interface InputItemsSelect<T extends InputOptionItem = InputOptionItem> {
  value: string
  items: T[]
}

export type KeysOfValueType<T, V> = {
  [K in keyof T as T[K] extends V ? K : never]: T[K]
}

export type UnitSuffix = 'px' | 'em' | 'rem' | 'vh' | 'vw' | '%'
export type Units = `${number}${UnitSuffix}`
export type CSSSize = 0 | Units
