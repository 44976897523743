import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms'

@Component({
  selector: 'otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent implements OnInit {
  @Input() otpLength: number = 4
  @Input() error = false;
  @Input() type: 'number' | 'alphabet' | 'alphanumeric' = 'number';
  @Input() disabled = false

  otpForm!: FormGroup

  @Output() otpCompleted = new EventEmitter<string>()

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otpArray: this.fb.array(
        new Array(this.otpLength).fill('').map(() => this.fb.control(''))
      ),
    })
  }

  get otpArray() {
    return this.otpForm.get('otpArray') as FormArray
  }

  checkComplete() {
    if (this.otpArray.controls.every((control) => control.value !== '')) {
      const otpValue = this.otpArray.value.join('')
      this.otpCompleted.emit(otpValue)
    }
  }

  get validLengthOtp(): boolean{
    return this.otpArray.value.join('').length === this.otpLength;
  }

  onInput(value: string, index: number, event: any) {
    if (this.isValidInput(value)) {
      this.checkComplete()
      if (value.length === 1 && index < this.otpLength - 1) {
        this.focusNextInput(index)
      }
    } else {
      this.otpArray.controls[index].setValue('');
    }
  }

  onKeypress(index: number, event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      this.handleBackspaceDelete(index)
    }
  }

  private handleBackspaceDelete(index: number) {
    if (index > 0) {
      const prevInputId = `otpInput${index - 1}`
      const prevInput = document.getElementById(prevInputId)
      if (prevInput) {
        this.otpArray.controls[index].setValue('')
        prevInput.focus()
      }
    }
  }

  private focusNextInput(index: number) {
    const nextIndex = index + 1
    if (nextIndex < this.otpLength) {
      const nextInputId = `otpInput${nextIndex}`
      const nextInput = document.getElementById(nextInputId)
      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  private isValidInput(value: string): boolean {
    const regexMap: { [key: string]: RegExp } = {
      'number': /^[0-9]*$/,
      'alphabet': /^[a-zA-Z]*$/,
      'alphanumeric': /^[a-zA-Z0-9]*$/,
    };
    return regexMap[this.type].test(value);
  }

  getInputPattern(): string {
    const patternMap: { [key: string]: string } = {
      'number': '[0-9]',
      'alphabet': '[a-zA-Z]',
      'alphanumeric': '[a-zA-Z0-9]',
    };
    return patternMap[this.type];
  }
}
