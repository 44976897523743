import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { LinkAction } from 'src/app/common/enums'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { ElectronService } from 'src/app/services/electron.service'
import { UserService } from 'src/app/services/user.service'
import { WorkstationLaunchType } from '../../classes/workstation-types'

export interface NoAppWorkstationAction {
  name: 'Cancel' | 'Download'
}

export type AppInstallType = WorkstationLaunchType | 'Lucid' | 'BebopEarth'

@Component({
  selector: 'bebop-no-app-workstation',
  templateUrl: './no-app-workstation.component.html',
  styleUrls: ['./no-app-workstation.component.scss'],
})
export class NoAppWorkstationComponent implements OnInit, OnExternalModalClose {
  type: AppInstallType
  constructor(
    public ref: ModalOverlayRef<NoAppWorkstationComponent, NoAppWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private util: BebopClientUtilsService,
    private userService: UserService,
    private electronService: ElectronService
  ) {}

  ngOnInit(): void {
    this.type = <AppInstallType>this.data.type
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  get isLucidOrEarth() {
    return ['BebopEarth', 'Lucid'].includes(this.type)
  }

  download(d?: string) {
    let linkAction: LinkAction
    switch (this.type) {
      case 'Lucid':
        d = d || this.electronService.platform
        linkAction = d == 'win32' ? LinkAction.LUCID_DOWNLOAD_WIN : LinkAction.LUCID_DOWNLOAD_OSX
        break
      case 'PCoIP':
        linkAction = LinkAction.PCOIP_CLIENTS_DOWNLOAD
        break
      case 'Jump':
        linkAction = LinkAction.JUMP_DOWNLOAD
        break
      case 'Parsec':
        linkAction = LinkAction.PARSEC_DOWNLOAD
        break
      case 'Ultra':
        linkAction = LinkAction.PCOIP_CLIENTS_DOWNLOAD
        break

      case 'BebopEarth':
        d = d || this.electronService.platform
        linkAction = d == 'win32' ? LinkAction.BBP_BROADCASTER_DOWNLOAD_WIN : LinkAction.BBP_BROADCASTER_DOWNLOAD_OSX
        break
      default:
        console.error('Unhandled link action for not installed app prompt ', this.type, d)
        break
    }

    if (linkAction) {
      this.util.openExternalLink(linkAction)
    }

    this.ref.send({ name: 'Download' })
    this.ref.close()
  }
}
