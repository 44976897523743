import { CommonModule, NgOptimizedImage } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { AccordionComponent } from './components/accordion/accordion.component'
import { ActionsListComponent } from './components/actions-list/actions-list.component'
import { AppleSpinnerComponent } from './components/apple-spinner/apple-spinner.component'
import { BannerComponent } from './components/banner/banner.component'
import { ButtonComponent } from './components/button/button.component'
import { CardContainerComponent } from './components/card-container/card-container.component'
import { CardPriceComponent } from './components/card-price/card-price.component'
import { CheckboxButtonComponent } from './components/checkbox-button/checkbox-button.component'
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component'
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component'
import { CopyInputComponent } from './components/copy-input/copy-input.component'
import { Cree8BtnComponent } from './components/cree8-btn/cree8-btn.component'
import { Cree8BtnIconComponent } from './components/cree8-btn-icon/cree8-btn-icon.component'
import { Cree8ButtonComponent } from './components/cree8-button/cree8-button.component'
import { Cree8ButtonIconComponent } from './components/cree8-button-icon/cree8-button-icon.component'
import { Cree8CheckboxComponent } from './components/cree8-checkbox/cree8-checkbox.component'
import { Cree8ChipComponent } from './components/cree8-chip/cree8-chip.component'
import { Cree8DropdownComponent } from './components/cree8-dropdown/cree8-dropdown.component'
import { Cree8DropdownTriggerDirective } from './components/cree8-dropdown/cree8-dropdown-trigger.directive'
import { Cree8ImageUploadComponent } from './components/cree8-image-upload/cree8-image-upload.component'
import { Cree8InputComponent } from './components/cree8-input/cree8-input.component'
import { Cree8InputChipsComponent } from './components/cree8-input-chips/cree8-input-chips.component'
import { Cree8ListOptionsComponent } from './components/cree8-list-options/cree8-list-options.component'
import { Cree8ModalComponent } from './components/cree8-modal/cree8-modal.component'
import { Cree8SpinnerComponent } from './components/cree8-spinner/cree8-spinner.component'
import { Cree8TabsComponent } from './components/cree8-tabs/cree8-tabs.component'
import { Cree8ToastComponent } from './components/cree8-toast/cree8-toast.component'
import { Cree8ToggleComponent } from './components/cree8-toggle/cree8-toggle.component'
import { Cree8TooltipComponent } from './components/cree8-tooltip/cree8-tooltip.component'
import { DisplayDateTimeComponent } from './components/display-date-time/display-date-time.component'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { DropdownTriggerForDirective } from './components/dropdown/dropdown-trigger-for.directive'
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component'
import { InputComponent } from './components/input/input.component'
import { InputCodeComponent } from './components/input-code/input-code.component'
import { InputDateComponent } from './components/input-date/input-date.component'
import { PhoneNumberInputComponent } from './components/input-phone-number/input-phone-number.component'
import { InputSearchComponent } from './components/input-search/input-search.component'
import { InputSelectComponent } from './components/input-select/input-select.component'
import { InputSelectOptionComponent } from './components/input-select-option/input-select-option.component'
import { InputTagsComponent } from './components/input-tags/input-tags.component'
import { InputTimeComponent } from './components/input-time/input-time.component'
import { LinkButtonComponent } from './components/link-button/link-button.component'
import { LoaderComponent } from './components/loader/loader.component'
import { WarningPromptComponent } from './components/modals/warning-prompt/warning-prompt.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { NavbarSecondaryComponent } from './components/navbar-secondary/navbar-secondary.component'
import { NavigationBackComponent } from './components/navigation-back/navigation-back.component'
import { NotificationComponent } from './components/notification/notification.component'
import { OptionComponent } from './components/option/option.component'
import { OtpInputComponent } from './components/otp-input/otp-input.component'
import { PagintionComponent } from './components/pagintion/pagintion.component'
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component'
import { RadioButtonComponent } from './components/radio-button/radio-button.component'
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component'
import { ReloadComponent } from './components/reload/reload.component'
import { SocialMediaComponent } from './components/social-media/social-media.component'
import { SplitButtonComponent } from './components/split-button/split-button.component'
import { SplitNButtonComponent } from './components/split-n-button/split-n-button.component'
import { StepperSignupComponent } from './components/stepper-signup/stepper-signup.component'
import { StepsComponent } from './components/steps/steps.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { TagComponent } from './components/tag/tag.component'
import { TitleDescComponent } from './components/title-desc/title-desc.component'
import { ToastModule } from './components/toast/toast.module'
import { ToggleComponent } from './components/toggle/toggle.component'
import { TooltipWidgetComponent } from './components/tooltip-widget/tooltip-widget.component'
import { WebsiteComponent } from './components/website/website.component'
import { WebsiteAiTrainingComponent } from './components/website/website-ai-training/website-ai-training.component'
import { WebsiteClientsComponent } from './components/website/website-clients/website-clients.component'
import { WebsiteContainerComponent } from './components/website/website-container/website-container.component'
import { WebsiteCopyrightComponent } from './components/website/website-copyright/website-copyright.component'
import { WebsiteFooterComponent } from './components/website/website-footer/website-footer.component'
import { WebsiteHeroComponent } from './components/website/website-hero/website-hero.component'
import { WebsitePreInstalledComponent } from './components/website/website-pre-installed/website-pre-installed.component'
import { WebsiteSectionComponent } from './components/website/website-section/website-section.component'
import { WebsiteSubscriptionComponent } from './components/website/website-subscription/website-subscription.component'
import { WebsiteTestimoniComponent } from './components/website/website-testimoni/website-testimoni.component'
import { AutoFocusDirective } from './directives/auto-focus.directive'
import { ClickOutsideDirective } from './directives/click-outside.directive'
import { DragDropDirective } from './directives/drag-drop.directive'
import { SvgInjectDirective } from './directives/svg-inject.directive'
import { TooltipComponent } from './directives/tooltip.component'
import { Cree8TooltipDirective } from './directives/tooltip.directive'
import { FileSizePipe } from './pipes/bytes-transform.pipe'
import { RelativeDatePipe } from './pipes/relative-date.pipe'
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { TimeDiffPipe } from './pipes/time-diff.pipe';
import { CalendlyComponent } from './components/navbar/calendly/calendly.component'

@NgModule({
  declarations: [
    InputComponent,
    InputCodeComponent,
    PhoneNumberInputComponent,
    InputSearchComponent,
    TagComponent,
    InputTagsComponent,
    AutoFocusDirective,
    TooltipComponent,
    TooltipWidgetComponent,
    ButtonComponent,
    LinkButtonComponent,
    LoaderComponent,
    ClickOutsideDirective,
    SplitButtonComponent,
    CheckboxButtonComponent,
    RadioButtonComponent,
    RadioButtonGroupComponent,
    ProgressBarComponent,
    InputSelectComponent,
    InputSelectOptionComponent,
    DropdownTriggerForDirective,
    DropdownComponent,
    PagintionComponent,
    AppleSpinnerComponent,
    TabsComponent,
    ImageCarouselComponent,
    SafeHtmlPipe,
    RelativeDatePipe,
    FileSizePipe,
    TimeDiffPipe,
    SplitNButtonComponent,
    InputDateComponent,
    InputTimeComponent,
    StepsComponent,
    DragDropDirective,
    NotificationComponent,
    BannerComponent,
    WarningPromptComponent,
    CircularProgressComponent,
    StepperSignupComponent,
    OtpInputComponent,
    CardContainerComponent,
    TitleDescComponent,
    Cree8ButtonComponent,
    Cree8ToastComponent,
    NavigationBackComponent,
    CopyInputComponent,
    Cree8SpinnerComponent,
    ReloadComponent,
    NavbarComponent,
    AccordionComponent,
    CardPriceComponent,
    SocialMediaComponent,
    WebsiteComponent,
    Cree8TooltipComponent,
    WebsiteHeroComponent,
    WebsiteSectionComponent,
    WebsitePreInstalledComponent,
    WebsiteTestimoniComponent,
    WebsiteAiTrainingComponent,
    WebsiteCopyrightComponent,
    WebsiteFooterComponent,
    WebsiteClientsComponent,
    WebsiteSubscriptionComponent,
    NavbarSecondaryComponent,
    WebsiteContainerComponent,
    Cree8TabsComponent,
    ActionsListComponent,
    Cree8ModalComponent,
    DisplayDateTimeComponent,
    Cree8ButtonIconComponent,
    ToggleComponent,
    ConfirmationModalComponent,
    Cree8BtnComponent,
    OptionComponent,
    Cree8BtnComponent,
    SvgInjectDirective,
    Cree8TooltipDirective,
    Cree8BtnIconComponent,
    Cree8ToggleComponent,
    Cree8InputComponent,
    Cree8InputChipsComponent,
    Cree8ChipComponent,
    Cree8CheckboxComponent,
    Cree8ListOptionsComponent,
    Cree8ImageUploadComponent,
    Cree8DropdownComponent,
    Cree8DropdownTriggerDirective,
    CalendlyComponent
  ],
  exports: [
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    InputComponent,
    InputCodeComponent,
    PhoneNumberInputComponent,
    InputSearchComponent,
    TagComponent,
    InputTagsComponent,
    AutoFocusDirective,
    TooltipComponent,
    TooltipWidgetComponent,
    ButtonComponent,
    LinkButtonComponent,
    LoaderComponent,
    ClickOutsideDirective,
    SplitButtonComponent,
    CheckboxButtonComponent,
    RadioButtonComponent,
    RadioButtonGroupComponent,
    ProgressBarComponent,
    InputSelectComponent,
    InputSelectOptionComponent,
    DropdownTriggerForDirective,
    DropdownComponent,
    PagintionComponent,
    AppleSpinnerComponent,
    TabsComponent,
    ImageCarouselComponent,
    SafeHtmlPipe,
    RelativeDatePipe,
    FileSizePipe,
    TimeDiffPipe,
    SplitNButtonComponent,
    InputDateComponent,
    InputTimeComponent,
    StepsComponent,
    DragDropDirective,
    NotificationComponent,
    BannerComponent,
    CircularProgressComponent,
    StepperSignupComponent,
    OtpInputComponent,
    CardContainerComponent,
    TitleDescComponent,
    Cree8ButtonComponent,
    Cree8ToastComponent,
    NavigationBackComponent,
    CopyInputComponent,
    Cree8SpinnerComponent,
    ReloadComponent,
    NavbarComponent,
    AccordionComponent,
    CardPriceComponent,
    SocialMediaComponent,
    WebsiteComponent,
    Cree8TooltipComponent,
    WebsiteHeroComponent,
    WebsiteSectionComponent,
    WebsitePreInstalledComponent,
    WebsiteTestimoniComponent,
    WebsiteAiTrainingComponent,
    WebsiteCopyrightComponent,
    WebsiteFooterComponent,
    WebsiteClientsComponent,
    WebsiteSubscriptionComponent,
    NavbarSecondaryComponent,
    Cree8TabsComponent,
    ActionsListComponent,
    Cree8ModalComponent,
    DisplayDateTimeComponent,
    Cree8ButtonIconComponent,
    ToggleComponent,
    ConfirmationModalComponent,
    Cree8BtnComponent,
    OptionComponent,
    Cree8BtnComponent,
    SvgInjectDirective,
    Cree8TooltipDirective,
    Cree8BtnIconComponent,
    Cree8ToggleComponent,
    Cree8InputComponent,
    Cree8InputChipsComponent,
    Cree8ChipComponent,
    Cree8CheckboxComponent,
    Cree8ListOptionsComponent,
    Cree8ImageUploadComponent,
    Cree8DropdownComponent,
    Cree8DropdownTriggerDirective,
  ],
  imports: [
    ToastModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    RouterModule,
    NgOptimizedImage,
  ],
})
export class UiModule {}
