import { Component, Input } from '@angular/core'

@Component({
  selector: 'cree8-icon-indicator',
  styleUrl: './cree8-icon-indicator.component.scss',
  templateUrl: './cree8-icon-indicator.component.html',
})
export class Cree8IconIndicatorComponent {
  @Input() position: 'center' | 'left' | 'right' = 'center'
}
