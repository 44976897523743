<div class="bebop-input-select" (click)="onClickTopPanel()" [class.disabled]="disabled">
  <div class="input-top-section" tabindex="-1" *ngIf="showInputLabel">
    <div class="input-label small">
      <ng-content select="[input-label]"></ng-content>
    </div>

    <div class="input-guide small" #inputGuide tabindex="-1" *ngIf="showInputGuide">
      <ng-content select="[input-guide]"></ng-content>
    </div>
  </div>
  <div class="input-select-body paragraph" [class.selected]="isOpen">
    <div class="input-select-icon" #inputSelectIcon *ngIf="hasInputSelectIcon">
      <ng-content select="[dropdown-icon]"></ng-content>
    </div>

    <div class="input-select-value" [class.placeholder]="!value">{{ value || placeholder || '' }}</div>

    <div class="input-select-icon" [class.placeholder]="!value">
      <svg viewBox="0 0 20 20" fill="none" *ngIf="!isOpen">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.817 7.683a.625.625 0 0 1 0 .884l-4.375 4.375a.625.625 0 0 1-.884 0L5.183 8.567a.625.625 0 1 1 .884-.884L10 11.616l3.933-3.933a.625.625 0 0 1 .884 0Z"
          fill="#5A5A68"
        />
      </svg>

      <svg viewBox="0 0 20 20" fill="none" *ngIf="isOpen">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.817 12.942a.625.625 0 0 0 0-.884l-4.375-4.375a.625.625 0 0 0-.884 0l-4.375 4.375a.625.625 0 1 0 .884.884L10 9.009l3.933 3.933c.244.244.64.244.884 0Z"
          fill="#5A5A68"
        />
      </svg>
    </div>
  </div>

  <div
    class="input-select-dropdown"
    #inputSelectDropdown
    [class.hide]="!isOpen"
    [class.hasLabel]="showInputLabel"
    [class.disabled]="disabled"
    (bebopClickOutside)="!disabled && onClickOutside($event)"
  >
    <bebop-input-search
      [(binding)]="searchText"
      [placeholder]="searchPlaceholder || 'Search…'"
      clearButton="true"
      (on-keyup)="onSearchEntry($event)"
      *ngIf="!isSelectOnly"
      [autofocus]="isOpen"
      (focus)="onSearchFocus()"
      (click)="$event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault()"
    ></bebop-input-search>

    <div class="input-select-dropdown-options" [class.no-search]="isSelectOnly" [class.no-children]="!hasChildren" (click)="onClickDropdownInside($event)" #dropdownOptions>
      <ng-content select="[dropdown-options]"></ng-content>
      <!-- <div class="placeholder"></div> -->
    </div>
  </div>
</div>
