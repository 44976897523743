import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Option {
  title: string;
  description: string;
  value: string;
}

@Component({
  selector: 'cree8-list-options',
  templateUrl: './cree8-list-options.component.html',
  styleUrl: './cree8-list-options.component.scss',
})
export class Cree8ListOptionsComponent {
  @Input() options: Option[] = [];
  @Input() selectedOption: string = '';
  @Output() selectedOptionChange: EventEmitter<string> =
    new EventEmitter<string>();

  onOptionSelected(value: string) {
    this.selectedOptionChange.emit(value);
  }
}
