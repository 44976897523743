<div
  class="bebop-apple-loader"
  [class.icon-small]="size == 'small'"
  [class.icon-large]="size == 'large'"
  [class.xicon-large]="size == 'xlarge'"
>
  <div class="loader">
    <div class="centered">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
