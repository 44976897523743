<div class="cree8-card-file" (click)="onTitleClick()">
  <div class="thumbnail">
    <img *ngIf="image" class="card-image" [src]="image" [alt]="title" />
    <div *ngIf="icon" class="card-icon">
      <svg viewBox="0 0 24 24">
        <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#' + icon"></use>
      </svg>
    </div>
  </div>

  <div class="description">
    <div class="info">
      <span class="card-title body2">{{ title }}</span>
      <div class="card-subtitle captions">
        Size: {{ folderSize || '0 GB' }}
      </div>
    </div>
    <div (click)="$event.stopPropagation()">
      <ng-content select="[actions-menu]"></ng-content>
    </div>
  </div>
</div>
