<div class="bebop-retry-workstation">
  <div class="banner-container">
    <div class="workstation-banner">
      <div class="modal-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="12" fill="#0C0C0F" fill-opacity="0.32" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.84456 11.9999C6.84456 9.0544 9.23238 6.66659 12.1779 6.66659C15.1234 6.66659 17.5112 9.0544 17.5112 11.9999C17.5112 14.9454 15.1234 17.3333 12.1779 17.3333C9.23238 17.3333 6.84456 14.9454 6.84456 11.9999ZM12.1779 5.33325C8.496 5.33325 5.51123 8.31802 5.51123 11.9999C5.51123 15.6818 8.496 18.6666 12.1779 18.6666C15.8598 18.6666 18.8446 15.6818 18.8446 11.9999C18.8446 8.31802 15.8598 5.33325 12.1779 5.33325ZM12.8446 9.33325C12.8446 8.96506 12.5461 8.66659 12.1779 8.66659C11.8097 8.66659 11.5112 8.96506 11.5112 9.33325V11.9999C11.5112 12.3681 11.8097 12.6666 12.1779 12.6666C12.5461 12.6666 12.8446 12.3681 12.8446 11.9999V9.33325ZM12.1779 13.9999C11.8097 13.9999 11.5112 14.2984 11.5112 14.6666C11.5112 15.0348 11.8097 15.3333 12.1779 15.3333H12.1846C12.5528 15.3333 12.8512 15.0348 12.8512 14.6666C12.8512 14.2984 12.5528 13.9999 12.1846 13.9999H12.1779Z"
            fill="#F1344B"
            fill-opacity="0.8"
          />
        </svg>
      </div>
      <div class="modal-title h2">Launch Failed</div>
    </div>
  </div>
  <div class="modal-content">
    <div class="h5 message">{{ message }}</div>

    <div class="paragraph sub-message">BeBop can keep retrying for the next…</div>

    <div class="dropdown-retry">
      <bebop-input-select
        class="input-select-retry-interval"
        placeholder="Select retry interval…"
        [value]="retryInterval?.name"
        selectOnly="true"
      >
        <div dropdown-icon>
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.10728 4.10752C5.6144 2.6004 7.69888 1.66675 9.99984 1.66675C12.3008 1.66675 14.3853 2.6004 15.8924 4.10752C17.3995 5.61465 18.3332 7.69913 18.3332 10.0001C18.3332 12.301 17.3995 14.3855 15.8924 15.8926C14.3853 17.3998 12.3008 18.3334 9.99984 18.3334C7.69888 18.3334 5.6144 17.3998 4.10728 15.8926C2.60016 14.3855 1.6665 12.301 1.6665 10.0001C1.6665 7.69913 2.60016 5.61465 4.10728 4.10752ZM9.99984 3.33341C8.15866 3.33341 6.49313 4.07869 5.28579 5.28604C4.07845 6.49338 3.33317 8.1589 3.33317 10.0001C3.33317 11.8413 4.07845 13.5068 5.28579 14.7141C6.49313 15.9215 8.15866 16.6667 9.99984 16.6667C11.841 16.6667 13.5065 15.9215 14.7139 14.7141C15.9212 13.5068 16.6665 11.8413 16.6665 10.0001C16.6665 8.1589 15.9212 6.49338 14.7139 5.28604C13.5065 4.07869 11.841 3.33341 9.99984 3.33341ZM9.99984 5.83342C10.4601 5.83342 10.8332 6.20651 10.8332 6.66675V9.6549L12.2558 11.0775C12.5812 11.4029 12.5812 11.9306 12.2558 12.256C11.9303 12.5814 11.4027 12.5814 11.0772 12.256L9.41058 10.5893C9.33068 10.5094 9.2704 10.4174 9.22974 10.3191C9.18929 10.2215 9.16684 10.1147 9.16651 10.0026C9.16651 10.0017 9.1665 10.0009 9.1665 10.0001V9.99951V6.66675C9.1665 6.20651 9.5396 5.83342 9.99984 5.83342Z"
              fill="#5A5A68"
            />
          </svg>
        </div>
        <div dropdown-options>
          <bebop-input-select-option *ngFor="let interval of retryIntervals" (click)="updateRetryInterval(interval)">
            <div option-text>
              {{ interval?.name }}
            </div>
          </bebop-input-select-option>
        </div>
      </bebop-input-select>
    </div>

    <div class="action-section">
      <bebop-button size="large" outlined="true" (on-click)="cancel()">Dismiss</bebop-button>
      <bebop-button size="large" (on-click)="keepRetrying()">Keep Retrying</bebop-button>
    </div>
  </div>
</div>
