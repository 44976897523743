import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'navbar-secondary',
  templateUrl: './navbar-secondary.component.html',
  styleUrl: './navbar-secondary.component.scss',
  standalone: false
})
export class NavbarSecondaryComponent {

  constructor(private router: Router) {}

  navigateBack() {
    this.router.navigate(['/']);
  }

  onRedirectImage() {
    this.router.navigate(['/']);
  }
}
