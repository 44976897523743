import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'cree8-card-new-project',
  styleUrls: ['./cree8-card-new-project.component.scss'],
  templateUrl: './cree8-card-new-project.component.html',
})
export class Cree8CardNewProjectComponent implements OnInit {
  constructor() {}

  @Input('title') title?: string = 'New Project'
  @Output() addClick = new EventEmitter<void>()
  @Input('format') format?: 'default' | 'compact' = 'default'

  ngOnInit(): void {}

  onAddClick(): void {
    this.addClick.emit()
  }
}
