<div class="cree8-card-new-project" [ngClass]="format" (click)="onAddClick()">
  <div class="content">
    <cree8-btn-icon (click)="$event.stopPropagation(); onAddClick()" [title]="title" size="large">
      <svg class="ico" viewBox="0 0 24 24">
        <use xlink:href="app/common/assets/icons/_sprite.svg#Plus"></use>
      </svg>
    </cree8-btn-icon>
    <span class="headline4">{{ title }}</span>
  </div>
</div>
