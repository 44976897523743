import { Component, Input } from '@angular/core'

@Component({
  selector: 'no-data',
  styleUrl: './no-data.component.scss',
  templateUrl: './no-data.component.html',
})
export class NoDataComponent {
  @Input() title: string = ''
  @Input() description: string = ''
  @Input() icon: string = ''
}
