<div class="suspended-org" *ngIf="selectedOrg && selectedOrg.suspended">
  <div class="body3">
    Your CREE8 account has been suspended, please contact CREE8 Account Payable to resolve.
  </div>
</div>

<div class="suspended-org" *ngIf="selectedOrg && !selectedOrg.suspended && !hasValidSubscription">
  <div class="body3">
    Sorry, we could not find a valid CREE8 user subscription.
  </div>
</div>

<div *ngIf="!loading && selectedOrg && !selectedOrg.suspended && hasValidSubscription" class="dashboard scrollbar">

  <div class="banner" *ngIf="hasBanner">
    <bebop-banner *ngIf="hasBanner" [message]="bannerText" (on-close)="onCloseBanner()"></bebop-banner>
  </div>

  <div class="content">
    <bebop-loader [status]="'Loading'" [rounded]="false" *ngIf="loading"></bebop-loader>
    <div class="projects">
      <div class="headline1">Dashboard</div>
      <cree8-all-projects [isHomePage]="true"></cree8-all-projects>
    </div>

    <div class="plan">
      <div class="plan-header">
        <div class="plan-title">
          <div class="headline1">Plan</div>
          <cree8-label [size]="'small'" [type]="'accent'" *ngIf="subscriptionDetails.productName?.toLowerCase() == 'free'">{{subscriptionDetails.productName}}</cree8-label>
        </div>
        <cree8-btn *ngIf="isOrgAdmin" [size]="'small'" (click)="managePlan()">
          {{ isFreePlan ? 'Upgrade' : 'Manage' }}
        </cree8-btn>
      </div>
      <div class="download-section" *ngIf="!downloadCardClose && environment.browser" (click)="downloadApp()">
        <div class="dcard-content" *ngIf="environment.browser">
          <div class="logo">
            <svg class="ico" viewBox="0 0 30 18">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Cree8Logo"></use>
            </svg>
          </div>
          <div>
            <div class="headline5">Get the app</div>
            <div class="captions">Download the Desktop App for better file transfer experience.</div>
            <!-- <cree8-btn [size]="'extra-small'" (click)="downloadApp()">Download Now</cree8-btn> -->
          </div>
        </div>

        <div class="dcard-close">
          <cree8-btn-icon [size]="'small'" (click)="$event.stopPropagation(); closeDownloadCard()">
            <svg class="ico" viewBox="0 0 24 24">
              <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Xmark_Single'"></use>
            </svg>
          </cree8-btn-icon>
        </div>
      </div>

      <ng-container *ngIf="orgUsage?.workingStorage?.purchased !== '0.00'">
        <cree8-card-plan [title]="'Working Storage'"
          [subtitle]="((orgUsage?.workingStorage?.used ?? 0)/1).toFixed(0) + ' GB Used'"
          [description]="'of ' + ((orgUsage?.workingStorage?.purchased ?? 0)/1).toFixed(0) + ' GB Available'"
          [format]="EFormat.DEFAULT" [button]="'New project'" (onActionClick)=" onStorageClick()"></cree8-card-plan>
      </ng-container>

      <ng-container *ngIf="orgUsage?.archiveStorage?.purchased !== '0.00'">
        <cree8-card-plan [title]="'Archive Storage'"
          [subtitle]="((orgUsage?.archiveStorage?.used ?? 0)/1).toFixed(0) + ' GB Used'"
          [description]="'of ' + ((orgUsage?.archiveStorage?.purchased ?? 0)/1).toFixed(0) + ' GB Available'"
          [format]="EFormat.DEFAULT" [button]="'New project'" (onActionClick)=" onStorageClick()"></cree8-card-plan>
      </ng-container>

      <ng-container *ngIf="!isFreePlan">
        <cree8-card-plan 
          [title]="'Workstation Hours'" 
          [subtitle]="((orgUsage?.workstation?.used ?? 0)/1).toFixed(0) + ' Hrs Used'"
          [description]="'of ' + ((orgUsage?.workstation?.purchased ?? 0)/1).toFixed(0) + ' Hours Available'" 
          [format]="EFormat.DEFAULT"
          [button]="'New workstation'" 
          (onActionClick)="navigateTo('workstations')"
          [activated]="true">
        </cree8-card-plan>
      </ng-container>
      
      <ng-container *ngIf="isFreePlan">
        <cree8-card-plan 
          [title]="'Workstation Hours'" 
          [subtitle]="'Not Available in the Free Account'"
          [description]="'Not Available in the Free Account'" 
          [format]="EFormat.SETUP"
          [button]="'Upgrade'" 
          (onActionClick)="managePlan()"
          [activated]="false">
        </cree8-card-plan>
      </ng-container>

      <ng-container *ngIf="uiMembers.available">
        <cree8-card-plan [title]="'Team Members'" [subtitle]="uiMembers.desc" [description]="uiMembers.info"
          [format]="uiMembers.format" [button]="'Invite Members'" (onActionClick)="onTeamClick()"></cree8-card-plan>
      </ng-container>
    </div>
  </div>
</div>
