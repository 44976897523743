import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'display-date-time',
  templateUrl: './display-date-time.component.html',
  styleUrl: './display-date-time.component.scss'
})
export class DisplayDateTimeComponent {
  @Input() value: Date | string = '';
  @Input() type: 'default' = 'default';

  get time(): string {
    return dayjs(this.value).format('hh:mm A');
  }

  get date(): string {
    return dayjs(this.value).format('MM/DD/YYYY');
  }
}
