import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core'
import { TooltipWidgetComponent } from '../components/tooltip-widget/tooltip-widget.component'
import { TooltipPayload, Placement, TooltipProps, TooltipMessageType, PlacementPositionX, PlacementPositionY } from '../types'
@Directive({
  selector: '[bebop-tooltip]',
})
export class TooltipComponent implements OnInit, OnChanges {
  @Input('bebop-tooltip') title: string
  @Input('bebop-tooltip-placement') placement: Placement
  @Input('bebop-tooltip-tip-placement') tipPlacement: PlacementPositionX | PlacementPositionY
  @Input('bebop-tooltip-show-delay') delay: string | number
  @Input('bebop-tooltip-hide-delay') hideDelay: string | number
  @Input('bebop-tooltip-type') type: TooltipMessageType
  @Input('bebop-tooltip-offset') offset: number | string
  @Input('bebop-tooltip-left-offset-percent-value') leftOffsetPercent: number | string
  @Input('bebop-tooltip-width') width: number | string
  @Input('bebop-tooltip-bg-color') backgroundColor: string
  @Input('bebop-tooltip-bg-border-color') backgroundBorderColor: string

  

  // css => contain: layout
  @Input('bebop-tooltip-contained-layout') containedLayout: string

  timerHandle = -1

  viewable = false

  constructor(private el: ElementRef, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes['title']?.currentValue && this.viewable) {
      this.show()
    }

    if (!changes['title']?.currentValue) {
      this.hide()
    }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(ev: MouseEvent) {
    this.onShow()
  }

  // @HostListener('focusin') onFocus() {
  //   this.onShow()
  // }

  // @HostListener('focusout') onBlur() {
  //   this.onHide()
  // }

  @HostListener('click') onClick() {
    this.onHide()
  }

  @HostListener('window:blur') onWFocusOut() {
    this.onHide()
  }

  @HostListener('window:click') onWClick() {
    this.onHide()
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(ev: MouseEvent) {
    this.onHide()
  }

  onShow() {
    this.viewable = true

    if (!this.title) {
      // console.error('<div bebop-tooltip> without message')
      return
    }
    if (!this.component) {
      this.show()
    }
  }

  onHide() {
    this.viewable = false

    if (this.component) {
      this.hide()
    }
  }

  show() {
    if (!this.component) this.create()
    if (this.timerHandle != -1) window.clearTimeout(this.timerHandle)
  }

  hide() {
    // call mouse remove
    this.timerHandle = window.setTimeout(() => {
      this.viewContainerRef.clear()
      this.component = null
      this.timerHandle = -1
    }, +this.hideDelay || 0)
  }

  component: ComponentRef<TooltipWidgetComponent>

  ngOnDestroy() {
    this.viewContainerRef?.clear()
  }

  createHandle = -1
  create() {
    if (this.component || !this.viewable) return
    this.component = this.viewContainerRef.createComponent(TooltipWidgetComponent)
    let x: HTMLElement = this.el.nativeElement
    this.component.instance.props = {
      type: 'Tooltip',
      placement: this.placement || 'bottom',
      tipPlacement: this.tipPlacement,
      initialDelay: Number.isNaN(+this.delay) ? 300 : +this.delay,
      tooltip: <TooltipPayload>{
        message: this.title,
        messageType: this.type || 'error',
        background: this.backgroundColor,
        borderBackground: this.backgroundBorderColor,
        width: +this.width || 50,
      },
      parent: this.el.nativeElement,
      containedLayout: this.containedLayout == 'true',
      offsetFromParent: +this.offset || 8,
      leftOffsetPercent: +this.leftOffsetPercent || 0,
    }
    this.setPosition()
  }

  setPosition() {
    this.component?.instance?.updateParentTooltipPosition()
  }
}
