<div class="cree8-card-project" [ngClass]="format" (click)="onTitleClick()">
  <div class="thumbnail">
    <img class="card-image" [src]="image" [alt]="title" />
    <div class="indicators">
      <cree8-live-indicator *ngIf="hasLiveFile" [type]="isFileLive ? 'red' : 'grey'"
        [showLabel]="false"></cree8-live-indicator>
      <div [ngClass]="getStorageClass()">
        <span>{{ storageType }}</span>
      </div>
    </div>
  </div>

  <div class="description">
    <div class="info">
      <span class="card-title body2">{{ title }}</span>
      <span class="card-subtitle body3">Size: {{ folderSize }} GB</span>
    </div>
    <div (click)="$event.stopPropagation()">
      <ng-content select="[actions-menu]"></ng-content>
    </div>
  </div>
</div>
