import { Component, Input } from '@angular/core';

@Component({
  selector: 'cree8-tooltip',
  templateUrl: './cree8-tooltip.component.html',
  styleUrl: './cree8-tooltip.component.scss'
})
export class Cree8TooltipComponent {
  @Input() variant: 'dark' | 'light' = 'dark';
  @Input() tooltip: string = '';
}
