import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { CSSSize, TooltipProps } from '../../types'

@Component({
  selector: 'bebop-tooltip-widget',
  templateUrl: './tooltip-widget.component.html',
  styleUrls: ['./tooltip-widget.component.scss'],
})
export class TooltipWidgetComponent implements OnInit, AfterViewInit {
  @Input('props') props: TooltipProps

  top = 0
  left = 0

  margin = 16
  leftOffsetInPercent = 0.2

  show = false

  constructor(private widget: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.updateParentTooltipPosition()
      this.show = true
    }, this.props.initialDelay || 0)
  }

  getSelfBound() {
    return (
      // ???
      this.widget.nativeElement?.children[0]?.children[0]?.children[0]?.getBoundingClientRect() ||
      this.widget.nativeElement.getBoundingClientRect()
    )
  }

  getWidgetFixedPosition() {
    return this.widget.nativeElement?.children[0]?.children[0]?.getBoundingClientRect?.()
  }

  get getPosition() {
    return `${this.getTipPosition * 100}%`
  }

  get getTipPosition() {
    switch (this.props.tipPlacement) {
      case "start":
      case "top": return .2
      case "bottom":
      case "end": return .8
      case "center": return .5
      default:
        switch ( this.props.placement ) {
          case "top":
          case "bottom": return .2
          case "right":
          case "left": return .5
          default: return .5
        }
    }
  }

  updateParentTooltipPosition() {
    if (this.props.type != 'Tooltip' || !this.props.parent) return

    const host = this.props.parent.getBoundingClientRect()
    const self = this.getSelfBound()

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

    const placement = this.props.placement || 'bottom'
    let offset = this.props.offsetFromParent || 8 // from design

    offset += 8 // tip size

    this.top = 0
    this.left = 0

    let contained = this.props.containedLayout

    let tip = this.getTipPosition

    if (placement === 'top') {
      this.top = (contained ? 0 : host.top) - self.height - offset
      this.top += this.margin
      this.left = (contained ? 0 : host.left) + (host.width - self.width) / 2
    }

    if (placement === 'bottom') {
      this.top = (contained ? host.height : host.bottom) + offset
      this.top -= this.margin
      this.left = (contained ? 0 : host.left) + (host.width - self.width) / 2
    }

    if (placement === 'left') {
      this.top = (contained ? 0 : host.top) + (host.height - self.height) / 2
      this.left = (contained ? 0 : host.left) - self.width - offset
      this.left += this.margin
    }

    if (placement === 'right') {
      this.top = (contained ? 0 : host.top) + (host.height - self.height) / 2
      this.left = (contained ? host.width : host.right) + offset
      this.left -= this.margin
    }

    // handle only for top and bottom for now
    if (placement == 'top' || placement == 'bottom') {
      if (this.left + self.width > window.innerWidth) {
        this.props.tipPlacement = 'end'
        this.left -= this.left + self.width - window.innerWidth
      }

      this.left += (this.props.leftOffsetPercent ?? (contained ? this.leftOffsetInPercent : 0)) * self.width
    }

    this.top += scrollTop

    if (!contained) {
      // inside modal, the fixed position relative to that window causing issues,
      // since we did fixed using contained property for certain cases, 
      // lets handle her for just non contained cases without breaking existing code

      let pos = this.getWidgetFixedPosition()
      this.left -= pos?.x ?? 0
      this.top -= pos?.y ?? 0

    }

    // this.top -= this.margin
  }
}
