import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "website-hero",
  templateUrl: "./website-hero.component.html",
  styleUrl: "./website-hero.component.scss",
})
export class WebsiteHeroComponent implements OnInit {
  fromBebop = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const fromParam = params["from"];
      this.fromBebop = fromParam === "bebop";
    });
  }
}
