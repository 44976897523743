import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'website-ai-training',
  templateUrl: './website-ai-training.component.html',
  styleUrl: './website-ai-training.component.scss'
})
export class WebsiteAiTrainingComponent {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements =
      this.elementRef.nativeElement.querySelectorAll(".fade-in-up");
    elements.forEach((el: Element) => observer.observe(el));
  }

  openLearnMore() {
    window.open('https://cree8.io/privacy-policy', '_blank');
  }
}
