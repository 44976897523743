import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import Timepicker from '../../classes/lib/tw-elements/timepicker'

@Component({
  selector: 'bebop-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
})
export class InputTimeComponent implements OnInit, AfterViewInit {
  @ViewChild('timepicker') timePicker

  @Input('date') date: Date

  @Output('onChange') onChange = new EventEmitter<Date>()

  @Input('options') options: { [key: string]: string | boolean | number | Date }

  get timeStr() {
    if (!this.date) return ''
    let hr = this.date.getHours()
    let min = this.date.getMinutes()
    return [hr < 10 ? '0' : '', hr, ':', min < 10 ? '0' : '', min, ' ', hr > 12 ? 'PM' : 'AM'].join('')
  }

  constructor() {}

  ngOnInit(): void {
    this.date = this.date || new Date()
  }

  ngAfterViewInit(): void {
    let dom = this.timePicker?.nativeElement
    dom?.addEventListener('timeChange.bebop.timepicker', (d: { hour: number, min: number }) => {
      let date = new Date()
      date.setHours(d.hour, d.min, 0)
      this.onChangeEvent(date)
    })
  }

  onChangeEvent(d: Date) {
    this.onChange.emit(d)
  }


  openTimePicker() {
    if (this.timePicker?.nativeElement) {
      let dp = Timepicker.getInstance(this.timePicker?.nativeElement)
      dp =
        dp ||
        new Timepicker(this.timePicker?.nativeElement, {
          ...(this.options || {}),
        })

      dp?._open()
    }
  }
}
