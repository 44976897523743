import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'bebop-apple-spinner',
  templateUrl: './apple-spinner.component.html',
  styleUrls: ['./apple-spinner.component.scss'],
})
export class AppleSpinnerComponent implements OnInit {
  @Input('size') size: 'small' | 'large' | 'xlarge' | 'default'

  constructor() {}

  ngOnInit(): void {}
}
