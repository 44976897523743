import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { AfterViewInit, Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "website-section",
  templateUrl: "./website-section.component.html",
  styleUrl: "./website-section.component.scss",
  animations: [
    trigger("fadeInUp", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(20px)" }),
        animate(
          "500ms ease-out",
          style({ opacity: 1, transform: "translateY(0)" })
        ),
      ]),
    ]),
  ],
})
export class WebsiteSectionComponent implements AfterViewInit {
  @Input() title: string = "";
  @Input() description: string = "";
  @Input() image: any = {
    src: "",
    width: 0,
    height: 0,
    alt: "",
  };
  @Input() right: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements =
      this.elementRef.nativeElement.querySelectorAll(".fade-in-up");
    elements.forEach((el: Element) => observer.observe(el));
  }
}
