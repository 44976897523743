import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'

import { Observable, Subscription } from 'rxjs'
import { RadioItem } from 'src/app/common/components/radio-button-group/radio-button-group.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { Organization, Pod, User } from 'src/app/models/bebop.model'
import { UiOrganization, UiPod } from 'src/app/models/ui.model'
import { ElectronService } from 'src/app/services/electron.service'
import { MainService } from 'src/app/services/main.service'
import { UserSettingsService } from 'src/app/services/user-settings.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { UIService } from 'src/app/store/ui/ui.service'

@Component({
  selector: 'bebop-settings',
  styleUrls: ['./app-settings.component.scss'],
  templateUrl: './app-settings.component.html',
})
export class AppSettingsComponent implements OnInit, OnDestroy {
  blockPowerSaver = false
  loading = true
  radioItems: RadioItem[] = [
    {
      description:
        'This will prevent application from being suspended. Keeps system being active but allows screen to be turned off.',
      name: 'Prevent app suspension',
      selected: true,
      value: 'prevent-app-suspension',
    },

    {
      description: 'This will prevent the display from going to sleep but keeps system and screen active',
      name: 'Prevent display sleep',
      value: 'prevent-display-sleep',
    },
  ]

  selectedPod: UiPod
  selectedOrganization: UiOrganization

  pods: UiPod[] = []

  organizations: UiOrganization[] = []

  selectedFolder = ''
  selectedPathSegment: string[] = []

  org$: Subscription
  user: User
  user$: Subscription

  userSettings: any

  userPreferences: any

  get canOpenDevTools() {
    return this.userPreferences?.openDevTools ?? false
  }

  showDevOptionsDropdown = false

  get showDevOptions() {
    // add more options
    return this.canOpenDevTools
  }

  constructor(
    private sessionQuery: SessionQuery,
    private uiService: UIService,
    private sessionService: SessionService,
    private uiQuery: UIQuery,
    private userService: UserSettingsService,
    private mainService: MainService,
    private toastService: ToastService,
    private electronService: ElectronService,
    private zone: NgZone
  ) {
    this.toUiPod = this.toUiPod.bind(this)
    this.toUiOrg = this.toUiOrg.bind(this)
    this.loading = false
  }

  ngOnInit(): void {
    this.updateSelectedPath(this.electronService.defaultDownloadPath)

    this.user$ = this.sessionQuery.getUser().subscribe((user) => {
      this.user = user
      this.userPreferences = user?.userPreferences
      this.readSettings()
    })

    this.org$ = this.sessionQuery.getOrganizations().subscribe((orgs) => {
      this.organizations = orgs.map(this.toUiOrg)

      console.log(this.organizations)

      this.syncSettings()
    })
  }

  closeDevOptionDropdown() {
    this.showDevOptionsDropdown = false
  }

  toggleDevOptionDropdown() {
    this.showDevOptionsDropdown = !this.showDevOptionsDropdown
  }

  updateSelectedPath(_path: string) {
    this.selectedFolder = _path
    this.selectedPathSegment = this.selectedFolder.split(/[\\/]+/).filter((x) => x)
  }

  syncSettings() {
    let selectedDefaults = this.userSettings?.appSettings?.defaults || {}
    if (selectedDefaults.org) {
      this.organizations?.forEach((o) => {
        o.selected = selectedDefaults.org == o?._id
        if (o.selected) {
          this.onSelectOrganization(o)
        }
      })
    } else {
      this.organizations?.forEach((o) => (o.selected = false))
      this.selectedOrganization = null
      this.selectedPod = null
    }

    if (selectedDefaults.pod) {
      this.pods?.forEach((o) => {
        o.selected = selectedDefaults.pod == o?._id

        if (o.selected) {
          this.selectedPod = o
        }
      })
    } else {
      this.pods?.forEach((o) => (o.selected = false))
      this.selectedPod = null
    }

    let powerValue = this.userSettings?.appSettings?.powerSaveBlocker || 'prevent-app-suspension'

    this.radioItems = [...this.radioItems]

    this.radioItems?.forEach((i) => (i.selected = i.value == powerValue))

    this.blockPowerSaver = powerValue != 'none'
  }

  readSettings() {
    this.userService.readSettings('UserSettings', this.user?._id, (err: NodeJS.ErrnoException, settings: any) => {
      this.userSettings = (err ? {} : settings) || {}

      // readSettings returns [] on error, TODO - fix it later
      if (Array.isArray(settings)) {
        this.userSettings = {}
      }

      if (!this.userSettings.appSettings) {
        this.userSettings.appSettings = {
          powerSaveBlocker: 'prevent-app-suspension',
        }
      }

      if (!this.userSettings.downloadPath) {
        this.userSettings.downloadPath = this.electronService.defaultDownloadPath
      }
      this.updateSelectedPath(this.userSettings.downloadPath)

      this.syncSettings()
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    ;[this.org$, this.user$].forEach((u) => u?.unsubscribe())
  }

  toUiPod(pod: Pod) {
    if (!pod) return null
    return {
      _id: pod._id,
      location: '',
      name: pod.name,
      projectsCount: 0,
      selected: this.selectedPod?._id == pod._id,
      source: pod,
    }
  }

  toUiOrg(org: Organization) {
    if (!org) return null
    return {
      _id: org._id,
      activePodCount: org.pods ? org.pods?.length : -1,
      logo: org.logo,
      name: org.name,
      selected: this.selectedOrganization?._id == org._id,
      source: org,
    }
  }

  onBlockPowerSaverChange(value: string) {
    this.radioItems.forEach((ri) => {
      ri.selected = value == ri.value
    })
    this.userSettings.appSettings.powerSaveBlocker = value
  }

  onChangeBPSaverFlag(value: boolean) {
    this.blockPowerSaver = value
  }

  reset() {
    let userSettings = {
      appSettings: {
        powerSaveBlocker: 'prevent-app-suspension',
      },
      downloadPath: this.electronService.defaultDownloadPath,
    }

    this.userService.saveSettings('UserSettings', this.user?._id, userSettings, (err) => {
      this.zone.run(() => {
        if (err) {
          console.error('Save settings failed', err.message)
          this.toastService.show({
            text: 'Failed to persist reset settings.',
            type: 'error',
          })
        } else {
          this.toastService.show({
            text: 'Settings reset to default.',
            type: 'success',
          })

          this.userSettings = userSettings

          this.mainService.startPowerSaveBlocker()
          this.updateSelectedPath(this.userSettings.downloadPath)
          this.syncSettings()
        }
      })
    })
  }

  saveChanges() {
    this.userSettings.appSettings.defaults = {
      org: this.selectedOrganization?._id || undefined,
      pod: this.selectedPod?._id || undefined,
    }

    if (!this.blockPowerSaver) {
      this.userSettings.appSettings.powerSaveBlocker = 'none'
    }

    this.userSettings.downloadPath = this.selectedFolder

    this.userService.saveSettings('UserSettings', this.user?._id, this.userSettings, (err) => {
      this.zone.run(() => {
        if (err) {
          console.error('Save settings failed', err.message)
          this.toastService.show({
            text: 'Failed to save settings.',
            type: 'error',
          })
        } else {
          this.toastService.show({
            text: 'Settings saved.',
            type: 'success',
          })

          this.mainService.startPowerSaveBlocker()
        }
      })
    })
  }

  selectFolder() {
    this.electronService
      .showOpenDialog({
        buttonLabel: 'Select',
        defaultPath: this.selectedFolder,
        filters: [],
        message: 'Select download path',
        properties: ['openDirectory', 'createDirectory', 'promptToCreate'],
        title: 'Select Download Path',
      })
      .then((e) => {
        if (!e.canceled && e?.filePaths?.length) {
          this.updateSelectedPath(e.filePaths[0])
        }
      })
  }

  onSelectOrganization(p: UiOrganization) {
    if (this.selectedOrganization?.name == p?.name) return
    this.selectedPod = null

    this.organizations?.forEach((p) => (p.selected = false))
    p.selected = true
    this.selectedOrganization = p

    this.userSettings.appSettings.defaults = this.userSettings.appSettings.defaults || {}
    this.userSettings.appSettings.defaults.org = p._id

    // if (!this.selectedPod) {
    this.sessionService.getPods(p._id).subscribe((pods) => {
      if (pods.error) console.error('Unable to fetch pods for org', p.name)
      else {
        this.pods = pods.data.map(this.toUiPod)
        this.selectedPod = null
        this.syncSettings()
      }
    })
    // }
  }

  onSelectPod(pod: UiPod) {
    this.selectedPod = pod
    this.pods?.forEach((p) => (p.selected = false))
    pod.selected = true
  }

  openDevTools() {
    this.electronService.openDevTools()
  }
}
