/* eslint-disable @stylistic/js/max-len */
import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router'

@Component({
  selector: 'cree8-workstations-card',
  styleUrls: ['./workstations.component.scss'],
  templateUrl: './workstations.component.html',
})
export class WorkStationsComponent implements OnInit {
  @Input() data: { info: string; available: boolean; desc: string }

  sanitizedIcon: any // Store sanitized SVG

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(this.icon)
  }

  onButtonClick = () => {
    this.router.navigate(['app/workstations'])
  }

  get icon() {
    return `
        <svg width="150" height="118" viewBox="0 0 150 118" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_15_7181)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M78.4615 28.5385C78.4615 26.0319 80.4935 24 83 24C115.585 24 142 50.4152 142 83C142 115.585 115.585 142 83 142C80.4935 142 78.4615 139.968 78.4615 137.462C78.4615 134.955 80.4935 132.923 83 132.923C110.572 132.923 132.923 110.572 132.923 83C132.923 55.4282 110.572 33.0769 83 33.0769C80.4935 33.0769 78.4615 31.045 78.4615 28.5385Z" fill="url(#paint0_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M79.9744 48.2051C82.4809 48.2051 84.5128 50.2371 84.5128 52.7436V78.4615H104.179C106.686 78.4615 108.718 80.4935 108.718 83C108.718 85.5065 106.686 87.5385 104.179 87.5385H79.9744C77.4678 87.5385 75.4359 85.5065 75.4359 83V52.7436C75.4359 50.2371 77.4678 48.2051 79.9744 48.2051Z" fill="url(#paint1_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M56.1928 41.6051C56.666 41.7724 57.1752 41.8634 57.7056 41.8634C60.2122 41.8634 62.2441 39.8314 62.2441 37.3249C62.2441 33.9829 59.5349 31.2736 56.1928 31.2736C52.8508 31.2736 50.1415 33.9829 50.1415 37.3249C50.1415 39.8314 52.1735 41.8634 54.68 41.8634C55.2104 41.8634 55.7196 41.7724 56.1928 41.6051Z" fill="url(#paint2_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M56.1928 33.0447C55.7196 32.8775 55.2104 32.7865 54.68 32.7865C52.1735 32.7865 50.1415 34.8184 50.1415 37.3249C50.1415 40.667 52.8508 43.3762 56.1928 43.3762C59.5349 43.3762 62.2441 40.667 62.2441 37.3249C62.2441 34.8184 60.2122 32.7865 57.7056 32.7865C57.1752 32.7865 56.666 32.8775 56.1928 33.0447Z" fill="url(#paint3_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.8098 61.3686C37.283 61.5359 37.7922 61.6269 38.3227 61.6269C40.8292 61.6269 42.8611 59.5949 42.8611 57.0884C42.8611 53.7464 40.1519 51.0371 36.8098 51.0371C33.4678 51.0371 30.7586 53.7464 30.7586 57.0884C30.7586 59.5949 32.7905 61.6269 35.297 61.6269C35.8275 61.6269 36.3367 61.5359 36.8098 61.3686Z" fill="url(#paint4_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.8098 52.8082C36.3367 52.641 35.8275 52.5499 35.297 52.5499C32.7905 52.5499 30.7586 54.5819 30.7586 57.0884C30.7586 60.4304 33.4678 63.1397 36.8098 63.1397C40.1519 63.1397 42.8611 60.4304 42.8611 57.0884C42.8611 54.5819 40.8292 52.5499 38.3227 52.5499C37.7922 52.5499 37.283 52.641 36.8098 52.8082Z" fill="url(#paint5_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.0513 87.2802C30.5245 87.4475 31.0337 87.5385 31.5641 87.5385C34.0706 87.5385 36.1026 85.5065 36.1026 83C36.1026 79.658 33.3933 76.9487 30.0513 76.9487C26.7093 76.9487 24 79.658 24 83C24 85.5065 26.0319 87.5385 28.5385 87.5385C29.0689 87.5385 29.5781 87.4475 30.0513 87.2802Z" fill="url(#paint6_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.0513 78.7198C29.5781 78.5525 29.0689 78.4615 28.5385 78.4615C26.0319 78.4615 24 80.4935 24 83C24 86.342 26.7093 89.0513 30.0513 89.0513C33.3933 89.0513 36.1026 86.342 36.1026 83C36.1026 80.4935 34.0706 78.4615 31.5641 78.4615C31.0337 78.4615 30.5245 78.5525 30.0513 78.7198Z" fill="url(#paint7_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.8098 113.192C37.283 113.359 37.7922 113.45 38.3227 113.45C40.8292 113.45 42.8611 111.418 42.8611 108.912C42.8611 105.57 40.1519 102.86 36.8098 102.86C33.4678 102.86 30.7586 105.57 30.7586 108.912C30.7586 111.418 32.7905 113.45 35.297 113.45C35.8275 113.45 36.3367 113.359 36.8098 113.192Z" fill="url(#paint8_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.8098 104.631C36.3367 104.464 35.8275 104.373 35.297 104.373C32.7905 104.373 30.7586 106.405 30.7586 108.912C30.7586 112.254 33.4678 114.963 36.8098 114.963C40.1519 114.963 42.8611 112.254 42.8611 108.912C42.8611 106.405 40.8292 104.373 38.3227 104.373C37.7922 104.373 37.283 104.464 36.8098 104.631Z" fill="url(#paint9_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M56.1928 132.955C56.666 133.123 57.1752 133.214 57.7056 133.214C60.2122 133.214 62.2441 131.182 62.2441 128.675C62.2441 125.333 59.5349 122.624 56.1928 122.624C52.8508 122.624 50.1415 125.333 50.1415 128.675C50.1415 131.182 52.1735 133.214 54.68 133.214C55.2104 133.214 55.7196 133.123 56.1928 132.955Z" fill="url(#paint10_linear_15_7181)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M56.1928 124.395C55.7196 124.228 55.2104 124.137 54.68 124.137C52.1735 124.137 50.1415 126.169 50.1415 128.675C50.1415 132.017 52.8508 134.726 56.1928 134.726C59.5349 134.726 62.2441 132.017 62.2441 128.675C62.2441 126.169 60.2122 124.137 57.7056 124.137C57.1752 124.137 56.666 124.228 56.1928 124.395Z" fill="url(#paint11_linear_15_7181)"/>
</g>
<defs>
<linearGradient id="paint0_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_15_7181" x1="83" y1="35.9229" x2="83" y2="142" gradientUnits="userSpaceOnUse">
<stop stop-color="#494E55"/>
<stop offset="1" stop-color="#1D1D23" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_15_7181">
<rect width="150" height="118" rx="10" fill="white"/>
</clipPath>
</defs>
</svg>
`
  }
}
