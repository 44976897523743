<div class="bebop-logout-prompt">
  <div class="logout-banner"></div>
  <div class="modal-content">
    <div class="modal-icon">
      <svg viewBox="0 0 42 42" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.622 21a8.667 8.667 0 1 1 17.334 0 8.667 8.667 0 0 1-17.334 0Zm8.667-10.833c-5.983 0-10.834 4.85-10.834 10.833 0 5.983 4.85 10.833 10.834 10.833 5.983 0 10.833-4.85 10.833-10.833 0-5.983-4.85-10.833-10.833-10.833Zm1.083 6.5a1.083 1.083 0 0 0-2.166 0V21a1.083 1.083 0 1 0 2.166 0v-4.333ZM21.29 24.25a1.083 1.083 0 1 0 0 2.167h.01a1.083 1.083 0 1 0 0-2.167h-.01Z"
          fill="#FFC14C"
        />
        <rect x=".5" y=".5" width="41" height="41" rx="20.5" stroke="url(#a)" />
        <defs>
          <linearGradient id="a" x1=".58" y1=".243" x2="14.082" y2="48.452" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" stop-opacity=".08" />
            <stop offset="1" stop-color="#fff" stop-opacity=".04" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="h2 message">Are you sure you want to log out of CREE8?</div>

    <div class="paragraph sub-message" *ngIf="busy">Your active sessions will be stopped</div>

    <div class="action-section">
      <bebop-button size="large" outlined="true" (on-click)="cancel()">Cancel</bebop-button>
      <bebop-button size="large" (on-click)="stop()">Log Out</bebop-button>
    </div>
  </div>
</div>
