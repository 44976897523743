import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { SharedModule } from './components/shared/shared.module'
import { LoginBannerComponent } from './components/login/login-banner/login-banner.component'
import { LoginComponent } from './components/login/login.component'
/*
  Seperate module to add components that has custom tags (for web template components)
  CUSTOM_ELEMENTS_SCHEMA in top level app.module or common.modules will disable template validations
*/
@NgModule({
  declarations: [LoginBannerComponent, LoginComponent],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, BrowserAnimationsModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppCustomElementsModule {}
