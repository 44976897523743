import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MaxPageElementCount, MinPageElementCount, PageSize, PageSizes } from '../../types'

/**

  pageOptions: Pagination = {
    page: 1,
    total: 250,
    size: PageSizes[0]
  }

  onPageChange(s: number) {
    
  }

  <bebop-pagintion [total]="pageOptions.total" [page]="pageOptions.page" [size]="pageOptions.size" (pageChange)="onPageChange($event)"></bebop-pagintion>
*/

export interface PageItem {
  label: number | '…'
  selected: boolean
}

@Component({
  selector: 'bebop-pagintion',
  inputs: ['total', 'size', 'page', 'pageElementCount'],
  outputs: ['pageChange'],
  templateUrl: './pagintion.component.html',
  styleUrls: ['./pagintion.component.scss'],
})
export class PagintionComponent {
  constructor(private cdRef: ChangeDetectorRef) {}

  pageItems: PageItem[] = []

  _pageElementCount: number
  get pageElementCount() {
    return this._pageElementCount || MinPageElementCount
  }

  set pageElementCount(value: number) {
    if (value % 2 == 0) {
      console.error('<bebop-pagination [pageElementCount]> pageElementCount must be odd and at least equal to 7')
    }
    this._pageElementCount = Math.max(MinPageElementCount, Math.max(MinPageElementCount, this._pageElementCount))
    this._pageElementCount = value
    this.updatePageList()
  }

  _total: number
  get total() {
    return this._total
  }
  set total(value: number) {
    this._total = +value
    this.updatePageCount()
    this.updatePageList()
  }

  _size: number
  get size() {
    return this._size
  }
  set size(value: number) {
    this._size = +value // PageSizes.includes(value) ? value : PageSizes[0]
    this.updatePageCount()
    this.updatePageList()
  }

  _pageCount: number
  updatePageCount() {
    this._pageCount = Math.ceil(this._total / this._size)
  }

  _page: number
  pageChange = new EventEmitter<number>()
  get page() {
    return this._page
  }
  set page(value: number) {
    this._page = Math.max(1, Math.min(this._total || 1, value))
    this.pageChange.emit(this._page)
    this.updatePageList()
  }

  get canMoveToNextPage(): boolean {
    return this.page < this._pageCount ? true : false
  }

  get canMoveToPreviousPage(): boolean {
    return this.page > 1 ? true : false
  }

  moveToNextPage() {
    if (this.canMoveToNextPage) {
      this.page++
    }
  }

  moveToPreviousPage() {
    if (this.canMoveToPreviousPage) {
      this.page--
    }
  }

  moveToLastPage() {
    this.page = this._pageCount
  }

  moveToFirstPage() {
    this.page = 1
  }

  moveTo(value: number | '…') {
    if (value == '…') return
    this.page = value
  }

  updatePageList() {
    this.pageItems = this.getPageList()
  }

  getPageList(): PageItem[] {
    let output: PageItem[] = []
    if (this.total <= 1) return output

    let p = this._page || 1
    let t = this._pageCount || 1
    let pe = this._pageElementCount || MinPageElementCount

    if (t <= pe) {
      // no ellipsis

      for (let idx = 1; idx <= t; idx++) {
        output.push({
          label: idx,
          selected: idx == p,
        })
      }

      return output
    }

    for (let idx = 1; idx < pe; idx++) {
      output.push({
        label: idx,
        selected: idx == p,
      })
    }

    output.push({
      label: t,
      selected: t == p,
    })

    let skewLeft = Math.abs(1 - p) < pe - 4
    let skewRight = Math.abs(t - p) < pe - 4
    let left = 1
    let right = pe - 2
    let w = pe - 2

    if (!skewLeft) {
      output[1] = { label: '…', selected: false }
      left++
      w--
    }

    if (!skewRight) {
      output[pe - 2] = { label: '…', selected: false }
      right--
      w--
    }

    let value = Math.max(left + 1, p - Math.floor(w / 2))

    if (!skewLeft && skewRight) {
      // 3 holes
      value = Math.min(t - (pe - 3), value)
    }

    for (let idx = left, pos = value; idx <= right; idx++, pos++) {
      output[idx] = { label: pos, selected: pos == p }
    }

    return output
  }
}
