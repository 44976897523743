import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Query } from '@datorama/akita'

import { distinctUntilChanged, map } from 'rxjs/operators'
import { RocketSession, UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { Organization, Pod } from 'src/app/models/bebop.model'
import { UiPod, UiProject } from 'src/app/models/ui.model'

import { DownloaderState, DownloaderStore } from './downloader.store'

@Injectable({ providedIn: 'root' })
export class DownloaderQuery extends Query<DownloaderState> {
  constructor(protected store: DownloaderStore) {
    super(store)
  }

  getSessions() {
    return this.select('downloads')
  }

  getSessionsValue() {
    return this.getValue().downloads
  }

  getSelectedSession() {
    return this.select('selectedDownload')
  }

  getDownloadSession(id: number) {
    return this.select('downloads').pipe(map((u) => u.find((m) => m.id == id)))
  }

  getSelectedTuple() {
    return this.select([(s) => s.selectedProject, (s) => s.selectedPod, (s) => s.selectedDownload])
  }

  getSelectedTupleValue(): [UiProject, Pod, RocketSession<any, UiDownloadFile>] {
    let value = this.getValue()
    return [value.selectedProject, value.selectedPod, value.selectedDownload]
  }

  getDownloadSessionByOrg(o: Organization) {
    return this.select('downloads').pipe(map((u) => u.find((m) => m.organization?._id == o?._id)))
  }

  getSessionByOrgIdValue(id: string) {
    let value = this.getValue()

    return value.downloads.find((d) => d.organization?._id == id)
  }

  getAny<K extends keyof DownloaderState>(value: K) {
    //  : Observable<UploaderState[K]>
    return this.select(value)
  }

  getComponentFor(router: Router) {
    return router.isActive('/app/download', {
      fragment: 'ignored',
      matrixParams: 'exact',
      paths: 'subset',
      queryParams: 'exact',
    })
      ? 'Download'
      : 'Link'
  }
}
