import { Injectable } from '@angular/core'
import { BehaviorSubject, first, takeLast } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class StepperSignupService {
  private listStepperSource = new BehaviorSubject<
    { step: string; value: string }[]
  >([])
  private currentStepperSource = new BehaviorSubject<number>(1)

  listStepper$ = this.listStepperSource.asObservable()
  currentStepper$ = this.currentStepperSource.asObservable()

  constructor() {}

  setListStepper(data: { step: string; value: string }[]) {
    this.listStepperSource.next(data)
  }

  setCurrentStepper(step: number) {
    this.currentStepperSource.next(step)
  }

  setValueList(value: string, currentStep?: number) {
    if (currentStep) {
      this.setValue(value, currentStep - 1)
      return
    }

    this.currentStepper$.pipe(first()).subscribe({
      next: (res) => {
        this.setValue(value, res - 1)
      },
    })
  }

  private setValue(value: string, index: number) {
    const currentIndex = index
    const currentList = this.listStepperSource.getValue()
    if (currentIndex >= 0 && currentIndex < currentList.length) {
      const updatedList = [...currentList]
      updatedList[currentIndex] = { ...updatedList[currentIndex], value }
      this.listStepperSource.next(updatedList)
    } else {
      console.error('Index out of range')
    }
  }
}
