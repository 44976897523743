import { OverlayRef } from '@angular/cdk/overlay'
import { CdkPortal } from '@angular/cdk/portal'
import { EmbeddedViewRef } from '@angular/core'

import { BehaviorSubject, filter, first, Observable } from 'rxjs'

import { OnExternalModalClose } from '../services/component-modal.service'

export class ModalOverlayRef<T, V = any> {
  private actions = new BehaviorSubject<V>(null)

  componentOrTemplateRef: T | EmbeddedViewRef<any>

  constructor(private ref: OverlayRef) {}

  setOverlayComponent(c: T | EmbeddedViewRef<any>) {
    this.componentOrTemplateRef = c
  }

  public send(v: V) {
    this.actions.next(v)
  }

  public events(): Observable<V> {
    return this.actions.pipe(filter((e) => !!e))
  }

  public once() {
    return this.actions.pipe(
      filter((e) => !!e),
      first()
    )
  }

  _beforeCloseHook: () => void = null
  public beforeCloseHook(fn: () => void) {
    this._beforeCloseHook = fn
  }

  onBeforeClose() {
    return new Promise(async (r) => {
      try {
        await ((<OnExternalModalClose>(<any>this.componentOrTemplateRef))?.beforeClose?.()?.catch(() => true) ?? true)
        r(true)
      } catch (e) {
        r(true)
      }
    })
  }

  async onExternalClick() {
    if (!this.ref) return
    if (this.componentOrTemplateRef instanceof CdkPortal) return
    ;(<OnExternalModalClose>(<any>this.componentOrTemplateRef))?.cancel?.()
  }

  close(): void {
    if (!this.ref) return
    this._beforeCloseHook?.()
    this._beforeCloseHook = null
    this.onBeforeClose().then(() => {
      this.ref?.dispose?.()
      this.actions.complete()
      this.ref = null
    })
  }

  async sleep(ms = 0): Promise<void> {
    return new Promise((r) => window.setTimeout(r, ms))
  }
}
