import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { EStorageType } from './types'

@Component({
  selector: 'cree8-card-project',
  styleUrls: ['./cree8-card-project.component.scss'],
  templateUrl: './cree8-card-project.component.html',
})
export class Cree8CardProjectComponent implements OnInit {
  constructor() {}

  @Input('image') image!: string
  @Input('title') title!: string
  @Input('folderSize') folderSize?: number
  @Input('hasLiveFile') hasLiveFile: boolean = false
  @Input('isFileLive') isFileLive: boolean = false
  @Input('storageType') storageType?: EStorageType = EStorageType.STANDARD

  @Input('format') format?: 'default' | 'compact' = 'default'

  @Output() titleClick = new EventEmitter<void>()

  ngOnInit(): void {}

  getStorageClass(): string {
    switch (this.storageType) {
      case EStorageType.ARCHIVE:
        return 'storage storage--archive inverted'
      case EStorageType.HIGH_PERFORMANCE:
        return 'storage storage--high-performance'
      case EStorageType.STANDARD:
        return 'storage storage--standard'
      default:
        return 'storage storage--hide'
    }
  }

  onTitleClick(): void {
    this.titleClick.emit()
  }
}
