import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'

import dayjs from 'dayjs'
import { Subject, takeUntil } from 'rxjs'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BebopLink } from 'src/app/models/bebop.model'
import { BroadcastLinkSecurity, LinkSecurity, LinkSecurityExpiry } from 'src/app/models/ui.model'
import { ProjectsLinksService } from 'src/app/store/projects/projects-links.service'

export type ExpirationStatus = 'success' | 'loading' | 'error'
@Component({
  selector: 'link-expiration',
  styleUrl: './link-expiration.component.scss',
  templateUrl: './link-expiration.component.html',
})
export class LinkExpirationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() link: BebopLink

  props: BroadcastLinkSecurity = {
    endTime: new Date(),
    expiry: LinkSecurityExpiry.Infinite,
    linkSecurity: LinkSecurity.Public,
  }

  expirationTabs: ITabs[] = [
    { active: true, label: 'Infinite', value: 'infinity' },
    { label: 'Set Time', value: 'set-time' },
  ]

  timeOptions = {
    okLabel: 'Set Time',
  }

  _date = new Date()
  _time = new Date()

  dateOptions = {
    minDate: new Date(),
    okBtnText: 'Set Date',
    startDate: new Date(),
    title: 'Expiry Date',
  }

  activeTab: string = 'infinity'
  destroy$: Subject<void> = new Subject<void>()

  activeDate = dayjs().add(1, 'year').format('YYYY-MM-DD')
  activeTime = dayjs().format('HH:mm')
  infinityTime = 4070965399819
  currentTime = dayjs(this._time).format('HH:mm')
  currentDate = dayjs(this._date).format('YYYY-MM-DD')

  @Output() statusUpdate: EventEmitter<ExpirationStatus> = new EventEmitter<ExpirationStatus>()

  constructor(
    private projectsLinksService: ProjectsLinksService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['link']) {
      const currentTime = dayjs(changes['link'].currentValue.expires).valueOf()
      if (currentTime === this.infinityTime) {
        this.activeTab = 'infinity'
      } else {
        this.activeTab = 'set-time'
        this.activeDate = dayjs(currentTime).format('YYYY-MM-DD')
        this.activeTime = dayjs(currentTime).format('HH:mm')
        this._date = new Date(this.activeDate)
        this._time = new Date(this.activeDate)
      }
    }
  }

  onLinkTabClick(tab: string) {
    this.activeTab = tab
  }

  onUpdateExpiration() {
    this.statusUpdate.emit('loading')
    const combinedDateTime = dayjs(`${this.currentDate} ${this.currentTime}`).valueOf()
    const time = this.activeTab === 'set-time' ? combinedDateTime : this.infinityTime

    this.projectsLinksService
      .updatedExpiration(this.link, time)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (e) => {
          this.toastService.show({
            text: 'CREE8 Link update failed',
            type: 'error',
          })
          this.statusUpdate.emit('error')
        },
        next: (e) => {
          this.toastService.show({
            text: 'CREE8 Link has been updated',
            type: 'success',
          })
          this.statusUpdate.emit('success')
        },
      })
  }

  onChangeEndDate(d: Date) {
    if (!d) return
    this.currentDate = dayjs(d).format('YYYY-MM-DD')
    this._date = d
  }

  onChangeEndTime(d: Date) {
    if (!d) return
    this._time = d
    this.currentTime = dayjs(d).format('HH:mm')
  }

  getValue() {
    const combinedDateTime = dayjs(`${this.currentDate} ${this.currentTime}`).valueOf()
    const time = this.activeTab === 'set-time' ? combinedDateTime : this.infinityTime
    return time
  }
}
