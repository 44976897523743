import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'

interface ITagWithError {
  tag: string
  error: boolean
  message?: string
}

@Component({
  selector: 'cree8-input-chips',
  styleUrls: ['./cree8-input-chips.component.scss'],
  templateUrl: './cree8-input-chips.component.html',
})
export class Cree8InputChipsComponent implements OnInit {
  @Input('autofocus') autofocus: boolean = false
  @Input('placeholder') placeholder: string = ''
  @Input('disabled') disabled: boolean = false
  @Input('multiline') multiline: boolean = false
  @Input('noduplicate') noDuplicate: boolean = true
  @Input('showLabel') showLabel: boolean = false

  @Input('tags') tags: string[] = []
  @Input('validate') validate: ((s: string, arr: string[]) => boolean | string) | undefined
  @Output('tagsChange') onChange = new EventEmitter<string[]>()

  @Output('enter') onEnter = new EventEmitter<string>()

  @ViewChild('inputTagLabel') inputTagLabel: any

  get isDisabled() {
    return this.disabled
  }

  isAutoFocused = true
  isMultilined = true

  isContainedLayout = false

  tag = ''
  all: ITagWithError[] = []

  lastKey = ''
  lastTs = 0
  lastTag = ''

  constructor(
    private cdRef: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.isAutoFocused = this.autofocus
    this.isMultilined = this.multiline

    this.all = this.tags.map((tag) => ({ error: false, tag }))

    if (!this.validate) {
      console.error('validate attribute is missing')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tags']) {
      this.updateTagsWithErrors() // Update when tags change
    }
  }

  private updateTagsWithErrors() {
    this.all = this.tags.map((tag) => ({ error: false, tag }))
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges()
  }

  _validate() {
    try {
      // True when validate is not provided
      return this.validate ? this.validate(this.tag, this.tags) : true
    } catch (e: any) {
      console.error('validate callback error', e.message, e.stack)
    }

    return false
  }

  onRemoveTag(pos: number) {
    if (pos < 0) return
    this.tags.splice(pos, 1)
    this.onChange.emit(this.tags)
  }

  onRemoveTagWithError(pos: number) {
    if (pos < 0) return
    let [e] = this.all.splice(pos, 1)
    if (!e.error) {
      let i = this.tags.findIndex((t) => t == e.tag)
      if (i != -1) this.onRemoveTag(i)
    }
  }

  onKeyup(ev: KeyboardEvent) {
    if (ev.key === 'Backspace') {
      if (this.lastKey == ev.key && this.tag == '' && this.lastTag == '' && Date.now() - this.lastTs < 1000) {
        this.lastKey = ''
        this.lastTs = Date.now()

        if (this.all.length) {
          this.onRemoveTagWithError(this.all.length - 1)
        }
        return
      }
    }

    this.lastKey = ev.key
    this.lastTs = Date.now()
    this.lastTag = this.tag
    if (ev.key === 'Enter' || ev.key === ',' || ev.key === ' ') {
      if (this.tags?.includes(this.tag) && this.noDuplicate) {
        console.error('Ignore duplicate tag', this.tag, this.tags)
        return
      }

      if (ev.key === ',' || ev.key === ' ') this.tag = this.tag.trim().replace(',', '')

      let reply = this._validate()
      if (reply == true) {
        this.onEnter.emit(this.tag)
        this.tags.push(this.tag)
        this.onChange.emit(this.tags)
      }

      this.all.push({ error: reply != true, message: reply == true ? '' : reply || 'Invalid tag', tag: this.tag })

      this.tag = ''

      if (this.isMultilined) return
      let e = this.el.nativeElement?.children[0]?.children[1]?.children[0]

      setTimeout(() => e?.scrollTo({ behavior: 'smooth', left: e?.scrollWidth + 10000 }), 100)
    }
  }
}
