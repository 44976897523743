<div class="bebop-tabs" [class.box]="tabType == 'box'" [class.outline]="tabType == 'outline'" [class.outline-header]="type == 'outline-header'">
  <div class="nav nav-tabs" #navTabs>
    <div
      class="nav-header"
      *ngFor="let tab of tabs; let i = index; trackBy:trackByItem"
      [class.box]="tabType == 'box'"
      [class.outline]="tabType == 'outline'"
      [class.outline-header]="type == 'outline-header'"
      [class.active]="tab.selected"
      (click)="onRouteChange(tab, i)"
    >
      <div class="nav-template-icon" *ngIf="tab.iconTemplate">
        <ng-container [ngTemplateOutlet]="tab.iconTemplate"></ng-container>
      </div>
      <a class="nav-title" [class.title]="tabType == 'box'" [class.h5]="type == 'outline'" [class.h4]="type == 'outline-header'"
        [class]="tab.cssClass || 'tagz'"
        [style]="{
          'minWidth': tab.minWidth ?? undefined
        }"
        >{{ tab.name }}
        <div
          class="badge small"
          [class.title]="tabType == 'box'"
          [class.h5]="tabType == 'outline'"
          [style]="{
            '--tab-badge-color': tab.badgeColor || 'var(--text-secondary)',
            '--tab-default-badge-color': tab.defaultBadgeColor || 'var(--text-secondary)'
          }"
          *ngIf="tab?.badgeCount?.()"
        >
          {{ tab?.badgeCount?.() }}
        </div>
      </a>
    </div>
    <div class="nav-indicator" #navIndicator></div>
  </div>
  <div class="divider" *ngIf="tabType == 'outline'"></div>
  <div class="nav-tab" [@routeAnimations]="routlet && routlet.activatedRouteData && routlet.activatedRouteData['animation']">
      <router-outlet *ngIf="secondaryOutlet" name="secondary" #o="outlet"></router-outlet>
      <router-outlet *ngIf="!secondaryOutlet"  #o="outlet"></router-outlet>
  </div>
</div>

<!--
        [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="tab.route"
            routerLinkActive="active"

 -->
