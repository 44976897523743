import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Self,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'

@Component({
  selector: 'bebop-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input('value') value: string
  @Input('disabled') disabled: boolean
  @Output('on-change') changeReport = new EventEmitter<string>()

  isDescription = true

  @ViewChild('description') description

  model: string
  onChange = (value: string) => {}
  onTouched = () => {}
  constructor(@Self() private ngControl: NgControl, private cdRef: ChangeDetectorRef) {
    ngControl.valueAccessor = this
  }

  ngOnInit() {
    this.ngControl.control.valueChanges.subscribe((value) => {
      if (this.model === value) return
      this.writeValue(value)
    })
  }

  ngAfterViewInit(): void {
    this.isDescription = this.description?.nativeElement?.children?.length > 0
    this.cdRef.detectChanges()
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  writeValue(value: any) {
    this.model = value
  }

  select() {
    if (this.disabled) return
    if (this.model == this.value) return
    this.model = this.value
    this.onChange(this.model)

    this.changeReport.emit(this.model)
  }
}
