import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export interface StopWorkstationAction {
  name: 'Cancel' | 'Stop'
}

@Component({
  selector: 'bebop-stop-workstation',
  templateUrl: './stop-workstation.component.html',
  styleUrls: ['./stop-workstation.component.scss'],
})
export class StopWorkstationComponent implements OnInit, OnExternalModalClose {
  constructor(
    public ref: ModalOverlayRef<StopWorkstationComponent, StopWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  stop() {
    this.ref.send({ name: 'Stop' })
    this.ref.close()
  }
}
