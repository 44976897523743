export const NAVBAR_IMAGES = {
  LOGO: '../../assets/img/cree8_logo_dark.svg',
  MEDIA_ENTERTAINMENT: '../../assets/img/media-entertainment.png',
  LIVE_SPORTS: '../../assets/img/live-sports.png',
  IN_HOUSE_AGENCIES: '../../assets/img/in-house-agencies.png',
  GAMING_STUDIOS: '../../assets/img/game-studios.png',
} as const

export const INDUSTRY_CARDS = [
  {
    id: 'media',
    href: 'https://cree8.io/media-entertainment/',
    image: NAVBAR_IMAGES.MEDIA_ENTERTAINMENT,
    alt: 'Media & Entertainment',
    title: 'Media & Entertainment',
  },
  {
    id: 'live-sports',
    href: 'https://cree8.io/live-sports/',
    image: NAVBAR_IMAGES.LIVE_SPORTS,
    alt: 'Live Sports',
    title: 'Live Sports',
  },
  {
    id: 'in-house-agencies',
    href: 'https://cree8.io/in-house-agencies/',
    image: NAVBAR_IMAGES.IN_HOUSE_AGENCIES,
    alt: 'In-House Agencies',
    title: 'In-House Agencies',
  },
  {
    id: 'gaming-studios',
    href: 'https://cree8.io/gaming-studios/',
    image: NAVBAR_IMAGES.GAMING_STUDIOS,
    alt: 'Gaming Studios',
    title: 'Gaming Studios',
  },
] as const

export const RESOURCES_FEATURED = {
  href: 'https://cree8.io/blog/185725856183/',
  image:
    'https://40271656.fs1.hubspotusercontent-na1.net/hubfs/40271656/AI-Generated%20Media/Images/CREE8%20Nvidia%20Inception-1.jpg',
  alt: 'CREE8 Nvidia Inception',
  caption: 'CREE8 Joins NVIDIA Inception to Fuel Next-Generation Production',
} as const
