import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { OnExternalModalClose, BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'
import { BroadcastLinkSecurity, LinkSecurity, LinkSecurityExpiry } from 'src/app/models/ui.model'

export interface NewOrUpdateBroadcastModalAction {
  name: 'Close' | 'New' | 'Update'
  data?: BroadcastLinkSecurity
}
@Component({
  selector: 'bebop-new-update-broadcast-modal',
  templateUrl: './new-update-broadcast-modal.component.html',
  styleUrls: ['./new-update-broadcast-modal.component.scss'],
})
export class NewOrUpdateBroadcastModalComponent implements OnInit, OnExternalModalClose {
  props: BroadcastLinkSecurity = {
    linkSecurity: LinkSecurity.Public,
    expiry: LinkSecurityExpiry.Infinite,
    endTime: new Date(),
  }

  minDate = new Date()

  // TODO update props.endTime with _date and _time fields
  _date = new Date()
  _time = new Date()

  // src/ui/src/app/common/classes/lib/tw-elements/datepicker/index.js
  dateOptions = {
    title: 'Expiry Date',
    okBtnText: 'Set Date',
    startDate: new Date(),
    minDate: new Date(),
  }

  timeOptions = {
    okLabel: 'Set Time',
  }

  isNew = true

  formErrors = {
    password: '',
    confirmPassword: '',
  }

  constructor(
    public ref: ModalOverlayRef<NewOrUpdateBroadcastModalComponent, NewOrUpdateBroadcastModalAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.data.broadcast) {
      this.isNew = false
      if (this.data.broadcast.securitySettings) {
        this.props = { ...this.data.broadcast.securitySettings }
      }
    }
  }

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  get linkSecurityEnum(): typeof LinkSecurity {
    return LinkSecurity
  }

  get linkSecurityExpiryEnum(): typeof LinkSecurityExpiry {
    return LinkSecurityExpiry
  }

  onSelectSecurity(i: LinkSecurity) {
    this.props.linkSecurity = i
  }

  onSelectExpiryMode(e: LinkSecurityExpiry) {
    this.props.expiry = e
    // if (e == LinkSecurityExpiry.SetTime) {
    //   this.openDatePicker()
    // }
  }

  onKeyupConfirmPassword(e: KeyboardEvent) {
    this.formErrors.confirmPassword = ''
    if (!this.props.confirmPassword?.trim()) {
      this.formErrors.confirmPassword = 'confirm password is empty'
      return
    }

    if (this.props.confirmPassword != this.props.password) {
      this.formErrors.confirmPassword = 'password mismatch'
    }
  }

  onKeyupPassword(e: KeyboardEvent) {
    this.formErrors.password = ''
    if (!this.props.password?.trim()) {
      this.formErrors.password = 'password is empty'
    }
  }

  _updateEndTime() {
    this.props.endTime = this._date
    this.props.endTime.setHours(this._time.getHours())
    this.props.endTime.setMinutes(this._time.getMinutes())
  }

  onChangeEndDate(d: Date) {
    if (!d) return
    this._date = d
    this._updateEndTime()
  }

  onChangeEndTime(d: Date) {
    if (!d) return
    this._time = d
    this._updateEndTime()
  }

  validate() {
    switch (this.props.linkSecurity) {
      case LinkSecurity.Public:
        break
      case LinkSecurity.Password:
        this.onKeyupPassword(null)
        this.onKeyupConfirmPassword(null)

        if (this.props.confirmPassword || this.props.password) {
          this.toastService.show({
            type: 'error',
            text: this.props.password || this.props.confirmPassword,
          })
          return false
        }

        if (!this.props.password?.trim()) {
          this.formErrors.password = 'password is empty'
          this.toastService.show({
            type: 'error',
            text: this.formErrors.password,
          })
          return false
        }

        if (!this.props.confirmPassword?.trim()) {
          this.formErrors.confirmPassword = 'confirm password is empty'
          this.toastService.show({
            type: 'error',
            text: this.formErrors.confirmPassword,
          })
          return false
        }

        if (this.props.confirmPassword != this.props.password) {
          this.formErrors.confirmPassword = 'password mismatch'

          this.toastService.show({
            type: 'error',
            text: this.formErrors.confirmPassword,
          })
          return false
        }

        break
      case LinkSecurity.BebopLogin:
        break
      default:
        break
    }

    switch (this.props.expiry) {
      case LinkSecurityExpiry.Infinite:
        this.props.endTime = new Date(40709088e5)
        break
      case LinkSecurityExpiry.SetTime:
        if (this.props.endTime < new Date()) {
          this.toastService.show({
            type: 'error',
            text: 'Set Time is past time',
          })
          return false
        }
        break
      default:
        break
    }

    this.formErrors = {
      password: '',
      confirmPassword: '',
    }

    return true
  }

  startBroadcast() {
    if (!this.validate()) return

    this.ref.send({ name: this.isNew ? 'New' : 'Update', data: this.props })
    this.ref.close()
  }
}
