<div
  class="bebop-button"
  [class.disabled]="isDisabled"
  [class.outlined]="isOutlined"
  [class.icon-small]="isIconSmall"
  [class.icon-xsmall]="isIconXsmall"
  [class.icon-large]="isIconLarge"
  [class.text-small]="isSmall"
  [class.text-xsmall]="isXsmall"
  [class.text-large]="isLarge"
  (click)="onClick($event)"
>
  <div class="button-container" [class.large-button-text]="isLarge" [class.button-text]="isSmall" [class.small]="isXsmall">
    <ng-content></ng-content>
  </div>
</div>
