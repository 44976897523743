import { ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UtilsService } from '../../services/utils.service'

@Component({
  selector: 'bebop-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  isOutlined = true
  isLarge = false
  isSmall = false
  isXsmall = false

  isIconLarge = false
  isIconSmall = false
  isIconXsmall = false

  @Output('on-click') click = new EventEmitter<MouseEvent>()
  @Input('disabled') disabled: string | boolean
  @Input('outlined') outlined: string

  @Input('size') size: 'small' | 'large' | 'xsmall'
  @Input('icon-size') iconSize: 'small' | 'large' | 'xsmall'

  get isDisabled() {
    return this.util.isTrueLike(this.disabled)
  }

  @HostBinding('tabindex') tabindex = 0;

  @HostListener('focus')
  focusHandler() {}

  @HostListener('keydown.enter', ['$event']) 
  onKeydown(e: KeyboardEvent) {
    this.onClick(<MouseEvent><unknown>e)
  }


  constructor(private cdRef: ChangeDetectorRef, private util: UtilsService) {}

  ngOnInit(): void {
    this.isOutlined = this.outlined == 'true'

    this.isLarge = this.size == 'large'
    this.isXsmall = this.size == 'xsmall'
    this.isSmall = !this.isLarge && !this.isXsmall

    this.isIconLarge = this.iconSize == 'large'
    this.isIconSmall = this.iconSize == 'small'
    this.isIconXsmall = this.iconSize == 'xsmall'
  }

  onClick(e: MouseEvent) {
    if (this.isDisabled) return
    this.click.emit(e)
  }
}
