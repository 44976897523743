<div class="bebop-tooltip-widget">
  <div class="bebop-tooltip-container" *ngIf="props.type == 'Tooltip'">
    <div
      class="bebop-tooltip small"
      [style.top]="top + 'px'"
      [style.left]="left + 'px'"
      [class.bebop-tooltip-show]="show"
      [class]="{
        success: props.tooltip.messageType == 'success',
        warning: props.tooltip.messageType == 'warning',
        info: props.tooltip.messageType == 'info',
        error: props.tooltip.messageType == 'error'
      }"
      [style]="{
        '--tag-bg-color': props.tooltip.background || '#2b2b33',
        '--tag-bg-border-color': props.tooltip.borderBackground || '#404040',
        '--tag-tip-position': getPosition
      }"
      [class]="'bebop-tooltip-' + props.placement"
    >
      <div class="tooltip-wrapper">
        <div class="bebop-tooltip-icon" *ngIf="props.tooltip.messageType != 'none'">
          <svg viewBox="0 0 24 24" fill="none" *ngIf="props.tooltip.messageType == 'warning'">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7ZM13.01 8C13.01 7.44772 12.5623 7 12.01 7C11.4577 7 11.01 7.44772 11.01 8V12C11.01 12.5523 11.4577 13 12.01 13C12.5623 13 13.01 12.5523 13.01 12V8ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H12.01C12.5623 17 13.01 16.5523 13.01 16C13.01 15.4477 12.5623 15 12.01 15H12Z"
              fill="#FFC14C"
            />
          </svg>

          <svg viewBox="0 0 16 16" fill="none" *ngIf="props.tooltip.messageType == 'error'">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.8444 7.99998C2.8444 5.05446 5.23222 2.66665 8.17774 2.66665C11.1233 2.66665 13.5111 5.05446 13.5111 7.99998C13.5111 10.9455 11.1233 13.3333 8.17774 13.3333C5.23222 13.3333 2.8444 10.9455 2.8444 7.99998ZM8.17774 1.33331C4.49584 1.33331 1.51107 4.31808 1.51107 7.99998C1.51107 11.6819 4.49584 14.6666 8.17774 14.6666C11.8596 14.6666 14.8444 11.6819 14.8444 7.99998C14.8444 4.31808 11.8596 1.33331 8.17774 1.33331ZM8.8444 5.33331C8.8444 4.96512 8.54593 4.66665 8.17774 4.66665C7.80955 4.66665 7.51107 4.96512 7.51107 5.33331V7.99998C7.51107 8.36817 7.80955 8.66665 8.17774 8.66665C8.54593 8.66665 8.8444 8.36817 8.8444 7.99998V5.33331ZM8.17774 9.99998C7.80955 9.99998 7.51107 10.2985 7.51107 10.6666C7.51107 11.0348 7.80955 11.3333 8.17774 11.3333H8.1844C8.55259 11.3333 8.85107 11.0348 8.85107 10.6666C8.85107 10.2985 8.55259 9.99998 8.1844 9.99998H8.17774Z"
              fill="#F1344B"
              fill-opacity="0.8"
            />
          </svg>

          <svg viewBox="0 0 24 24" fill="none" *ngIf="props.tooltip.messageType == 'success'">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM16.7682 9.64018C17.1218 9.2159 17.0645 8.58534 16.6402 8.23177C16.2159 7.87821 15.5854 7.93553 15.2318 8.35981L10.9002 13.5578L8.67268 11.5328C8.26402 11.1613 7.63157 11.1914 7.26007 11.6C6.88856 12.0087 6.91868 12.6412 7.32733 13.0127L10.3273 15.7399C10.5291 15.9234 10.797 16.0164 11.0691 15.9976C11.3411 15.9788 11.5937 15.8497 11.7682 15.6402L16.7682 9.64018Z"
              fill="#32BA78"
            />
          </svg>

          <svg viewBox="0 0 24 24" fill="none" *ngIf="props.tooltip.messageType == 'info'">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.267 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-10 8c0-5.523 4.477-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.523 0-10-4.477-10-10Zm10-1a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1Zm0-4a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01Z"
              fill="#4574F5"
            />
          </svg>

          <!-- Add message type icons -->
        </div>
        <div class="bebop-tooltip-message" [style]="{ width: (props.tooltip.width || 50) + 'px' }">
          {{ props.tooltip.message }}
        </div>
      </div>
    </div>
  </div>

  <div class="bebop-tooltip-popover-container" *ngIf="props.type == 'Pullover'"></div>
</div>
