<cree8-modal [size]="'large'" *ngIf="active" [modal]="modal" (cancelModal)="onCancel()">

  <div class="user-workstations-modal">

    <div class="header">
      <div class="user">
        <img [src]="getUserImage(user)" alt="{{ user?.name }}" width="32">
        <div class="info">
          <div class="body3">{{user.name}}</div>
          <div class="captions">{{user.email}}</div>
        </div>
      </div>
      <div class="searchBar">
        <bebop-input-select placeholder="Select workstation" [value]="" [onSearch]="onSearchWorkstations"
          (bebopClickOutside)="resetSearchWorkstations()" (dropdownState)="onDropdownWorkstationChange($event)">
          <div dropdown-icon>
            <svg class="ico" width="16" height="16" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Workstations"></use>
            </svg>
          </div>
          <div dropdown-options>
            <bebop-input-select-option *ngFor="let workstation of filteredWorkstations"
              (on-click)="onSelectedWorkstation(workstation)">
              <div option-icon>
                <svg class="ico" width="16" height="16" viewBox="0 0 24 24">
                  <use xlink:href="app/common/assets/icons/_sprite.svg#Workstations"></use>
                </svg>
              </div>
              <div option-text>
                {{ workstation.DISPLAY_NAME || workstation.NAME }}
              </div>
            </bebop-input-select-option>
          </div>
        </bebop-input-select>
      </div>
    </div>



    <div class="workstation-list" *ngIf="workstations.length > 0; else noWorkstations">
      <table class="cree8-table">
        <thead>
          <tr>
            <th>Workstation</th>
            <th>Status</th>
            <th>Access</th>
          </tr>
        </thead>
        <tbody class="scrollbar">
          <tr *ngFor=" let workstation of workstations">
            <td>
              <div class="workstations">
                <div>
                  {{ workstation.DISPLAY_NAME || workstation.NAME }}
                </div>
              </div>
            </td>
            <td>
              <div class="information captions" [ngClass]="'status-' + workstation.POWER_STATUS_CODE">
                {{ getPowerCodeLabel(workstation).state }}
              </div>
            </td>
            <td>
              <cree8-btn-icon cree8-tooltip-text="Remove workstation" cree8-tooltip-position="top"
                (click)="removeWorkstation(workstation)">
                <svg style="color:var(--message-error)" class="ico" viewBox="0 0 24 24">
                  <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Delete'"></use>
                </svg>
              </cree8-btn-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Template for when there are no invites -->
    <ng-template #noWorkstations>
      <div *ngIf="!loading">
        No workstations currently assigned
      </div>
    </ng-template>
  </div>
</cree8-modal>
