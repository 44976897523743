<div [ngClass]="generateClass">
  <div class="header body-small" [class.empty]="header.length === 0">
    <span class="badge inverted">{{header}}</span>
  </div>
  <div [ngClass]="generateClassBody">
    <ng-content></ng-content>
    <div class="footer" *ngIf="footer">
      <ng-content select="[footer-card]"></ng-content>
    </div>
  </div>
</div>
