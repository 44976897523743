import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'

import { debounceTime, Subject, takeUntil } from 'rxjs'

export type IActionType = 'search' | 'refresh' | 'sorting' | 'created' | 'settings'

@Component({
  selector: 'actions-list',
  styleUrl: './actions-list.component.scss',
  templateUrl: './actions-list.component.html',
})
export class ActionsListComponent implements OnInit, OnDestroy, OnChanges {
  isSearchActive: boolean = false
  searchQuery: string = ''
  searchControl = new FormControl()
  isSortingActive: boolean = false
  @Input() allowedActions: IActionType[] = []
  @Output() searchValue: EventEmitter<string> = new EventEmitter<string>()
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>()
  @Output() sorting: EventEmitter<string> = new EventEmitter<string>()
  @Output() created: EventEmitter<void> = new EventEmitter<void>()
  @Output() settings: EventEmitter<void> = new EventEmitter<void>()
  @Input() labelCreated: string = 'boolean = false'
  @Input() listSorting: { label: string; value: string }[] = [
    { label: 'Newest to oldest', value: '-date_created' },
    { label: 'Oldest to newest', value: 'date_created' },
    { label: 'From A to Z', value: 'name' },
    { label: 'From Z to A', value: '-name' },
  ]

  @Input() activedSort: string = this.listSorting[0].value
  destroy$: Subject<void> = new Subject<void>()
  @ViewChild('sortingContainer') sortingContainer: ElementRef

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe((value) => {
      this.emitSearchValue(value)
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activedSort']) {
      this.activedSort = changes['activedSort'].currentValue
    }
  }

  // Close sorting when clicking outside the container
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const clickedElement = event.target as HTMLElement

    // Check if click was inside the sorting container
    if (this.sortingContainer && this.sortingContainer.nativeElement.contains(clickedElement)) {
      return // Click inside the container, do nothing
    }

    // Check if the click is on an SVG or inside any SVG-related element
    if (clickedElement.closest('svg') || clickedElement.closest('use')) {
      return // Ignore clicks inside SVG elements
    }

    this.isSortingActive = false // Close sorting
  }

  toggleSearch(state: boolean): void {
    this.isSearchActive = state
  }

  onRefresh() {
    this.refresh.emit()
  }

  emitSearchValue(value: string) {
    this.searchValue.emit(value)
  }

  activeSorting(sorting: string) {
    this.activedSort = sorting
    this.isSortingActive = false
    this.sorting.emit(sorting)
  }

  onCreated() {
    this.created.emit()
  }

  onSettings() {
    this.settings.emit()
  }
}
