@if (link) {
  <div class="link-stats">
    <table>
    <tr>
      <th>Name</th>
      <td> {{ link.name || 'N/A' }}</td>
    </tr>
    <tr>
      <th>Total Views</th>
      <td>{{ link.views || 0 }}</td>
    </tr>
    <tr>
      <th>Total Transfers</th>
      <td>{{ link.stats.uploads.total || 0 }}</td>
    </tr>
  </table>
</div>
}
@else {
  <div class="link-stats">
    <p>No link selected</p>
  </div>
}
