import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'reload',
  templateUrl: './reload.component.html',
  styleUrl: './reload.component.scss',
})
export class ReloadComponent {
  constructor(private router: Router) {}

  @Input() centeredPage = true;
  @Input() description = 'Something wrong please click reload icon.';

  reloadPage() {
    const currentUrl = this.router.url;
    if (currentUrl === '/') {
      window.location.reload()
    } else {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(currentUrl);
      });
    }
  }
}
