import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private isInitialized = false

  constructor() {}

  initIntercom(userData: any): void {
    if (this.isInitialized) {
      ;(window as any).Intercom('update', userData)
    } else {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = `https://widget.intercom.io/widget/${userData.app_id}`
      script.onload = () => {
        ;(window as any).Intercom('boot', userData)
        this.isInitialized = true
      }
      document.head.appendChild(script)
    }
  }

  shutdownIntercom(): void {
    if (this.isInitialized) {
      ;(window as any).Intercom('shutdown')
      this.isInitialized = false
    }
  }
}
