import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'

export interface RadioItem {
  value: string
  name: string
  description?: string
  selected?: boolean
  [key: string]: any
}

/*
  example
  
  onChange(e: string) {
    console.log(e, 'on change--')
  }

  items = [{ name: 'H1', value: '1'}, { name: 'H2', value: '2'}, { name: 'H3', value: '3'} ]

  <bebop-radio-button-group name="radios" [items]="items" (on-select)="onChange($event)">
  </bebop-radio-button-group>

*/

@Component({
  selector: 'bebop-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent implements OnInit, OnChanges {
  @Input('name') name: string
  @Input('disabled') disabled: string | boolean
  @Input('items') items: RadioItem[]
  @Output('on-select') onSelect = new EventEmitter<string>()

  group: FormGroup

  constructor(private formBuilder: FormBuilder) {
    if (!this.name) {
      this.name = 'radios'
    }

    if (this.items?.length == 0) {
      console.error('<bebop-radio-button-group> without items or items are empty')
    }
  }

  get isDisabled() {
    return this.disabled == 'disabled' || this.disabled == 'true' || this.disabled == true
  }

  ngOnInit(): void {
    let first = (this.items || []).find((i) => i.selected == true)
    this.group = this.formBuilder.group({
      [this.name]: [first?.value || true],
    })
  }

  onSelectValue(value: string) {
    this.onSelect.emit(value)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }
}
