import { Component } from '@angular/core'
import { ITestimoni } from './website-testimoni/website-testimoni.component';
@Component({
  selector: 'website',
  templateUrl: './website.component.html',
  styleUrl: './website.component.scss',
})
export class WebsiteComponent {
  testimoni: ITestimoni = {
    quote:
      '“I love the ability to be working on two machines at once. It is a game changer in letting one machine run untouched, and be able to continue on the next.”',
    name: 'Connor Ryan',
    profession: 'Writer/Director, Glass Poets',
    photo: 'assets/img/connor-ryan.png',
    quoteWidthClass: 'medium-width',
  };

  showBackToTop: boolean = false

  testimoniDetails: ITestimoni = {
    quote: `"Creating photo-realistic renderings has always been the most effective way to communicate your ideas with the client, but it's also a task that typically takes up considerable resources. Working in CREE8, the ease of turning on a powerful remote GPU has revolutionized my work flows and allowed me to produce presentation-ready renderings in a fraction of the time it used to take on my personal computer. To say nothing of the work-flow advantages the office now has in being able to work in a single virtual space where we can all share and collaborate in one place, rather than across multiple different apps."`,
    name: 'Connor Ryan',
    profession: 'Writer/Director, Glass Poets',
    photo: 'assets/img/connor-ryan.png',
    quoteWidthClass: 'medium-width',
  };


  repeatedClients: any[] = [];

  onGetStarted() {
    window.location.replace('https://store.cree8.io/')
  }
}
