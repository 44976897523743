import { Inject, Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'

import { pid } from 'process'
import { Subscription } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { RocketLifeCycle, RocketSession, UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import {
  CancelResumeActionModalAction,
  CancelResumeActionModalComponent,
} from 'src/app/components/rocket/common/modals/cancel-resume-action-modal/cancel-resume-action-modal.component'
import { Organization, OrgEntitlements, Pod, Project } from 'src/app/models/bebop.model'
import { ProjectResponse, ResponseError } from 'src/app/models/response.model'
import { DownloadQueueResponse } from 'src/app/models/rocket-rest.model'
import { UiProject } from 'src/app/models/ui.model'
import { DownloaderQuery } from 'src/app/store/rocket/downloader/downloader.query'
import { DownloaderService } from 'src/app/store/rocket/downloader/downloader.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { UIService } from 'src/app/store/ui/ui.service'
import { environment } from 'src/environments/environment'

import { BebopClientUtilsService } from '../../bebop-client-utils.service'
import { BebopConfigService } from '../../bebop-config.service'
import { ElectronService } from '../../electron.service'
import { MainService } from '../../main.service'
import { UserService } from '../../user.service'
import { UserSettingsService } from '../../user-settings.service'
import { DownloadUiEvents } from '../classes/download-ui-events'
import { DownloadConstants } from '../classes/rocket-constants'
import { ElasticSearchService } from '../elastic-search.service'
import { RocketService } from '../rocket.service'

let fs, path

if (!environment.browser) {
  fs = window.require('fs')
  path = window.require('path')
}

@Injectable({
  providedIn: 'root',
})
export class DownloaderLifecycleService implements RocketLifeCycle {
  sessions: RocketSession<any, UiDownloadFile>[] = []
  orgs$: Subscription
  navPermissions$: Subscription
  settings: any = {}

  downloadProjects: Project[] = []
  downloadPods: Pod[] = []
  downloadOrgs: Organization[] = []
  downloadProjectsMap: {
    [key: string]: Project
  } = {}

  pendingNotifications: Organization[] = []

  pendingQueue: DownloadQueueResponse

  machineId = ''
  entitlements: OrgEntitlements

  constructor(
    private electronService: ElectronService,
    private toastService: ToastService,
    private rocketService: RocketService,
    private uiQuery: UIQuery,
    private uiService: UIService,
    private util: BebopClientUtilsService,
    private sessionQuery: SessionQuery,
    private downloaderQuery: DownloaderQuery,
    private downloaderService: DownloaderService,
    private userService: UserService,
    private userSettings: UserSettingsService,
    private bebopConfig: BebopConfigService,
    private es: ElasticSearchService,
    private modalService: ComponentModalService,
    private sessionService: SessionService,
    private router: Router,
    @Inject(Injector) private readonly injector: Injector
  ) {
    this.addProject = this.addProject.bind(this)
    this.mapEsRecordToUI = this.mapEsRecordToUI.bind(this)

    this.electronService.MachineId.then((id) => (this.machineId = id))
    this.onInit()
  }

  private get mainService() {
    return this.injector.get(MainService, null)
  }

  onInit() {
    if (this.navPermissions$) return
    this.navPermissions$ = this.uiQuery.getNavPermissions().subscribe((permission) => {
      // stop if nav permission removed for user
      // TODO - revisit

      let { download } = permission

      let selectedOrg = this.uiQuery.getSelectedOrgValue()
      if (!selectedOrg) return

      if (download) {
        // init download session
      } else {
        // pause
      }
    })

    this.sessionQuery.getEntitlements().subscribe((e) => (this.entitlements = e))

    this.userSettings.getUserSettings().subscribe((settings) => {
      this.settings = settings
      let downloadCount = this.settings.downloadCount
      let verificationCount = this.settings.verificationCount

      this.sessions?.forEach((s) => {
        if (s.rocket) {
          s.rocket.opts.simultaneousDownload = downloadCount
          s.rocket.opts.simultaneousVerification = verificationCount
        }
      })
    })
  }

  hasActiveTransfers(): boolean {
    return this.sessions?.some((s) => this.downloaderService.isBusy(s))
  }

  onLogin(): void {
    let currentUser = this.userService.user
    this.userSettings.readSettings('UserSettings', currentUser._id, (err: NodeJS.ErrnoException, res?: any) => {
      this.onUserLogin(err, res)
    })

    this.loadPendingDownloads()

    this.navPermissions$ = this.uiQuery.getNavPermissions().subscribe((permission) => {
      // stop if nav permission removed for user
      // TODO - revisit

      let { download } = permission

      let selectedOrg = this.uiQuery.getSelectedOrgValue()

      // selected org doesn't have download nav access - pause all rocket downloads of the selected org!
      if (selectedOrg && (!download || selectedOrg?.suspended)) {
        this.sessions
          ?.filter((s) => s?.project?.organization?._id == selectedOrg._id)
          .forEach((s) => {
            s.rocket?.rocketEvents?.pauseAll()
          })
      }
    })

    this.orgs$ = this.sessionQuery.getOrganizations().subscribe((orgs) => {
      if (!orgs?.length) {
        // No orgs, pause all transfers
        this.sessions?.forEach((s) => {
          s.rocket?.rocketEvents?.pauseAll()
        })
        return
      }

      // suspended org, pause transfer
      let sorgs = orgs?.filter((o) => o?.suspended)
      sorgs?.forEach((o) => {
        this.sessions
          ?.filter((s) => s?.project?.organization?._id == o._id)
          ?.forEach((s) => {
            s.rocket?.rocketEvents?.pauseAll()
          })
      })
    })
  }

  onLogout(): void {
    // TODO
    this.navPermissions$?.unsubscribe?.()
    this.orgs$?.unsubscribe?.()
    this.sessions?.forEach((s) => {
      s.abort = true
      if (this.downloaderService.isBusy(s)) s.rocket?.rocketEvents?.pauseAll()
    })

    this.clearAllOnExit()

    setTimeout(() => {
      this.sessions = []
      this.downloaderService.forceLogout()
    }, 2000)

    // cache ?
  }

  newRocketSession(org: Organization) {
    let rocket = this.rocketService.downloadPlus()

    rocket.opts.tokenAgent = this.rocketService.tokenProvider.downloader()
    rocket.opts.downloadTokenAgent = this.rocketService.tokenFileProvider.downloader
    rocket.opts.context = {
      bebop: true,
      id: Date.now(),
      ipService: this.rocketService.getRocketIpCache,
      notification: rocket.rocketEvents?.notification,
      serverEndpoint: this.bebopConfig.apiUrl,
    }

    if (this.settings.downloadCount) {
      rocket.opts.simultaneousDownload = Math.min(
        DownloadConstants.MAX_PARALLEL_DOWNLOAD,
        Math.max(DownloadConstants.MIN_PARALLEL_DOWNLOAD, this.settings.downloadCount)
      )
    }
    if (this.settings.verificationCount) {
      rocket.opts.simultaneousVerification = Math.min(
        DownloadConstants.MAX_PARALLEL_VERIFICATION,
        Math.max(DownloadConstants.MIN_PARALLEL_VERIFICATION, this.settings.verificationCount)
      )
    }

    rocket.opts.machineId = this.machineId
    rocket.opts.downloadPath = this.settings.downloadPath
    let service = this.downloaderService.createDownloadInstance(rocket, org, {})

    rocket.opts.tokenAgent.updateTokenParams({
      transferSessionID: service.sessionID,
      userId: this.userService.id,
    })

    this.rocketService.createRocketDownloaderEvents(service, this)

    return service
  }

  getRocketSession(org: Organization) {
    if (!org?._id) return null
    let service: RocketSession<any, UiDownloadFile> = this.downloaderQuery.getSessionByOrgIdValue(org._id)
    return service || this.newRocketSession(org)
  }

  selectDownloadSession(org: Organization, queue?: { projects: Project[]; files: any[] }) {
    if (!org) throw new Error('select download session without org')
    let service: RocketSession<any, UiDownloadFile> = this.getRocketSession(org)

    if (queue) {
      service.rocket?.rocketEvents?.pendingQueue(org, queue)
      // select first project
      let project = queue.projects?.[0]
      if (project) {
        let uiProject: UiProject = {
          _id: project?._id,
          name: project?.name,
          source: project,
        }

        this.downloaderService.selectProjectAndPod({
          selectedPod: project?.pod,
          selectedProject: uiProject,
        })
      }
    }

    service.rocket?.rocketEvents?.selectOrganization(org)
    this.downloaderService.update({ selectedDownload: service })

    this.updateDiskUsage(service)
  }

  updateDownloadPath(downloadPath: string) {
    if (this.settings.downloadPath == downloadPath) return
    let oldPath = this.settings.downloadPath
    this.settings.downloadPath = downloadPath

    this.userSettings.saveSettings('UserSettings', this.userService.id, this.settings, (err: NodeJS.ErrnoException) => {
      if (err) {
        console.error('user settings save download path error', err.message)
        this.settings.downloadPath = oldPath
      }
    })
  }

  updateDiskUsage(session: RocketSession<any, UiDownloadFile>) {
    if (!this.settings.downloadPath) throw new Error('download path is empty while checking disk usage')
    session?.rocket
      ?.diskUsage?.(this.settings.downloadPath)
      .then((usage: any) => {
        session.diskUsage = {
          available: session.rocket.readablizeBytes(usage.available || 0),
          // <= 5 percent of total space available
          low: usage.ratio.available <= 5,
          total: session.rocket.readablizeBytes(usage.total),
        }
        this.downloaderService.updateSession(session)
      })
      .catch((err: NodeJS.ErrnoException) => {
        // Toast ?
        // add notification and a indicator in ui
        this.toastService.show({
          text: 'Unable to access disk usage for ' + this.settings.downloadPath,
          type: 'warning',
        })
        console.error('Unable to access disk usage', err.message, this.settings.downloadPath)
      })
  }

  onUserLogin(err: NodeJS.ErrnoException, settings: any = {}) {
    this.settings = settings || {}

    // if user settings persisted wrongly as array
    if (Array.isArray(this.settings)) {
      this.settings = {}
    }

    let downloadPath = this.electronService.downloadPath
    if (!this.settings.downloadPath) {
      this.updateDownloadPath(downloadPath)
    } else {
      fs.readdir(this.settings.downloadPath, (err: NodeJS.ErrnoException, __) => {
        if (err) return this.updateDownloadPath(downloadPath)
      })
    }
  }

  saveAs(option: any = {}, cb: (p?: string) => void) {
    this.electronService
      .showSaveDialog({
        buttonLabel: 'Download',
        defaultPath: path.join(this.settings.downloadPath || this.electronService.downloadPath, option.name || ''),
        filters: [],
        message: 'Download As',
        nameFieldLabel: 'Download File',
        title: option.title || 'Download As',
      })
      .then((r) => {
        if (r.canceled) cb()
        else cb(r.filePath)
      })
      .catch((e) => {
        console.error('[Save Error]', e.message)
      })
  }

  changeLocation(label: string, defaultPath: string = '', onSuccess?: (file: any) => void) {
    let handle = this.electronService.showOpenDialog({
      buttonLabel: 'Select',
      defaultPath: defaultPath || this.settings.downloadPath || this.electronService.downloadPath,
      filters: [],
      message: 'Location',
      properties: ['openDirectory', 'createDirectory', 'promptToCreate'],
      title: label || 'Downloads',
    })

    handle
      .then((result) => {
        let locations = result.filePaths
        if (!result.canceled && locations && locations.length) {
          // check write access
          let dest = locations[0]

          let tmpFile = path.join(dest, '.bebop-writer')
          try {
            let stream = fs.createWriteStream(tmpFile)
            stream.end('test write access')
            setTimeout(() => {
              fs.unlink(tmpFile, () => {})
            }, 1000)
          } catch (e) {
            this.toastService.show({
              text: e.message,
              type: 'error',
            })
            return
          }

          if (onSuccess) return onSuccess(dest)
        }
      })
      .catch((e) => {
        console.error('[Open dialog error]', e.message)
      })
  }

  generateUniqueId(f: any) {
    if (environment.browser) {
      return btoa(f.fullpath + f.projectId + (f.dir ? 'd' : 'f')).substring(0, 25)
    } else {
      return Buffer.from(f.fullpath + f.projectId + (f.dir ? 'd' : 'f')).toString('base64')
    }
  }

  mapEsRecordToUI(f: any) {
    let fileType = f.dir ? 'folder' : this.rocketService.fileBadgeService.getFileType(f.name)
    return Object.assign(f, {
      displayName:
        f.name.length > DownloadConstants.MAX_NAME_DISPLAY_LENGTH
          ? f.name.substring(0, DownloadConstants.MAX_NAME_DISPLAY_LENGTH) + '…'
          : f.name,
      modified: new Date(f.mtimeMs),
      project: this.downloadProjectsMap[f.projectId],
      sizeBytes: f.size ? this.util.readablizeBytes(f.size) : '',
      type: fileType,
      uniqueIdentifier: this.generateUniqueId(f),
      userId: this.userService.id,
    })
  }

  getMappedProject(obj: any) {
    return this.downloadProjectsMap[obj.projectId || obj.project]
  }

  getAllPodId(orgId: string) {
    return this.downloadPods.filter((p) => p.organizationsAsPod?.includes(orgId)).map((p) => p._id)
  }

  getAllPodName(orgId: string) {
    return this.downloadPods.filter((p) => p.organizationsAsPod?.includes(orgId)).map((p) => p.name)
  }

  getAllProjectId(orgId: string) {
    return this.downloadProjects.filter((p) => p.organization?._id == orgId).map((p) => p._id)
  }

  getAllProjectName(orgId: string) {
    return this.downloadProjects.filter((p) => p.organization?._id == orgId).map((p) => p.name)
  }

  loadPendingDownloads() {
    let machineId = this.machineId

    this.downloaderService
      .list({
        machineId: machineId,
        userId: this.userService.id,
      })
      .subscribe((result: DownloadQueueResponse) => {
        if (result.error) {
          console.error('Unable to fetch download queue list', result.error)
          return
        }

        let queue = result || {}
        if (!queue.files?.length) return

        let entitlements = this.entitlements ?? {}

        queue.projects = queue.projects || []
        queue.projects = queue.projects.filter(
          (project) => entitlements[project.organization._id] && entitlements[project.organization._id].DOWNLOAD
        )

        if (!queue.projects.length) return

        let projectMap = queue.projects.reduce((acc, project) => {
          acc[project._id] = true
          return acc
        }, {})

        queue.files = queue.files.filter((f) => projectMap[f.project])

        if (!queue.files.length) return

        // Go by orgs

        let orgQueueMap: { [key: string]: { projects: Project[]; files: any[] } } = queue.projects.reduce((acc, p) => {
          if (!p?.organization?._id) return acc
          acc[p.organization._id] = acc[p.organization._id] || { files: [], projects: [] }
          acc[p.organization._id].projects.push(p)
          return acc
        }, {})

        let orgs = Object.keys(orgQueueMap)

        if (!orgs?.length) return

        for (let org of orgs) {
          let qs = orgQueueMap[org]
          let prjs = qs.projects
          let pids = prjs.map((p) => p._id)

          qs.files = queue.files.filter((f) => pids.includes(f.project))
          if (!qs.files.length) delete orgQueueMap[org]
        }

        orgs = Object.keys(orgQueueMap)

        if (!orgs?.length) return

        this.pendingQueue = queue

        queue.projects?.forEach(this.addProject)

        this.selectDownloadSessions(orgQueueMap)
      })
  }

  selectDownloadSessions(orgQueueMap: { [key: string]: { projects: Project[]; files: any[] } }) {
    let orgs = Object.keys(orgQueueMap)
    for (let org of orgs) {
      let queue = orgQueueMap[org]
      let prjs = queue.projects
      if (!prjs.length) continue
      this.selectDownloadSession(prjs[0].organization, queue)
    }
  }

  addProject(project: Project) {
    if (!project?._id) return
    let findById = (l: { _id: string }) => (r: { _id: string }) => r._id === l._id
    if (this.downloadProjectsMap[project._id]) return
    this.downloadProjectsMap[project._id] = project
    this.downloadProjects.push(project)

    if (project.pod && !this.downloadPods.find(findById(project.pod))) {
      this.downloadPods.push(project.pod)
    }

    if (project.organization && !this.downloadPods.find(findById(project.organization))) {
      this.downloadOrgs.push(project.organization)
    }
  }

  getNotificationId(org: Organization) {
    return 'download-' + org._id
  }

  removeNotification(org: Organization) {
    this.mainService.removeNotification({ id: this.getNotificationId(org) })
  }

  notifyPendingDownloads(org: Organization, downloadUiEvent: DownloadUiEvents) {
    // TODO notification related code
    let elm = document.querySelector('.bebop-notifications')

    if (!this.pendingNotifications.find((o) => o._id == org._id)) {
      this.pendingNotifications.push(org)

      this.mainService.addNotification({
        closeable: true,
        id: this.getNotificationId(org),
        onClose: () =>
          new Promise((r, _) => {
            let ref = this.modalService.open<CancelResumeActionModalComponent, CancelResumeActionModalAction>(
              CancelResumeActionModalComponent,
              {
                animateFrom: elm,
                data: {},
                hasBackdrop: true,
              },
              {
                hasBackdropClick: true,
                hasEscapeClose: true,
                isCentered: true,
              }
            )

            ref.once().subscribe((e) => {
              if (e?.name == 'Yes') {
                let cacheIdx = this.pendingNotifications.findIndex((o) => o._id == org._id)

                if (cacheIdx != -1) {
                  this.pendingNotifications.splice(cacheIdx, 1)
                  downloadUiEvent?.cancelAll?.()
                  // Old code doesn't have org passed in
                  // remove after release
                  if (!this.pendingNotifications?.length) {
                    downloadUiEvent?.cancelAllOrg?.()
                  }
                }
                r(true)
                return
              }

              r(false)
            })
          }),
        onSelect: () =>
          new Promise((r, _) => {
            let orgs = this.sessionQuery.getOrganizationsValue()

            let selectedOrg = orgs.find((o) => o?._id == org._id)

            if (!selectedOrg) {
              console.error('No matching orgs found to resume download', org._id, org.name)
              r(false)
              return
            }

            this.uiService.setSelectedOrg(selectedOrg)
            this.router.navigate([`app/download`])
            r(true)
          }),
        text: `Resume download for ${org.name}`,
        type: 'info',
      })
    }
  }

  clearAllOnExit() {
    this.downloadProjects = []
    this.downloadPods = []
    this.downloadOrgs = []
    this.settings = null
    this.pendingQueue = null
  }
}
