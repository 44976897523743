<div class="navbar-secondary">
  <div class="left-content" (click)="navigateBack()">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="#141414"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 7L5 12"
        stroke="#141414"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 17L5 12"
        stroke="#141414"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <span>Back</span>
  </div>
  <img
    ngSrc="/assets/img/cree8_logo_dark.svg"
    width="108"
    height="20"
    class="logo"
    alt="Cree8 | Creativity. Unleashed"
    (click)="onRedirectImage()"
  />
</div>
