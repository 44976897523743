<div class="bebop-projects">
  <div class="header-panel">
    <div class="headline1">Projects</div>
  </div>
  <div class="tabs-panel">
    <cree8-tabs [tabs]="headerTabs" (tabClick)="onChooseHeaderTab($event)"></cree8-tabs>
    <div class="actions-list">
      <cree8-tabs [tabs]="filterTabsOnLinkProject" (tabClick)="filterTabsLinks($event)" type="outlined"
        size="extra-small" *ngIf="activeParamsTab === 'links'"></cree8-tabs>
      <actions-list *ngIf="activeParamsTab !== 'live'" (refresh)="onRefresh()" (searchValue)="onSearchValue($event)"
        (sorting)="onSorting($event)" [allowedActions]="allowedActions" (created)="onCreatedProject()"
        (viewType)="onViewType($event)" [labelCreated]="'New Project'" [listSorting]="listSortingProjects"
        [activedSort]="activeSorting" [activedViewType]="activedViewType"></actions-list>
    </div>
  </div>

  <div class="projects-container scrollbar">
    <cree8-links-projects *ngIf="activeParamsTab === 'links'" #linkProject></cree8-links-projects>
    <cree8-all-projects *ngIf="activeParamsTab === 'all'" #allProjects
      (addProject)="onCreatedProject()"></cree8-all-projects>
    <cree8-live-files *ngIf="activeParamsTab === 'live' && hasLiveFiles" [enableLink]="true"></cree8-live-files>
  </div>
</div>
