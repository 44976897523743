import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { BehaviorSubject, Subject, takeUntil } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { RocketSession, UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import {
  SelectRocketUploadPathAction,
  SelectRocketUploadPathComponent,
} from 'src/app/components/rocket/uploads/modals/select-rocket-upload-path/select-rocket-upload-path.component'
import { Organization } from 'src/app/models/bebop.model'
import { ArchiveService } from 'src/app/services/archive.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { DownloaderLifecycleService } from 'src/app/services/rocket/download/downloader-lifecycle.service'
import { ArchiveQuery } from 'src/app/store/archive/archive.query'
import { ArchiveService as ArchiveStoreService } from 'src/app/store/archive/archive.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'

@Component({
  selector: 'cree8-archive-view',
  styleUrls: ['./archive-view.component.scss'],
  templateUrl: './archive-view.component.html',
})
export class ArchiveViewComponent implements OnInit, OnDestroy {
  @Output() breadcrumbChange = new EventEmitter<any[]>()
  @Input() breadcrumbs: { name: string; prefix?: string }[] = []
  @Input() project: any

  private destroy$ = new Subject<void>()
  items: any[] = [] // Combined list of files and folders
  currentPrefix = '/' // Initial prefix
  currentPage = 1 // Pagination current page
  hasMore = false // Flag to indicate more files are available
  isLoading = false // Loading indicator
  bucketName = ''
  credential = ''
  viewType = 'list'
  search = ''
  session: RocketSession<any, UiDownloadFile>
  changeLocationRef$: ModalOverlayRef<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>
  page = 1
  maxKeys = 48
  activeFolderPrefix = ''
  organization: Organization
  // continuationToken = ''

  continuationTokens: string[] = []
  currentToken: string | undefined = undefined

  nextToken: string | null = null
  prevTokens: (string | null)[] = []

  buttonPreviousDisabled$ = new BehaviorSubject<boolean>(true)
  buttonNextDisabled$ = new BehaviorSubject<boolean>(false)

  constructor(
    private util: BebopClientUtilsService,
    private archiveService: ArchiveService,
    private archiveStoreService: ArchiveStoreService,
    private archiveQuery: ArchiveQuery,
    private projectsService: ProjectsService,
    private uiQuery: UIQuery,
    private modalService: ComponentModalService,
    private lcService: DownloaderLifecycleService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }

        this.organization = org
        this.session = this.lcService.newRocketSession(org)

        this.archiveQuery
          .getSearchParams()
          .pipe(takeUntil(this.destroy$))
          .subscribe((params) => {
            if (params.credential && params.bucketName) {
              this.bucketName = params.bucketName
              this.currentPrefix = params.prefix
              this.currentPage = params.currentPage
              this.credential = params.credential
              this.prevTokens = []
              this.nextToken = null
              this.loadFilesAndFolders(this.currentPrefix, null)
              this.updateBreadcrumb(this.currentPrefix)
            }
          })
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  // Load files and folders based on prefix and page
  loadFilesAndFolders(prefix: string, continuationToken: string | null): void {
    if (this.isLoading) {
      return // Prevent duplicate requests
    }
    this.isLoading = true

    // if (!this.currentToken) {
    //   this.buttonPreviousDisabled$.next(true)
    // } else {
    //   this.buttonPreviousDisabled$.next(false)
    // }

    this.archiveService
      .listFiles({
        bucketName: this.bucketName,
        continuationToken: continuationToken,
        credentialId: this.credential,
        maxKeys: this.maxKeys,
        prefix,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (!isErrorResponse(response)) {
          // Map folders and add type "folder"
          const folders = response.folderList.map((folder: any) => ({
            name: folder.Prefix.split('/').slice(-2, -1)[0], // Extract folder name
            prefix: folder.Prefix,
            type: 'folder',
          }))

          // Map files and add type "file"
          const files = response.fileList
            .filter((file: any) => file.Key !== prefix) // Exclude current folder key
            .map((file: any) => {
              const keyParts = file.Key.split('/')
              const fullFileName = keyParts[keyParts.length - 1]
              const fileNameParts = fullFileName.split('.')
              const name = fullFileName
              const fileType = fileNameParts[fileNameParts.length - 1]

              return {
                ...file,
                fileType,
                name,
                size: file.Size,
                sizeBytes: this.util.readablizeBytes(file.Size),
                type: 'file',
              }
            })

          // Combine files and folders into a single list
          this.items = [...folders, ...files]
          this.hasMore = response.hasMore

          this.nextToken = response.continuationToken

          if (this.hasMore) {
            this.buttonNextDisabled$.next(false)
          } else {
            //disable next and enable previous since last page
            this.buttonNextDisabled$.next(true)
            this.buttonPreviousDisabled$.next(false)
          }

          if (this.prevTokens.length > 0) {
            this.buttonPreviousDisabled$.next(false)
          } else {
            this.buttonPreviousDisabled$.next(true)
          }
        }
        this.isLoading = false
      })
  }

  // Handle folder click
  onFolderClick(prefix: string): void {
    this.nextToken = null
    this.prevTokens = []
    this.archiveStoreService.updateSearch({
      bucketName: this.bucketName,
      credential: this.credential,
      currentPage: 1,
      prefix,
    })
    // this.loadFilesAndFolders(this.currentPrefix)
    this.updateBreadcrumb(prefix)
  }

  retrieveFileOrFolder(file: any, ev?: Event): void {
    this.changeLocationRef$ = this.modalService.open<SelectRocketUploadPathComponent, SelectRocketUploadPathAction>(
      SelectRocketUploadPathComponent,
      {
        animateFrom: ev?.target as Element,
        data: {
          archive: this.project.solutions?.archive,
          project: this.project,
          session: this.session,
          title: 'Select project and folder to retrieve files to',
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )

    this.changeLocationRef$.events().subscribe((e) => {
      if (e?.name == 'Cancel' || e?.name == 'Close') {
        return
      }

      const storage = this.project.storage
      let rootPath = [storage.transferServerMount, storage.directories.projects, this.project.folderName].join('/')

      if (this.project.solutions?.osOneFlex) {
        // Handle osOneFlex solution
        rootPath = [
          storage.transferServerMount,
          this.project.organization.flexMountCredential?.credentials?.lucid?.root_mount || '',
          storage.directories.projects,
          this.project.folderName,
        ].join('/')
      } else if (this.project.solutions?.osOneBlock) {
        // Handle osOneBlock solution
        rootPath = [
          storage.transferServerMount,
          this.project.organization.osOneBlockRootPath || '',
          storage.directories.projects,
          this.project.folderName,
        ].join('/')
      }

      if (!e.project) {
        // show notification
        this.toastService.show({
          text: 'No project selected',
          type: 'error',
        })
        return
      }

      let fullPath = rootPath.replace(/[/]?$/, '/') + e.path
      let relativePath = e.path || '/'

      // Call API to create sync settings in the selected project with the selected file/folder
      let retriveToProject = e.project

      // Do not sync full tree structure -
      let fromBucket = this.project.fromBucket.replace(/[/]?$/, '/') + this.currentPrefix.replace(/[/]?$/, '/')

      this.projectsService
        .saveToSyncQueue(retriveToProject._id, {
          credentials: [this.project.credentials[0]?._id],
          fromBucket: fromBucket,
          organization: this.organization._id,
          sync_active: true,
          sync_down_delete: false,
          sync_filters: { blacklist_file_tags: [], blacklist_folder_tags: [], file_tags: [], folder_tags: [] },
          sync_scheduler: { type: 'ONCE', value: 'ALWAYS' },
          sync_up_delete: false,
          sync_up_delete_file_only: false,
          syncDestination: relativePath === '/' ? '' : relativePath,
          syncDirection: 'down',
          syncFiles: [file.prefix || file.Key],
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: () => {
            this.toastService.show({
              text: 'Request to fetch file/folder failed',
              type: 'error',
            })
          },
          next: (res) => {
            this.toastService.show({
              text: 'Request queued, you can check the status in the transfer status tab',
              type: 'success',
            })
          },
        })
    })
  }

  onActionFile(props: { type: string; data: any }, file: any) {
    if (file.type === 'folder' && props.type === 'detail') {
      this.activeFolderPrefix = file.prefix
      this.onFolderClick(file.prefix)
      return
    }

    if (props.type === 'retrieve') {
      this.retrieveFileOrFolder(file)
      return
    }
  }

  private updateBreadcrumb(prefix: string): void {
    const segments = prefix.split('/').filter((seg) => seg)
    this.breadcrumbs = [
      { name: 'All', prefix: 'root' }, // Base path
      { name: this.project?.name || 'Unknown Project', prefix: '' }, // Dynamic project name
    ]
    let accumulatedPrefix = ''
    segments.forEach((segment) => {
      accumulatedPrefix += segment + '/'
      this.breadcrumbs.push({ name: segment, prefix: accumulatedPrefix })
    })

    this.breadcrumbChange.emit(this.breadcrumbs)
  }

  onRefresh() {
    this.loadFilesAndFolders(this.currentPrefix, null)
  }

  onSearchFilename(s: string) {
    this.search = this.activeFolderPrefix.length > 0 ? `${this.activeFolderPrefix}${s}` : s
    this.loadFilesAndFolders(this.search, null)
  }

  onCreateFolder(folderName: string) {
    let options = {
      bucketName: this.bucketName,
      credentialId: this.credential,
      key: this.currentPrefix.replace(/^\//, '') + folderName + '/',
    }
    this.archiveService.createFolder(options).subscribe({
      error: (e) => {
        console.log('Error creating folder', e)
        this.toastService.show({
          text: 'Error creating folder',
          type: 'error',
        })
      },
      next: (result) => {
        console.log('Folder created', folderName, result)
        this.toastService.show({
          text: 'Folder created successfully',
          type: 'success',
        })
        this.onRefresh()
      },
    })
  }

  onViewType(type: string) {
    this.viewType = type
  }

  onClickPreviousPage() {
    if (this.prevTokens.length > 0) {
      const newPrevTokens = [...this.prevTokens]
      newPrevTokens.pop() // Remove last token
      const prevToken = newPrevTokens[newPrevTokens.length - 1]
      this.prevTokens = newPrevTokens
      this.loadFilesAndFolders(this.currentPrefix, prevToken)
    }
  }

  onClickNextPage() {
    if (this.nextToken) {
      this.prevTokens.push(this.nextToken)
      this.loadFilesAndFolders(this.currentPrefix, this.nextToken)
    }
  }

  get filteredFiles() {
    return this.items.filter((f) => f.type !== 'folder')
  }
  get filteredFolders() {
    return this.items.filter((f) => f.type === 'folder')
  }
}
