import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { ResponseError } from '../models/response.model'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class WatermarkService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  deleteWatermarkByKey(id: string, type: string): Observable<any | ResponseError> {
    return this.http.delete<any>(`${this.bebopConfig.apiUrl}/api/v1/watermark/${id}/${type}`).pipe(
      catchError((error: any) => {
        console.error('On deleteWatermarkByKey', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getWatermarkByKey(id: string, type?: string): Observable<any | ResponseError> {
    // Construct URL with optional type
    let url = `${this.bebopConfig.apiUrl}/api/v1/watermark/${id}`
    if (type) url += `/${type}`

    return this.http.get<any>(url).pipe(
      catchError((error: any) => {
        console.error('On getWatermarkByKey', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateWatermarkByKey(
    id: string,
    data: {
      watermark: string
      type: string
    }
  ): Observable<any | ResponseError> {
    const url = `${this.bebopConfig.apiUrl}/api/v1/watermark/${id}`
    return this.http.post<any>(url, data).pipe(
      catchError((error: any) => {
        console.error('On updateWatermarkByKey', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
