import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { IActionType } from 'src/app/common/components/actions-list/actions-list.component'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { RemoteNavigator, RemoteNavTree } from 'src/app/components/rocket/common/classes/rocket-types'
import {
  AddProjectModalAction,
  AddProjectModalComponent,
} from 'src/app/components/rocket/project/modals/add-project-modal/add-project-modal.component'
import { LinkType, Project, User } from 'src/app/models/bebop.model'
import { ElectronService } from 'src/app/services/electron.service'
import { MainService } from 'src/app/services/main.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { UserService } from 'src/app/services/user.service'
import { LiveFilesQuery } from 'src/app/store/projects/live-files.query'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { ProjectsLinksService } from 'src/app/store/projects/projects-links.service'
import { DownloaderService } from 'src/app/store/rocket/downloader/downloader.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { NavPermissions } from 'src/app/store/ui/ui.store'

import { CreateFolderComponent } from '../../components/create-folder/create-folder.component'
import { LinksProjectsComponent } from '../links-projects/links-projects.component'

import { ProjectFilesSettingsComponent } from './project-files-settings/project-files-settings.component'
import { ProjectFilesUsersComponent } from './project-files-users/project-files-users.component'
import { ProjectFilesViewComponent } from './project-files-view/project-files-view.component'

@Component({
  selector: 'bebop-projects-detail',
  styleUrl: './projects-detail.component.scss',
  templateUrl: './projects-detail.component.html',
})
export class ProjectsDetailComponent {
  @ViewChild('projectFilesSettings')
  projectFilesSettings: ProjectFilesSettingsComponent
  @ViewChild('projectFilesUsers') projectFilesUsers: ProjectFilesUsersComponent
  @ViewChild('projectFilesView')
  projectFileViewComponent: ProjectFilesViewComponent
  @ViewChild('projectFilesUsers')
  projectFilesUsersComponent: ProjectFilesUsersComponent
  @ViewChild('createFolder') createFolderComponent: CreateFolderComponent
  @ViewChild('linksProjectsComponent') linksProjectsComponent: LinksProjectsComponent

  headerTabs: ITabs[] = [
    { active: true, label: 'Files', value: 'files' },
    { label: 'Links', value: 'links' },
    { label: 'Permissions', value: 'members' },
    { label: 'Live', value: 'live' },
  ]

  linkProjectTabs: ITabs[] = [
    { active: true, label: 'Receive', value: LinkType.Receive.toString() },
    { label: 'Shared', value: LinkType.Share.toString() },
    { label: 'Notes', value: LinkType.LiveRoom.toString() },
  ]

  listDefaultSortingProjects = [
    { label: 'Newest to oldest', value: '-date_created' },
    { label: 'Oldest to newest', value: 'date_created' },
    { label: 'From A to Z', value: 'name' },
    { label: 'From Z to A', value: '-name' },
  ]

  listLinkSortingProjects = [
    { label: 'Active links only', value: 'active' },
    { label: 'Show deactived', value: 'deactivated' },
    { label: 'Show expired only', value: 'expired' },
  ]

  listSortingProjects = this.listDefaultSortingProjects
  activeSorting = this.listSortingProjects[0].value
  activedViewType = 'grid'

  labelCreated: string = 'New Folder'

  settingsModalTitle = 'Project Settings'
  teamModalTitle = 'Team'
  createFolderModalTitle = 'Create a new Folder'

  modalSetting: Cree8Modal = {
    counting: 0,
    current: 0,
    title: '',
    type: 'confirmation',
  }

  allowedActions: IActionType[] = []
  activeTab = this.headerTabs[0].value
  navTree: RemoteNavTree[] = []

  _destroy$ = new Subject()
  project: Project | undefined
  projectUsers: User[] = []
  projectId: string | undefined = undefined

  projectLiveFile = {
    hasLiveFile: false,
    isLive: false,
    status: 'STOPPED',
  }

  organization: any
  navigator: RemoteNavigator
  isLoadingTeam = false
  team = []

  permissions: NavPermissions

  constructor(
    private projectsStoreService: ProjectsStoreService,
    private projectsService: ProjectsService,
    private projectsLinksService: ProjectsLinksService,
    private router: Router,
    private projectsQuery: ProjectsQuery,
    private downloaderService: DownloaderService,
    private modalService: ComponentModalService,
    private route: ActivatedRoute,
    private uiQuery: UIQuery,
    private toastService: ToastService,
    private userService: UserService,
    private liveFilesQuery: LiveFilesQuery,
    private mainService: MainService,
    private electronService: ElectronService
  ) {}

  ngOnInit(): void {
    this.uiQuery
      .getNavPermissions()
      .pipe(takeUntil(this._destroy$))
      .subscribe((permissions) => {
        this.permissions = permissions
        if (!permissions.users) {
          this.headerTabs = this.headerTabs.filter((tab) => tab.value !== 'members')
        } else {
          if (!this.headerTabs.some((tab) => tab.value === 'members')) {
            this.headerTabs.push({ label: 'Permissions', value: 'members' })
          }
        }
      })

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this._destroy$))
      .subscribe((org) => {
        // Ensure org exists
        if (!org) {
          this.projectsStoreService.resetStore()
          return
        }

        if (this.organization && this.organization._id !== org._id && this.projectId) {
          this.router.navigate(['/app/projects'])
        }
        this.organization = org
        this.renderDetail()

        this.liveFilesQuery
          .selectProjectActiveStatus(this.projectId)
          .pipe(takeUntil(this._destroy$))
          .subscribe((response) => {
            this.projectLiveFile = response

            if (this.projectLiveFile.hasLiveFile) {
              // Check if the 'Live' tab is already in the headerTabs array
              const liveTabExists = this.headerTabs.some((tab) => tab.value === 'live')

              // Push the tab only if it doesn't already exist
              if (!liveTabExists) {
                this.headerTabs.push({ class: this.projectLiveFile.status, label: 'Live', value: 'live' })
              }
            } else {
              this.headerTabs = this.headerTabs.filter((tab) => tab.value !== 'live')
            }
          })
      })
    this.projectsQuery
      .getViewTypeFiles()
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.activedViewType = response
      })

    this.projectsQuery
      .getSelectedProject()
      .pipe(takeUntil(this._destroy$))
      .subscribe((project) => {
        if (!project) return
        this.projectUsers = project.users
        this.loadUsers()
      })
  }

  renderDetail() {
    this.projectsQuery
      .getFilesProps()
      .pipe(takeUntil(this._destroy$))
      .subscribe((filesProps) => {
        this.navTree = filesProps.navTree
      })

    this.route.queryParams.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      if (res['activeTab']) {
        this.activeTab = res['activeTab']
      }
      this.mappingActionList(this.activeTab)

      if (this.projectId == res['projectId']) return

      this.projectId = res['projectId']
      this.setProject(this.projectId)
    })
  }

  setProject(id: string) {
    this.projectsService
      .getProject(id, {
        userPage: 1,
        userSize: 50,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.project = response.data
        this.projectUsers = this.project.users
        this.projectsStoreService.setSelectedProject(this.project)
        this.updateNav()
        this.loadUsers()
      })
  }

  updateNav() {
    this.navTree = [
      {
        name: this.project.name,
        path: '/',
        root: true,
      },
    ]
    this.projectsStoreService.setNavTree(this.navTree)
  }

  ngOnDestroy(): void {
    this.updateNav()
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  onSelectHeaderTabs(value: string) {
    this.router.navigate([], {
      queryParams: { activeTab: value },
      queryParamsHandling: 'merge',
    })
  }

  onFilterTabsLink(value: string) {
    this.linkProjectTabs.forEach((tab) => {
      tab.active = tab.value === value
    })

    this.projectsLinksService.filterByType(Number(value), this.projectId)
  }

  onRefresh() {
    if (this.activeTab === 'members') {
      this.projectFilesUsersComponent.loadUsers()
      return
    }

    if (this.activeTab === 'files') {
      this.projectFileViewComponent.refresh()
      this.loadUsers()
      return
    }
  }

  onSearchValue(searchText: string) {
    if (this.activeTab === 'members') {
      this.projectFilesUsersComponent.searchUser(searchText)
      return
    }

    if (this.activeTab === 'files') {
      this.projectFileViewComponent.onSearchFilename(searchText)
      return
    }
  }

  onSorting(sorting: any) {
    if (this.activeTab === 'members') {
      this.projectFilesUsersComponent.sortingMembers(sorting)
      return
    }

    if (this.activeTab === 'links') {
      this.linksProjectsComponent.sorting(sorting)
    } else {
      this.projectsStoreService.sortProjects(sorting)
    }

    this.activeSorting = sorting
  }

  onViewType(viewType: string) {
    this.activedViewType = viewType
    this.projectsStoreService.setViewTypeFiles(viewType)
  }

  onSubmit() {
    this.addNewProject()
  }

  addProject(e: MouseEvent) {
    this.addNewProject()
  }

  async addNewProject() {
    let ref = this.modalService.open<AddProjectModalComponent, AddProjectModalAction>(
      AddProjectModalComponent,

      {
        data: {},
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
      }
    )

    ref.events().subscribe(async (e) => {
      if (e.name == 'Add') {
        this.onRefresh()
      }
    })
  }

  private mappingActionList(value: string) {
    this.labelCreated = value === 'files' ? 'New Folder' : 'Assign Member'

    if (value === 'links') {
      this.allowedActions = ['sorting']
      this.onFilterTabsLink(this.linkProjectTabs[0].value)
      this.listSortingProjects = this.listLinkSortingProjects
    } else {
      this.allowedActions = ['search', 'sorting', 'refresh', 'settings', 'created']
      this.listSortingProjects = this.listDefaultSortingProjects
    }

    this.activeSorting = this.listSortingProjects[0].value

    this.mappingActiveTabs()
  }

  private mappingActiveTabs() {
    this.headerTabs.forEach((tab) => {
      tab.active = tab.value === this.activeTab
    })
  }

  onSettings() {
    this.modalSetting.title = this.settingsModalTitle
    this.modalSetting.labelConfirm = 'SAVE'
    this.modalSetting.type = 'confirmation'
  }

  onCloseModal() {
    if (this.modalSetting.title === this.teamModalTitle && this.activeTab === 'members') {
      this.projectFilesUsersComponent.getProjectDetail()
    }

    this.modalSetting.title = ''
  }

  onSubmitModal() {
    if (this.modalSetting.title === this.settingsModalTitle) {
      this.projectFilesSettings.saveSettings()
      return
    }

    if (this.modalSetting.title === this.createFolderModalTitle) {
      const name = this.createFolderComponent.bindings.name
      this.createFolder(name)
      return
    }
  }

  private createFolder(name: string) {
    this.modalSetting.loading = true
    this.projectFileViewComponent
      .createFolder(name)
      .then((result: any) => {
        if (result?.error?.description && result.error.description.indexOf('EEXIST') != -1) {
          this.toastService.show({
            text: 'Directory already exists!',
            type: 'warning',
          })
        } else {
          this.toastService.show({
            text: 'Folder created successfully',
            type: 'success',
          })
        }
      })
      .catch((error) => {
        this.toastService.show({
          text: 'Error creating folder',
          type: 'error',
        })
      })
      .finally(() => {
        this.projectFileViewComponent.refresh()
        this.modalSetting.title = ''
        this.modalSetting.loading = false
      })
  }

  onCreated() {
    if (this.activeTab === 'members') {
      this.modalSetting.title = this.teamModalTitle
      this.modalSetting.type = 'close'
    }

    if (this.activeTab === 'files') {
      this.modalSetting.title = this.createFolderModalTitle
      this.modalSetting.labelConfirm = 'SAVE'
      this.modalSetting.type = 'confirmation'
    }
  }

  onGoDirectory(segment: RemoteNavTree) {
    this.projectFileViewComponent.goBackDirectory(segment)
  }

  browseAll() {
    this.downloaderService.clear()
    this.projectsStoreService.clear()
    this.router.navigate(['/app/projects'])
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement
    const scrollTop = target.scrollTop
    const scrollHeight = target.scrollHeight
    const clientHeight = target.clientHeight

    // Check if user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 40 && this.projectFileViewComponent) {
      this.projectFileViewComponent.loadMoreFiles()
    }
  }

  loadUsers() {
    this.isLoadingTeam = true
    this.userService
      .getUsers({
        page: 1,
        searchText: '',
        size: 100,
        sort: 'name',
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        error: (err) => {
          this.toastService.show({ text: err.error.msg, type: 'error' })
          this.isLoadingTeam = false
        },
        next: (users) => {
          this.isLoadingTeam = true

          this.team = this.projectUsers.map((projectUser) => {
            const teamUser = users.data.find((user) => user._id === projectUser._id)
            return teamUser ? { ...projectUser, ...teamUser } : projectUser
          })
          // this.team = Array(10).fill(this.team).flat()
          this.team.sort((a, b) => {
            if (a.status === 'online' && b.status !== 'online') return -1
            if (a.status !== 'online' && b.status === 'online') return 1
            return a.name.localeCompare(b.name)
          })

          this.isLoadingTeam = false
        },
      })
  }

  copyEmail(email: string): void {
    this.electronService.copyToClipboard(email)
    navigator.clipboard.writeText(email)

    this.toastService.show({
      text: 'Email copied to clipboard',
      type: 'success',
    })
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }
}
