import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'

export type BestPracticeBroadcastActionType = 'Cancel' | 'Continue' | 'Link'
export type BestPracticeBroadcastLinkActionType = 'Best Practices'

export type BestPracticeBroadcastActionName = Exclude<
  BestPracticeBroadcastActionType | BestPracticeBroadcastLinkActionType,
  'Link'
>
export interface BestPracticeBroadcastAction {
  name: BestPracticeBroadcastActionType
  doNotShowAgain?: boolean
  linkAction?: 'Best Practices'
}

@Component({
  selector: 'bebop-best-practice-broadcast',
  templateUrl: './best-practice-broadcast.component.html',
  styleUrls: ['./best-practice-broadcast.component.scss'],
})
export class BestPracticeBroadcastComponent implements OnInit, OnExternalModalClose {
  doNotShowAgain = false

  constructor(
    public ref: ModalOverlayRef<BestPracticeBroadcastComponent, BestPracticeBroadcastAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  continue() {
    this.ref.send({ name: 'Continue', doNotShowAgain: this.doNotShowAgain })
    this.ref.close()
  }

  onDoNotShowAgain(value: boolean) {
    this.doNotShowAgain = value
  }

  onAction(name: BestPracticeBroadcastActionName) {
    switch (name) {
      case 'Best Practices':
        this.ref.send({ name: 'Link', linkAction: name })
        break
      case 'Continue':
        this.ref.send({ name: 'Continue', doNotShowAgain: this.doNotShowAgain })
        this.ref.close()
        break
      case 'Cancel':
        this.ref.send({ name: 'Cancel' })
        this.ref.close()
    }
  }
}
