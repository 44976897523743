import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'

import { Subscription } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { LinkAction } from 'src/app/common/enums'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { Organization, OrgEntitlements, Pod, UserWorkstation, Workstation } from 'src/app/models/bebop.model'
import {
  StandardDataResponse,
  WorkstationActionResponse,
  WorkstationLaunchVmResponse,
} from 'src/app/models/response.model'
import { AlertLogService } from 'src/app/services/alert-log.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'
import { ExecutableService } from 'src/app/services/executable.service'
import { MainService } from 'src/app/services/main.service'
import { UserService } from 'src/app/services/user.service'
import { UserSettingsService } from 'src/app/services/user-settings.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { WorkstationQuery } from 'src/app/store/workstation/workstation.query'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'
import { RetryWorkstation } from 'src/app/store/workstation/workstation.store'
import { environment } from 'src/environments/environment'

import {
  MAX_LAUNCH_RETRY_COUNT,
  RETRY_LAUNCH_SESSION_DELAY,
  UiWorkstation,
  VmPowerStatus,
  WorkstationErrorCodes,
  WorkstationLaunchState,
  WorkstationLaunchType,
  WorkstationLaunchTypeBgColors,
} from '../classes/workstation-types'
import {
  EditWorkstationNicknameAction,
  EditWorkstationNicknameComponent,
} from '../modals/edit-workstation-nickname/edit-workstation-nickname.component'
import {
  AppInstallType,
  NoAppWorkstationAction,
  NoAppWorkstationComponent,
} from '../modals/no-app-workstation/no-app-workstation.component'
import {
  RetryWorkstationAction,
  RetryWorkstationComponent,
} from '../modals/retry-workstation/retry-workstation.component'
import {
  RetryWorkstationLogAction,
  RetryWorkstationLogComponent,
} from '../modals/retry-workstation-log/retry-workstation-log.component'
import {
  StartWorkstationAction,
  StartWorkstationComponent,
} from '../modals/start-workstation/start-workstation.component'
import { StopWorkstationAction, StopWorkstationComponent } from '../modals/stop-workstation/stop-workstation.component'
import {
  TermianateWorkstationAction,
  TerminateWorkstationComponent,
} from '../modals/terminate-workstation/terminate-workstation.component'

/**

  <bebop-workstation-card [station]="workstation"></bebop-workstation-card>

  workstation: Workstation = {
    name: 'avantage-ws-use-s78iv-8ui',
    state: 'Running',
    badges: ['PCoIP', 'Jump', 'Parsec'],
    broadcast: true,
    ...
  }

 */

type CallbackFunction = (arg1?: any, arg2?: any) => void

@Component({
  selector: 'bebop-workstation-card',
  styleUrls: ['./workstation-card.component.scss'],
  templateUrl: './workstation-card.component.html',
})
export class WorkstationCardComponent implements OnInit, OnDestroy {
  @Input('station') station: UiWorkstation
  // It should be Input, not Output
  @Input('on-user-interaction') onAction: (w: UiWorkstation) => void

  organization: Organization
  pod: Pod

  loc = ''

  destroyed = false
  environment = environment
  showMoreActionDropdown = false
  embeddedSupportCheckComplete = false
  embeddedClientSupported = true

  // Stopping..., Good to go! etc
  get powerCodeDescription() {
    return this.station?.powerCodeDescription || ''
  }
  // Started 1h ago ...
  get powerCodeLabel() {
    return this.station?.powerCodeLabel || 'Not Started'
  }

  // assigned user
  get assignedUser() {
    return this.station?.source?.USER_ID?.name || ''
  }

  get isReadyToLaunch() {
    return this.station?.source?.READY_TO_LAUNCH ?? false
  }

  get isAssignedToSelf() {
    return this.station?.source?.USER_ID?._id == this.userService.id
  }

  get isAdminAccess() {
    let source = this.station?.source
    if (!source) return false

    let user = source?.USER_ID
    if (!user) return false

    if (this.station.state != 'Starting' && this.station.state != 'Started' && this.station.state != 'Running')
      return false

    return (
      user?.role?.name === 'super_user' ||
      user?.role?.name === 'l1_admin' ||
      user?.role?.name === 'l2_admin' ||
      user?.role?.name === 'administrator'
    )
  }

  get isMaintenance() {
    return (this.station?.source?.IN_MAINTENANCE || this.isAdminAccess) && !this.isAssignedToSelf
  }

  get isRetryingBySomeoneElse() {
    return this.station?.state == 'Retrying'
  }

  get isFavorite() {
    return this.station?.favorite ?? false
  }

  private retryInstance: RetryWorkstation
  get isRetryRequested() {
    return this.station.state == 'Retrying' || (this.retryInstance ?? null)
  }

  isLaunching = false

  launchFullScreenSession = true

  launchRetryCount = 0

  loading$: Subscription

  settings$: Subscription

  retryWorkstations: RetryWorkstation[]
  retryWorkstation$: Subscription

  entitlements: OrgEntitlements
  entitlements$: Subscription

  settings: any

  get wid() {
    return this.station?.source?._id
  }

  constructor(
    private modalService: ComponentModalService,
    private userService: UserService,
    private toastService: ToastService,
    private wservice: WorkstationService,
    private workstationQuery: WorkstationQuery,
    private alertLogService: AlertLogService,
    private uiQuery: UIQuery,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    private util: BebopClientUtilsService,
    private userSettings: UserSettingsService,
    private exeService: ExecutableService,
    private mainService: MainService,
    private workstationService: WorkstationService,
    private sessionQuery: SessionQuery
  ) {}

  ngOnInit(): void {
    if (!this.station) {
      console.error('<bebop-workstation-card> station attr is missing')
      throw new Error('<bebop-workstation-card> station attr is missing')
    }

    if (!this.station.state) {
      this.station.state = 'Not Started'
    }

    this.organization = this.uiQuery.getSelectedOrgValue()
    this.pod = this.uiQuery.getSelectedPodValue()

    if (this.pod?.region?.cgUrlLocArr?.length == 1) {
      this.loc = this.pod.region.cgUrlLocArr[0].location
    }

    this.getEmbeddedPCOIPSupportStatus()

    this.loading$ = this.workstationQuery.getLoaderWorkstations().subscribe((lw) => (this.isLaunching = lw[this.wid]))

    this.retryWorkstation$ = this.workstationQuery.getRetryWorkstations().subscribe((rws) => {
      this.retryWorkstations = rws ?? []
      this.retryInstance = this.retryWorkstations.find((w) => w?.workstation?._id == this.station?.source?._id)

      this.updateTickerTimer()
    })

    this.settings$ = this.userSettings.getUserSettings().subscribe((s) => {
      this.settings = s
    })

    this.entitlements$ = this.sessionQuery
      .getEntitlements()
      .subscribe((entitlements) => (this.entitlements = entitlements))
  }

  ngOnDestroy(): void {
    ;[this.loading$, this.settings$, this.retryWorkstation$, this.entitlements$].forEach((e) => e?.unsubscribe())
    this.destroyed = true
    this.clearTimeTicker()
  }

  toggleFullscreenSession() {
    this.launchFullScreenSession = !this.launchFullScreenSession
  }

  // public get bgColors(): typeof WorkstationLaunchTypeBgColors {
  //   return WorkstationLaunchTypeBgColors;
  // }

  getBgColor(badge: WorkstationLaunchType) {
    if (this.station.state != 'Running' || this.isMaintenance) {
      return WorkstationLaunchTypeBgColors.Fallback
    }
    let w = this.station?.source

    switch (badge) {
      case 'PCoIP':
        return w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL == 'PCOIP_SDK' && this.embeddedClientSupported
          ? WorkstationLaunchTypeBgColors.PCoIP
          : WorkstationLaunchTypeBgColors.PCoIPExt
      case 'Jump':
        return WorkstationLaunchTypeBgColors.Jump
      case 'Parsec':
        return WorkstationLaunchTypeBgColors.Parsec
      case 'Ultra':
        return WorkstationLaunchTypeBgColors.Ultra
      default:
        return WorkstationLaunchTypeBgColors.Fallback
    }
  }

  startWorkstation(ev: Event) {
    if (this.organization?.suspended) {
      this.toastService.show({
        text: 'Your CREE8 account has been suspended, please contact CREE8 Account Payable to resolve.',
        type: 'error',
      })
      return
    }

    // when already set do not show again
    if (this.settings?.doNotShowAgainStartWorkstation) {
      this.station.source.POWER_STATUS_CODE = VmPowerStatus.STARTING
      this.station.state = 'Starting'
      this.updateStation()
      this.onAction?.(this.station)
      this.startVM()
      return
    }

    let ref = this.modalService.open<StartWorkstationComponent, StartWorkstationAction>(
      StartWorkstationComponent,
      {
        animateFrom: ev.target as Element,
        hasBackdrop: true,
      },
      {}
    )

    let subs$ = ref.events().subscribe(({ doNotShowAgain, linkAction, name }) => {
      if (name != 'Link') {
        subs$.unsubscribe()
      }

      if (doNotShowAgain) {
        this.settings.doNotShowAgainStartWorkstation = true
        this.userSettings.saveSettings('UserSettings', this.userService.id, this.settings, () => {})
      }

      if (name == 'Link') {
        if (linkAction == 'Best Practices') {
          this.util.openExternalLink(LinkAction.LAUNCH_SESSION_BEST_PRACTICES, { userId: this.userService.id })
          return
        }

        if (linkAction == 'Zero Client') {
          this.util.openExternalLink(LinkAction.ZERO_CLIENT, { userId: this.userService.id })
          return
        }

        return
      }

      if (name == 'Start') {
        this.station.source.POWER_STATUS_CODE = VmPowerStatus.STARTING
        this.station.state = 'Starting'
        this.updateStation()
        this.onAction?.(this.station)
        this.startVM()
      }
    })

    // TODO
  }

  launchWorkstation(ev: Event) {
    this.preLaunchSession(ev)
    // this.launchVmSession()
  }

  stopWorkstation(ev: Event) {
    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    if (w.USER_ID && w.USER_ID._id !== this.userService.id) {
      this.toastService.show({
        text: `Sorry '${displayName}' workstation is not assigned to you.`,
        type: 'error',
      })
      return
    }

    let ref = this.modalService.open<StopWorkstationComponent, StopWorkstationAction>(
      StopWorkstationComponent,
      { animateFrom: ev.target as Element, hasBackdrop: true },
      {}
    )

    ref.once().subscribe(({ name }) => {
      switch (name) {
        case 'Cancel':
          return
        case 'Stop':
          this._stopWorkstation()
      }
    })
  }

  terminateWorkstation(ev: Event) {
    let ref = this.modalService.open<TerminateWorkstationComponent, TermianateWorkstationAction>(
      TerminateWorkstationComponent,
      { animateFrom: ev.target as Element, hasBackdrop: true },
      {}
    )

    ref.once().subscribe(({ name }) => {
      switch (name) {
        case 'Cancel':
          return
        case 'Terminate':
          this._terminateWorkstation()
      }
    })
  }

  onSelectBadge(badge: WorkstationLaunchType, ev: Event) {
    if (!this.isReadyToLaunch) return

    if (this.station.state != 'Running' || this.isMaintenance) return

    this.setDefaultChannel(badge, ev)
  }

  onClickBroadcast() {
    // TODO
  }

  _stopWorkstation() {
    let w = this.station?.source

    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    if (w.USER_ID && w.USER_ID._id !== this.userService.id) {
      this.toastService.show({
        text: `Sorry '${displayName}' workstation is not assigned to you.`,
        type: 'error',
      })
      return
    }

    this.wservice.setLoaderWorkstation(this.wid, true)

    let prevState = this.station.state
    let prevVmState = w.POWER_STATUS_CODE
    let label = `Stop workstation: ${displayName}`
    this.station.state = 'Stopping'
    w.POWER_STATUS_CODE = VmPowerStatus.STOPPING
    this.updateStation()
    this.onAction?.(this.station)

    this.wservice.stopWorkstation(this.wid).subscribe((res: WorkstationActionResponse) => {
      this.wservice.setLoaderWorkstation(this.wid, false)
      if (res.error || !res?.success) {
        this.station.state = prevState
        w.POWER_STATUS_CODE = prevVmState
        this.updateStation()
        if (!this.destroyed) this.onAction?.(this.station)
        this.toastService.show({
          text: `Failed to stop ${displayName} workstation.`,
          type: 'error',
        })

        this.alertLogService.logActivity(label, res.error?.msg || 'Stop failed', 'error')
        return
      }

      this.toastService.show({
        text: `Stopping ${displayName} workstation.`,
        type: 'info',
      })

      this.station.state = 'Stopping'
      w.POWER_STATUS_CODE = VmPowerStatus.STOPPING
      this.updateStation()
      this.onAction?.(this.station)

      console.log(label, res)
      this.wservice.stopIfCastWorkstation(w)
    })
  }

  _terminateWorkstation() {
    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    this.wservice.terminateWorkstation(this.wid).subscribe((res: WorkstationActionResponse) => {
      this.toastService.show({
        text: `Terminating ${displayName}.`,
        type: 'info',
      })
    })
  }

  appNotAvailable(channel: AppInstallType, ev?: Event) {
    this.exeService.appNotAvailable(channel, ev?.target as Element)
  }

  openBeBopClient_EXT(cb: CallbackFunction, token?: string, ev?: Event) {
    let pcoipExternalInstalled = this.exeService.isPcoIPExternalAvailable()
    if (!pcoipExternalInstalled) {
      this.appNotAvailable('PCoIP', ev)
      this.wservice.setLoaderWorkstation(this.wid, false)
      return cb()
    }
    let w = this.station?.source

    let data = {
      dom: w.REGION_ID.adDomainUrl,
      usr: this.userService.user?.username,
      vm: w.NAME,
    }

    if (!token) {
      console.error('Generate JWT failed')
      this.wservice.setLoaderWorkstation(this.wid, false)
      return cb()
    }

    let cmd = this.exeService.openPcoIPExternalCommand({
      cgDNS: w.REGION_ID.cgDNS,
      token,
    })

    setTimeout(() => {
      this.toastService.show({
        text: `Launching PCoIP App…`,
        type: 'info',
      })
      this.wservice.setLoaderWorkstation(this.wid, false)
    }, 3000)

    this.electronService
      .openExternal(cmd)
      .catch((e) => this.toastService.show({ text: `Launch failed: ${e.message}`, type: 'error' }))
    return cb()
  }

  async getEmbeddedPCOIPSupportStatus() {
    if (environment.browser) {
      this.embeddedClientSupported = false
      return this.embeddedClientSupported
    } else {
      return true
    }

    // The latest binaries should support the Apple Silicon M3 - leaving the code below to handle if any unsupported chipset
    if (this.embeddedSupportCheckComplete) return this.embeddedClientSupported

    let pcoipEmbeddedUnsupportedChipsets = ['M3']
    if ((process.arch == 'arm' || process.arch == 'arm64') && process.platform == 'darwin') {
      let cpuInfo = await this.exeService.getMacCPUInfo()
      this.embeddedClientSupported = !pcoipEmbeddedUnsupportedChipsets.some(
        (chip) => cpuInfo.toLocaleLowerCase().indexOf(chip.toLocaleLowerCase()) != -1
      )
    }
    this.embeddedSupportCheckComplete = true
    return this.embeddedClientSupported
  }

  async openBeBopClient(ev: Event, res: WorkstationLaunchVmResponse, cb: CallbackFunction) {
    let { cgEndpoint, token, uri: desktopUri } = res
    let w = this.station?.source

    this.wservice.setLoaderWorkstation(this.wid, true)
    console.info(w.NAME + ' : ' + w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL)
    //-- Launch Externally - if default channel is PCOIP_EXT or if embedded client is not supported
    if (w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'PCOIP_EXT' || !this.embeddedClientSupported) {
      return this.openBeBopClient_EXT(cb, token, ev)
    }

    if (w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL != 'PCOIP_SDK') {
      this.wservice.setLoaderWorkstation(this.wid, false)
      return
    }

    // Before open PcoIP, check for prerequisites like dll
    let proceed = await this.workstationService.beforeLaunchPCoIP()

    if (!proceed) {
      this.wservice.setLoaderWorkstation(this.wid, false)
      return
    }

    let cmd = this.exeService.openPcoIPCommand({
      cgEndpoint,
      desktopUri,
      launchFullScreenSession: this.launchFullScreenSession,
    })

    //console.log('launchVMUrl-RESPONSE', desktopUri);
    if (cmd instanceof Error) {
      this.toastService.show({
        text: cmd.message,
        type: 'error',
      })

      this.wservice.setLoaderWorkstation(this.wid, false)

      return
    }

    this.alertLogService.logActivity(cmd, {}, 'info')
    // console.info('cmd: ', cmd)

    let timedout = window.setTimeout(() => this.wservice.setLoaderWorkstation(this.wid, false), 5000)

    let proc = this.electronService.exec(cmd, (error, stdout, stderr) => {
      console.error('error: ', error)
      console.info('stdout: ', stdout)
      console.error('stderr: ', stderr)

      if (error || stderr) {
        this.toastService.show({
          text: `Session Stopped.`,
          type: 'warning',
        })
        this.wservice.setLoaderWorkstation(this.wid, false)
        return
      }

      if (stdout?.indexOf('Session refused') > -1) {
        this.alertLogService.logActivity(stdout)
        this.wservice.setLoaderWorkstation(this.wid, false)

        this.wservice.removeActiveAppSession(this.wid)

        window.setTimeout(() => {
          window.clearTimeout(timedout)
          cb(null, { retry: true })
        }, RETRY_LAUNCH_SESSION_DELAY)
        return
      }

      if (stdout.indexOf('Session closed remotely') > -1) {
        this.alertLogService.logActivity(stdout)
        this.wservice.removeActiveAppSession(this.wid)
        this.wservice.setLoaderWorkstation(this.wid, false)

        this.toastService.show({
          text: `Closing Session.`,
          type: 'info',
        })
      }
      this.wservice.setLoaderWorkstation(this.wid, false)
    })

    if (proc) this.wservice.addActiveAppSession(this.wid, proc)
  }

  openJumpSession(ev?: Event) {
    let w = this.station?.source

    let jumpDeviceID = w.SESSION_LAUNCH_CHANNEL?.JUMP.deviceID || null
    if (!jumpDeviceID) {
      this.toastService.show({
        text: `Device ID not available.`,
        type: 'error',
      })

      return
    }

    let loginUsername = this.pod?.region?.adDomainUrl + '\\' + this.userService.user?.username

    let jumpInstalled = this.exeService.isJumpAvailable()
    if (!jumpInstalled) {
      this.appNotAvailable('Jump', ev)
      return
    }

    this.wservice.setLoaderWorkstation(this.wid, true)

    let cmd = this.exeService.openJumpCommand({
      deviceId: jumpDeviceID,
      displayName: w.NAME,
      userName: loginUsername,
    })
    //helperService.log(cmd);
    console.info('opeJumpSession - cmd: ', cmd)

    // TODO label ?
    // 'Launching an awesome desktop via Jump!'

    setTimeout(() => {
      this.toastService.show({
        text: `'Please open the Jump App to proceed.'`,
        type: 'info',
      })

      this.wservice.setLoaderWorkstation(this.wid, false)
    }, 3000)

    this.electronService
      .openExternal(cmd)
      .catch((e) => this.toastService.show({ text: `Launch failed: ${e.message}`, type: 'error' }))
  }

  async openParsecSession(ev?: Event) {
    let w = this.station?.source

    let parsecID = w.SESSION_LAUNCH_CHANNEL?.PARSEC?.peerID || null
    if (!parsecID) {
      this.toastService.show({
        text: `Peer ID not available.`,
        type: 'error',
      })
      return
    }

    if (environment.browser) {
      let cmd = this.exeService.openParsecWithURI(parsecID)
  
      setTimeout(() => {
        this.toastService.show({
          text: `Launching Parsec App…`,
          type: 'info',
        })
        this.wservice.setLoaderWorkstation(this.wid, false)
      }, 3000)
  
      this.electronService
        .openExternal(cmd)
        .catch((e) => this.toastService.show({ text: `Launch failed: ${e.message}`, type: 'error' }))
      return
    }

    if (await !this.exeService.isParsecAvailableAsync()) {
      this.appNotAvailable('Parsec', ev)
      return
    }

    let cmd = this.exeService.openParsecCommand(parsecID)
    //helperService.log(cmd);
    console.info('openParsecSession - cmd: ', cmd)

    // TODO label ?
    // 'Launching an awesome desktop via Parsec!'
    this.wservice.setLoaderWorkstation(this.wid, true)

    setTimeout(() => {
      this.toastService.show({
        text: `'Please open the Parsec App to proceed.'`,
        type: 'info',
      })

      this.wservice.setLoaderWorkstation(this.wid, false)
    }, 3000)

    let proc = this.electronService.exec(cmd, (error, stdout, stderr) => {
      console.error('openParsecSession error: ', error)
      console.info('openParsecSession stdout: ', stdout)
      console.error('openParsecSession stderr: ', stderr)
    })

    if (proc) {
      this.wservice.addActiveAppSession(this.wid, proc)
    }
  }

  openPcoipSession(ev?: Event, retries: number = 0) {
    let w = this.station?.source

    let payload = {
      clientConfig: { ...this.bebopConfig.settings, platform: this.electronService.platform },
      desktop: w,
      organization: this.organization,
      pod: this.pod,
    }

    if (this.loc) {
      w.selectedLocation = this.loc
    }

    console.log('@launchVMSession', w)
    this.wservice.setLoaderWorkstation(this.wid, true)

    this.wservice.launchVm(payload).subscribe((res: WorkstationLaunchVmResponse) => {
      if (res.error) {
        this.launchRetryCount = 0
        this.toastService.show({
          text: `Failed to launch desktop session due to a server error.`,
          type: 'error',
        })
        this.wservice.setLoaderWorkstation(this.wid, false)
        return
      }

      this.alertLogService.logActivity('Launch VM', {
        DESKTOP_ID: w.DESKTOP_ID,
        USER_ID: w.USER_ID,
        WINDOWS_NAME: w.WINDOWS_NAME,
        WORKSTATION_GROUP: this.station.group,
      })

      if (res.uri) {
        this.launchRetryCount = 0

        this.toastService.show({
          text: `Established connection.`,
          type: 'info',
        })

        //Established connection with gateway.
        this.openBeBopClient(ev, res, (err, data) => {
          if (data?.retry && retries < MAX_LAUNCH_RETRY_COUNT) {
            this.toastService.show({
              text: `Retrying connection to server. We apologize for the inconvenience.`,
              type: 'info',
            })
            this.openPcoipSession(ev, retries + 1)
          } else if (data?.retry) {
            this.toastService.show({
              text: `Session refused.`,
              type: 'error',
            })
            this.wservice.setLoaderWorkstation(this.wid, false)
            this.wservice.removeActiveAppSession(this.wid)
          }
        })
        return
      }

      if (res.msg) {
        if (res.code == 'ERR_AUTH_USER') {
          this.launchRetryCount = 0

          this.toastService.show({
            text: WorkstationErrorCodes[res.code],
            type: 'error',
          })
          this.wservice.setLoaderWorkstation(this.wid, false)
          let displayName = w?.DISPLAY_NAME || w?.NAME || ''

          this.alertLogService.logActivity(`Launch workstation ${displayName}`, `Failed to authenticate user.`, 'error')

          return
        }

        if (res.code == 'ERR_CONNECTING_DESKTOP') {
          if (this.launchRetryCount < MAX_LAUNCH_RETRY_COUNT) {
            this.launchRetryCount++
            this.toastService.show({
              text: `${WorkstationErrorCodes[res.code]}. [${this.launchRetryCount}]Retrying…`,
              type: 'error',
            })

            this.wservice.setLoaderWorkstation(this.wid, false)
            return this.openPcoipSession(ev)
          } else {
            this.toastService.show({
              text: `${WorkstationErrorCodes[res.code]}.`,
              type: 'error',
            })
            return
          }
        }
        this.launchRetryCount = 0

        this.toastService.show({
          text: `Failed to connect to workstation due to an unknown issue. Please try again in few minutes.`,
          type: 'error',
        })

        this.wservice.setLoaderWorkstation(this.wid, false)

        return
      }

      if (this.launchRetryCount < MAX_LAUNCH_RETRY_COUNT) {
        this.launchRetryCount++
        this.toastService.show({
          text: `Failed to launch workstation. [${this.launchRetryCount}]Retrying…`,
          type: 'error',
        })
        this.wservice.setLoaderWorkstation(this.wid, false)
        return this.openPcoipSession(ev)
      }

      this.launchRetryCount = 0
      this.toastService.show({
        text: `Failed to launch workstation.`,
        type: 'error',
      })
      this.wservice.setLoaderWorkstation(this.wid, false)
    })
  }

  launchVmSession(via?: string, ev?: Event) {
    let w = this.station?.source

    console.info('launch Vm Session: ', w.NAME, w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL, via)
    if (w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'JUMP' || via === 'JUMP') {
      this.openJumpSession(ev)
      return
    }

    if (w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL == 'PARSEC' || via === 'PARSEC') {
      this.openParsecSession(ev)
      return
    }

    if (
      w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'PCOIP_SDK' ||
      w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'PCOIP_URL' ||
      w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'PCOIP_EXT'
    ) {
      this.openPcoipSession(ev)

      return
    }

    console.error('Invalid state ?', w)
  }

  startVM() {
    this.wservice.setLoaderWorkstation(this.wid, true)
    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    let label = `Start workstation: ${displayName}`

    let prevState = this.station.state

    let updatePreviousState = () => {
      this.station.state = prevState
      this.updateStation()
    }

    let updateNextState = () => {
      w.POWER_STATUS_CODE = VmPowerStatus.STARTING
      this.station.state = 'Starting'
      this.updateStation()
    }

    // gcp instances take 15-30 seconds to respond
    let futureId = window.setTimeout(updateNextState, 1500)
    this.wservice.startWorkstation(this.wid).subscribe((res: WorkstationActionResponse) => {
      window.clearTimeout(futureId)
      this.wservice.setLoaderWorkstation(this.wid, false)
      if (res?.error || !res?.success || res?.errObj) {
        updatePreviousState()
        if (!this.destroyed) this.onAction?.(this.station)

        let errCode = typeof res.errObj == 'string' ? res.errObj : res.errObj?.code
        let msg = WorkstationErrorCodes[errCode]
        if (msg) {
          this.alertLogService.logActivity(label, msg, 'warn')
          // retry on capacity issue
          if (this.isRetryAllowed() && WorkstationErrorCodes.InsufficientInstanceCapacity == msg) {
            return this._retryWorkstation('Failed to launch desktop due to temporary capacity limits')
          }

          this.toastService.show({
            text: msg,
            type: 'warning',
          })
          return
        }

        msg = errCode || res.error?.msg || res.message || 'Start failed'

        this.toastService.show({
          text: `Failed to start ${displayName} workstation, ${msg}`,
          type: 'error',
        })

        this.alertLogService.logActivity(label, msg, 'error')
        return
      }

      this.toastService.show({
        text: `Starting ${displayName} workstation.`,
        type: 'info',
      })

      this.launchRetryCount = 0
      updateNextState()
      this.onAction?.(this.station)
    })
  }

  updateStation() {
    Object.assign(this.station, {
      badges: this.wservice.getWorkstationCardBadges(this.station?.source),
      ...this.wservice.getWorkstationCardPowerCodeLabel(this.station?.source),
    })
    // this.station = {
    //   ...this.station,
    //   badges: this.wservice.getWorkstationCardBadges(this.station?.source),
    //   ...this.wservice.getWorkstationCardPowerCodeLabel(this.station?.source),
    // }
  }

  preLaunchSession(ev: Event) {
    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    let via = ''
    if (w?.PREFS?.CAN_JUMP) via = 'JUMP'
    else if (w?.PREFS?.PCOIP_SETTINGS?.ULTRA) via = 'ULTRA'

    if (w.POWER_STATUS_CODE == VmPowerStatus.STOPPED) {
      //Stopped so start VM
      this.startVM()
      return
    }

    if (w.POWER_STATUS_CODE == VmPowerStatus.RUNNING && w.READY_TO_LAUNCH) {
      //Running and Ready, do per launch session checks
      console.log('Workstation user id: ', w.USER_ID, this.userService.id)
      //Authorization check
      if (w.USER_ID && w.USER_ID._id !== this.userService.id) {
        this.toastService.show({
          text: `Sorry '${displayName}' workstation is not assigned to you.`,
          type: 'error',
        })

        return
      }

      if (w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'JUMP' || via == 'JUMP') {
        //Force a session launch via Jump
        this.launchVmSession(via, ev)
        return
      }

      if (w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'PARSEC' || via == 'PARSEC') {
        //Session Launch via Parsec
        this.launchVmSession(null, ev)
        return
      }

      let userName = w?.LOGGED_IN_USERNAME ?? ''

      if (!userName || userName == 'NONE' || this.userService.user?.username == userName) {
        this.launchVmSession(null, ev)
        return
      }

      //Some other user is logged in so don't launch session
      console.log('Logged in user ', userName)

      this.toastService.show({
        text: `${w.LOGGED_IN_USERNAME} is currently logged into the system.`,
        type: 'info',
      })

      return
    }

    if (w.POWER_STATUS_CODE == VmPowerStatus.RUNNING && !w.READY_TO_LAUNCH) {
      this.toastService.show({
        text: `${w.LOGGED_IN_USERNAME} is not ready to launch. Please wait…`,
        type: 'info',
      })
    }
  }

  setDefaultChannel(channel: string, ev?: Event) {
    if (this.environment.browser) return
    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    if (w.USER_ID && w.USER_ID._id !== this.userService.id) {
      this.toastService.show({
        text: `Sorry '${displayName}' workstation is not assigned to you.`,
        type: 'error',
      })

      return
    }

    if (!this.isReadyToLaunch && channel != 'Parsec') {
      this.toastService.show({
        text: `${w.LOGGED_IN_USERNAME || 'User'} is not ready to launch. Please wait…`,
        type: 'info',
      })
      return
    }

    if (['Jump', 'Parsec'].includes(channel)) {
      if (w.POWER_STATUS_CODE == VmPowerStatus.RUNNING && w.READY_TO_LAUNCH) {
        //Running and Ready, do per launch session checks
        console.log('Workstation user id: ', w.USER_ID, this.userService.id)
        //Authorization check
        if (w.USER_ID && w.USER_ID._id !== this.userService.id) {
          this.toastService.show({
            text: `Sorry '${displayName}' workstation is not assigned to you.`,
            type: 'error',
          })

          return
        }
        //Force a session launch via given channel
        if (channel == 'Jump') this.openJumpSession(ev)
        else if (channel == 'Parsec') this.openParsecSession(ev)
        return
      }

      this.toastService.show({
        text: `Sorry '${displayName}' workstation is not running.`,
        type: 'error',
      })
      return
    }

    if (channel != 'PCoIP') return

    w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL =
      w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL == 'PCOIP_SDK' ? 'PCOIP_EXT' : 'PCOIP_SDK'

    this.wservice.setDefaultChannel(w, w.SESSION_LAUNCH_CHANNEL.DEFAULT_CHANNEL).subscribe((res) => {
      if (res.error) {
        this.toastService.show({
          text: `Could not update default channel for '${w.LOGGED_IN_USERNAME || 'user'}'`,
          type: 'error',
        })
        return
      }

      this.toastService.show({
        text:
          w.SESSION_LAUNCH_CHANNEL?.DEFAULT_CHANNEL == 'PCOIP_SDK'
            ? 'You are now using the embedded PCoIP Client.'
            : 'You are now using the external PCoIP Client.',
        type: 'success',
      })
    })
  }

  _retryWorkstation(message: string = '') {
    if (this.retryInstance) {
      let log = [...this.retryInstance.retryFailedLog]
      if (log[0]) {
        log[0].retryEndTime = new Date()
        log[0].failedMessage = message
      }
      this.wservice.updateRetryWorkstation({
        ...this.retryInstance,
        retryFailedLog: log,
      })
      return
    }

    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    let ref = this.modalService.open<RetryWorkstationComponent, RetryWorkstationAction>(
      RetryWorkstationComponent,
      {
        data: {
          message,
        },
        hasBackdrop: true,
      },
      {}
    )

    ref.once().subscribe(({ name, payload }) => {
      if (name != 'Retry') return
      console.log('retry workstation', name, payload, displayName)
      this.wservice.addRetryWorkstation({
        durationInMinutes: payload.durationInMinutes,
        retryFailedLog: [
          {
            retryTime: new Date(),
            status: 'Init',
          },
          {
            failedMessage: message,
            retryEndTime: new Date(),
            retryTime: new Date(),
            status: 'Failed',
          },
        ],
        retryTill: payload.retryTill,
        workstation: w,
      })
    })

    return
  }

  async dismissRetryWorkstation() {
    if (!this.retryInstance) return

    let expired = this.retryInstance?.retryTill?.getTime() < Date.now()

    let dismissed =
      expired ||
      (await this.mainService.warningPrompt(null, {
        leftActionLabel: 'Cancel',
        message: '<br>Do you want to stop retry?',
        rightActionLabel: 'Dismiss',
        subMessage: `Configured to retry till ${this.util.getFormattedDateOnly(
          this.retryInstance?.retryTill
        )} ${this.util.getTimeString(this.retryInstance?.retryTill)}.`,
      }))

    if (!dismissed) return

    this.wservice.removeRetryWorkstation(this.retryInstance)
  }

  isRetryAllowed() {
    let entitlement = this.entitlements?.[this.organization?._id]
    return entitlement?.['WORKSTATION_LAUNCH_RETRY'] ?? false
  }

  get isTerminateAllowed() {
    let entitlement = this.entitlements?.[this.organization?._id]
    return entitlement?.['ADMIN_ORGANIZATION'] ?? false
  }

  showRetryWorkstationLog() {
    if (!this.retryInstance) return

    let w = this.station?.source
    let displayName = w?.DISPLAY_NAME || w?.NAME || ''

    let ref = this.modalService.open<RetryWorkstationLogComponent, RetryWorkstationLogAction>(
      RetryWorkstationLogComponent,
      {
        data: {
          instance: this.retryInstance,
        },
        hasBackdrop: true,
      },
      {}
    )

    ref.once().subscribe(({ name, payload }) => {
      console.log('retry workstation log', name, payload, displayName)
    })
  }

  setFavorite(fav: boolean) {
    let ofav = this.station.favorite
    if (ofav == fav) return

    this.wservice
      .toggleUserFavorite(this.station?.source, fav)
      .subscribe((e: StandardDataResponse<UserWorkstation>) => {
        if (e.error || !e.data) {
          this.station.favorite = ofav
          this.toastService.show({
            text: fav ? 'Failed to add to favorite' : 'Failed to remove from favorite',
            type: 'error',
          })
          return
        }

        this.station.favorite = fav
        this.onAction?.(this.station)
        this.toastService.show({
          text: fav ? 'Added to favorite' : 'Removed from favorite',
          type: 'success',
        })
      })
  }

  addToFavorites() {
    this.setFavorite(true)
  }

  removeFromFavorites() {
    this.setFavorite(false)
  }

  editWorkstationNickname(ev: Event) {
    let ref = this.modalService.open<EditWorkstationNicknameComponent, EditWorkstationNicknameAction>(
      EditWorkstationNicknameComponent,
      {
        animateFrom: ev.target as Element,
        data: {
          workstation: this.station,
        },
        hasBackdrop: true,
      },
      {}
    )

    let subs$ = ref.once().subscribe(({ name, payload }) => {
      if (name == 'Save') {
        this.station.nickname = payload?.nickname ?? ''
        this.onAction?.(this.station)
      }
    })
  }

  onClickOutside() {
    this.showMoreActionDropdown = false
  }

  onClickMoreActionDropdown() {
    this.showMoreActionDropdown = !this.showMoreActionDropdown
  }

  getTimeTickerValue() {
    if (!this.retryInstance) return '00:00:00'
    let date = new Date()
    let till = this.retryInstance.retryTill

    let diffTs = till?.getTime() - date?.getTime()

    if (diffTs <= 0) return '00:00:00'

    return this.util.getTimeStringWithoutOffset(diffTs)
  }

  private _tickerTimer = -1
  updateTickerTimer() {
    if (this._tickerTimer != -1) return

    this._retryTimeLeft = this.getTimeTickerValue()
    this._tickerTimer = window.setInterval(() => {
      this._retryTimeLeft = this.getTimeTickerValue()
      if (!this.retryInstance) {
        this.clearTimeTicker()
      }
    }, 1000)
  }

  clearTimeTicker() {
    if (this._tickerTimer == -1) return
    window.clearInterval(this._tickerTimer)
    this._tickerTimer = -1
  }

  private _retryTimeLeft = '00:00:00'
  get retryTimeLeft() {
    return this._retryTimeLeft ?? '00:00:00'
  }
}
