import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'

@Directive({
  selector: '[bebopAutoFocus]',
})
export class AutoFocusDirective implements OnChanges {
  @Input('bebopAutoFocus') focus?: boolean

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['focus']?.currentValue) {
      setTimeout(() => {
        this.el.nativeElement.focus()
      }, 300)
    }
  }

  ngAfterContentInit() {
    if (!this.focus) return
    setTimeout(() => this.el.nativeElement.focus(), 300)
  }
}
