import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { EFormat } from './types'

@Component({
  selector: 'cree8-card-plan',
  styleUrls: ['./cree8-card-plan.component.scss'],
  templateUrl: './cree8-card-plan.component.html',
})
export class Cree8CardPlanComponent implements OnInit {
  constructor() {}

  @Input('title') title!: string
  @Input('button') button?: string
  @Input('subtitle') subtitle?: string
  @Input('description') description!: string

  //used to determine the format of the card
  @Input('format') format: EFormat = EFormat.DEFAULT

  @Input('activated') activated: boolean = true
  @Input('activatedText') activatedText?: string = 'Not available in the Free Account'

  @Output() onActionClick = new EventEmitter<void>()

  ngOnInit(): void {}

  onAction(): void {
    this.onActionClick.emit()
  }

  get cardClass(): string {
    let classes = 'cree8-card-plan'

    if (this.activated) {
      classes += ' activated'
    } else {
      classes += ' deactivated'
    }

    switch (this.format) {
      case EFormat.DEFAULT:
        classes += ' format-default'
        break
      case EFormat.SETUP:
        classes += ' format-setup'
        break
      default:
        classes += ' format-default'
        break
    }

    return classes
  }
}
