<ng-container *ngFor="let item of toastList; let i = index">
  <div
    class="toast-container"
    [@fade]="'enter'"
    [ngClass]="generateClass(item.type)"
  >
    <span class="text">{{ item.message }}</span>
    <span class="close" (click)="onClose(i)">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 8L16 16"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 8L8 16"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</ng-container>
