import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { OnExternalModalClose, BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'
import { InputItemsSelect, InputOptionItem } from 'src/app/common/types'
import {
  FilterWorkstationActionPayload,
  WorkstationLaunchState,
  WorkstationLaunchStates,
  WorkstationWidgetHandle,
} from '../../classes/workstation-types'
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations'

const AllUser = 'All Users'
export interface FilterWorkstationAction {
  name: 'Cancel' | 'Apply' | 'Clear'
  payload?: FilterWorkstationActionPayload
}
@Component({
  selector: 'bebop-filter-workstation',
  templateUrl: './filter-workstation.component.html',
  styleUrls: ['./filter-workstation.component.scss'],
  animations: [
    trigger('slideAnim', [
      transition(':enter', [
        style({ position: 'relative', right: '-322px' }),
        animate('300ms ease-in', style({ right: '0px' })),
      ]),
      transition(':leave', [
        style({ position: 'relative', right: '0px' }),
        animate('300ms ease-out', style({ right: '-322px' })),
      ]),
    ]),
  ],
})
export class FilterWorkstationComponent implements OnInit, OnExternalModalClose {
  stateSelect: InputItemsSelect = {
    value: 'All',
    items: [<InputOptionItem>{ value: 'All' }].concat(
      WorkstationLaunchStates.map((s) => <InputOptionItem>{ value: <string>s })
    ),
  }

  get hasFiltered() {
    return this.stateSelect.value != 'All' || this.userSelect.value != AllUser
  }

  users: string[] = []

  userSelect: InputItemsSelect = {
    value: AllUser,
    items: [<InputOptionItem>{ value: AllUser }],
  }

  guessCount = 0

  parent: WorkstationWidgetHandle

  constructor(
    public ref: ModalOverlayRef<FilterWorkstationComponent, FilterWorkstationAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.init()
    if (this.hasFiltered) {
      this.guessFilter()
    }
  }

  init() {
    this.parent = this.data.parentWidget
    if (this.data.users) {
      this.users = [...new Set<string>(this.data.users)]
      this.userSelect.items = [<InputOptionItem>{ value: AllUser }].concat(
        this.users?.map((u) => <InputOptionItem>{ value: <string>u })
      )
    }

    if (this.data.filterApply) {
      this.stateSelect.value = <string>this.data.filterApply.state
      this.userSelect.value = <string>this.data.filterApply.users?.[0] ?? AllUser
    }
  }

  cancel() {
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  applyAll() {
    this.ref.send({
      name: 'Apply',
      payload: {
        state: <WorkstationLaunchState>this.stateSelect.value,
        users: this.userSelect.value != AllUser ? [this.userSelect.value] : [],
      },
    })
    this.ref.close()
  }

  clearAll() {
    this.ref.send({ name: 'Clear' })
    this.ref.close()
  }

  onSelectState(item: InputOptionItem) {
    // console.log(item)
    this.stateSelect.value = item.value
    this.guessFilter()
  }

  onSelectUser(item: InputOptionItem) {
    // console.log(item)
    this.userSelect.value = item.value
    this.guessFilter()
  }

  syncUsers() {
    let users = this.parent?.getUsers() ?? []
    this.users = [...new Set<string>(users)]
    this.userSelect.items = [<InputOptionItem>{ value: AllUser }].concat(
      this.users?.map((u) => <InputOptionItem>{ value: <string>u })
    )

    if (this.userSelect.value != AllUser) {
      if (!this.users.find((u) => u == this.userSelect.value)) {
        this.userSelect.value = AllUser
      }
    }
  }

  guessFilter() {
    this.guessCount = this.parent?.guessFilter({
      state: <WorkstationLaunchState>this.stateSelect.value,
      users: this.userSelect.value != AllUser ? [this.userSelect.value] : [],
    })

    this.syncUsers()
  }

  _closed = false
  async beforeClose() {
    this._closed = true
    await this.ref.sleep(300)
  }
}
