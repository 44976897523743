import { Component, Input, OnInit } from '@angular/core'
import { Cree8ToastService } from '../cree8-toast/cree8-toast.service'

@Component({
  selector: 'copy-input',
  templateUrl: './copy-input.component.html',
  styleUrls: ['./copy-input.component.scss'],
})
export class CopyInputComponent implements OnInit {
  @Input() label: string = ''
  @Input() messageCopy = ''

  constructor(private cree8Toast: Cree8ToastService) {}

  ngOnInit(): void {}

  onCopy() {
    this.cree8Toast.show({
      status: true,
      message: this.messageCopy,
      type: 'success',
    })
    navigator.clipboard.writeText(this.label)
  }
}
