import { Injectable } from '@angular/core'
import { BehaviorSubject, take } from 'rxjs'
import { ICree8Toast } from './cree8-toast.types'

@Injectable({
  providedIn: 'root',
})
export class Cree8ToastService {
  private toastList: BehaviorSubject<ICree8Toast[]> = new BehaviorSubject<
    ICree8Toast[]
  >([])

  constructor() {}

  show(value: ICree8Toast) {
    this.toastList.pipe(take(1)).subscribe({
      next: (res) => {
        const list: ICree8Toast[] = [...res, value]
        this.toastList.next(list)
        const timeout = value?.timeout || 3000

        setTimeout(() => {
          this.closeByValue(value)
        }, timeout)
      },
    })
  }

  close(index: number) {
    this.toastList.pipe(take(1)).subscribe({
      next: (res) => {
        if (index >= 0 && index < res.length) {
          const updatedList = res.filter((_, i) => i !== index)
          this.toastList.next(updatedList)
        }
      },
    })
  }

  getList() {
    return this.toastList.asObservable()
  }

  closeByValue(value: ICree8Toast) {
    this.toastList.pipe(take(1)).subscribe({
      next: (res) => {
        const updatedList = res.filter((toast) => toast !== value)
        this.toastList.next(updatedList)
      },
    })
  }
}
