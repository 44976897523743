<div class="icon-indicator-container">
  <div class="icon-indicator-icon">
    <ng-content select="svg"></ng-content>
    <ng-content select=".svg"></ng-content>
  </div>
  <div
    class="icon-indicator-dropdown"
    [class.left]="position === 'left'"
    [class.right]="position === 'right'"
    [class.center]="position === 'center'"
  >
    <ng-content select=".content"></ng-content>
  </div>
</div>
