import { Component, Input, OnInit } from '@angular/core'
@Component({
  selector: 'card-dashboard',
  styleUrls: ['./card.component.scss'],
  templateUrl: './card.component.html',
})
export class CardComponent implements OnInit {
  @Input() title: string
  @Input() subtitle: string
  @Input() description: string
  @Input() available: boolean
  @Input() icon: string
  @Input() buttonText?: string
  @Input() onButtonClick!: () => void

  constructor() {}

  ngOnInit(): void {}
}
