import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { toastAnimations, ToastAnimationState } from './toast-animations'
import { ToastConfig, ToastData, ToastRef, TOAST_CONFIG_TOKEN } from './toast.types'
@Component({
  selector: 'bebop-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  // animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  // animationState: ToastAnimationState = 'default'

  private intervalId: number

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {}

  ngOnInit() {
    let err = ''
    if (!this.data?.text?.length) {
      err = 'Empty toast request'
    } else if (!this.data?.type) {
      err = 'Toast without type'
    }

    if (err) {
      console.error(err, this.data)
      this.close()
      return
    }

    // this.intervalId = window.setTimeout(() => (this.animationState = 'closing'), 5000)
    this.intervalId = window.setTimeout(() => this.close(), this.toastConfig.timeout || 5000)
    let maxLen = this.toastConfig.maxCharacters || 350
    this.data.text = this.data.text.length > maxLen ? this.data.text.slice(0, maxLen - 1) + '…' : this.data.text
  }

  ngOnDestroy() {
    window.clearTimeout(this.intervalId)
  }

  close() {
    this.ref.close()
  }

  // onFadeFinished(event: any) {
  //   const { toState } = event
  //   const isFadeOut = (toState as ToastAnimationState) === 'closing'
  //   const itFinished = this.animationState === 'closing'

  //   if (isFadeOut && itFinished) {
  //     this.close()
  //   }
  // }
}
