import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

import { ITeamQueryParams } from 'src/app/models/team.model'

export type TAction = 'refresh' | 'search' | 'sort' | 'init'
export interface ITeamState {
  activeTab: string
  action: TAction
  queryParams: ITeamQueryParams
  cleared: boolean
}

export function createInitialState(): ITeamState {
  return {
    action: 'init',
    activeTab: 'members',
    cleared: false,
    queryParams: {
      page: 1,
      searchText: '',
      size: 25,
      sort: '-date_created',
    },
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'team-store', resettable: true })
export class TeamStore extends Store<ITeamState> {
  constructor() {
    super(createInitialState())
  }
}
