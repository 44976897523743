import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BebopLink, ISettingsLinks } from 'src/app/models/bebop.model'
import { LinkService } from 'src/app/store/rocket/link/link.service'

export type SettingsStatus = 'success' | 'loading' | 'error'
@Component({
  selector: 'link-settings',
  styleUrl: './link-settings.component.scss',
  templateUrl: './link-settings.component.html',
})
export class LinkSettingsComponent implements OnInit {
  @Input() link: BebopLink
  @Output() statusUpdateSettings: EventEmitter<SettingsStatus> = new EventEmitter<SettingsStatus>()
  name: string
  extensions: string[] = []
  maxSize: number
  maxFiles: number
  notes: string
  path: string

  constructor(
    private linkService: LinkService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.name = this.link.name
    this.extensions = this.link.settings.uploads.allowedFileTypes
    this.maxSize = this.link.settings.uploads.maxSize
    this.maxFiles = this.link.settings.uploads.maxFiles
    this.notes = this.link.settings.notes
    this.path = this.link.settings.uploads.dropPath
  }

  validateExtension(s: string, arr: string[]): string | boolean {
    if (!s) return false

    if (arr?.length && arr.includes(s)) return 'Duplicate extension'

    return true
  }

  onUpdateSettings() {
    this.statusUpdateSettings.emit('loading')
    const data: ISettingsLinks = {
      ...this.link.settings,
      allowedIPs: this.link.settings.allowedIPs,
      disallowedIPs: this.link.settings.disallowedIPs,
      downloads: {
        ...this.link.settings.downloads,
        files: this.link.settings.downloads.files,
      },
      flexLink: {
        files: [],
      },
      notes: this.notes,
      prdLibNotes: { files: [] },
      qikNotes: { files: [] },
      review: {
        ...this.link.settings.review,
        allowDownload: this.link.settings.review.allowDownload ?? false,
        files: this.link.settings.review.files, // Set default value
      },
      uploads: {
        ...this.link.settings.uploads,
        allowedFileTypes: this.extensions,
        allowUnsafeFiles: this.link.settings.uploads.allowUnsafeFiles,
        dropPath: this.link.settings.uploads.dropPath,
        maxFiles: this.maxFiles,
        maxSize: this.maxSize,
        maxSizeInGb: this.maxSize / (1024 * 1024 * 1024),
      },
    }

    this.linkService.updateLinkSettings(this.link, data).subscribe((res) => {
      if (res.error) {
        this.statusUpdateSettings.emit('error')
        this.toastService.show({
          text: 'CREE8 Link update failed',
          type: 'error',
        })
      }

      this.statusUpdateSettings.emit('success')
      this.toastService.show({
        text: 'CREE8 Link has been updated',
        type: 'success',
      })

      return
    })
  }
}
