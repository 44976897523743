export const UploadConstants = {
  MAX_METRICS_DELAY_INTERVAL: 10,
  CHUNK_SIZE: 1024 * 1024 * 10,
  MAX_CHUNK_SIZE: 1024 * 1024 * 10,
  MAX_CONCURRENT_REQUEST: 12,
  // 2 files per tx server
  MAX_FILES_PER_TX: 2,
  // 10 files per tx server for flex projects
  MAX_FILES_PER_TX_BEBOP_FLEX: 10,

  MAX_VIEW: Number.MAX_SAFE_INTEGER, //250, // strict pagination
  MAX_FOLDER_NAME_LENGTH: 50,
  HAS_ACTIVE_EPLISON_TIME: 5 * 1000,
  COMPLETED_LIST_SIZE: 25,
  MAX_NAME_DISPLAY_LENGTH: 150,
  COMPLETED_DEBOUNCE_DELAY: 2000,
}

export const DownloadConstants = {
  ROCKET_PARALLEL_DOWNLOADS: 4,
  ROCKET_PARALLEL_VERIFICATIONS: 2,
  MAX_NAME_DISPLAY_LENGTH: 150, // 45 in old client - update as needed
  MAX_METRICS_DELAY_INTERVAL: 10, // in sec

  MAX_PARALLEL_DOWNLOAD: 40,
  MAX_PARALLEL_VERIFICATION: 40,
  MIN_PARALLEL_DOWNLOAD: 2,
  MIN_PARALLEL_VERIFICATION: 2,
  DEFAULT_PARALLEL_DOWNLOAD: 4,
  DEFAULT_PARALLEL_VERIFICATION: 4,
  MAX_LIST_DIRECTORY_RETRY: 5,
}
