<div class="cree8-live-indicator-container">
  <div class="circle">
    <div class="circle-inner">
      @if (type === 'red') {
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <circle cx="9" cy="9" r="8" stroke="#FF2121" />
        <circle cx="9" cy="9" r="6" fill="#FF2121" />
      </svg>
      }@else if (type === 'green') {

      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <circle cx="9" cy="9" r="8" stroke="#1CB22A" />
        <circle cx="9" cy="9" r="6" fill="#1CB22A" />
      </svg>
      } @else {
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <circle cx="9" cy="9" r="8" stroke="#6C757D" />
        <circle cx="9" cy="9" r="6" fill="#6C757D" />
      </svg>
      }
    </div>
  </div>
  <div class="text" *ngIf="showLabel">{{ label }}</div>
</div>
