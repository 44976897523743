<div class="card" [ngClass]="{'setup': !available }" (click)="onButtonClick()">
  <div class="content">
    <span [ngClass]="available ? 'headline1' : 'body3'">{{title}}</span>
    <span [ngClass]="available ? 'body2' : 'headline1'">{{subtitle}}</span>
    <span [ngClass]="available ? 'body3' : 'hide'">{{description}}</span>
    <div *ngIf="!available && buttonText">
      <cree8-btn (clicked)="onButtonClick()" appearance="text">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Plus"></use>
        </svg>
        {{buttonText}}
      </cree8-btn>
    </div>
  </div>
  <div class="card-icon" [innerHTML]="icon"></div>
</div>
