import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'

import { DropdownPanel } from '../../types'

@Component({
  selector: 'cree8-dropdown',
  styleUrl: './cree8-dropdown.component.scss',
  templateUrl: './cree8-dropdown.component.html',
})
export class Cree8DropdownComponent implements DropdownPanel, OnInit {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>
  @Output() closed = new EventEmitter<void>()

  @Input() panelClasses: string[]

  @ViewChild('ddContent') ddContent

  get isEmpty() {
    return this.ddContent?.nativeElement?.childElementCount == 0 || true
  }

  cssClasses: { [key: string]: boolean } = {}

  constructor() {}

  ngOnInit(): void {
    ;(this.panelClasses || []).reduce((prev, curr) => {
      prev[curr] = true
      return prev
    }, this.cssClasses)
  }
}
