import { Component, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

import { forkJoin, Subject, takeUntil } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { MainService } from 'src/app/services/main.service'
import { UserService } from 'src/app/services/user.service'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { isErrorResponse } from 'src/app/utils/response-utils'

@Component({
  selector: 'cree8-profile',
  styleUrl: './profile.component.scss',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit, OnDestroy {
  _destroy$ = new Subject()
  user: any
  currentUser: any
  isSelf: boolean = true
  profileForm: FormGroup
  changePassForm: FormGroup
  showForm: boolean = true
  avatars: string[] = []
  lastSeen = ''
  onlineStatus = 'offline'

  passValidations: any = {
    capMessage: 'Password must contain a capital letter.',
    confirmedMessage: 'Passwords match.',
    hasLetters: false,
    hasNumber: false,
    hasSpecialChars: false,
    isCap: false,
    isConfirmed: false,
    isLen: false,
    lenMessage: 'Password must be greater than 6 characters.',
    lettersMessage: 'Password must contain letters.',
    numberMessage: 'Password must contain a number.',
    sameMessage: 'New password is different from current password',
    specialCharsMessage: 'Password can only contain one of these characters: !@#%^*_+-',
  }
  passValidationsConfirm: any = {
    confirmedMessage: 'Passwords not match.',
    isConfirmed: false,
  }

  constructor(
    private route: ActivatedRoute,
    private uiQuery: UIQuery,
    private userService: UserService,
    private mainService: MainService,
    private toastService: ToastService,
    private fb: FormBuilder
  ) {
    this.changePassForm = this.fb.group({
      confirmPassword: [
        '',
        [
          Validators.required,
          this.confirmPasswordValidator(this.changePassForm?.get('password')?.value, this.passValidationsConfirm),
        ],
      ],
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, this.passwordValidator(this.passValidations)]],
    })

    // Reapply confirm password validation on password field change
    this.changePassForm.get('newPassword')?.valueChanges.subscribe((password) => {
      this.changePassForm
        .get('confirmPassword')
        ?.setValidators(this.confirmPasswordValidator(password, this.passValidationsConfirm))
      this.changePassForm.get('confirmPassword')?.updateValueAndValidity()
    })
  }

  ngOnInit() {
    // Listen to route changes
    this.route.params.subscribe((params) => {
      this.loadMemberData(params['id'])
    })
  }

  loadMemberData(id: string) {
    this.avatars = Array.from({ length: 92 }).map((_, i) => {
      return `assets/img/avatars/2023/Avatar-${i + 1}.png`
    })
    // Fetch the current user
    this.uiQuery
      .getCurrentUser()
      .pipe(takeUntil(this._destroy$))
      .subscribe((currentUser) => {
        this.currentUser = currentUser

        // Fetch the selected organization after we have the currentUser
        this.uiQuery
          .getSelectedOrg()
          .pipe(takeUntil(this._destroy$))
          .subscribe((org) => {
            // Ensure org exists
            if (!org) {
              this.resetForm()
              return
            }

            // Set isSelf based on the user ID comparison
            this.isSelf = id === this.currentUser._id

            // Fetch the selected user's data
            this.userService
              .getUserById(id, { pageUserOrg: 1, sizeUserOrg: 100 })
              .pipe(takeUntil(this._destroy$))
              .subscribe((response) => {
                if (isErrorResponse(response)) {
                  this.resetForm()
                  this.toastService.show({
                    text: response.error.msg,
                    type: 'error',
                  })
                } else {
                  this.userService
                    .getOnlineStatus(id)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe((response) => {
                      if (isErrorResponse(response)) {
                      } else {
                        this.onlineStatus = response.status
                        this.lastSeen = response.lastSeen
                        if (response.secs < 365 * 24 * 60 * 60) {
                          this.lastSeen = response.lastSeen + ' ago'
                        } else {
                          this.lastSeen = ''
                        }
                      }
                    })
                  this.showForm = true
                  this.user = response.data
                  this.setupProfileForm(this.user)
                }
              })
          })
      })
  }

  resetForm() {
    this.profileForm?.reset() // Reset form values
    this.changePassForm?.reset() // Reset password form
    this.showForm = false // Hide the form
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }

  updateProfile() {
    const { email, firstname, lastname, picture } = this.profileForm.value
    this.userService
      .updateProfile(this.user._id, {
        email: email,
        firstname: firstname,
        lastname: lastname,
        picture: picture,
      })
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        if (isErrorResponse(response)) {
          this.toastService.show({
            text: response.error.msg,
            type: 'error',
          })
        } else {
          this.toastService.show({
            text: 'Profile updated',
            type: 'success',
          })
          this.user = response.user
          this.setupProfileForm(this.user)
        }
      })
  }

  // Called on form submission
  onChangePassword(): void {
    if (this.changePassForm.valid) {
      const { confirmPassword, currentPassword, newPassword } = this.changePassForm.value
      this.userService
        .updatePassword(this.user._id, {
          confirmPassword: confirmPassword,
          currentPassword: currentPassword,
          password: newPassword,
        })
        .pipe(takeUntil(this._destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.toastService.show({
              text: 'Password updated',
              type: 'success',
            })
            this.user = response.user
            this.setupProfileForm(this.user)
            this.changePassForm?.reset() // Reset password form
          }
        })
    } else {
      this.toastService.show({
        text: 'Invalid password submit',
        type: 'error',
      })
    }
  }

  setupProfileForm(user: any) {
    // Initialize or update the profile form with user data
    this.profileForm = this.fb.group({
      email: [user.email],
      firstname: [user.firstname, [Validators.required]],
      lastname: [user.lastname, [Validators.required]],
      picture: [user.picture],
      username: [user.username],
    })
  }

  // Convenient getter for form controls
  get f() {
    return this.changePassForm.controls
  }

  passwordValidator(passValidations: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value || ''
      // Password validation criteria
      passValidations.isLen = value.length > 6
      passValidations.isCap = /[A-Z]/.test(value)
      passValidations.hasLetters = /[a-z]/.test(value)
      passValidations.hasNumber = /\d/.test(value)
      passValidations.hasSpecialChars = /[!@#%^*_+-]/.test(value)

      return passValidations.isLen &&
        passValidations.isCap &&
        passValidations.hasLetters &&
        passValidations.hasNumber &&
        passValidations.hasSpecialChars
        ? null
        : { passwordInvalid: true }
    }
  }

  confirmPasswordValidator(password: string, passValidationsConfirm: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const confirmPass = control.value || ''
      passValidationsConfirm.isConfirmed = password === confirmPass
      return passValidationsConfirm.isConfirmed ? null : { passwordsDoNotMatch: true }
    }
  }

  onAvatarSelected(avatar: string): void {
    this.profileForm.get('picture').setValue(avatar)
  }
}
