import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate,
  keyframes,
  state,
  animation,
} from '@angular/animations'
let optional = { optional: true }

export const slideLeftIn = animation([
  style({ position: 'absolute', left: '100%' }),
  animate('{{time}} ease-out', style({ left: '0%' })),
])

export const slideLeftOut = animation([
  style({ position: 'absolute', left: '0%' }),
  animate('{{time}} ease-out', style({ left: '-100%' })),
])

export const slideRightIn = animation([
  style({ position: 'absolute', left: '-100%' }),
  animate('{{time}} ease-out', style({ left: '0%' })),
])

export const slideRightOut = animation([
  style({ position: 'absolute', left: '0%' }),
  animate('{{time}} ease-out', style({ left: '100%' })),
])
