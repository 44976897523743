import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

import { BebopLink } from 'src/app/models/bebop.model'
import { ILinkQueryParams, ILinksDetail } from 'src/app/models/projects-links.model'

export interface ProjectsLinksState {
  listProjectsLinks: BebopLink[]
  queryParamsList: ILinkQueryParams
  loading: boolean
}

export function createInitialState(): ProjectsLinksState {
  return {
    listProjectsLinks: [],
    loading: true,
    queryParamsList: {
      deactivated: false,
      expired: false,
      linkType: 8,
      linkTypeStrict: false,
      page: 1,
      size: 25,
      sortBy: 'date_created',
      sortOrder: -1,
    },
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'project-links-store' })
export class ProjectsLinksStore extends Store<ProjectsLinksState> {
  constructor() {
    super(createInitialState())
  }
}
