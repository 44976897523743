import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { BEBOP_MODAL_DATA, OnExternalModalClose } from 'src/app/common/services/component-modal.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { WorkstationQuery } from 'src/app/store/workstation/workstation.query'
import { WorkstationService } from 'src/app/store/workstation/workstation.service'
import {
  RetryWorkstation,
  RetryWorkstationLog,
  RetryWorkstationState,
} from 'src/app/store/workstation/workstation.store'

export interface RetryWorkstationLogAction {
  name: 'Dismiss' | 'Retry' | 'Cancel'
  payload?: {
    retryTill: Date
    durationInMinutes: number
    instance: RetryWorkstation
  }
}

export interface RetryWorkstationLogEntry {
  logDate: string
  logTime: string
  log: RetryWorkstationLog
  error?: boolean
  status: RetryWorkstationState
  errorMessage?: string
  incomplete: boolean
}

@Component({
  selector: 'bebop-retry-workstation-log',
  templateUrl: './retry-workstation-log.component.html',
  styleUrls: ['./retry-workstation-log.component.scss'],
})
export class RetryWorkstationLogComponent implements OnInit, OnExternalModalClose, OnDestroy {
  retryIntervals = [5, 10, 15, 30].map((v) => ({
    name: `${v} minutes`,
    value: v,
  }))

  retryInterval = this.retryIntervals[1]

  instance: RetryWorkstation

  retryWorkstations: RetryWorkstation[]
  retryWorkstation$: Subscription

  logRetryEntries: RetryWorkstationLogEntry[] = []

  addingTime = false

  constructor(
    public ref: ModalOverlayRef<RetryWorkstationLogComponent, RetryWorkstationLogAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any,
    private workstationQuery: WorkstationQuery,
    private util: BebopClientUtilsService,
    private wservice: WorkstationService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.instance = this.data.instance

    if (!this.instance) {
      console.error('Retry instance is not set')
      this.cancel()
      return
    }

    this.instance = { ...this.instance }

    this.updateRetryLogs()

    this.retryWorkstation$ = this.workstationQuery.getRetryWorkstations().subscribe((rws) => {
      this.retryWorkstations = rws ?? []
      let instance = this.retryWorkstations.find((w) => w?.workstation?._id == this.instance?.workstation?._id)
      if (!instance) {
        console.log('Retry workstation action is no longer tracked.', this.instance)

        // this.toastService.show({
        //   type: 'info',
        //   text: `Auto retry for ${instance?.workstation?.DISPLAY_NAME || instance?.workstation?.NAME || 'Workstation'} is cleared`
        // })
        this.cancel()
        return
      }
      this.instance = instance
      this.updateRetryLogs()
    })
  }

  ngOnDestroy(): void {
    ;[this.retryWorkstation$].forEach((e) => e?.unsubscribe())
    this.clearTimeTicker()
  }

  updateRetryInterval(interval: { name: string; value: number }) {
    this.retryInterval = interval
  }

  cancel() {
    this.cancelTime()
    this.ref.send({ name: 'Cancel' })
    this.ref.close()
  }

  addTime() {
    this.addingTime = true
  }

  saveTime() {
    this.addingTime = false
    let mins = this.retryInterval?.value ?? 0
    let durationInMinutes = this.instance.durationInMinutes + mins
    let retryTill = new Date((this.instance.retryTill?.getTime?.() ?? 0) + mins * 60 * 1000)
    this.wservice.updateRetryWorkstation({
      ...this.instance,
      durationInMinutes,
      retryTill,
    })
  }

  cancelTime() {
    this.addingTime = false
  }

  dismiss() {
    if (this.addingTime) this.cancelTime()

    this.wservice.removeRetryWorkstation(this.instance)

    this.ref.send({
      name: 'Dismiss',
    })
    this.ref.close()
  }

  keepRetrying() {
    if (this.addingTime) this.cancelTime()
    // new Date(Date.now() + 1000 * 60 * (this.retryInterval.value ?? 0))
    this.ref.send({
      name: 'Retry',
      payload: {
        retryTill: this.instance.retryTill,
        durationInMinutes: this.instance.durationInMinutes,
        instance: this.instance,
      },
    })
    this.ref.close()
  }

  updateRetryLogs() {
    let logs = this.instance?.retryFailedLog ?? []
    let entries: RetryWorkstationLogEntry[] = logs.map((l) => {
      let { retryEndTime, retryTime, status, failedMessage } = l

      return {
        logDate: this.util.getFormattedDateOnly(retryEndTime || retryTime),
        logTime: this.util.getTimeStringWithoutOffset(retryEndTime || retryTime),
        log: l,
        status: status,
        error: status == 'Failed',
        errorMessage: failedMessage ?? '',
        incomplete: !retryEndTime,
      }
    })

    this.logRetryEntries = entries
    this.updateTickerTimer()
  }

  getTickingLogTime() {
    if (!this.instance) return '00:00:00'
    let date = new Date()
    let till = this.instance.retryTill

    let diffTs = till?.getTime() - date?.getTime()

    if (diffTs <= 0) return '00:00:00'

    return this.util.getTimeStringWithoutOffset(diffTs)
  }

  private _tickerTimer = -1
  updateTickerTimer() {
    if (this._tickerTimer != -1) return

    this._tickerTimer = window.setInterval(() => {
      this._retryTimeLeft = this.getTickingLogTime()
      this._progress = this.getProgress()
      if (!this.instance) {
        window.clearInterval(this._tickerTimer)
        this._tickerTimer = -1
      }
    }, 1000)
  }

  clearTimeTicker() {
    if (this._tickerTimer == -1) return
    window.clearInterval(this._tickerTimer)
  }

  private _retryTimeLeft = '00:00:00'
  get timeLabel() {
    return this._retryTimeLeft
  }

  private _progress = 0
  get progress() {
    return this._progress ?? 0
  }

  getProgress() {
    if (!this.instance) return 0

    let date = new Date()
    let till = this.instance.retryTill

    let diffTs = till?.getTime() - date?.getTime()

    if (diffTs <= 0) return 100

    return 100 - Math.ceil(diffTs / (this.instance?.durationInMinutes * 600))
  }
}
