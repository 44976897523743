import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'title-desc',
  templateUrl: './title-desc.component.html',
  styleUrls: ['./title-desc.component.scss']
})
export class TitleDescComponent implements OnInit {
  @Input() title = '';
  @Input('icon') icon = ''
  @Input('sizeDescription') sizeDescription: 'small' | 'medium' = 'small'
  @Input('sizeTitle') sizeTitle: 'small' | 'upSmall' | 'medium' = 'medium'
  @Input('margin') margin: 'no-margin' | 'default' = 'default';

  constructor() { }

  ngOnInit(): void {
  }

  get generateDescriptionClass(){
    return `${this.sizeDescription} ${this.margin}`;
  }


  get generateSizeClass(){
    return this.sizeTitle;
  }

}
