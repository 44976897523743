import { Component, Input, OnInit } from '@angular/core'

export type ProgressBar3LevelType = 'lt50' | 'gt50' | 'gt95'

//                            Uploading | Checking  | Paused   | Pending  |  Error  |  Stopped
export type ProgressBarType = 'active' | 'checking' | 'paused' | 'queued' | 'error' | 'stopped' | 'download' | 'hotfolder' | ProgressBar3LevelType


/**

  <div class="progress-test" [style]="{'width': '400px', 'height': '10px'}">
    <bebop-progress-bar [progress]="progress" [type]="'checking'"></bebop-progress-bar>
  </div>

  <bebop-progress-bar [progress]="progress" [type]="'checking'" [style]="{'width': '400px', 'height': '10px'}"></bebop-progress-bar>  

*/

@Component({
  selector: 'bebop-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input('progress') progress: number
  @Input('total') total: number

  @Input('type') type: ProgressBarType

  get _progress() {
    return (this.progress / this.total) * 100
  }

  constructor() {}

  ngOnInit(): void {
    if (!this.type) {
      console.error('<bebop-progress-bar> type attribute is not set')
      this.type = 'stopped'
    }

    if (!this.progress || this.progress < 0) {
      this.progress = 0
    }

    if (this.total === 0) {
      this.total = this.progress
    } else if (!this.total) {
      this.total = 100
    }
    if (this.progress > this.total) {
      this.progress = 100
    }
  }
}
