import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, of } from 'rxjs'
import { N } from 'src/app/common/classes/lib/tw-elements/util/keycodes'
import { Slide } from 'src/app/common/components/image-carousel/image-carousel.component'
import { KeysOfValueType } from 'src/app/common/types'
import { UiBebopLink, UiFileType } from 'src/app/components/rocket/common/classes/rocket-types'
import { REFRESH_LUCID_CACHE } from 'src/app/components/workstation/classes/workstation-types'
import { BebopLink, LucidCacheRootFolder, LucidKey, LucidRootPath, ObjectId } from 'src/app/models/bebop.model'
import {
  FlexMountResponse,
  GenericResponse,
  LucidFsFilesResponse,
  MountActivityResponse,
  StandardResponse,
} from 'src/app/models/response.model'
import { FlexPinStatus, UiBroadcast, UiProject } from 'src/app/models/ui.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'
import { ExecutableService } from 'src/app/services/executable.service'
import { FlexPerformanceMonitoringService } from 'src/app/services/flex-performance-monitoring.service'
import { MountOption } from './flex.select.store'
import { FlexState, FlexStore } from './flex.store'
import { ProgressBar3LevelType } from 'src/app/common/components/progress-bar/progress-bar.component'
import { UserService } from 'src/app/services/user.service'

const LUCID_ENDPOINT_BASE_PORT = 7778

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
}

export interface MediaInfoDetails {
  slides?: Slide[]
  mediainfo?: any
  media?: {
    isPreviewCollapsed?: boolean
    isInfoCollapsed?: boolean
    isTracksCollapsed?: boolean
    thumbnail?: string
    thumbnails?: any
    name?: string
    info: {
      [key: string]: any[]
      tracks: any[]
    }
    thumbsCount_?: number
    thumbsCount?: number
  }
}
export interface HubFlexFile extends MediaInfoDetails {
  displayName: string
  path: string
  selected: boolean
  type: UiFileType
  searchStr?: string
  file?: string
  dir?: boolean
  size?: number
  sizeStr?: string
  lastModified?: Date
  lastModifiedStr?: string
  relativePath?: string

  sizeBytes?: string

  projectPath?: string

  supported?: {
    rev?: boolean
    share?: boolean
    receive?: boolean
    flex?: boolean
  }
}

export interface PinnedFile extends HubFlexFile {
  status: FlexPinStatus
}

export interface FlexActivity {
  dateCreated?: Date
  dateStr?: string
  user?: string
  action?: string
  since?: string
  readOnly?: boolean
  readableOthers?: boolean
  locked?: boolean
  reason?: string
  duration?: string
  isLink?: boolean
}

@Injectable({ providedIn: 'root' })
export class FlexService {
  constructor(
    private store: FlexStore,
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService,
    private exeService: ExecutableService,
    private ts: FlexPerformanceMonitoringService,
    private clientUtils: BebopClientUtilsService,
    private userService: UserService
  ) {}

  async onLogout() {
    console.log('unmount lucid mounts')
    let mounts = this.store.getValue().mounts
    for (const m of mounts) {
      this.removeMount(m)
      if (!m?.mounted || m?.mounting || m?.unmounting) continue
      this.onUnmount(m)
      await this.exeService.lucidExit(this.getMountedIndex(m))
      m.mounted = false
      this.mountActivity(
        m._id,
        {
          type: 'UNMOUNTED',
          meta: {
            sessionDurationInMillis: Date.now() - m.source.mountedTime,
            ...m.mountOption,
            isLink: m.isLink,
          },
        },
        m.isLink
      ).subscribe((res) => {})
    }

    this.ts.stopAll()
    if (this._flexScanHandle != -1) window.clearTimeout(this._flexScanHandle)
    this._flexScanHandle = -1
    this._refreshing = false
  }
  _flexScanHandle = -1
  _refreshDoneAt = 0
  onLogIn() {
    this.afterLogin()
    if (this._flexScanHandle != -1) return

    this._refreshing = false

    let looper = () => {
      this._flexScanHandle = window.setTimeout(async () => {
        this._flexScanHandle = -1
        if (!this._refreshing) this.onRefreshAll()
        looper()
      }, REFRESH_LUCID_CACHE)
    }

    looper()
  }

  afterLogin() {
    // Fix bug
    // rootpath location is not mapped per user
    let lucidPath = LucidRootPath
    let out: { [key: string]: string } = this.clientUtils.getLocalStorageItem(LucidRootPath, false)
    if (!out) return
    let id = this.userService.id
    if (!id) return

    lucidPath = `${lucidPath}.${id}`
    this.clientUtils.setLocalStorageItem(lucidPath, out)
    this.clientUtils.removeLocalStorageItem(LucidRootPath)
  }

  getLucidRootPathKey() {
    let id = this.userService.id
    return `${LucidRootPath}.${id}`
  }

  async onRefresh(f: UiProject | UiBebopLink) {
    let mounts = this.store.getValue().mounts
    let mount = mounts?.find((m) => m._id == f._id)
    if (!mount || mount?.mounting || mount?.unmounting) return

    await this.lucidRefresh(mount).catch(console.log)
  }

  _refreshing = false
  async onRefreshAll() {
    let mounts = this.store.getValue().mounts

    if (!mounts?.length) return

    this._refreshing = true
    for (const m of mounts) {
      if (!(m.mounted || m.unmounting || m.mounting)) {
        this.removeMount(m)
      }

      if (!m?.mounted || m?.mounting || m?.unmounting) continue
      await this.lucidRefresh(m).catch(console.log)
      this.addOrUpdateMount(m)
    }
    this._refreshing = false
  }

  async lucidRefresh(m: UiProject | UiBebopLink) {
    if (!m.mounted || m.mounting || m.unmounting) return
    await this.lucidStatus(m)
    await this.lucidCache(m)
    await this.lucidPinnedList(m)
  }

  getMountedIndex(f: UiProject | UiBebopLink) {
    return this.exeService.getLucidMountedIndex(f)
  }

  async lucidPinnedList(f: UiProject | UiBebopLink) {
    let list = await this.exeService.lucidPinnedList(this.getMountedIndex(f))
    f.pinnedList = list
    f.pinnedFiles = list.filter((l) => l.status == 'Pinned').map((l) => l.file)
  }

  updateFilespaceDetails(
    f: UiProject | UiBebopLink,
    details: {
      [key: string]: string
    }
  ) {
    f.fsName = details['Filespace name'] ?? f.fsName
    f.rootPoint = details['Root point'] ?? f.rootPoint
    f.fsFormatVersion = +details['Filespace format'] || 1.0
    f.fsBlockSize = details['Filespace block size']

    let version = details['Known service version']?.trim?.() || ''
    let vers = version.split('.')
    f.versions = {
      major: +vers?.[0] || 0,
      minor: +vers?.[1] || 0,
      revision: +vers?.[2] || 0,
    }

    f.fsState = {
      service: details['Filespace service state'], // 'connected'
      store: details['Filespace object store state'], // 'connected' backing cloud store
    }

    f.fsMode = details['Filespace mode'] // 'live'
  }

  hasLucidRestApiSupport(f: UiProject | UiBebopLink) {
    if (!f) return false
    if (f.fsFormatVersion < 2.0) return false

    let { major, minor, revision } = f.versions

    if (major < 2) return false
    if (major > 2) return true

    // major is 2
    if (minor < 2) return false
    if (minor > 2) return true

    // minor is 2
    // API is available as of LucidLink Client version 2.2.4403
    return revision >= 4403
  }

  isRemoteUploadStatusSupported(f: UiProject | UiBebopLink) {
    return f ? this.hasLucidRestApiSupport(f) : false
  }

  async lucidStatus(f: UiProject | UiBebopLink) {
    let details = await this.exeService.lucidStatus(this.getMountedIndex(f))
    f.statusDetails = details

    if (!details) return
    this.updateFilespaceDetails(f, details)

    // Todo - how to find connection status

    // Used below code for testing

    // Filespace object store download latency: low
    // Filespace object store upload latency: low

    let values = ['high', 'medium', 'low']

    let dlat = details['Filespace object store download latency']
    let ulat = details['Filespace object store upload latency']

    let fairness = Math.max(values.indexOf(dlat), 0) + Math.max(values.indexOf(ulat), 0)

    if (fairness <= 1) f.connectionStatus = 'Poor'
    else if (fairness < 3) f.connectionStatus = 'Fair'
    if (fairness < 4) f.connectionStatus = 'Good'
    else f.connectionStatus = 'Excellent'

    f.indexStatus = details['Filespace file index']
    let indexing = f.indexStatus != 'up-to-date'
    if (f.syncing) f.syncing = indexing
    if (f.indexNotReady) f.indexNotReady = indexing
  }

  async lucidSync(f: UiProject | UiBebopLink) {
    f.syncing = true
    let out = await this.exeService.lucidSync(this.getMountedIndex(f))
    f.syncing = false

    if (out?.exitCode != 0 && (out?.error || out?.stderr)) {
      return new Error(out?.error || out?.stderr)
    }

    return
  }

  getSyncStatus(value: number): ProgressBar3LevelType {
    if (value < 50) return 'lt50'

    if (value <= 95) return 'gt50'

    return 'gt95'
  }

  // cached data, use it only for insight/logs
  lucidVersion: string = ''
  setLucidVersion(version: string) {
    this.lucidVersion = version
  }

  getLucidVersion() {
    return this.lucidVersion ?? ''
  }

  async lucidCache(f: UiProject | UiBebopLink) {
    let details = await this.exeService.lucidCacheDetails(this.getMountedIndex(f))

    let err = details?.output?.stderr || details?.output?.error
    if (err) {
      if (f.mounting || f.unmounting || !f.mounted) return
      if (
        err?.indexOf('Lucid is currently not running.') != -1 ||
        err?.indexOf('Request failed with: Not Found') != -1
      ) {
        f.mounted = false
        this.onUnmount(f)
        // this.removeActiveMount(f)
        this.removeMount(f)
        // this.mountActivity(
        //   f._id,
        //   {
        //     type: 'UNMOUNTED',
        //     meta: {
        //       sessionDurationInMillis: Date.now() - f.source.mountedTime,
        //       ...f.mountOption,
        //       isLink: f.isLink,
        //     },
        //   },
        //   f.isLink
        // ).subscribe((res) => {})

        return
      }
    }

    if (details && !(details instanceof Error)) {
      f.cacheDetails = details
      let lastRemainingUpload = f.remainingUpload ?? '0 B'
      f.remainingUpload = details.remainingUpload
      f.syncStatus = f.remainingUpload == '0B' ? 'up-to-date' : details.state
      f.cacheSize = details.cacheLimit ?? ''

      let m: (keyof KeysOfValueType<UiProject, string>)[] = ['remainingUpload', 'cacheSize']

      m.filter((x) => f[x]).forEach((x) => {
        f[x] = f[x]?.replace(/([\d.]+)(?:([KMGTPE])?i?B)/g, '$1 $2B') ?? f[x]
        // f[x] = [f[x]?.replace(/[^0-9.]+/g, '') ?? '', f[x]?.replace(/[\di.]+/g, '')].filter((x) => x).join(' ')
      })

      if (f.syncStatus == 'up-to-date') {
        f.syncUploadCompletePercent = 100
        f.totalUploadInProgress = 0
      } else {
        let rem = this.toBytes(f.remainingUpload)
        // already in progress
        if (f.totalUploadInProgress) {
          let lrem = this.toBytes(lastRemainingUpload)
          // some more uploads happening!
          // take previous completed bytes into consideration - its a approximation, we may miss uploaded bytes count between pool interval
          // and initial pool delay. total transfer upload is just an observed value as its not provided by lucid
          if (f.totalUploadInProgress < rem) {
            f.totalUploadInProgress = rem + (100 - f.syncUploadCompletePercent) * f.totalUploadInProgress
          } else if (lrem < rem) {
            f.totalUploadInProgress += rem - lrem
          }

          f.syncUploadCompletePercent = (((f.totalUploadInProgress - rem) * 100) / f.totalUploadInProgress) | 0
        } else {
          // identified for the first time after last sync complete
          f.totalUploadInProgress = rem
          f.syncUploadCompletePercent = 0
        }
      }

      // console.log({
      //   remainingUpload: f.remainingUpload,
      //   syncUploadCompletePercent: f.syncUploadCompletePercent,
      //   totalUploadInProgress: f.totalUploadInProgress,
      // })
    }
  }

  toBytes(lucidByteWithSpace: String) {
    let bytes = lucidByteWithSpace?.split(' ') ?? ['0', 'B']

    let ranges = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']

    let idx = ranges?.findIndex((r) => r == bytes?.[1])

    if (idx == -1) return 0

    return 2 ** idx * (+bytes?.[0] || 0)
  }

  onUnmount(f: UiProject | UiBebopLink) {
    if (!f) return
    this.ts.stop({
      project: f,
      instanceId: <number>this.getMountedIndex(f),
    })
  }

  removeActiveMount(f: UiProject | UiBebopLink) {
    if (!f) return
    let value = this.store.getValue()

    this.updateMounts(value.mounts?.filter((m) => m._id != f._id) ?? [])
  }

  onMount(f: UiProject | UiBebopLink) {}

  updateMounts(mounts: (UiProject | UiBebopLink)[]) {
    let value = this.store.getValue()

    let selected: UiProject

    if (value.selected) {
      selected = mounts.find((m) => m._id == value.selected?._id)
    }

    this.store.update(
      (store) =>
        (store = {
          ...store,
          mounts: [...mounts],
          selected,
        })
    )
  }

  refresh() {
    this.store.update(
      (store) =>
        (store = {
          ...store,
        })
    )
  }

  addOrUpdateMount(mount: UiProject | UiBebopLink) {
    if (!mount) return
    let value = this.store.getValue()

    let idx = value.mounts?.findIndex((v) => v._id == mount?._id)

    let mounts = [...value.mounts]
    if (idx != -1) {
      mounts[idx] = mount // { ...mounts[idx], ...mount }
    } else {
      mounts.push(mount)
    }

    this.store.update(
      (store) =>
        (store = {
          ...store,
          mounts,
        })
    )
  }

  removeMount(mount: UiProject | UiBebopLink) {
    if (!mount) return
    let value = this.store.getValue()

    let mounts = [...value.mounts]

    let idx = mounts?.findIndex((v) => v._id == mount?._id)

    if (idx != -1) {
      mounts.splice(idx, 1)
    }

    let selected = value.selected
    if (selected?._id && selected?._id == mount?._id) {
      selected = null
    }

    this.store.update(
      (store) =>
        (store = {
          ...store,
          mounts,
          selected,
        })
    )
  }

  updateSelected(selected: UiProject | UiBebopLink) {
    this.store.update(
      (store) =>
        (store = {
          ...store,
          selected,
        })
    )
  }

  update(partial: Partial<FlexState>) {
    this.store.update(
      (store) =>
        (store = {
          ...store,
          ...partial,
        })
    )
  }

  switchFlexLockMount(projectId: ObjectId, switchTo: 'lock' | 'unlock' | 'readable-others') {
    return this.http
      .get<GenericResponse>(
        `${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/flexSwitchLockMount?switchTo=${switchTo}`
      )
      .pipe(
        catchError((error: any) => {
          console.error('On getFlexMount', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getFlexMount(projectId: ObjectId, opt: MountOption) {
    let type = ''
    if (opt?.readOnly) type = '?type=readonly'
    else if (opt?.locked) type = '?type=locked'
    else if (opt?.readableByOthers) type = '?type=readable-others'

    return this.http
      .get<FlexMountResponse>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/flexMount${type}`)
      .pipe(
        catchError((error: any) => {
          console.error('On getFlexMount', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  getFlexMountLink(linkId: string, opt: MountOption) {
    let type = opt?.readOnly ? '?type=readonly' : ''

    return this.http
      .get<FlexMountResponse>(`${this.bebopConfig.apiUrl}/api/v1/drop-link/${linkId}/flexMount${type}`)
      .pipe(
        catchError((error: any) => {
          console.error('On getFlexMountLink', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  mountActivity(id: string, data: any, isLink: boolean) {
    let parts = isLink ? `drop-link/${id}/flex-activity` : `projects/${id}/activity`

    return this.http.post<StandardResponse>(`${this.bebopConfig.apiUrl}/api/v1/${parts}`, data).pipe(
      catchError((error: any) => {
        console.error('On mountActivity', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getLucidInstanceIndex(item: UiProject | UiBebopLink) {
    let id = item?.source?._id
    if (!id) return -1

    let out: string[] = this.clientUtils.getLocalStorageItem(LucidKey, false) ?? []
    let idx = out?.findIndex((i) => i == id)

    if (idx != -1) return idx + 1

    out.push(id)

    this.clientUtils.setLocalStorageItem(LucidKey, out)
    return out.length
  }

  getLucidInstanceRootPath(item: UiProject | UiBebopLink) {
    let id = item?.source?._id
    if (!id) return ''

    let out: { [key: string]: string } = this.clientUtils.getLocalStorageItem(this.getLucidRootPathKey(), false) ?? {}

    return out[id] ?? ''
  }

  setLucidInstanceRootPath(item: UiProject | UiBebopLink, path: string) {
    let id = item?.source?._id
    if (!id) return false
    // falsey for unset
    if (!path) path = null

    let lucidPath = this.getLucidRootPathKey()
    let out: { [key: string]: string } = this.clientUtils.getLocalStorageItem(lucidPath, false) ?? {}

    let modified = out?.[id] != path

    out[id] = path

    this.clientUtils.setLocalStorageItem(lucidPath, out)
    return modified
  }

  setLucidInstanceCacheLocation(item: UiProject | UiBebopLink, path: string) {
    return this.setLucidInstanceRootPath(item, this.getLucidInstanceCacheLocation(path))
  }

  resetLucidCache(item: UiProject | UiBebopLink) {
    return this.setLucidInstanceRootPath(item, this.electronService.os.homedir())
  }

  getLucidInstanceCacheLocation(path: string) {
    let idx = path.indexOf(LucidCacheRootFolder)
    return idx != -1 ? path.slice(0, idx) : path
  }

  searchMountActivity(
    id: string,
    options: { page: number; size: number; since?: number; search?: string },
    isLink = false
  ) {
    let parts = isLink ? `drop-link/${id}/flex-activity` : `projects/${id}/activity`
    return this.http
      .get<MountActivityResponse>(`${this.bebopConfig.apiUrl}/api/v1/${parts}`, {
        params: options,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On searchMountActivity', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  lucidFSEndPointFiles(opts: { mount: UiProject | UiBebopLink; file: string }) {
    let port = LUCID_ENDPOINT_BASE_PORT + +(<number>this.getMountedIndex(opts.mount))
    let path = this.electronService.path

    let nfile = path.join(opts.mount.rootPoint, opts.file)
    let url = `http://localhost:${port}/v1/${opts.mount.fsName}/files`
    let queryParams = new HttpParams().set('path', nfile)

    return this.http
      .get<LucidFsFilesResponse>(url, {
        headers: httpOptions.headers,
        params: queryParams,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On lucidFSEndPointFiles', error.message)

          let msg = `${opts.file}: `
          if (error.status == 405) msg += 'Path provided refers to a directory/folder. This is not allowed'
          else if (error.status == 404) msg += 'Invalid path. The file cannot be found'
          else if (error.status == 400) msg += 'Invalid request'

          return of({
            error: {
              msg,
              reason: '',
            },
          })
        })
      )
  }
}
