<div class="bebop-rocket-file-icon">
  <div class="file-icon icon-audio" *ngIf="type == 'audio'">
    <svg viewBox="0 0 20 20" fill="none">
      <path
        d="M18.615 3.468 9.14.124A2.162 2.162 0 0 0 7.213.37c-.278.189-.504.44-.66.731a1.994 1.994 0 0 0-.237.944v6.263a4.256 4.256 0 0 0-2.105-.57C1.888 7.738 0 9.572 0 11.826s1.888 4.088 4.21 4.088c2.323 0 4.211-1.834 4.211-4.088v-9.78l9.474 3.343v7.005a4.265 4.265 0 0 0-2.105-.568c-2.323 0-4.211 1.833-4.211 4.088 0 2.254 1.888 4.087 4.21 4.087C18.113 20 20 18.167 20 15.913V5.388c0-.857-.557-1.63-1.385-1.92ZM4.21 13.868c-1.162 0-2.106-.916-2.106-2.043 0-1.127.944-2.043 2.106-2.043 1.16 0 2.105.916 2.105 2.043 0 1.127-.944 2.044-2.105 2.044Zm11.579 4.088c-1.162 0-2.106-.916-2.106-2.043 0-1.127.944-2.044 2.106-2.044 1.16 0 2.105.917 2.105 2.044s-.944 2.043-2.105 2.043Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-video" *ngIf="type == 'video'">
    <svg viewBox="0 0 20 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 6.536c-1.333-.77-3 .192-3 1.732v3.464c0 1.54 1.667 2.502 3 1.732l3-1.732c1.333-.77 1.333-2.694 0-3.464l-3-1.732ZM9 8.268 12 10l-3 1.732V8.268Z"
        fill="#5A5A68"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 0a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h10a5 5 0 0 0 5-5V5a5 5 0 0 0-5-5H5ZM2 5a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-document" *ngIf="type == 'document'">
    <svg viewBox="0 0 20 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4a2 2 0 0 1 2-2h4v1a5 5 0 0 0 5 5h1v1h2V6.828a3 3 0 0 0-.879-2.12L11.293.877A3 3 0 0 0 9.172 0H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h8a4.002 4.002 0 0 0 3.874-3h-2.142A2 2 0 0 1 12 18H4a2 2 0 0 1-2-2V4Zm11.586 2L10 2.414V3a3 3 0 0 0 3 3h.586Zm-.133 8.797c-.488.469-1.103.703-1.848.703-.743 0-1.362-.234-1.855-.703-.487-.469-.731-1.068-.731-1.797 0-.73.244-1.329.731-1.797.493-.469 1.112-.703 1.855-.703.745 0 1.36.234 1.848.703s.732 1.068.732 1.797c0 .73-.244 1.329-.732 1.797Zm-1.848-.398c.392 0 .708-.133.95-.399.241-.266.362-.599.362-1s-.12-.734-.362-1-.558-.399-.95-.399c-.396 0-.715.133-.956.399s-.362.599-.362 1 .12.734.362 1 .56.399.957.399Zm-5.569 1.014H4V10.58h2.029c.773 0 1.401.217 1.884.652.488.435.732 1.022.732 1.76 0 .74-.244 1.33-.732 1.769-.483.435-1.109.652-1.877.652Zm-.007-1.087c.41 0 .737-.128.978-.384.242-.26.363-.577.363-.95 0-.39-.116-.71-.348-.956-.232-.246-.56-.37-.986-.37h-.79v2.66h.783Zm9.284.478c.497.464 1.12.696 1.87.696.53 0 .968-.123 1.311-.37.348-.25.614-.56.797-.927l-1.072-.507a1.14 1.14 0 0 1-.413.5 1.045 1.045 0 0 1-.623.203c-.392 0-.715-.133-.971-.399a1.415 1.415 0 0 1-.377-1c0-.396.125-.727.377-.993.256-.27.58-.406.97-.406.228 0 .435.068.624.203.193.136.33.302.413.5l1.072-.507c-.435-.865-1.137-1.297-2.108-1.297-.754 0-1.38.232-1.877.696-.493.463-.74 1.065-.74 1.804 0 .734.25 1.336.747 1.804Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-image" *ngIf="type == 'image'">
    <svg viewBox="0 0 20 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 5a5 5 0 0 1 5-5h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5Zm5-3a3 3 0 0 0-3 3v10c0 .398.078.778.219 1.126l1.874-2.343a2.966 2.966 0 0 1 3.962-.615.966.966 0 0 0 1.29-.2l1.6-2a3 3 0 0 1 4.263-.43L18 12.865V5a3 3 0 0 0-3-3H5Zm12.968 13.441-4.04-3.367a1 1 0 0 0-1.422.144l-1.599 1.999a2.966 2.966 0 0 1-3.962.615.966.966 0 0 0-1.29.2L3.57 17.638c.425.23.912.362 1.43.362h10a3 3 0 0 0 2.968-2.559ZM7 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM4 7a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-folder" *ngIf="type == 'folder'">
    <svg viewBox="0 0 20 20" fill="none" *ngIf="!filled">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.375a2 2 0 0 0-2-2h-4.93a3 3 0 0 1-2.496-1.336L7.512 2.445A1 1 0 0 0 6.68 2H4ZM0 4a4 4 0 0 1 4-4h2.68a3 3 0 0 1 2.496 1.336l1.062 1.594a1 1 0 0 0 .832.445H16a4 4 0 0 1 4 4V16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z"
        fill="#5A5A68"
      />
    </svg>

    <svg width="20" height="20" viewBox="0 0 96 72" fill="none" *ngIf="filled">
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H28.6573C31.1255 0 33.4555 1.13929 34.9712 3.08726L36.1693 4.62703C37.685 6.575 40.015 7.71429 42.4831 7.71429H88C92.4183 7.71429 96 11.296 96 15.7143V64C96 68.4183 92.4183 72 88 72H8C3.58172 72 0 68.4183 0 64V8Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-pdf" *ngIf="type == 'pdf'">
    <svg viewBox="0 0 18 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4a2 2 0 0 1 2-2h4v1a5 5 0 0 0 5 5h1v1h2V6.828a3 3 0 0 0-.879-2.12L11.293.877A3 3 0 0 0 9.172 0H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h8a4.002 4.002 0 0 0 3.874-3h-2.142A2 2 0 0 1 12 18H4a2 2 0 0 1-2-2V4Zm11.586 2L10 2.414V3a3 3 0 0 0 3 3h.586Zm-8.297 9.5H4v-5h2.504c.54 0 .964.16 1.274.48.31.315.465.71.465 1.184 0 .47-.155.865-.465 1.185-.31.314-.735.472-1.274.472H5.289V15.5Zm1.042-2.804a.63.63 0 0 0 .428-.142.479.479 0 0 0 .172-.39.487.487 0 0 0-.172-.397.63.63 0 0 0-.428-.143H5.29v1.072h1.042Zm4.592 2.804H8.817v-5h2.099c.8 0 1.45.225 1.949.675.505.45.757 1.057.757 1.821 0 .765-.252 1.375-.757 1.83-.5.45-1.147.674-1.942.674Zm-.007-1.124c.425 0 .762-.133 1.012-.398.25-.27.375-.597.375-.982 0-.405-.12-.734-.36-.99-.24-.254-.58-.382-1.02-.382h-.817v2.752h.81Zm3.347 1.124h1.29v-1.972h2.324v-1.124h-2.324v-.78h2.376V10.5h-3.666v5Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-zip" *ngIf="type == 'zip'">
    <svg viewBox="0 0 22 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4a2 2 0 0 1 2-2h2.68a1 1 0 0 1 .832.445L8.574 4.04a3 3 0 0 0 2.496 1.336H16a2 2 0 0 1 2 2V9h2V7.375a4 4 0 0 0-4-4h-4.93a1 1 0 0 1-.832-.445L9.176 1.336A3 3 0 0 0 6.68 0H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4.002 4.002 0 0 0 3.874-3h-2.142A2 2 0 0 1 16 18H4a2 2 0 0 1-2-2V4Zm10.66 12H8v-1.232l2.555-3.419H8V10h4.597v1.232l-2.564 3.419h2.627V16Zm2.435 0h-1.547v-6h1.547v6Zm1.08 0h1.546v-2.015h1.458c.647 0 1.157-.189 1.529-.567.372-.383.558-.857.558-1.421 0-.57-.186-1.043-.558-1.421-.372-.384-.882-.576-1.53-.576h-3.004v6Zm3.31-3.535a.757.757 0 0 1-.513.17h-1.25V11.35h1.25c.21 0 .38.057.512.171a.585.585 0 0 1 .207.477.574.574 0 0 1-.206.468Z"
        fill="#5A5A68"
      />
    </svg>
  </div>
  <div class="file-icon icon-others" *ngIf="type == 'others'">
    <svg viewBox="0 0 16 20" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8h-1a5 5 0 0 1-5-5V2H4Zm6 .414V3a3 3 0 0 0 3 3h.586L10 2.414ZM0 4a4 4 0 0 1 4-4h5.172a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 16 6.828V16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm5 2a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H5Zm-1 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z"
        fill="#fff"
      />
    </svg>
  </div>

  <svg
    class="pulse-icon tw-animate-pulse"
    *ngIf="pulse"
    viewBox="0 0 6 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    bebop-tooltip="Growing File"
    bebop-tooltip-type="info"
    bebop-tooltip-placement="right"
    bebop-tooltip-offset="4"
    bebop-tooltip-width="80"
    bebop-tooltip-bg-border-color="rgba(255, 255, 255, 0.08)"
    bebop-tooltip-bg-color="#0C0C0F"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3Z"
      fill="white"
    />
  </svg>
</div>
