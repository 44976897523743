import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

export type LoginBannerType = 'Email2FA' | 'OAuth'

export interface LoginBannerData {
  sentTo?: string
}

@Component({
  selector: 'bebop-login-banner',
  templateUrl: './login-banner.component.html',
  styleUrls: ['./login-banner.component.scss'],
})
export class LoginBannerComponent implements OnInit {
  @Input('type') type: LoginBannerType
  @Input('banner-data') data: LoginBannerData
  @Output('on-login') onLogin = new EventEmitter<void>()

  constructor() {
    console.log('login banner loaded')
  }

  ngOnInit(): void {}

  logIn() {
    this.onLogin.emit()
  }
}
