import { Location } from '@angular/common'
import { Injectable } from '@angular/core'

import { environment } from 'src/environments/environment'

import { ElectronService } from './electron.service'

const DEV_ENVS = ['dev', 'osonedev', 'osonedev2']
@Injectable({
  providedIn: 'root',
})
export class BebopConfigService {
  constructor(private electronService: ElectronService) {}

  get appVersion() {
    return this.electronService.appVersion
  }

  get appFullVersion() {
    return this.electronService.appFullVersion
  }

  get env() {
    return this.electronService.env
  }

  get isDevelopment() {
    return DEV_ENVS.includes(this.electronService.env)
  }

  get settings() {
    return environment[this.electronService.env]
  }

  get apiUrl() {
    return environment[this.electronService.env].serverUrl
  }

  get supportUrl() {
    return environment[this.electronService.env].supportUrl
  }

  get storeUrl() {
    return environment[this.electronService.env].storeUrl
  }

  getApiUrl(url: string) {
    return Location.joinWithSlash(this.apiUrl, url)
  }
}
