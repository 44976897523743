import { Component, Input } from '@angular/core'

@Component({
  selector: 'cree8-spinner',
  standalone: false,
  templateUrl: './cree8-spinner.component.html',
  styleUrl: './cree8-spinner.component.scss',
})
export class Cree8SpinnerComponent {
  @Input() variant: 'light' | 'dark' = 'dark'
}
