<div class="option-list">
  <div class="option-container" *ngFor="let option of options">
  <div class="option-content">
    <span class="option-title">{{option.title}}</span>
    <span class="option-description">{{option.description}}</span>
  </div>
  <div class="option-actions">
    <input
    type="radio"
    name="option"
    class="hidden-radio"
    [value]="option.value"
    [checked]="option.value === selectedOption"
    (change)="onOptionSelected(option.value)"
  />
  </div>
</div>
</div>
