import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class WorkstationsService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getAll(params?: any) {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/workstations/server`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getAll', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  deleteUserFromWorkstation(workstationId: string, userId: string) {
    return this.manageUserToWorkstation(workstationId, userId, true)
  }

  addUserToWorkstation(workstationId: string, userId: string) {
    return this.manageUserToWorkstation(workstationId, userId, false)
  }

  private manageUserToWorkstation(workstationId: string, userId: string, deletUser: boolean) {
    return this.http
      .post<any>(`${this.bebopConfig.apiUrl}/api/v2/workstations/${workstationId}/users/${userId}`, {
        deleteUser: deletUser,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On manageUserToWorkstation', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }
}
