import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subject } from 'rxjs'
import { IActionType } from 'src/app/common/components/actions-list/actions-list.component'
import { ITabs } from 'src/app/common/components/cree8-tabs/cree8-tabs.component'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import {
  AddProjectModalAction,
  AddProjectModalComponent,
} from 'src/app/components/rocket/project/modals/add-project-modal/add-project-modal.component'
import { Project, User } from 'src/app/models/bebop.model'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService } from 'src/app/store/projects/projects.service'
import { ProjectsLinksService } from 'src/app/store/projects/projects-links.service'
import { SessionQuery } from 'src/app/store/session/session.query'

import { LinksProjectsComponent } from '../links-projects/links-projects.component'

import { AllProjectsComponent } from './all-projects/all-projects.component'

@Component({
  selector: 'projects-view',
  styleUrl: './projects-view.component.scss',
  templateUrl: './projects-view.component.html',
})
export class ProjectsViewComponent implements OnInit, OnDestroy {
  @ViewChild('linkProject') linkProjectComponent!: LinksProjectsComponent
  @ViewChild('allProjects') allProjectsComponent!: AllProjectsComponent

  _destroy$ = new Subject()

  headerTabs: ITabs[] = [
    { active: true, label: 'All Projects', value: 'all' },
    { label: 'Links', value: 'links' },
  ]

  filterTabsOnLinkProject: ITabs[] = [
    { active: true, label: 'Receive', value: '8' },
    { label: 'Shared', value: '1' },
    { label: 'Notes', value: '4' },
  ]

  listSortingProjects: { label: string; value: string }[] = [
    { label: 'Newest to oldest', value: '-date_created' },
    { label: 'Oldest to newest', value: 'date_created' },
    { label: 'From A to Z', value: 'name' },
    { label: 'From Z to A', value: '-name' },
  ]

  activeSorting: string = this.listSortingProjects[0].value

  allowedActions: IActionType[] = ['search', 'refresh', 'sorting', 'created']
  activeParamsTab = 'all'
  private user: User
  project: Project | undefined

  constructor(
    private projectsQuery: ProjectsQuery,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private projectsLinksService: ProjectsLinksService,
    private sessionQuery: SessionQuery,
    private router: Router,
    private modalService: ComponentModalService
  ) {
    this.projectsService.resetStore()
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      if (res['activeTab']) {
        this.activeParamsTab = res['activeTab']
        this.mappingActionListByHeaderTabs(this.activeParamsTab)
      }
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true)
    this._destroy$.complete()
  }

  onChooseHeaderTab(value: string) {
    this.router.navigate([], {
      queryParams: { activeTab: value },
    })
  }

  filterTabsLinks(value: string) {
    this.filterTabsOnLinkProject.forEach((tab) => {
      tab.active = tab.value === value
    })

    this.linkProjectComponent.filterByType(Number(value))
  }

  onRefresh() {
    this.allProjectsComponent.onRefresh()
  }

  onSearchValue(searchText: string) {
    this.projectsService.searchProjects(searchText)
  }

  onSorting(sorting: string) {
    if (this.activeParamsTab === 'links') {
      this.linkProjectComponent.sorting(sorting)
    } else {
      this.projectsService.sortProjects(sorting)
    }

    this.activeSorting = sorting
  }

  onCreatedProject() {
    this.addNewProject()
  }

  addProject(e: MouseEvent) {
    this.addNewProject()
  }

  async addNewProject() {
    let ref = this.modalService.open<AddProjectModalComponent, AddProjectModalAction>(
      AddProjectModalComponent,

      {
        data: {},
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
      }
    )

    ref.events().subscribe(async (e) => {
      if (e.name == 'Add') {
        this.onRefresh()
      }
    })
  }

  private mappingActionListByHeaderTabs(valueTab: string) {
    if (valueTab === 'all') {
      this.allowedActions = ['search', 'refresh', 'sorting', 'created']

      this.listSortingProjects = [
        { label: 'Newest to oldest', value: '-date_created' },
        { label: 'Oldest to newest', value: 'date_created' },
        { label: 'From A to Z', value: 'name' },
        { label: 'From Z to A', value: '-name' },
      ]

      this.activeSorting = this.listSortingProjects[0].value
    }

    if (valueTab === 'links') {
      this.allowedActions = ['sorting']

      this.listSortingProjects = [
        { label: 'Active links only', value: 'active' },
        { label: 'Show deactivated', value: 'deactivated' },
        { label: 'Show expired only', value: 'expired' },
      ]

      this.activeSorting = this.listSortingProjects[0].value
    }

    this.headerTabs.forEach((tab) => {
      tab.active = tab.value === this.activeParamsTab
    })
  }

  onScroll(event: Event): void {
    if (!this.allProjectsComponent) return
    const target = event.target as HTMLElement
    const scrollTop = target.scrollTop
    const scrollHeight = target.scrollHeight
    const clientHeight = target.clientHeight

    // Check if user has scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 40) {
      this.allProjectsComponent.loadMoreProjects()
    }
  }
}
