<div class="link-settings">
  <bebop-input
    type="text"
    [autofocus]="false"
    placeholder="Name"
    [(binding)]="name"
  >
    <div input-label class="email-label">Name</div>
  </bebop-input>

  <bebop-input-tags
    multiline="true"
    noDuplicate="false"
    placeholder=".mp4, .mov, .jpg, etc."
    [tags]="extensions"
    [validate]="validateExtension"
    contained-layout="false"
    tooltip-left-offset-percent-value=".305"
  >
  <div tags-label class="email-label">Whitelist file extensions</div>
  </bebop-input-tags>

  <bebop-input
    type="number"
    [autofocus]="false"
    placeholder="Max size per file"
    [(binding)]="maxSize"
  >
    <div input-label class="email-label">Max size per file</div>
  </bebop-input>

  <bebop-input
    type="number"
    [autofocus]="false"
    placeholder="Max number of files"
    [(binding)]="maxFiles"
  >
    <div input-label class="email-label">Max number of files</div>
  </bebop-input>

  <bebop-input
  type="text"
  [autofocus]="false"
  placeholder="Path to folder in project"
  [(binding)]="path"
>
  <div input-label class="email-label">Path to folder in project</div>
</bebop-input>

  <bebop-input
    [textarea]="true"
    [autofocus]="false"
    placeholder="Notes for the link"
    [(binding)]="notes"
  >
    <div input-label class="email-label">Notes for the link</div>
  </bebop-input>
</div>
