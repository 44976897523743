import { Component, Input } from '@angular/core';

@Component({
  selector: 'live-indicator',
  templateUrl: './live-indicator.component.html',
  styleUrl: './live-indicator.component.scss'
})
export class LiveIndicatorComponent {
  @Input() type: 'grey' | 'green' | 'red' = 'grey';
  @Input() showLabel: boolean = true;
  @Input() label: string = 'Live';
}
