import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { IResponseUpdateProjectSettings, PayloadUpdateProjectSettings } from '../models/projects.model'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getProject(id: string, params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/projects/${id}`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getProject', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getProjects(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/projects`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getProjects', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getOrgProjects(params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/orgProjects`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getOrgProjects', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getProjectsStorageByPod(): Observable<any> {
    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/projects-storage-by-pod`).pipe(
      catchError((error: any) => {
        console.error('On getProjectsStorageByPod', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getMyProjectsByUserId(userId: string, params?: any): Observable<any> {
    // Default parameters if no arguments are passed
    if (!params) {
      params = {}
    }
    // Create HttpParams from the params object
    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/myprojects/user/${userId}`, { params: httpParams })
      .pipe(
        catchError((error: any) => {
          console.error('On getMyProjectsByUserId', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  updateProjectSettings(projectId: string, settings: PayloadUpdateProjectSettings): Observable<any> {
    return this.http
      .post<IResponseUpdateProjectSettings>(`${this.bebopConfig.apiUrl}/api/v1/myprojects/${projectId}`, {
        ...settings,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On updateProjectSettings', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  deleteUserFromProject(projectId: string, userId: string) {
    return this.manageUserProjects(projectId, userId, true)
  }

  addUserToProject(projectId: string, userId: string) {
    return this.manageUserProjects(projectId, userId, false)
  }

  private manageUserProjects(projectId: string, userId: string, deletUser: boolean) {
    return this.http
      .post<any>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/users/${userId}`, {
        deleteUser: deletUser,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On deleteUserFromProject', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  saveSyncSetting(projectId: string, settings: any) {
    return this.http.put<any>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/syncsettings`, settings).pipe(
      catchError((error: any) => {
        console.error('On saveSyncSetting', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  saveToSyncQueue(projectId: string, settings: any) {
    return this.http.put<any>(`${this.bebopConfig.apiUrl}/api/v1/projects/${projectId}/sync-queue`, settings).pipe(
      catchError((error: any) => {
        console.error('On saveToSyncQueue', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
