import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { UiModule } from 'src/app/common/ui.module'

import { LiveIndicatorComponent } from './live-indicator/live-indicator.component'
import { NoDataComponent } from './no-data/no-data.component'
import { TableActionsComponent } from './table-actions/table-actions.component'

@NgModule({
  declarations: [TableActionsComponent, NoDataComponent, LiveIndicatorComponent],
  exports: [FormsModule, UiModule, ReactiveFormsModule, TableActionsComponent, NoDataComponent, LiveIndicatorComponent],
  imports: [CommonModule, FormsModule, UiModule, ReactiveFormsModule],
})
export class SharedModule {}
