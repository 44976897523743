import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { MainService } from 'src/app/services/main.service'

@Component({
  selector: 'cree8-activities',
  styleUrls: ['./activities.component.scss'],
  templateUrl: './activities.component.html',
})
export class ActivitiesComponent implements OnInit {
  @Input() activities: any[] = [] // Input to receive activities data from parent component
  @Input() noActivities: boolean = true // Input to control if there are no activities

  constructor(
    private mainService: MainService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  getUserImage(activity: any): any {
    // Get user image based on the activity's created_by
    if (activity?.created_by) {
      return this.mainService.getUserImage(activity.created_by)
    } else {
      return this.mainService.getSystemIcon()
    }
  }

  seeAllActivities() {
    this.router.navigate(['app/activity-log'])
  }
}
