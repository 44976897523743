import { Workstation } from 'src/app/models/bebop.model'

export const WorkstationLaunchStates = [
  'Not Started',
  'Starting',
  'Started',
  'Running',
  'Stopping',
  'Retrying',
] as const
export type WorkstationLaunchState = (typeof WorkstationLaunchStates)[number]

export const WorkstationLaunchTypes = ['PCoIP', 'Jump', 'Parsec', 'Ultra'] as const
export type WorkstationLaunchType = (typeof WorkstationLaunchTypes)[number]

export enum WorkstationLaunchTypeBgColors {
  PCoIP = 'rgba(50, 186, 120, 0.4)',
  PCoIPExt = 'rgba(255, 193, 76, 1)',
  Jump = 'rgba(46, 186, 247, 0.4)',
  Parsec = 'rgba(255, 82, 165, 0.4)',
  Ultra = 'rgba(46, 186, 246, 0.4)',
  Fallback = 'var(--tertiary)',
}

export const FETCH_DELAY_SINCE_LAST_INTERACTION = 2000
export const MAX_LAUNCH_RETRY_COUNT = 2
export const RETRY_LAUNCH_SESSION_DELAY = 2000
export const NDI_MSG_LENGTH = 150

export const REFRESH_LUCID_CACHE = 5000
export const MIN_LUCID_VERSION_RECOMMENDED = 2.0

export const WorkstationErrorCodes = {
  ERR_AUTH_USER: 'Failed to authenticate user.',
  ERR_CONNECTING_DESKTOP: 'Failed to connect to workstation.',
  'Insufficient capacity.':
    'Failed to launch desktop due to temporary capacity limits. Please try to launch again in 5-10 mins.',
  InsufficientInstanceCapacity:
    'Failed to launch desktop due to temporary capacity limits. Please try to launch again in 5-10 mins.',
  'InvalidInstanceID.NotFound': 'Instance not found. Please try to launch again in 5-10 mins.',
}

export interface UiWorkstation {
  name?: string
  iname?: string
  nickname?: string
  state?: WorkstationLaunchState

  powerCodeDescription?: string
  powerCodeLabel?: string

  badges?: WorkstationLaunchType[]

  broadcast?: boolean

  favorite?: boolean

  group?: string

  recentUsed?: Date
  runningTime?: number

  // original payload from network
  source?: Workstation
}

export interface WorkstationWidgetHandle {
  cardUpdateLastUserInteraction: (ev: UiWorkstation) => void
  getBadges: (w: Workstation) => void
  getBroadcastStatus: (w: Workstation) => void
  getPowerCodeLabel: (w: Workstation) => Partial<UiWorkstation>
  updateCount: (kind: WorkstationTabKind, count: number) => void
  clearAllFilter: () => void
  guessFilter(apply: FilterWorkstationActionPayload): number
  getUsers(): string[]
}

export interface FilterWorkstationActionPayload {
  state: WorkstationLaunchState | 'All'
  users: string[]
}
export const WorkstationGroupDefault = 'default'
export const WorkstationFilterApplyDefault: FilterWorkstationActionPayload = { state: 'All', users: [] }
export type WorkstationTabKind = 'All' | 'Running' | 'Not-Started' | 'Favorite'

export const WorkstationSortByLabel = 'WorkstationSortBy'
export type WorkstationSort = 'Recently Used' | 'Alphabetical' | 'Running Time'
export interface WorkstationTabGroup {
  name: string
  count: number
}

export interface UiWorkstationGroup {
  name: string
  workstations: UiWorkstation[]
}

export const WORKSTATION_POLL_INTERVAL = 5000

export enum VmPowerStatus {
  LAUNCHING = 0,
  RUNNING = 1,
  STOPPED = 2,
  STOPPING = 3,
  STARTING = 4,
  REBOOTING = 5,
  FAILED = 7,
  SUSPENDING = 8,
  SUSPENDED = 9,
  RESUMING = 10,
  UNKNOWN = 12,
  PAUSING = 14,
  PAUSED = 15,
  REVERTING = 18,
  TERMINATED = 20,
  QUEUED_FOR_TERMINATION = 21,
}

export enum VmOperations {
  START = 'START',
  REBOOT = 'REBOOT',
  STOP = 'STOP',
  TERMINATE = 'TERMINATE',
  ANY = 'ANY',
}

export enum VmRoles {
  TX_SERVER = 'TX_SERVER',
  SYNC_SERVER = 'SYNC_SERVER',
  CONTENT_SCANNER = 'CONTENT_SCANNER',
  STORAGE_MONITOR = 'STORAGE_MONITOR',
  WORKSTATION = 'WORKSTATION',
  TIGER_SERVER = 'TIGER_SERVER',
  AMI_INSTANCE = 'AMI_INSTANCE',
  PROXY_SERVER = 'PROXY_SERVER',
  SGCM = 'SGCM',
}

export enum VmChannels {
  CAST_STATION = 'CAST_STATION',
  CAST_EARTH_STATION = 'CAST_EARTH_STATION',
  WORKSTATION = 'WORKSTATION',
}

export enum VmTypes {
  AMAZON = 'AMAZON',
  SOFTLAYER = 'SOFTLAYER',
  AZURE = 'AZURE',
  GOOGLE = 'GOOGLE',
  ALIBABA = 'ALIBABA',
}

export enum VmAction {
  START = 'START',
  STOP = 'STOP',
  REBOOT = 'REBOOT',
}
