import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'

@Component({
  selector: 'cree8-chip',
  templateUrl: './cree8-chip.component.html',
  styleUrls: ['./cree8-chip.component.scss'],
})
export class Cree8ChipComponent implements OnInit, AfterViewInit {
  constructor(private cdRef: ChangeDetectorRef) {}

  hasIcon = true
  canClose = false
  isError = false

  @Input('color') color: string = ''
  @Input('bg-color') bgColor: string = ''

  @Input('error') error: boolean = false
  @Input('error-message') errorMessage: string = ''

  @Input('closable') closeable: boolean = false
  @Output('close') onClose = new EventEmitter<Event>()

  @ViewChild('tagIcon') tagIcon: any

  ngOnInit(): void {
    this.canClose = this.closeable
    this.isError = this.error

    if (this.isError) {
      this.color = ''
      this.bgColor = ''
    }
  }

  ngAfterViewInit() {
    this.hasIcon = this.tagIcon?.nativeElement && this.tagIcon.nativeElement.children.length > 0
    this.cdRef.detectChanges()
  }

  onCloseEvent(ev: Event) {
    this.onClose.emit(ev)
  }
}
