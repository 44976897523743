import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererFactory2,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { fireworks } from 'src/app/common/animations/anime-functions'
import { AutoUpdateState, ElectronService } from 'src/app/services/electron.service'

type AppPatchUpdateState = 'UpdatingPatch' | 'ErrorPatch' | 'InstallPatch'
type AppUpdateState = 'Splash' | 'Checking' | 'Updating' | 'Error' | 'No Updates' | 'Install' | AppPatchUpdateState
@Component({
  selector: 'bebop-updater',
  templateUrl: './updater.component.html',
  styleUrls: ['./updater.component.scss'],
})
export class UpdaterComponent implements OnInit, OnDestroy {
  updateState: AppUpdateState
  theme = 'dark'

  download = 0 // 0 - 100
  timeHandle = -1

  splashCompleted = false

  animeCbs: Function[] = []

  unregister: Function
  unregister2: Function

  private renderer: Renderer2

  @ViewChild('particles') canvas!: ElementRef

  get state(): AppUpdateState {
    return this.splashCompleted ? this.updateState : 'Splash'
  }

  get isSplashView() {
    return this.updateState == 'Splash' || this.updateState == 'Checking'
  }

  _downloadLink = ''
  get downloadLink() {
    return this._downloadLink
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(e: MouseEvent) {
    this.animeCbs.push(fireworks(this.canvas?.nativeElement, { x: e.clientX, y: e.clientY }))
  }

  constructor(
    private route: ActivatedRoute,
    private rendererFactory: RendererFactory2,
    private electronService: ElectronService,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
    this.updateState = 'Splash'

    window.setTimeout(() => {
      let metadata = this.electronService.metadata
      let subdomain = metadata?.server
      this._downloadLink = `https://${subdomain}.${metadata?.domain}/installers/bbp-clt-v4/${
        this.electronService.isMac ? 'osx' : 'win'
      }`
    }, 3000)
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.unregister?.()
    this.unregister2?.()
    window.clearTimeout(this.timeHandle)
    this.animeCbs?.forEach((a) => a?.())
  }

  downloadClient() {
    this.electronService.openExternal(this.downloadLink)
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let locs = [
        {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
        },
        // {
        //   x: 25,
        //   y: window.innerHeight - 25,
        // },
        // {
        //   x: window.innerWidth - 25,
        //   y: 25,
        // },
      ]

      locs.forEach((loc, i) => {
        this.animeCbs.push(
          fireworks(this.canvas?.nativeElement, loc, {
            noCircles: true,
            distance: Math.min(window.innerWidth / 2, window.innerHeight / 2),
            delay: i * 250,
            numberOfParticles: 36,
          })
        )
      })
    }, 200)
  }

  ngOnInit(): void {
    this.theme = this.route?.snapshot?.queryParams?.theme || 'dark'
    this.renderer.addClass(document.body, this.theme)

    // splash duration is 8 seconds
    this.timeHandle = window.setTimeout(() => {
      this.splashCompleted = true

      if (this.updateState == 'Splash') {
        // let the user login
        this.router.navigate(['/'], {})
      }
    }, 8 * 1000)

    this.unregister = this.electronService.registerHookFor('bebop-auto-update', (event: any, res: any) => {
      let name: AutoUpdateState = res?.name
      this.ngZone.run(() => {
        switch (name) {
          case 'login':
            // we are not sending this event
            break
          case 'checking-for-update':
            this.updateState = 'Checking'
            this.download = 0
            break
          case 'update-available':
            this.updateState = 'Updating'
            this.download = 0
            break
          case 'update-not-available':
            this.router.navigate(['/'], {})
            break
          case 'update-cancelled':
            // we are not capturing this event as of now
            this.router.navigate(['/'], {})
            break
          case 'download-progress':
            this.updateState = 'Updating'
            this.download = Math.floor(((res?.data?.progress?.percent ?? 0) | 0) / 100)
            break
          case 'update-downloaded':
            this.updateState = 'Install'
            break
          case 'error':
            this.updateState = 'Error'
            // what to do next on error ?
            break
          default:
            console.error('Invalid app update state', res)
            break
        }
        console.log('[bebop-auto-update]', name, res?.name, res?.data)
      })
    })

    this.unregister2 = this.electronService.registerHookFor('bebop-patch-update', (event: any, res: any) => {
      let name: AutoUpdateState = res?.name
      this.ngZone.run(() => {
        switch (name) {
          case 'login':
            // we are not sending this event
            break
          case 'checking-for-update':
            this.updateState = 'Checking'
            this.download = 0
            break
          case 'update-available':
            this.updateState = 'UpdatingPatch'
            this.download = 0
            break
          case 'update-not-available':
            this.router.navigate(['/'], {})
            break
          case 'update-cancelled':
            // we are not capturing this event as of now
            this.router.navigate(['/'], {})
            break
          case 'download-progress':
            this.updateState = 'UpdatingPatch'
            this.download = Math.floor(((res?.data?.progress?.percent ?? 0) | 0) / 100)
            break
          case 'update-downloaded':
            this.updateState = 'InstallPatch'
            break
          case 'error':
            this.updateState = 'ErrorPatch'
            // what to do next on error ?
            break
          default:
            console.error('Invalid app update state', res)
            break
        }
        console.log('[bebop-patch-update]', name, res?.name, res?.data)
      })
    })

    this.electronService.checkForUpdates()
  }
}
