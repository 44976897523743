<div class="bebop-select-rocket-upload-path">
  <div class="header h2">{{title}}</div>

  <div class="browse-area">
    <div class="browse-panel-container">
      <bebop-server-status status="Loading" *ngIf="loading"></bebop-server-status>
      <div class="browse-panel">

        <div class="project-select" *ngIf="data.archive">
          <bebop-input-select
            placeholder="Select Project…"
            [value]="selectedProject?.name"
            [onSearch]="onProjectSearch"
            (dropdownState)="onDropdownProjectChange($event)"
          >
            <div dropdown-options>
              <bebop-input-select-option *ngFor="let project of projects; let total = count" (click)="onSelectProject(project)">
                <div option-text>
                  {{ project?.name }}
                </div>
              </bebop-input-select-option>
            </div>
          </bebop-input-select>
        </div>

        <div class="browse-project" *ngIf="projectName">
          <svg
            class="nav-up-icon"
            (click)="onNavUp()"
            [class.disabled]="navTree.length <= 1"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            ng-click="goDirectoryUp()"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 6C4 4.89543 4.89543 4 6 4H8.75C9.06476 4 9.36115 4.14819 9.55 4.4L10.6 5.8C11.1666 6.55542 12.0557 7 13 7H18C19.1046 7 20 7.89543 20 9V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6ZM6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V9C22 6.79086 20.2091 5 18 5H13C12.6852 5 12.3889 4.85181 12.2 4.6L11.15 3.2C10.5834 2.44458 9.69427 2 8.75 2H6ZM12.7071 10.2929C12.3166 9.90237 11.6834 9.90237 11.2929 10.2929L8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071C8.68342 15.0976 9.31658 15.0976 9.70711 14.7071L11 13.4142V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V13.4142L14.2929 14.7071C14.6834 15.0976 15.3166 15.0976 15.7071 14.7071C16.0976 14.3166 16.0976 13.6834 15.7071 13.2929L12.7071 10.2929Z"
              fill="#5A5A68"
            />
          </svg>
          <div class="browse-breadcrumb">
            <div
              class="name tw-cursor-pointer h5"
              *ngFor="let segment of navTree; let i = index"
              (click)="goBackDirectory(segment)"
            >
              {{ segment?.name || '' }}
              <div class="slash">/</div>
            </div>
          </div>
          <div class="browse-action">
            <!-- <svg class="plus-icon" (click)="onAddTrigger()" viewBox="0 0 24 24" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm0 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 0 1 1-1Z"
                fill="#5A5A68"
              />
            </svg> -->

            <svg class="refresh-icon" (click)="onRefresh()" viewBox="0 0 24 24" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.75-.6c0-1.902 1.813-3.65 4.25-3.65 1.264 0 2.351.592 3.156 1.236l.08.064H14.6a.75.75 0 0 0 0 1.5H17a.75.75 0 0 0 .75-.75V7a.75.75 0 0 0-1.5 0v.942a8.595 8.595 0 0 0-.157-.128C15.15 7.058 13.736 6.25 12 6.25c-3.086 0-5.75 2.252-5.75 5.15a.75.75 0 0 0 1.5 0Zm10 1.2a.75.75 0 0 0-1.5 0c0 1.902-1.813 3.65-4.25 3.65-1.264 0-2.351-.592-3.156-1.236a7.164 7.164 0 0 1-.08-.064H9.4a.75.75 0 0 0 0-1.5H7a.75.75 0 0 0-.75.75V17a.75.75 0 0 0 1.5 0v-.942l.156.128c.945.756 2.358 1.564 4.094 1.564 3.086 0 5.75-2.252 5.75-5.15Z"
                fill="#5A5A68"
              />
            </svg>
          </div>
        </div>

        <div class="browse-search">
          <bebop-input-search
            placeholder="Search current directory"
            [(binding)]="search"
            (on-keyup)="onSearch($event)"
          ></bebop-input-search>
        </div>

        <div class="browse-list">
          <div class="browse-folder-label title" (click)="toggleSort()">
            Folder name
            <svg viewBox="0 0 16 16" fill="none" *ngIf="!pageOptions?.sort.reverse">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.854 6.146a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L8 9.293l3.146-3.147a.5.5 0 0 1 .708 0Z"
                fill="#7F7F8C"
              />
            </svg>

            <svg viewBox="0 0 24 24" fill="none" *ngIf="pageOptions?.sort.reverse">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7803 15.5303C18.0732 15.2374 18.0732 14.7626 17.7803 14.4697L12.5303 9.21967C12.2374 8.92678 11.7626 8.92678 11.4697 9.21967L6.21967 14.4697C5.92678 14.7626 5.92678 15.2374 6.21967 15.5303C6.51256 15.8232 6.98744 15.8232 7.28033 15.5303L12 10.8107L16.7197 15.5303C17.0126 15.8232 17.4874 15.8232 17.7803 15.5303Z"
                fill="#7F7F8C"
              />
            </svg>
          </div>

          <div class="browse-file-list">
            <div class="add-folders" [class.loading]="loading" *ngIf="!data.glacier">
              <div class="folder-item">
                <div class="folder-icon" (click)="onAddTrigger()">
                  <svg *ngIf="!isAddState" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.66667 2.66667C8.66667 2.29848 8.36819 2 8 2C7.63181 2 7.33333 2.29848 7.33333 2.66667L7.33333 7.33333H2.66667C2.29848 7.33333 2 7.63181 2 8C2 8.36819 2.29848 8.66667 2.66667 8.66667H7.33333V13.3333C7.33333 13.7015 7.63181 14 8 14C8.36819 14 8.66667 13.7015 8.66667 13.3333V8.66667H13.3333C13.7015 8.66667 14 8.36819 14 8C14 7.63181 13.7015 7.33333 13.3333 7.33333H8.66667V2.66667Z"
                      fill="#4574F5"
                    />
                  </svg>

                  <svg *ngIf="isAddState" viewBox="0 0 16 16" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 2.667c-.736 0-1.333.597-1.333 1.333v8c0 .736.597 1.334 1.333 1.334h8c.736 0 1.333-.598 1.333-1.334V6.25c0-.736-.597-1.333-1.333-1.333H8.714a2 2 0 0 1-1.665-.89l-.708-1.063a.667.667 0 0 0-.555-.297H4ZM1.333 4A2.667 2.667 0 0 1 4 1.333h1.786a2 2 0 0 1 1.665.891l.708 1.063a.667.667 0 0 0 .555.296H12a2.667 2.667 0 0 1 2.667 2.667V12A2.667 2.667 0 0 1 12 14.667H4A2.667 2.667 0 0 1 1.333 12V4Z"
                      [attr.fill]="errorFolder ? 'rgba(255, 255, 255, 0.1)' : '#4574F5'"
                    />
                  </svg>
                </div>

                <div class="editor" *ngIf="isAddState">
                  <bebop-input
                    noInputLabel="true"
                    placeholder="Folder name"
                    [(binding)]="folderName"
                    (on-keyup)="onKeyUpFolder($event)"
                    [error]="errorFolder"
                    autofocus="true"
                    [bebop-tooltip]="errorFolder ? tooltipErrMsg : ''"
                    bebop-tooltip-placement="bottom"
                    bebop-tooltip-offset="2"
                    bebop-tooltip-width="150"
                    bebop-tooltip-show-delay="0"
                    bebop-tooltip-contained-layout="true"
                    tabindex="0"
                  >
                  </bebop-input>
                </div>

                <div class="editor-action" *ngIf="isAddState">
                  <bebop-link-button
                    underline="false"
                    (on-click)="onAddFolder()"
                    class="add-new"
                    [class.error]="errorFolder"
                  >
                    <svg width="16" height="16" class="tick-icon" viewBox="0 0 16 16" fill="none">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.106 4.165a.667.667 0 0 1 .063.94l-5.834 6.667a.667.667 0 0 1-.938.065l-3.5-3.03A.667.667 0 0 1 3.77 7.8l2.998 2.596 5.397-6.167a.667.667 0 0 1 .94-.063Z"
                        fill="#32BA78"
                      />
                    </svg>

                    Add
                  </bebop-link-button>

                  <bebop-link-button underline="false" (on-click)="onClearFolder()" class="clear-new">
                    <svg
                      width="16"
                      height="16"
                      class="clear-icon"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5285 3.52876C11.7889 3.26841 12.211 3.26841 12.4713 3.52876C12.7317 3.78911 12.7317 4.21122 12.4713 4.47157L8.94273 8.00016L12.4713 11.5288C12.7317 11.7891 12.7317 12.2112 12.4713 12.4716C12.211 12.7319 11.7889 12.7319 11.5285 12.4716L7.99992 8.94297L4.47132 12.4716C4.21097 12.7319 3.78886 12.7319 3.52851 12.4716C3.26816 12.2112 3.26816 11.7891 3.52851 11.5288L7.05711 8.00016L3.52851 4.47157C3.26817 4.21122 3.26817 3.78911 3.52851 3.52876C3.78886 3.26841 4.21097 3.26841 4.47132 3.52876L7.99992 7.05735L11.5285 3.52876Z"
                        fill="#7F7F8C"
                      />
                    </svg>

                    Cancel
                  </bebop-link-button>
                </div>

                <div class="folder-name paragraph" (click)="onAddTrigger()" *ngIf="!isAddState">Add New Folder</div>
              </div>
            </div>
            <div class="empty-folder" *ngIf="!loading && !pageOptions?.list?.length">
              <svg viewBox="0 0 128 128" fill="none">
                <circle cx="64" cy="64" r="63.5" fill="#2B2B33" stroke="url(#paint0_linear_795_55457)" />
                <mask
                  id="mask0_795_55457"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="128"
                  height="128"
                >
                  <circle
                    cx="64"
                    cy="64"
                    r="63.5"
                    fill="url(#paint1_linear_795_55457)"
                    stroke="url(#paint2_linear_795_55457)"
                  />
                </mask>
                <g mask="url(#mask0_795_55457)">
                  <circle cx="75" cy="42" r="3" fill="#5A5A68" />
                  <circle cx="87" cy="42" r="3" fill="#5A5A68" />
                  <circle cx="99" cy="42" r="3" fill="#5A5A68" />
                </g>
                <mask
                  id="mask1_795_55457"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="128"
                  height="128"
                >
                  <circle
                    cx="64"
                    cy="64"
                    r="63.5"
                    fill="url(#paint3_linear_795_55457)"
                    stroke="url(#paint4_linear_795_55457)"
                  />
                </mask>
                <g mask="url(#mask1_795_55457)">
                  <path
                    d="M138.061 70H93.5607C89.4185 70 86.0607 73.3579 86.0607 77.5V81C86.0607 83.2091 84.2698 85 82.0607 85V85C79.8515 85 78.0607 83.2091 78.0607 81V69.5C78.0607 66.4624 75.5982 64 72.5607 64V64C69.5231 64 67.0607 66.4624 67.0607 69.5V92.6667C67.0607 95.6122 64.6729 98 61.7273 98V98C58.0454 98 55.0607 95.0152 55.0607 91.3333V58.2803C55.0607 55.3641 52.6966 53 49.7803 53V53C46.8641 53 44.5 55.3641 44.5 58.2803V61.5C44.5 66.1944 40.6944 70 36 70H2"
                    stroke="#7F7F8C"
                    stroke-width="3"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_795_55457"
                    x1="1.76688"
                    y1="0.73968"
                    x2="42.9163"
                    y2="147.664"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.08" />
                    <stop offset="1" stop-color="white" stop-opacity="0.04" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_795_55457"
                    x1="113"
                    y1="20.5"
                    x2="55.5"
                    y2="57.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2B2B33" />
                    <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
                    <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_795_55457"
                    x1="1.76688"
                    y1="0.73968"
                    x2="42.9163"
                    y2="147.664"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.08" />
                    <stop offset="1" stop-color="white" stop-opacity="0.04" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_795_55457"
                    x1="96.5"
                    y1="37"
                    x2="27"
                    y2="77.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2B2B33" />
                    <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
                    <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
                    <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
                    <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_795_55457"
                    x1="128"
                    y1="102.4"
                    x2="2.27386"
                    y2="96.9895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.04" />
                    <stop offset="1" stop-color="white" stop-opacity="0.08" />
                  </linearGradient>
                </defs>
              </svg>

              <div class="empty-folder-label paragraph">Empty</div>
            </div>

            <ng-container *ngIf="pageOptions?.list?.length">
              <cdk-virtual-scroll-viewport [itemSize]="pageOptions.size" class="folder-list-section">
                <div class="folder-item" *cdkVirtualFor="let folder of pageOptions?.list" (click)="selectFolder(folder)">
                  <div class="folder-icon">
                    <svg viewBox="0 0 16 16" fill="none">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4 2.667c-.736 0-1.333.597-1.333 1.333v8c0 .736.597 1.334 1.333 1.334h8c.736 0 1.333-.598 1.333-1.334V6.25c0-.736-.597-1.333-1.333-1.333H8.714a2 2 0 0 1-1.665-.89l-.708-1.063a.667.667 0 0 0-.555-.297H4ZM1.333 4A2.667 2.667 0 0 1 4 1.333h1.786a2 2 0 0 1 1.665.891l.708 1.063a.667.667 0 0 0 .555.296H12a2.667 2.667 0 0 1 2.667 2.667V12A2.667 2.667 0 0 1 12 14.667H4A2.667 2.667 0 0 1 1.333 12V4Z"
                        fill="#5A5A68"
                      />
                    </svg>
                  </div>

                  <div class="folder-name paragraph">
                    {{ folder?.name }}
                  </div>
                </div>
              </cdk-virtual-scroll-viewport>
            </ng-container>

            <!-- no pagination - response has full data set - system native call for listing directory -->
            <!-- <div class="folder-list-section" *ngIf="pageOptions?.list?.length">
              <div class="folder-item" *ngFor="let folder of pageOptions?.list" (click)="selectFolder(folder)">
                <div class="folder-icon">
                  <svg viewBox="0 0 16 16" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 2.667c-.736 0-1.333.597-1.333 1.333v8c0 .736.597 1.334 1.333 1.334h8c.736 0 1.333-.598 1.333-1.334V6.25c0-.736-.597-1.333-1.333-1.333H8.714a2 2 0 0 1-1.665-.89l-.708-1.063a.667.667 0 0 0-.555-.297H4ZM1.333 4A2.667 2.667 0 0 1 4 1.333h1.786a2 2 0 0 1 1.665.891l.708 1.063a.667.667 0 0 0 .555.296H12a2.667 2.667 0 0 1 2.667 2.667V12A2.667 2.667 0 0 1 12 14.667H4A2.667 2.667 0 0 1 1.333 12V4Z"
                      fill="#5A5A68"
                    />
                  </svg>
                </div>

                <div class="folder-name paragraph">
                  {{ folder?.name }}
                </div>
              </div>
            </div> -->

            <div
              class="folder-navigation"
              *ngIf="!noPagination && !loading && pageOptions?.list?.length && pageOptions.total > pageOptions.size"
            >
              <bebop-pagintion
                [total]="pageOptions.total"
                [page]="pageOptions.page"
                [size]="pageOptions.size"
                (pageChange)="onPageChange($event)"
              ></bebop-pagintion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="browse-bottom-action">
      <bebop-button class="cancel" size="large" outlined="true" (on-click)="cancel()">Cancel</bebop-button>
      <bebop-button class="select" size="large" (on-click)="select()">Select</bebop-button>
    </div>
  </div>
</div>
