<div class="table-actions">
  <cree8-btn-icon ariaLabel="Copy link" cree8-tooltip-text="Copy" cree8-tooltip-position="top"
    (click)="onAction('copy')">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Copy"></use>
    </svg>
  </cree8-btn-icon>
  <cree8-btn-icon ariaLabel="Link expiry" cree8-tooltip-text="Expiry" cree8-tooltip-position="top"
    (click)="onAction('expiry')">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Calendar"></use>
    </svg>
  </cree8-btn-icon>
  <cree8-btn-icon ariaLabel="Link security" cree8-tooltip-text="Protect" cree8-tooltip-position="top"
    (click)="onAction('protect')">
    <svg class="ico" viewBox="0 0 24 24" style="color:var(--message-success)">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Padlock_Locked"></use>
    </svg>
  </cree8-btn-icon>
  <cree8-btn-icon ariaLabel="Link settings" cree8-tooltip-text="Settings" cree8-tooltip-position="top"
    (click)="onAction('settings')">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Settings"></use>
    </svg>
  </cree8-btn-icon>
  <cree8-btn-icon ariaLabel="Link status" cree8-tooltip-text="Status" cree8-tooltip-position="top"
    (click)="onAction('status')">
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Info"></use>
    </svg>
  </cree8-btn-icon>
  <!-- <toggle (toggleChange)="toggleChange.emit($event)" [isActive]="isActive"></toggle> -->
  <cree8-toggle (checkedChange)="toggleChange.emit($event)" [checked]="isActive" [ariaLabel]="'Toggle'"></cree8-toggle>

</div>
