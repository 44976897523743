<div class="bebop-login-banners">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'Email2FA'" [ngTemplateOutlet]="email2fa"></ng-container>
    <ng-container *ngSwitchCase="'OAuth'" [ngTemplateOutlet]="oauth"></ng-container>
  </ng-container>

  <div class="click-section paragraph">
    <bebop-link-button (on-click)="logIn()">Click here</bebop-link-button> to Log In with different account.
  </div>

  <ng-template #email2fa>
    <div class="email2fa-section">
      <div class="banner-logo">
        <svg viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="56" cy="56" r="55.5" fill="#2B2B33" stroke="url(#paint0_linear_1863_27478)" />
          <mask
            id="mask0_1863_27478"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="112"
            height="112"
          >
            <circle
              cx="56"
              cy="56"
              r="55.5"
              fill="url(#paint1_linear_1863_27478)"
              stroke="url(#paint2_linear_1863_27478)"
            />
          </mask>
          <g mask="url(#mask0_1863_27478)">
            <path
              d="M65.0633 41.5949V55.2276C65.0633 60.8029 60.5436 65.3225 54.9684 65.3225V65.3225C49.3931 65.3225 44.8735 60.8029 44.8735 55.2276V54.2662C44.8735 48.6909 49.3931 44.1713 54.9684 44.1713V44.1713C60.5436 44.1713 65.0633 48.6909 65.0633 54.2662V58.0687C65.0633 61.8094 68.0957 64.8418 71.8364 64.8418V64.8418C75.5771 64.8418 78.6096 61.8094 78.6096 58.0687V53.3048C78.6096 40.2958 68.0637 29.75 55.0548 29.75V29.75C42.0458 29.75 31.5 40.2958 31.5 53.3048V56.2263C31.5 68.6837 41.5987 78.7824 54.0562 78.7824V78.7824C58.7897 78.7824 63.4252 77.4326 67.4187 74.8912L70.5991 72.8673C71.4354 72.3352 72.4061 72.0525 73.3974 72.0525V72.0525C76.276 72.0525 78.6096 74.386 78.6096 77.2646V85.5124C78.6096 87.6363 80.3313 89.358 82.4552 89.358V89.358C84.5792 89.358 86.3009 87.6363 86.3009 85.5124V69.1682C86.3009 67.0443 88.0227 65.3225 90.1466 65.3225V65.3225C92.2705 65.3225 93.9923 67.0443 93.9923 69.1682V73.0139C93.9923 77.2617 97.4358 80.7053 101.684 80.7053H109.375"
              stroke="#7F7F8C"
              stroke-width="3"
              stroke-linecap="round"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_1863_27478"
              x1="1.54602"
              y1="0.64722"
              x2="37.5517"
              y2="129.206"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" stop-opacity="0.08" />
              <stop offset="1" stop-color="white" stop-opacity="0.04" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1863_27478"
              x1="84.4375"
              y1="32.375"
              x2="23.625"
              y2="67.8125"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2B2B33" />
              <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
              <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
              <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
              <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1863_27478"
              x1="112"
              y1="89.6"
              x2="1.98962"
              y2="84.8658"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" stop-opacity="0.04" />
              <stop offset="1" stop-color="white" stop-opacity="0.08" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div class="h4 banner-label">We have sent you an email at</div>
      <div class="h4 email-label">{{ data?.sentTo }}</div>
      <div class="h4 banner-label">Look for the steps on the email to authorize the login</div>
    </div>
  </ng-template>
  <ng-template #oauth>
    <div class="oauth-section">
      <div class="banner-logo">
        <img src="/assets/img/login/login_sso.gif" />
      </div>

      <div class="h4">Please visit your default system browser</div>
      <div class="h4">to continue login with SSO.</div>
    </div>
  </ng-template>
</div>
