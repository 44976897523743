import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Output } from '@angular/core'

@Directive({
  selector: '[bebopDragAndDrop]',
})
export class DragDropDirective {
  @HostBinding('class.drag') drag: boolean
  @Output('on-drop') onDropFiles = new EventEmitter<FileList>()

  @HostListener('dragenter', ['$event']) onDragEnter(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.drag = true
  }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.drag = true
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.drag = this.el.nativeElement.contains(evt.relatedTarget)
  }

  @HostListener('drop', ['$event']) ondrop(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    this.drag = false
    let files = evt.dataTransfer.files
    if (files.length > 0) {
      this.onDropFiles.emit(files)
    }
  }

  constructor(private el: ElementRef) {}
}