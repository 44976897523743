import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'

export interface CancelResumeActionModalAction {
  name: 'Yes' | 'No'
}

@Component({
  selector: 'bebop-cancel-resume-action-modal',
  templateUrl: './cancel-resume-action-modal.component.html',
  styleUrls: ['./cancel-resume-action-modal.component.scss'],
})
export class CancelResumeActionModalComponent implements OnInit {
  constructor(
    public ref: ModalOverlayRef<CancelResumeActionModalComponent, CancelResumeActionModalAction>,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.send({ name: 'No' })
    this.ref.close()
  }

  confirm() {
    this.ref.send({ name: 'Yes' })
    this.ref.close()
  }
}
