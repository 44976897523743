import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'

import { ITeamState, TeamStore } from './team.store'

@Injectable({ providedIn: 'root' })
export class TeamQuery extends Query<ITeamState> {
  constructor(protected store: TeamStore) {
    super(store)
  }

  selectAll() {
    return this.select((state) => state)
  }

  selectActiveTab() {
    return this.select('activeTab')
  }
}
