import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core'

@Component({
  selector: 'cree8-toggle',
  styleUrl: './cree8-toggle.component.scss',
  templateUrl: './cree8-toggle.component.html',
})
export class Cree8ToggleComponent {
  @Input() checked = false
  @Input() ariaLabel = ''
  @Output() checkedChange = new EventEmitter<boolean>()

  onToggle() {
    this.checked = !this.checked
    this.checkedChange.emit(this.checked)
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault()
      this.onToggle()
    }
  }
}
