import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'

import Datepicker from '../../classes/lib/tw-elements/datepicker'

@Component({
  selector: 'bebop-input-date',
  styleUrls: ['./input-date.component.scss'],
  templateUrl: './input-date.component.html',
})
export class InputDateComponent implements OnInit, AfterViewInit {
  @ViewChild('datePicker') datePicker

  @Input('date') date: Date

  @Output('onChange') onChange = new EventEmitter<Date>()

  @Input('options') options: { [key: string]: string | boolean | number | Date }

  get dateStr() {
    return this.date?.toDateString() || ''
  }

  constructor() {}

  ngOnInit(): void {
    this.date = this.date || new Date()
  }

  ngAfterViewInit(): void {
    let dom = this.datePicker?.nativeElement
    dom?.addEventListener('dateChange.bebop.datepicker', (d: { date: Date }) => this.onChangeEvent(d.date))
  }

  onChangeEvent(d: Date) {
    this.onChange.emit(d)
  }

  openDatePicker() {
    if (this.datePicker?.nativeElement) {
      let dp = Datepicker.getInstance(this.datePicker?.nativeElement)
      dp =
        dp ||
        new Datepicker(this.datePicker?.nativeElement, {
          ...(this.options || {}),
        })

      dp?.open()
    }
  }
}
