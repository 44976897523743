import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

import { Organization, OrgEntitlements, Pod, User } from 'src/app/models/bebop.model'
import { AnnouncementsResponse } from 'src/app/models/response.model'
import { IDashboardCache } from 'src/app/models/session.model'

export type BebopClientAnnouncement = AnnouncementsResponse['bebopClient']

export interface SessionState {
  loggedIn: boolean
  user: User
  organizations: Organization[]
  dashboards: { [orgId: string]: IDashboardCache }
  pods: Pod[]
  entitlements: OrgEntitlements
  announcements?: BebopClientAnnouncement
  token: string
}

export function createInitialState(): SessionState {
  return {
    announcements: null,
    dashboards: {},
    entitlements: null,
    loggedIn: false,
    organizations: [],
    pods: [],
    token: null,
    user: null,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState())
  }
}
