import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from './bebop-config.service'

@Injectable({
  providedIn: 'root',
})
export class EntitlementsService {
  constructor(
    private bebopConfig: BebopConfigService,
    private http: HttpClient
  ) {}

  getEntitlements(params?: any): Observable<any> {
    if (!params) {
      params = {}
    }

    let httpParams = new HttpParams()
    httpParams = httpParams.appendAll(params)

    return this.http.get<any>(`${this.bebopConfig.apiUrl}/api/v1/entitlements`, { params: httpParams }).pipe(
      catchError((error: any) => {
        console.error('On getEntitlements', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  getEntitlementGroupsForOrg(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.bebopConfig.apiUrl}/api/v1/entitlement_organization_group/organization/${id}`)
      .pipe(
        catchError((error: any) => {
          console.error('On getEntitlementGroupsForOrg', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }
}
