import { Component, Inject, Injector, OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { interval, Observable, Subscription, take, timer } from 'rxjs'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { LinkAction } from 'src/app/common/enums'
import { ComponentModalService } from 'src/app/common/services/component-modal.service'
import { PasswordMatcher, UtilsService } from 'src/app/common/services/utils.service'
import { EULAInfo, EulaRegion, LoginInfo, Provider, ReleaseNote } from 'src/app/models/bebop.model'
import { LoginRequest } from 'src/app/models/request.model'
import { LoginProviderResponse, LoginResponse, PasswordResetResponse } from 'src/app/models/response.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { MainService } from 'src/app/services/main.service'
import { RecaptchaService } from 'src/app/services/recaptcha.browser.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { SessionService } from 'src/app/store/session/session.service'
import { BebopClientAnnouncement } from 'src/app/store/session/session.store'
import { UIService } from 'src/app/store/ui/ui.service'

import { EulaModalAction, EulaModalComponent } from './modal/eula-modal/eula-modal.component'
import { ReleaseModalAction, ReleaseModalComponent } from './modal/release-modal/release-modal.component'

enum LogInState {
  Login,
  LoginWithPassword,
  LoginOAuth,
  LoginSMS2FA,
  LoginEmail2FA,
  SetupTOTP2FA,
  LoginTOTP2FA,
  ForgotPassword,
  ResetPassword,
  PasswordChanged,
}

enum LoginType {
  Auto,
  Bebop,
  OAuth,
}

type PollerType = 'bebopsso' | 'bebop2fa'

interface PasswordMatch {
  key: string
  match: boolean
}

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  logInState = LogInState.Login
  loginType = LoginType.Auto
  loading = false
  credentials: LoginInfo = {}
  oAuthProviders: Provider[] = []
  formErrors: { pwd?: string; email?: string; authCode?: string; token?: string } = {}
  loginSuccess: boolean = false
  formInfo: {
    passwordAutoFocus?: boolean
    mobileLast4Digits?: number | string
    showResendCode?: boolean
    emailSent?: boolean
    emailSentTo?: string
    emailSendFailed?: string
    emailSentCount?: number
    totpSetupPending?: boolean
    totpSetupVerified?: boolean
    showTOTPQR?: boolean
    passwordMatch?: PasswordMatch[]
    confirmPasswordMatch?: PasswordMatch[]
    matchWidget?: { password?: boolean; confirmPassword?: boolean }
  } = {}

  selectedProvider: Provider
  pollSubscription: Subscription

  authInfo: string
  authError: string
  passwordMatchRules: PasswordMatcher[] = []

  readonly POLL_INTERVAL = 5 * 1000
  readonly MAX_POLL_REQUESTS = 60
  readonly CODE_RESEND_INTERVAL = 30 * 1000
  releaseRef: ModalOverlayRef<ReleaseModalComponent, ReleaseModalAction>
  eulaRef: ModalOverlayRef<EulaModalComponent, EulaModalAction>
  clientAnnouncements: BebopClientAnnouncement
  loginResponse: LoginResponse

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private modalService: ComponentModalService,
    private util: BebopClientUtilsService,
    private commonUtil: UtilsService,
    private config: BebopConfigService,
    private mainService: MainService,
    private recaptchaService: RecaptchaService,
    private sessionQuery: SessionQuery,
    @Inject(Injector) private readonly injector: Injector
  ) {}

  ngOnInit(): void {
    this.passwordMatchRules = this.commonUtil.getPasswordMatchRules()
    this.clientAnnouncements = this.sessionQuery.getAnnouncementsValue()
    let oldBannerText = this.mainService.getAnnouncementItem('bebop-client:banner:siginin')
    this._bannerText = this.clientAnnouncements?.signin ?? ''

    if (oldBannerText == this._bannerText) {
      this._bannerText = ''
    }
  }

  ngOnDestroy(): void {
    ;[this.eulaRef].forEach((ref) => ref?.close())

    this.pollSubscription?.unsubscribe?.()
  }

  public get logInStateEnum(): typeof LogInState {
    return LogInState
  }

  private get sessionService() {
    return this.injector.get(SessionService, null)
  }

  private get uiService() {
    return this.injector.get(UIService, null)
  }

  private resetFormErrors() {
    this.formErrors.authCode = ''
    this.formErrors.email = ''
    this.formErrors.pwd = ''
  }

  private resetFormInfo() {
    this.formInfo.emailSendFailed = ''
    this.formInfo.emailSent = false
    this.formInfo.emailSentTo = ''
    this.formInfo.showResendCode = false
    this.formInfo.emailSentCount = 0
    this.formInfo.mobileLast4Digits = ''
  }

  showLoginWithPassword() {
    this.logInState = LogInState.LoginWithPassword
    this.loginType = LoginType.Bebop
    this.formInfo.passwordAutoFocus = this.credentials.username && this.credentials.username?.length != 0
  }

  showForgotPassword() {
    this.logInState = LogInState.ForgotPassword
    this.formInfo.emailSent = false
    this.formInfo.emailSentTo = ''
    this.formInfo.emailSendFailed = ''
    this.formInfo.emailSentCount = 0
  }

  showLogin(reset: boolean = false) {
    this.logInState = LogInState.Login
    this.loginType = LoginType.Auto
    if (reset) {
      this.credentials = {}
      this.resetFormErrors()
      this.resetFormInfo()
      this.pollSubscription?.unsubscribe?.()
    }
  }

  showLoginSMS2FA() {
    this.logInState = LogInState.LoginSMS2FA
    const resendTimer = timer(this.CODE_RESEND_INTERVAL)
    resendTimer.subscribe(() => {
      this.formInfo.showResendCode = true
    })
  }

  showLoginEmail2FA() {
    this.logInState = LogInState.LoginEmail2FA
  }

  showSetupTOTP2FA() {
    this.logInState = LogInState.SetupTOTP2FA
  }

  showLoginTOTP2FA() {
    this.logInState = LogInState.LoginTOTP2FA
  }

  get loginWithPassword() {
    return this.logInState == LogInState.LoginWithPassword
  }

  get forgotPassword() {
    return this.logInState == LogInState.ForgotPassword
  }

  get loginWithSSO() {
    return this.logInState == LogInState.Login
  }

  get loginWith2FA() {
    return this.logInState == LogInState.LoginSMS2FA
  }

  get loginWithTOTP() {
    return this.logInState == LogInState.LoginTOTP2FA
  }

  async getLoginProvider() {
    if (!this.formValidate()) return

    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    this.sessionService
      .getLoginProvider({ recaptchaResponse: recaptchaToken, username: this.credentials.username })
      .subscribe((res: LoginProviderResponse) => {
        console.log('res', res)
        this.loading = false
        this.authError = ''
        if (res.error) {
          this.authError = res.error.msg || 'Login failed'
          console.error(res.error)
          return
        } else if (res.type == 'BEBOP') {
          this.showLoginWithPassword()
        } else if (res.type == 'OAUTH') {
          this.loginType = LoginType.OAuth
          this.credentials.correlationKey = this.credentials.auth_key = res.correlationKey
          if (!res.providers || res.providers.length == 0) {
            console.warn('Loging Provider Response is OAuth, but no providers found')
            this.showLoginWithPassword()
            return
          }

          if (res.providers.length == 1) {
            this.loginWithOAuth(res.providers[0])
            return
          }

          this.oAuthProviders = res.providers
        }
      })
  }

  async onLoginWithPassword(ev: Event) {
    if (!this.formValidate()) return

    if (
      this.credentials.username == this.config.settings.rootUser &&
      this.credentials.password == this.config.settings.devToolsSecret
    ) {
      this.sessionService.openDevTools()
      this.credentials = {}
      this.resetFormInfo()
      return
    }

    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    this.sessionService
      .login({
        clientType: 'BEBOP_CLIENT_V4',
        clientVersion: this.config.appVersion,
        password: this.credentials.password,
        recaptchaResponse: recaptchaToken,
        username: this.credentials.username,
      })
      .subscribe((res: LoginResponse) => {
        this.loading = false
        this.authError = ''
        if (res.error) {
          this.authError = res.error.msg
          console.error(res.error)
          return
        }

        this.handleLoginResponse(res, ev)
      })
  }

  loginWithOAuth(provider: Provider) {
    this.sessionService.oAuthLogin(provider.redirectUrl)
    this.logInState = LogInState.LoginOAuth
    this.selectedProvider = provider
    this.pollIfAuthorized('bebopsso')
  }

  continue(ev?: Event) {
    if (this.loginWithPassword) return this.onLoginWithPassword(ev)

    if (this.loginWithSSO) return this.getLoginProvider()

    if (this.loginWith2FA) return this.onConfirm2FACode(ev)

    if (this.loginWithTOTP) return this.onConfirmTOTP2FACode(ev)

    throw new Error('unhandled continue action')
  }

  async onSendResetPasswordLink() {
    this.formInfo.emailSent = false
    this.formInfo.emailSentTo = ''
    this.formInfo.emailSendFailed = ''

    if (this.formInfo.emailSentCount >= 2) {
      this.formInfo.emailSendFailed = 'Email resend limit reached. Please check your email'
      return
    }
    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    this.sessionService
      .sendPasswordResetEmail({ email: this.credentials.username, recaptchaResponse: recaptchaToken })
      .subscribe((res: PasswordResetResponse) => {
        this.loading = false
        if (res.error) {
          this.formInfo.emailSendFailed = res.error.msg
          console.error(res.error)
          return
        }

        this.formInfo.emailSent = true
        this.formInfo.emailSentTo = this.credentials.username
        this.formInfo.emailSentCount = (this.formInfo.emailSentCount ?? 0) + 1
      })
  }

  onLoginSuccess(res: LoginResponse) {
    this.loginSuccess = true
    this.sessionService.updateUserInfo(res.user)
    this.uiService.setCurrentUser(res.user)
    this.waitTillPermissionsFetched()
  }

  waitTillPermissionsFetched() {
    this.loading = true
    let sub: Subscription
    let cancel = this.startFailSafe(() => {
      this.loading = false
      this.mainService.goToDashboard()
    }, 5000)

    sub = this.sessionQuery.getOrganizations().subscribe((orgs) => {
      if (!sub) return
      cancel()
      this.loading = false
      sub.unsubscribe()
      this.mainService.goToDashboard()
    })
  }

  startFailSafe(fn: Function, ts: number = 5000): Function {
    let timeHandle = window.setTimeout(fn, ts)
    return () => window.clearTimeout(timeHandle)
  }

  handleLoginResponse(res: LoginResponse, ev?: Event) {
    if (res.redirect || res.redirectUrl) {
      this.handleRedirects(res)
    } else if (res.errType) {
      this.handleErrorTypes(res, ev)
    } else if (res.user) {
      console.log('Login success', res.user)
      this.onLoginSuccess(res)
    } else {
      console.error('Login failed, unknown response', res)
      this.authError = 'Login failed'
    }
  }

  handleRedirects(res: LoginResponse) {
    if (res.redirectUrl) {
      window.location.href = this.config.apiUrl + res.redirectUrl
      return
    }
    switch (res.redirect) {
      case 'loginsms2fa':
        console.log('Login SMS 2FA')
        this.credentials.auth_key = res.auth_key
        this.formInfo.mobileLast4Digits = res.last4digits
        this.showLoginSMS2FA()
        break
      case 'logintotp2fa':
        console.log('Login TOTP 2FA')
        if (res.totpSetupURI) {
          this.credentials.totpSetupURI = res.totpSetupURI
          this.formInfo.totpSetupPending = true
          this.formInfo.showTOTPQR = true
          this.showSetupTOTP2FA()
          this.registerQRAnimationEvents()
        } else {
          this.showLoginTOTP2FA()
        }

        break
      case 'loginbebop2fa':
        this.credentials.auth_key = res.auth_key
        this.formInfo.emailSentTo = res.masked_email
        this.showLoginEmail2FA()
        this.pollIfAuthorized('bebop2fa')
        break
      case 'canImpersonate':
        this.credentials = {}
        this.resetFormInfo()
        this.authInfo = 'Impersonation Request: Please login again as L1 user.'
        break
    }
  }

  showReleaseNotes(ev: Event, note: ReleaseNote, cb?: (err: Error, data?: ReleaseNote) => void) {
    this.releaseRef = this.modalService.open<ReleaseModalComponent, ReleaseModalAction>(
      ReleaseModalComponent,
      {
        animateFrom: ev?.target as Element,
        data: {
          note,
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )

    this.releaseRef.once().subscribe((e) => {
      // just ok
      cb(null, note)
    })
  }

  acceptEula(ev: Event, eulas: EULAInfo[], cb?: (err: Error, data?: EULAInfo) => void) {
    this.eulaRef = this.modalService.open<EulaModalComponent, EulaModalAction>(
      EulaModalComponent,
      {
        animateFrom: ev?.target as Element,
        data: {
          eulas,
        },
        hasBackdrop: true,
      },
      {
        hasBackdropClick: false,
        hasEscapeClose: false,
        isCentered: true,
      }
    )

    this.eulaRef.once().subscribe((e) => {
      cb(e.name != 'Accept' ? new Error('Eula action: ' + e.name) : null, e.name == 'Accept' ? e.eula : null)
    })
  }

  handleErrorTypes(res: LoginResponse, ev?: Event) {
    switch (res.errType) {
      case 'Eula':
        let eulas = res.eulas
        let region: EulaRegion = (res.region || eulas?.[0]?.region || 'US') as EulaRegion

        let eulaForRegion = eulas // eulas.find(function (eula) {
        //   return eula.region === region
        // })

        // just one
        this.acceptEula(ev, eulas, (err: Error, ae: EULAInfo) => {
          if (err) {
            this.authError = 'Licence Denied'
            this.showLogin(true)
          } else {
            this.onAcceptEULAOrReleaseNotes(ae, null)
            console.log('EULA', eulaForRegion)
          }
        })
        break
      case 'ReleaseNotes':
        console.log('ReleaseNotes')
        this.onAcceptEULAOrReleaseNotes(null, res.releaseNote?.version)
        window.setTimeout(() => this.showReleaseNotes(ev, res.releaseNote, (_err, _note) => {}), 1000)
        break
    }
  }

  async resend2FACode() {
    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    this.sessionService
      .resendSMS2FA({
        auth_key: this.credentials.auth_key,
        recaptchaResponse: recaptchaToken,
        username: this.credentials.username,
      })
      .subscribe((res: LoginResponse) => {
        console.log('res', res)
        this.loading = false
        this.authError = ''
        if (res.error) {
          this.authError = res.error.msg || 'Unable to resend SMS'
          console.error(res.error)
          return
        }

        this.formInfo.showResendCode = false
        this.showLoginSMS2FA()
      })
  }

  /**
   * Check wheather the 2FA code is valid
   * @returns
   */
  async onConfirm2FACode(ev: Event) {
    if (!this.formValidate()) return

    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    this.sessionService
      .loginSMS2FA({
        auth_code: this.credentials.auth_code,
        auth_key: this.credentials.auth_key,
        recaptchaResponse: recaptchaToken,
        username: this.credentials.username,
      })
      .subscribe((res: LoginResponse) => {
        console.log('res', res)
        this.loading = false
        this.authError = ''
        if (res.error) {
          this.authError = res.error.msg || 'Authentication failed, code incorrect or expired'
          console.error(res.error)
          return
        }

        this.handleLoginResponse(res, ev)
      })
  }

  registerQRAnimationEvents() {
    setTimeout(() => {
      document.getElementById('qr1').addEventListener('codeRendered', function listener() {
        document.getElementById('qr1').animateQRCode(function anim(targets, _x, _y, _count, entity) {
          return {
            duration: 500,
            easing: 'cubic-bezier(1, 1, 0,.5)',
            from: entity === 'module' ? Math.random() * 200 : 200,
            targets: targets,
            web: { opacity: [0, 1], scale: [0.5, 1.1, 1] },
          }
        })
      })
    }, 500)
  }

  verifyTOTPSetup() {
    this.formInfo.showTOTPQR = false
  }

  showTOTPVerificationSuccess() {
    this.formInfo.totpSetupPending = false
    this.formInfo.totpSetupVerified = true
  }

  /**
   * Check wheather the token is valid
   * @returns
   */
  async onConfirmTOTP2FACode(ev: Event) {
    if (!this.formValidate()) return

    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    this.sessionService
      .loginTOTP2FA({
        recaptchaResponse: recaptchaToken,
        token: this.credentials.token,
        username: this.credentials.username,
      })
      .subscribe((res: LoginResponse) => {
        console.log('res', res)
        this.loading = false
        this.authError = ''
        if (res.error) {
          this.authError = res.error.msg || 'Authentication failed, code incorrect or expired'
          console.error(res.error)
          return
        }

        if (this.logInState == this.logInStateEnum.SetupTOTP2FA) {
          // Show TOTP setup success message, and continue login on user clicks on continue
          this.formInfo.totpSetupVerified = true
          this.formInfo.totpSetupPending = false
          this.loginResponse = res
          return
        }
        this.handleLoginResponse(res, ev)
      })
  }

  onContinueLoginProcess(ev: Event) {
    this.formInfo.totpSetupVerified = true
    this.handleLoginResponse(this.loginResponse, ev)
  }

  /**
   * Poll if Email 2FA or SSO is authorized
   * @param type
   */
  pollIfAuthorized(type: PollerType) {
    const pollTimer = interval(this.POLL_INTERVAL)
    this.pollSubscription = pollTimer.pipe(take(this.MAX_POLL_REQUESTS)).subscribe(async (val) => {
      let recaptchaToken = await this.recaptchaService.getToken()
      this.sessionService
        .pollAuthStatus(type, {
          auth_key: this.credentials.auth_key,
          recaptchaResponse: recaptchaToken,
          username: this.credentials.username,
        })
        .subscribe((res: LoginResponse) => {
          console.log('res', res)
          this.loading = false
          this.authError = ''
          this.selectedProvider = null

          if (res.error) {
            this.authError = res.error.msg || 'Login failed'
            console.error(res.error)
            return
          }

          if (res.authorized == false) return

          this.pollSubscription.unsubscribe()

          if (res.user) {
            this.loginSuccess = true
            this.onLoginSuccess(res)
          } else if (res.redirect || res.errType) {
            this.handleLoginResponse(res)
          }
        })
    })
  }

  /**
   * User has accepted EULA or Releaes notes, update status to API
   * @param eula
   * @param lastViewedReleaseNote
   */
  async onAcceptEULAOrReleaseNotes(eula: EULAInfo, lastViewedReleaseNote: string) {
    this.loading = true
    let recaptchaToken = await this.recaptchaService.getToken()
    let payload: LoginRequest = {
      clientType: 'BEBOP_CLIENT_V4',
      clientVersion: this.config.appVersion,
      eula: eula?._id,
      lastViewedReleaseNote,
      recaptchaResponse: recaptchaToken,
    }

    payload = { ...payload, password: this.credentials.password, username: this.credentials.username }

    this.sessionService.login.apply(this.sessionService, [payload]).subscribe((res: LoginResponse) => {
      this.loading = false
      this.authError = ''
      if (res.error) {
        this.authError = res.error.msg
        console.error(res.error)
        return
      }

      if (this.loginSuccess) {
        this.onLoginSuccess(res)
        return
      }

      this.handleLoginResponse(res)
    })
  }

  goToSupportPage() {
    this.util.openExternalLink(LinkAction.SUPPORT_PORTAL)
  }

  continueToLogin() {
    // continue after reseting password
    this.logInState = LogInState.LoginWithPassword
  }

  resetUserPassword() {
    if (!this.formValidate()) return
  }

  formValidate(): boolean {
    let ok = true

    if (this.loginWithSSO) {
      if (!this.credentials.username?.length) {
        this.formErrors.email = 'Email is required'
        ok = false
      }
    }

    if (this.loginWithPassword) {
      if (!this.credentials.password?.length) {
        this.formErrors.pwd = 'Password is required'
        ok = false
      }
    }

    if (this.loginWith2FA) {
      this.formErrors.authCode = this.credentials.auth_code?.length ? '' : 'Code is required'
      if (!this.formErrors.authCode)
        this.formErrors.authCode = Number.isNaN(+this.credentials.auth_code?.trim()) ? 'Only numbers are allowed' : ''

      if (this.formErrors.authCode) ok = false
    }

    if (this.loginWithTOTP) {
      this.formErrors.authCode = this.credentials.token?.length ? '' : 'Code is required'
      if (!this.formErrors.authCode)
        this.formErrors.authCode = Number.isNaN(+this.credentials.token?.trim()) ? 'Only numbers are allowed' : ''

      if (this.formErrors.authCode) ok = false
    }

    if (ok) {
      this.formErrors = {}
    }

    return ok
  }

  onKeyupUsername(ev: KeyboardEvent): void {
    if (this.credentials.username) {
      this.formErrors.email = ''
    }

    if (ev.key == 'Enter') {
      this.formErrors.email = this.credentials.username?.length ? '' : 'Email is required'
      this.continue()
      // if (!this.formErrors.email && this.credentials.username && this.loginWithSSO) return this.continue()
    }
  }

  onKeyupPassword(ev: KeyboardEvent): void {
    if (ev.key == 'Enter') {
      this.formErrors.pwd = this.credentials.password?.length ? '' : 'Password is required'
      this.continue()
      // if (!this.formErrors.pwd && this.credentials.username && this.loginWithPassword) return this.continue()
    }
  }

  onKeyupEmail(ev: KeyboardEvent): void {
    if (this.credentials.username) {
      this.formErrors.email = ''
    }

    if (ev.key == 'Enter') {
      this.formErrors.email = this.credentials.username?.length ? '' : 'Email is required'
      if (!this.formErrors.email && this.credentials.username) {
        this.onSendResetPasswordLink()
      }
    }
  }

  onKeyupAuthCode(ev: KeyboardEvent): void {
    if (this.credentials.auth_code && Number.isNaN(+this.credentials.auth_code?.trim())) {
      this.formErrors.authCode = 'Only number(s) are allowed'
      return
    }

    this.formErrors.authCode = this.credentials.auth_code?.length ? '' : 'Code is required'

    if (ev.key == 'Enter') {
      this.continue()
      // if (this.credentials.username && this.loginWithSSO) return this.continue()
    }
  }

  onKeyupAuthToken(ev: KeyboardEvent): void {
    if (this.credentials.token && Number.isNaN(+this.credentials.token?.trim())) {
      this.formErrors.authCode = 'Only number(s) are allowed'
      return
    }

    this.formErrors.token = this.credentials.token?.length ? '' : 'Code is required'

    if (ev.key == 'Enter') {
      this.continue()
    }
  }

  onFocusValidateResetPassword() {
    this.validateResetPassword()
    this.formInfo.matchWidget.password = true
  }

  onBlurValidateResetPassword() {
    this.validateResetPassword()
    this.formInfo.matchWidget.password = false
  }

  onFocusValidateResetConfirmPassword() {
    this.validateResetPassword()
    this.formInfo.matchWidget.confirmPassword = true
  }

  onBlurValidateResetConfirmPassword() {
    this.validateResetPassword()
    this.formInfo.matchWidget.confirmPassword = false
  }

  onKeyupResetConfirmPassword(ev: KeyboardEvent): void {
    this.validateResetPassword()
    this.formInfo.matchWidget.confirmPassword = true
  }

  onKeyupResetPassword(ev: KeyboardEvent): void {
    this.validateResetPassword()
    this.formInfo.matchWidget.password = true
  }

  validateResetPassword() {
    this.formInfo.passwordMatch = []
    this.formInfo.confirmPasswordMatch = []
    this.formInfo.matchWidget = {}

    this.passwordMatchRules?.forEach((r) => {
      if (r.pair) {
        this.formInfo.confirmPasswordMatch.push({
          key: r.label,
          match: r.matcher(this.credentials.password, this.credentials.confirmPassword),
        })
      } else {
        this.formInfo.passwordMatch.push({ key: r.label, match: r.matcher(this.credentials.password) })
        this.formInfo.confirmPasswordMatch.push({ key: r.label, match: r.matcher(this.credentials.confirmPassword) })
      }
    })
  }

  onLoginWithDifferentAccount() {
    this.showLogin(true)
  }

  onCloseBanner() {
    this.mainService.setAnnouncementItem('bebop-client:banner:siginin', this._bannerText)
    this._bannerText = ''
  }

  private _bannerText: string
  get bannerText() {
    return this._bannerText ?? ''
  }

  get hasBanner() {
    return !!this._bannerText
  }

  loginSSO(type: 'google' | 'windows' | 'adobe') {
    let url = ''

    if (type == 'adobe') url = 'api/v1/oauth2/adobe/continue'
    if (type === 'google') url = 'api/v1/oauth2/google/continue'
    if (type === 'windows') url = 'api/v1/oauth2/microsoft/continue'

    this.sessionService.loginSSO(url).subscribe((res: LoginProviderResponse) => {
      this.credentials.correlationKey = this.credentials.auth_key = res.correlationKey
      this.loginWithOAuth({name: type, provider: type, redirectUrl: res.redirectUrl})
    })
  }

  checkIdentifier() {
    return this.config.settings?.type
  }

  showSignUp() {
    window.location.replace(this.config.storeUrl)
  }
}
