import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'
import {
  FilterWorkstationActionPayload,
  UiWorkstation,
  UiWorkstationGroup,
  WorkstationFilterApplyDefault,
  WorkstationWidgetHandle,
} from 'src/app/components/workstation/classes/workstation-types'
import { CastQuality, Workstation, WorkstationGroup } from 'src/app/models/bebop.model'
import { NdiStatus, StreamQuality } from 'src/app/models/ui.model'

export const WORKSTATION_RESTART_INTERVAL = 15 * 1000
export const MAX_JOB_REQUEST_RETRY = 3
export const MAX_JOB_REQUEST_RETRY_INTERVAL = 5000

export type WorkstationJobs = 'AUTO_RETRY_WORKSTATION_CAPACITY_ISSUE_RESOLVED'
export type RetryWorkstationState = 'Init' | 'Retrying' | 'Failed' | 'Success' | 'Queued'
export interface RetryWorkstationLog {
  status: RetryWorkstationState
  retryTime: Date
  retryEndTime?: Date
  // failure could be insufficient capacity or something else like workstation is no longer exists
  failedMessage?: string
}

export interface RetryWorkstation {
  workstation: Workstation
  retryTill: Date
  durationInMinutes: number
  retryFailedLog: RetryWorkstationLog[]
}

export interface WorkstationTabInfo {
  // for tab support
  allWorkstations?: UiWorkstation[]
  filterApply?: FilterWorkstationActionPayload
  searchText?: string
  groups?: UiWorkstationGroup[]
  workstationGroups?: WorkstationGroup[]

  favorites?: UiWorkstation[]
  parentWidget?: WorkstationWidgetHandle
}
export interface WorkstationState {
  loaderWorkstations: {
    [key: string]: boolean
  }

  activeWorkstationSessions?: number
  activeWorkstations?: {
    [key: string]: any
  }

  ndi?: NdiStatus

  retryWorkstations?: RetryWorkstation[]

  tabInfo?: WorkstationTabInfo
}

export function createInitialState(): WorkstationState {
  return {
    loaderWorkstations: {},
    activeWorkstationSessions: 0,
    activeWorkstations: {},
    retryWorkstations: [],
    ndi: {
      castActive: false,
      quality: StreamQuality.SQ,
      message: '',
      workstation: null,
      castOperator: null,
    },
    // for tab support
    tabInfo: {
      allWorkstations: [],
      filterApply: WorkstationFilterApplyDefault,
      searchText: '',
      workstationGroups: [],
      favorites: [],
      parentWidget: null,
    },
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workstation-store' })
export class WorkstationStore extends Store<WorkstationState> {
  constructor() {
    super(createInitialState())
  }
}
