import { Component } from '@angular/core';

@Component({
  selector: 'social-media',
  templateUrl: './social-media.component.html',
  styleUrl: './social-media.component.scss'
})
export class SocialMediaComponent {

}
