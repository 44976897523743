<label class="cree8-checkbox">
  <input type="checkbox" [(ngModel)]="isChecked" (ngModelChange)="onCheckboxChange($event)" />
  <span class="cree8-checkbox-box">
    <svg *ngIf="isChecked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15_6736)">
        <path d="M20 6.5L9 17.5L4 12.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_15_6736">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
  <span class="checkbox-label body3">{{ label }}</span>
</label>
