import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'navigation-back',
  templateUrl: './navigation-back.component.html',
  styleUrls: ['./navigation-back.component.scss'],
})
export class NavigationBackComponent implements OnInit {
  @Output() back: EventEmitter<void> = new EventEmitter<void>()
  constructor() {}

  ngOnInit(): void {}
}
