<div class="cree8-chip" [style.background-color]="bgColor" [class.error]="isError" [class.closeBtn]="canClose">
  <div class="icon" #tagIcon [style.color]="color" *ngIf="hasIcon">
    <ng-content select="[tag-icon]"></ng-content>
  </div>
  <div class="body3" [style.color]="color">
    <ng-content></ng-content>
  </div>
  <div class="close-icon" *ngIf="canClose" (click)="onCloseEvent($event)">
    <svg class="ico" viewBox="0 0 24 24">
      <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Xmark_Single'"></use>
    </svg>
  </div>
</div>
