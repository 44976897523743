import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { EFormat } from 'src/app/common/components/cree8-card-plan/types'
import { LinkAction } from 'src/app/common/enums'
import { OrgEntitlements } from 'src/app/models/bebop.model'
import { IOrgSubscriptionDetails, IOrgUsage } from 'src/app/models/organization.model'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { MainService } from 'src/app/services/main.service'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { UserService } from 'src/app/services/user.service'
import { SessionQuery } from 'src/app/store/session/session.query'
import { BebopClientAnnouncement } from 'src/app/store/session/session.store'
import { UIQuery } from 'src/app/store/ui/ui.query'
import { environment } from 'src/environments/environment'
@Component({
  selector: 'bebop-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  EFormat = EFormat
  environment = environment
  uiMembers = { available: false, desc: '', format: EFormat.DEFAULT, info: '' }
  loading = false
  selectedOrg: any
  entitlements: OrgEntitlements
  clientAnnouncements: BebopClientAnnouncement
  private _bannerText: string
  downloadCardClose: boolean = false
  orgUsage: any = {}
  subscriptionDetails: IOrgSubscriptionDetails
  // Subject to signal when to unsubscribe
  private destroy$ = new Subject<void>()

  get hasValidSubscription() {
    return this.entitlements?.[this.selectedOrg?._id] ? true : false
  }

  get isOrgAdmin() {
    return this.entitlements?.[this.selectedOrg?._id]?.ADMIN_ORGANIZATION ?? false
  }

  constructor(
    private organizationsService: OrganizationsService,
    private userService: UserService,
    private mainService: MainService,
    private sessionQuery: SessionQuery,
    private uiQuery: UIQuery,
    private cdRef: ChangeDetectorRef,
    private util: BebopClientUtilsService,
    private router: Router
  ) {
    const isCardClosed = sessionStorage.getItem('downloadCardClose')
    this.downloadCardClose = isCardClosed === 'true'
  }

  ngOnInit() {
    this.sessionQuery
      .getAnnouncements()
      .pipe(takeUntil(this.destroy$))
      .subscribe((announcements) => {
        this.clientAnnouncements = announcements
        const oldBannerText = this.mainService.getAnnouncementItem('bebop-client:banner:dashboard')
        this._bannerText = this.clientAnnouncements?.dashboard ?? ''

        if (oldBannerText == this._bannerText) {
          this._bannerText = ''
        }
      })

    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (!org) {
          return
        }
        this.sessionQuery
          .getEntitlements()
          .pipe(takeUntil(this.destroy$))
          .subscribe((entitlements) => (this.entitlements = entitlements))

        this.resetState()
        this.selectedOrg = org
        if (!this.selectedOrg.suspended && this.hasValidSubscription) {
          this.loading = true
          this.loadDashboardData()
        }
      })
  }

  loadDashboardData() {
    this.loadData()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  loadData() {
    let pendingRequests = 4

    const completeLoading = () => {
      pendingRequests--
      if (pendingRequests === 0) {
        this.loading = false
      }
    }

    const handleError = (error: any) => {
      completeLoading()
    }

    this.userService
      .getUsers({
        page: 1,
        searchText: '',
        size: 100,
        sort: 'name',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: handleError,
        next: (users) => {
          const totalUsers = users?.data?.length || 0
          this.uiMembers.available = true
          this.uiMembers.info = `${totalUsers} ${totalUsers === 1 ? 'member' : 'members'}`
          //if only you then show add more
          this.uiMembers.format = totalUsers > 1 ? EFormat.DEFAULT : EFormat.SETUP
          completeLoading()
        },
      })

    this.organizationsService
      .usersOnlineCount()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: handleError,
        next: (res) => {
          this.uiMembers.desc = (res.count || 1) + ' online'
          this.cdRef.detectChanges()
          completeLoading()
        },
      })

    this.organizationsService
      .getOrgUsage()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: handleError,
        next: (response: IOrgUsage) => {
          if (!response) return
          this.orgUsage = response
          completeLoading()
        },
      })

    this.organizationsService
      .getOrgSubscriptionDetails(this.selectedOrg._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: handleError,
        next: (response: IOrgSubscriptionDetails) => {
          if (!response) {
            this.subscriptionDetails = null
            return
          }

          // Convert date strings to Date objects
          this.subscriptionDetails = {
            ...response,
            // Make sure amounts is properly initialized if not present
            amounts: response.amounts || {
              addons: 0,
              base: 0,
              discounts: 0,
              finalAmount: 0,
              subtotalBeforeDiscounts: 0,
              total: 0,
            },
            cancellationInfo: response.cancellationInfo
              ? {
                  cancelledAt: new Date(response.cancellationInfo.cancelledAt),
                  validUntil: new Date(response.cancellationInfo.validUntil),
                }
              : null,
            nextPaymentDate: response.nextPaymentDate ? new Date(response.nextPaymentDate) : null,
          }
          completeLoading()
          console.log('Subscription details:', this.subscriptionDetails)
        },
      })
  }

  resetState() {
    this.uiMembers = { available: false, desc: '', format: EFormat.DEFAULT, info: '' }
    this.loading = false // or false based on your logic
  }

  onCloseBanner() {
    this.mainService.setAnnouncementItem('bebop-client:banner:dashboard', this._bannerText)
    this._bannerText = ''
  }

  get bannerText() {
    return this._bannerText ?? ''
  }

  get hasBanner() {
    return !!this._bannerText
  }

  closeDownloadCard(): void {
    sessionStorage.setItem('downloadCardClose', 'true')
    this.downloadCardClose = true
  }

  downloadApp(): void {
    this.util.openExternalLink(LinkAction.BBP_CLIENT_DOWNLOAD)
  }

  managePlan() {
    this.router.navigate(['app/org-profile'], { queryParams: { manage: true } })
  }

  navigateTo(route: string) {
    if (route === 'workstations' && this.isFreePlan) {
      this.managePlan()
    } else {
      this.router.navigate([`app/${route}`])
    }
  }

  onStorageClick = () => this.navigateTo('projects')
  onWorkstationClick = () => this.navigateTo('workstations')
  onTeamClick = () => this.navigateTo('team')

  // isWorkstationActivated = () => Number(this.orgUsage.workstation.purchased) !== 0

  get isFreePlan() {
    return this.subscriptionDetails?.productName?.toLocaleLowerCase() === 'free'
  }
}
