<div class="invites-container">
  <table class="cree8-table" *ngIf="invites?.length > 0; else noInvites">
    <thead>
      <tr>
        <th>Email</th>
        <th>Subsciption</th>
        <th>Invite status</th>
        <th>Invite expiry</th>
        <th>Last invite sent</th>
        <th>Invited by</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody class="scrollbar">
      <!-- Loop through invites and display data -->
      <tr *ngFor="let invite of invites">
        <td>{{ invite.email }}</td>
        <td>{{ invite.inviteInfo.entitlement }}</td>
        <td>{{ invite.inviteStatus }}</td>
        <td>{{ invite.inviteExpiry | date:'dd-MMM-yyyy hh:mm a' }}</td>
        <td>{{ invite.lastInviteSent | date:'dd-MMM-yyyy' }}</td>
        <td>{{ invite.invitedBy.name }}</td>
        <td>

          <div class="actions" *ngIf="invite.inviteStatus == 'Invited'">
            <cree8-btn-icon ariaLabel="Copy invite link" cree8-tooltip-text="Copy invite link"
              cree8-tooltip-position="top" (click)="copyInviteURL(invite)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Copy'"></use>
              </svg>
            </cree8-btn-icon>
            <cree8-btn-icon ariaLabel="Resend invite" cree8-tooltip-text="Resend invite" cree8-tooltip-position="top"
              (click)="resendInvite(invite)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Email_Resend'"></use>
              </svg>
            </cree8-btn-icon>
            <cree8-btn-icon ariaLabel="Revoke invite" cree8-tooltip-text="Revoke invite" cree8-tooltip-position="top"
              (click)="revokeInvite(invite)">
              <svg style="color:var(--message-error)" class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Email_Revoke'"></use>
              </svg>
            </cree8-btn-icon>
          </div>
          <div class="actions" *ngIf="invite.inviteStatus != 'Invited'">
            <cree8-btn-icon ariaLabel="Re invite" cree8-tooltip-text="Re Invite" cree8-tooltip-position="top"
              (click)="resendInvite(invite)">
              <svg class="ico" viewBox="0 0 24 24">
                <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Email_Resend'"></use>
              </svg>
            </cree8-btn-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Template for when there are no invites -->
  <ng-template #noInvites>
    <no-data [title]="'No invited members yet?'" [icon]="'Team'" [description]="
      'Let\'s not wait any longer—start inviting members'
    "></no-data>
  </ng-template>
</div>
