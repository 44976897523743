<div class="bebop-notifications" (click)="toggleDropDown()">
  <div class="notification-wrapper" [class.reminder]="hasReminder" [class.active]="hasActive"
  [bebopDropdownTriggerFor]="notifyDropdown"
  placement="bottom"
  placement-overlay-x-position="end"
  placement-origin-x-position="end"
  placement-tooltip-position="none"
  [placement-tooltip-offset]="12"
  (bebopClickOutside)="onClickOutside()"
  >

    <svg class="dot-icon tw-animate-pulse" *ngIf="hasReminder" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3Z" fill="white"/>
    </svg>
      

    <svg class="notify-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.83215 2.65451C5.56602 1.81784 6.64633 1.33398 8 1.33398C9.35367 1.33398 10.434 1.81784 11.1679 2.65451C11.8907 3.4786 12.2222 4.58103 12.2222 5.73398V6.88954C12.2222 7.05223 12.2952 7.24795 12.4856 7.50049C12.668 7.74255 12.9048 7.96958 13.1665 8.22038L13.1665 8.22039L13.1665 8.22041L13.2109 8.26293C13.6667 8.70024 14 9.33374 14 10.0895C14 11.114 13.2012 12.0007 12.1481 12.0007H3.85185C2.79882 12.0007 2 11.114 2 10.0895C2 9.33374 2.33331 8.70024 2.78914 8.26293L2.83348 8.22042C3.09513 7.96961 3.33199 7.74256 3.51444 7.50049C3.70478 7.24795 3.77778 7.05223 3.77778 6.88954V5.73398C3.77778 4.58103 4.10931 3.4786 4.83215 2.65451ZM7.22042 12.9627C7.01535 12.6569 6.60122 12.5752 6.29542 12.7803C5.98963 12.9854 5.90798 13.3995 6.11305 13.7053C6.31779 14.0106 6.60383 14.2528 6.93343 14.4171C7.26287 14.5812 7.63036 14.665 8.00007 14.665C8.36978 14.665 8.73727 14.5812 9.06671 14.4171C9.39631 14.2528 9.68235 14.0106 9.88709 13.7053C10.0922 13.3995 10.0105 12.9854 9.70472 12.7803C9.39892 12.5752 8.98478 12.6569 8.77971 12.9627C8.71346 13.0615 8.61052 13.1547 8.47212 13.2236C8.33356 13.2927 8.17044 13.3316 8.00007 13.3316C7.8297 13.3316 7.66658 13.2927 7.52802 13.2236C7.38961 13.1547 7.28668 13.0615 7.22042 12.9627Z"
        fill="#7F7F8C"
      />
    </svg>
  </div>
</div>


<bebop-dropdown #notifyDropdown [panelClasses]="['notification-panel-dd']" [class.no-reminder]="!hasReminder">

  <div class="notification-dd-wrapper">
    <div class="notification-dd-header">
      <div class="header-dd-label h4">Notifications</div>
      <div class="header-dd-badge small">{{notifications?.length}}</div>
      <div class="header-dd-action tw-cursor-pointer" *ngIf="showCloseAll">
        <bebop-link-button size="xsmall" class="notify" (on-click)="closeAll($event)">
          Close All
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.64645 2.64645C8.84171 2.45118 9.15829 2.45118 9.35355 2.64645C9.54882 2.84171 9.54882 3.15829 9.35355 3.35355L6.70711 6L9.35355 8.64645C9.54882 8.84171 9.54882 9.15829 9.35355 9.35355C9.15829 9.54882 8.84171 9.54882 8.64645 9.35355L6 6.70711L3.35355 9.35355C3.15829 9.54882 2.84171 9.54882 2.64645 9.35355C2.45118 9.15829 2.45118 8.84171 2.64645 8.64645L5.29289 6L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L6 5.29289L8.64645 2.64645Z" fill="white" fill-opacity="0.4"/>
          </svg>
        </bebop-link-button>
      </div>

    </div>
    <div class="notification-dd-body">

      <div class="no-reminder-dd-body" *ngIf="!hasReminder">
        No notifications
      </div>

      <div class="notification-dd-entry" *ngFor="let n of notifications">

        <ng-container *ngIf="!n.template">
          <div class="notification-dd-entry-row" (click)="onSelect(n, $event)">
            <div class="notification-icon">
              <svg *ngIf="n.type == 'success'" class="notify-success-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM16.7682 9.64018C17.1218 9.2159 17.0645 8.58534 16.6402 8.23177C16.2159 7.87821 15.5854 7.93553 15.2318 8.35981L10.9002 13.5578L8.67268 11.5328C8.26402 11.1613 7.63157 11.1914 7.26007 11.6C6.88856 12.0087 6.91868 12.6412 7.32733 13.0127L10.3273 15.7399C10.5291 15.9234 10.797 16.0164 11.0691 15.9976C11.3411 15.9788 11.5937 15.8497 11.7682 15.6402L16.7682 9.64018Z" fill="#32BA78"/>
              </svg>

              <svg *ngIf="n.type == 'error'" class="notify-error-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.2666 12C4.2666 7.58172 7.84832 4 12.2666 4C16.6849 4 20.2666 7.58172 20.2666 12C20.2666 16.4183 16.6849 20 12.2666 20C7.84832 20 4.2666 16.4183 4.2666 12ZM12.2666 2C6.74375 2 2.2666 6.47715 2.2666 12C2.2666 17.5228 6.74375 22 12.2666 22C17.7894 22 22.2666 17.5228 22.2666 12C22.2666 6.47715 17.7894 2 12.2666 2ZM13.2666 8C13.2666 7.44772 12.8189 7 12.2666 7C11.7143 7 11.2666 7.44772 11.2666 8V12C11.2666 12.5523 11.7143 13 12.2666 13C12.8189 13 13.2666 12.5523 13.2666 12V8ZM12.2666 15C11.7143 15 11.2666 15.4477 11.2666 16C11.2666 16.5523 11.7143 17 12.2666 17H12.2766C12.8289 17 13.2766 16.5523 13.2766 16C13.2766 15.4477 12.8289 15 12.2766 15H12.2666Z" fill="#F1344B" fill-opacity="0.8"/>
              </svg>
              
              <svg *ngIf="n.type == 'info'" class="notify-info-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2666 4C7.84832 4 4.2666 7.58172 4.2666 12C4.2666 16.4183 7.84832 20 12.2666 20C16.6849 20 20.2666 16.4183 20.2666 12C20.2666 7.58172 16.6849 4 12.2666 4ZM2.2666 12C2.2666 6.47715 6.74375 2 12.2666 2C17.7894 2 22.2666 6.47715 22.2666 12C22.2666 17.5228 17.7894 22 12.2666 22C6.74375 22 2.2666 17.5228 2.2666 12ZM12.2666 11C12.8189 11 13.2666 11.4477 13.2666 12V16C13.2666 16.5523 12.8189 17 12.2666 17C11.7143 17 11.2666 16.5523 11.2666 16V12C11.2666 11.4477 11.7143 11 12.2666 11ZM12.2666 7C11.7143 7 11.2666 7.44772 11.2666 8C11.2666 8.55228 11.7143 9 12.2666 9H12.2766C12.8289 9 13.2766 8.55228 13.2766 8C13.2766 7.44772 12.8289 7 12.2766 7H12.2666Z" fill="#4574F5"/>
              </svg>
              
              <svg *ngIf="n.type == 'warning'" class="notify-warning-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7ZM13.01 8C13.01 7.44772 12.5623 7 12.01 7C11.4577 7 11.01 7.44772 11.01 8V12C11.01 12.5523 11.4577 13 12.01 13C12.5623 13 13.01 12.5523 13.01 12V8ZM12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H12.01C12.5623 17 13.01 16.5523 13.01 16C13.01 15.4477 12.5623 15 12.01 15H12Z" fill="#FFC14C"/>
              </svg>
                
            </div>
            <div class="paragraph notification-text">{{n.text}}</div>
            <div class="closeable" [class.hide]="!n.closeable" (click)="onClose(n, $event)">
              <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5288 3.52925C11.7891 3.2689 12.2112 3.2689 12.4716 3.52925C12.7319 3.7896 12.7319 4.21171 12.4716 4.47206L8.94297 8.00065L12.4716 11.5292C12.7319 11.7896 12.7319 12.2117 12.4716 12.4721C12.2112 12.7324 11.7891 12.7324 11.5288 12.4721L8.00016 8.94346L4.47157 12.4721C4.21122 12.7324 3.78911 12.7324 3.52876 12.4721C3.26841 12.2117 3.26841 11.7896 3.52876 11.5292L7.05735 8.00065L3.52876 4.47206C3.26841 4.21171 3.26841 3.7896 3.52876 3.52925C3.78911 3.2689 4.21122 3.2689 4.47157 3.52925L8.00016 7.05784L11.5288 3.52925Z" fill="white" fill-opacity="0.4"/>
              </svg>                
            </div>
          </div>

        </ng-container>
        <ng-container *ngIf="n.template">
          <ng-container *ngTemplateOutlet="n.template; context: { $implicit: n.templateContext }"></ng-container>
        </ng-container>
      </div>

    </div>
  </div>

</bebop-dropdown>