import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'

import { distinctUntilChanged } from 'rxjs/operators'

import { SessionState, SessionStore } from './session.store'

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  constructor(protected store: SessionStore) {
    super(store)
  }

  getLoggedIn() {
    return this.select('loggedIn').pipe(distinctUntilChanged())
  }

  getLoggedInValue() {
    return this.getValue().loggedIn
  }

  getUser() {
    return this.select('user')
  }

  getUserValue() {
    return this.getValue().user
  }

  getOrganizations() {
    return this.select('organizations')
  }

  getOrganizationsValue() {
    return this.getValue().organizations
  }

  getPodsForCurrentOrg() {
    return this.select('pods')
  }

  getPodsForCurrentOrgValue() {
    return this.getValue().pods
  }

  getToken() {
    return this.select('token')
  }

  getTokenValue() {
    return this.getValue().token
  }

  getEntitlements() {
    return this.select('entitlements')
  }

  getAnnouncements() {
    return this.select('announcements')
  }

  getAnnouncementsValue() {
    return this.getValue().announcements
  }

  getDashboards() {
    return this.select('dashboards')
  }

  getDashboardForOrg(orgId: string) {
    return this.select((state) => state.dashboards[orgId]).pipe(distinctUntilChanged())
  }

  getDashboardForOrgValue(orgId: string) {
    return this.getValue().dashboards[orgId]
  }
}
