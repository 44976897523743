import { Component, Inject, OnInit } from '@angular/core'
import { ModalOverlayRef } from 'src/app/common/classes/modal-overlay-ref'
import { LinkAction } from 'src/app/common/enums'
import { OnExternalModalClose, BEBOP_MODAL_DATA } from 'src/app/common/services/component-modal.service'
import { BebopClientUtilsService } from 'src/app/services/bebop-client-utils.service'
import { UserService } from 'src/app/services/user.service'
import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations'

export interface InfoBroadcastAction {
  name: 'Cancel' | 'Close'
}

@Component({
  selector: 'bebop-info-broadcast',
  templateUrl: './info-broadcast.component.html',
  styleUrls: ['./info-broadcast.component.scss'],
  animations: [
    trigger('slideAnim', [
      transition(':enter', [
        style({ position: 'relative', right: '-322px' }),
        animate('300ms ease-in', style({ right: '0px' })),
      ]),
      transition(':leave', [
        style({ position: 'relative', right: '0px' }),
        animate('300ms ease-out', style({ right: '-322px' })),
      ]),
    ]),
  ],
})
export class InfoBroadcastComponent implements OnInit, OnExternalModalClose {
  constructor(
    public ref: ModalOverlayRef<InfoBroadcastComponent, InfoBroadcastAction>,
    private util: BebopClientUtilsService,
    private userService: UserService,
    @Inject(BEBOP_MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  _closed = false
  async beforeClose() {
    this._closed = true
    await this.ref.sleep(300)
  }

  cancel() {
    this.ref.send({ name: 'Close' })
    this.ref.close()
  }

  downloadMac() {
    this.util.openExternalLink(LinkAction.NDI_OSX_DOWNLOAD, { userId: this.userService.id })
  }

  downloadWin() {
    this.util.openExternalLink(LinkAction.NDI_WIN_DOWNLOAD, { userId: this.userService.id })
  }
}
