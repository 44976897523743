<div class="projects-grid">
  <ng-container *ngFor="let project of listProjects;">
    <ng-container *ngIf="project.name !== 'Media Cache'">
      <card-projects [project]="project" [type]="type" [activeProject]="selectedProject"
        (actionProject)="onActionProject($event)"></card-projects>
    </ng-container>

  </ng-container>
  <cree8-card-new-project *ngIf="!isHomePage" [title]="'New Project'" (addClick)="onAddProject()"
    [format]="type === 'grid' ? 'default' : 'compact'"></cree8-card-new-project>
  
  <cree8-card-new-project *ngIf="isHomePage" class="view-all-projects-card" [title]="'View All Projects'" (addClick)="onViewAllProjects()"
    [format]="type === 'grid' ? 'default' : 'compact'"></cree8-card-new-project>
</div>
