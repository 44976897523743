import { Component, EventEmitter, Input, Output } from '@angular/core';

export type TableAction = 'copy' | 'expiry' | 'protect' | 'active' | 'settings' | 'status';
@Component({
  selector: 'table-actions',
  templateUrl: './table-actions.component.html',
  styleUrl: './table-actions.component.scss'
})
export class TableActionsComponent {
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() action: EventEmitter<TableAction> = new EventEmitter<TableAction>();
  @Input() isActive = false;

  onAction(action: TableAction) {
    this.action.emit(action);
  }
}
