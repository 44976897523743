<div class="profile-dropdown">
  <div class="profile-view" [bebopDropdownTriggerFor]="profileDropdown" placement="bottom"
    placement-tooltip-position="right" [placement-tooltip-offset]="12" (click)="onToggleProfile()"
    (bebopClickOutside)="onCloseProfileDropdown()">
    <div class="profile-picture">
      <img class="user-image" [src]="picture" />
    </div>
    <!--
    <div class="user-details">
      <div class="name title">{{ user?.name }}</div>
      <div class="handle small">{{ user?.username ? '@' : '' }}{{ user?.username }}</div>
    </div> -->

    <div class="user-dropdown">
      <svg viewBox="0 0 16 16" fill="none" *ngIf="toggleOpen">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.854 10.354a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0l-3.5 3.5a.5.5 0 0 0 .708.708L8 7.207l3.146 3.147a.5.5 0 0 0 .708 0Z"
          fill="#7F7F8C" />
      </svg>

      <svg viewBox="0 0 16 16" fill="none" *ngIf="!toggleOpen">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.854 6.146a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L8 9.293l3.146-3.147a.5.5 0 0 1 .708 0Z"
          fill="#7F7F8C" />
      </svg>
    </div>
  </div>

  <bebop-dropdown #profileDropdown [panelClasses]="['profile-panel-dd']">

    <div class="profile-action">

      <div class="profile-picture" (click)="onClickAction('Profile',user?._id)">
        <img class="user-image" [src]="picture" />
      </div>

      <div class="user-details" (click)="onClickAction('Profile',user?._id)">
        <div class="name h4">{{ user?.name }}</div>
        <div class="handle paragraph">{{ user?.username ? '@' : '' }}{{ user?.username }}</div>
      </div>

      <bebop-button outlined="true" size="large" (on-click)="onClickAction('Refresh')"
        *ngIf="!environment.browser">Refresh</bebop-button>
    </div>

    <div class="dropdown-separator"></div>

    <!-- <div class="action" (click)="onClickAction('Profile',user?._id)">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Profile"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Profile</div>
    </div> -->

    <div class="action" (click)="onClickAction('Organization')">
      <div class="icon">
        <svg class="ico" viewBox="0 0 16 16">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Organization"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Account Settings</div>
    </div>

    <div class="action" (click)="onClickAction('OrgSelection')">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Switch"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Switch workspace</div>
    </div>

    <div class="action" (click)="onClickAction('Settings')" *ngIf="!environment.browser">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Settings"></use>
        </svg>
      </div>
      <div class="paragraph action-text">App Settings</div>
    </div>

    <div class="action" (click)="onClickAction('DownloadClient')" *ngIf="environment.browser">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Get_the_app"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Get the app</div>
    </div>

    <div class="action" *ngIf="analyticsEnabled && environment.browser" (click)="onClickAction('LookerStudio')">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Info"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Analytics</div>
    </div>

    <div class="action" (click)="onClickAction('Support')" *ngIf="environment.browser">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Support"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Support</div>
    </div>

    <cree8-theme-toggle />

    <div class="dropdown-separator"></div>

    <div class="action" (click)="onClickAction('Log Out')">
      <div class="icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Logout"></use>
        </svg>
      </div>
      <div class="paragraph action-text">Log Out</div>
    </div>
  </bebop-dropdown>
</div>

<cree8-org-selection *ngIf="orgSelectionModal"
  (organizationSelected)="onSelectOrganization($event)"></cree8-org-selection>
