<div class="bebop-split-n-button" [class.disabled]="isDisabled">
  <div
    class="button-container button-text"
    *ngFor="let t of templates; let i = index; let c = count"
    [class.last]="c == i + 1"
  >
    <div class="outlet-section" [class.active]="active == i" [class.disabled]="disabledList[i]" (click)="!disabledItems[i] && onClick(i)">
      <ng-container *ngTemplateOutlet="t"></ng-container>
    </div>
  </div>
</div>
