<div class="actions-list">
  <div class="tbs search" *ngIf="allowedActions.includes('search')">
    <div class="search-container">
      <div class="search-icon">
        <svg class="ico" viewBox="0 0 24 24">
          <use xlink:href="app/common/assets/icons/_sprite.svg#Search"></use>
        </svg>
      </div>
      <input
        type="text"
        class="search-input-icon"
        placeholder="Search..."
        [formControl]="searchControl"
      />
      <span class="remove-icon"></span>
    </div>
  </div>
  <div class="tbs sorting" (click)="isSortingActive = !isSortingActive">
    <svg class="ico" viewBox="0 0 24 24">
      <use
        xlink:href="app/common/assets/icons/_sprite.svg#Filters_Default"
      ></use>
    </svg>
  </div>
  <div
    class="tbs refresh"
    (click)="onRefresh()"
    *ngIf="allowedActions.includes('refresh')"
  >
    <svg class="ico" viewBox="0 0 24 24">
      <use xlink:href="app/common/assets/icons/_sprite.svg#Refresh"></use>
    </svg>
  </div>

  <div
    class="sorting-container"
    [class.active]="isSortingActive"
    *ngIf="allowedActions.includes('sorting')"
    #sortingContainer
  >
    <ul>
      <li
        [class.active]="item.value === activedSort"
        *ngFor="let item of listSorting"
        (click)="activeSorting(item.value)"
      >
        {{ item.label }}
        <ng-container *ngIf="item.value === activedSort">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13.3333 4.33334L6.00001 11.6667L2.66667 8.33334"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
      </li>
    </ul>
  </div>

  <div
    class="tbs refresh"
    (click)="onSettings()"
    *ngIf="allowedActions.includes('settings')"
  >
    <div appSvgInject="app/common/assets/icons/Settings.svg"></div>
  </div>
  <cree8-btn *ngIf="allowedActions.includes('created')" (click)="onCreated()">
    {{ labelCreated }}
  </cree8-btn>
</div>
