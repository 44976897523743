<app-cree8-toast></app-cree8-toast>
<div class="container">
  <div class="label">
    <div class="code-2fa">{{ label }}</div>
  </div>
  <div class="button">
    <div class="code-2fa copy" (click)="onCopy()">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3333 4.66634H8"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 6.66634H8"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3333 8.66634H8"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="5.33325"
          y="2"
          width="8.66667"
          height="9.33333"
          rx="2"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.6667 11.3337V12.667C10.6667 13.4034 10.0697 14.0003 9.33333 14.0003H3.33333C2.59695 14.0003 2 13.4034 2 12.667V6.00033C2 5.26395 2.59695 4.66699 3.33333 4.66699H5.33333"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</div>
