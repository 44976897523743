/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-prototype-builtins */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpXhrBackend } from '@angular/common/http'

import lodash from 'lodash'
import { catchError, of } from 'rxjs'
import {
  PartialVerificationThreshold,
  RocketVerificationOptions,
} from 'src/app/components/rocket/common/classes/rocket-types'

let _sortBy = lodash.sortBy
let _uniq = lodash.uniq
let _shuffle = lodash.shuffle
let _debounce = lodash.debounce

let MAX_TOKEN_RETRY = 5
let MAX_RENAME_RETRY = 3
let MAX_STREAM_RETRY = 5
let MAX_PROGRESS_DELAY_INTERVAL = 2000
let STREAM_RETRY_DELAY_INTERVAL = 2000
let FILE_WRITE_BUFFER = 1 * 1024 * 1024
let CACHE_TIMEOUT = 30 * 1000

let DownloadPlusState = {
  ABORTED: -2,
  CANCELLED: 4,
  COMPLETED: 6,
  DOWNLOADING: 7,
  FAILED: 5,
  INIT: 0,
  PARTIALLY_VERIFIED: -3,
  PAUSED: 3,
  STARTED: 1,
  VERIFIED: -1,
  VERIFYING: 9,
}

let IntegritySourceType = {
  LOCAL: 'local',
  REMOTE: 'remote',
}

let IntegrityTrackerState = {
  INIT: 0,
  MISMATCH: 2,
  PROGRESS: 1,
  SKIPPED: 4,
  VERIFIED: 3,
}

let bytesRegex = /^bytes\s(\d+)-(?:\d+\/\d+)$/

let HASH_WINDOW_SIZE = 10 * 1024 * 1024
let SENTINAL_BUFFER = 0 //Buffer.allocUnsafe(0)

export class DownloadError extends Error {
  _retryCount = 0
  constructor(msg: string) {
    super(msg)
  }

  get retryCount() {
    return this._retryCount
  }
  set retryCount(cnt: number) {
    this._retryCount = cnt
  }
}

export function IntegrityTracker(source) {
  let self = this

  this.vsize = (function () {
    let opt = source.getVerificationOption?.()
    let size = source.file.size
    let { smallFileCriteria } = source.downloadPlus?.opt ?? {}

    if (
      opt == RocketVerificationOptions.Skip ||
      (opt == RocketVerificationOptions.SkipOnlySmallFiles && size <= smallFileCriteria)
    ) {
      size = 0
    }

    if (opt == RocketVerificationOptions.PartialVerification) {
      size = Math.ceil(size * PartialVerificationThreshold)
    }

    return size
  })()

  this.total = (function (size: number) {
    let quo = (size / HASH_WINDOW_SIZE) | 0
    let residue = size % HASH_WINDOW_SIZE ? 1 : 0
    return quo + residue
  })(this.vsize)

  this.bufSize = this.total * 4

  this.buffers = this.total
    ? {
        local: 0,
        remote: 0,
      }
    : {
        local: SENTINAL_BUFFER,
        remote: SENTINAL_BUFFER,
      }

  this.indices = {
    local: 0,
    remote: 0,
  }

  this.matchedIndex = 0
  this.mismatch = false

  this.finished = {
    local: false,
    remote: false,
  }

  this._skipIntegrity = false

  this.skipIntegrity = function (skip = true) {
    this._skipIntegrity = skip
  }

  this.track = function (type, input) {
    if (!this.buffers[type]) throw new Error('Invalid IntegritySourceType')

    let buf = this.buffers[type]
    let idx = this.indices[type]

    input.copy(buf, idx)
    this.indices[type] += input.length
    this.match()
  }

  this.match = function () {
    if (self.indices.local === 0 || self.indices.remote === 0 || self.mismatch) return

    let common = Math.min(self.indices.local, self.indices.remote)
    while (!self.mismatch && common > self.matchedIndex) {
      if (this.buffers.local[self.matchedIndex] !== this.buffers.remote[self.matchedIndex]) self.mismatch = true
      self.matchedIndex++
    }

    if (self.mismatch) {
      source.onIntegrity(IntegrityTrackerState.MISMATCH)
    } else if (this.matchedIndex >= this.bufSize && this.finished.local && this.finished.remote) {
      source.onIntegrity(IntegrityTrackerState.VERIFIED, self.buffers.local)
    } else {
      source.onIntegrity(IntegrityTrackerState.PROGRESS)
    }
  }

  this.end = function (type) {
    if (!this.buffers[type]) throw new Error('Invalid IntegritySourceType')

    this.finished[type] = true
    this.match()
  }

  this._fixOffset = function () {
    this.indices.local -= this.indices.local % 4
    this.indices.remote -= this.indices.remote % 4
  }

  this.reset = function (type) {
    if (!this.buffers[type]) throw new Error('Invalid IntegritySourceType')

    this.indices[type] = 0
    this.finished[type] = false
    this.matchedIndex = 0
    this.mismatch = false
  }

  this.resetFinished = function () {
    this.finished.local = false
    this.finished.remote = false
  }

  this.getFileOffset = function (type) {
    if (!this.buffers[type]) throw new Error('Invalid IntegritySourceType')

    this._fixOffset()
    // no need to use mismatch and matchedIndex
    // since both local and remote files are immutable blobs

    let idx = this.indices[type]
    let offset = (idx / 4) | 0
    this.indices[type] -= this.indices[type] % 4
    // going beyond file size is fine!
    return offset * HASH_WINDOW_SIZE
  }

  this.getPercent = function () {
    return ((this.matchedIndex * 100) / this.bufSize) | 0
  }

  this.fixPartials = function () {
    if (source.file.__source && source.file.__source.vPercent) {
      let percent = Math.max(0, source.file.__source.vPercent)
      self.matchedIndex = ((percent * self.bufSize) / 100) | 0
      self.indices.local = self.indices.remote = self.matchedIndex

      self._fixOffset()
      self.matchedIndex = self.indices.local

      if (self.matchedIndex >= self.bufSize) {
        self.finished.local = self.finished.remote = true
      }

      source.file.__source.vPercent = 0
    }
  }

  this.isVerified = function () {
    return this.matchedIndex >= this.bufSize
  }

  this.isSkippedVerification = function () {
    return this._skipIntegrity
  }

  this.isError = function () {
    return this.mismatch
  }

  this.fixPartials()
}

export function DownloadPlusFile(downloadPlus, meta) {
  let self = this
  this.meta = meta || {}

  if (!meta.storage) throw new Error('Storage is missing')
  // if (meta.source.dir) throw new Error('Directory is not expected')

  this.storage = meta.storage
  this.downloadPlus = downloadPlus

  this.project = meta.project

  this.file = this.meta.source
  this.stream = null

  this.notFound = false
  this.notRecoverable = false
  this.notRecoverableReason = null
  this.lastError = ''

  this.dir = this.file?.dir ?? false

  this.uniqueIdentifier = this.file.uniqueIdentifier

  this.lastProcessSentTs = 0
  this.percent = 0
  this.received = 0

  this.host = this.storage.txChannelDNS?.CLIENT || this.storage.bebopUploaderEndpoint
  this.protocol = this.storage.bebopUploaderProtocol

  let flexNode =
    this.project?.solutions?.osOneFlex &&
    this.project?.organization?.flexMountCredential?.credentials?.lucid?.root_mount
      ? '/' + this.project?.organization?.flexMountCredential?.credentials?.lucid?.root_mount
      : ''

  this.baseDir = this.storage.transferServerMount + flexNode + '/' + this.storage.directories.projects

  this.state = DownloadPlusState.INIT
  this.integrityState = DownloadPlusState.INIT

  this.integrityTracker = new IntegrityTracker(this)

  this.getVerificationOption = function () {
    let uvOpt = this.project?.rocket?.downloadVerificationOption || this.storage?.rocket?.downloadVerificationOption
    return uvOpt ?? this.downloadPlus?.opts.verificationOption ?? RocketVerificationOptions.FullVerification
  }

  this.onProgress = function (size) {
    if (self.aborted) {
      self.abortRequest()
      return
    }

    if (self.isPaused() || self.downloadPlus.paused) {
      return self.pause()
    }

    this.received += size

    this.percent = (this.received * 100) / this.meta.size
    this.percent |= 0

    let delay = Date.now() - self.lastProcessSentTs > MAX_PROGRESS_DELAY_INTERVAL
    if (delay) {
      self._onProgress(self)
      self.lastProcessSentTs = Date.now()
    }
  }

  this._onProgress = function () {
    if (self.isCompleted() || self.isError() || self.isPaused() || self.isCancelled()) return
    this.state = DownloadPlusState.DOWNLOADING
    downloadPlus.onProgress(self)
  }

  this._move = function (cb, retryCount) {
    return cb()
  }

  this.isExistsAsync = function (cb) {
    return cb()
  }

  this.getParams = function (opt) {
    opt = opt || {}
    let params: any = {}
    return new Promise(function (resolve, reject) {
      downloadPlus
        .getTarget(self, opt)
        .then(function (target) {
          let agent = downloadPlus.opts.tokenAgent

          if (agent) {
            // token based authorization
            agent
              .getToken({ linkId: self.meta.linkId, projectId: self.meta.project._id })
              .then(function (token) {
                let tokenParams = token || {}
                params = Object.assign({}, params, tokenParams)
                resolve(
                  Object.assign(params, {
                    machineId: downloadPlus.opts.machineId,
                    target: target,
                  })
                )
              })
              .catch(reject)
            return
          }
          resolve(
            Object.assign(params, {
              machineId: downloadPlus.opts.machineId,
              target: target,
            })
          )
        })
        .catch(reject)
    })
  }

  this.getSourceFilename = function () {
    return this.meta.fullpath
  }

  this.createDirectory = function (dirname) {
    return Promise.resolve()
  }

  this.getDestinationFilename = function () {
    return ''
  }

  this.getTempFilename = function (ts) {
    return ''
  }

  this.getPartialData = function () {
    return Promise.resolve()
  }

  this.abortRequest = function () {}

  this.pause = function () {}

  this.resume = function () {}

  this.retry = function () {}

  this.cancelVerification = function () {}

  this.cancel = function (force) {}

  this.cancel = this.cancel.bind(this)

  this.auditError = function (e, emitNotification) {}

  this.onError = function (e) {}
  this.onError = this.onError.bind(this)

  this.setLastError = function (e) {}

  this.resetLastError = function () {}

  this.getLastError = function () {}

  this.onComplete = function (e) {}

  this._download = function (params, retryCount) {}

  this.download = function (retryCount, cb) {}

  this.download = this.download.bind(this)

  this.verify = function () {}

  this.verify = this.verify.bind(this)

  this.abortVerification = function () {}

  this._verify = function (params, retryCount) {}

  this._verify = this._verify.bind(this)

  this.reverify = function () {}

  this.onIntegrity = function (type, data) {}

  this.isDownloading = function () {
    return self.state === DownloadPlusState.DOWNLOADING
  }

  this.isFresh = this.isNew = function () {
    return self.state === DownloadPlusState.INIT
  }

  this.isFailed = this.isError = function () {
    return self.state === DownloadPlusState.FAILED || self.integrityState === DownloadPlusState.FAILED
  }

  this.isCancelled = function () {
    return self.state === DownloadPlusState.CANCELLED || self.integrityState === DownloadPlusState.ABORTED
  }

  this.isPaused = function () {
    return self.state === DownloadPlusState.PAUSED || self.integrityState === DownloadPlusState.PAUSED
  }

  this.isCompleted = function () {
    return self.state === DownloadPlusState.COMPLETED
  }

  this.isVerifying = function () {
    return self.integrityState === DownloadPlusState.VERIFYING
  }

  this.isWaiting = function () {
    return self.integrityState === DownloadPlusState.INIT && self.isCompleted()
  }

  this.isSkippedVerificationCompleted = function () {
    return (self.integrityTracker?.isSkippedVerification?.() ?? false) && self.isCompleted()
  }

  this.isPartiallyVerified = function () {
    return self.integrityState === DownloadPlusState.PARTIALLY_VERIFIED && self.isCompleted()
  }

  this.isVerificationAborted = function () {
    return self.integrityState === DownloadPlusState.ABORTED && self.isCompleted()
  }

  this.isVerified = function () {
    return self.integrityState === DownloadPlusState.VERIFIED
  }

  this.isVerifiedError = function () {
    return self.integrityState === DownloadPlusState.FAILED
  }

  this.isActive = function () {
    return this.isDownloading() || this.isVerifying()
  }

  this.isNotRecoverable = function () {
    return this.notRecoverable
  }

  this.isDone = function () {
    return this.isVerified() || (this.isError() && this.isNotRecoverable()) || this.isCancelled()
  }
}

export function DownloadPlus(opts) {
  /**
   * Default options for DownloadPlus.js
   * @type {Object}
   */
  this.defaults = {
    downloadPath: '.',
    downloadTokenAgent: null,
    machineId: '00:00:00:00:00',
    simultaneousDownload: 4,
    simultaneousVerification: 2,
    smallFileCriteria: 50 * 1024 * 1024,
    tokenAgent: null,
    verificationOption: RocketVerificationOptions.FullVerification,
  }

  /**
   * Current options
   * @type {Object}
   */
  this.opts = Object.assign({}, this.defaults, opts || {})

  /**
   * List of events:
   *  key stands for event name
   *  value array list of callbacks
   * @type {}
   */
  this.events = {}

  /**
   * Download files list
   */
  this.files = []

  /**
   * Integrity files list
   */
  this.integrityFiles = []

  /**
   * Download queue list
   */
  this.queuedFiles = []

  /**
   * Integrity queue list
   */
  this.queuedIntegrityFiles = []

  /**
   * Downloaded files
   */
  this.downloadedFiles = []

  /**
   * Fair-share dns manager
   */
  this._dnsManager = {}

  this._dnsCache = {}

  // pause/resume state
  this.paused = false

  this.lookup = {}
}

// DNS, LifeCycle, Events, Actions are just logical grouping.
let DNS = {
  getActiveServersCount: function () {},

  getHost: function (fileObj, opts, cb, force) {
    let that = this
    this.lookupDNS(
      fileObj.host,
      opts,
      function (err, records) {
        if (err || !records.length) return cb(err || 'No dns records found!')

        // lookup last used host of given file
        let host = fileObj._targetHost
        let availability = host && records.indexOf(host) !== -1

        if (availability) return cb(null, host)

        // fetch from dns manager
        // reindex dns manager
        if (!that._dnsManager[fileObj.storage._id]) {
          that._dnsManager[fileObj.storage._id] = []
        }

        that._dnsManager[fileObj.storage._id] = that._dnsManager[fileObj.storage._id].filter(function (entry) {
          return records.indexOf(entry.ip) !== -1
        })

        let dnsManager = that._dnsManager[fileObj.storage._id]

        records.reduce(function (acc, record) {
          let idx = acc.findIndex(function (o) {
            return record === o.ip
          })
          if (idx !== -1) return acc

          acc.push({
            ip: record,
            weight: 0,
          })
          return acc
        }, dnsManager)

        if (!dnsManager.length) return cb('No dns records found!')

        // shuffle helps to get better distribution of same weighted ip
        that._dnsManager[fileObj.storage._id] = _shuffle(dnsManager)
        // sort to pick the least weighted one
        that._dnsManager[fileObj.storage._id] = _sortBy(that._dnsManager[fileObj.storage._id], 'weight')

        fileObj._targetHost = that._dnsManager[fileObj.storage._id][0].ip
        that._dnsManager[fileObj.storage._id].weight++
        cb(null, fileObj._targetHost)
      },
      force
    )
  },
  /**
   * Get target url based on the context
   *
   */
  getTarget: function (fileObj, opts) {
    return Promise.resolve(
      `${fileObj.storage.bebopUploaderProtocol}://${fileObj.storage.txChannelDNS?.CLIENT || fileObj.storage.bebopUploaderEndpoint}`
    )
  },
  lookupDNS: function (host, opts, cb, force) {},
  resetDNSOnDone: function (fileObj) {},
  resetHost: function (fileObj) {},
  revalidateHost: function (err, fileObj, invalidate, emit) {},
}

let LifeCycle: any = {}

LifeCycle.vfeed = function (cnt) {
  cnt = +cnt || 0
  let self = this
  if (self.vfeeding) return

  let activeCount = this.integrityFiles.length

  let max = Math.max(1, cnt || this.opts.simultaneousVerification)

  // trim active integrity check
  // Do not trim down number of integrity check if its already in motion
  // lets consider the setting changes are for future actions.
  // eslint-disable-next-line no-constant-condition
  if (activeCount > max && false) {
    let extra = activeCount - max

    let sortList = self.integrityFiles.reduce(function (acc, file) {
      acc.push({ file: file, percent: file.integrityTracker.getPercent() })
      return acc
    }, [])

    sortList = _sortBy(sortList, 'percent')
    self.vfeeding = true
    let queue = sortList.slice(0, extra).map(function (l) {
      return l.file
    })
    shieldSideEffect(function () {
      queue.reverse().forEach(function (file) {
        file.integrityState = DownloadPlusState.COMPLETED
        file.abortVerification?.()
        self.addIntegrityFile(file, true)
      })
    })
    self.vfeeding = false

    setTimeout(function () {
      self.vfeed()
    }, 100)
    return
  }

  if (self.paused || activeCount >= max) return

  let left = max - activeCount

  let next = this.queuedIntegrityFiles.splice(0, left)

  next.forEach(function (file) {
    self.integrityFiles.push(file)
    file.verify()
    // self.fire('file:integrity:started', file)
  })
}

LifeCycle.feed = function (cnt) {
  cnt = +cnt || 0
  let self = this

  if (self.feeding) return

  let activeCount = this.files.length

  let max = Math.max(1, cnt || this.opts.simultaneousDownload)

  // trim active downloads
  // Do not trim down number of active download check if its already in motion
  // lets consider the setting changes are for future actions.
  // eslint-disable-next-line no-constant-condition
  if (activeCount > max && false) {
    let extra = activeCount - max

    self.files = _sortBy(self.files, 'percent')
    self.feeding = true
    let queue = self.files.slice(0, extra)
    shieldSideEffect(function () {
      queue.forEach(function (file) {
        file.cancel()
      })
    })
    self.feeding = false

    self.addFiles(
      queue.reverse().map(function (entry) {
        return entry.file
      }),
      true
    )
    setTimeout(function () {
      self.feed()
    }, 100)
    return
  }

  if (self.paused || activeCount >= max) return

  let left = max - activeCount

  let next = this.queuedFiles.splice(0, left)

  self.files = self.files.concat(next)
  next.forEach(function (file) {
    file.download(0, function (exists) {
      if (exists) {
        let idx = self.files.findIndex(function (f) {
          return f.file.fullpath === file.file.fullpath
        })

        if (idx != -1) {
          self.files.splice(idx, 1)
        } else {
          console.log(file, ' is already removed from files list')
        }

        delete self.lookup[file.uniqueIdentifier]

        self.fire('file:exists', file)
        self.trigger()
      } else {
        self.fire('file:started', file)
      }
    })
  })
}

LifeCycle.onComplete = function (file) {
  this.fire('file:downloaded', file)
  if (file.dir) {
    file.integrityState = DownloadPlusState.VERIFIED
  }
  this.addIntegrityFile(file)
  this.trigger()
}

LifeCycle.onError = function (file, reason) {
  let self = this
  if (!self.aborted) {
    this.fire('file:failed', file, reason)
  }
  this.trigger()
}

LifeCycle.onPause = function (file) {
  if (this.paused) return
  this.fire('file:paused', file)
}

LifeCycle.onResume = function (file) {
  if (!this.paused) return

  this.fire('file:resumed', file)

  let done = file.isCompleted()
  this.removeFile(file)

  if (done) {
    this.addIntegrityFile(file, true)
  } else {
    this.addFile(file.file, true)
  }
  this.trigger()
}

LifeCycle.onCancel = function (file) {
  this.fire('file:cancelled', file)
  this.trigger()
}

LifeCycle.onRemoved = function (file) {
  this.fire('file:removed', file)
  this.trigger()
}

LifeCycle.onRefresh = function () {
  this.fire('downloader:refresh')
}

LifeCycle.onProgress = function (file) {
  this.fire('file:progress', file)
}

LifeCycle.onVerified = function (file) {
  this.removeIntegrityFile(file)
  this.fire('file:integrity:verified', file)
  this.trigger()
}

LifeCycle.onSkipVerification = function (file) {
  this.removeIntegrityFile(file)
  file?.integrityTracker?.skipIntegrity?.()
  this.fire('file:integrity:skipped', file)
  this.trigger()
}

LifeCycle.onChangeVerificationOptions = async function () {
  let self = this
  let { smallFileCriteria, verificationOption } = self.opts ?? {}

  let isSkip =
    verificationOption == RocketVerificationOptions.SkipOnlySmallFiles ||
    verificationOption == RocketVerificationOptions.Skip
  let isSmallSkip = verificationOption == RocketVerificationOptions.SkipOnlySmallFiles

  if (isSkip) {
    // Already queued files for verification
    let bigFiles = isSmallSkip ? (self.queuedIntegrityFiles?.filter((f) => f.meta.size > smallFileCriteria) ?? []) : []
    let files =
      (isSmallSkip
        ? self.queuedIntegrityFiles?.filter((f) => f.meta.size <= smallFileCriteria)
        : self.queuedIntegrityFiles) ?? []
    self.queuedIntegrityFiles = bigFiles

    for (let qf of files) {
      // move won't always call the callback
      qf._move(() => {
        self.onSkipVerification?.(qf)
      })
      await sleep(40) // 25 renames per second
    }

    // On going verification
    let binfs = isSmallSkip ? (self.integrityFiles?.filter((f) => f.meta.size > smallFileCriteria) ?? []) : []
    let infs =
      (isSmallSkip ? self.integrityFiles?.filter((f) => f.meta.size <= smallFileCriteria) : self.integrityFiles) ?? []
    self.integrityFiles = binfs

    for (let fi of infs) {
      fi.abortVerification()
      // move won't always call the callback
      fi._move(() => {
        self.onSkipVerification?.(fi)
      })
    }

    return
  }

  // Full -> Partial (if its already started, let it be full, user can cancel it from download and lets move it to download)
  // Partial -> Full (if its already started, let it be partial)
}

LifeCycle.onPersistHash = function (file, base64) {
  this.fire('file:persist:hash', file, base64)
}

LifeCycle.onVerificationError = function (file, err) {
  this.removeIntegrityFile(file, true)
  this.fire('file:integrity:failed', file, err)
  this.trigger()
}

LifeCycle.onVerificationProgress = function (file) {
  this.fire('file:integrity:progress', file)
}

LifeCycle.pause = function (file) {
  file.pause()
}

LifeCycle.pauseAll = function () {
  if (this.paused) return
  ;[this.files, this.integrityFiles].forEach(function (files) {
    files
      .slice(0)
      .filter(function (file) {
        return file.isActive()
      })
      .forEach(function (file) {
        file.pause()
      })
  })
  this.paused = true
  this.fire('fileList:paused')
}

LifeCycle.resume = function (file) {
  file.resume()
  this.trigger()
}

LifeCycle.resumeAll = function () {
  if (!this.paused) return
  ;[this.files, this.integrityFiles].forEach(function (files, idx) {
    files
      .slice(0)
      .filter(function (file) {
        return file.isPaused() || file.isFresh() || file.isWaiting()
      })
      .forEach(function (file) {
        file.resume()
      })
  })

  this.paused = false
  this.fire('fileList:resumed')
  this.feed()
  this.vfeed()
}

LifeCycle.cancelAll = function (clean) {
  let self = this

  ;[this.queuedFiles, this.queuedIntegrityFiles].forEach((files) => {
    files?.slice(0).forEach((file) => {
      self.onCancel(file)
      self.onRemoved(file)
    })
  })

  this.queuedFiles = []
  this.queuedIntegrityFiles = []
  this.downloadedFiles = []
  let context = this.opts.context
  ;[this.files, this.integrityFiles].forEach(function (files) {
    files.slice(0).forEach(function (file) {
      file.cancel(clean)
      context &&
        window.txServer &&
        window.txServer.remove(
          Object.assign(
            {
              projectId: file.project._id,
              storageId: file.storage._id,
            },
            context
          )
        )
    })
  })

  this.lookup = {}

  this.fire('fileList:cancelled')
}

LifeCycle.cancel = function (file, force) {
  file.cancel(force)
}

LifeCycle.cancelById = function (id) {
  let self = this
  let f = self.lookup[id]
  if (f) {
    if (f.isCompleted()) {
      f._move(() => {})
      return true
    }

    f.cancel(true) && self.onCancel(f)
    self.removeFile(f)
    self.onRemoved(f)
    return true
  }
  return false
}

LifeCycle.clearAll = function () {
  let self = this
  let files = this.files
    .filter(function (file) {
      return file.isError()
    })
    .forEach(function (file) {
      self.onRemoved(file, true)
      delete self.lookup[file.uniqueIdentifier]
    })

  self.downloadedFiles.forEach(function (file) {
    delete self.lookup[file.uniqueIdentifier]
  })
  self.downloadedFiles = []

  this.files = this.files.filter(function (file) {
    return !file.isError()
  })

  files = this.integrityFiles
    .filter(function (file) {
      return file.isVerifiedError() || file.isVerified()
    })
    .forEach(function (file) {
      self.onRemoved(file, true)
      delete self.lookup[file.uniqueIdentifier]
    })

  this.integrityFiles = this.integrityFiles.filter(function (file) {
    return !file.isVerifiedError() && !file.isVerified()
  })

  this.fire('fileList:cleared')
}

let Events = {
  /**
   * Fire an event
   * @function
   * @param {string} event event name
   * @param {...} args arguments of a callback
   * @return {bool} value is false if at least one of the event handlers which handled this event
   * returned false. Otherwise it returns true.
   */
  _fire: function (event, args) {
    let preventDefault = false
    if (this.events.hasOwnProperty(event)) {
      this.events[event].forEach(function (callback) {
        preventDefault = callback.apply(this, args.slice(1)) === false || preventDefault
      }, this)
    }
    if (event != 'catchall') {
      args.unshift('catchAll')
      preventDefault = this.fire.apply(this, args) === false || preventDefault
    }
    return !preventDefault
  },

  fire: function (event, args) {
    let self = this
    // `arguments` is an object, not array, in FF, so:
    args = Array.prototype.slice.call(arguments)
    event = event.toLowerCase()

    try {
      self._fire(event, args)
    } catch (e) {
      // prevent angular client side errors affects download plus cycle
      errorLog(e)
    }
  },

  /**
   * Remove event callback
   * @function
   * @param {string} [event] removes all events if not specified
   * @param {Function} [fn] removes all callbacks of event if not specified
   */
  off: function (event, fn) {
    if (event !== undefined) {
      event = event.toLowerCase()
      if (fn !== undefined) {
        if (this.events.hasOwnProperty(event)) {
          arrayRemove(this.events[event], fn)
        }
      } else {
        delete this.events[event]
      }
    } else {
      this.events = {}
    }
  },

  /**
   * Set a callback for an event
   * @function
   * @param {string} event
   * @param {Function} callback
   */
  on: function (event, callback) {
    event = event.toLowerCase()
    if (!this.events.hasOwnProperty(event)) {
      this.events[event] = []
    }
    this.events[event] = [callback]
  },
}

let Actions: any = {}

Actions._removeFromFiles = function (file) {
  let self = this
  let findBy = function (f) {
    return file.meta.fullpath === f.meta.fullpath && f.meta.storage._id === file.meta.storage._id
  }

  let idx = self.files.findIndex(findBy)

  if (idx !== -1) {
    self.files.splice(idx, 1)
  }
}

Actions.addIntegrityFile = function (file, prefix) {
  let self = this
  this._removeFromFiles(file)

  let { smallFileCriteria, verificationOption: vOpt } = this.opts ?? {}
  let verificationOption = file?.getVerificationOption?.() ?? vOpt

  if (
    verificationOption == RocketVerificationOptions.Skip ||
    (verificationOption == RocketVerificationOptions.SkipOnlySmallFiles && file.meta.size <= smallFileCriteria)
  ) {
    file?._move?.(() => {
      self.onSkipVerification?.(file)
    })
    self.vfeed()
    return
  }

  if (prefix) {
    this.queuedIntegrityFiles = [file].concat(this.queuedIntegrityFiles)
  } else {
    this.queuedIntegrityFiles.push(file)
  }
  this.vfeed()
}

Actions.removeIntegrityFile = function (file, doNotPush = false) {
  let self = this
  let findBy = function (f) {
    return file.meta.fullpath === f.meta.fullpath && f.meta.storage._id === file.meta.storage._id
  }

  let idx = self.integrityFiles.findIndex(findBy)

  if (idx !== -1) {
    self.integrityFiles.splice(idx, 1)
  }
  !doNotPush && self.downloadedFiles.push(file)
}

Actions.newDownloadFile = function (entry) {
  let meta = {
    dir: entry?.dir,
    fullpath: entry.fullpath,
    linkId: entry.linkId,
    loc: entry.loc || this.opts.downloadPath,
    mtimeMs: entry.mtimeMs,
    name: entry.name,
    project: entry.project,
    relativePath: entry.relativePath,
    size: entry.size,
    sizeStr: this.readablizeBytes(entry.size),
    source: entry,
    storage: entry.project.storage,
    userId: entry.userId,
  }
  return new DownloadPlusFile(this, meta)
}

Actions.addFiles = function (list, prefix) {
  let self = this
  let nextList = list.map(self.newDownloadFile.bind(self))

  nextList.forEach(function (f) {
    if (self.lookup[f.uniqueIdentifier] && (f.isVerified() || f.isNotRecoverable())) {
      self.removeFile(f)
      self.onRemoved(f, true)
    }
  })

  nextList = nextList.filter(function (f) {
    return !self.lookup[f.uniqueIdentifier]
  })

  if (!nextList?.length) return false

  nextList.forEach(function (f) {
    self.lookup[f.uniqueIdentifier] = f
  })

  let scan = nextList.reduce(
    function (acc, entry) {
      acc[+!!entry.file.__verifyOnly].push(entry)
      entry.file.__verifyOnly = false
      return acc
    },
    [[], []]
  )

  let vOnlyList = scan[1]
  let downloadList = scan[0]

  if (downloadList.length) {
    if (prefix) {
      self.queuedFiles = downloadList.concat(self.queuedFiles)
    } else {
      self.queuedFiles = self.queuedFiles.concat(downloadList)
    }

    self.feed()
  }

  if (vOnlyList.length) {
    vOnlyList.forEach(function (file) {
      file.state = DownloadPlusState.COMPLETED
      file.received = file.meta.size
      file.percent = 100
      file.dest = file.getDestinationFilename()
    })

    if (prefix) {
      self.queuedIntegrityFiles = downloadList.concat(self.queuedIntegrityFiles)
    } else {
      self.queuedIntegrityFiles = self.queuedIntegrityFiles.concat(vOnlyList)
    }
    self.vfeed()
  }

  self.onRefresh()
  return true
}

Actions.addFile = function (file, prefix) {
  if (file) this.addFiles([file], prefix)
}

Actions.removeFile = function (file) {
  let self = this
  let findBy = function (f) {
    return file.meta.fullpath === f.meta.fullpath && f.meta.storage._id === file.meta.storage._id
  }

  delete self.lookup[file.uniqueIdentifier]

  let idx = self.queuedFiles.findIndex(findBy)

  if (idx !== -1) {
    self.queuedFiles.splice(idx, 1)
    return
  }

  idx = self.files.findIndex(findBy)

  if (idx !== -1) {
    let downloadPlusFile = self.files[idx]
    self.files.splice(idx, 1)
    if (downloadPlusFile.isDownloading()) {
      downloadPlusFile.cancel()
    }
  }

  idx = self.queuedIntegrityFiles.findIndex(findBy)

  if (idx !== -1) {
    self.queuedIntegrityFiles.splice(idx, 1)
    return
  }

  idx = self.integrityFiles.findIndex(findBy)

  if (idx !== -1) {
    let downloadPlusFile = self.integrityFiles[idx]
    self.integrityFiles.splice(idx, 1)
    if (downloadPlusFile.isVerifying()) {
      downloadPlusFile.cancel()
    }
  }
}

Actions.cancelByQueueId = function (id) {
  let self = this

  let byQueueID = function (f) {
    return f.file.__queueId === id
  }

  let files = self.queuedFiles.filter(byQueueID)

  if (!files.length) files = self.files.filter(byQueueID)
  if (!files.length) files = self.integrityFiles.filter(byQueueID)
  if (!files.length) files = self.queuedIntegrityFiles.filter(byQueueID)
  if (!files.length) {
    errorLog('Cancel queue id missing', id)
    return false
  }

  files.forEach(function (f) {
    if (!f.cancel(true)) {
      self.onCancel(f)
      self.removeFile(f)
      self.onRemoved(f)
    }
  })
  return true
}

Actions.hasFile = function (esFile) {
  let key = esFile.uniqueIdentifier || 0
  return !!this.lookup[key]
}

Actions.trigger = function () {
  this.feed()
  this.vfeed()
}

let http = new HttpClient(
  new HttpXhrBackend({
    build: () => new XMLHttpRequest(),
  })
)

let Extras: any = {}

Extras.diskUsage = function (path) {
  return Promise.resolve()
}

Extras.listDirectory = function (obj, opts) {
  let entry = {
    fullpath: '/',
    mtimeMs: 0,
    name: '(dummy list)',
    project: obj.project,
    relativePath: '(dummy list)',
    size: 0,
    userId: opts.userId,
  }
  let that = this
  return new Promise(function (resolve, reject) {
    let file = that.newDownloadFile(entry)
    file
      .getParams({ browse: true })
      .then(function (params) {
        let options = {
          body: Object.assign({}, params, opts),

          json: true,

          // agent: noKeepAliveAgent,
          rejectUnauthorized: false,
          timeout: 3000,
          url: params.target + '/v1/tree/directory/view',
        }

        http
          .post(options.url, options.body)
          .pipe(
            catchError((err: any) => {
              if (['ESOCKETTIMEDOUT', 'ECONNRESET'].includes(err?.code)) {
                console.log(
                  '/v1/tree/directory/view POST request failed with ECONNRESET/ESOCKETTIMEDOUT',
                  err,
                  options?.url
                )
                if (!obj.resetRetry) obj.resetRetry = 0
                if (obj.resetRetry < MAX_TOKEN_RETRY) {
                  obj.resetRetry++
                  setTimeout(() => that.listDirectory(obj, opts).then(resolve).catch(reject), 1000)
                  return
                }
              }
              return of({})
            })
          )
          .subscribe((data) => {
            resolve(data)
          })
      })
      .catch(reject)
  })
}

Extras.createFolderOnProject = function (obj, opts) {
  let entry = {
    fullpath: '/',
    mtimeMs: 0,
    name: '(dummy list)',
    project: obj.project,
    relativePath: opts.relativePath,
    size: 0,
    userId: opts.userId,
  }
  let that = this
  return new Promise(function (resolve, reject) {
    let file = that.newDownloadFile(entry)
    file
      .getParams({ browse: true })
      .then(function (params) {
        let options = {
          body: Object.assign({}, params, opts),
          json: true,
          // agent: noKeepAliveAgent,
          rejectUnauthorized: false,
          timeout: 3000,
          url: params.target + '/v1/tree/directory/new',
        }

        http
          .post(options.url, options.body)
          .pipe(
            catchError((err: any) => {
              if (['ESOCKETTIMEDOUT', 'ECONNRESET'].includes(err?.code)) {
                console.log(
                  '/v1/tree/directory/new POST request failed with ECONNRESET/ESOCKETTIMEDOUT',
                  err,
                  options?.url
                )
                if (!obj.resetRetry) obj.resetRetry = 0
                if (obj.resetRetry < MAX_TOKEN_RETRY) {
                  obj.resetRetry++
                  setTimeout(() => that.createFolder(obj, opts).then(resolve).catch(reject), 1000)
                  return
                }
              }
              return of({})
            })
          )
          .subscribe((data) => {
            resolve(data)
          })
      })
      .catch(reject)
  })
}

Extras.transcode = function (obj, opts) {
  return this.$encodeOrTranscode(obj, opts, 'transcode')
}

Extras.mediaEncoding = function (obj, opts) {
  return this.$encodeOrTranscode(obj, opts, 'media-encoding')
}

/*
Extras.$encodeOrTranscode = function (obj, opts, eventName) {
  let entry = {
    name: obj.name,
    fullpath: obj.path,
    relativePath: obj.relativePath,
    userId: opts.userId,
    project: obj.project,
    size: obj.size,
    mtimeMs: 0,
  }

  let that = this
  return new Promise(function (resolve, reject) {
    let file = that.newDownloadFile(entry)
    file
      .getParams({ browse: true })
      .then(function (params) {
        let options = {
          url: params.target + '/v1/tree/file/' + eventName,
          body: Object.assign({}, params, opts),
          //agent: keepAliveAgent,
          rejectUnauthorized: false,
          timeout: 3000,
          json: true,
        }



        request.post(options, function (err, httpResponse, body) {
          if (err || (httpResponse && httpResponse.statusCode !== 200)) {
            errorLog(
              `${eventName} POST request failed`,
              err || {
                body: body,
                status: httpResponse.statusCode,
                url: options.url,
              }
            )

            if (httpResponse && httpResponse.statusCode === 403) {
              file.downloadPlus.opts.tokenAgent.reset(Object.assign({}, params, { projectId: file.meta.project._id }))
              if (!obj.retry) obj.retry = 0
              if (obj.retry < MAX_TOKEN_RETRY) {
                obj.retry++
                return that.mediaEncoding(obj, opts).then(resolve).catch(reject)
              }
            }

            let msg = body && body.error ? body.error.msg : ''
            msg = msg || 'Fetch media encoding failed'
            return reject(new Error(msg))
          }
          resolve(body)
        })
      })
      .catch(reject)
  })
}
*/

Extras.fetchStats = function (obj, opts) {
  let entry = {
    fullpath: '/',
    mtimeMs: 0,
    name: '(dummy list)',
    project: obj.project,
    relativePath: '(dummy list)',
    size: 0,
    userId: opts.userId,
  }
  let that = this
  return new Promise(function (resolve, reject) {
    let file = that.newDownloadFile(entry)
    file
      .getParams({ browse: true })
      .then(function (params) {
        let options = {
          body: Object.assign({}, params, opts),

          json: true,

          //agent: keepAliveAgent,
          rejectUnauthorized: false,
          timeout: 3000,
          url: params.target + '/v1/tree/files/stat',
        }

        http
          .post(options.url, options.body)
          .pipe(
            catchError((err: HttpErrorResponse) => {
              errorLog('/v1/tree/files/stat POST request failed', err)

              if (err.status === 403) {
                file.downloadPlus.opts.tokenAgent.reset(Object.assign({}, params, { projectId: file.meta.project._id }))
                if (!obj.retry) obj.retry = 0
                if (obj.retry < MAX_TOKEN_RETRY) {
                  obj.retry++
                  return that.fetchStats(obj, opts).then(resolve).catch(reject)
                }
              }

              let msg = err.message || ''
              msg = msg || 'Fetch stats failed'
              reject(new Error(msg))

              return of({})
            })
          )
          .subscribe((data) => {
            resolve(data)
          })
      })
      .catch(reject)
  })
}

DownloadPlus.prototype = Object.assign({}, Events, Actions, LifeCycle, DNS, Extras)

/**
 * Remove value from array
 * @param array
 * @param value
 */
function arrayRemove(array, value) {
  let index = array.indexOf(value)
  if (index > -1) {
    array.splice(index, 1)
  }
}

function shieldSideEffect(fn) {
  try {
    fn()
  } catch (e) {
    errorLog('Error: ', e.message)
  }
}

function toStr(o) {
  try {
    return typeof o === 'string' ? o : JSON.stringify(o)
  } catch (e) {
    return o?.toString?.()
  }
}

function sleep(tsInMillis = 50) {
  return new Promise<void>((r) => setTimeout(r, tsInMillis ?? 50))
}

function errorLog(...e) {
  let instance = console as any
  instance?.errorLog?.(...e)
}

function warnLog(...e) {
  let instance = console as any
  instance?.warnLog?.(...e)
}
