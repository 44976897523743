<!-- <div class="folder-list">
  <div *ngFor="let folder of folders" class="folder-item" (click)="onFolderClick(folder.prefix)">
    📁 {{ folder.name }}
  </div>
</div>

<div class="file-list">
  <div *ngFor="let file of files" class="file-item">
    {{ file.name }} ({{ file.fileType }})
  </div>
</div>

<div *ngIf="hasMore" class="load-more">
  <button (click)="loadMoreFiles()" [disabled]="isLoading">
    Load More
  </button>
</div> -->


<div class="container">

  <div class="loader" *ngIf="isLoading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <!-- Folders -->
  <div class="folders-gallery-entries">
    <ng-container *ngFor="let file of filteredFolders">
      <card-file [isArchive]="true" [type]="viewType" (actionFile)="onActionFile($event, file)" [activeProject]="project"
        [file]="file" *ngIf="file.type === 'folder'"></card-file>
    </ng-container>
  </div>

  <div class="file-gallery-entries" [class.files-grid]="viewType === 'grid'" [class.files-list]="viewType === 'list'">
    <ng-container *ngFor="let file of filteredFiles;let i = index; let isFirst = first; let isLast = last">
      <div [class.last]="i == items?.length - 1" [class.first]="i == 0" *ngIf="file.type !== 'folder'">
        <card-file [isArchive]="true" [type]="viewType" [isFirst]="isFirst" [isLast]="isLast"
          (actionFile)="onActionFile($event, file)" [file]="file"></card-file>
      </div>
    </ng-container>



    <!-- <div class="pagination" *ngIf="items.length > 0"> -->
    <div class="pagination"
      *ngIf="(!(buttonPreviousDisabled$ | async) || !(buttonNextDisabled$ | async)) && items.length > 0">
      <cree8-btn-icon size="small" [disabled]="buttonPreviousDisabled$ | async" (click)="onClickPreviousPage()"
        title="previous page">
        <svg class="ico" viewBox="0 0 24 24">
          <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Chevron_Single_Backward'"></use>
        </svg>
      </cree8-btn-icon>

      <cree8-btn-icon size="small" [disabled]="buttonNextDisabled$ | async" (click)="onClickNextPage()"
        title="next page">
        <svg class="ico" viewBox="0 0 24 24">
          <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Chevron_Single_Forward'"></use>
        </svg>
      </cree8-btn-icon>
    </div>
  </div>

  <no-data [title]="'No files yet?'" [icon]="'Folder_Single'"
    [description]="'There are no files in this project yet.'"
    *ngIf="items?.length < 1 && !isLoading"></no-data>
</div>
