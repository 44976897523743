<div class="bebop-client-release-notes">
  <div class="header">
    <div class="title-info">
      <span class="h3">WHAT'S NEW</span>
      <span class="release-date small">{{ note.releaseDateStr }}</span>
    </div>
    <div class="close-action">
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="ok()">
        <path
          d="M17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289Z"
          fill="#7f7f8c"
        />
      </svg>
    </div>
  </div>

  <div class="release-body">
    <div class="release-notes">
      <div class="media-section" *ngIf="mediaTag">
        <div [innerHtml]="mediaTag | safeHtml" class="release-notes-media"></div>
      </div>
      <div class="spoiler-section">
        <div class="h4 release-note-label">
          {{ title || 'SPOILER AHEAD' }}
          <div class="divider"></div>
        </div>
      </div>
      <div class="release-section" [class.no-media]="!mediaTag">
        <div [innerHtml]="releaseNotes" class="paragraph release-notes-content"></div>
      </div>

      <!-- <div class="prev-section">
        <span class="update pargraph">Missed an update?</span>
        <div class="changelog paragraph" (click)="changelog()">Check out our previous change logs</div>
      </div> -->
    </div>
  </div>
</div>
