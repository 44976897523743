import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { BebopConfigService } from '../services/bebop-config.service'

export interface LookerReportResponse {
  success: boolean
  embedUrl?: string
  error?: string
  lastUpdated?: string
}

@Injectable({
  providedIn: 'root',
})
export class LookerAuthService {
  private apiUrl: string

  constructor(
    private http: HttpClient,
    private configService: BebopConfigService
  ) {
    this.apiUrl = this.configService.apiUrl
  }

  /**
   * Get the Looker Studio report URL
   * This will return a URL that can be used to open the report in a new window or iframe
   */
  getReport(orgId: string): Observable<LookerReportResponse> {
    return this.http
      .get<LookerReportResponse>(`${this.apiUrl}/api/v1/looker/report/${orgId}`)
      .pipe(catchError((error) => throwError(() => error.error || error)))
  }
}
