import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { debounceTime, distinctUntilChanged, Observable, Subject, Subscription } from 'rxjs'

/**
Example

    <bebop-input-select placeholder="Select Project" [value]="select.value" [onSearch]="onInputSelectSearch">
      <div dropdown-icon>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99999 3.33268C4.07952 3.33268 3.33332 4.07887 3.33332 4.99935V14.9993C3.33332 15.9198 4.07952 16.666 4.99999 16.666H15C15.9205 16.666 16.6667 15.9198 16.6667 14.9993V7.81185C16.6667 6.89137 15.9205 6.14518 15 6.14518H10.892C10.0561 6.14518 9.2755 5.72743 8.81184 5.03193L7.92639 3.70377C7.77184 3.47193 7.51164 3.33268 7.23302 3.33268H4.99999ZM1.66666 4.99935C1.66666 3.1584 3.15904 1.66602 4.99999 1.66602H7.23302C8.0689 1.66602 8.84948 2.08377 9.31314 2.77927L10.1986 4.10743C10.3531 4.33926 10.6133 4.47852 10.892 4.47852H15C16.8409 4.47852 18.3333 5.9709 18.3333 7.81185V14.9993C18.3333 16.8403 16.8409 18.3327 15 18.3327H4.99999C3.15904 18.3327 1.66666 16.8403 1.66666 14.9993V4.99935Z" fill="#5A5A68"/>
        </svg>
      </div>

      <div dropdown-options>
        <bebop-input-select-option *ngFor="let item of select.items" (click)="onSelectItem(item)">
          <div option-icon>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.00001 1.66732C2.26363 1.66732 1.66668 2.26427 1.66668 3.00065V11.0007C1.66668 11.737 2.26363 12.334 3.00001 12.334H11C11.7364 12.334 12.3333 11.737 12.3333 11.0007V5.25065C12.3333 4.51427 11.7364 3.91732 11 3.91732H7.71359C7.04488 3.91732 6.42042 3.58312 6.04949 3.02672L5.34113 1.96418C5.21749 1.77872 5.00933 1.66732 4.78643 1.66732H3.00001ZM0.333344 3.00065C0.333344 1.52789 1.52725 0.333984 3.00001 0.333984H4.78643C5.45514 0.333984 6.0796 0.668187 6.45053 1.22458L7.15889 2.28712C7.28253 2.47258 7.49069 2.58398 7.71359 2.58398H11C12.4728 2.58398 13.6667 3.77789 13.6667 5.25065V11.0007C13.6667 12.4734 12.4728 13.6673 11 13.6673H3.00001C1.52725 13.6673 0.333344 12.4734 0.333344 11.0007V3.00065Z" fill="#2D5DE0"/>
            </svg>
          </div>

          <div option-text>
            {{ item.value }}
          </div>
        </bebop-input-select-option>
      </div>

    </bebop-input-select>


    select = {
      value: '',
      items: [{ value: 'test value 1' }, { value: 'test value 2' }, { value: 'test value 3' }, { value: 'test value 4' }]
    }

    // do bind(this) for this binding
    onInputSelectSearch(s: string) {

      return new Observable<string[]>(subscriber => {
        // subscriber.next('test value 1');
        // subscriber.next('test value 2');
        // subscriber.next('test value 3');
        setTimeout(() => {
          subscriber.next(['test value 1', 'test value 2', 'test value 3', 'test value 4']);
          subscriber.complete();
        }, 1000);
      });

    }

    onSelectItem(i: { value: string }) {
      this.select.value = i.value
    }


*/

const CHILD_FOCUS = 'BEBOP-INPUT-SELECT-OPTION'
@Component({
  selector: 'bebop-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
})
export class InputSelectComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  hasInputSelectIcon = true
  hasInputSelectDropdownIcon = true
  showInputGuide = false
  isOpen = true
  isSelectOnly = false
  searchText = ''

  searchChanged = new Subject<string>()
  private searchChanged$: Subscription
  private searchCallback$: Subscription

  @Input('value') value: string
  @Input('showInputLabel') showInputLabel: boolean
  @Input('placeholder') placeholder: string
  @Input('searchPlaceholder') searchPlaceholder: string
  @Input('debounceTime') debounceTime: string | number

  @Input('selectOnly') selectOnly: string
  @Input('disabled') disabled: boolean

  @Input('onSearch') onSearch: (s: string) => Observable<string[]>
  @Output('dropdownState') onDropdownChange = new EventEmitter<'Open' | 'Close'>()

  @ViewChild('inputSelectIcon') inputSelectIcon
  @ViewChild('inputSelectDropdownIcon') inputSelectDropdownIcon
  @ViewChild('inputSelectDropdown') inputSelectDropdown

  @Output('on-nav') onNav = new EventEmitter<'Up' | 'Down'>()
  @HostBinding('tabindex') tabindex = 0

  focus = false
  @HostListener('focus')
  focusHandler() {
    this.focus = true
  }

  @HostListener('blur')
  blurHandler() {
    this.focus = false
  }

  @HostListener('mouseover', ['$event'])
  onMouseHover(e: MouseEvent) {
    let target = e.target as HTMLElement

    while (target) {
      if (target.tagName == CHILD_FOCUS) {
        let parent = target.parentElement
        let idx = Array.from(parent.children).findIndex((c) => c == target)
        this.focusIndex = Math.max(idx, 0)
        Array.from(parent.children).forEach((c: HTMLElement) => {
          // c.blur()
          c.children?.[0]?.classList.remove('active')
        })
        break
      }
      target = target.parentElement
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(e: MouseEvent) {
    if (!this.isOpen) return
    this.el.nativeElement.focus()
    this.setFocusOrClick()
  }

  @HostListener('keyup.ArrowDown', ['$event'])
  onKeyArrowDown(e: KeyboardEvent) {
    if (!this.isOpen) return
    this.onNav.emit('Down')
    ++this.focusIndex
    this.setFocusOrClick()
  }


  @HostListener('keyup', ['$event'])
  onAnyKey(e: KeyboardEvent) {
    if (['Esc'].includes(e.key)) return

    if (!this.isOpen) {
      // if (this.focus) {
        this.onClickTopPanel()
      // }
      return
    }
  }

  @HostListener('keyup.enter', ['$event'])
  onKeyEnter(e: KeyboardEvent) {
    if (!this.isOpen) return
    this.setFocusOrClick(true)
  }

  @HostListener('keyup.esc', ['$event'])
  onKeyEsc(e: KeyboardEvent) {
    if (!this.isOpen) return
    this.reset()
  }

  focusIndex = 0
  @HostListener('keyup.ArrowUp', ['$event'])
  onKeyArrowUp(e: KeyboardEvent) {
    if (!this.isOpen) return
    this.onNav.emit('Up')

    --this.focusIndex
    this.setFocusOrClick()
  }

  onSearchFocus() {
    setTimeout(() => this.setFocusOrClick(), 50)
  }

  @ViewChild('dropdownOptions') dropdownOptions

  hasChildren = false
  setFocusOrClick(click: boolean = false) {
    let children = Array.from<HTMLElement>(this.dropdownOptions?.nativeElement?.querySelectorAll(CHILD_FOCUS))
    if (this.focusIndex < 0) this.focusIndex = 0
    if (this.focusIndex > children.length - 1) this.focusIndex = Math.max(children.length - 1, 0)

    this.hasChildren = children?.length != 0

    children?.forEach((c, i) => {
      let child = <HTMLElement>c.children?.[0]
      if (i == this.focusIndex) {
        if (click) c.click()
        else c.focus()
      } else {
        c.blur()
        child?.classList.remove('active')
      }
    })

    // if (children?.[this.focusIndex]) {
    //   if (click) children[this.focusIndex].click()
    //   else children[this.focusIndex].focus()
    // }
  }

  constructor(private cdRef: ChangeDetectorRef, private el: ElementRef) {}

  ngOnInit(): void {
    this.searchChanged$ = this.searchChanged
      .pipe(debounceTime(+this.debounceTime || 100)) //, distinctUntilChanged())
      .subscribe((text) => {
        this.searchCallback$?.unsubscribe()
        this.searchCallback$ = this.onSearch(text).subscribe((values) => {
          this.searchCallback$?.unsubscribe()
          // console.log(values)
        })
      })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.searchChanged$?.unsubscribe()
    this.searchCallback$?.unsubscribe()
  }

  ngAfterViewInit(): void {
    this.hasInputSelectIcon = this.inputSelectIcon?.nativeElement?.children?.length > 0
    this.hasInputSelectDropdownIcon = this.inputSelectDropdownIcon?.nativeElement?.children?.length > 0

    this.setFocusOrClick()

    this.isSelectOnly = this.selectOnly == 'true'
    this.reset()
    this.cdRef.detectChanges()
  }

  onSearchEntry(s: string) {
    this.searchChanged.next(this.searchText)
  }

  onClickTopPanel() {
    this.isOpen = !this.isOpen
    this.onDropdownChange.emit(this.isOpen ? 'Open' : 'Close')
    this.focusIndex = 0
    if (this.isOpen) {
      setTimeout(() => this.setFocusOrClick(), 100)
    }
  }

  onClickOutside(e: MouseEvent) {
    if (!this.isOpen) return
    if (!this.inputSelectDropdown?.nativeElement?.parentElement) return

    let el = this.inputSelectDropdown?.nativeElement?.parentElement

    let target = <HTMLElement>e.target

    while (target) {
      if (target == el) break
      target = target.parentElement
    }

    if (target) return

    this.reset()

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']?.firstChange) return
    this.reset()

  }

  onClickDropdownInside(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()

    this.reset()
  }

  reset() {
    this.focusIndex = 0
    this.isOpen = false
    this.searchText = ''
    this.onDropdownChange.emit('Close')

    let targets = Array.from<HTMLElement>(this.el.nativeElement.querySelectorAll(CHILD_FOCUS))
    targets.forEach((c: HTMLElement) => {
      c.children?.[0]?.classList.remove('active')
    })
  }
}
