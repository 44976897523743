<div class="bebop-input-widget" [class]="{ disabled: isDisabled }">
  <div class="input-top-section" tabindex="-1" *ngIf="showInputLabel">
    <div class="input-sm">
      <ng-content select="[input-label]"></ng-content>
    </div>

    <div class="input-sm" #inputGuide tabindex="-1" *ngIf="showInputGuide">
      <ng-content select="[input-guide]"></ng-content>
    </div>
  </div>

  <div class="input-main-section" [class.error]="error" [class]="{ borderless: !border, textarea: textarea }">
    <div class="input-main-icon" #inputIconLeft *ngIf="showInputIconLeft"
      [class]="{ filled: binding && (binding + '').length != 0 }">
      <ng-content select="[input-icon-left]"></ng-content>
    </div>

    <div class="icon-separator" *ngIf="isLeftIconSeparator"></div>

    <div class="input-main-editor">
      <ng-container *ngIf="textarea">
        <textarea [attr.cols]="textareaCols || 80" [attr.rows]="textareaRows || 4" [class]="textareaResize || 'none'"
          [bebopAutoFocus]="isAutoFocused" [placeholder]="placeholder" [disabled]="isDisabled" class="body3"
          [(ngModel)]="binding" (keyup)="onChangeUserInput(); onKeyup($event)" (click)="onClick($event)"
          (dblclick)="onDblclick($event)" (submit)="onSubmit()" (blur)="onBlur($event)" (focus)="onFocus($event)"
          (scroll)="onScroll($event)" (cut)="onCut($event)" (copy)="onCopy($event)" (paste)="onPaste($event)"
          (keypress)="onKeypress($event)" (keydown)="onKeydown($event)" (mouseup)="onMouseup($event)"
          (mousedown)="onMousedown($event)" (mouseenter)="onMouseenter($event)" (drag)="onDrag($event)"
          (drop)="onDrop($event)" (dragover)="onDragover($event)">
        </textarea>
      </ng-container>

      <ng-container *ngIf="!textarea">
        <input [bebopAutoFocus]="isAutoFocused" [type]="hidePasswordField ? type : 'text'" [placeholder]="placeholder"
          [disabled]="isDisabled" class="body3" [(ngModel)]="binding" (keyup)="onChangeUserInput(); onKeyup($event)"
          (click)="onClick($event)" (dblclick)="onDblclick($event)" (submit)="onSubmit()" (blur)="onBlur($event)"
          (focus)="onFocus($event)" (scroll)="onScroll($event)" (cut)="onCut($event)" (copy)="onCopy($event)"
          (paste)="onPaste($event)" (keypress)="onKeypress($event)" (keydown)="onKeydown($event)"
          (mouseup)="onMouseup($event)" (mousedown)="onMousedown($event)" (mouseenter)="onMouseenter($event)"
          (drag)="onDrag($event)" (drop)="onDrop($event)" (dragover)="onDragover($event)" />
      </ng-container>
    </div>
    <div class="input-password-icon" *ngIf="isPasswordField" (click)="togglePasswordFieldView()">
      <svg class="ico" viewBox="0 0 24 24" style="width:16px;height:16px;" *ngIf="hidePasswordField">
        <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Hidden'"></use>
      </svg>

      <svg class="ico" viewBox="0 0 24 24" style="width:16px;height:16px;" *ngIf="!hidePasswordField">
        <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Revealed'"></use>
      </svg>
    </div>
    <div class="input-main-icon" #inputIconRight *ngIf="showInputIconRight">
      <ng-content select="[input-icon-right]"></ng-content>
    </div>
    <div class="input-clear-icon"
      *ngIf="isClearActionEnabled && ((binding && (binding + '').length != 0) || showIconOnFocus )"
      (click)="clearUserInput()">
      <svg class="ico" viewBox="0 0 24 24" style="width:16px;height:16px;">
        <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Xmark_Single'"></use>
      </svg>
    </div>
  </div>


  <!-- error section -->
  <div class="input-bottom-section" [class.no-error]="!error" *ngIf="inputErrorLabelAvailable">
    <div class="error-section">
      <ng-content select="[input-error-icon]" #inputErrorIcon *ngIf="inputErrorIconAvailable && error"></ng-content>
      <div class="error-icon" *ngIf="!inputErrorIconAvailable && inputErrorLabelAvailable && error">
        <svg class="ico" viewBox="0 0 24 24">
          <use [attr.xlink:href]="'app/common/assets/icons/_sprite.svg#Caution'"></use>
        </svg>
      </div>
      <div class="error-description captions" #inputErrorLabel tabindex="-1" *ngIf="inputErrorLabelAvailable">
        <ng-content select="[input-error-label]"></ng-content>
      </div>
    </div>
  </div>

</div>
