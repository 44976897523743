import { Component, EventEmitter, Output } from '@angular/core'

import { Subject, takeUntil } from 'rxjs'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { SelectDrive } from 'src/app/components/rocket/project/modals/add-project-modal/add-project-modal.component'
import { Project } from 'src/app/models/bebop.model'
import { PayloadUpdateProjectSettings } from 'src/app/models/projects.model'
import { UiPod, UiStorage } from 'src/app/models/ui.model'
import { ProjectsService } from 'src/app/services/projects.service'
import { ProjectsQuery } from 'src/app/store/projects/projects.query'
import { ProjectsService as ProjectsStoreService } from 'src/app/store/projects/projects.service'
import { isErrorResponse } from 'src/app/utils/response-utils'

@Component({
  selector: 'cree8-project-files-settings',
  styleUrl: './project-files-settings.component.scss',
  templateUrl: './project-files-settings.component.html',
})
export class ProjectFilesSettingsComponent {
  @Output() successSubmit: EventEmitter<boolean> = new EventEmitter<boolean>()
  project: Project
  private destroy$ = new Subject()

  isLoading = false

  formErrors = {
    driveLetter: '',
    folderLabel: '',
    name: '',
    pod: '',
    storage: '',
  }

  filteredPodCount = 0

  pods: UiPod[] = []
  storages: UiStorage[] = []

  drives: SelectDrive[] = Array.from({ length: 22 }).map((m, i) => ({
    name: String.fromCharCode(65 + 4 + i),
    selected: false,
  }))

  bindings: {
    name: string
    folderLabel: string
    driveLetter: string
    storage: UiStorage
    pod: UiPod
    autoMount: boolean
    readOnly: boolean
    enableProjectLocking: boolean
  } = {
    autoMount: false,
    driveLetter: '',
    enableProjectLocking: false,
    folderLabel: '',
    name: '',
    pod: null,
    readOnly: false,
    storage: null,
  }

  constructor(
    private toastService: ToastService,
    private projectQuery: ProjectsQuery,
    private projectsStoreService: ProjectsStoreService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.projectQuery
      .getSelectedProject()
      .pipe(takeUntil(this.destroy$))
      .subscribe((project) => {
        this.project = project
        this.bindings = {
          ...this.bindings,
          autoMount: project.autoMount,
          driveLetter: project.preferredLetter,
          enableProjectLocking: project.mountPermissions.canLock,
          folderLabel: project.folderLabel,
          name: project.name,
          pod: project.pod,
          readOnly: project.mountPermissions.readOnly,
          storage: project.storage,
        }

        this.drives.forEach((d) => (d.selected = d.name.toLowerCase() == this.bindings.driveLetter.toLowerCase()))
        this.updateDrives(this.bindings.driveLetter)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  updateDrives(label: string) {
    const startIndex = this.drives.findIndex((d) => d.name.toLowerCase() === label.toLowerCase())

    if (startIndex !== -1) {
      // Rearrange the drives array starting from the preferredLetter
      this.drives = [...this.drives.slice(startIndex), ...this.drives.slice(0, startIndex)]
    }

    // Update the selected status for the drives
    this.drives.forEach((d) => {
      d.selected = d.name.toLowerCase() == this.bindings.driveLetter.toLowerCase()
    })
  }

  applyRules(_for: 'folderLabel' | 'name' | 'pod' | 'driveLetter' | 'all' = 'all') {
    let forAll = _for == 'all'
    if (forAll || _for == 'folderLabel') {
      if (this.formErrors.folderLabel && this.bindings.folderLabel?.trim()) {
        this.formErrors.folderLabel = ''
      }

      if (this.bindings.folderLabel.match(/[^\w]/i)) {
        this.formErrors.folderLabel = 'Folder label: No special characters allowed.'
      } else if ((this.bindings.folderLabel?.trim?.()?.length ?? 0) > 20) {
        this.formErrors.folderLabel = 'Folder label: Max length is 20 characters.'
      } else if (!this.bindings.folderLabel?.trim()) {
        this.formErrors.folderLabel = 'Folder label can not be empty.'
      } else {
        this.formErrors.folderLabel = ''
      }
    }

    if (forAll || _for == 'name') {
      let tname = this.bindings.name?.trim()
      // special characters allowed ?
      if (!tname) {
        this.formErrors.name = 'Name can not be empty.'
      } else if ((tname?.length ?? 0) > 50) {
        this.formErrors.name = 'Name: Max length is 50 characters.'
      } else if ((tname?.length ?? 0) < 5) {
        this.formErrors.name = 'Name: Min length is 5 characters.'
      } else {
        this.formErrors.name = ''
      }
    }

    if (forAll || _for == 'pod') {
      this.formErrors.pod = this.bindings.pod ? '' : 'Pod is not selected.'
    }

    if (forAll || _for == 'driveLetter') {
      this.formErrors.driveLetter = this.drives.some((d) => d.selected) ? '' : 'Drive letter is not selected.'
    }
  }

  validate() {
    this.applyRules()

    if (this.formErrors.name) {
      this.toastService.show({
        text: this.formErrors.name,
        type: 'error',
      })
      return false
    }

    if (this.formErrors.pod) {
      this.toastService.show({
        text: this.formErrors.pod,
        type: 'error',
      })
      return false
    }

    if (this.formErrors.folderLabel) {
      this.toastService.show({
        text: this.formErrors.folderLabel,
        type: 'error',
      })

      return false
    }

    if (this.formErrors.driveLetter) {
      this.toastService.show({
        text: this.formErrors.driveLetter,
        type: 'error',
      })
      return false
    }

    return true
  }

  saveSettings() {
    if (this.validate()) {
      this.isLoading = true

      const payload: PayloadUpdateProjectSettings = {
        autoMount: this.bindings.autoMount,
        folderLabel: this.bindings.folderLabel,
        mountPermissions: {
          enableProjectLocking: this.bindings.enableProjectLocking,
          readOnly: this.bindings.readOnly,
        },
        name: this.bindings.name,
        preferredLetter: this.bindings.driveLetter,
      }

      this.projectsService
        .updateProjectSettings(this.project._id, payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (isErrorResponse(response)) {
            this.isLoading = false
            this.toastService.show({
              text: response.error.msg,
              type: 'error',
            })
          } else {
            this.isLoading = false
            //need to reload the project from this api service since return has invalid storage object
            this.projectsService
              .getProject(response.project._id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((response) => {
                this.projectsStoreService.setSelectedProject(response)
              })

            this.toastService.show({
              text: response.msg,
              type: 'success',
            })
            this.successSubmit.emit(true)
          }
        })
    }
  }

  onSelectDrive(dr: SelectDrive) {
    this.drives.forEach((d) => (d.selected = d.name == dr.name))
    this.bindings.driveLetter = dr.name
  }

  onKeyupProjectName(ev: KeyboardEvent) {
    this.applyRules('name')
  }

  onKeyupFolderLabel(ev: KeyboardEvent) {
    this.applyRules('folderLabel')
  }

  searchStorage = ''
  filteredStorageCount = 0

  resetAndFetchStorage() {
    this.resetStorage()
  }

  resetStorage() {
    this.bindings.storage = null
    this.storages = []
    this.filteredStorageCount = 0
    this.searchStorage = ''
  }

  onChangeAutoMount() {
    this.bindings.autoMount = !this.bindings.autoMount
  }

  onChangeReadOnly() {
    this.bindings.readOnly = !this.bindings.readOnly
  }

  onChangeEnableProjectLocking() {
    this.bindings.enableProjectLocking = !this.bindings.enableProjectLocking
  }
}
