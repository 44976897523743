import { trigger, state, style, transition, animate, useAnimation } from '@angular/animations'
import { Component, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { UtilsService } from '../../services/utils.service'
import { slideLeftIn, slideLeftOut, slideRightIn, slideRightOut } from '../../animations/carousel-animations'
import { SafeUrl } from '@angular/platform-browser'

export interface Slide {
  src: SafeUrl | string
}

@Component({
  selector: 'bebop-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
  animations: [
    trigger('slideAnimation', [
      // TODO - fix this: going back and forth needs extra state
      // unidirectional
      transition('void => slideLeft', [useAnimation(slideRightIn, { params: { time: '500ms' } })]),
      transition('slideLeft => void', [useAnimation(slideRightOut, { params: { time: '500ms' } })]),
    ]),
  ],
})
export class ImageCarouselComponent implements OnInit, OnDestroy {
  @Input('slides') slides: Slide[]
  @Input('no-control') noControl: string | boolean
  @Input('auto-slide') autoSlide: string | number

  current = 0

  timerHandle = -1

  animDirection = 'slideLeft'

  speedX = 0.5

  noAnim = true

  get hasControl() {
    return !this.util.isTrueLike(this.noControl)
  }

  get isDisabled() {
    return !this.hasControl && this.timerHandle == -1
  }

  @HostBinding('tabindex') tabindex = 0;

  @HostListener('mouseenter', ['$event'])
  onFocus(e) {
    this.noAnim = false
  }

  @HostListener('mouseleave')
  onBlur() {
    this.noAnim = true
  }

  constructor(private util: UtilsService) {}

  ngOnInit(): void {
    this.speedX = (Number.isNaN(+this.autoSlide) ? 1000 : +this.autoSlide) / 1000
  }

  ngOnDestroy(): void {
    if (this.timerHandle == -1) return
    window.clearInterval(this.timerHandle)
  }

  canGoPrev() {
    return this.current > 0
  }

  canGoNext() {
    return this.current + 1 < this.slides?.length
  }

  onPrevious() {
    if (!this.canGoPrev()) return
    this.current -= 1
  }

  goToSlide(i: number) {
    if (i < 0 || i >= this.slides?.length) return
    this.current = i
  }

  onNext() {
    if (!this.canGoNext()) return
    this.current += 1
  }
}
