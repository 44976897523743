import { Injectable, Injector, Inject } from '@angular/core'
import { Overlay } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'

import { ToastComponent } from './toast.component'
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig, ToastRef } from './toast.types'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toasts: ToastRef[] = []
  bufferedToastsData: ToastData[] = []

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {}

  removeToast() {
    this.toasts = this.toasts.filter((t) => t.isVisible())

    this.toasts.forEach((t) => t.updatePosition())

    if (this.toasts.length < this.toastConfig.maxToastsInViewPort) {
      let dataBuffer = this.bufferedToastsData
      this.bufferedToastsData = []
      dataBuffer.forEach((b) => this.show(b))
    }
  }

  show(data: ToastData) {
    if (this.toasts.length >= this.toastConfig.maxToastsInViewPort) {
      this.bufferedToastsData.push(data)
      return null
    }

    this.toastConfig.timeout = data.duration || this.toastConfig.timeout
    const positionStrategy = this.getPositionStrategy()

    const overlayRef = this.overlay.create({ positionStrategy })

    const toastRef = new ToastRef(overlayRef, this)
    this.toasts.push(toastRef)

    const injector = this.getInjector(data, toastRef, this.parentInjector)
    const toastPortal = new ComponentPortal(ToastComponent, null, injector)

    overlayRef.attach(toastPortal)

    return toastRef
  }

  getPositionStrategy(toastRef?: ToastRef) {
    return this.overlay
      .position()
      .global()
      .top(this.getTopPosition(toastRef))
      .right(this.toastConfig.position.right + 'px')
  }

  getVisible(toastRef?: ToastRef) {
    this.toasts = this.toasts.filter((t) => t.isVisible())
    if (toastRef) {
      let id = toastRef.getId()
      let idx = this.toasts.findIndex((t) => t.getId() == id)
      if (idx != -1) idx = idx - 1
      if (idx >= 0) return this.toasts[idx].isVisible() ? this.toasts[idx] : null
      return null
    }

    return this.toasts.length && this.toasts[this.toasts.length - 1].isVisible()
      ? this.toasts[this.toasts.length - 1]
      : null
  }

  getTopPosition(toastRef?: ToastRef) {
    const lastToastIsVisible = this.getVisible(toastRef)
    const position = lastToastIsVisible
      ? lastToastIsVisible.getPosition().bottom + this.toastConfig.position.top
      : this.toastConfig.position.firstTop

    return position + 'px'
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    return Injector.create({
      parent: parentInjector,
      providers: [
        { provide: ToastData, useValue: data },
        { provide: ToastRef, useValue: toastRef },
      ],
    })
  }
}
