import { Component } from '@angular/core'

import { UploadConstants } from 'src/app/services/rocket/classes/rocket-constants'

@Component({
  selector: 'cree8-create-folder',
  styleUrl: './create-folder.component.scss',
  templateUrl: './create-folder.component.html',
})
export class CreateFolderComponent {
  bindings: {
    name: string
  } = {
    name: '',
  }

  formErrors = {
    name: '',
  }

  onKeyupProjectName(ev: KeyboardEvent) {
    this.validateFolderName()
  }

  validateFolderName() {
    this.bindings.name = this.bindings.name ? this.bindings.name.trim() : ''
    if (!this.bindings.name || this.bindings.name.length > UploadConstants.MAX_FOLDER_NAME_LENGTH) {
      this.formErrors.name = 'Folder name length should be between 1 and 50 characters'
      return false
    }

    let regEx = new RegExp('^([a-zA-Z0-9 ._()-]{1,50})$')

    if (!regEx.test(this.bindings.name)) {
      this.formErrors.name =
        'Folder name can only contain alphabets, numbers, a space, and the following characters . , _ - ( )'
      return false
    }

    this.formErrors.name = ''
    return true
  }
}
