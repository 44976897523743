import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { NotificationData } from './notification.types'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: NotificationData[] = []

  _notify = new BehaviorSubject<NotificationData[]>([])
  _openView = new BehaviorSubject<boolean>(false)

  constructor() {}

  getNotifications(fn: (n: NotificationData[]) => void) {
    return this._notify.subscribe(fn)
  }

  showOrHideNotification(fn: (n: boolean) => void) {
    return this._openView.subscribe(fn)
  }

  count() {
    return this.notifications?.length ?? 0
  }

  isDuplicate(n: NotificationData) {
    return this.notifications?.findIndex((x) => x.id == n.id)
  }

  hasNotification(n: NotificationData) {
    return this.notifications?.findIndex((x) => x.id == n.id) != -1
  }

  addNotification(n: NotificationData) {
    let idx = this.notifications?.findIndex((x) => x.id == n.id)
    if (idx != -1) {
      // consider this as replace instead of update so that caller do not need to tweak priority to show item on top
      this.notifications.splice(idx, 1)
    }

    let data = { ...n }

    if (!data.priority) data.priority = 0

    if (!data.template && !data.type) {
      data.type = 'info'
    }

    if (data.template && !data.templateContext) {
      data.templateContext = {}
    }

    // new entry will come first before applying priority
    this.notifications.unshift(data)
    this.notifications.sort((l, r) => (l.priority >= r.priority ? 0 : 1))

    if (n.autoShow) {
      this._openView.next(true)
    }

    this._notify.next(this.notifications)
    return true
  }

  removeNotification(n: NotificationData) {
    let idx = this.notifications?.findIndex((x) => x.id == n.id)

    if (idx == -1) return
    this.notifications.splice(idx, 1)
    // let the caller do any post clean up
    n.afterClose?.()
    this._notify.next(this.notifications)
  }

  removeAll() {
    if (!this.notifications?.length) return
    let ns = this.notifications
    this.notifications = []

    ns?.forEach(n => n?.afterClose?.())
    this._notify.next(this.notifications)
  }

  closeAll() {
    if (!this.notifications?.length) return
    let ns = this.notifications.filter(n => n.closeable)
    this.notifications = this.notifications.filter(n => !n.closeable)
  
    ns?.forEach(n => n?.afterClose?.())

    this._notify.next(this.notifications)
  }
}
