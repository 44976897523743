<div class="bebop-radio-button" [class.disabled]="disabled" [class.inactive]="model != value">
  <div class="radio-wrapper" (click)="select()">
    <div class="radio-icon" [class.inactive]="model != value">
      <svg viewBox="0 0 10 10" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5a5 5 0 1 1 10 0A5 5 0 0 1 0 5Z" fill="#2D5DE0" />
      </svg>
    </div>
    <div class="radio-label small">
      <ng-content select="[radio-label]"></ng-content>
    </div>
  </div>
  <div class="radio-description paragraph" #description *ngIf="isDescription">
    <ng-content select="[radio-desc]"></ng-content>
  </div>
</div>
