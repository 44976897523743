import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

import { Subject, takeUntil } from 'rxjs'
import { Cree8Modal } from 'src/app/common/components/cree8-modal/cree8-modal.component'
import { ToastService } from 'src/app/common/components/toast/toast.service'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { TeamService } from 'src/app/store/team/team.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

import { EntitlementsService } from '../../../../services/entitlements.service'
import { UserInviteService } from '../../../../services/user-invite.service'

@Component({
  selector: 'user-invite',
  styleUrls: ['./user-invite.component.scss'],
  templateUrl: './user-invite.component.html',
})
export class UserInviteComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>()

  @Input() active = false
  @Output() activeChange = new EventEmitter<boolean>()
  @Output() confirmed = new EventEmitter<void>()

  modal = {
    counting: 2,
    current: 1,
    disableNext: true,
    labelCancel: 'CANCEL',
    labelConfirm: 'NEXT',
    title: 'Invite team',
    type: 'confirmation',
  } as Cree8Modal

  selected = {
    entitlement: null,
    entitlementGroups: null,
    org: null,
    projects: [],
  }

  MAX_EMAILS = 10
  emails: any[] = []
  allGroups: any[] = []
  entitlementsAll: any[] = []
  entitlementsAllTemp: any[] = []
  entitlementGroups: any[] = []
  emailErrors: any[] = []
  projects: any[] = []
  validating = false
  formErrors = false
  allProjectsSelected = false // Variable to track if all projects are selected

  constructor(
    private uiQuery: UIQuery,
    private toastService: ToastService,
    // private notifications: NotificationsService,
    private projectsService: ProjectsService,
    private organizationsService: OrganizationsService,
    private entitlementsService: EntitlementsService,
    private userInviteService: UserInviteService,

    private teamService: TeamService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.init()
  }

  private init(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        if (org) {
          this.selected.org = org
          this.onSelectOrg()
        }
      })
  }

  onSelectOrg(): void {
    this.selected.entitlement = null
    this.selected.entitlementGroups = []
    this.listEntitlements()
    this.getEntitlementGroups()
    this.listProjects()
  }

  listEntitlements(): void {
    const query = {
      deactivated: false,
      page: 1,
      size: 100,
      sort: 'name',
    }
    this.entitlementsService
      .getEntitlements(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.entitlementsAllTemp = res.data || []
        this.getEntitlementCapacity()
      })
  }

  getEntitlementCapacity(): void {
    this.organizationsService
      .getOrgEntitlementCapacity()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const capacity = res
        this.entitlementsAll = this.entitlementsAllTemp
          .map((e) => {
            const match = capacity.find((c) => e._id === c.entitlement)
            if (!match) {
              e.capacity = 0
              e.allotted = 0
              return e
            }

            e.expires = match.capacity.expires
            e.capacity = match.capacity.total || 0
            e.allotted = match.capacity.allotted || 0
            e.availableSeats = Math.max(0, e.capacity - e.allotted)
            return e
          })
          .filter((e) => !!e.capacity)

        if (this.entitlementsAll.length === 1) {
          this.selected.entitlement = this.entitlementsAll[0]
          this.onSelectEntitlement()
        }
      })
  }

  listProjects(): void {
    const query = {
      page: 1,
      size: 25,
      sort: 'name',
    }

    this.projectsService
      .getProjects(query)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.projects = response.data || []
      })
  }

  getEntitlementGroups(): void {
    this.entitlementsService
      .getEntitlementGroupsForOrg(this.selected.org?._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.allGroups = response
        if (this.selected.entitlement) {
          this.onSelectEntitlement()
        }
      })
  }

  onSelectEntitlement(entitlement?: any): void {
    if (entitlement) this.selected.entitlement = entitlement

    // Check if allGroups is properly initialized
    if (!this.allGroups || !this.selected.entitlement || !this.selected.entitlement._id) {
      console.error('allGroups or selected entitlement is not defined')
      return
    }

    // Access entitlement groups using the entitlement ID
    this.entitlementGroups = this.allGroups[this.selected.entitlement._id] || []
    this.selected.entitlementGroups = []

    // Ensure MAX_EMAILS is set according to the available seats in the selected entitlement
    this.MAX_EMAILS = Math.min(10, this.selected.entitlement.availableSeats)
    this.checkNextButtonState() // Call the new function
  }

  onSelectGroup(isChecked: boolean, entitlementGroup: any): void {
    if (isChecked) {
      this.selected.entitlementGroups.push(entitlementGroup._id)
    } else {
      this.selected.entitlementGroups = this.selected.entitlementGroups.filter((e) => e !== entitlementGroup._id)
    }
    this.checkNextButtonState() // Call the new function
  }

  limitEmails(): void {
    const availableSeats = this.selected.entitlement.availableSeats
    if (this.emails.length > availableSeats) {
      this.emails = this.emails.slice(0, availableSeats) // Create a new array with the allowed number of emails
      this.toastService.show({
        text: 'Not enough seats available',
        type: 'error',
      })
    }

    if (this.emails.length > this.MAX_EMAILS) {
      this.emails = this.emails.slice(0, this.MAX_EMAILS) // Create a new array with the max emails allowed
      this.toastService.show({
        text: `Max users allowed per invite is ${this.MAX_EMAILS}`,
        type: 'error',
      })
    }
    this.checkNextButtonState() // Call the new function
  }

  isValidEmail = (email: string, emailList?: string[]): boolean | string => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    this.formErrors = true
    // Check for duplicate email
    if (emailList?.includes(email)) {
      const errorMessage = 'Duplicate email'
      this.toastService.show({ text: errorMessage, type: 'error' })
      return errorMessage
    }

    // Validate email format
    if (emailPattern.test(email)) {
      this.formErrors = false
      return true
    }

    // Show error message for invalid email
    const errorMessage = 'Invalid email'
    this.toastService.show({ text: errorMessage, type: 'error' })
    return errorMessage
  }

  ok(): void {
    const inviteData = {
      emails: this.emails,
      entitlement: this.selected.entitlement._id,
      entitlementGroupIds: this.selected.entitlementGroups,
      organization: this.selected.org._id,
      projects: this.selected.projects.map((p) => p._id),
    }

    const timeoutDuration = this.validating ? 200 : 0
    setTimeout(() => {
      this.userInviteService
        .sendInvite(inviteData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toastService.show({ text: 'Users Invited to Organization', type: 'success' })
          this.confirmed.emit()
          this.onCancel()
          this.teamService.triggerRefresh()
          this.teamService.updateActiveTab('invites')
          this.router.navigate(['app/team/invites'])
        })
    }, timeoutDuration)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onNext() {
    if (this.modal.current < this.modal.counting) {
      this.modal.current++
      if (this.modal.current == 2) this.modal.labelConfirm = 'SEND'
    } else {
      this.ok()
    }
  }

  onCancel() {
    this.modal.current = 1
    this.active = false
    this.activeChange.emit(false)
  }

  onCheckboxChange(isChecked: boolean, project: any): void {
    if (isChecked) {
      this.selected.projects.push(project)
    } else {
      this.selected.projects = this.selected.projects.filter((p) => p._id !== project._id)
    }
  }

  toggleAllProjects(checked: boolean): void {
    this.allProjectsSelected = checked
    if (checked) {
      // Select all project IDs
      this.selected.projects = this.projects
    } else {
      // Deselect all projects
      this.selected.projects = []
    }
  }

  checkNextButtonState(): void {
    if (this.modal.current === 1) {
      this.modal.disableNext = !(
        this.selected.entitlement &&
        this.emails.length > 0 &&
        this.selected.entitlementGroups.length > 0 &&
        !this.formErrors
      )
    } else {
      this.modal.disableNext = true // Disable next for other steps
    }
  }
}
