import { Component, Input, OnInit } from '@angular/core'
import { RocketType } from '../classes/rocket-types'

// TODO - this component is more generic than I thought, rename and move later
@Component({
  selector: 'bebop-empty-tab-state',
  templateUrl: './empty-tab-state.component.html',
  styleUrls: ['./empty-tab-state.component.scss'],
})
export class EmptyTabStateComponent implements OnInit {
  @Input('show') show: boolean

  @Input('type') type: RocketType | 'Broadcast' | 'Workstation' | 'Search Result'

  @Input('alert') alert: boolean

  constructor() {}

  ngOnInit(): void {
    if (!this.type) {
      console.error('<bebop-empty-tab-state> type attr is mandatory')
      this.type = 'Upload'
    }
  }
}
