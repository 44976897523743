import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { forkJoin } from 'rxjs'

import { NotificationService } from '../common/components/notification/notification.service'
import { NotificationData } from '../common/components/notification/notification.types'
import { IOrgSubscriptionDetails, IOrgUsage } from '../models/organization.model'
import { SessionQuery } from '../store/session/session.query'
import { UIQuery } from '../store/ui/ui.query'

import { ElectronService } from './electron.service'
import { OrganizationsService } from './organizations.service'

interface ResponseError {
  error: string
}

@Injectable({
  providedIn: 'root',
})
export class UsageNotificationsService {
  private readonly CHECK_INTERVAL = 1000 * 10 // 10 seconds (for testing)
  private readonly NOTIFICATION_THRESHOLDS = [80, 90, 95]
  private readonly NOTIFICATION_COOLDOWN = 3 * 24 * 60 * 60 * 1000 // 3 days in milliseconds
  private readonly PAYMENT_WARNING_WINDOW = 30 * 24 * 60 * 60 * 1000 // 30 days in milliseconds
  private monitoringInterval: any

  constructor(
    private organizationsService: OrganizationsService,
    private notificationService: NotificationService,
    private electronService: ElectronService,
    private uiQuery: UIQuery,
    private sessionQuery: SessionQuery,
    private router: Router
  ) {}

  startMonitoring() {
    this.stopMonitoring()
    this.checkUsage()
    this.monitoringInterval = setInterval(() => this.checkUsage(), this.CHECK_INTERVAL)
  }

  stopMonitoring() {
    if (this.monitoringInterval) {
      clearInterval(this.monitoringInterval)
      this.monitoringInterval = null
    }
  }

  private getNotificationKey(type: string, threshold: number): string {
    const userId = this.sessionQuery.getUserValue()?._id
    return `notification_${type}_${threshold}_${userId}`
  }

  private hasSeenNotification(type: string, threshold: number, nextPaymentDate: Date | string): boolean {
    const key = this.getNotificationKey(type, threshold)
    const lastNotified = localStorage.getItem(key)
    if (!lastNotified) return false

    const lastNotifiedTime = parseInt(lastNotified, 10)
    const now = Date.now()
    const paymentDate = nextPaymentDate instanceof Date ? nextPaymentDate : new Date(nextPaymentDate)

    const billingCycleStart = new Date(paymentDate)
    billingCycleStart.setDate(billingCycleStart.getDate() - 30)
    billingCycleStart.setHours(0, 0, 0, 0)

    if (lastNotifiedTime < billingCycleStart.getTime()) {
      localStorage.removeItem(key)
      return false
    }

    return now - lastNotifiedTime < this.NOTIFICATION_COOLDOWN
  }

  private markNotificationAsSeen(type: string, threshold: number): void {
    const key = this.getNotificationKey(type, threshold)
    localStorage.setItem(key, Date.now().toString())
  }

  private shouldShowNotification(usage: number): number | null {
    if (usage <= 0) {
      return null
    }

    const exceededThreshold = [...this.NOTIFICATION_THRESHOLDS].reverse().find((threshold) => usage >= threshold)

    return exceededThreshold || null
  }

  private isWithinPaymentWindow(nextPaymentDate: Date | string): boolean {
    const paymentDate = nextPaymentDate instanceof Date ? nextPaymentDate : new Date(nextPaymentDate)
    const now = new Date()
    const timeDiff = paymentDate.getTime() - now.getTime()

    const lastPaymentDate = new Date(paymentDate)
    lastPaymentDate.setDate(lastPaymentDate.getDate() - 30)
    lastPaymentDate.setHours(0, 0, 0, 0)
    const currentDateOnly = new Date(now)
    currentDateOnly.setHours(0, 0, 0, 0)
    const isAfterBillingStart = currentDateOnly.getTime() >= lastPaymentDate.getTime()
    const adjustedPaymentWindow = this.PAYMENT_WARNING_WINDOW * 1.02
    const isInWindow = timeDiff > 0 && isAfterBillingStart && timeDiff <= adjustedPaymentWindow

    return isInWindow
  }

  private removeAllUsageNotifications() {
    this.NOTIFICATION_THRESHOLDS.forEach((threshold) => {
      const workingStorageId = `working-storage-threshold-${threshold}`
      const archiveStorageId = `archive-storage-threshold-${threshold}`
      const workstationId = `workstation-threshold-${threshold}`

      this.notificationService.removeNotification({ id: workingStorageId } as NotificationData)
      this.notificationService.removeNotification({ id: archiveStorageId } as NotificationData)
      this.notificationService.removeNotification({ id: workstationId } as NotificationData)
    })
  }

  private checkUsage() {
    const selectedOrg = this.uiQuery.getSelectedOrgValue()
    if (!selectedOrg) return

    // Get both usage and subscription details
    forkJoin({
      subscription: this.organizationsService.getOrgSubscriptionDetails(selectedOrg._id),
      usage: this.organizationsService.getOrgUsage(),
    }).subscribe({
      complete: () => {},
      error: (error: Error) => {
        console.error('Failed to get organization data', error)
      },
      next: (res) => {
        const usage = res.usage as IOrgUsage | ResponseError
        const subscription = res.subscription as IOrgSubscriptionDetails | ResponseError

        if ('error' in usage || 'error' in subscription) {
          console.error(
            'Error getting organization data',
            'error' in usage ? usage.error : (subscription as ResponseError).error
          )
          return
        }
        const validUsage = usage as IOrgUsage
        const validSubscription = subscription as IOrgSubscriptionDetails

        // Remove all notifications if outside payment window or from previous billing cycle
        if (!this.isWithinPaymentWindow(validSubscription.nextPaymentDate)) {
          this.removeAllUsageNotifications()
          return
        }

        if ('workingStorage' in validUsage && 'archiveStorage' in validUsage && 'workstation' in validUsage) {
          this.checkUsageThresholds(validUsage, validSubscription.nextPaymentDate)
        } else {
          console.error('Invalid organization usage format')
        }
      },
    })
  }

  private checkUsageThresholds(usage: IOrgUsage, nextPaymentDate: Date | string): void {
    // Check working storage
    const workingStoragePercent = parseFloat(usage.workingStorage.percent)
    const workingThreshold = this.shouldShowNotification(workingStoragePercent)
    if (workingThreshold && !this.hasSeenNotification('working_storage', workingThreshold, nextPaymentDate)) {
      // this.showWorkingStorageNotification(usage.workingStorage, workingThreshold, nextPaymentDate)
    }

    // Check archive storage
    const archiveStoragePercent = parseFloat(usage.archiveStorage.percent)
    const archiveThreshold = this.shouldShowNotification(archiveStoragePercent)
    if (archiveThreshold && !this.hasSeenNotification('archive_storage', archiveThreshold, nextPaymentDate)) {
      // this.showArchiveStorageNotification(usage.archiveStorage, archiveThreshold, nextPaymentDate)
    }

    // Check workstation usage
    const workstationPercent = parseFloat(usage.workstation.percent)
    const workstationThreshold = this.shouldShowNotification(workstationPercent)
    if (workstationThreshold && !this.hasSeenNotification('workstation_usage', workstationThreshold, nextPaymentDate)) {
      // this.showWorkstationUsageNotification(usage.workstation, workstationThreshold, nextPaymentDate)
    }
  }

  private showWorkingStorageNotification(storage: any, threshold: number, nextPaymentDate: Date | string): void {
    this.notificationService.addNotification({
      closeable: true,
      id: `working-storage-threshold-${threshold}`,
      onClose: async () => {
        this.markNotificationAsSeen('working_storage', threshold)
        return true
      },
      onSelect: async () => {
        console.log('working-storage-threshold', threshold)
        await this.router.navigate(['/app/org-profile'], { skipLocationChange: false })
        this.markNotificationAsSeen('working_storage', threshold)
        return true
      },
      priority: 90,
      text: `Working Storage: ${Number(storage.used) / 1000}TB of ${Number(storage.purchased) / 1000}TB used (${threshold}% threshold exceeded)`,
      type: 'warning',
    })
    this.electronService.beep()
    new window.Notification('Usage Warning', {
      body: `Working Storage has exceeded ${threshold}% threshold`,
    })
  }

  private showArchiveStorageNotification(storage: any, threshold: number, nextPaymentDate: Date | string): void {
    this.notificationService.addNotification({
      closeable: true,
      id: `archive-storage-threshold-${threshold}`,
      onClose: async () => {
        this.markNotificationAsSeen('archive_storage', threshold)
        return true
      },
      onSelect: async () => {
        console.log('archive-storage-threshold', threshold)
        await this.router.navigate(['/app/org-profile'], { skipLocationChange: false })
        this.markNotificationAsSeen('archive_storage', threshold)
        return true
      },
      priority: 90,
      text: `Archive Storage: ${Number(storage.used) / 1000}TB of ${Number(storage.purchased) / 1000}TB used (${threshold}% threshold exceeded)`,
      type: 'warning',
    })
    this.electronService.beep()
    new window.Notification('Usage Warning', {
      body: `Archive Storage has exceeded ${threshold}% threshold`,
    })
  }

  private showWorkstationUsageNotification(workstation: any, threshold: number, nextPaymentDate: Date | string): void {
    this.notificationService.addNotification({
      closeable: true,
      id: `workstation-threshold-${threshold}`,
      onClose: async () => {
        this.markNotificationAsSeen('workstation_usage', threshold)
        return true
      },
      onSelect: async () => {
        console.log('workstation-threshold', threshold)
        await this.router.navigate(['/app/org-profile'], { skipLocationChange: false })
        this.markNotificationAsSeen('workstation_usage', threshold)
        return true
      },
      priority: 90,
      text:
        `Workstation Hours: ${Number(workstation.used)} of ${Number(workstation.purchased)} hours used ` +
        `(${threshold}% threshold exceeded)`,
      type: 'warning',
    })
    this.electronService.beep()
    new window.Notification('Usage Warning', {
      body: `Workstation usage has exceeded ${threshold}% threshold`,
    })
  }
}
