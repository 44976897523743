import { Component, Input, OnInit } from '@angular/core'
export type Step = {
  id: number
  active: boolean
}

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  constructor() {}

  @Input() steps: Step[] = []

  currentStep: number

  ngOnInit(): void {
    this.currentStep = this.steps.filter((step) => step.active).length
  }
}
