<nav [ngClass]="{ full: show }" [class.disable-overlay]="disableOverlay">
  <div class="navbar">
    <div class="nav-container">
      <div class="logo">
        <img
          [ngSrc]="images.LOGO"
          width="108"
          height="20"
          class="logo"
          alt="Cree8 | Creativity. Unleashed"
          (click)="onRedirectHome()"
        />
      </div>

      <div class="nav-item" [class.signup-route]="isSignupRoute">
        <ng-container *ngIf="isSignupRoute; else fullMenu">
          <div class="signup-nav-wrapper">
            <ul class="centered">
              <li
                class="item"
                *ngFor="let item of centerMenus"
                [class.has-dropdown]="hasDropdown(item)"
              >
                <ng-container *ngIf="hasDropdown(item); else regularItem">
                  <a class="dropdown-trigger">
                    {{ item.label }}
                    <svg
                      class="dropdown-arrow"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                    >
                      <path
                        d="M1 1L5 5L9 1"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="dropdown-menu" *ngIf="item.id === 'industries'">
                      <div class="industries-layout">
                        <div class="demo-column">
                          <h3>Let's Explore How CREE8 Fits Your Workflow.</h3>

                          <cree8-btn
                            (clicked)="redirectTo({
    id: 'demo',
    label: 'Book A Demo',
    href: 'https://calendly.com/cree8/cree8-demo?month=2024-07',
    type: 'item',
  })"
                            [fullwidth]="true"
                            >Book A Demo</cree8-btn
                          >
                        </div>
                        <div class="industries-grid">
                          <a
                            *ngFor="let card of industryCards"
                            [href]="card.href"
                            class="industry-card"
                          >
                            <div class="industry-icon">
                              <img [src]="card.image" [alt]="card.alt" />
                            </div>
                            <h3>{{ card.title }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="dropdown-menu" *ngIf="item.id === 'resources'">
                      <div class="resources-layout">
                        <a [href]="resourcesFeatured.href" class="image-column">
                          <img
                            [src]="resourcesFeatured.image"
                            [alt]="resourcesFeatured.alt"
                          />
                          <span class="image-caption">
                            {{ resourcesFeatured.caption }}
                          </span>
                        </a>
                        <ul class="resources-list">
                          <li *ngFor="let subItem of item.items">
                            <a
                              [href]="subItem.href"
                              [target]="subItem.target || '_self'"
                            >
                              {{ subItem.label }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </ng-container>
                <ng-template #regularItem>
                  <a [href]="item.href" [target]="item.target || '_self'">{{
                    item.label
                  }}</a>
                </ng-template>
              </li>
            </ul>

            <div class="right-side-buttons" *ngIf="rightMenus.length">
              <ng-container *ngFor="let item of rightMenus">
                <li
                  class="item"
                  *ngIf="item.type === 'item'"
                  (click)="redirectTo(item)"
                  [class.icon-only]="item.id === 'call'"
                >
                  <span
                    class="icon"
                    *ngIf="item.icon"
                    [innerHTML]="sanitizeIcon(item.icon)"
                  ></span>
                  <span *ngIf="item.id !== 'call'">{{ item.label }}</span>
                </li>
                <li *ngIf="item.type !== 'item'">
                  <cree8-btn (click)="redirectTo(item)">{{
                    item.label
                  }}</cree8-btn>
                </li>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #fullMenu>
          <ul>
            <ng-container *ngFor="let item of listMenus">
              <li
                class="item"
                *ngIf="item.type === 'item'"
                (click)="redirectTo(item)"
              >
                <span
                  class="icon"
                  *ngIf="item.icon"
                  [innerHTML]="item.icon"
                ></span>
                {{ item.label }}
              </li>
              <li *ngIf="item.type !== 'item'">
                <cree8-btn (click)="redirectTo(item)">{{
                  item.label
                }}</cree8-btn>
              </li>
            </ng-container>
          </ul>
        </ng-template>

        <div class="mobile-mode" (click)="toggle()">
          <div class="toggle">
            @if (!show) {
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon--bars"
            >
              <path
                d="M8.00049 20.0003H31.9999"
                stroke="#14141A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.00049 26.6663H31.9999"
                stroke="#14141A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99988 13.3333H31.9993"
                stroke="#14141A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            } @else {
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon--close"
            >
              <path
                d="M8 8L30 30"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M30 8L8 30"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="nav-item-mobile" *ngIf="show">
      <ul>
        <li
          class="item"
          (click)="onRedirectHome()"
          [class.active]="activeMenu === 'home'"
        >
          Home
        </li>
        <ng-container *ngFor="let item of listMenus">
          @if (item.type === 'item') {
          <li
            class="item"
            (click)="redirectTo(item)"
            [class.active]="activeMenu === item.label.toLowerCase()"
            [class.icon-only]="item.id === 'call'"
          >
            <span class="icon" *ngIf="item.icon" [innerHTML]="item.icon"></span>
            <span *ngIf="item.id !== 'call'">{{ item.label }}</span>
          </li>
          } @else {
          <li>
            <cree8-btn (clicked)="redirectTo(item)" [fullwidth]="true">{{
              item.label
            }}</cree8-btn>
          </li>
          }
        </ng-container>
      </ul>

      <div class="social-media-container">
        <p>Follow Us</p>
        <social-media></social-media>
      </div>
      <div class="copyright">
        <p>© 2024 Cree8. All Right Reserved</p>
        <p>2350 Keystone Dr El Dorado Hills CA 95762-9543</p>
      </div>
    </div>
  </div>
</nav>

<cree8-calendly
  *ngIf="showCalendly"
  (closeModal)="showCalendly = false"
></cree8-calendly>
