<div class="radio-button-group" [class.disabled]="isDisabled">
  <form [formGroup]="group" *ngIf="group">
    <bebop-radio-button
      [disabled]="isDisabled"
      (on-change)="onSelectValue($event)"
      [formControlName]="name"
      *ngFor="let item of items"
      [value]="item.value"
    >
      <div class="h5" radio-label>{{ item.name }}</div>
      <div radio-desc *ngIf="item.description">{{ item.description }}</div>
    </bebop-radio-button>
  </form>
</div>
