<div class="bebop-broadcasts">
  <div class="header-panel">
    <div class="header h1">
      Broadcasts
      <!--<div class="help-icon" (click)="openHelpCenter()">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          bebop-tooltip="Get Help"
          bebop-tooltip-placement="right"
          bebop-tooltip-type="info"
          bebop-tooltip-offset="4"
        >
          <path
            d="M11.29 15.29a1.573 1.573 0 0 0-.12.15.762.762 0 0 0-.09.18.643.643 0 0 0-.06.18 1.362 1.362 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 13 16a1 1 0 0 0-1.71-.71ZM12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16.001A8 8 0 0 1 12 20Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 12 9a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-.18A3 3 0 0 0 12 7Z"
            fill="#5A5A68"
          />
        </svg>
      </div>-->
    </div>
    <div class="action-panel">
      <div class="active-broadcasts h5">{{ total }} active broadcasters</div>

      <div class="divider"></div>
      <bebop-button outlined="true" icon-size="small" (on-click)="showInfo($event)" class="show-info">
        <svg viewBox="0 0 16 16" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.178 2.667a5.333 5.333 0 1 0 0 10.666 5.333 5.333 0 0 0 0-10.666ZM1.51 8a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 1.511 8Zm6.667-.667c.368 0 .666.299.666.667v2.667a.667.667 0 1 1-1.333 0V8c0-.368.299-.667.667-.667Zm0-2.666a.667.667 0 1 0 0 1.333h.006a.667.667 0 0 0 0-1.333h-.006Z"
            fill="#7F7F8C"
          />
        </svg>
      </bebop-button>
    </div>
  </div>

  <div class="broadcast-empty-grid anim-panel" *ngIf="total == 0">
    <bebop-empty-tab-state [show]="total == 0" type="Broadcast"> There are no live broadcast sessions happening right now. </bebop-empty-tab-state>
  </div>

  <div class="ndi-panel" *ngIf="total != 0 && ndi?.castActive">
    <div class="ndi-desc-panel">
      <div class="ndi-label h3">
        {{ ndi?.quality }} Cast from
        <span class="ws-name">{{ ndi.workstation?.DISPLAY_NAME || ndi.workstation?.NAME }}</span>
      </div>

      <bebop-button class="cast-disconnect-btn" size="xsmall" (on-click)="disconnectCast()"
        >Disconnect Cast</bebop-button
      >
    </div>
    <div class="ndi-data-panel small" [innerHtml]="ndi.message | safeHtml"></div>
  </div>

  <div class="broadcast-grid anim-panel" *ngIf="total != 0">
    <div class="grid-header">
      <div class="title grid-status">Status</div>
      <div class="title grid-source">Stream Source</div>
      <div class="title grid-user">User</div>
      <div class="title grid-runtime" (click)="sortBy('runtime')">
        Runtime
        <svg
          class="chevon-down"
          *ngIf="sort?.runtime == 'Down'"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7803 9.21967C18.0732 9.51256 18.0732 9.98744 17.7803 10.2803L12.5303 15.5303C12.2374 15.8232 11.7626 15.8232 11.4697 15.5303L6.21967 10.2803C5.92678 9.98744 5.92678 9.51256 6.21967 9.21967C6.51256 8.92678 6.98744 8.92678 7.28033 9.21967L12 13.9393L16.7197 9.21967C17.0126 8.92678 17.4874 8.92678 17.7803 9.21967Z"
            fill="white"
          />
        </svg>

        <svg
          class="chevon-up"
          *ngIf="sort?.runtime == 'Up'"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7803 15.5303C18.0732 15.2374 18.0732 14.7626 17.7803 14.4697L12.5303 9.21967C12.2374 8.92678 11.7626 8.92678 11.4697 9.21967L6.21967 14.4697C5.92678 14.7626 5.92678 15.2374 6.21967 15.5303C6.51256 15.8232 6.98744 15.8232 7.28033 15.5303L12 10.8107L16.7197 15.5303C17.0126 15.8232 17.4874 15.8232 17.7803 15.5303Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="title grid-clients">Clients</div>
      <div class="title grid-sesop">Session Operations</div>
    </div>
    <div class="grid-body">
      <div
        class="grid-row"
        *ngFor="let broadcast of broadcasts; let i = index"
        [class.ndi-active]="broadcast?.activeNdi"
      >
        <div class="paragraph grid-status-data">
          <bebop-tag label="true" bg-color="rgba(34, 34, 41, 0.84)" color="rgba(255, 255, 255, 1)">
            <div tag-icon>
              <svg
                class="pending-tag"
                *ngIf="broadcast.status == broadcastStatusEnum.Pending"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.87529 2.87517C3.93028 1.82018 5.38941 1.16663 7.00008 1.16663C8.61075 1.16663 10.0699 1.82018 11.1249 2.87517C12.1799 3.93016 12.8334 5.38929 12.8334 6.99996C12.8334 8.61063 12.1799 10.0698 11.1249 11.1247C10.0699 12.1797 8.61075 12.8333 7.00008 12.8333C5.38941 12.8333 3.93028 12.1797 2.87529 11.1247C1.8203 10.0698 1.16675 8.61063 1.16675 6.99996C1.16675 5.38929 1.8203 3.93016 2.87529 2.87517ZM7.00008 2.33329C5.71126 2.33329 4.54539 2.85499 3.70025 3.70013C2.85511 4.54527 2.33341 5.71113 2.33341 6.99996C2.33341 8.28879 2.85511 9.45465 3.70025 10.2998C4.54539 11.1449 5.71126 11.6666 7.00008 11.6666C8.28891 11.6666 9.45477 11.1449 10.2999 10.2998C11.1451 9.45465 11.6667 8.28879 11.6667 6.99996C11.6667 5.71113 11.1451 4.54527 10.2999 3.70013C9.45477 2.85499 8.28891 2.33329 7.00008 2.33329ZM7.00008 4.08329C7.32225 4.08329 7.58342 4.34446 7.58342 4.66663V6.75834L8.57923 7.75415C8.80703 7.98195 8.80703 8.3513 8.57923 8.57911C8.35142 8.80691 7.98208 8.80691 7.75427 8.57911L6.5876 7.41244C6.53167 7.35651 6.48948 7.29205 6.46101 7.22325C6.4327 7.15498 6.41698 7.08017 6.41675 7.00171C6.41675 7.00113 6.41675 7.00054 6.41675 6.99996V6.99956V4.66663C6.41675 4.34446 6.67792 4.08329 7.00008 4.08329Z"
                  fill="#2D5DE0"
                />
              </svg>

              <svg
                class="live-tag"
                *ngIf="broadcast.status == broadcastStatusEnum.Live"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.6667 9.91663C11.6667 10.8831 10.8832 11.6666 9.91671 11.6666H8.75004C8.42788 11.6666 8.16671 11.9278 8.16671 12.25C8.16671 12.5721 8.42788 12.8333 8.75004 12.8333H9.91671C11.5275 12.8333 12.8334 11.5275 12.8334 9.91663V4.08329C12.8334 2.47246 11.5275 1.16663 9.91671 1.16663H4.08338C2.47255 1.16663 1.16671 2.47246 1.16671 4.08329V4.66663C1.16671 4.98879 1.42788 5.24996 1.75004 5.24996C2.07221 5.24996 2.33338 4.98879 2.33338 4.66663V4.08329C2.33338 3.11679 3.11688 2.33329 4.08338 2.33329H9.91671C10.8832 2.33329 11.6667 3.11679 11.6667 4.08329V9.91663ZM1.7499 12.8333C2.07207 12.8333 2.33323 12.5721 2.33323 12.25C2.33323 11.9278 2.07207 11.6666 1.7499 11.6666C1.42773 11.6666 1.16657 11.9278 1.16657 12.25C1.16657 12.5721 1.42773 12.8333 1.7499 12.8333ZM3.4999 12.25C3.4999 11.1605 2.64322 10.5 1.74984 10.5C1.42767 10.5 1.1665 10.2388 1.1665 9.91663C1.1665 9.59446 1.42767 9.3333 1.74984 9.33329C3.1899 9.33329 4.66656 10.4227 4.66657 12.25C4.66657 12.5721 4.4054 12.8333 4.08323 12.8333C3.76107 12.8333 3.4999 12.5721 3.4999 12.25ZM1.74992 7.87496C4.33096 7.87496 6.11529 10.1006 5.88313 12.1429C5.84674 12.463 6.07673 12.752 6.39684 12.7884C6.71694 12.8248 7.00594 12.5948 7.04233 12.2747C7.356 9.51539 5.00217 6.70829 1.74992 6.70829C1.42775 6.70829 1.16658 6.96946 1.16658 7.29163C1.16658 7.61379 1.42775 7.87496 1.74992 7.87496Z"
                  fill="#32BA78"
                />
              </svg>
              {{ broadcast.status }}
            </div>
          </bebop-tag>
        </div>

        <div class="paragraph grid-source-data">
          {{ broadcast?.streamSource }}
        </div>

        <div class="paragraph grid-user-data">
          {{ broadcast?.username }}
        </div>

        <div class="paragraph grid-runtime-data" [class.running]="broadcast.status == broadcastStatusEnum.Live">
          {{ broadcast?.runtime }}
        </div>

        <div class="paragraph grid-clients-data">
          <div class="active-cnt h5" [class.active]="broadcast?.clients?.active != 0">
            {{ broadcast?.clients?.active || 0 }}
          </div>
          /
          <div class="total-cnt h5">{{ broadcast?.clients?.total || 0 }}</div>
        </div>

        <div class="paragraph grid-sesop-data">
          <!-- <div class="h5 definition" *ngIf="!broadcast?.activeNdi" [@switchAnim]="broadcast.quality"> -->
          <div class="h5 definition" *ngIf="!broadcast?.activeNdi">
            <div>
              <div
                class="high-def def"
                [class.disabled]="ndi?.castActive || broadcast.allowedQuality == 'SQ'"
                [class.active]="!ndi?.castActive && broadcast.quality == 'HQ'"
                (click)="!(ndi?.castActive || broadcast.allowedQuality == 'SQ') && highQuality($event, broadcast)"
                bebop-tooltip="{{
                  broadcast.allowedQuality == 'SQ' ? 'Please update the source broadcast to HQ to receive in HQ.' : ''
                }}"
                bebop-tooltip-placement="top"
                bebop-tooltip-type="info"
                bebop-tooltip-offset="4"
                bebop-tooltip-width="180"
                bebop-tooltip-tip-placement="center"
                bebop-tooltip-bg-border-color="rgba(255, 255, 255, 0.08)"
                bebop-tooltip-bg-color="#0C0C0F"
              >
                HQ
              </div>
            </div>

            <div
              class="std-def def"
              [class.disabled]="ndi?.castActive"
              [class.active]="!ndi?.castActive && broadcast.quality == 'SQ'"
              (click)="!ndi?.castActive && stdQuality($event, broadcast)"
            >
              SQ
            </div>
          </div>

          <div class="ndi-active" *ngIf="broadcast?.activeNdi">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="16" fill="#F1344B" fill-opacity="0.6" />
              <path
                d="M11.0922 11.5444L11.0378 11.4889C10.8137 11.2602 10.44 11.2879 10.2362 11.5444C9.20366 12.8476 8.66699 14.3933 8.66699 16.0015C8.66699 17.6027 9.14931 19.1485 10.2158 20.4516C10.4264 20.715 10.8204 20.7427 11.0582 20.5001C11.2688 20.2853 11.3028 19.9387 11.1126 19.7099C10.2498 18.6424 9.82184 17.2977 9.82184 16.0015C9.82184 14.7052 10.2566 13.3536 11.1126 12.293C11.2892 12.0712 11.296 11.7523 11.0922 11.5444Z"
                fill="white"
              />
              <path
                d="M20.9085 11.5444L20.9628 11.4889C21.187 11.2602 21.5606 11.2879 21.7644 11.5444C22.797 12.8476 23.3337 14.3933 23.3337 16.0015C23.3337 17.6027 22.8513 19.1485 21.7848 20.4516C21.5742 20.715 21.1802 20.7427 20.9424 20.5001C20.7319 20.2853 20.6979 19.9387 20.8881 19.7099C21.7508 18.6424 22.1788 17.2977 22.1788 16.0015C22.1788 14.7052 21.744 13.3536 20.8881 12.293C20.7115 12.0712 20.7047 11.7523 20.9085 11.5444Z"
                fill="white"
              />
              <path
                d="M18.5711 18.8895C18.8157 19.139 19.2165 19.1113 19.4271 18.8271C20.0384 18.0091 20.3577 17.0387 20.3577 16.0683C20.3034 15.0909 20.0113 14.1066 19.4067 13.2887C19.3604 13.2253 19.3013 13.1727 19.2334 13.1346C19.1654 13.0965 19.0903 13.0738 19.013 13.0678C18.9357 13.0619 18.8581 13.073 18.7854 13.1003C18.7127 13.1277 18.6465 13.1706 18.5915 13.2263L18.5371 13.2817C18.3537 13.4689 18.3198 13.7739 18.4828 13.9818C18.9583 14.6195 19.2029 15.3682 19.2029 16.0683C19.2029 16.81 18.9719 17.5447 18.5168 18.1339C18.3401 18.3557 18.3673 18.6815 18.5711 18.8895Z"
                fill="white"
              />
              <path
                d="M13.4295 18.8895C13.185 19.139 12.7842 19.1113 12.5736 18.8271C11.9622 18.0091 11.6429 17.0387 11.6429 16.0683C11.6973 15.0909 11.9894 14.1066 12.594 13.2887C12.6403 13.2253 12.6994 13.1727 12.7673 13.1346C12.8352 13.0965 12.9103 13.0738 12.9876 13.0678C13.0649 13.0619 13.1425 13.073 13.2153 13.1003C13.288 13.1277 13.3541 13.1706 13.4092 13.2263L13.4635 13.2817C13.6469 13.4689 13.6809 13.7739 13.5178 13.9818C13.0423 14.6195 12.7978 15.3682 12.7978 16.0683C12.7978 16.81 13.0287 17.5447 13.4839 18.1339C13.6605 18.3557 13.6333 18.6815 13.4295 18.8895Z"
                fill="white"
              />
              <path
                d="M16.0003 17.3334C16.7367 17.3334 17.3337 16.7364 17.3337 16C17.3337 15.2637 16.7367 14.6667 16.0003 14.6667C15.2639 14.6667 14.667 15.2637 14.667 16C14.667 16.7364 15.2639 17.3334 16.0003 17.3334Z"
                fill="white"
              />
            </svg>
          </div>

          <div>
            <svg
              class="copy-icon"
              (click)="copy(broadcast)"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              bebop-tooltip="Copy Stream Link"
              bebop-tooltip-placement="left"
              bebop-tooltip-type="info"
              bebop-tooltip-offset="4"
              bebop-tooltip-width="120"
              bebop-tooltip-bg-border-color="rgba(255, 255, 255, 0.08)"
              bebop-tooltip-bg-color="#0C0C0F"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 2C3.79086 2 2 3.79086 2 6V11C2 13.2091 3.79086 15 6 15H6.20833C6.76062 15 7.20833 14.5523 7.20833 14C7.20833 13.4477 6.76062 13 6.20833 13H6C4.89543 13 4 12.1046 4 11V6C4 4.89543 4.89543 4 6 4H11C12.1046 4 13 4.89543 13 6V6.20833C13 6.76062 13.4477 7.20833 14 7.20833C14.5523 7.20833 15 6.76062 15 6.20833V6C15 3.79086 13.2091 2 11 2H6ZM13 9C10.7909 9 9 10.7909 9 13V18C9 20.2091 10.7909 22 13 22H18C20.2091 22 22 20.2091 22 18V13C22 10.7909 20.2091 9 18 9H13ZM11 13C11 11.8954 11.8954 11 13 11H18C19.1046 11 20 11.8954 20 13V18C20 19.1046 19.1046 20 18 20H13C11.8954 20 11 19.1046 11 18V13Z"
                fill="#7F7F8C"
              />
            </svg>
          </div>

          <div>
            <svg
              class="open-link-icon"
              (click)="openExternal(broadcast)"
              viewBox="0 0 24 24"
              fill="none"
              bebop-tooltip="Open Stream Link"
              bebop-tooltip-placement="left"
              bebop-tooltip-type="info"
              bebop-tooltip-offset="4"
              bebop-tooltip-width="120"
              bebop-tooltip-bg-border-color="rgba(255, 255, 255, 0.08)"
              bebop-tooltip-bg-color="#0C0C0F"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 4.00001C21 3.44773 20.5523 3.00001 20 3.00001L14 3C13.4477 3 13 3.44772 13 4C13 4.55228 13.4477 5 14 5L17.6 5.00001L11.4076 11.1924C11.0171 11.5829 11.0171 12.2161 11.4076 12.6066C11.7981 12.9971 12.4313 12.9971 12.8218 12.6066L19.0024 6.42597L19.0084 9.8995C19.0084 10.4518 19.4561 10.8995 20.0084 10.8995C20.5607 10.8995 21.0084 10.4518 21.0084 9.8995L21 4.00001Z"
                fill="#7F7F8C"
              />
              <path
                d="M3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V15C21 14.4477 20.5523 14 20 14C19.4477 14 19 14.4477 19 15V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V7C5 5.89543 5.89543 5 7 5H9C9.55228 5 10 4.55228 10 4C10 3.44772 9.55228 3 9 3H7C4.79086 3 3 4.79086 3 7Z"
                fill="#7F7F8C"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
