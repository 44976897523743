import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { User } from '../models/bebop.model'
import { SessionQuery } from '../store/session/session.query'
import { SessionService } from '../store/session/session.service'
import { ElectronService } from './electron.service'


@Injectable({
  providedIn: 'root',
})
export class AlertLogService {
  loggedIn = false
  user: User

  constructor(
    private http: HttpClient,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private electronService: ElectronService
  ) {
    this.sessionQuery.getLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn
    })

    this.sessionQuery.getUser().subscribe((user) => {
      this.user = user
    })
  }


  errorCount = 0

  logActivity(msg: string, data?: any, logType: 'error' | 'warn' | 'log' | 'info' = 'log') {
    // if (this.consoleEnabled) this._console?.[logType]?.(msg, data)

    this.sessionService
      .logActivity({
        message: msg,
        user: this.user?.username,
        data: data,
        os: this.electronService.platform,
      })
      .subscribe((res) => {})
  }

}
