@if (showPasswordForm) {
  <div class="link-protect-form">
    <bebop-input
      class="input-password-confirm"
      type="password"
      [autofocus]="false"
      placeholder="Enter a password"
      [(binding)]="password"
    >
      <div input-label class="email-label">Enter a password</div>
    </bebop-input>

    <bebop-input
      class="input-password-confirm"
      type="password"
      [autofocus]="false"
      placeholder="Confirm password"
      [(binding)]="confirmPassword"
      (on-keyup)="onKeyupConfirmPassword($event)"
      [error]="errorConfirm.length > 0"
    >
      <div input-label class="email-label">Confirm Password</div>
      <div input-error-label>{{ errorConfirm }}</div>
    </bebop-input>
  </div>
} @else {
  <div class="link-protect">
    <div class="link-protect-header">
      <h2>Set link access restrictions:</h2>
    </div>

    <div class="link-protect-options">
      <cree8-list-options
        [options]="options"
        [selectedOption]="protectValue"
        (selectedOptionChange)="onProtectOptionSelected($event)"
      ></cree8-list-options>
    </div>
  </div>
}
