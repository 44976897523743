<ng-container *ngIf="file && file.type !== 'folder'">
  @if (type === "grid") {
  <cree8-card-file [title]="file.name" [folderSize]="file.sizeBytes"
    (titleClick)="onAction({ action: 'detail', data: file })" [image]="getThumbnailSrc(file)"
    [icon]="getThumbnailIcon(file)">
    <cree8-card-actions-list actions-menu (closeDropdown)="onCloseDropdown(file)" (openDropdown)="onOpenDropdown(file)"
      [data]="file" [actionsList]="actionsList()" [type]="'file'" [active]="file.ui?.dropDownActive"
      (action)="onAction($event)"></cree8-card-actions-list>
  </cree8-card-file>
  } @else {
  <div class="projects-container-list" *ngIf="file" [ngClass]="{ 'first-card': isFirst, 'last-card': isLast }">
    <div class="data" (click)="onAction({ action: 'detail', data: file })">
      <div class="left">
        <div class="thumbnail-projects">
          <div class="thumbnail-icon" [class.active]="file.type === 'video'">
            @if (file.type === 'video') {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Play"></use>
            </svg>
            } @else if (file.type === 'audio') {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Audio"></use>
            </svg>
            } @else if (file.type === 'image'){
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
            </svg>
            }
            @else {
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Generic_File"></use>
            </svg>
            }
          </div>

        </div>
        <span class="body2 file-name" [title]="file.name">{{
          file.name }}</span>
      </div>
      <div class="right">
        <span class="desc body3" [hidden]="!file?.sizeBytes">Size: {{ file.sizeBytes }}</span>

      </div>
    </div>
    <div class="options">
      <cree8-card-actions-list (closeDropdown)="onCloseDropdown(file)" (openDropdown)="onOpenDropdown(file)"
        [data]="file" [actionsList]="actionsList()" [type]="'file'" [active]="file.ui?.dropDownActive"
        (action)="onAction($event)"></cree8-card-actions-list>
    </div>
  </div>
  }
</ng-container>

<ng-container *ngIf="file && file.type === 'folder'">
  <cree8-card-folder [title]="file.name" [folderSize]="file.sizeBytes"
    (titleClick)="onAction({ action: 'detail', data: file })">
    <cree8-card-actions-list actions-menu (closeDropdown)="onCloseDropdown(file)" (openDropdown)="onOpenDropdown(file)"
      [data]="file" [actionsList]="actionsList()" [type]="'folder'" [active]="file.ui?.dropDownActive"
      (action)="onAction($event)"></cree8-card-actions-list>
  </cree8-card-folder>
</ng-container>
