import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

import { combineLatest, Subject, takeUntil } from 'rxjs'
import { User } from 'src/app/models/bebop.model'
import { MainService } from 'src/app/services/main.service'
import { OrganizationsService } from 'src/app/services/organizations.service'
import { UserService } from 'src/app/services/user.service'
import { TeamQuery } from 'src/app/store/team/team.query'
import { TeamService } from 'src/app/store/team/team.service'
import { UIQuery } from 'src/app/store/ui/ui.query'

@Component({
  selector: 'cree8-team-unassigned',
  styleUrl: './unassigned.component.scss',
  templateUrl: './unassigned.component.html',
})
export class UnassignedComponent implements OnInit {
  users: User[] = []
  selectedUser: User

  loading = false
  active = false

  defaultQuery = {
    page: 1,
    size: 50,
    sort: 'name',
  }

  private destroy$ = new Subject<void>()

  constructor(
    private router: Router,
    private userService: UserService,
    private mainService: MainService,
    private teamQuery: TeamQuery,
    private organizationsService: OrganizationsService,
    private teamService: TeamService,
    private uiQuery: UIQuery
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.uiQuery
      .getSelectedOrg()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org) => {
        this.loading = true
        this.users = []
        if (!org) {
          return
        }

        // init load of members
        this.loadUsers(this.defaultQuery)
        // refresh data based on action from action-list
        this.teamQuery
          .selectAll()
          .pipe(takeUntil(this.destroy$))
          .subscribe((state) => {
            if (state.action === 'refresh') {
              this.loadUsers(this.defaultQuery)
              this.teamService.clear()
            } else if (state.action === 'sort' || state.action === 'search') {
              this.loadUsers(state.queryParams)
              this.teamService.clear()
            }
          })
      })
  }
  loadUsers(query) {
    this.loading = true

    // Combine the entitlements and users observables into a single stream
    const entitlements$ = this.organizationsService.getOrgEntitlementsUsers()
    const users$ = this.userService.getUsers(query)

    combineLatest([entitlements$, users$])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        error: (err) => {
          console.error('Error loading users and entitlements', err)
          this.loading = false
        },
        next: ([entitlements, res]) => {
          const users = res.data

          // Categorize users into `assigned` and `unassigned` based on entitlements
          const { assigned, unassigned } = users.reduce(
            (acc, user) => {
              const isEntitled = entitlements.some((ent) => ent.user?._id === user._id)
              isEntitled ? acc.assigned.push(user) : acc.unassigned.push(user)
              return acc
            },
            { assigned: [], unassigned: [] }
          )

          this.users = unassigned // Store the unassigned users
          this.loading = false
        },
      })
  }

  getUserImage(user: any): any {
    // Get user image based on the activity's created_by
    if (user) {
      return this.mainService.getUserImage(user)
    } else {
      return this.mainService.getSystemIcon()
    }
  }

  onSettingsModal(selectedUser, event: Event) {
    event.stopPropagation()
    this.selectedUser = structuredClone(selectedUser)
    this.active = true
  }

  onCancelModal() {
    this.active = false
  }

  navigateToProfile(id: string): void {
    this.router.navigate([`app/profile/${id}`])
  }
}
