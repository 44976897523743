import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cree8-checkbox',
  templateUrl: './cree8-checkbox.component.html',
  styleUrls: ['./cree8-checkbox.component.scss']
})
export class Cree8CheckboxComponent {
  @Input() label: string = '';
  @Input() isChecked: boolean = false;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onCheckboxChange(checked: boolean) {
    this.isChecked = checked;
    this.isCheckedChange.emit(checked);
  }
}
