import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { catchError, of } from 'rxjs'
import { Pagination } from 'src/app/common/types'
import { RocketSession, UiTransferFile } from 'src/app/components/rocket/common/classes/rocket-types'
import {
  BebopLink,
  IPayloadSettingsLinks,
  ISettingsLinks,
  LinkProtection,
  LinkSettings,
  LinkType,
  ObjectId,
  UpdateLinkProtectPayload,
} from 'src/app/models/bebop.model'
import {
  GenericResponse,
  LinkAccessResponse,
  LinkCreateResponse,
  LinkListResponse,
  LinkResponse,
  LinkUpdateResponse,
} from 'src/app/models/response.model'
import { BebopConfigService } from 'src/app/services/bebop-config.service'
import { ElectronService } from 'src/app/services/electron.service'

import { createInitialState, LinkState, LinkStore } from './link.store'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
}

export const ALMOST_EXPIRY_WINDOW = 2 * 24 * 60 * 60 * 1000

@Injectable({ providedIn: 'root' })
export class LinkService {
  constructor(
    private store: LinkStore,
    private http: HttpClient,
    private bebopConfig: BebopConfigService,
    private electronService: ElectronService
  ) {}

  update(link: Partial<LinkState>) {
    this.store.update((store) => ({
      ...store,
      ...link,
    }))
  }

  updateSession(session: RocketSession<any, UiTransferFile>) {
    this.store.update((store) => ({
      ...store,
      session: session ? { ...session } : null,
    }))
  }

  reset() {
    this.store.update((store) => ({
      ...createInitialState(),
    }))
  }

  getLink(linkId: string, linkType: LinkType = LinkType.None) {
    let extra = linkType != LinkType.None ? `&linktype=${linkType}` : ''

    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/get-link/${linkId}?transferInfo=true${extra}`

    return this.http.get<LinkResponse>(url).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:getLink', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  listFlexLinks(qs: {
    readOnly?: boolean
    expired?: boolean
    almostExpired?: boolean
    pageOptions?: Pagination
    searchText?: string
    createdByYou?: boolean
    createdByOthers?: boolean
  }) {
    let params = new HttpParams().set('searchText', qs?.searchText || '')
    params = params.append('size', qs?.pageOptions?.size || 10)
    params = params.append('page', qs?.pageOptions?.page || 1)
    if (qs?.expired) params = params.append('expired', qs?.expired)
    if (qs?.createdByYou) params = params.append('createdBy', qs?.createdByYou)
    if (qs?.readOnly) params = params.append('readOnly', qs?.readOnly)
    if (qs?.createdByOthers) params = params.append('createdByOthers', qs?.readOnly)
    if (qs?.almostExpired) params = params.append('almostExpired', qs?.almostExpired)

    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/flex-links`

    return this.http
      .get<LinkListResponse>(url, {
        headers: httpOptions.headers,
        params,
      })
      .pipe(
        catchError((error: any) => {
          console.error('On bebop-link:listFlexLinks', error.message)
          return of({
            error: {
              msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
              reason: error?.error?.reason ?? '',
            },
          })
        })
      )
  }

  updateLink(link: BebopLink, emailTo?: string[]) {
    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/update`

    let payload = emailTo?.length ? { ...link, emailTo } : link

    return this.http.put<LinkUpdateResponse>(url, { ...payload, projectId: payload?.project?._id }).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:updateLink', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  deactivate(link: BebopLink) {
    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/deactivate`

    return this.http.put<GenericResponse>(url, link).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:deactivate', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  createLink(link: {
    organizationId: ObjectId
    projectId: ObjectId
    linkType: LinkType
    expires?: number | Date
    protected?: LinkProtection
    password?: string
    name: string
    settings?: LinkSettings
  }) {
    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/create`

    return this.http.post<LinkCreateResponse>(url, link).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:createLink', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  validateLinkAccess(data: { link: string; password: string; linkType: LinkType }) {
    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/validate-password/${data.link}`

    return this.http.post<LinkAccessResponse>(url, data).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:validateLinkAccess', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateLinkProtect(link: BebopLink, status: LinkProtection) {
    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/update`
    const payload: UpdateLinkProtectPayload = {
      for: 'password',
      linkId: link._id,
      linkType: link.linkType,
      organizationId: link.organization?._id,
      projectId: link.project?._id,
      protected: status,
    }

    if (status === 'PASSWORD') {
      payload.password = link.password
    }

    return this.http.put<LinkUpdateResponse>(url, payload).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:updateLink', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }

  updateLinkSettings(link: BebopLink, settings: ISettingsLinks) {
    let url = `${this.bebopConfig.apiUrl}/api/v1/drop-link/update`

    const payload: IPayloadSettingsLinks = {
      for: 'settings',
      linkId: link._id,
      linkType: link.linkType,
      name: link.name,
      organizationId: link.organization?._id,
      projectId: link.project?._id,
      settings: settings,
    }

    return this.http.put<LinkUpdateResponse>(url, payload).pipe(
      catchError((error: any) => {
        console.error('On bebop-link:updateLinkSettings', error.message)
        return of({
          error: {
            msg: error?.error?.msg || error?.error?.error?.msg || error?.error?.message || '',
            reason: error?.error?.reason ?? '',
          },
        })
      })
    )
  }
}
