<div class="container">

  <div class="loader" *ngIf="isLoading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <div class="file-entries" *ngIf="!noFiles && browseView == 'List'">
    <div class="file-entry" *ngFor="let file of pageOptions.list; let i = index"
      [class.last]="i == pageOptions.list?.length - 1" [class.first]="i == 0"></div>
  </div>

  <div class="file-gallery-entries" *ngIf="!noFiles && browseView == 'Gallery'">
    <div *ngFor="let file of pageOptions.list; let i = index" [class.last]="i == pageOptions.list?.length - 1"
      [class.first]="i == 0">
      <cree8-card-file (actionFile)="onActionFile($event, file)" [activeProject]="selectedFile"
        [file]="file"></cree8-card-file>
    </div>
  </div>

  <no-data [title]="'No files yet?'" [icon]="'Folder_Single'"
    [description]="'There are no files in this folder yet. You can upload files via the CREE8 client or create more folders.'"
    *ngIf="noFiles && !isLoading"></no-data>
</div>

<cree8-modal [modal]="modalSetting" (cancelModal)="onCloseModal()" (next)="onSubmitModal()" [size]="
    modalSetting.title === MODAL_TITLE_SHARE_LINK ||
    modalSetting.title === MODAL_TITLE_RECEIVE_LINK
      ? 'normal'
      : 'large'
  " *ngIf="modalSetting.title !== ''">
  <ng-container *ngIf="modalSetting.title !== MODAL_TITLE_SHARE_LINK">
    <video width="100%" height="auto" controls *ngIf="selectedFile?.type === 'video' && streamUrl !== ''">
      <source [src]="streamUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="image-container">
      <img [src]="streamUrl" *ngIf="selectedFile?.type === 'image' && streamUrl !== ''" class="image-preview" />
    </div>
  </ng-container>

  <cree8-files-share-link *ngIf="modalSetting.title === MODAL_TITLE_SHARE_LINK" [data]="selectedFile"
    #shareLinkComponent (successCreate)="onSuccessCreateLink()"></cree8-files-share-link>

  <cree8-files-share-link *ngIf="modalSetting.title === MODAL_TITLE_RECEIVE_LINK" [data]="selectedFile"
    [receiveLink]="true" #shareLinkComponent (successCreate)="onSuccessCreateLink()"></cree8-files-share-link>
</cree8-modal>

<confirmation-modal *ngIf="modalDeleteActive" [data]="confirmationDeleteModal" (closeModal)="modalDeleteActive = false"
  (delete)="deleteFolderOrFile()"></confirmation-modal>
