import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

import {
  RemoteNavigator,
  RocketChannel,
  RocketDownloadBrowseView,
  RocketDownloadView,
  RocketSession,
  RocketTransferProgress,
  UiDownloadFile,
} from 'src/app/components/rocket/common/classes/rocket-types'
import { Organization, Pod, Project } from 'src/app/models/bebop.model'
import { ServerState, UiProject } from 'src/app/models/ui.model'

let downloadId = 0

export interface DownloaderState {
  selectedDownload: RocketSession<any, UiDownloadFile>
  selectedPod: Pod
  selectedProject: UiProject
  downloads: RocketSession<any, UiDownloadFile>[]
  serverState: ServerState
  browseNav: Record<string, RemoteNavigator>
}

export function createInitialState(): DownloaderState {
  return {
    browseNav: {},
    downloads: [],
    selectedDownload: null,
    selectedPod: null,
    selectedProject: null,
    serverState: 'Connected',
  }
}

export function createDefaultNavigator(): RemoteNavigator {
  return {
    depth: 0,
    endDate: null,
    endDateTs: 0,
    ext: null,
    maxSize: null,
    path: '/',
    search: '',
    sort: [],
    startDate: null,
    startDateTs: 0,
  }
}

export function createInitialDownloadOverallProgress(): Partial<RocketTransferProgress<UiDownloadFile>> {
  return {
    completed: 0,
    completeList: [],
    durationReadable: '0 Sec',
    durationSeconds: 0,
    error: 0,
    errorList: [],
    nextList: [],
    paused: false,
    pendingCount: 0,
    progress: 0,
    progressCount: 0,
    progressList: [],
    queuedList: [],
    size: 0,
    sizeBytes: '0 bytes',
    total: 100,
    transferRate: '0 Mbps',

    transferred: 0,
    transferredBytes: '0 bytes',

    verifying: 0,
    verifyingList: [],
  }
}

export function createDownloadSession(
  rocket: any,
  organization: Organization,
  props?: Partial<RocketSession<any, UiDownloadFile>>
): RocketSession<any, UiDownloadFile> {
  rocket.$sessionID = rocket.$sessionID || Math.random().toString(36).substring(2, 17)
  return {
    abort: false,
    browseView: RocketDownloadBrowseView.ListView,
    busy: [],
    channel: RocketChannel.Bebop,
    checksumFileList: [],
    diskUsage: {
      available: '0.0',
      low: true,
      total: '0.0',
    },
    downloadQueue: [],
    downloadQueueMap: {},
    entries: [],
    id: ++downloadId,
    metricsInfo: {
      $modifiedList: {},
    },
    newSession: false,
    newSessionStarted: 0,
    organization: organization,
    overallProgress: createInitialDownloadOverallProgress(),
    remoteDirectory: '/',
    resetRootPath: '/',
    rocket,
    rocketDownloadView: RocketDownloadView.browser,
    rootPath: '/',
    sessionID: rocket.$sessionID,
    sessionLastCleared: 0,
    totalFiles: 0,
    transferLabel: '0 OF 0',
    transferStatus: 'init',
    ...props,
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rocket:downloader', resettable: true })
export class DownloaderStore extends Store<DownloaderState> {
  constructor() {
    super(createInitialState())
  }
}
