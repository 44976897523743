import { Injectable } from '@angular/core'
import { BehaviorSubject, first } from 'rxjs'
import { Router, NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class StepperSignupService {
  private readonly STEPS = [
    { step: '1', value: 'Account Details' },
    { step: '2', value: 'Set Password' },
    { step: '3', value: 'Setup 2FA' },
  ]

  private listStepperSource = new BehaviorSubject<
    { step: string; value: string }[]
  >(this.STEPS)
  private currentStepperSource = new BehaviorSubject<number>(1)

  listStepper$ = this.listStepperSource.asObservable()
  currentStepper$ = this.currentStepperSource.asObservable()

  constructor(private router: Router) {
    // Update step based on current route
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const url = event.url
        if (
          url.includes('/signup/personal-details') ||
          url.includes('/signup/email-sent')
        ) {
          this.setCurrentStepper(1)
        } else if (url.includes('/signup/create-password')) {
          this.setCurrentStepper(2)
        } else if (url.includes('/signup/setup-two-factor')) {
          this.setCurrentStepper(3)
        }
      })
  }

  setListStepper(data: { step: string; value: string }[]) {
    this.listStepperSource.next(data)
  }

  setCurrentStepper(step: number) {
    this.currentStepperSource.next(step)
  }

  setValueList(value: string, currentStep?: number) {
    if (currentStep) {
      this.setValue(value, currentStep)
      return
    }

    this.currentStepper$.pipe(first()).subscribe({
      next: (res) => {
        this.setValue(value, res)
      },
    })
  }

  private setValue(value: string, index: number) {
    const currentIndex = index - 1 // Convert 1-based index to 0-based for array access
    const currentList = this.listStepperSource.getValue()
    if (currentIndex >= 0 && currentIndex < currentList.length) {
      const updatedList = [...currentList]
      updatedList[currentIndex] = { ...updatedList[currentIndex], value }
      this.listStepperSource.next(updatedList)
    } else {
      console.error('Index out of range')
    }
  }
}
