<p class="error" *ngIf="error && validLengthOtp">* Enter a valid code</p>
<div class="container-otp">
  <form [formGroup]="otpForm">
    <div formArrayName="otpArray" class="form-otp">
      <ng-container *ngFor="let control of otpArray.controls; let i = index">
        <input
          type="text"
          class="otp-input"
          [formControlName]="i"
          [maxLength]="1"
          [attr.id]="'otpInput' + i"
          (input)="onInput(control.value, i, $event)"
          (keyup)="onKeypress(i, $event)"
          [attr.pattern]="getInputPattern()"
          [disabled]="disabled"
        />
      </ng-container>
    </div>
  </form>
</div>

