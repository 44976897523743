<div class="bebop-input-time-picker timepicker" #timepicker>
  <bebop-input
    noInputLabel="true"
    leftIconSeparator="true"
    type="text"
    [binding]="timeStr"
    (click)="openTimePicker()"
    class="time-input"
  >
    <div class="icon-left" input-icon-left>
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.10746 4.10679C5.61459 2.59967 7.69906 1.66602 10 1.66602C12.301 1.66602 14.3855 2.59967 15.8926 4.10679C17.3997 5.61392 18.3334 7.69839 18.3334 9.99935C18.3334 12.3003 17.3997 14.3848 15.8926 15.8919C14.3855 17.399 12.301 18.3327 10 18.3327C7.69906 18.3327 5.61459 17.399 4.10746 15.8919C2.60034 14.3848 1.66669 12.3003 1.66669 9.99935C1.66669 7.69839 2.60034 5.61392 4.10746 4.10679ZM10 3.33268C8.15884 3.33268 6.49332 4.07796 5.28597 5.2853C4.07863 6.49264 3.33335 8.15817 3.33335 9.99935C3.33335 11.8405 4.07863 13.5061 5.28597 14.7134C6.49332 15.9207 8.15884 16.666 10 16.666C11.8412 16.666 13.5067 15.9207 14.7141 14.7134C15.9214 13.5061 16.6667 11.8405 16.6667 9.99935C16.6667 8.15817 15.9214 6.49265 14.7141 5.2853C13.5067 4.07796 11.8412 3.33268 10 3.33268ZM10 5.83268C10.4603 5.83268 10.8334 6.20578 10.8334 6.66602V9.65417L12.2559 11.0768C12.5814 11.4022 12.5814 11.9298 12.2559 12.2553C11.9305 12.5807 11.4029 12.5807 11.0774 12.2553L9.41077 10.5886C9.33087 10.5087 9.27059 10.4166 9.22992 10.3183C9.18948 10.2208 9.16702 10.1139 9.16669 10.0018C9.16669 10.001 9.16669 10.0002 9.16669 9.99935V9.99877V6.66602C9.16669 6.20578 9.53978 5.83268 10 5.83268Z"
          fill="white"
        />
      </svg>
    </div>
  </bebop-input>
</div>
