import { Injectable } from '@angular/core'

import { UIStore, UIState, createInitialState } from './ui.store'
import { User, Organization, Pod, Entitlement, UserPermission } from 'src/app/models/bebop.model'

@Injectable({ providedIn: 'root' })
export class UIService {
  constructor(private uiStore: UIStore) {}

  update(data: Partial<UIState>) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          ...data,
        })
    )
  }

  setCurrentUser(user: Partial<User>) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          currentUser: {
            ...store.currentUser,
            ...user,
          },
        })
    )
  }

  setUserSettingFirstTimeApply(
    value: Partial<{
      Pod: boolean
      Organization: boolean
    }>
  ) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          userSettingFirstTimeApply: {
            ...store.userSettingFirstTimeApply,
            ...value,
          },
        })
    )
  }

  setSelectedOrg(org: Organization) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          selectedOrg: org,
        })
    )
  }

  setSelectedEntitlement(entitlement: Entitlement) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          selectedEntitlement: entitlement,
        })
    )
  }

  setSelectedPod(pod: Pod) {
    let spod = this.uiStore.getValue().selectedPod
    // if (spod?._id == pod?._id) return

    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          selectedPod: pod,
        })
    )
  }

  setNavPermissions(permissions: UIState['navPermissions']) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          navPermissions: permissions,
        })
    )
  }

  setUserPermissions(permission: UserPermission) {
    this.uiStore.update(
      (store) =>
        (store = {
          ...store,
          userPermissions: permission,
        })
    )
  }

  setLoadingPods(loadingPods: boolean) {
    this.update({
      loadingPods,
    })
  }

  forceLogout() {
    this.uiStore.update((store) => createInitialState())
  }
}
