<div class="accordion" [ngClass]="variant">
  <div class="header" [class.active]="show" (click)="toggleDiv()">
    @if(!show){ Show {{ title }}

    <div class="icon">
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L5 5L9 1"
          [attr.stroke]="variant === 'light' ? 'black' : 'white'"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    } @else { Hide {{ title }}

    <div class="icon">
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 5L5 1L1 5"
          [attr.stroke]="variant === 'light' ? 'black' : 'white'"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    }
  </div>

  <div *ngIf="show" @slideToggle class="content">
    <ng-content> Tester </ng-content>
  </div>
</div>
