import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'

import { UiDownloadFile } from 'src/app/components/rocket/common/classes/rocket-types'
import { IProjectDetail } from 'src/app/models/projects.model'
import { ProjectsService } from 'src/app/store/projects/projects.service'

import { ICardActionsList } from '../card-actions-list/card-actions-list.component'

@Component({
  selector: 'card-projects',
  styleUrl: './card-projects.component.scss',
  templateUrl: './card-projects.component.html',
})
export class CardProjectsComponent implements OnInit {
  @Input() type: 'grid' | 'list' = 'grid'
  @Input() project: IProjectDetail = {} as IProjectDetail
  @Input() activeProject: IProjectDetail
  @Output() actionProject: EventEmitter<{
    type: ICardActionsList
    data: any
  }> = new EventEmitter<{ type: ICardActionsList; data: any }>()
  listCover: string[] = ['folder-cover-1.png', 'folder-cover-2.png', 'folder-cover-3.png', 'folder-cover-4.png']
  projectCover: string

  showActionButtons = false

  constructor(
    private router: Router,
    private projectsService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.projectCover = this.generateCover()
  }

  // hideToggleActionButtons() {
  //   this.actionProject.emit({ data: null, type: 'toggle' })
  // }

  deleteProject(project: IProjectDetail) {
    this.actionProject.emit({ data: project, type: 'delete' })
  }

  detailProject(project: IProjectDetail) {
    this.projectsService.setProjectPage('details')
    this.router.navigate(['/app/projects/view'], { queryParams: { projectId: project._id } })
  }

  // toggleProject(project: IProjectDetail) {
  //   this.actionProject.emit({ data: project, type: 'toggle' })
  // }

  generateCover(): string {
    const index = Math.floor(Math.random() * this.listCover.length)
    return `assets/img/folder-cover/${this.listCover[index]}`
  }

  onOpenDropdown(project: IProjectDetail) {
    if (!project.dropDownActive) project.dropDownActive = true
    else project.dropDownActive = true
  }

  onCloseDropdown(project: IProjectDetail) {
    if (!project.dropDownActive) project.dropDownActive = false
    else project.dropDownActive = false
  }

  actionsList(): ICardActionsList[] {
    return ['link', 'members', 'settings', 'delete']
  }

  onAction(props: { action: ICardActionsList; data: any }) {
    this.actionProject.emit({ data: props.data, type: props.action })
  }
}
