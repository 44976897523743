<div class="container">
  <div class="loader" *ngIf="loading">
    <bebop-loader [status]="'Loading'" [rounded]="false"></bebop-loader>
  </div>

  <no-data [title]="'No links yet?'" [icon]="'Link'" [description]="
      'Let\'s not wait any longer—start setting up your first link now!'
    " *ngIf="links.length === 0 && !loading"></no-data>

  <table class="cree8-table" *ngIf="links.length > 0">
    <thead>
      <tr>
        <th style="width: 20%">Name</th>
        <th>Projects</th>
        <th>Expiration</th>
        <th style="width: 15%">Quick actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let link of links">
        <td>
          <div class="label-container">
            <span class="body3">{{ link.name || '' }}</span>
            <span class="captions">{{ link.created_by?.username || '' }}</span>
          </div>
        </td>
        <td>
          <div class="label-container">
            <span class="body3">{{ link.link || '' }}</span>
            <span class="captions">{{ link.project?.name || '' }}</span>
          </div>
        </td>
        <td>
          <display-date-time [value]="link.expires"></display-date-time>
        </td>
        <td>
          <table-actions (toggleChange)="activedLinks($event, link)" (action)="onAction($event, link)"
            [isActive]="link.is_active"></table-actions>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<cree8-modal *ngIf="modalActive" [modal]="modal" (cancelModal)="onCancelModal($event)" (next)="onNext($event)">
  <!-- Modal Link Protect -->
  <link-protect #linkProtect [protectValue]="selectedLink.protected" [link]="selectedLink"
    (statusUpdateProtect)="onStatusUpdateProtect($event)" (changeOption)="onChangeOption($event)"
    *ngIf="modal.title === MODAL_TITLE_PROTECT">
  </link-protect>

  <!-- Modal Link Stats -->
  <link-stats *ngIf="modal.title === MODAL_TITLE_STATUS" [link]="selectedLink">
  </link-stats>

  <!-- Modal Link Expiration -->
  <link-expiration *ngIf="modal.title === MODAL_TITLE_EXPIRATION" [link]="selectedLink"
    (statusUpdate)="onStatusUpdateExpiration($event)" #linkExpiration>
  </link-expiration>

  <!-- Modal Link Settings -->
  <link-settings *ngIf="modal.title === MODAL_TITLE_SETTINGS" [link]="selectedLink"
    (statusUpdateSettings)="onStatusUpdateSettings($event)" #linkSettings>
  </link-settings>
</cree8-modal>
