<div class="bebop-login">
  <div class="banner" *ngIf="hasBanner">
    <bebop-banner *ngIf="hasBanner" [message]="bannerText" (on-close)="onCloseBanner()"></bebop-banner>
  </div>

  <div class="login-view">


    <div class="state-login" [class]="'state-' + logInState" [class.loading]="loading"
      [class.reset-password]="logInState == logInStateEnum.ResetPassword"
      [class.password-changed]="logInState == logInStateEnum.PasswordChanged"
      [class.login-banner]="logInState == logInStateEnum.LoginEmail2FA || logInState == logInStateEnum.LoginOAuth">

      <div class="identifier" *ngIf="checkIdentifier() ===  'beta' || checkIdentifier() === 'dev'">
        <cree8-indicator-env [identifier]="checkIdentifier()"></cree8-indicator-env>
      </div>

      <div class="logo-section"></div>
      <!-- <bebop-server-status
        status="Loading"
        *ngIf="loading"
        [loadingStyles]="{ width: '96px', height: '96px', filter: 'contrast(0.75)' }"
      ></bebop-server-status> -->
      <!-- TODO Update loading contrast -->
      <ng-container [ngSwitch]="logInState">
        <ng-container *ngSwitchCase="logInStateEnum.Login" [ngTemplateOutlet]="userLogin"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.LoginWithPassword" [ngTemplateOutlet]="userLogin"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.LoginOAuth" [ngTemplateOutlet]="userLoginOAuth"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.LoginSMS2FA" [ngTemplateOutlet]="userSMS2FA"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.LoginEmail2FA" [ngTemplateOutlet]="userEmail2FA"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.SetupTOTP2FA" [ngTemplateOutlet]="userTOTP2FASetup"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.LoginTOTP2FA" [ngTemplateOutlet]="userTOTP2FALogin"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.ForgotPassword" [ngTemplateOutlet]="forgotPassword"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.ResetPassword" [ngTemplateOutlet]="resetPassword"></ng-container>
        <ng-container *ngSwitchCase="logInStateEnum.PasswordChanged"
          [ngTemplateOutlet]="passwordChanged"></ng-container>
      </ng-container>
    </div>

    <ng-template #userLogin>

      <ng-container *ngIf="logInState !== logInStateEnum.LoginWithPassword">

        <!-- <div class="h0">Log In</div>
        <div class="paragraph welcome">Welcome back! Please, fill the information below.</div> -->

        <div class="auth-info paragraph" *ngIf="!authError && authInfo">{{ authInfo }}</div>
        <div class="auth-failed paragraph" *ngIf="authError">{{ authError }}</div>

        <cree8-input type="text" placeholder="Email Address" [(binding)]="credentials.username" [autofocus]="
            ((!credentials.username || credentials.username.length == 0)) ||
            logInState == logInStateEnum.Login
          " (on-keyup)="onKeyupUsername($event)" [error]="!!formErrors.email">
          <div input-label class="email-label">Email</div>
          <div input-error-label>{{ formErrors.email }}</div>
        </cree8-input>

        <ng-container *ngIf="logInState == logInStateEnum.Login">
          <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
            (clicked)="getLoginProvider()" *ngIf="!oAuthProviders.length">
            CONTINUE
          </cree8-btn>

          <div class="login-providers">
            <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button-provider"
              (clicked)="loginWithOAuth(provider)" *ngFor="let provider of oAuthProviders">
              {{ provider.name }}
            </cree8-btn>
          </div>
          <!-- <bebop-link-button (on-click)="showLoginWithPassword()" disabled="false" class="login-link-password">
            Log in with password?
          </bebop-link-button> -->

        </ng-container>

      </ng-container>

      <ng-container *ngIf="logInState == logInStateEnum.LoginWithPassword">
        <div class="fill-email">
          <cree8-btn-icon (clicked)="showLogin()">
            <svg class="ico" viewBox="0 0 24 24">
              <use xlink:href="app/common/assets/icons/_sprite.svg#Arrow_Single_Back"></use>
            </svg>
          </cree8-btn-icon>
          <span class="body3">{{ credentials.username }}</span>
        </div>

        <cree8-input class="input-password" type="password" placeholder="Password" [(binding)]="credentials.password"
          [autofocus]="formInfo.passwordAutoFocus" (on-keyup)="onKeyupPassword($event)" [error]="!!formErrors.pwd">
          <div input-label class="email-label">Password</div>
          <div input-error-label>{{ formErrors.pwd }}</div>
        </cree8-input>

        <div class="keep-container">
          <cree8-checkbox label="Keep me signed in" [isChecked]="true"></cree8-checkbox>
          <a class="body3" (click)="showForgotPassword()">Forgot password</a>
        </div>

        <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
          (clicked)="onLoginWithPassword($event)">
          SIGN IN
        </cree8-btn>

        <!--<bebop-link-button (on-click)="showLogin()" disabled="false" class="login-link-password">
          Log in with SSO?
        </bebop-link-button>-->
        <div class="divider"></div>
      </ng-container>

      <div class="centered-text-border">
        <span class="body3">Or sign in with</span>
      </div>
      <div class="sso-login-container inverted">
        <cree8-btn-icon (click)="loginSSO('google')" title="Google login" size="large">
          <svg class="ico" viewBox="0 0 16 16">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Google"></use>
          </svg>
        </cree8-btn-icon>
        <cree8-btn-icon (click)="loginSSO('windows')" title="Windows login" size="large">
          <svg class="ico" viewBox="0 0 16 16">
            <use xlink:href="app/common/assets/icons/_sprite.svg#Windows"></use>
          </svg>
        </cree8-btn-icon>
        <cree8-btn-icon (click)="loginSSO('adobe')" title="Adobe login" size="large">
          <svg class="adobe" viewBox="0 0 16 16">
            <use xlink:href="app/common/assets/icons/_sprite.svg#AdobeCC"></use>
          </svg>
        </cree8-btn-icon>
      </div>

      <p class="body3 dont-have-account">Don’t have an account? <a (click)="showSignUp()">Sign up</a></p>
    </ng-template>

    <ng-template #userSMS2FA>
      <div class="h0">Authentication</div>
      <div class="paragraph welcome">
        We have sent an authentication code to your mobile number ending -
        <span class="xxx paragraph">{{ this.formInfo?.mobileLast4Digits || 'xxx' }}</span>
      </div>
      <div class="auth-failed paragraph" *ngIf="authError">{{ authError }}</div>

      <bebop-input type="number" placeholder="Code" [(binding)]="credentials.auth_code" autofocus="true"
        (on-keyup)="onKeyupAuthCode($event)" [error]="!!formErrors.authCode">
        <div input-label class="email-label">Code</div>
        <div input-error-label>{{ formErrors.authCode }}</div>
      </bebop-input>

      <div class="resend-code" *ngIf="formInfo.showResendCode">
        <div class="resend-label paragraph">Haven't received it?</div>
        <bebop-link-button (click)="resend2FACode()">Resend</bebop-link-button>
      </div>

      <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
        (clicked)="onConfirm2FACode($event)">
        Confirm
      </cree8-btn>

      <bebop-link-button (click)="showLogin(true)" disabled="false" class="login-link-password">
        Log in with a different account?
      </bebop-link-button>

    </ng-template>

    <ng-template #userEmail2FA>
      <div class="login-banner-view">
        <bebop-login-banner type="Email2FA" [banner-data]="{ sentTo: formInfo.emailSentTo }"
          (on-login)="onLoginWithDifferentAccount()"></bebop-login-banner>
      </div>
    </ng-template>

    <ng-template #userTOTP2FASetup>
      <ng-container *ngIf="formInfo.totpSetupPending">
        <ng-container *ngIf="formInfo.totpSetupPending && formInfo.showTOTPQR">
          <!-- Show QR -->
          <div class="h0">Setup</div>
          <div class="paragraph welcome">
            To enhance your security, scan the displayed QR code using your preferred 2FA authenticator app
          </div>
          <div class="auth-failed paragraph" *ngIf="authError">{{ authError }}</div>
          <qr-code id="qr1" contents="{{ credentials.totpSetupURI }}" module-color="#000000"
            position-ring-color="#000000" position-center-color="#000000" mask-x-to-y-ratio="1"
            style="width: 200px; height: 200px; margin: 2em auto; background-color: #ffffff; border-radius: 10px">
            <img src="/assets/logo/icon.ico" slot="icon" class="qr-logo" />
          </qr-code>
          <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
            (clicked)="verifyTOTPSetup()">
            Continue
          </cree8-btn>
        </ng-container>
        <ng-container *ngIf="formInfo.totpSetupPending && !formInfo.showTOTPQR">
          <!-- Verification code -->
          <div class="h0">Verification</div>
          <div class="paragraph welcome">Please enter the token generated on your authenticator app</div>
          <div class="auth-failed paragraph" *ngIf="authError">{{ authError }}</div>

          <bebop-input type="number" placeholder="Token" [(binding)]="credentials.token" autofocus="true"
            (on-keyup)="onKeyupAuthToken($event)" [error]="!!formErrors.authCode">
            <div input-label class="email-label">Token</div>
            <div input-error-label>{{ formErrors.authCode }}</div>
          </bebop-input>

          <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
            (clicked)="onConfirmTOTP2FACode($event)">
            Confirm
          </cree8-btn>
        </ng-container>
        <bebop-link-button (click)="showLogin(true)" disabled="false" class="login-link-password">
          Log in with a different account?
        </bebop-link-button>
      </ng-container>
      <ng-container *ngIf="formInfo.totpSetupVerified">
        <!-- TOTP setup complete-->
        <div class="h0">Success</div>
        <div class="paragraph welcome">
          Setup complete, for your future logins you will be prompted to enter the token from your authentication app
        </div>
        <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
          (clicked)="onContinueLoginProcess($event)">
          Continue
        </cree8-btn>

        <bebop-link-button (on-click)="showLogin(true)" disabled="false" class="login-link-password">
          Log in with a different account?
        </bebop-link-button>
      </ng-container>
    </ng-template>

    <ng-template #userTOTP2FALogin>
      <div class="h0">Authentication</div>
      <div class="paragraph welcome">Please enter the token generated on your authenticator app</div>
      <div class="auth-failed paragraph" *ngIf="authError">{{ authError }}</div>

      <bebop-input type="number" placeholder="Token" [(binding)]="credentials.token" autofocus="true"
        (on-keyup)="onKeyupAuthToken($event)" [error]="!!formErrors.authCode">
        <div input-label class="email-label">Token</div>
        <div input-error-label>{{ formErrors.authCode }}</div>
      </bebop-input>

      <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
        (clicked)="onConfirmTOTP2FACode($event)">
        Confirm
      </cree8-btn>

      <bebop-link-button (click)="showLogin(true)" disabled="false" class="login-link-password">
        Log in with a different account?
      </bebop-link-button>
    </ng-template>

    <ng-template #userLoginOAuth>
      <div class="login-banner-view">
        <bebop-login-banner type="OAuth" (on-login)="onLoginWithDifferentAccount()"></bebop-login-banner>
      </div>
    </ng-template>

    <ng-template #forgotPassword>
      <div class="h0">Forgot Password</div>
      <div class="paragraph welcome forgotpwd">
        No worries! Enter your email below and we'll send you a link to reset your password.
      </div>

      <div class="auth-info paragraph" *ngIf="formInfo.emailSent">Email with link to reset your password is sent. Refer
        to the steps in the email to reset your password.</div>
      <div class="auth-failed paragraph" *ngIf="formInfo.emailSendFailed">{{ formInfo.emailSendFailed }}</div>

      <bebop-input type="text" placeholder="Email Address" [(binding)]="credentials.username" autofocus="true"
        (on-keyup)="onKeyupEmail($event)" [error]="!!formErrors.email">
        <div input-label class="email-label">Email</div>
        <div input-error-label>{{ formErrors.email }}</div>
      </bebop-input>

      <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
        (clicked)="onSendResetPasswordLink()">
        {{ formInfo.emailSent ? 'Resend link' : 'Send link' }}
      </cree8-btn>

      <bebop-link-button size="small" (click)="showLogin(true)" disabled="false" class="login-link-password">
        <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M10.8536 4.14645C11.0488 4.34171 11.0488 4.65829 10.8536 4.85355L7.70711 8L10.8536 11.1464C11.0488 11.3417 11.0488 11.6583 10.8536 11.8536C10.6583 12.0488 10.3417 12.0488 10.1464 11.8536L6.64645 8.35355C6.45118 8.15829 6.45118 7.84171 6.64645 7.64645L10.1464 4.14645C10.3417 3.95118 10.6583 3.95118 10.8536 4.14645Z"
            fill="#2D5DE0" />
        </svg>
        Back to Log in
      </bebop-link-button>
    </ng-template>

    <ng-template #resetPassword>
      <div class="h0">Reset password</div>
      <div class="auth-failed paragraph" *ngIf="authError">{{ authError }}</div>

      <div class="password-match-wrapper input-reset-password">
        <bebop-input class="input-reset-password" type="password" placeholder="Input Password"
          [(binding)]="credentials.password" (on-keyup)="onKeyupResetPassword($event)"
          (on-focus)="onFocusValidateResetPassword()" (on-blur)="onBlurValidateResetPassword()">
          <div input-label class="email-label">Password</div>
        </bebop-input>
        <ng-container *ngIf="formInfo.matchWidget?.password">
          <ng-container
            *ngTemplateOutlet="passwordMatch; context: { $implicit: formInfo.passwordMatch }"></ng-container>
        </ng-container>
      </div>
      <div class="password-match-wrapper">
        <bebop-input class="input-password" type="password" placeholder="Confirm Password"
          [(binding)]="credentials.confirmPassword" (on-keyup)="onKeyupResetConfirmPassword($event)"
          (on-focus)="onFocusValidateResetConfirmPassword()" (on-blur)="onBlurValidateResetConfirmPassword()">
          <div input-label class="email-label">Confirm Password</div>
        </bebop-input>
        <ng-container *ngIf="formInfo.matchWidget?.confirmPassword">
          <ng-container
            *ngTemplateOutlet="passwordMatch; context: { $implicit: formInfo.confirmPasswordMatch }"></ng-container>
        </ng-container>
      </div>

      <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
        (clicked)="resetUserPassword()">
        Reset Password
      </cree8-btn>
    </ng-template>

    <ng-template #passwordChanged>
      <div class="password-changed-view">
        <svg class="tick-icon" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="44" cy="44" r="44" fill="#2B2B33" />
          <circle cx="44" cy="44" r="43.5" stroke="#32BA78" stroke-opacity="0.1" />
          <mask id="mask0_796_77491" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="88"
            height="88">
            <circle cx="44" cy="44" r="43.5" fill="url(#paint0_linear_796_77491)"
              stroke="url(#paint1_linear_796_77491)" />
          </mask>
          <g mask="url(#mask0_796_77491)">
            <path
              d="M11.6875 26.4029L35.6436 51.7929C37.6311 53.8994 40.9868 53.882 42.9524 51.7551L65.9928 26.8239C66.8097 25.9401 67.9585 25.4375 69.1619 25.4375V25.4375C72.8205 25.4375 74.8191 29.7046 72.4769 32.5152L71.5 33.6875"
              stroke="#32BA78" stroke-width="3" />
          </g>
          <mask id="mask1_796_77491" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="88"
            height="88">
            <circle cx="44" cy="44" r="43.5" fill="url(#paint2_linear_796_77491)"
              stroke="url(#paint3_linear_796_77491)" />
          </mask>
          <g mask="url(#mask1_796_77491)">
            <path
              d="M67.7188 38.1562L48.7961 58.5345C46.7902 60.6948 43.3602 60.6595 41.3991 58.4586L30.1715 45.8581C29.6279 45.248 28.8496 44.899 28.0325 44.899V44.899C26.4502 44.899 25.1674 46.1818 25.1674 47.7641V49.024C25.1674 51.3022 23.3206 53.149 21.0424 53.149H-4.46875"
              stroke="#32BA78" stroke-width="3" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_796_77491" x1="77.6875" y1="14.0937" x2="38.1563" y2="39.5313"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B2B33" />
              <stop offset="0.834554" stop-color="#2B2B33" stop-opacity="0.112383" />
              <stop offset="0.901042" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_796_77491" x1="1.21473" y1="0.50853" x2="29.5049" y2="101.519"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.08" />
              <stop offset="1" stop-color="white" stop-opacity="0.04" />
            </linearGradient>
            <linearGradient id="paint2_linear_796_77491" x1="66.3438" y1="25.4375" x2="18.5625" y2="53.2812"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#2B2B33" />
              <stop offset="0.515625" stop-color="#2B2B33" stop-opacity="0.854167" />
              <stop offset="0.880208" stop-color="#2B2B33" stop-opacity="0.0734767" />
              <stop offset="0.90625" stop-color="#2B2B33" stop-opacity="0.0319464" />
              <stop offset="1" stop-color="#2B2B33" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint3_linear_796_77491" x1="88" y1="70.4" x2="1.56328" y2="66.6803"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.04" />
              <stop offset="1" stop-color="white" stop-opacity="0.08" />
            </linearGradient>
          </defs>
        </svg>

        <div class="h0 changed-header">Password changed!</div>
        <div class="paragraph changed-message">Your password has been changed successfully!</div>
        <div class="paragraph changed-message2">Please login to CREE8 with new password.</div>

        <cree8-btn [loading]="loading" size="normal" [fullwidth]=true class="login-button"
          (clicked)="continueToLogin()">
          Continue to Log In
        </cree8-btn>
      </div>
    </ng-template>

    <ng-template #passwordMatch let-matchers>
      <div class="password-match-container">
        <div class="password-match-panel">
          <div class="password-match-entry" *ngFor="let match of matchers" [class.selected]="match.match">
            <div class="password-match-icon">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.00004 0.333496C3.31814 0.333496 0.333374 3.31826 0.333374 7.00016C0.333374 10.6821 3.31814 13.6668 7.00004 13.6668C10.6819 13.6668 13.6667 10.6821 13.6667 7.00016C13.6667 3.31826 10.6819 0.333496 7.00004 0.333496ZM10.0935 4.48803C10.3764 4.72374 10.4146 5.14412 10.1789 5.42697L6.84553 9.42697C6.72915 9.56663 6.56078 9.6527 6.37942 9.66525C6.19806 9.67781 6.01945 9.61576 5.88493 9.49347L3.88493 7.67529C3.61249 7.42762 3.59241 7.00599 3.84009 6.73355C4.08776 6.46111 4.50939 6.44103 4.78183 6.6887L6.26681 8.03869L9.15456 4.57339C9.39027 4.29054 9.81065 4.25232 10.0935 4.48803Z"
                  fill="#5A5A68" />
              </svg>
            </div>
            <div class="password-match-label small">{{ match.key }}</div>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="support-section" (click)="goToSupportPage()">
      <!-- Help.svg -->
      <svg viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm9-11C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1Zm-.581 14.004c.159.14.355.21.589.21.244 0 .44-.07.59-.21a.762.762 0 0 0 .222-.557c0-.211.053-.482.16-.813a1.62 1.62 0 0 1 .572-.829c.425-.32.802-.642 1.131-.963.33-.331.59-.703.78-1.114.192-.412.287-.899.287-1.46 0-.653-.16-1.22-.478-1.702a3.111 3.111 0 0 0-1.274-1.144c-.53-.281-1.14-.422-1.83-.422-.786 0-1.476.156-2.07.467a3.411 3.411 0 0 0-1.37 1.25c-.319.531-.478 1.149-.478 1.851 0 .221.074.407.223.558.16.14.356.21.59.21.243 0 .44-.07.588-.21a.762.762 0 0 0 .223-.558c0-.431.08-.808.24-1.129a1.79 1.79 0 0 1 .763-.753c.34-.18.77-.27 1.29-.27.425 0 .78.09 1.067.27a1.804 1.804 0 0 1 .924 1.58c0 .443-.08.82-.24 1.13-.148.302-.35.573-.604.813-.245.231-.515.467-.812.708a3.403 3.403 0 0 0-.988 1.25c-.212.462-.318.888-.318 1.28 0 .22.074.406.223.557Zm-.191 2.695c.223.2.483.301.78.301.308 0 .568-.1.78-.301a.986.986 0 0 0 .335-.738.955.955 0 0 0-.335-.738 1.065 1.065 0 0 0-.78-.316 1.1 1.1 0 0 0-.78.316.976.976 0 0 0-.319.738c0 .281.106.527.319.738Z"
          fill="#fff" />
      </svg>

      <div class="support-label large-button-text">Support</div>
    </div>
  </div>
</div>
